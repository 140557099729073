import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import _styles from './styles.scss';
import * as constants from '../../constants';

const FieldMessage = ({ intent, children, styles }) => {
  const className = cnames({
    [styles.errorMessage]: intent === constants.ERROR,
    [styles.successMessage]: intent === constants.SUCCESS,
    [styles.warningMessage]: intent === constants.WARNING,
    [styles.informationMessage]: intent === constants.INFORMATION,
    [styles.neutralMessage]: intent === constants.NEUTRAL,
  });

  return <div className={className}>{children}</div>;
};

FieldMessage.propTypes = {
  intent: PropTypes.string,
  children: PropTypes.node.isRequired,
  styles: PropTypes.shape({}),
};

FieldMessage.defaultProps = {
  intent: constants.INFORMATION,
  styles: _styles,
};

export default FieldMessage;
