import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';

import * as TableExports from '.';

// eslint-disable-next-line
import TableSrc from '!raw-loader!.';

const { default: Table } = TableExports;
const docs = generateDocs(TableSrc, resolver.findAllExportedComponentDefinitions);

const basicExample = {
  name: 'Basic',
  description: 'A generic table for displaying tabular text data',
  overflowVisual: false,
  render: () => (
    <Table
      rowKey="size"
      columns={[
        { dataIndex: 'size', title: 'Size', width: 150 },
        { dataIndex: 'chest', title: 'Chest' },
        { dataIndex: 'length', title: 'Length' },
      ]}
      rows={[
        { size: 'XS', chest: '37"', length: '27"' },
        { size: 'S', chest: '39"', length: '27.5"' },
        { size: 'M', chest: '41"', length: '27.75"' },
        { size: 'L', chest: '43"', length: '28.5"' },
        { size: 'XL', chest: '45"', length: '29"' },
        { size: '2XL', chest: '47"', length: '29.5"' },
      ]}
    />
  ),
  code: `
<Table
  rowKey="size"
  columns={[
    { dataIndex: 'size', title: 'Size', width: 150 },
    { dataIndex: 'chest', title: 'Chest' },
    { dataIndex: 'length', title: 'Length' },
  ]}
  rows={[
    { size: 'XS', chest: '37"', length: '27"' },
    { size: 'S', chest: '39"', length: '27.5"' },
    { size: 'M', chest: '41"', length: '27.75"' },
    { size: 'L', chest: '43"', length: '28.5"' },
    { size: 'XL', chest: '45"', length: '29"' },
    { size: '2XL', chest: '47"', length: '29.5"' },
  ]}
/>
  `,
};

const scrollingExampleSimple = {
  name: 'Row headings',
  description: 'Make the first cell in a row a table heading with <code>headingFirstColumn</code>.',
  overflowVisual: false,
  render: () => (
    <Table
      rowKey="item"
      headingFirstColumn
      columns={[
        { dataIndex: 'item', title: 'ID' },
        { dataIndex: 'weight', title: 'Weight' },
        { dataIndex: 'shipping', title: 'Shipping date' },
        { dataIndex: 'delivery', title: 'Delivery date' },
        { dataIndex: 'notes', title: 'Comments' },
      ]}
      rows={[
        { item: '213341', weight: '4kg minimum', shipping: 'In the future', delivery: '4 business days', notes: 'The package will be left outside the property if nobody is home. Please dont be mad.' },
        { item: '241233', weight: '4kg minimum', shipping: 'In the future', delivery: '4 business days', notes: 'The package will be left outside the property if nobody is home. Please dont be mad.' },
        { item: '231568', weight: '4kg minimum', shipping: 'In the future', delivery: '4 business days', notes: 'The package will be left outside the property if nobody is home. Please dont be mad.' },
      ]}
    />
  ),
  code: `
<Table
  rowKey="item"
  headingFirstColumn
  columns={[
    { dataIndex: 'item', title: 'ID' },
    { dataIndex: 'weight', title: 'Weight' },
    { dataIndex: 'shipping', title: 'Shipping date' },
    { dataIndex: 'delivery', title: 'Delivery date' },
    { dataIndex: 'notes', title: 'Comments' },
  ]}
  rows={[
    { item: '213341', weight: '4kg minimum', shipping: 'In the future', delivery: '4 business days', notes: 'The package will be left outside the property if nobody is home. Please dont be mad.' },
    { item: '241233', weight: '4kg minimum', shipping: 'In the future', delivery: '4 business days', notes: 'The package will be left outside the property if nobody is home. Please dont be mad.' },
    { item: '231568', weight: '4kg minimum', shipping: 'In the future', delivery: '4 business days', notes: 'The package will be left outside the property if nobody is home. Please dont be mad.' },
  ]}
/>
  `,
};

const scrollingExampleFixed = {
  name: 'Fixed column',
  description: 'You can choose to fix the first column on the table  with <code>fixedFirstColumn</code>.',
  overflowVisual: false,
  render: () => (
    <Table
      rowKey="item"
      fixedFirstColumn
      headingFirstColumn
      columns={[
        { dataIndex: 'item', title: 'ID' },
        { dataIndex: 'weight', title: 'Weight' },
        { dataIndex: 'shipping', title: 'Shipping date' },
        { dataIndex: 'delivery', title: 'Delivery date' },
        { dataIndex: 'notes', title: 'Comments' },
      ]}
      rows={[
        { item: '213341', weight: '4kg minimum', shipping: 'In the future', delivery: '4 business days', notes: 'The package will be left outside the property if nobody is home. Please dont be mad.' },
        { item: '241233', weight: '4kg minimum', shipping: 'In the future', delivery: '4 business days', notes: 'The package will be left outside the property if nobody is home. Please dont be mad.' },
        { item: '231568', weight: '4kg minimum', shipping: 'In the future', delivery: '4 business days', notes: 'The package will be left outside the property if nobody is home. Please dont be mad.' },
      ]}
    />
  ),
  code: `
<Table
  rowKey="item"
  fixedFirstColumn
  headingFirstColumn
  columns={[
    { dataIndex: 'item', title: 'ID' },
    { dataIndex: 'weight', title: 'Weight' },
    { dataIndex: 'shipping', title: 'Shipping date' },
    { dataIndex: 'delivery', title: 'Delivery date' },
    { dataIndex: 'notes', title: 'Comments' },
  ]}
  rows={[
    { item: '213341', weight: '4kg minimum', shipping: 'In the future', delivery: '4 business days', notes: 'The package will be left outside the property if nobody is home. Please dont be mad.' },
    { item: '241233', weight: '4kg minimum', shipping: 'In the future', delivery: '4 business days', notes: 'The package will be left outside the property if nobody is home. Please dont be mad.' },
    { item: '231568', weight: '4kg minimum', shipping: 'In the future', delivery: '4 business days', notes: 'The package will be left outside the property if nobody is home. Please dont be mad.' },
  ]}
/>
  `,
};

const clickableRowExample = {
  name: 'Clickable Rows',
  description: 'You can make the rows clickable by specifying an <code>onRow</code>prop.',
  overflowVisual: false,
  render: () => (
    <Table
      rowKey="item"
      columns={[
        { dataIndex: 'item', title: 'ID' },
        { dataIndex: 'weight', title: 'Weight' },
        { dataIndex: 'shipping', title: 'Shipping date' },
      ]}
      rows={[
        { item: '213341', weight: '4kg minimum', shipping: <a href="#213341">Click for more details</a> },
        { item: '213342', weight: '4kg minimum', shipping: <a href="#213343">Click for more details</a> },
        { item: '213343', weight: '4kg minimum', shipping: <a href="#213343">Click for more details</a> },
      ]}
      onRow={(shipment, index) => ({
        onClick: () => {
          alert(`Clicked on shipment ${shipment.item} which is on row ${index}`);
        },
      })}
    />
  ),
  code: `
<Table
  rowKey="item"
  columns={[
    { dataIndex: 'item', title: 'ID' },
    { dataIndex: 'weight', title: 'Weight' },
    { dataIndex: 'shipping', title: 'Shipping date' },
  ]}
  rows={[
    { item: '213341', weight: '4kg minimum', shipping: <a href="#213341">'Click for more details'</a> },
    { item: '213342', weight: '4kg minimum', shipping: <a href="#213343">'Click for more details'</a> },
    { item: '213343', weight: '4kg minimum', shipping: <a href="#213343">'Click for more details'</a> },
  ]}
/>
`,
};

const Demo = {
  title: Table.displayName,
  description: 'A table component',
  docGen: docs,
  slug: 'table',
  exports: Object.keys(TableExports),
  examples: [
    basicExample,
    scrollingExampleSimple,
    scrollingExampleFixed,
    clickableRowExample,
  ],
  notes: `
### Setting up rows and columns

The \`Table\` component lets you specify columns with the \`columns\` prop. Each column supports the following properties:

\`\`\`
{
  // The key of the row data you want to be rendered in this column
  dataIndex: 'name',
  // The human readable title displayed at the top of this column
  title: 'Name',
  // The width of the column (not guaranteed since content doesn't wrap)
  width: true,
  // The alignment of text in this column (left, right, center)
  align: 'right',
}
\`\`\`

Any data provided to the table as \`rows\` will be destructured into the appropriate columns based on the column's \`dataIndex\`.

E.g. given the following column config and row data:

\`\`\`
<Table
  columns={[
    { dataIndex: 'name',  title: 'Name' },
    { dataIndex: 'email', title: 'Email' },
    { dataIndex: 'name',  title: 'Name Again' },
  ]}
  rows={[
    { name: 'Brad',    email: 'example@email.com', age: 10 },
    { name: 'Marcus',  email: 'foo@bar.com',       age: 12 },
    { name: 'Russell', email: 'bar@example.com',   age: 14 },
  ]}
/>
\`\`\`

Would produce the following table:

| Name          | Email             | Name Again    |
|:------------- |:----------------- |:------------- |
| Brad          | example@email.com | Brad          |
| Marcus        | foo@bar.com       | Marcus        |
| Russell       | bar@example.com   | Russell       |

### React Keys

Each row of data in your table needs a unique React key.
Ensure that each row has a unique \`key\` property, or set the \`rowKey\` on the \`Table\` to indicate which property of the row to use as the React key.


### User interactions / events

You can use the \`onRow\` prop and \`onCell\` column attribute to attach events to entire cells and rows respectively:

\`\`\`
<Table
  columns={[
    {
      dataIndex: 'name',
      title: 'Name'
      onCell: (record, index) => ({
        onClick: () => handleCell(record, index),
        ...etc
      }),
    },
    ...
  ]}
  rows={...}
  onRow={(record, index) => ({
    onClick: () => handleRowClick(record, index),
    ...etc
  })}
/>
\`\`\`

See [the rc-table docs](http://react-component.github.io/table/examples/rowAndCellClick.html) for more information.

### Responsive Tables (Scrolling)

Tables will fill the width of their container. If the container grows smaller, the table data will become increasingly compacted. When it reaches a point that the content would overflow, the table will become horizontally scrollable.

If you have many columns and you need to help the user keep their context as they scroll horizontally, set the \`fixedFirstColumn\` prop to \`true\`.

  `,
};

export default Demo;
