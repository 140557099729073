import React, { useEffect } from 'react';
import cnames from 'classnames';
import { toast as toastify } from 'react-toastify';
import styles from './styles.scss';
import Card from '../Card';
import Box from '../Box';
import * as constants from '../constants';
import CloseIcon from '../Icons/Close';
import Button from '../Button';
import useTracking, { EVENT_OPEN, EVENT_DISMISS } from '../hooks/useTracking';

export const events = { EVENT_OPEN, EVENT_DISMISS };

export const openToast = (children, options = {}) => {
  const { action, tracking, ...rest } = options;

  return toastify((
    <Toast action={action} tracking={tracking}>
      { children }
    </Toast>
  ), rest);
};

export const updateToast = (id, options = {}) => {
  const { render, action, tracking, ...rest } = options;

  return toastify.update(id, {
    render: render && (
      <Toast action={action} tracking={tracking}>
        { render }
      </Toast>
    ),
    ...rest,
  });
};

const Toast = ({ children, closeToast, action, tracking }) => {
  const { track, TrackingContextProvider } = useTracking(tracking, 'Toast');

  useEffect(() => {
    track(EVENT_OPEN);
  }, []);

  const handleClose = (e) => {
    track(EVENT_DISMISS);
    closeToast(e);
  };

  return (
    <TrackingContextProvider>
      <Card elevation={constants.ELEVATION_HIGH}>
        <Box
          display="flex"
          padding="m"
          paddingRight={action ? 1 : 2}
          alignItems="center"
          justifyContent="space-between"
          className={cnames(styles.toast)}
        >
          { children }

          <Box marginLeft="m">
            {
              action ? action({ closeToast: handleClose }) : (
                <Box className={styles.defaultClose}>
                  <Button
                    onClick={handleClose}
                    size={constants.SMALL}
                    iconAfter={
                      <CloseIcon size={constants.MEDIUM} color={constants.COLOR_NEUTRAL_500} />
                    }
                    circle
                  />
                </Box>
              )
            }
          </Box>
        </Box>
      </Card>
    </TrackingContextProvider>
  );
};

Toast.displayName = 'Toast';

export default Toast;
