import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import cnames from 'classnames';
import { usePopper } from 'react-popper';
import posed from 'react-pose';
import Card from '../Card';
import Box from '../Box';
import useTracking, { trackingShape, EVENT_OPEN, EVENT_DISMISS } from '../hooks/useTracking';
import * as constants from '../constants';
import styles from './styles.scss';

export const events = { EVENT_OPEN, EVENT_DISMISS };

const sameWidth = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    const newState = state;
    newState.styles.popper.width = `${newState.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    const newState = state;
    newState.elements.popper.style.width = `${
      state.elements.reference.offsetWidth
    }px`;
  },
};

const PLACEMENT_MAP = {
  [constants.TOP_LEFT]: 'top-start',
  [constants.TOP_RIGHT]: 'top-end',
  [constants.BOTTOM_LEFT]: 'bottom-start',
  [constants.BOTTOM_RIGHT]: 'bottom-end',
};

const convertPlacement = (placement) => {
  if (Object.keys(PLACEMENT_MAP).includes(placement)) {
    return PLACEMENT_MAP[placement];
  }

  return placement;
};

const isControlled = open => typeof open === 'boolean';

const reducedMotion = typeof window !== 'undefined'
  && typeof window.matchMedia === 'function'
  && window.matchMedia('(prefers-reduced-motion)').matches;

const ease = [0.2, 1, 0.4, 1];

const Fade = posed.div({
  in: {
    opacity: 1,
    scale: 1,
    transition: {
      ease,
      duration: reducedMotion ? 0 : 350,
    },
  },
  out: {
    opacity: 0,
    scale: 0.9,
    transition: {
      ease,
      duration: reducedMotion ? 0 : 150,
    },
  },
});

const Popover = forwardRef(({
  trigger,
  children,
  accessibleTitle,
  size,
  fit,
  matchTriggerWidth,
  placement,
  primaryAction,
  secondaryAction,
  arrow,
  innerRef,
  open,
  onCloseRequested,
  tracking,
  ...props
}, ref) => {
  const [isOpen, setIsOpen] = useState(open);
  const { track, TrackingContextProvider } = useTracking(tracking, 'Popover');

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles: style, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: convertPlacement(placement),
    strategy: 'fixed',
    modifiers: [sameWidth, { name: 'sameWidth', enabled: matchTriggerWidth }],
  });

  const togglePopover = () => {
    if (!isControlled(open)) {
      setIsOpen(prevIsOpen => !prevIsOpen);
    }
  };

  // When isOpen changes for whatever reason, track it.
  useEffect(() => {
    if (typeof update === 'function') update();
    if (isOpen) track(EVENT_OPEN);
    if (!isOpen) track(EVENT_DISMISS);
  }, [isOpen]);

  // When open changes, update the state
  useEffect(() => {
    if (isControlled(open)) setIsOpen(open);
  }, [open]);

  const closePopover = () => {
    if (!isControlled(open)) {
      setIsOpen(false);
    } else {
      onCloseRequested();
    }
  };

  return (
    <TrackingContextProvider>
      <Box
        display="inline-block"
        aria-haspopup="true"
        aria-label={accessibleTitle}
        aria-expanded={isOpen ? 'true' : 'false'}
        ref={ref || innerRef}
        {...props}
      >
        <OutsideClickHandler
          disabled={!isOpen}
          onOutsideClick={closePopover}
        >
          <Box
            innerRef={setReferenceElement}
            style={style.reference}
          >
            {
              typeof trigger === 'function' && (
                trigger({
                  onClick: () => togglePopover(),
                  onKeyDown: (e) => {
                    const prevent = () => {
                      e.preventDefault();
                      e.stopPropagation();
                    };

                    if ([constants.KEY_ENTER, constants.KEY_SPACE].includes(e.which)) {
                      prevent();
                      togglePopover();
                    }

                    if (e.which === constants.KEY_ESC) {
                      prevent();
                      closePopover();
                    }
                  },
                })
              )
            }
          </Box>

          <div
            ref={setPopperElement}
            style={style.popper}
            className={cnames(
              styles.popover,
              styles[size],
              {
                [styles.fit]: fit || matchTriggerWidth,
                [styles.visuallyHidden]: !isOpen,
              },
            )}
            aria-hidden={!isOpen}
            {...attributes.popper}
          >
            <Fade className={styles.offset} pose={isOpen ? 'in' : 'out'} initialPose="out">
              <Card elevation={constants.ELEVATION_HIGH}>
                <div className={styles.inner}>
                  {
                    typeof children === 'function' ? (
                      children({ closePopover })
                    ) : children
                  }
                </div>

                {
                  (primaryAction || secondaryAction) && (
                    <Box
                      padding="xs"
                      flexDirection="row-reverse"
                      display="flex"
                      className={styles.action}
                      flexWrap="wrap"
                    >
                      <Box marginLeft="xs">
                        {
                          primaryAction && (
                            typeof primaryAction === 'function' ? (
                              primaryAction({ closePopover })
                            ) : primaryAction
                          )
                        }
                      </Box>
                      <Box marginLeft="xs">
                        {
                          secondaryAction && (
                            typeof secondaryAction === 'function' ? (
                              secondaryAction({ closePopover })
                            ) : secondaryAction
                          )
                        }
                      </Box>
                    </Box>
                  )
                }
              </Card>
            </Fade>
          </div>
        </OutsideClickHandler>
      </Box>
    </TrackingContextProvider>
  );
});

Popover.propTypes = {
  /**
    * A function that returns the node to be used as a trigger to toggle the popover
    * Provides props to be spread onto the triggering element
    */
  trigger: PropTypes.func.isRequired,
  /**
    * The content of the Popover
    */
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]).isRequired,
  /**
    * Describe to screen readers what this popover is for
    */
  accessibleTitle: PropTypes.string.isRequired,
  /**
    * How wide the Popover will be
    */
  size: PropTypes.oneOf([
    constants.SMALL,
    constants.MEDIUM,
    constants.LARGE,
  ]),
  /**
    * Whether to make the Popover scale width-wize to accomodate it's children
    */
  fit: PropTypes.bool,
  /**
    * Where to place the popover relative to it's trigger
    */
  placement: PropTypes.oneOf([
    constants.TOP,
    constants.RIGHT,
    constants.BOTTOM,
    constants.LEFT,
    constants.TOP_LEFT,
    constants.TOP_RIGHT,
    constants.BOTTOM_LEFT,
    constants.BOTTOM_RIGHT,
  ]),
  /**
    * An action to be displayed in the bottom right of the popover
    */
  primaryAction: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]),
  /**
    * An action displayed to the left of the primary action
    */
  secondaryAction: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]),
  /**
    * Whether or not to display an arrow pointing at the trigger
    */
  arrow: PropTypes.bool,
  /**
    * Whether or not the popover should be the same width as the trigger
    */
  matchTriggerWidth: PropTypes.bool,
  /**
    * Tracking configuration for auto tracking
    */
  tracking: trackingShape,
};

Popover.defaultProps = {
  size: constants.MEDIUM,
  fit: false,
  primaryAction: null,
  matchTriggerWidth: false,
  secondaryAction: null,
  arrow: false,
  placement: constants.BOTTOM,
  tracking: null,
};

Popover.displayName = 'Popover';

export default Popover;
