import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import * as AvatarExports from '.';
import Box from '../Box';
import Label from '../Label';
import Card, { CardHeader } from '../Card';
import Text from '../Text';
import Image from '../Image';
import List, { ListItem, ListMedia, ListContent } from '../List';
import * as constants from '../constants';
// eslint-disable-next-line
import AvatarSrc from '!raw-loader!./';
import imageSrc from './assets/rb-avatar-bird.svg';

const { default: Avatar } = AvatarExports;
const docs = generateDocs(AvatarSrc, resolver.findAllExportedComponentDefinitions);

const username = 'kitten';

const defaultExample = {
  name: 'Basic',
  muted: true,
  description: 'The avatar component is a round image with a 1:1 ratio.',
  render: () => (
    <Box display="flex" style={{ textAlign: 'center' }}>
      <Box marginRight="m">
        <Label>Basic</Label>
        <Box marginBottom="m" />
        <Box display="flex" justifyContent="center">
          <Avatar src={imageSrc} alt={`Avatar for ${username}`} />
        </Box>
      </Box>
      <Box marginRight="m">
        <Label>Basic with link</Label>
        <Box marginBottom="m" />
        <Box display="flex" justifyContent="center">
          <Avatar src={imageSrc} alt={`Avatar for ${username}`} href="#" />
        </Box>
      </Box>
    </Box>
  ),
  code: `
<Avatar src="${imageSrc}" size={constants.SMALL} alt="Avatar for ${username}" />
<Avatar src="${imageSrc}" alt="Avatar for ${username}" href="#"/>
  `,
};


const highlightedExample = {
  name: 'Highlighted',
  muted: true,
  description: 'You can make the avatar appear highlighted, with blue border and a tick.',
  render: () => (
    <Box display="flex" style={{ textAlign: 'center' }}>
      <Box marginRight="m">
        <Label>Small</Label>
        <Box marginBottom="m" />
        <Avatar src={imageSrc} size={constants.SMALL} alt={`Avatar for ${username}`} highlighted />
      </Box>
      <Box marginRight="m">
        <Label>Medium</Label>
        <Box marginBottom="m" />
        <Avatar src={imageSrc} size={constants.MEDIUM} alt={`Avatar for ${username}`} highlighted />
      </Box>
      <Box marginRight="m">
        <Label>Large</Label>
        <Box marginBottom="m" />
        <Avatar src={imageSrc} size={constants.LARGE} alt={`Avatar for ${username}`} highlighted />
      </Box>
    </Box>
  ),
  code: `
    <Avatar src="${imageSrc}" size={constants.SMALL} alt="Avatar for ${username}" highlighted />
    <Avatar src="${imageSrc}" size={constants.MEDIUM} alt="Avatar for ${username}" highlighted />
    <Avatar src="${imageSrc}" size={constants.LARGE} alt="Avatar for ${username}" highlighted />
    `,
};

const sizesExample = {
  name: 'Sizes',
  muted: true,
  description: "It can be set to some predetermined sizes or fluid to scale with it's container",
  render: () => (
    <Box display="flex" style={{ textAlign: 'center' }}>
      <Box marginRight="m">
        <Label>Tiny</Label>
        <Box marginBottom="m" />
        <Box display="flex" justifyContent="center">
          <Avatar src={imageSrc} size={constants.TINY} alt={`Avatar for ${username}`} />
        </Box>
      </Box>
      <Box marginRight="m">
        <Label>Small</Label>
        <Box marginBottom="m" />
        <Avatar src={imageSrc} size={constants.SMALL} alt={`Avatar for ${username}`} />
      </Box>
      <Box marginRight="m">
        <Label>Medium</Label>
        <Box marginBottom="m" />
        <Avatar src={imageSrc} size={constants.MEDIUM} alt={`Avatar for ${username}`} />
      </Box>
      <Box marginRight="m">
        <Label>Large</Label>
        <Box marginBottom="m" />
        <Avatar src={imageSrc} size={constants.LARGE} alt={`Avatar for ${username}`} />
      </Box>
      <Box marginRight="m" flexDirection="column" alignItems="center" justifyContent="center" flex="1">
        <Label>Fluid</Label>
        <Box marginBottom="m" />
        <Box style={{ width: '100%' }}>
          <Avatar src={imageSrc} alt={`Avatar for ${username}`} fluid />
        </Box>
      </Box>
    </Box>
  ),
  code: `
<Avatar src="${imageSrc}" size={constants.TINY} alt="Avatar for ${username}" />
<Avatar src="${imageSrc}" size={constants.SMALL} alt="Avatar for ${username}" />
<Avatar src="${imageSrc}" size={constants.MEDIUM} alt="Avatar for ${username}" />
<Avatar src="${imageSrc}" size={constants.LARGE} alt="Avatar for ${username}" />
<Avatar src="${imageSrc}" alt="Avatar for ${username}" fluid />
  `,
};


const borderedExample = {
  name: 'Bordered',
  muted: false,
  description: 'You can add a border to the avatar to give it more contrast. Useful for when the avatar is over an image or small.',
  render: () => (
    <Box display="flex">
      <Box marginRight="m" flex="1">
        <Card>
          <Image src="https://ih1.redbubble.net/cover.151929.2400x600.jpg" alt="Artist kaylabell" fluid />
          <Box display="flex" justifyContent="center" style={{ marginTop: '-40px' }}>
            <Avatar src="https://ih1.redbubble.net/avatar.1056301.140x140.jpg" size={constants.LARGE} alt="Artist kaylabell" bordered={{ color: 'var(--ds-color-background-ui)' }} />
          </Box>
          <Box paddingTop="m" style={{ textAlign: 'center' }}>
            <Text type="display3">kaylabell</Text>
          </Box>
          <Box paddingBottom="xl" style={{ textAlign: 'center' }}>
            <Text type="body2" muted>Joined 2008</Text>
          </Box>
        </Card>
      </Box>
      <Box marginRight="m" flex="1">
        <Card>
          <CardHeader>
            <Text display="block" type="display3">
              Trending artists
            </Text>
            <Text display="block" type="body2">
              We think you might like
            </Text>
          </CardHeader>
          <List relaxed divided>
            <ListItem element="a" href="http://www.redbubble.com/people/tekay/portfolio?ref=explore-artist-profile" arrow>
              <ListMedia>
                <Avatar size={constants.TINY} src="https://ih0.redbubble.net/avatar.1834901.140x140.jpg" bordered />
              </ListMedia>
              <ListContent>
                <Text display="block" type="display6">Tordis Kayma</Text>
              </ListContent>
            </ListItem>
            <ListItem element="a" href="https://www.redbubble.com/people/kaylabell/portfolio" arrow>
              <ListMedia>
                <Avatar size={constants.TINY} src="https://ih1.redbubble.net/avatar.1056301.140x140.jpg" bordered />
              </ListMedia>
              <ListContent>
                <Text display="block" type="display6">kaylabell</Text>
              </ListContent>
            </ListItem>
            <ListItem element="a" href="http://www.redbubble.com/people/celestecia/portfolio?ref=explore-artist-profile" arrow>
              <ListMedia>
                <Avatar size={constants.TINY} src="https://ih1.redbubble.net/avatar.989254.140x140.jpg" bordered />
              </ListMedia>
              <ListContent>
                <Text display="block" type="display6">Celeste Ciafarone</Text>
              </ListContent>
            </ListItem>
            <ListItem element="a" href="http://www.redbubble.com/people/thepapercrane/portfolio?ref=explore-artist-profile" arrow>
              <ListMedia>
                <Avatar size={constants.TINY} src="https://ih1.redbubble.net/avatar.682483.140x140.jpg" bordered />
              </ListMedia>
              <ListContent>
                <Text display="block" type="display6">thepapercrane</Text>
              </ListContent>
            </ListItem>
            <ListItem element="a" href="http://www.redbubble.com/people/nicebleed/portfolio?ref=explore-artist-profile" arrow>
              <ListMedia>
                <Avatar size={constants.TINY} src="https://ih1.redbubble.net/avatar.2145653.140x140.jpg" bordered />
              </ListMedia>
              <ListContent>
                <Text display="block" type="display6">nicebleed</Text>
              </ListContent>
            </ListItem>
          </List>
        </Card>
      </Box>
    </Box>
  ),
  code: `
<Avatar 
  src="https://ih1.redbubble.net/avatar.1056301.140x140.jpg" 
  size={constants.LARGE} alt="Artist kaylabell" 
  bordered={{ color: 'var(--ds-color-background-ui)' }} 
/>
<Avatar 
  size={constants.TINY} 
  src="https://ih0.redbubble.net/avatar.1834901.140x140.jpg" 
  bordered 
/>
  `,
};

const loadingExample = {
  name: 'Loading',
  muted: true,
  description: 'The Avatar can be rendered in a loading state which works well for skeleton or placeholder layouts while content is loading.',
  render: () => (
    <Box display="flex" style={{ textAlign: 'center' }}>
      <Box marginRight="m">
        <Label>Tiny</Label>
        <Box marginTop="m">
          <Avatar src={imageSrc} size={constants.TINY} alt={`Avatar for ${username}`} loading />
        </Box>
      </Box>
      <Box marginRight="m">
        <Label>Small</Label>
        <Box marginTop="m">
          <Avatar src={imageSrc} size={constants.SMALL} alt={`Avatar for ${username}`} loading />
        </Box>
      </Box>
    </Box>
  ),
  code: `
  <Box display="flex" style={{ textAlign: 'center' }}>

  <Box marginRight="m">
    <Label>Tiny</Label>
    <Box marginLeft="m">
      <Avatar src="${imageSrc}" size={constants.TINY} alt="Avatar for ${username}" loading/>
    </Box>
  </Box>

  <Box marginRight="m">
    <Label>Small</Label>
    <Box marginLeft="m">
      <Avatar src="${imageSrc}" size={constants.SMALL} alt="Avatar for ${username}" loading/>
    </Box>
  </Box>

</Box>
  `,
};

const Demo = {
  title: Avatar.displayName,
  description: 'Avatars are used to represent and identify users / people',
  slug: 'Avatar',
  exports: Object.keys(AvatarExports),
  docGen: docs,
  examples: [
    defaultExample,
    highlightedExample,
    sizesExample,
    borderedExample,
    loadingExample,
  ],
  notes: `
### Colored borders

The \`Avatar\` supports having a border via the boolean \`bordered\` prop. If you'd like to change the color of the border for use on a different background you can specify a configuration object instead of a boolean:

\`\`\`
<Avatar src="/path" bordered={{ color: 'var(--ds-color-white)' }} />
\`\`\`
  `,
};

export default Demo;
