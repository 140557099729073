import React from 'react';
import PropTypes from 'prop-types';
import * as constants from '../constants';
import Button from '../Button';
import { trackingShape, fwdTrackingWithComponent } from '../hooks/useTracking';

const { TOGGLE, SMALL, MEDIUM, LARGE } = constants;

export { events } from '../Button';

const ButtonToggle = ({ tracking, ...props }) => (
  <Button
    {...props}
    size={props.size}
    intent={TOGGLE}
    type="button"
    tracking={fwdTrackingWithComponent(tracking, 'ButtonToggle')}
  />
);

ButtonToggle.displayName = 'ButtonToggle';


ButtonToggle.propTypes = {
  /**
    * The text to be shown in the button
    */
  children: PropTypes.node,
  /**
    * Whether the button is toggled on or off
    */
  active: PropTypes.bool,
  /**
    * Fired when the button is clicked
    */
  onClick: PropTypes.func,
  /**
    * If true, the button width will fill it's container
    */
  fluid: PropTypes.bool,
  /**
    * If true, the button will be suitable to use on a dark background
    */
  inverse: PropTypes.bool,
  /**
    * Renders the button and it's text in different sizes
    */
  size: PropTypes.oneOf([
    SMALL,
    MEDIUM,
    LARGE,
  ]),
  /**
    * When true, forces the button to be a circle
    */
  circle: PropTypes.bool,
  /**
    * When true, disables interactions with the button and visually grays it out
    */
  disabled: PropTypes.bool,
  /**
    * When true, the button will have an animation to it
    */
  loading: PropTypes.bool,
  /**
    * An <Icon /> to show on the right of the button
    */
  iconAfter: PropTypes.node,
  /**
    * An <Icon /> to show on the left of the button
    */
  iconBefore: PropTypes.node,
  /**
    * Tracking configuration for auto tracking
    */
  tracking: trackingShape,
};

ButtonToggle.defaultProps = {
  children: '',
  onClick: null,
  fluid: false,
  circle: false,
  iconBefore: null,
  iconAfter: null,
  inverse: false,
  size: MEDIUM,
  disabled: false,
  loading: false,
  active: false,
  tracking: null,
};

export default ButtonToggle;
