import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import Text from '../Text';
import _styles from './styles.scss';
import * as constants from '../constants';

export {
  NEUTRAL,
  WARNING,
  INFORMATION,
  ERROR,
  SUCCESS,
  EXPRESSIVE_TEAL,
} from '../constants';

const Label = ({ children, intent, subtle, styles }) => {
  if (!children) return null;
  return (
    <div
      className={
        cnames(
          styles.label,
          styles[intent],
          {
            [styles.subtle]: subtle,
          },
        )
      }
      title={children}
    >
      { /* TODO - consider moving text-overflow ellipsis to Text component */ }
      <Text type="caption">
        <span className={styles.labelText}>{ children }</span>
      </Text>
    </div>
  );
};

Label.propTypes = {
  children: PropTypes.node.isRequired,
  intent: PropTypes.oneOf([
    constants.NEUTRAL,
    constants.WARNING,
    constants.INFORMATION,
    constants.ERROR,
    constants.SUCCESS,
    constants.EXPRESSIVE_TEAL,
  ]),
  subtle: PropTypes.bool,
  /**
    * The CSS classNames for this component. NEVER manually specify this prop
    */
  styles: PropTypes.shape({}),
};

Label.defaultProps = {
  subtle: false,
  intent: constants.NEUTRAL,
  styles: _styles,
};

Label.displayName = 'Label';

export default Label;
