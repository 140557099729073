import React from 'react';
import TokenReferenceTable from './TokenReference';
import useDOMState from '../../../hooks/useDOMState';
import Text from '../../../../react/Text';
import Box from '../../../../react/Box';

const TokenCategory = ({ name, description = '', tokens, type, semantic }) => {
  const readyState = useDOMState();
  if (readyState !== 'complete') return null;

  const filteredTokens = Object.entries(tokens).reduce((filtered, [token, attrs]) => {
    if (attrs.type === type && !attrs.deprecated) {
      // eslint-disable-next-line no-param-reassign
      if (!semantic || attrs.semanticType === semantic) filtered[token] = attrs;
    }

    return filtered;
  }, {});

  return (
    <React.Fragment>
      <Text display="block" type="display2" id={name}>{ name }</Text>
      <Box marginBottom="xxs" />
      { description && <Text type="body">{ description }</Text> }
      <Box marginBottom="xl" />

      <TokenReferenceTable tokens={filteredTokens} type={type} semantic={semantic} />
    </React.Fragment>
  );
};

export default TokenCategory;
