const store = (() => {
  try {
    const { localStorage } = window;
    const x = '__test__';
    localStorage.setItem(x, x);
    localStorage.removeItem(x);
    return localStorage;
  } catch {
    return null;
  }
})();

export default {
  get: store ? store.getItem.bind(store) : () => {},
  set: store ? store.setItem.bind(store) : () => {},
  clear: store ? store.removeItem.bind(store) : () => {},
};
