import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Text from '../../Text';
import Button from '../../Button';
import ChevronLeftIcon from '../../Icons/ChevronLeftBig';
import Box from '../../Box';
import * as constants from '../../constants';
import List, { ListItem, ListContent } from '../../List';
import { sortItems, itemPropShape, handleMouseDown } from '../utils';
import { itemCssBackgroundImage } from '../ItemImage';
import NavBadge from '../NavBadge';
import styles from './mobileStyles.scss';

const MobileSubMenu = ({
  rootItem,
  goBack,
  logEvent,
  onNavItemClick,
}) => {
  if (!rootItem || !rootItem.label) return null;

  const itemHeroStyles = {
    backgroundImage: itemCssBackgroundImage(rootItem.banner),
    backgroundColor: rootItem.color,
  };

  return (
    <Fragment>
      <Box
        style={itemHeroStyles}
        className={styles.itemHero}
      >
        <Box
          display="flex"
          flexDirection="column"
          padding="xl"
          paddingLeft="m"
          paddingBottom="m"
          className={styles.itemHeroOverlay}
        >
          <Button
            strong
            circle
            onClick={goBack}
            elevation={constants.ELEVATION_LOW}
            iconBefore={<ChevronLeftIcon />}
          />
          <Box paddingTop="xs" />
          <Text type="display3" display="block">
            {rootItem.label}
          </Text>
        </Box>
      </Box>
      <Box
        element="nav"
        paddingBottom="xl"
      >
        <List
          relaxed
          divided
        >
          {
            (Array.isArray(rootItem.items) && !!rootItem.items.length)
            && [...rootItem.items].sort(sortItems).map((item) => {
              const actionUrl = get(item, 'action.parameters.url', null);

              return (
                <ListItem
                  key={`global-nav_${item.id}`}
                  element="a"
                  href={actionUrl}
                  onMouseDown={() => {
                    if (onNavItemClick) onNavItemClick(item, rootItem);
                    handleMouseDown(actionUrl, logEvent);
                  }}
                >
                  <ListContent>
                    <Box paddingLeft="m">
                      <Text type="body">
                        <Box display="flex" alignItems="center" flexWrap="wrap">
                          {item.label}
                          <NavBadge badge={item.badge} />
                        </Box>
                      </Text>
                    </Box>
                  </ListContent>
                </ListItem>
              );
            })
          }
        </List>
      </Box>
    </Fragment>
  );
};

MobileSubMenu.propTypes = {
  // eslint-disable-next-line react/no-typos
  rootItem: itemPropShape.isRequired,
  goBack: PropTypes.func.isRequired,
  logEvent: PropTypes.func,
  onNavItemClick: PropTypes.func,
};

MobileSubMenu.defaultProps = {
  logEvent: () => {},
};

export default MobileSubMenu;
