import React from 'react';
import State from '@reach/component-component';
import { parse as generateDocs, resolver } from 'react-docgen';
import * as constants from '../constants';
import Box from '../Box';
import StarIcon from '../Icons/Star';


import * as ButtonToggleExports from '.';

const { default: ButtonToggle } = ButtonToggleExports;

// eslint-disable-next-line
import ButtonSrc from '!raw-loader!./';

const docs = generateDocs(ButtonSrc, resolver.findAllExportedComponentDefinitions);

const buttonToggleInteractive = {
  name: 'Toggling',
  description: `
    Use the onClick prop to listen for clicks and toggle the ButtonToggle's active prop
  `,
  render: () => (
    <State initialState={{ active: false }}>
      {
        ({ setState, state }) => (
          <ButtonToggle active={state.active} onClick={() => setState({ active: !state.active })}>
            { state.active ? 'Following' : 'Follow' }
          </ButtonToggle>
        )
      }
    </State>
  ),
  code: `
<State initialState={{ active: false }}>
  {
    ({ setState, state }) => (
      <ButtonToggle active={state.active} onClick={() => setState({ active: !state.active })}>
        { state.active ? 'Following' : 'Follow' }
      </ButtonToggle>
    )
  }
</State>
  `,
};

const toggleVarieties = {
  name: 'Variations',
  description: `
    ButtonToggle supports most of the same properties as the <strong>Button</strong> component
  `,
  render: () => (
    <State initialState={{ active: false, loading: false }}>
      {
        ({ setState, state }) => (
          <Box display="flex">
            <ButtonToggle
              active={state.active}
              onClick={() => setState({ active: !state.active })}
              circle
              loading={state.loading}
            >
              { state.active ? 'O' : 'X' }
            </ButtonToggle>
            <Box padding="xxs" />
            <ButtonToggle
              active={state.active}
              onClick={() => setState({ active: !state.active })}
              disabled
              loading={state.loading}
            >
              Disabled
            </ButtonToggle>
            <Box padding="xxs" />
            <ButtonToggle
              active={state.active}
              onClick={() => {
                setState({ loading: true });

                setTimeout(() => {
                  setState({ active: !state.active, loading: false });
                }, 1000);
              }}
              iconBefore={<StarIcon size={constants.SMALL} />}
              loading={state.loading}
            >
              { state.active ? 'Favourite' : 'Favourited' }
            </ButtonToggle>
          </Box>
        )
      }
    </State>
  ),
  code: `
<State initialState={{ active: false, loading: false }}>
  {
    ({ setState, state }) => (
      <Box display="flex">
        <ButtonToggle
          active={state.active}
          onClick={() => setState({ active: !state.active })}
          circle
          loading={state.loading}
        >
          { state.active ? 'O' : 'X' }
        </ButtonToggle>
        <Box padding="xxs" />
        <ButtonToggle
          active={state.active}
          onClick={() => setState({ active: !state.active })}
          disabled
          loading={state.loading}
        >
          Disabled
        </ButtonToggle>
        <Box padding="xxs" />
        <ButtonToggle
          active={state.active}
          onClick={() => {
            setState({ loading: true });

            setTimeout(() => {
              setState({ active: !state.active, loading: false });
            }, 1000);
          }}
          iconBefore={<StarIcon size={constants.SMALL} />}
          loading={state.loading}
        >
          { state.active ? 'Favourite' : 'Favourited' }
        </ButtonToggle>
      </Box>
    )
  }
</State>
  `,
};

const Demo = {
  title: ButtonToggle.displayName,
  description: `
    ButtonToggles are used when the user needs to quickly toggle between states.
  `,
  docGen: docs,
  slug: 'button-toggle',
  exports: Object.keys(ButtonToggleExports),
  examples: [
    buttonToggleInteractive,
    toggleVarieties,
  ],
};

export default Demo;
