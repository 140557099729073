import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import posed from 'react-pose';
import { get } from 'lodash';
import useTracking, { trackingShape } from '../hooks/useTracking';
import Overlay from '../Modal/ModalOverlay';
import _styles from './styles.scss';
import * as constants from '../constants';

const sizeConstants = {
  [constants.LARGE]: 100,
  [constants.MEDIUM]: 66,
  [constants.SMALL]: 33,
};

const reducedMotion = typeof window !== 'undefined'
  && typeof window.matchMedia === 'function'
  && window.matchMedia('(prefers-reduced-motion)').matches;

const transition = {
  duration: reducedMotion ? 0 : 300,
  ease: [0.17, 0.99, 0.43, 1.08],
};

// TODO can this use:
// DesignSystemConsumer -> theme -> tokens -> --ds-spacing-xx
const SCREEN_EDGE_BUFFER = 16 * 4;

const Fade = posed.div({
  visible: {
    transition: {
      ...transition,
      delay: reducedMotion ? 0 : 100,
    },
    x: ({ from }) => {
      if (from === constants.LEFT) return -(SCREEN_EDGE_BUFFER);
      if (from === constants.RIGHT) return (SCREEN_EDGE_BUFFER);
      return undefined;
    },
    y: ({ from }) => {
      if (from === constants.TOP) return -(SCREEN_EDGE_BUFFER);
      if (from === constants.BOTTOM) return (SCREEN_EDGE_BUFFER);
      return undefined;
    },
  },
  hidden: {
    transition,
    x: ({ from, fadeRef }) => {
      if (get(fadeRef, 'current.offsetWidth') === undefined) return undefined;
      const elWidth = fadeRef.current.offsetWidth;
      const elOffset = elWidth + 80;
      if (from === constants.LEFT) return -elOffset;
      if (from === constants.RIGHT) return elOffset;
      return undefined;
    },
    y: ({ from, fadeRef }) => {
      if (get(fadeRef, 'current.offsetHeight') === undefined) return undefined;
      const elHeight = fadeRef.current.offsetHeight;
      const elOffset = elHeight * 1.05;
      if (from === constants.TOP) return -elOffset;
      if (from === constants.BOTTOM) return elOffset;
      return undefined;
    },
  },
});

const Drawer = ({
  children,
  from,
  size,
  styles,
  fit,
  innerRef,
  tracking,
  ...props
}) => {
  const fadeRef = React.createRef();
  const sizeStyles = {};

  if ([constants.LEFT, constants.RIGHT].includes(from)) {
    sizeStyles[fit ? 'maxWidth' : 'width'] = `calc(${sizeConstants[size]}% + ${SCREEN_EDGE_BUFFER}px)`;
  }
  if ([constants.TOP, constants.BOTTOM].includes(from)) {
    sizeStyles[fit ? 'maxHeight' : 'height'] = `calc(${sizeConstants[size]}% + ${SCREEN_EDGE_BUFFER}px)`;
  }

  const { track, TrackingContextProvider } = useTracking(tracking, 'Drawer');

  return (
    <TrackingContextProvider>
      <Overlay
        {...props}
        ref={innerRef}
        attach={from}
        overflowHidden
        hideCloseButton
        track={track}
        delayClose={reducedMotion ? 0 : 200}
      >
        {
          args => (
            <Fade
              ref={fadeRef}
              fadeRef={fadeRef}
              from={from}
              initialPose="hidden"
              pose={args.closing || !args.open ? 'hidden' : 'visible'}
              className={cnames(styles.drawer, styles[from])}
              style={{
                ...sizeStyles,
                '--ds-js-drawer-screen-edge-buffer': `${SCREEN_EDGE_BUFFER}px`,
              }}
            >
              { typeof children === 'function' ? children(args) : children }
            </Fade>
          )
        }
      </Overlay>
    </TrackingContextProvider>
  );
};

/* eslint-disable react/require-default-props */
Drawer.propTypes = {
  /**
    * The content of the Drawer
    */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  /**
    * The direction the drawer will enter from
    */
  from: PropTypes.oneOf([
    constants.LEFT,
    constants.RIGHT,
    constants.TOP,
    constants.BOTTOM,
  ]),
  /**
    * The width/height of the Drawer
    */
  size: PropTypes.oneOf([
    constants.LARGE,
    constants.MEDIUM,
    constants.SMALL,
  ]),
  /**
    * Called when the user dismisses the Drawer
    */
  onCloseRequested: PropTypes.func,
  /**
    * A title for screen readers to provide context about the Drawer's contents / purpose.
    */
  accessibleTitle: PropTypes.string,
  /**
    * Use instead of trigger to control the Drawer with external state
    */
  open: PropTypes.bool,
  /**
    * A render function. Provides an onClick handler so you can build a
    * trigger UI that will open the Drawer when clicked.
    */
  trigger: PropTypes.func,
  /**
    * Fits the drawer to it's contents ignoring width/height props
    */
  fit: PropTypes.bool,
  /**
    * The CSS classNames for this component. NEVER manually specify this prop
    */
  styles: PropTypes.shape({}),
  /**
    * Tracking configuration for auto tracking
    */
  tracking: trackingShape,
  /**
    * Allows clicks outside of the active Drawer, which is disabled by default
    */
  allowClicksOutsideWhenActive: PropTypes.bool,
};
/* eslint-enable react/require-default-props */

Drawer.defaultProps = {
  from: constants.BOTTOM,
  size: constants.LARGE,
  styles: _styles,
  fit: false,
  tracking: null,
};

Drawer.displayName = 'Drawer';

export default Drawer;
