import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import Box from '../Box';
import useTracking, { trackingShape } from '../hooks/useTracking';
import styles from './styles.scss';

const Grid = ({ children, columns, condensed, tracking }) => {
  const { TrackingContextProvider } = useTracking(tracking, 'Grid');

  const classnames = cnames(styles.grid, {
    [styles.condensed]: condensed,
  });

  if (!children) return null;

  return (
    <TrackingContextProvider>
      <Box
        className={classnames}
        style={{ gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))` }}
      >
        {children}
      </Box>
    </TrackingContextProvider>
  );
};

Grid.displayName = 'Grid';

Grid.propTypes = {
  /**
    * Items to be display in the grid
    */
  children: PropTypes.node.isRequired,
  /**
    * The number of columns in the grid
    */
  columns: PropTypes.number,
  /**
    * When true, items in the grid are closer to one another
    */
  condensed: PropTypes.number,
  /**
    * Tracking configuration for auto tracking
    */
  tracking: trackingShape,
};

Grid.defaultProps = {
  columns: 4,
  tracking: null,
};

export default Grid;
