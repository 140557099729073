import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import Color from 'color';
import TickIcon from '../Icons/Tick';
import * as constants from '../constants';
import _styles from './styles.scss';

const hash = (hexColor) => {
  if (!hexColor) return hexColor;
  return hexColor.includes('#') ? hexColor : `#${hexColor}`;
};

const determineStyles = (userColor, imageUrl) => {
  const backgroundColorStyle = { backgroundColor: userColor };
  const backgroundImageStyle = { backgroundImage: `url(${imageUrl})` };

  if (typeof imageUrl === 'string' && imageUrl !== '') {
    return { ...backgroundColorStyle, ...backgroundImageStyle };
  }

  return backgroundColorStyle;
};

// copied the luminosity value from Shop:
// https://github.com/redbubble/shop/blob/32a6bdfecf650b41810e2f9608dc68daac6e7d82/app/shared/components/ColorSwatch/index.js#L20
const isLight = color => color.luminosity() > 0.8;

const darken = color => color.darken(0.2);

const ColorSwatch = ({
  hexColor: oldHexColor,
  color: userColor,
  imageUrl,
  bordered,
  selected,
  ticked,
  fluid,
  size,
  onClick,
  onBlur,
  onFocus,
  styles,
}) => {
  let rawColor;
  let color;
  try {
    rawColor = hash(oldHexColor) || userColor;
    color = Color(rawColor);
  } catch (e) {
    console.log(e);
    return null;
  }

  const light = isLight(color);

  const swatchStyles = determineStyles(rawColor, imageUrl);
  const classNames = cnames(styles.colorSwatch, {
    [styles.bordered]: bordered || light,
    [styles[size]]: size,
    [styles.fluid]: fluid,
    [styles.selected]: selected && !ticked,
    // [styles.selectedDark]: !ticked && selected && light,
    // [styles.selectedLight]: !ticked && selected && !light,
    [styles.tickedDark]: !selected && ticked && light,
    [styles.tickedLight]: !selected && ticked && !light,
  });

  const iconSize = ({
    [constants.SMALL]: constants.MEDIUM,
    [constants.MEDIUM]: constants.LARGE,
    [constants.LARGE]: constants.XLARGE,
  })[size];

  return (
    <button
      type="button"
      className={classNames}
      style={swatchStyles}
      onClick={onClick}
      onBlur={onBlur}
      onFocus={onFocus}
    >
      {ticked && (
        // This <div> creates the little border around the Tick icon
        <div style={{ border: `var(--ds-size-border-width-small) solid ${light ? constants.COLOR_SECONDARY : constants.COLOR_WHITE}`, borderRadius: 'var(--ds-radius-pill)' }}>
          <TickIcon
            size={iconSize}
            color={light ? constants.COLOR_SECONDARY : constants.COLOR_WHITE}
          />
        </div>
      )}
      {selected && (
        <div
          className={styles.focus}
          style={{ borderColor: darken(color) }}
        />
      )}
    </button>
  );
};

ColorSwatch.propTypes = {
  color: PropTypes.string,
  hexColor: PropTypes.string,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  imageUrl: PropTypes.string,
  bordered: PropTypes.bool,
  selected: PropTypes.bool,
  ticked: PropTypes.bool,
  styles: PropTypes.shape({}),
  fluid: PropTypes.bool,
  size: PropTypes.oneOf([constants.SMALL, constants.MEDIUM, constants.LARGE]),
};

ColorSwatch.defaultProps = {
  color: null,
  hexColor: null,
  onClick: null,
  onBlur: null,
  onFocus: null,
  selected: false,
  ticked: false,
  bordered: false,
  imageUrl: '',
  fluid: false,
  size: constants.SMALL,
  styles: _styles,
};

ColorSwatch.displayName = 'ColorSwatch';

export default ColorSwatch;
