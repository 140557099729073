import React from 'react';
import PropTypes from 'prop-types';
import styles from './TextContainer.css';

const TextContainer = ({ children }) => {
  return (
    <div className={styles.textContainer}>{children}</div>
  );
};

TextContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TextContainer;
