import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import * as ListExports from '.';
import Text from '../Text';
import TextLink from '../TextLink';
import Image from '../Image';
import CheckDiscIcon from '../Icons/CheckDisc';
import CrossDiscIcon from '../Icons/CrossDisc';
import Box from '../Box';
import * as constants from '../constants';

// eslint-disable-next-line
import ListSrc from '!raw-loader!./';

const { default: List, ListItem, ListMedia, ListContent } = ListExports;
const docs = generateDocs(ListSrc, resolver.findAllExportedComponentDefinitions);

const imageSrc = 'http://placekitten.com/400/400';

const basicExample = {
  name: 'Text lists',
  description: 'Regular, bulleted, ordered and lists with custom bullets.',
  render: responsive => (
    <Box
      display="flex"
      flexWrap="wrap"
      flexDirection={responsive.desktop ? 'row' : 'column'}
    >
      <Box marginRight="m">
        <List>
          <ListItem>Milk</ListItem>
          <ListItem>Cheese</ListItem>
          <ListItem>Bread</ListItem>
          <ListItem>Eggs</ListItem>
        </List>
      </Box>

      <Box marginRight="m">
        <List bulleted>
          <ListItem>
            Milk
            <List bulleted>
              <ListItem>Milk</ListItem>
              <ListItem>Cheese</ListItem>
              <ListItem>Bread</ListItem>
              <ListItem>Eggs</ListItem>
            </List>
          </ListItem>
          <ListItem>Cheese</ListItem>
          <ListItem>Bread</ListItem>
          <ListItem>Eggs</ListItem>
        </List>
      </Box>

      <Box marginRight="m">
        <List ordered>
          <ListItem>Milk</ListItem>
          <ListItem>Cheese</ListItem>
          <ListItem>Bread</ListItem>
          <ListItem>Eggs</ListItem>
        </List>
      </Box>

      <Box>
        <List bulletMedia={<CrossDiscIcon color="#dc305b" />}>
          <ListItem>Milk</ListItem>
          <ListItem>Toast</ListItem>
          <ListItem bulletMedia={<CheckDiscIcon color="#47CCCC" />}>Vegemite</ListItem>
          <ListItem>Avocado</ListItem>
        </List>
      </Box>
    </Box>
  ),
  code: responsive => `
<Box
  display="flex"
  flexDirection="${responsive.desktop ? 'column' : 'row'}"
>
  <Box marginRight="m">
    <List>
      <ListItem>Milk</ListItem>
      <ListItem>Cheese</ListItem>
      <ListItem>Bread</ListItem>
      <ListItem>Eggs</ListItem>
    </List>
  </Box>

  <Box marginRight="m">
    <List bulleted>
      <ListItem>
        Milk
        <List bulleted>
          <ListItem>Milk</ListItem>
          <ListItem>Cheese</ListItem>
          <ListItem>Bread</ListItem>
          <ListItem>Eggs</ListItem>
        </List>
      </ListItem>
      <ListItem>Cheese</ListItem>
      <ListItem>Bread</ListItem>
      <ListItem>Eggs</ListItem>
    </List>
  </Box>

  <Box marginRight="m">
    <List ordered>
      <ListItem>Milk</ListItem>
      <ListItem>Cheese</ListItem>
      <ListItem>Bread</ListItem>
      <ListItem>Eggs</ListItem>
    </List>
  </Box>

  <Box>
    <List bulletMedia={<CrossDiscIcon color="#dc305b" />}>
      <ListItem>Milk</ListItem>
      <ListItem>Toast</ListItem>
      <ListItem bulletMedia={<CheckDiscIcon color="#47CCCC" />}>Vegemite</ListItem>
      <ListItem>Avocado</ListItem>
    </List>
  </Box>
</Box>
  `,
};

const relaxedExample = {
  name: 'Relaxed lists',
  description: 'Relaxed lists have more padding between items',
  render: responsive => (
    <Box
      display="flex"
      flexDirection={responsive.desktop ? 'row' : 'column'}
    >
      <Box style={{ width: '300px' }}>
        <List>
          <ListItem>Regular</ListItem>
          <ListItem>List</ListItem>
          <ListItem>Spacing</ListItem>
        </List>
      </Box>
      <Box style={{ width: '300px' }}>
        <List relaxed>
          <ListItem>Relaxed</ListItem>
          <ListItem>List</ListItem>
          <ListItem>Spacing</ListItem>
        </List>
      </Box>
    </Box>
  ),
  code: responsive => `
<Box
  display="flex"
  flexDirection="${responsive.desktop ? 'column' : 'row'}"
>
  <Box style={{ width: '300px' }}>
    <List>
      <ListItem>Regular</ListItem>
      <ListItem>List</ListItem>
      <ListItem>Spacing</ListItem>
    </List>
  </Box>
  <Box style={{ width: '300px' }}>
    <List relaxed>
      <ListItem>Relaxed</ListItem>
      <ListItem>List</ListItem>
      <ListItem>Spacing</ListItem>
    </List>
  </Box>
</Box>
  `,
};

const dividedExample = {
  name: 'Divided List',
  description: 'You can separate items in a list with dividers. Divided lists are always relaxed.',
  render: () => (
    <Box style={{ width: '300px' }}>
      <List divided relaxed>
        <ListItem>Milk</ListItem>
        <ListItem>Cheese</ListItem>
        <ListItem>Bread</ListItem>
        <ListItem>Eggs</ListItem>
      </List>
    </Box>
  ),
  code: `
<Box style={{ width: '300px' }}>
  <List divided relaxed>
    <ListItem>Milk</ListItem>
    <ListItem>Cheese</ListItem>
    <ListItem>Bread</ListItem>
    <ListItem>Eggs</ListItem>
  </List>
</Box>
  `,
};

const textExample = {
  name: 'List content',
  description: 'List items can hold any content such as a title and description for each item.',
  render: responsive => (
    <Box
      display="flex"
      flexDirection={responsive.desktop ? 'row' : 'column'}
    >
      <Box style={{ width: '300px' }}>
        <List relaxed>
          <ListItem>
            <Text display="block" type="display6">Title One</Text>
            <Text type="body2">A simple description</Text>
          </ListItem>
          <ListItem>
            <Text display="block" type="display6">Title Two</Text>
            <Text type="body2">A simple description</Text>
          </ListItem>
          <ListItem>
            <Text display="block" type="display6">Title Three</Text>
            <Text type="body2">A simple description</Text>
          </ListItem>
        </List>
      </Box>
      <Box style={{ width: '300px' }}>
        <List relaxed divided>
          <ListItem>
            <Text display="block" type="display6">Title One</Text>
            <Text type="body2">A simple description</Text>
          </ListItem>
          <ListItem>
            <Text display="block" type="display6">Title Two</Text>
            <Text type="body2">A simple description</Text>
          </ListItem>
          <ListItem>
            <Text display="block" type="display6">Title Three</Text>
            <Text type="body2">A simple description</Text>
          </ListItem>
        </List>
      </Box>
    </Box>
  ),
  code: responsive => `
<Box
  display="flex"
  flexDirection="${responsive.desktop ? 'column' : 'row'}"
>
  <Box style={{ width: '300px' }}>
    <List relaxed>
      <ListItem>
        <Text display="block" type="display6">Title One</Text>
        <Text type="body2">A simple description</Text>
      </ListItem>
      <ListItem>
        <Text display="block" type="display6">Title Two</Text>
        <Text type="body2">A simple description</Text>
      </ListItem>
      <ListItem>
        <Text display="block" type="display6">Title Three</Text>
        <Text type="body2">A simple description</Text>
      </ListItem>
    </List>
  </Box>
  <Box style={{ width: '300px' }}>
    <List relaxed divided>
      <ListItem>
        <Text display="block" type="display6">Title One</Text>
        <Text type="body2">A simple description</Text>
      </ListItem>
      <ListItem>
        <Text display="block" type="display6">Title Two</Text>
        <Text type="body2">A simple description</Text>
      </ListItem>
      <ListItem>
        <Text display="block" type="display6">Title Three</Text>
        <Text type="body2">A simple description</Text>
      </ListItem>
    </List>
  </Box>
</Box>
  `,
};

const linkExample = {
  name: 'Links in lists',
  description: 'List items can be rendered as links or with links inside them.',
  render: responsive => (
    <Box
      display="flex"
      flexDirection={responsive.desktop ? 'row' : 'column'}
    >
      <Box style={{ width: '300px' }}>
        <List relaxed>
          {
            [1, 2, 3].map(i => (
              <ListItem key={i}>
                <TextLink href="#href">These are just plain TextLinks</TextLink>
              </ListItem>
            ))
          }
        </List>
      </Box>
      <Box style={{ width: '300px' }}>
        <List relaxed divided>
          {
            [1, 2, 3].map(i => (
              <ListItem element="a" href="#href" key={i}>
                <Text display="block" type="display6">This whole item is a link</Text>
                <Text type="body2">It’s using the element prop to specify an anchor tag.</Text>
              </ListItem>
            ))
          }
        </List>
      </Box>
    </Box>
  ),
  code: responsive => `
<Box
  display="flex"
  flexDirection="${responsive.desktop ? 'column' : 'row'}"
>
  <Box style={{ width: '300px' }}>
    <List relaxed>
      {
        [1, 2, 3].map(i => (
          <ListItem key={i}>
            <TextLink href="#href">These are just plain TextLinks</TextLink>
          </ListItem>
        ))
      }
    </List>
  </Box>
  <Box style={{ width: '300px' }}>
    <List relaxed divided>
      {
        [1, 2, 3].map(i => (
          <ListItem element="a" href="#href" key={i}>
            <Text display="block" type="display6">This whole item is a link</Text>
            <Text type="body2">It’s using the element prop to specify an anchor tag.</Text>
          </ListItem>
        ))
      }
    </List>
  </Box>
</Box>
  `,
};

const arrowExample = {
  name: 'Arrows on list items',
  description: 'List items can be rendered with arrows. This is a good way to indicate links on mobile.',
  render: () => (
    <Box display="flex" style={{ width: '300px' }}>
      <List relaxed divided>
        <ListItem element="a" href="#using" arrow>
          <Text display="block" type="display6">These List Items</Text>
          <Text type="body2">Are using the element prop to specify an anchor tag.</Text>
        </ListItem>
        <ListItem element="a" href="#anchor" arrow>
          <Text display="block" type="display6">These List Items</Text>
          <Text type="body2">Are using the element prop to specify an anchor tag.</Text>
        </ListItem>
        <ListItem element="a" href="#element" arrow>
          <Text display="block" type="display6">These List Items</Text>
          <Text type="body2">Are using the element prop to specify an anchor tag.</Text>
        </ListItem>
      </List>
    </Box>
  ),
  code: `
<Box display="flex" style={{ width: '300px' }}>
  <List relaxed divided>
    <ListItem element="a" href="#using" arrow>
      <Text display="block" type="display6">These List Items</Text>
      <Text type="body2">Are using the element prop to specify an anchor tag.</Text>
    </ListItem>
    <ListItem element="a" href="#anchor" arrow>
      <Text display="block" type="display6">These List Items</Text>
      <Text type="body2">Are using the element prop to specify an anchor tag.</Text>
    </ListItem>
    <ListItem element="a" href="#element" arrow>
      <Text display="block" type="display6">These List Items</Text>
      <Text type="body2">Are using the element prop to specify an anchor tag.</Text>
    </ListItem>
  </List>
</Box>
  `,
};

const richExample = {
  name: 'Rich List content',
  description: 'List items can also hold images.',
  render: responsive => (
    <Box
      display="flex"
      flexDirection={responsive.desktop ? 'row' : 'column'}
    >
      <Box marginRight="m" style={{ width: '300px' }}>
        <List relaxed divided>
          {
            [1, 2, 3].map(i => (
              <ListItem key={i}>
                <ListMedia>
                  <Image roundedCorners size={constants.SMALL} src={imageSrc} />
                </ListMedia>

                <ListContent>
                  <Text display="block" type="display6">Title One</Text>
                  <Text display="block" type="body2">A simple description</Text>
                </ListContent>
              </ListItem>
            ))
          }
        </List>
      </Box>

      <Box style={{ width: '300px' }}>
        <List relaxed divided>
          {
            [1, 2, 3].map(i => (
              <ListItem key={i} element={TextLink} href="#TextLink" arrow>
                <ListMedia>
                  <Image roundedCorners size={constants.TINY} src={imageSrc} />
                </ListMedia>

                <ListContent>
                  <Text>These items are links</Text>
                </ListContent>
              </ListItem>
            ))
          }
        </List>
      </Box>
    </Box>
  ),
  code: responsive => `
<Box
  display="flex"
  flexDirection="${responsive.desktop ? 'column' : 'row'}"
>
  <Box marginRight="m" style={{ width: '300px' }}>
    <List relaxed divided>
      {
        [1, 2, 3].map(i => (
          <ListItem key={i}>
            <ListMedia>
              <Image roundedCorners size={constants.SMALL} src="${imageSrc}" />
            </ListMedia>

            <ListContent>
              <Text display="block" type="display6">Title One</Text>
              <Text display="block" type="body2">A simple description</Text>
            </ListContent>
          </ListItem>
        ))
      }
    </List>
  </Box>

  <Box style={{ width: '300px' }}>
    <List relaxed divided>
      {
        [1, 2, 3].map(i => (
          <ListItem key={i} element={TextLink} href="#TextLink" arrow>
            <ListMedia>
              <Image roundedCorners size={constants.TINY} src="${imageSrc}" />
            </ListMedia>

            <ListContent>
              <Text>These items are links</Text>
            </ListContent>
          </ListItem>
        ))
      }
    </List>
  </Box>
</Box>
  `,
};

const Demo = {
  title: List.displayName,
  docGen: docs,
  description: 'For simple and rich lists of content',
  slug: 'List',
  exports: Object.keys(ListExports),
  examples: [
    basicExample,
    relaxedExample,
    dividedExample,
    textExample,
    linkExample,
    arrowExample,
    richExample,
  ],
};

export default Demo;
