import React from 'react';
import cnames from 'classnames';
import PropTypes from 'prop-types';
import Image from '../Image';
import Box from '../Box';
import HighlightedTickIcon from '../Icons/HighlightedTick';
import * as constants from '../constants';
import styles from './styles.scss';

const ConditionalLink = ({ href, wrapper, children }) => (href ? wrapper(children) : children);

const Avatar = React.memo(React.forwardRef((props, ref) => {
  const {
    bordered,
    loading,
    fluid,
    size,
    highlighted,
    className, // Exclude from ...rest
    style, // Exclude from ...rest
    href,
    ...rest
  } = props;

  const styleProp = {
    borderColor: bordered && bordered.color ? bordered.color : undefined,
  };

  return (
    <ConditionalLink href={href} wrapper={children => <a href={href}>{children}</a>}>
      <Box
        data-testid="ds-avatar"
        display={fluid ? 'block' : 'inline-block'}
        className={
          cnames(
            styles.avatar,
            {
              [styles.bordered]: bordered,
              [styles.loading]: loading,
              [styles[size]]: !!size,
              [styles.fluid]: fluid,
              [styles.highlighted]: highlighted,
            },
          )
        }
        style={styleProp}
        ref={ref}
      >
        {highlighted && (
          <Box
            className={cnames(styles.tick, {
              [styles.tickMedium]: size === constants.MEDIUM,
              [styles.tickLarge]: size === constants.LARGE,
            })}
            data-testid="highlighted-tick-icon"
          >
            <HighlightedTickIcon color="#06F" />
          </Box>
        )}
        <Image
          round
          role="presentation"
          ratio={constants.RATIO_SQUARE}
          fluid={fluid}
          {...rest}
        />
      </Box>
    </ConditionalLink>
  );
}));

Avatar.propTypes = {
  /**
    * HTML <img> src
    */
  src: PropTypes.string.isRequired,
  /**
    * HTML <img> srcSet
    */
  srcSet: PropTypes.string,
  /**
    * Adds a border to the avatar
    */
  bordered: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      color: PropTypes.string,
    }),
  ]),
  /**
    * The Avatar will fill the width of it's parent container
    */
  fluid: PropTypes.bool,
  /**
    * Choose a predefined size for the Avatar width
    */
  size: PropTypes.oneOf([
    constants.TINY,
    constants.XSMALL,
    constants.SMALL,
    constants.MEDIUM,
    constants.LARGE,
  ]),
  /**
    * Renders the Avatar in a loading state
    */
  loading: PropTypes.bool,
  /**
    * Delay avatar loading until it's in view (IntersectionObserver)
    */
  loadOnVisible: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({
    placeholder: PropTypes.string,
    offsetTopBoundaryByPx: PropTypes.number,
  })]),
  /**
  * When set to true, renders avatar with blue border and a tick
  */
  highlighted: PropTypes.bool,
  /**
  * Wraps the Avatar with an <a/> tag allowing for links
  */
  href: PropTypes.string,
};

Avatar.defaultProps = {
  bordered: false,
  fluid: false,
  size: constants.MEDIUM,
  highlighted: false,
  loadOnVisible: false,
  loading: false,
  srcSet: '',
};

Avatar.displayName = 'Avatar';

export default Avatar;
