import React from 'react';
import PropTypes from 'prop-types';

const Contain = ({ width, height, children }) => {
  return <div style={{ width: `${width}px`, height: `${height}px` }}>{ children }</div>;
};

Contain.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.node,
  width: PropTypes.node,
};

Contain.defaultProps = {
  width: null,
  height: null,
};

Contain.displayName = 'Contain';

export default Contain;
