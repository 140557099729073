import React from 'react';
import Box from '../../../react/Box';
import Image from '../../../react/Image';
import Avatar from '../../../react/Avatar';
import Text from '../../../react/Text';

const ArtistAttribution = ({ artist, title, artwork, avatar }) => {
  const imageProps = {
    src: avatar || artwork,
    size: avatar ? 'small' : 'small',
    ratio: 'square',
    roundedCorners: true,
  };

  return (
    <Box display="flex" alignItems="center">
      {
        avatar ? <Avatar {...imageProps} /> : <Image {...imageProps} />
      }
      <Box paddingX="m">
        <Text type="display5" display="block">{title}</Text>
        <Text type="body2" display="block">by {artist}</Text>
      </Box>
    </Box>
  );
};

export default ArtistAttribution;
