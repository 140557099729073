import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import Box from '../Box';

import * as ProgressBarExports from '.';

// eslint-disable-next-line
import ProgressBarSrc from '!raw-loader!.';

const { default: ProgressBar } = ProgressBarExports;
const docs = generateDocs(ProgressBarSrc, resolver.findAllExportedComponentDefinitions);

const basicExample = {
  name: 'Simple Example',
  description: 'Progress bars in different colors',
  overflowVisual: false,
  render: () => (
    <Box>
      <Box marginBottom="m">
        <ProgressBar percentage={40} color="information" />
      </Box>
      <Box marginBottom="m">
        <ProgressBar percentage={50} color="warning" />
      </Box>
      <Box marginBottom="m">
        <ProgressBar percentage={60} color="error" />
      </Box>
      <Box marginBottom="m">
        <ProgressBar percentage={70} color="success" />
      </Box>
    </Box>
  ),
  code: `
<Box>
  <Box marginBottom="m">
    <ProgressBar percentage={40} color="information" />
  </Box>
  <Box marginBottom="m">
    <ProgressBar percentage={50} color="warning" />
  </Box>
  <Box marginBottom="m">
    <ProgressBar percentage={60} color="error" />
  </Box>
  <Box marginBottom="m">
    <ProgressBar percentage={70} color="success" />
  </Box>
</Box>
  `,
};

const Demo = {
  title: ProgressBar.displayName,
  description: 'Progress bars for displaying percentage values.',
  docGen: docs,
  slug: 'progress-bar',
  exports: Object.keys(ProgressBarExports),
  examples: [
    basicExample,
  ],
  notes: `
  `,
};

export default Demo;
