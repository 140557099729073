import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import _styles from './styles.scss';

const CardHeader = ({ children, padded, styles, parentStyles }) => (
  <div
    className={cnames(
      styles.header,
      parentStyles.cardHeader,
      {
        [styles.padded]: padded,
      },
    )}
  >
    { children }
  </div>
);

CardHeader.propTypes = {
  children: PropTypes.node.isRequired,
  padded: PropTypes.bool,
  styles: PropTypes.shape({}),
  parentStyles: PropTypes.shape({}),
};

CardHeader.defaultProps = {
  padded: true,
  styles: _styles,
  parentStyles: undefined,
};

CardHeader.displayName = 'CardHeader';

export default CardHeader;
