import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ItemLink from '../ItemLink';
import { sortItems, itemsPropShape } from '../utils';

const Navigation = ({ items, logEvent }) => (
  <Fragment>
    {
      Array.isArray(items) && !!items.length && (
        <ul>
          {
            [...items].sort(sortItems).map(item => (
              <li
                key={`navigation_${item.id}`}
              >
                <ItemLink
                  action={item.action}
                  tag="a"
                  logEvent={logEvent}
                >
                  {item.label}
                </ItemLink>
                <Navigation items={item.items} />
              </li>
            ))
          }
        </ul>
      )
    }
  </Fragment>
);

const StaticMobileProductNav = ({ items, logEvent }) => {
  /*
    the resultant html here is not intended to be displayed, but
    only be present in the dom for crawlers to discover links should
    they not be able to execute JS
  */

  return (
    <nav style={{ display: 'none' }} aria-hidden="true">
      <Navigation items={items} logEvent={logEvent} />
    </nav>
  );
};

StaticMobileProductNav.defaultProps = {
  items: [],
  logEvent: () => {},
};

StaticMobileProductNav.propTypes = {
  // eslint-disable-next-line react/no-typos
  items: itemsPropShape,
  logEvent: PropTypes.func,
};

export default StaticMobileProductNav;
