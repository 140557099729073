import image from '../assets/glossary.svg';

/* eslint-disable max-len */
const markdown = `
  #

  13” PC Laptop

  15” PC Laptop


  ### A

  a-line dress, a-line dresses

  acrylic block, acrylic blocks

  am/pm

  Adventure Time

  amirite

  art board, art boards

  art print, art prints

  artist-in-residence, artists-in-residence

  athleisure, athleisure wear

  auto-fill

  ### B

  baby t-shirt, baby t-shirts, baby tees, baby’s t-shirt, babies’ t-shirts, babies’ tees

  baby one-piece, baby one-pieces, baby’s one-piece, babies’ one-pieces, one-piece, one-piece, onesie, onesie

  back end (n.), back-end (adj.)

  baseball ¾ sleeve, baseball ¾ sleeve t-shirt, baseball ¾ sleeve tee, baseball t-shirt, baseball tee

  bedroom, bedrooms

  binge-watch

  Black Friday

  Bookstagram (#bookstagram): a collective of book-related influencers who create content on Instagram

  Bookstagrammers: influencers who create book-related content on Instagram

  BookTube: a collective of book-related influencers who create content on YouTube

  BookTubers: influencers who create book-related content on YouTube

  btw: by the way

  Bubblers: Redbubble employees (only to be used internally)


  ### C

  canvas, canvases

  canvas print, canvas prints

  cellphone

  checkbox

  checkmark

  Chinese New Year

  Christmas, Christmas Eve

  classic t-shirt, classic tee

  click-through (adj., n.), click through (v.)

  clip-on case

  color, colors

  color-correcting

  cosplay, cosplayer

  coworker, coworkers

  CS: Customer Success

  CTA, CTAs: call to action

  cuz: because

  Cyber Monday



  ### D

  dabbler

  dashboard

  DEP: Design Engineering Product

  design, designs

  designer, designers

  device case, device cases

  die-cut

  digital art

  digital artist, digital artists

  DIY

  doona, doonas: also known as duvet covers in the US

  dorm, dorms, dorm room, dorm rooms

  double-click

  drawstring bag, drawstring bags

  drop-down (n. adj.), drop down (v.), drop-down menu

  duvet cover, duvet covers: also known as doona (doonas) for non-US markets


  ### E

  Easter, Easter Sunday

  e-commerce

  e.g.: exempli gratia (for example)

  email, emails

  emoji (singular and plural)

  ### F

  Facebook

  fan art

  fanbase

  fanboy, fangirl

  Father’s Day

  fav, favs, faving: short for favorite

  find your thing

  framed print, framed prints

  freestanding

  front end, front-end developer


  ### G

  GIF, GIFs, GIF’d, .gif

  GitHub

  gloss, gloss finish

  gonna

  Google (n.), google (v.), googled, googling

  graphic design

  graphic designer, graphic designers

  graphic t-shirt, graphic tee

  graphic t-shirt dress, graphic tee dress

  gray

  greeting card, greeting cards


  ### H

  Happy Holidays

  Halloween

  handmade

  Hanukkah

  hardcover journal

  hashtag

  heavyweight

  hero: the main image in an email, usually featuring a promo

  high-quality

  hipster

  holiday, holidays

  home decor

  homepage

  homepage banner

  hoodie, hoodies

  HTML, .html

  ### I

  i.e.: id est (that is)

  illustrator, illustrators

  Illustrator, Adobe Illustrator

  independence

  independent

  independent artist, independent artists

  InDesign, Adobe InDesign

  influencer, influencers (never to be used outwardly, only for internal use)

  Instagram

  Instagram Story, Instagram Stories

  internet

  iPad, iPads

  iPad skin, iPad skins

  iPad mini, iPad minis, iPad minis 1, iPad minis 2

  iPad case, iPad cases

  iPad Retina, iPad Retinas, iPad Retina 2, iPad Retina 3

  iPhone, iPhones

  iPhone case, iPhone cases

  iPhone XS / iPhone XS Max

  iPhone XR


  iPhone X

  iPhone 8 / iPhone 8 Plus

  iPhone 7 / iPhone 7 Plus

  iPhone 6s / iPhone 6s Plus, iPhone 6/iPhone 6 Plus

  iPhone SE

  iPhone 5s / iPhone 5, iPhone 5c

  iPhone 4s / iPhone 4

  iPhone wallet, iPhone wallets

  IRL: in real life

  IT: informational technology (IT person)

  ### J

  JPG, .jpg


  ### K

  kids’ t-shirts, kids’ tees

  kiss-cut

  kraft envelope


  ### L


  LaCroix

  laptop, laptops

  laptop skin, laptop skins

  laptop sleeve, laptop sleeves

  leggings: singular and plural

  lightweight

  lightweight hoodie, lightweight hoodies

  lightweight sweatshirt

  login (n., adj.), log in (v.)

  LOL, lol, LOL'd, LOLing

  long t-shirt, long tee

  long sleeve, long sleeves, long sleeve t-shirts, long sleeve tees


  ### M

  MacBook, 12”

  MacBook Air, 11”, 13”, 15”

  MacBook Pro, 13”, 15”

  MacBook Pro Retina, 13”, 15”

  mat board

  matte, matte finish

  medium, mediums

  meme, memes, memeing

  metal print, metal prints

  Merry Christmas

  mini skirt

  mixed media

  mom, moms, mom’s

  Mother’s Day

  mum, mums, mum’s


  ### N

  New Year

  New Year’s Day, New Year’s Eve


  ### O

  obvi: obvious

  OK

  one-piece

  onesie, onesies

  online

  open source, open source software

  opt-in (n., adj.), opt in (v.)

  Orphan Black

  ### P


  P&C: Redbubble’s people and culture team

  PC: personal computer

  PDF, .pdf

  painting, paintings

  painter, painters

  Pantone

  photograph, photographs, photos

  photographer, photographers

  photography

  photographic print, photographic prints, photo print, photo prints

  Photoshop, Adobe Photoshop

  Pinterest, pins, pinners

  plexiglass

  png, .png

  polyester

  polyester poplin

  pop-up (n., adj.), pop up (v.)

  poplin: lightweight cotton with a corded surface

  postcard, postcards

  poster, posters

  print on demand, print-on-demand (adj.), printed on demand (v.)

  premium t-shirt, premium tee

  pullover

  px: abbreviation for pixel (2400×3200px)


  ### Q

  QR code


  ### R

  raglan

  Ramadan

  Redbubble

  Reddit, redditor

  resident artist, resident artists

  Rick and Morty

  right-click

  RTS: return to sender

  ruled, ruled line


  ### S

  Samsung Galaxy

  Samsung Galaxy case, Samsung Galaxy cases

  Samsung Galaxy

  Samsung Galaxy S3

  Samsung Galaxy S4

  Samsung Galaxy S5

  Samsung Galaxy S6

  Samsung Galaxy S6 Edge

  Samsung Galaxy S7

  Samsung Galaxy S7 Edge

  Samsung Galaxy S8

  Samsung Galaxy S8 Plus

  Samsung Galaxy S9

  Samsung Galaxy S9 Plus

  Santa Claus, Santa

  scarf, scarves

  sci-fi

  screensaver

  screencap

  screengrab

  short sleeve, short sleeves

  signup (n., adj.), sign up (v.)

  sitewide

  smartphone

  smartwatch

  snap, snap case, snap cases

  Snapchat

  spandex

  spiral notebook, spiral notebooks

  SO: significant other

  St. Patrick’s Day, St. Paddy’s Day, St. Paddy’s

  St. Nikolaus Day

  startup

  stationary: not moving

  stationery: paper products

  sticker, stickers

  Steven Universe

  streetwear

  studio pouch, studio pouches

  succulent, succulents

  super: if using super as an adjective (i.e. super good) you don’t need to hyphenate

  superfan

  sweatshirt: used to describe long sleeve, fleece tops in the U.S.

  sweater: used to describe long sleeve, fleece tops in Australia and the U.K.

  sync


  ### T

  tag, tags

  tank top, tank tops

  tapestry, tapestries

  tbh: to be honest

  tech, technology

  Thanksgiving

  third party printers: the people who make and print the products available on Redbubble.

  throw pillow, pillow: also called cushion

  time lapse (n.), time-lapse (adj.)

  tl;dr: too long didn’t read

  tote bag, tote bags

  tough, tough case, tough cases

  T-shirt, tee

  tri-blend t-shirt, tri-blend tee

  Twitter (n.), tweet (n.), tweet (v.), tweeting (v.)


  ### U

  UK: United Kingdom

  unisex

  URL

  USA: United States of America

  username


  ### V

  Valentine’s Day, V-Day

  v-neck, v-necks, v-neck t-shirt, v-neck t-shirts, v-neck tee, v-neck

  vinyl

  voiceover, vo


  ### W

  wall art

  wall hanging, wall hangings

  wall tapestry, wall tapestries

  web comic

  website

  whoa

  WiFi

  WIMO: where is my order


  ### Y

  Yahoo

  yep, yup

  YouTube

  YouTuber: an influencer who produces content for YouTube


  ### Z


  zip code

  zipped hoodie, zipped hoodies

`;

const Glossary = {
  markdown,
  image,
  title: 'Glossary',
  subTitle: 'A brief dictionary',
  slug: 'glossary',
};

export default Glossary;
