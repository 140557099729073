import React from 'react';
import Box from '../../../../../../react/Box';

export default () => (
  <Box display="flex" flexDirection="column" flex="1">
    <Box
      padding="xl"
      flex="1"
      style={{ backgroundColor: 'var(--ds-color-background-overlay-transparent)' }}
    />
    <Box
      padding="xl"
      flex="1"
      style={{ backgroundColor: 'var(--ds-color-background-overlay)', borderRadius: '0 0 var(--ds-radius-medium) var(--ds-radius-medium)' }}
    />
  </Box>
);
