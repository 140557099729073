import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import get from 'lodash/get';
import { InView } from 'react-intersection-observer';
import Box from '../Box';
import styles from './styles.scss';
import * as constants from '../constants';

export const TINY = 'tiny';
export const SMALL = 'small';
export const MEDIUM = 'medium';
export const LARGE = 'large';
export const EXTRA_LARGE = 'extraLarge';
export const JUMBO = 'jumbo';

const SIZES = [TINY, SMALL, MEDIUM, LARGE, EXTRA_LARGE, JUMBO];
const RATIO_TYPES = [constants.RATIO_SQUARE, constants.RATIO_PORTRAIT, constants.RATIO_LANDSCAPE];
const RATIO_MODES = [constants.RATIO_COVER, constants.RATIO_CONTAIN];
const RATIO_PROP_TYPE = PropTypes.oneOfType([
  PropTypes.oneOf(RATIO_TYPES),
  PropTypes.shape({
    type: PropTypes.oneOf(RATIO_TYPES),
    mode: PropTypes.oneOf(RATIO_MODES),
  }),
]);

const DEFAULT_PLACEHOLDER = 'data:image/gif;base64,R0lGODdhFQAXAPAAANba3wAAACwAAAAAFQAXAAACFISPqcvtD6OctNqLs968+w+GolUAADs=';

const DEFAULT_OFFSET_TOP_BOUNDARY_BY_PX = 0;

export const generatePlaceholderSrc = ({ width, height }) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  canvas.getContext('2d').fillStyle = '#d6dadf'; // TODO tokenize this
  canvas.getContext('2d').fillRect(0, 0, width, height);

  return canvas.toDataURL('image/png');
};

export const getTypeAndMode = (ratio) => {
  if (!ratio) return null;

  if (RATIO_TYPES.includes(ratio)) {
    return [ratio, constants.RATIO_COVER];
  }

  if (typeof ratio !== 'object') return null;

  const type = get(ratio, 'type');
  const mode = get(ratio, 'mode', constants.RATIO_COVER);

  return type ? [type, mode] : null;
};

const WithRatio = ({
  children,
  size,
  ratio,
  roundedCorners,
}) => {
  const typeAndMode = getTypeAndMode(ratio);

  if (!typeAndMode) return children;

  const [type, mode] = typeAndMode;

  const ratioClasses = cnames(styles.ratioOuter, styles[mode], styles[type], {
    [styles.rounded]: roundedCorners,
  });

  return (
    <Box className={size && styles[size]}>
      <Box className={ratioClasses}>
        <Box className={styles.ratioInner}>
          { children }
        </Box>
      </Box>
    </Box>
  );
};

WithRatio.propTypes = {
  ratio: RATIO_PROP_TYPE,
};

WithRatio.defaultProps = {
  ratio: null,
};

class Image extends Component {
  constructor(props) {
    super(props);

    this.state = { isInView: false };

    this.handleInView = this.handleInView.bind(this);
  }

  handleInView(isInView) {
    if (isInView) {
      this.setState({ isInView });
    }
  }

  render() {
    const {
      src,
      size,
      fluid,
      roundedCorners,
      round,
      className,
      loadOnVisible,
      srcSet,
      ratio,
      ...rest
    } = this.props;

    const { isInView } = this.state;

    const classNames = cnames(styles.image, className, {
      [styles[size]]: !!size,
      [styles.rounded]: roundedCorners,
      [styles.fluid]: fluid,
      [styles.round]: round,
    });

    const isServer = typeof window === 'undefined';

    const srcProps = { src };

    if (srcSet !== '') {
      srcProps.srcSet = srcSet;
    }

    const loadOnVisibleDefaultOptions = {
      enabled: false,
      placeholder: DEFAULT_PLACEHOLDER,
      offsetTopBoundaryByPx: DEFAULT_OFFSET_TOP_BOUNDARY_BY_PX,
    };

    let loadOnVisibleOptions = {};

    if (loadOnVisible) {
      loadOnVisibleOptions = {
        ...loadOnVisibleDefaultOptions,
        enabled: true,
      };

      if (typeof loadOnVisible === 'object') {
        loadOnVisibleOptions = { ...loadOnVisibleOptions, ...loadOnVisible };
      }
    }

    if (loadOnVisibleOptions.enabled && (!isInView || isServer)) {
      srcProps.src = loadOnVisibleOptions.placeholder;

      if (srcSet) {
        delete srcProps.srcSet;
      }
    }

    return (
      <WithRatio
        ratio={ratio}
        roundedCorners={this.props.roundedCorners}
        size={size}
        {...rest}
      >
        { loadOnVisibleOptions.enabled && !isServer
          ? (
            <InView
              tag="img"
              rootMargin={`0px 0px ${loadOnVisibleOptions.offsetTopBoundaryByPx}px 0px`}
              onChange={this.handleInView}
              className={classNames}
              {...srcProps}
              {...rest}
            />
          ) : (
            /* eslint-disable jsx-a11y/alt-text */
            <img
              className={classNames}
              {...srcProps}
              {...rest}
            />
            /* eslint-enable jsx-a11y/alt-text */
          )
        }
      </WithRatio>
    );
  }
}

Image.propTypes = {
  /**
    * HTML <img> src
    */
  src: PropTypes.string.isRequired,
  /**
    * HTML <img> srcSet
    */
  srcSet: PropTypes.string,
  /**
    * Choose a predefined size for the Image width
    */
  size: PropTypes.oneOf(SIZES),
  /**
    * The Image will fill the width of it's parent container
    */
  fluid: PropTypes.bool,
  /**
    * Round the corners of the image slightly
    */
  roundedCorners: PropTypes.bool,
  /**
    * Make the image render as a circle
    */
  round: PropTypes.bool,
  /**
    * Lock the image to a given aspect ratio
    */
  ratio: RATIO_PROP_TYPE,
  className: PropTypes.string,
  /**
    * Delay image loading until it's in view (IntersectionObserver)
    */
  loadOnVisible: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({
    placeholder: PropTypes.string,
    offsetTopBoundaryByPx: PropTypes.number,
  })]),
};

Image.defaultProps = {
  size: null,
  fluid: false,
  roundedCorners: false,
  round: false,
  ratio: null,
  className: '',
  loadOnVisible: false,
  srcSet: '',
};

Image.displayName = 'Image';

export default Image;
