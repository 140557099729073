const hexToRgb = require('./hexToRgb');

/**
  * This function will return an rgba() value which
  * will **look** like the `desired` color, ONLY when
  * used on `background`. The returned color will be
  * semi-transparent, but no more transparent than 0.6 opacity
  */
function alphaize(desired, background = '#ffffff') {
  const { r: r1, g: g1, b: b1 } = hexToRgb(background);
  const { r: r2, g: g2, b: b2 } = hexToRgb(desired);

  let alpha = 0.6;
  let r = -1;
  let g = -1;
  let b = -1;

  while (alpha < 1 && (r < 0 || g < 0 || b < 0 || r > 255 || g > 255 || b > 255)) {
    // eslint-disable-next-line operator-assignment
    alpha = (alpha + 1 / 256).toFixed(2);
    const inv = 1 / alpha;
    r = Math.round(r2 * inv + r1 * (1 - inv));
    g = Math.round(g2 * inv + g1 * (1 - inv));
    b = Math.round(b2 * inv + b1 * (1 - inv));
  }

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

module.exports = alphaize;
