import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TextLink from '../../TextLink';
import Box from '../../Box';
import Text from '../../Text';
import List, { ListItem } from '../../List';
import { appendLocaleToUrl } from '../utils';

const Footer = ({ locale }) => {
  const baseUrl = appendLocaleToUrl('https://www.redbubble.com', locale);
  const baseHelpUrl = appendLocaleToUrl('https://help.redbubble.com/hc', locale);

  return (
    <List>
      <ListItem>
        <Box padding="m" paddingBottom="xxs" paddingTop="xxs">
          <TextLink
            subtle
            href={`${baseHelpUrl}/articles/203024315`}
            rel="nofollow"
          >
            <Text type="body2">
              <FormattedMessage defaultMessage="Delivery" />
            </Text>
          </TextLink>
        </Box>
      </ListItem>
      <ListItem>
        <Box padding="m" paddingBottom="xxs" paddingTop="xxs">
          <TextLink
            subtle
            href={`${baseHelpUrl}/articles/5857691382164`}
            rel="nofollow"
          >
            <Text type="body2">
              <FormattedMessage defaultMessage="Returns" />
            </Text>
          </TextLink>
        </Box>
      </ListItem>
      <ListItem>
        <Box padding="m" paddingBottom="xxs" paddingTop="xxs">
          <TextLink
            subtle
            href={`${baseHelpUrl}`}
            rel="nofollow"
          >
            <Text type="body2">
              <FormattedMessage defaultMessage="Help Center" />
            </Text>
          </TextLink>
        </Box>
      </ListItem>
      <ListItem>
        <Box padding="m" paddingBottom="xxs" paddingTop="xxs">
          <TextLink
            subtle
            href={`${baseUrl}/about/selling`}
            rel="nofollow"
          >
            <Text type="body2">
              <FormattedMessage defaultMessage="Sell Your Art" />
            </Text>
          </TextLink>
        </Box>
      </ListItem>
      <ListItem>
        <Box paddingBottom="xl" />
      </ListItem>
    </List>
  );
};

Footer.propTypes = {
  locale: PropTypes.string,
};

Footer.defaultProps = {
  locale: 'en',
};

export default Footer;
