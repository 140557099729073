import React from 'react';
import PropTypes from 'prop-types';
import { Route, NavLink } from 'react-router-dom';
import Text from '../../../react/Text';
import styles from './styles.css';

const slugify = str => str.toLowerCase().split(' ').join('-');
const getPath = child => (typeof child.props.path === 'undefined' ? child.props.label : child.props.path);

const TabBar = ({ children, rootPath }) => {
  return (
    <React.Fragment>
      <div className={styles.bar}>
        <div className={styles.tabs}>
          {
            React.Children.map(children, (child) => {
              if (!child) return null;

              return React.cloneElement(child, {
                to: {
                  pathname: `${rootPath}/${slugify(getPath(child))}`,
                  state: { preventScrollToTop: true },
                },
              });
            })
          }
        </div>
      </div>
      <div className={styles.tabBody}>
        {
          React.Children.map(children, (child) => {
            if (!child) return null;

            return (
              <Route
                path={`${rootPath}/${slugify(getPath(child))}`}
                render={() => (
                  <React.Fragment>
                    { child.props.children }
                  </React.Fragment>
                )}
              />
            );
          })
        }
      </div>
    </React.Fragment>
  );
};

TabBar.propTypes = {
  children: PropTypes.node.isRequired,
  rootPath: PropTypes.string.isRequired,
};

export const Tab = ({ children, label, ...props }) => (
  <NavLink
    activeClassName={styles.active}
    className={styles.tab}
    {...props}
  >
    <Text type="display5">
      { label }
    </Text>
  </NavLink>
);

Tab.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TabBar;
