import React, { useState, useEffect, useRef } from 'react';
import AriaModal from 'react-aria-modal';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import posed from 'react-pose';
import { RemoveScroll } from 'react-remove-scroll';
import _styles from './styles.scss';
import * as constants from '../../constants';
import { EVENT_DISMISS, EVENT_OPEN } from '../../hooks/useTracking';

export const events = { EVENT_DISMISS, EVENT_OPEN };

const BLANKET_FADE_DURATION = 200;

const Blanket = posed.div({
  in: {
    backgroundColor: 'rgba(140, 149, 165, 0.6)',
    transition: {
      backgroundColor: {
        type: 'tween',
        duration: BLANKET_FADE_DURATION,
      },
    },
  },
  out: {
    backgroundColor: 'rgba(140, 149, 165, 0)',
    transition: () => ({
      backgroundColor: {
        type: 'tween',
        duration: 0,
      },
    }),
  },
});

const stopPropagation = (e) => {
  e.stopPropagation();
  return false;
};

export const OverlayContext = React.createContext({});

const ModalOverlay = ({
  children,
  open: controlledOpen,
  trigger,
  accessibleTitle,
  attach,
  styles,
  modalVisible,
  hideCloseButton,
  overflowHidden,
  onCloseRequested,
  delayClose,
  track,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [closing, setClosing] = useState(false);
  const focusTrapPortalTarget = useRef(null);

  useEffect(() => {
    if (controlledOpen || open) {
      track(EVENT_OPEN);
    }
  }, [controlledOpen, open]);

  const handleTriggerClick = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    track(EVENT_DISMISS);
    const delayTime = typeof delayClose !== 'undefined' ? delayClose : BLANKET_FADE_DURATION;

    if (typeof controlledOpen === 'undefined' || controlledOpen === null) {
      setClosing(true);
    }

    setTimeout(() => {
      setClosing(false);
      setOpen(false);

      if (typeof onCloseRequested !== 'undefined') onCloseRequested(e);
    }, delayTime);
  };

  const additionalProps = { mounted: false };

  // If trigger provided
  if (typeof trigger !== 'undefined') {
    additionalProps.mounted = open;
  }

  // If modal is controlled
  if (typeof controlledOpen !== 'undefined' && controlledOpen !== null) {
    additionalProps.mounted = controlledOpen;
  }

  const underlayClassNames = cnames(styles.underlay, {
    [styles.open]: controlledOpen,
  });

  const wrapperClassNames = cnames(
    styles.wrapper,
    styles[attach],
    { [styles.overflowHidden]: overflowHidden },
  );

  let renderChildren = children;
  if (typeof children === 'function') {
    renderChildren = children({
      open: additionalProps.mounted,
      closing,
      visible: props.modalVisible,
      close: handleClose,
    });
  }

  renderChildren = React.cloneElement(renderChildren, {
    role: 'presentation',
    onClick: stopPropagation,
  });

  return (
    <React.Fragment>
      {
        typeof trigger !== 'undefined' && React.cloneElement(trigger({ onClick: handleTriggerClick }))
      }
      <AriaModal
        includeDefaultStyles={false}
        underlayClass={underlayClassNames}
        dialogClass={styles.stage}
        onExit={handleClose}
        titleText={accessibleTitle}
        focusDialog
        scrollDisabled={false} // using react-remove-scroll instead
        onCloseRequested={onCloseRequested}
        delayClose={delayClose}
        focusTrapOptions={{
          clickOutsideDeactivates: props.allowClicksOutsideWhenActive,
        }}
        {...additionalProps}
        {...props}
      >

        <RemoveScroll>
          <Blanket
            initialPose="out"
            pose={closing || !additionalProps.mounted ? 'out' : 'in'}
            role="presentation"
            className={wrapperClassNames}
            onClick={handleClose}
            delayClose={props.delayClose}
          >
            <OverlayContext.Provider value={{
              portalTarget: focusTrapPortalTarget, hideCloseButton, handleClose,
            }}
            >
              { renderChildren }
            </OverlayContext.Provider>
          </Blanket>
        </RemoveScroll>
        {/* Portal target that is inside the Focus Trap */}
        <div ref={focusTrapPortalTarget} />
      </AriaModal>
    </React.Fragment>
  );
};

ModalOverlay.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
  onCloseRequested: PropTypes.func,
  open: PropTypes.bool,
  overflowHidden: PropTypes.bool,
  attach: PropTypes.oneOf([
    constants.TOP, constants.RIGHT, constants.BOTTOM, constants.LEFT, constants.CENTER,
  ]),
  delayClose: PropTypes.number,
  /**
    * For auto tracking from parent component (e.g. Drawer, Modal)
    */
  track: PropTypes.func,
  /**
    * The CSS classNames for  component. NEVER manually specify this prop
    */
  styles: PropTypes.shape({}),
  /**
   * Allows clicks outside of the active ModalOverlay, which is disabled by default
   */
  allowClicksOutsideWhenActive: PropTypes.bool,
};

ModalOverlay.defaultProps = {
  overflowHidden: false,
  onCloseRequested: () => {},
  children: null,
  attach: constants.CENTER,
  track: () => {},
  styles: _styles,
  allowClicksOutsideWhenActive: false,
};

ModalOverlay.displayName = 'ModalOverlay';

export default ModalOverlay;
