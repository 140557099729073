import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import ColorSwatch from '../../ColorSwatch';
import _styles from './styles.scss';

const ColorField = ({
  options,
  form,
  field,
  label,
  onFocus,
  onBlur,
  styles,
  fluid,
  multiple,
  showTick,
}) => {
  if (!Array.isArray(options) || options.length === 0) return null;
  const fieldValue = multiple ? new Set(field.value) : field.value;

  return (
    <span
      className={cnames(styles.group, { [styles.fluid]: fluid })}
    >
      {
        options.map((option) => {
          let newValue = fieldValue;
          let optionIsSelected = fieldValue === option.value;
          let activePropName = showTick ? 'ticked' : 'selected';

          if (multiple) {
            activePropName = 'ticked';
            newValue = new Set(fieldValue);
            optionIsSelected = fieldValue.has(option.value);

            if (optionIsSelected) {
              newValue.delete(option.value);
            } else {
              newValue.add(option.value);
            }
          }

          const classes = cnames(styles.color, {
            [styles.selected]: optionIsSelected,
            [styles.disabled]: option.isDisabled,
          });

          return (
            // The input is nested inside the label
            /* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */
            <label
              tabIndex={-1}
              key={`${field.name}_${option.value}`}
              className={classes}
              title={option.label}
              aria-label={`${option.label}, ${label}`}
            >
              <ColorSwatch
                hexColor={option.hexColor}
                imageUrl={option.imageUrl}

                // this translate to:
                // {ticked: false} or {ticked: true} or {selected: true} or {selected: false}
                {...({ [activePropName]: optionIsSelected })}
                onClick={(e) => {
                  // Add a preventDefault() here to stops the onClick() event from triggering twice.
                  // The onClick() triggers twice because the <ColorSwatch /> has a
                  // <button> inside of a <label>. When the button is clicked,
                  // it triggers an event from the button AND another event from the label.
                  e.preventDefault();

                  form.setFieldValue(field.name, multiple ? [...newValue] : option.value);
                }}
                onFocus={onFocus}
                onBlur={(e) => {
                  e.target.name = field.name;
                  onBlur(e);
                  field.onBlur(e);
                }}
              />
            </label>
          );
        })
      }
    </span>
  );
};

ColorField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
    isDisabled: PropTypes.bool,
  })),
  field: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
  fluid: PropTypes.bool,
  multiple: PropTypes.bool,
  showTick: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  /**
    * The CSS classNames for this component. NEVER manually specify this prop
    */
  styles: PropTypes.shape({}),
};

ColorField.defaultProps = {
  options: [],
  fluid: false,
  multiple: false,
  showTick: false,
  onFocus: null,
  onBlur: f => f,
  styles: _styles,
};

export default ColorField;
