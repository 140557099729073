
import { defineMessages } from 'react-intl';

export const AMAZONPAY = 'amazonpay';
export const AMERICAN_EXPRESS = 'american_express';
export const APPLEPAY = 'applepay';
export const MASTERCARD = 'mastercard';
export const PAYPAL = 'paypal';
export const SEPA = 'sepa';
export const SOFORT = 'sofort';
export const VISA = 'visa';
export const AFTERPAY = 'afterpay';
export const CLEARPAY = 'clearpay';

export const PaymentMethods = {
  Visa: 'visa',
  Mastercard: 'mastercard',
  AmericanExpress: 'american_express',
  PayPal: 'paypal',
  ApplePay: 'applepay',
  AmazonPay: 'amazonpay',
  Sofort: 'sofort',
  AfterPay: 'afterpay',
  Clearpay: 'clearpay',
};

PaymentMethods.defaults = [
  PaymentMethods.Visa,
  PaymentMethods.Mastercard,
  PaymentMethods.AmericanExpress,
  PaymentMethods.PayPal,
  PaymentMethods.ApplePay,
];

const messages = defineMessages({
  visa: 'Visa',
  mastercard: 'MasterCard',
  american_express: 'American Express',
  paypal: 'PayPal',
  applepay: 'Apple pay',
  amazonpay: 'Amazon Pay',
  sofort: 'Sofort',
  afterpay: 'AfterPay',
  clearpay: 'Clearpay',
});

export const availablePaymentMethods = (intl, paymentMethods) => {
  return paymentMethods.map((paymentMethod) => {
    return {
      icon: paymentMethod,
      text: intl.formatMessage(messages[paymentMethod]),
    };
  });
};
