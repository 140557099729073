import get from 'lodash/get';

const isEqual = (child, assertion) => (
  child !== null && (typeof child.type === 'string' ? child.type : get(child, 'type.displayName')) === assertion
);

const childTypeEquals = (child, assertion) => {
  if (Array.isArray(assertion)) {
    return assertion.some(type => isEqual(child, type));
  }

  return isEqual(child, assertion);
};

export default childTypeEquals;
