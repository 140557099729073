function getElementType(Component, props) {
  const { defaultProps = {} } = Component;

  // user defined 'element' type
  if (props.element && props.element !== defaultProps.element) return props.element;

  // infer anchor links
  // TODO deprecate this
  if (props.href) return 'a';

  // use defaultProp or 'div'
  return defaultProps.element || 'div';
}

export default getElementType;
