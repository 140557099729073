import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import * as constants from '../../constants';
import styles from './styles.scss';

const TextArea = ({ intent, fluid, resizable, ...props }) => (
  <textarea
    {...props}
    className={
      cnames(
        styles.textarea,
        styles[intent],
        {
          [styles.fluid]: fluid,
          [styles.resizable]: resizable,
          [styles.disabled]: props.disabled,
        },
      )
    }
  />
);

TextArea.propTypes = {
  intent: PropTypes.oneOf([
    constants.INFORMATION,
    constants.ERROR,
    constants.WARNING,
    constants.SUCCESS,
    constants.NEUTRAL,
  ]),
  fluid: PropTypes.bool,
  disabled: PropTypes.bool,
  resizable: PropTypes.bool,
};

TextArea.defaultProps = {
  intent: constants.NEUTRAL,
  fluid: false,
  disabled: false,
  resizable: false,
};

export default TextArea;
