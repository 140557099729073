import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';
import * as constants from '../../constants';

const TextField = ({
  field,
  intent,
  placeholder,
  disabled,
  autoComplete,
  autoCapitalize,
  inputType,
  onFocus,
  onBlur,
  fluid,
}) => {
  return (
    <div>
      <Input
        disabled={disabled}
        fluid={fluid}
        intent={intent}
        type={inputType}
        autoComplete={autoComplete}
        autoCapitalize={autoCapitalize}
        placeholder={placeholder}
        {...field}
        onFocus={onFocus}
        onBlur={(e) => {
          onBlur(e);
          field.onBlur(e);
        }}
      />
    </div>
  );
};

TextField.propTypes = {
  field: PropTypes.shape({}).isRequired,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  autoCapitalize: PropTypes.string,
  inputType: PropTypes.string,
  fluid: PropTypes.bool,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  intent: PropTypes.oneOf([
    constants.INFORMATION,
    constants.ERROR,
    constants.WARNING,
    constants.SUCCESS,
    constants.NEUTRAL,
  ]),
};

TextField.defaultProps = {
  fluid: false,
  disabled: false,
  placeholder: '',
  autoComplete: 'on',
  autoCapitalize: 'on',
  inputType: 'text',
  onFocus: null,
  onBlur: f => f,
  intent: constants.NEUTRAL,
};

export default TextField;
