import React from 'react';
import cnames from 'classnames';
import styles from './styles.scss';
import Box from '../Box';
import Text from '../Text';

const useMenuItem = ({
  active,
  highlighted,
  iconBefore,
  iconAfter,
}) => {
  const textType = 'body';

  return {
    classNames: cnames(styles.item, {
      [styles.activeItem]: active,
      [styles.highlighted]: highlighted,
    }),
    IconBefore: () => (iconBefore ? (
      <Box
        alignItems="center"
        justifyContent="center"
        display="flex"
        paddingRight="s"
        className={styles.iconBefore}
      >
        {iconBefore}
      </Box>
    ) : null),
    IconAfter: () => (iconAfter ? (
      <Box
        alignItems="center"
        justifyContent="center"
        display="flex"
        marginLeft="auto"
        paddingLeft="xs"
        className={styles.iconAfter}
      >
        {iconAfter}
      </Box>
    ) : null),
    Content: ({ children }) => (
      <Box flex="1" paddingRight="xl"><Text type={textType}>{ children }</Text></Box>
    ),
  };
};

export default useMenuItem;
