import React from 'react';
import get from 'lodash/get';
import Image from '../../Image';
import { itemImagePropShape } from '../utils';

const ItemImage = ({ settings, ...rest }) => {
  const src = get(settings, 'url', '');
  const set = get(settings, 'set', []);
  const extraProps = {};

  if (!src) {
    return null;
  }

  if (Array.isArray(set) && set.length) {
    extraProps.srcSet = set
      .filter(({ url, descriptor }) => url && descriptor)
      .map(({ url, descriptor }) => `${url} ${descriptor}`)
      .join(', ');
  }

  return (
    <Image
      src={src}
      {...extraProps}
      {...rest}
    />
  );
};

ItemImage.propTypes = {
  settings: itemImagePropShape.isRequired,
};

export const itemCssBackgroundImage = (settings) => {
  const devicePixelRatio = typeof window !== 'undefined' ? (window.devicePixelRatio || 1) : 1;
  const srcSetDescriptors = ['1x', '2x'];
  const src = get(settings, 'url', null);
  const set = get(settings, 'set', []);
  let backgroundImage = src;

  if (backgroundImage && Array.isArray(set) && set.length) {
    const backgroundImageFromSet = set
      .filter(({ url, descriptor }) => (
        url && descriptor && descriptor === srcSetDescriptors[devicePixelRatio]))
      .map(({ url }) => url)
      .shift();

    if (backgroundImageFromSet) {
      backgroundImage = backgroundImageFromSet;
    }
  }

  if (backgroundImage) {
    backgroundImage = `url('${backgroundImage}')`;
  }

  return backgroundImage;
};

export default ItemImage;
