import React from 'react';
import Box from '../../../../../../react/Box';

const TextBox = ({ children, ...props }) => (
  <Box
    paddingTop="xs"
    paddingBottom="xs"
    flex="1"
    {...props}
  >
    { children }
  </Box>
);

export default () => (
  <Box
    flex={1}
    display="flex"
    flexDirection="column"
  >
    <Box padding="m" flex="1">
      <TextBox style={{ color: 'var(--ds-color-text)' }}>Text</TextBox>
      <TextBox style={{ color: 'var(--ds-color-text-muted)' }}>Text muted</TextBox>
    </Box>
    <Box padding="m" flex="1" style={{ backgroundColor: 'var(--ds-color-background-app-dark)', borderRadius: '0 0 var(--ds-radius-medium)  var(--ds-radius-medium)' }}>
      <TextBox style={{ color: 'var(--ds-color-text-contrast)' }}>Text contrast</TextBox>
      <TextBox style={{ color: 'var(--ds-color-text-muted-contrast)' }}>Text contrast muted</TextBox>
    </Box>
  </Box>
);
