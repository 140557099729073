import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import cnames from 'classnames';
import Button from '../../../Button';
import withNextAction from '../../../utils/withNextAction';
import Box from '../../../Box';
import styles from './style.scss';

const messages = defineMessages({
  sellArt: 'Sell your art',
  login: 'Login',
  signup: 'Signup',
});

const HeaderLinks = ({
  intl,
  baseUrl,
  doLogin,
  doSignup,
  isDesktop,
  isLoggedIn,
  hasHamburgerMenu,
}) => {
  if (!isDesktop || isLoggedIn) return null;

  const baseLinkProps = {
    rel: 'nofollow',
  };

  const loginProps = {
    ...baseLinkProps,
    onClick(ev) {
      if (doLogin) {
        return doLogin(ev);
      }

      // Generate link in onClick to prevent server-side rendering issues
      window.location.href = withNextAction(`${baseUrl}/auth/login`);
      return ev.preventDefault();
    },
    href: `${baseUrl}/auth/login`,
  };

  const signupProps = {
    ...baseLinkProps,
    onClick(ev) {
      if (doSignup) {
        return doSignup(ev);
      }

      // Generate link in onClick to prevent server-side rendering issues
      window.location.href = withNextAction(`${baseUrl}/signup`);
      return ev.preventDefault();
    },
    href: `${baseUrl}/signup`,
  };

  return (
    <>
      <Box className={cnames({ [styles.hideForHamburger]: hasHamburgerMenu })}>
        <Button href={`${baseUrl}/about/selling`}>
          {intl.formatMessage(messages.sellArt)}
        </Button>
      </Box>
      <Button
        {...loginProps}
        data-testid="ds-header-login-action"
      >
        {intl.formatMessage(messages.login)}
      </Button>
      <Box className={cnames({ [styles.hideForHamburger]: hasHamburgerMenu })}>
        <Button
          {...signupProps}
          data-testid="ds-header-signup-action"
        >
          {intl.formatMessage(messages.signup)}
        </Button>
      </Box>
    </>
  );
};

HeaderLinks.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  baseUrl: PropTypes.string.isRequired,
  doLogin: PropTypes.func,
  doSignup: PropTypes.func,
  hasHamburgerMenu: PropTypes.bool,
};


HeaderLinks.defaultProps = {
  doLogin: null,
  doSignup: null,
};

HeaderLinks.displayName = 'HeaderLinks';

export default HeaderLinks;
