import React from 'react';
import PropTypes from 'prop-types';
import TextArea from '../TextArea';
import * as constants from '../../constants';

const TextAreaField = ({
  field,
  intent,
  placeholder,
  disabled,
  autoComplete,
  autoCapitalize,
  onFocus,
  onBlur,
  fluid,
  rows,
  cols,
  maxLength,
  minLength,
  wrap,
  resizable,
}) => {
  return (
    <div>
      <TextArea
        resizable={resizable}
        disabled={disabled}
        fluid={fluid}
        intent={intent}
        autoComplete={autoComplete}
        autoCapitalize={autoCapitalize}
        placeholder={placeholder}
        {...field}
        onFocus={onFocus}
        onBlur={(e) => {
          onBlur(e);
          field.onBlur(e);
        }}
        rows={rows}
        cols={cols}
        maxLength={maxLength}
        minLength={minLength}
        wrap={wrap}
      />
    </div>
  );
};

TextAreaField.propTypes = {
  field: PropTypes.shape({}).isRequired,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  autoCapitalize: PropTypes.string,
  fluid: PropTypes.bool,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  intent: PropTypes.oneOf([
    constants.INFORMATION,
    constants.ERROR,
    constants.WARNING,
    constants.SUCCESS,
    constants.NEUTRAL,
  ]),
  /* eslint-disable react/require-default-props */
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cols: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wrap: PropTypes.string,
  resizable: PropTypes.bool,
  /* eslint-enable react/require-default-props */
};

TextAreaField.defaultProps = {
  fluid: false,
  resizable: false,
  disabled: false,
  placeholder: '',
  autoComplete: 'on',
  autoCapitalize: 'on',
  onFocus: null,
  onBlur: f => f,
  intent: constants.NEUTRAL,
};

export default TextAreaField;
