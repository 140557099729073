import React from 'react';
import cnames from 'classnames';
import PropTypes from 'prop-types';
import UserSettings from '../UserSettings';
import _styles from './themes/default.scss';

const FooterSettings = ({ isInView, baseUrl, returnUrl, userInfo,
  onToggleMatureContent, styles, parentStyles, intl }) => (
    <div
      className={cnames(
        styles.footerSettings,
        parentStyles.footerSettings,
      )}
    >
      <div className={styles.footerContainer}>
        {
          isInView && (
            <UserSettings
              baseUrl={baseUrl}
              intl={intl}
              userInfo={userInfo}
              returnUrl={returnUrl}
              onToggleMatureContent={onToggleMatureContent}
            />
          )
        }
      </div>
    </div>
);

FooterSettings.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  styles: PropTypes.shape({}),
  parentStyles: PropTypes.shape({}).isRequired,
  baseUrl: PropTypes.string.isRequired,
  returnUrl: PropTypes.string.isRequired,
  isInView: PropTypes.bool.isRequired,
  userInfo: PropTypes.shape({
    locale: PropTypes.string,
    country: PropTypes.string,
    currency: PropTypes.string,
  }),
  onToggleMatureContent: PropTypes.func,
};

FooterSettings.defaultProps = {
  styles: _styles,
  userInfo: {},
  onToggleMatureContent: () => {},
};

FooterSettings.displayName = 'FooterSettings';

export default FooterSettings;
