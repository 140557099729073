const getAriaProps = (props) => {
  return Object.entries(props).reduce((ariaProps, [propName, propValue]) => {
    if (propName.startsWith('aria-')) {
      // eslint-disable-next-line no-param-reassign
      ariaProps = { ...ariaProps, [propName]: propValue };
    }

    return ariaProps;
  }, {});
};

export default getAriaProps;
