import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, BrowserRouter as Router, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { ResponsiveProvider, ResponsiveConsumer as ResponsiveContext } from 'react-responsive-context';
import ReactGA from 'react-ga';
import Header from './components/Header';
import styles from './App.css';
import PageSection from '../react/PageSection';
import Box from '../react/Box';
// import Label from '../react/Label';
import Text from '../react/Text';
import Tooltip from '../react/Tooltip';
import TextLink from '../react/TextLink';
import Logo from '../react/Logo';
import Avatar from '../react/Avatar';
import GithubIcon from '../react/Icons/SocialGithub';
import defaultTheme from '../react/themes/default';
import DesignSystemProvider from '../react/DesignSystemProvider';
import ScrollToTop from './components/ScrollToTop';
import TextContainer from './components/TextContainer';
import InteractiveBox from './components/InteractiveBox';
import * as constants from '../react/constants';
import packages from '../package.json';
import redirects from './lib/redirects';

import Home, { Banner as HomeBanner } from './routes/Home';
import Foundations, { Sidebar as FoundationsSidebar, Banner as FoundationsBanner, Nav as FoundationsNav } from './routes/Foundations';
import Brand, { Sidebar as BrandSidebar, Banner as BrandBanner, Nav as BrandNav } from './routes/Brand';
import Components, { Sidebar as ComponentsSidebar, Banner as ComponentsBanner, Nav as ComponentsNav } from './routes/Components';
import Guides, { Sidebar as GuidesSidebar, Banner as GuidesBanner, Nav as GuidesNav } from './routes/Guides';

import metaImage from './images/meta-social.jpg';

import ana from './images/contributors/ana.jpeg';
import brad from './images/contributors/brad.jpeg';
import marcus from './images/contributors/marcus.jpeg';

const contributors = [
  {
    name: 'Ana',
    avatar: ana,
    url: 'https://github.com/saintasia',
  },
  {
    name: 'Brad',
    avatar: brad,
    url: 'https://github.com/bradbarrow',
  },
  {
    name: 'Marcus',
    avatar: marcus,
    url: 'https://github.com/marchamm',
  },
];

// initialize google analytics tracking
const gaCode = process.env.NODE_ENV === 'production' ? 'UA-322978-28' : '';
ReactGA.initialize(gaCode, { testMode: process.env.NODE_ENV === 'test' });

// Log when the location changes
const LogPageViews = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return <React.Fragment />;
};

const routes = [
  {
    title: 'Homepage',
    path: '/',
    sidebar: null,
    exact: true,
    main: Home,
    banner: HomeBanner,
    subnav: null,
  },
  {
    title: 'Foundations',
    path: '/foundations',
    sidebar: FoundationsSidebar,
    main: Foundations,
    banner: FoundationsBanner,
    subnav: FoundationsNav,
  },
  {
    title: 'Brand',
    path: '/brand',
    sidebar: BrandSidebar,
    main: Brand,
    banner: BrandBanner,
    subnav: BrandNav,
  },
  {
    title: 'Components',
    path: '/components',
    sidebar: ComponentsSidebar,
    main: Components,
    banner: ComponentsBanner,
    subnav: ComponentsNav,
  },
  {
    title: 'Guides',
    path: '/guides',
    sidebar: GuidesSidebar,
    main: Guides,
    banner: GuidesBanner,
    subnav: GuidesNav,
  },
];


const App = () => {
  return (
    <ResponsiveProvider>
      <Helmet>
        <title>Redbubble Design System</title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://redbubble.design/" />
        <meta property="og:title" content="Redbubble Design System" />
        <meta property="og:description" content="Efficiency for teams and consistency for users" />
        <meta property="og:image" content={`https://redbubble.design${metaImage}`} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="redbubble.design" />
        <meta property="twitter:url" content="https://redbubble.design" />
        <meta property="twitter:title" content="Redbubble Design System" />
        <meta property="twitter:description" content="Efficiency for teams and consistency for users" />
        <meta property="twitter:image" content={`https://redbubble.design${metaImage}`} />
      </Helmet>
      <ResponsiveContext.Consumer>
        {
          ({ desktop }) => (
            <DesignSystemProvider
              config={{
                theme: defaultTheme,
                useToasts: { profile: desktop ? constants.DESKTOP : constants.MOBILE },
                tracker: {
                  track: (args) => {
                    console.log(JSON.stringify(args, null, 4));
                    navigator.sendBeacon(
                      'https://enmhp44k0yoto.x.pipedream.net',
                      JSON.stringify(args, null, 4),
                    );
                  },
                },
              }}
            >
              <Router>
                {
                  redirects.map(redirect => (
                    <Route exact path={redirect.old}>
                      <Redirect to={redirect.new} />
                    </Route>
                  ))
                }
                <LogPageViews />
                <ScrollToTop />
                <Header mobile={!desktop} />
                <Switch>
                  {routes.map(route => (
                    <Route
                      key={route.title}
                      path={route.path}
                      exact={route.exact}
                    >
                      { route.banner && <route.banner mobile={!desktop} /> }
                    </Route>
                  ))}
                </Switch>
                <PageSection>
                  <Box display="flex" flexDirection={!desktop ? 'column' : 'row'}>
                    <Switch>
                      { desktop && routes.map(route => (
                        <Route
                          key={route.title}
                          path={route.path}
                          exact={route.exact}
                        >
                          { route.sidebar && (
                            <Box padding="xxl" marginTop="m" style={{ minWidth: 280 }}>
                              <route.sidebar />
                            </Box>
                          )}
                        </Route>
                      ))}
                    </Switch>
                    <Switch>
                      { !desktop && routes.map(route => (
                        <Route
                          key={route.title}
                          path={route.path}
                          exact={route.exact}
                        >
                          { route.subnav && (
                            <Box paddingX="l" paddingTop="xxl" marginTop="xl" paddingBottom="none">
                              <route.subnav />
                            </Box>
                          )}
                        </Route>
                      ))}
                    </Switch>
                    <Switch>
                      {routes.map(route => (
                        <Route
                          key={route.title}
                          path={route.path}
                          exact={route.exact}
                        >
                          { route.main && (
                            <>
                              <Box padding={!desktop ? 'l' : 'xxl'} flex="1" style={{ minWidth: 0 }}>
                                <route.main mobile={!desktop} title={route.title} />
                              </Box>
                            </>
                          )}
                        </Route>
                      ))}
                    </Switch>
                  </Box>
                </PageSection>
                <Box className={styles.footer} marginTop="xxl">
                  <PageSection>
                    <Box className={styles.footerTop} paddingY="xxl" paddingX={!desktop ? 'l' : 'xxl'}>
                      <Box paddingY="xxl">
                        <Text type="display2" marginBottom="xl" display="block">Contributions</Text>
                        <TextContainer>
                          <Text element="p" display="block">The Redbubble Design System welcomes contributions from everyone at Redbubble. We want to keep it as easy as possible to contribute changes that get things working in your environment.</Text>
                          <Text element="p" display="block">Read more about contributing to our system on <TextLink href="https://github.com/redbubble/design" target="_blank">GitHub</TextLink> and if you have any questions, please reach out to one of the core contributors:</Text>
                        </TextContainer>
                        <Box display="flex" marginTop="xl" marginBottom={!desktop && 'xxl'} paddingBottom={!desktop && 'xxl'}>
                          {
                            contributors.map(user => (
                              <InteractiveBox
                                marginRight="m"
                                pill
                                className={styles.avatarContainer}
                                element="a"
                                href={user.url}
                                target="_blank"
                              >
                                <Tooltip
                                  display="block"
                                  trigger={triggerProps => (
                                    <Avatar src={user.avatar} {...triggerProps} size="small" block />
                                  )}
                                >
                                  {user.name}
                                </Tooltip>
                              </InteractiveBox>
                            ))
                          }
                        </Box>
                      </Box>
                    </Box>
                  </PageSection>
                  <Box className={styles.footerBottom} paddingBottom={!desktop && 'xxl'}>
                    <PageSection padding={!desktop ? 'l' : 'xl'} marginBottom={!desktop && 'xxl'}>
                      <Box display="flex" alignItems="center" justifyContent="space-between" paddingBottom={!desktop && 'xxl'}>
                        <Box className={styles.logo}>
                          <Link to="/">
                            <Logo brandmarkOnly colored={false} />
                          </Link>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Text><TextLink href="https://redbubble.com" target="_blank">Redbubble</TextLink></Text>
                          <Text marginX="s" muted>•</Text>
                          <Text><TextLink href="https://github.com/redbubble/design" target="_blank">GitHub</TextLink></Text>
                          <Text marginX="s" muted>•</Text>
                          <Text type="body2">
                            <TextLink
                              iconBefore={<GithubIcon size="small" />}
                              href="https://github.com/redbubble/design/releases"
                              target="_blank"
                              subtle
                            >
                              v{packages.version}
                            </TextLink>
                          </Text>
                        </Box>
                      </Box>
                    </PageSection>
                  </Box>
                </Box>
              </Router>
            </DesignSystemProvider>
          )
        }
      </ResponsiveContext.Consumer>
    </ResponsiveProvider>
  );
};

export default App;
