import React from 'react';
import Text from '../../../react/Text';
import Box from '../../../react/Box';
import Image from '../../../react/Image';
import * as constants from '../../../react/constants';
import styles from './Hint.css';
import hintSvg from './hint.svg';

const Hint = ({ children, showSymbol }) => {
  return (
    <Box
      className={styles.wrapper}
    >
      <Box
        padding="m"
        className={styles.container}
        display="flex"
      >
        {showSymbol && (
          <Box marginRight="m">
            <Image src={hintSvg} size={constants.TINY} />
          </Box>
        )}
        <Text display="block">
          {children}
        </Text>
      </Box>
    </Box>
  );
};

export default Hint;
