import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import Box from '../Box';
import styles from './styles.scss';

const sizes = [
  0,
  0.25,
  0.5,
  0.75,
  1,
  1.25,
  1.5,
  1.75,
  2,
  'none',
  'xxxs',
  'xxs',
  'xs',
  's',
  'm',
  'l',
  'xl',
  'xxl',
  'xxxl',
];

const Divider = ({
  color,
  // Box props
  margin,
  marginTop,
  marginLeft,
  marginRight,
  marginBottom,
  marginX,
  marginY,
}) => {
  const classNames = cnames(styles.divider);

  return (
    <Box
      {...({
        margin,
        marginTop,
        marginLeft,
        marginRight,
        marginBottom,
        marginX,
        marginY,
      })}
      className={classNames}
      style={{ color }}
    />
  );
};

Divider.displayName = 'Divider';

Divider.propTypes = {
  /**
    * The color of the dividing line
    */
  color: PropTypes.string,
  /**
    * Margin around the divider
    */
  margin: PropTypes.oneOf(sizes),
  /**
    * Margin to the top of the divider
    */
  marginTop: PropTypes.oneOf(sizes),
  /**
    * Margin to the right of the divider
    */
  marginRight: PropTypes.oneOf(sizes),
  /**
    * Margin to the bottom of the divider
    */
  marginBottom: PropTypes.oneOf(sizes),
  /**
    * Margin to the left of the divider
    */
  marginLeft: PropTypes.oneOf(sizes),
  /**
    * Margin to the left and right of the divider
    */
  marginX: PropTypes.oneOf(sizes),
  /**
    * Margin to the top and bottom of the divider
    */
  marginY: PropTypes.oneOf(sizes),
};

Divider.defaultProps = {
};

export default Divider;
