import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem as ReachMenuItem } from '@reach/menu-button';
import { MenuItem } from '../Menu';
import useTracking, { trackingShape, EVENT_CLICK } from '../hooks/useTracking';
import wrapForReachUI from './wrapForReachUI';

const events = { EVENT_CLICK };

const PreparedMenuItem = wrapForReachUI(MenuItem);

const DropdownItem = ({ onSelect, tracking, ...props }) => {
  const { track } = useTracking(tracking, 'DropdownItem', events);

  return (
    <ReachMenuItem
      as={PreparedMenuItem}
      {...props}
      onSelect={(...args) => {
        track(EVENT_CLICK);
        if (typeof onSelect === 'function') onSelect(...args);
      }}
    />
  );
};

DropdownItem.propTypes = {
  children: PropTypes.node,
  onSelect: PropTypes.func,
  tracking: trackingShape,
};

export default DropdownItem;
