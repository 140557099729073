import React from 'react';
import PageIntro from '../../../components/PageIntro';
import PageTitle from '../../../components/PageTitle';
import PageDescription from '../../../components/PageDescription';
import Markdown from '../../../components/Markdown';
import Hint from '../../../components/Hint';
import Box from '../../../../react/Box';
import Text from '../../../../react/Text';
import Image, { generatePlaceholderSrc } from '../../../../react/Image';
import TextLink from '../../../../react/TextLink';
import ChevronRightBigIcon from '../../../../react/Icons/ChevronRightBig';
import MediaCard from '../../../../react/MediaCard';
import { PanelFooter } from '../../../components/Panel';
import styles from './Layout.css';

import gridExample from './media/grid.png';
import carouselExample from './media/carousel.png';
import masonryExample from './media/masonry.png';
import sidebarExample from './media/sidebar.png';

const relatedContent = [
  {
    name: 'Grid',
    link: '/components/Grid/examples',
  },
  {
    name: 'Masonry',
    link: '/components/masonry/examples',
  },
  {
    name: 'Box',
    link: '/components/Box/examples',
  },
  {
    name: 'Spacing',
    link: '/guidelines/spacing',
  },
];

const Layout = () => {
  return (
    <>
      <div className={styles.Layout_container}>
        <PageIntro>
          <PageTitle>Layout</PageTitle>
          <PageDescription>
            {/* eslint-disable max-len */}
            Layouts are helpful for organizing content into compositions that flow together.
            {/* eslint-enable max-len */}
          </PageDescription>
        </PageIntro>
        <Markdown
          source={`

  ## Overview

  Below you will find a few common layout patterns and templates for designing and building organised and consistent experiences. To know which one to choose, it is important to consider a few things:
  - What is the user trying to do, are they exploring or comparing?
  - What is the most important content on the page?
  - Are there groups of related content?

  Please explore the below examples to find the one that will suit your use-case.

  `}
        />
        <Markdown source={`
### Grid

There are several components providing the grid layouting options.

The [Grid](/components/grid/examples) responsive component can be used to showcase products in information-dense layouts.
Laying out products in a repeated uniform pattern allows for a clean and easily scannable interface with minimal wasted space.

[Carousel](/components/carousel/examples) allows for a strict grid-based layout with built-in carousel behavior for overflowing content,
assuring that the items' sizes stay consistent even on smaller viewports.

The [Masonry](/components/grid/examples) component allows to present artworks in a consistent and adaptive layout,
Masonry is best used for artwork-first approach, either by itself, or if in composite layouts, last (at the bottom) of a page.

`}
        />
        <Box marginY="xxxl" className={styles.examplesNarrow}>
          <Box className={styles.imageHoverContainer}>
            <MediaCard
              href="https://tinyurl.com/y3534ydj"
              imageRender={() => (
                <Box className={styles.imageHover}>
                  <Image
                    fluid
                    src={gridExample}
                    loadOnVisible={{
                      placeholder: generatePlaceholderSrc({ width: 500, height: 350 }),
                    }}
                  />
                </Box>
              )}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                paddingLeft="xs"
              >
                <Text display="block" type="display5">
                Grid Playroom
                </Text>
                <Box paddingLeft="m" paddingRight="xs" display="flex" alignItems="center">
                  <ChevronRightBigIcon />
                </Box>
              </Box>
            </MediaCard>
          </Box>

          <Box className={styles.imageHoverContainer}>
            <MediaCard
              href="https://tinyurl.com/y45h4qrt"
              imageRender={() => (
                <Box className={styles.imageHover}>
                  <Image
                    fluid
                    src={carouselExample}
                    loadOnVisible={{
                      placeholder: generatePlaceholderSrc({ width: 500, height: 350 }),
                    }}
                  />
                </Box>
              )}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                paddingLeft="xs"
              >
                <Text display="block" type="display5">
                Carousel Playroom
                </Text>
                <Box paddingLeft="m" paddingRight="xs" display="flex" alignItems="center">
                  <ChevronRightBigIcon />
                </Box>
              </Box>
            </MediaCard>
          </Box>

          <Box className={styles.imageHoverContainer}>
            <MediaCard
              href="https://tinyurl.com/yywbvyx4"
              imageRender={() => (
                <Box className={styles.imageHover}>
                  <Image
                    fluid
                    src={masonryExample}
                    loadOnVisible={{
                      placeholder: generatePlaceholderSrc({ width: 500, height: 350 }),
                    }}
                  />
                </Box>
              )}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                paddingLeft="xs"
              >
                <Text display="block" type="display5">
                Masonry Playroom
                </Text>
                <Box paddingLeft="m" paddingRight="xs" display="flex" alignItems="center">
                  <ChevronRightBigIcon />
                </Box>
              </Box>
            </MediaCard>
          </Box>
        </Box>
        <Markdown source={`
  ### Column layouts

  The [Box](/utilities/box/examples) utility supports creation of various column-based layouts.

  *Sidebar* or a master-detail layout is a pattern used in experiences for representing lists of items along with a larger view, allowing the user to stay on the same screen while viewing multiple categories of information.

  `}
        />
        <Box marginY="xl" className={styles.examples}>
          <Box>
            <Box className={styles.imageHoverContainer}>
              <MediaCard
                href="https://tinyurl.com/y2jyd2jo"
                imageRender={() => (
                  <Box className={styles.imageHover}>
                    <Image
                      fluid
                      src={sidebarExample}
                      loadOnVisible={{
                        placeholder: generatePlaceholderSrc({ width: 500, height: 350 }),
                      }}
                    />
                  </Box>
                )}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  paddingLeft="xs"
                >
                  <Text display="block" type="display5">
                  Sidebar Playroom
                  </Text>
                  <Box paddingLeft="m" paddingRight="xs" display="flex" alignItems="center">
                    <ChevronRightBigIcon />
                  </Box>
                </Box>
              </MediaCard>
            </Box>
            <Box marginTop="l">
              <Hint showSymbol>
                <Box display="flex">
                  <Box marginLeft="xs">
                    <Text type="body2">
                      This layout works best on larger desktop viewports.
                      When creating a mobile-friendly layout you can leverage other
                        patterns and components like <TextLink href="/components/Drawer/examples">Drawer</TextLink>
                    </Text>
                  </Box>
                </Box>
              </Hint>
            </Box>
          </Box>
        </Box>
      </div>
      <PanelFooter relatedContent={relatedContent} />
    </>
  );
};

export default Layout;
