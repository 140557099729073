import React from 'react';
import Box from '../../../../../../react/Box';
import ButtonToggle from '../../../../../../react/ButtonToggle';
import Text from '../../../../../../react/Text';
import Card from '../../../../../../react/Card';
import * as constants from '../../../../../../react/constants';

export default () => {
  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{ backgroundColor: 'var(--ds-color-background-alt-100)', pointerEvents: 'none' }}
    >
      <Box
        padding="xl"
        paddingBottom="m"
        display="flex"
      >
        <ButtonToggle
          active={false}
          size={constants.SMALL}
        >
          UI
        </ButtonToggle>
      </Box>
      <Box
        paddingBottom="m"
        display="flex"
      >
        <ButtonToggle
          active
          size={constants.SMALL}
        >
          UI Contrast
        </ButtonToggle>
      </Box>
      <Box
        padding="xl"
        paddingTop="m"
        display="flex"
      >
        <Box flex="1" marginRight="m">
          <Card>
            <Box padding="m" display="flex" justifyContent="center">
              <Text type="caption" muted>Default</Text>
            </Box>
          </Card>
        </Box>
        <Box flex="1" marginRight="m">
          <Card elevation={constants.ELEVATION_MEDIUM}>
            <Box padding="m" display="flex" justifyContent="center">
              <Text type="caption" muted>Medium</Text>
            </Box>
          </Card>
        </Box>
        <Box flex="1">
          <Card elevation={constants.ELEVATION_HIGH}>
            <Box padding="m" display="flex" justifyContent="center">
              <Text type="caption" muted>High</Text>
            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};
