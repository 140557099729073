import React from 'react';
import MarkdownContents from 'markdown-contents';
import TabBar, { Tab } from '../../../components/TabBar';
import Box from '../../../../react/Box';
import Markdown from '../../../components/Markdown';
import jsContents from './js.md';
import cssContents from './css.md';

const jsToc = MarkdownContents(jsContents);
const cssToc = MarkdownContents(cssContents);

const LintRules = () => {
  return (
    <>
      <Markdown>
        {`
  # Lint Rules

  #### Our lint rules help identify and report on patterns and anti-patterns that arise when using our Design System components, styles and utilities.
  `}
      </Markdown>

      <Box marginTop={1}>
        <TabBar rootPath="/guides/lint">
          <Tab label="JS">
            <Markdown>
              {`
  ${jsToc.markdown()}
  ${jsContents}
  `}
            </Markdown>
          </Tab>
          <Tab label="CSS">
            <Markdown>
              {`
  ${cssToc.markdown()}
  ${cssContents}
  `}
            </Markdown>
          </Tab>
        </TabBar>
      </Box>
    </>
  );
};

export default LintRules;
