import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import shortid from 'shortid';
import PageSection from '../PageSection';
import Image from '../Image';

import * as MasonryExports from '.';

// eslint-disable-next-line
import MasonrySrc from '!raw-loader!.';

const { default: Masonry } = MasonryExports;
const docs = generateDocs(MasonrySrc, resolver.findAllExportedComponentDefinitions);

const variableImages = new Array(95).fill(null).map((_, i) => {
  let ratio = i % 2 === 0 ? '100x100' : '300x400';
  if (i % 3 === 0) ratio = '400x300';
  return `https://dummyimage.com/${ratio}/000000/fff&text=${i + 1}`;
});

const basicExample = {
  name: 'Simple Example',
  description: `
The masonry grid will vary the number of columns it has, to keep the width of each column as close to the target column width you specify.
<br />
You can specify a maximum number of columns.
  `,
  overflowVisual: false,
  muted: true,
  render: () => (
    <PageSection>
      <Masonry targetColumnWidth={300}>
        {
          /* eslint-disable react/no-array-index-key */
          new Array(10).fill(null).map((_, i) => (
            <Image
              key={shortid.generate()}
              src={variableImages[i]}
              roundedCorners
              fluid
            />
          ))
        }
      </Masonry>
    </PageSection>
  ),
  code: () => `
<Masonry targetColumnWidth={300}>
  <Image
    src={${variableImages[0]}}
    roundedCorners
    fluid
  />
  <Image
    src={${variableImages[1]}}
    roundedCorners
    fluid
  />
  <Image
    src={${variableImages[2]}}
    roundedCorners
    fluid
  />
</Masonry>
  `,
};

const condensedExample = {
  name: 'Condensed',
  description: 'A condensed Masonry grid has smaller gutters, better suited to smaller screens.',
  overflowVisual: false,
  muted: true,
  render: () => (
    <PageSection>
      <Masonry targetColumnWidth={300} condensed>
        {
          /* eslint-disable react/no-array-index-key */
          new Array(10).fill(null).map((_, i) => (
            <Image
              key={shortid.generate()}
              src={variableImages[i]}
              roundedCorners
              fluid
            />
          ))
        }
      </Masonry>
    </PageSection>
  ),
  code: () => `
<Masonry targetColumnWidth={300} condensed>
  <Image
    src={${variableImages[0]}}
    roundedCorners
    fluid
  />
  <Image
    src={${variableImages[1]}}
    roundedCorners
    fluid
  />
  <Image
    src={${variableImages[2]}}
    roundedCorners
    fluid
  />
</Masonry>
  `,
};

const Demo = {
  title: Masonry.displayName,
  description: 'Masonry grids are a creative and immersive way to display engaging visual content.',
  docGen: docs,
  slug: 'Masonry',
  exports: Object.keys(MasonryExports),
  examples: [
    basicExample,
    condensedExample,
  ],
  notes: `
  `,
};

export default Demo;
