import { useReducer, useEffect } from 'react';
import Color from 'color';
import ColorThief from 'colorthief/dist/color-thief.umd';
import * as constants from '../../constants';

export const defaultFormat = constants.FORMAT_HEX;
export const defaultCrossOrigin = true;

export const loadImage = (url, crossOrigin = undefined) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.addEventListener('load', () => resolve(img));
    img.addEventListener('error', () => {
      reject(new Error(`Failed to load image at: ${url}`));
    });
    img.crossOrigin = crossOrigin;
    img.src = url;
  });
};

const extractColors = (img) => {
  const colorThief = new ColorThief();
  const dominant = colorThief.getColor(img);
  const palette = colorThief.getPalette(img);
  return Promise.resolve([dominant, ...palette]);
};

const initialState = {
  loading: true,
  colors: [],
  error: undefined,
};

const dominantColorsReducer = (state, { type, ...rest }) => {
  switch (type) {
    case 'init':
      return initialState;
    case 'success':
      return { ...state, ...rest };
    case 'error':
      return { ...state, ...rest };
    default:
      return state;
  }
};

const formatColors = (colorThiefColors, format) => {
  const formatColor = (color) => {
    if (format === constants.FORMAT_RGB) return color.rgb().array();
    return color.hex();
  };

  return colorThiefColors.map((colorThiefColor) => {
    const col = Color.rgb(...colorThiefColor);
    return formatColor(col);
  });
};

export default function useImagePalette(config) {
  let options = {
    src: config,
    crossOrigin: defaultCrossOrigin,
    format: defaultFormat,
  };

  if (typeof config !== 'string') {
    options = { ...options, ...config };
  }

  const { src, crossOrigin, format } = options;

  const [state, dispatch] = useReducer(dominantColorsReducer, initialState);

  useEffect(() => {
    dispatch({ type: 'init' });

    loadImage(src, crossOrigin)
      .then(extractColors)
      .then(colors => formatColors(colors, format))
      .then((colors) => {
        dispatch({
          type: 'success',
          colors,
          loading: false,
        });
      })
      .catch((e) => {
        dispatch({
          type: 'error',
          loading: false,
          error: e,
        });
      });
  }, [src]);

  return state;
}
