import React from 'react';
import Markdown from '../Markdown';
import Box from '../../../react/Box';
import Image from '../../../react/Image';
import Text from '../../../react/Text';
import styles from './styles.css';

const UsageTab = ({ usage }) => (
  <div>
    <Box marginBottom="xl">
      <Text display="block" type="display3">Overview</Text>
      <Markdown source={usage.overview || 'We need help writing this guideline ✨'} />
    </Box>

    {
      usage.principles && (
        <Box marginBottom="xl" marginTop="xl">
          <Text display="block" type="display3">Principles</Text>
          <Markdown source={usage.principles} />
        </Box>
      )
    }

    { (usage.anatomy || usage['anatomy-image']) && (
      <Box marginBottom="xl" marginTop="xl">
        <Text display="block" type="display3">Anatomy</Text>
        <Markdown source={usage.anatomy} />
        <Box marginBottom="m" />

        <Box display="flex" marginBottom="xl">
          <Box marginRight="xl">
            <Image style={{ maxWidth: 375 }} fluid src={`${usage['anatomy-image']}`} alt="anatomy" />
          </Box>
          <Box>
            <Markdown source={usage['anatomy-labels']} />
          </Box>
        </Box>
      </Box>
    ) }

    {
      (usage.sections || []).map(section => (
        <Box marginBottom="xl" marginTop="xl">
          <Text display="block" type="display3">{ section.title }</Text>
          <Markdown source={section.body} />

          {
            (section.do || section.dont) && (
              <div className={styles.ddContainer}>
                <div className={styles.item}>
                  <Text type="display6" element="h6" display="block">Do</Text>
                  <div className={styles.do}>
                    <Markdown source={section.do} />
                  </div>
                </div>
                <div className={styles.item}>
                  <Text type="display6" element="h6" display="block">Don&apos;t</Text>
                  <div className={styles.dont}>
                    <Markdown source={section.dont} />
                  </div>
                </div>
              </div>
            )
          }
        </Box>
      ))
    }
  </div>
);

export default UsageTab;
