import React from 'react';
import cnames from 'classnames';
import MarkdownContents from 'markdown-contents';
import { useLocation } from 'react-router-dom';
import useResponsive from '../../../hooks/useResponsive';
import Markdown from '../../../components/Markdown';
import TabBar, { Tab } from '../../../components/TabBar';
import Box from '../../../../react/Box';
import Text from '../../../../react/Text';
import tokenDictionary from '../../../../react/themes/tokenDictionary';
import * as types from '../../../../react/themes/tokenTypes';
import devGuide from './developerGuide';
import TokenCategory from './TokenReferenceCategory';
import DesignSystemConsumer from '../../../../react/DesignSystemConsumer';
import styles from './DesignTokens.css';

const toc = MarkdownContents(devGuide);

const tokenCategories = [
  {
    name: 'Brand Color',
    description: 'Single color used to re-enforce our brand.',
    type: types.COLOR,
    semantic: types.BRAND,
  },
  {
    name: 'Common Colors',
    description: 'Plain colors, for use in a wide variety of circumstances.',
    type: types.COLOR,
    semantic: types.COMMON,
  },
  {
    name: 'Primary Color',
    description: 'Our primary colour is used for call to actions and to highlight parts of our UI.',
    type: types.COLOR,
    semantic: types.PRIMARY,
  },
  {
    name: 'Intent Colors',
    description: 'Colors for specific purposes.',
    type: types.COLOR,
    semantic: types.INTENT,
  },
  {
    name: 'Neutral Colors',
    description: 'Gray scale for building UI.',
    type: types.COLOR,
    semantic: types.NEUTRAL,
  },
  {
    name: 'Expressive Colors',
    description: 'Colors to be used for more expressive UI.',
    type: types.COLOR,
    semantic: types.EXPRESSIVE,
  },
  {
    name: 'Text Colors',
    type: types.COLOR,
    semantic: types.TEXT,
  },
  {
    name: 'Border Colors',
    type: types.COLOR,
    semantic: types.BORDER,
  },
  {
    name: 'Background Colors',
    type: types.COLOR,
    semantic: types.BACKGROUND,
  },
  {
    name: 'Stateful Colors',
    type: types.COLOR,
    semantic: types.STATE,
  },
  {
    name: 'Font Family',
    type: types.FONT_FAMILY,
  },
  {
    name: 'Font Size',
    type: types.FONT_SIZE,
  },
  {
    name: 'Font Weight',
    type: types.FONT_WEIGHT,
  },
  {
    name: 'Line Height',
    type: types.LINE_HEIGHT,
  },
  {
    name: 'Spacing',
    type: types.SPACING,
  },
  {
    name: 'Dimensions',
    type: types.SIZE,
    semantic: types.DIMENSION,
  },
  {
    name: 'Border Widths',
    type: types.SIZE,
    semantic: types.BORDER_WIDTH,
  },
  {
    name: 'Radius',
    type: types.RADIUS,
  },
  {
    name: 'Shadows',
    type: types.SHADOW,
  },
];

const ROUTE_ROOT = '/guides/design-tokens';
const ROUTE_TOKEN_REFERENCE = '/guides/design-tokens/token-reference';

const Intro = () => (
  <Markdown>
    {`
# Design Tokens

#### Design tokens are the fundamental building blocks of our visual language. Similar to variables, they contain information about the different aspects of our visual design attributes.

> Their names give purpose to these attributes. And by using them in place of hard-coded values, we ensure that all parts of the system are harmonious and in sync.
`}
  </Markdown>
);

const DesignTokens = () => {
  const responsive = useResponsive();
  const { pathname, hash: encodedHash } = useLocation();
  const hash = decodeURIComponent(encodedHash).replace('#', '');

  return (
    <Box display="flex">
      <Box className={styles.wrapper}>
        <Intro />
        <Box marginTop="m">
          <TabBar rootPath={ROUTE_ROOT}>
            <Tab label="Developer Guide">
              <Markdown dedent={false}>
                { toc.markdown() }
              </Markdown>
              <Markdown>
                { devGuide }
              </Markdown>
            </Tab>
            <Tab label="Token Reference">
              <DesignSystemConsumer>
                {
                  ({ theme }) => {
                    const tokens = Object.entries(tokenDictionary).reduce((t, [token, attrs]) => {
                      return { ...t, [token]: { ...attrs, value: theme.tokens[token] } };
                    }, {});

                    return tokenCategories.map(category => (
                      <TokenCategory key={category.name} tokens={tokens} {...category} />
                    ));
                  }
                }
              </DesignSystemConsumer>
            </Tab>
          </TabBar>
        </Box>
      </Box>
      {
        responsive.desktop && pathname === ROUTE_TOKEN_REFERENCE && (
          <Box className={styles.sidebarContainer}>
            <Box
              marginTop="xl"
              display="flex"
              flexDirection="column"
              className={styles.sidebar}
            >
              <Text type="display3" display="block">Overview</Text>
              <Box marginBottom="m" />
              {
                tokenCategories.map(category => (
                  <a
                    className={cnames(
                      styles.tocLink,
                      {
                        [styles.tocLinkActive]: hash === category.name,
                      },
                    )}
                    href={`#${category.name}`}
                    key={category.name}
                  >
                    <Text type="body2">{category.name}</Text>
                  </a>
                ))
              }
            </Box>
          </Box>
        )
      }
    </Box>
  );
};

export default DesignTokens;
