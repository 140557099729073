import React, { Component } from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import cnames from 'classnames';
import SyntaxHighlighter, { registerLanguage } from 'react-syntax-highlighter/prism-light';
import jsx from 'react-syntax-highlighter/languages/prism/jsx';
import scss from 'react-syntax-highlighter/languages/prism/scss';
import solarizedlight from 'react-syntax-highlighter/styles/prism/solarizedlight';
import styles from './Code.css';

registerLanguage('jsx', jsx);
registerLanguage('scss', scss);

const Expandable = posed.div({
  closed: { height: 0 },
  open: { height: 'auto' },
});

const CodeBlock = React.memo(({ code, language }) => (
  <SyntaxHighlighter
    language={language || 'jsx'}
    wrapLines
    lineProps={{ style: { whiteSpace: 'pre-wrap' } }}
    codeTagProps={{
      style: {
        fontFamily: '"SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace',
      },
    }}
    style={solarizedlight}
    useInlineStyes={false}
    customStyle={{
      marginBottom: 0,
      padding: '0 16px',
      marginTop: 0,
      background: 'transparent',
      fontFamily: '"SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace',
    }}
  >
    { code }
  </SyntaxHighlighter>
));

class Code extends Component {
  render() {
    const {
      children,
      roundedCorners,
      language,
      open,
    } = this.props;

    return (
      <div
        ref={(n) => { this.codeWrapperNode = n; }}
        className={cnames(styles.code,
          {
            [styles.roundedCorners]: roundedCorners,
            [styles.open]: open,
          })}
      >
        <Expandable
          className={styles.codeContent}
          initialPose={open ? 'open' : 'closed'}
          pose={open ? 'open' : 'closed'}
        >
          <CodeBlock language={language} code={children} />
        </Expandable>
      </div>
    );
  }
}

Code.propTypes = {
  children: PropTypes.node.isRequired,
  roundedCorners: PropTypes.bool,
  open: PropTypes.bool,
};

Code.defaultProps = {
  roundedCorners: true,
  open: true,
};

export default Code;
