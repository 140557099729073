import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Redirect, useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import Menu, { MenuLink, MenuSection } from '../../../react/Menu';
import Text from '../../../react/Text';
import Select from '../../../react/Form/Select';
import PageBanner from '../../components/Banner';
import artwork from './assets/artwork.jpg';

// Subpages
import Logo from './Logo';
import Personality from './Personality';
import VoiceAndTone from './VoiceAndTone';
import Illustrations from './Illustrations';
import Photography from './Photography';

const routes = [
  {
    label: 'Logo',
    path: '/logo',
    component: Logo,
  },
  {
    label: 'Illustrations',
    path: '/illustrations',
    component: Illustrations,
  },
  {
    label: 'Photography',
    path: '/photography',
    component: Photography,
  },
  {
    label: 'Personality',
    path: '/personality',
    component: Personality,
  },
  {
    label: 'Voice and tone',
    path: '/voice-and-tone',
    component: VoiceAndTone,
  },
];

const Brand = ({ title }) => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route exact path={path}>
        <Redirect to={`${path}/logo`} />
      </Route>
      { routes.map(route => (
        <Route
          path={path + route.path}
          render={({ match }) => (
            <>
              <Helmet>
                <title>{title} - {route.label}</title>
                <meta property="og:title" content={route.label} />
              </Helmet>
              <route.component match={match} />
            </>
          )}
        />
      ))}
    </>
  );
};

const NavMenuItem = (props) => {
  // const { pathname } = useLocation();
  const history = useHistory();
  const match = useRouteMatch(props.href.replace('examples', ':tab'));

  function handleClick(e) {
    e.preventDefault();
    history.push(props.href);
  }

  return (
    <MenuLink
      {...props}
      onClick={handleClick}
      active={!!match}
    />
  );
};

const Sidebar = () => {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  return (
    <>
      <Text type="display3" display="block" marginBottom={1}>Brand</Text>
      <Menu>
        <MenuSection>
          {
            routes.map(item => (
              <NavMenuItem
                href={url + item.path}
                key={item.label}
                active={url + item.path === pathname}
              >
                {item.label}
              </NavMenuItem>
            ))
          }
        </MenuSection>
      </Menu>
    </>
  );
};

const Banner = ({ mobile }) => {
  return (
    <PageBanner
      artwork={artwork}
      artworkLink="https://www.redbubble.com/shop/ap/12963071"
      shopLink="https://www.redbubble.com/people/matthewdunnart/shop"
      avatar="https://ih0.redbubble.net/avatar.2057800.140x140.jpg"
      title="Ghoul"
      artist="matthewdunnart"
      mobile={mobile}
    />
  );
};

const Nav = () => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { pathname } = useLocation();

  return (
    <>
      { /* eslint-disable-next-line */ }
      <label>
        <Text type="display5" display="block" marginBottom="s">Foundations</Text>
        <Select
          label="Foundations"
          type="select"
          name="foundations"
          placeholder="Foundations"
          value={pathname}
          fluid
          onChange={values => history.push(values)}
          options={
            routes.map(item => (
              { value: url + item.path, label: item.label }
            ))
          }
        />
      </label>
    </>
  );
};

export {
  Nav,
  Sidebar,
  Banner,
  routes,
};

export default Brand;
