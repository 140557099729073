import React, { forwardRef } from 'react';
import cnames from 'classnames';
import Text from '../../../../../../Text';
import CloseIcon from '../../../../../../Icons/Close';
import { MEDIUM } from '../../../../../../constants';
import styles from './styles.scss';

const Pill = forwardRef(({ label, accessibleLabel, onClick, onKeyDown, onKeyUp, invert }, ref) => (
  <button
    className={cnames(styles.context, { [styles.invert]: invert })}
    aria-label={accessibleLabel}
    type="button"
    onClick={onClick}
    onKeyDown={onKeyDown}
    onKeyUp={onKeyUp}
    ref={ref}
  >
    <Text type="display6" display="block">
      {label}
    </Text>
    <div
      className={styles.clearIconWrapper}
    >
      <CloseIcon
        size={MEDIUM}
      />
    </div>
  </button>
));

export default Pill;
