import React from 'react';
import Box from '../../../../../../react/Box';
import Label from '../../../../../../react/Label';

export default () => (
  <Box display="flex" flexDirection="column" flex="1">
    <Box
      padding="xl"
      flex="1"
      style={{ backgroundColor: 'var(--ds-color-background-alt-50)' }}
    />
    <Box
      padding="xl"
      flex="1"
      style={{ backgroundColor: 'var(--ds-color-background-alt-100)' }}
    />
    <Box
      padding="xl"
      flex="1"
      display="flex"
      alignItems="center"
      justifyContent="space-around"
    >
      <Label>Default label</Label>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{
          width: '20px',
          height: '20px',
          backgroundColor: 'var(--ds-color-background-alt-500)',
          borderRadius: 'var(--ds-radius-pill)',
          color: 'var(--ds-color-text-contrast)',
          fontSize: 'var(--ds-font-size-caption)',
          fontWeight: 'var(--ds-font-weight-bold)',
        }}
      >
        2
      </Box>
    </Box>
  </Box>
);
