import React from 'react';
import PropTypes from 'prop-types';
import { MenuLink as ReachMenuLink } from '@reach/menu-button';
import { MenuLink } from '../Menu';
import useTracking, { trackingShape, EVENT_CLICK } from '../hooks/useTracking';
import wrapForReachUI from './wrapForReachUI';

const events = { EVENT_CLICK };

const PreparedMenuLink = wrapForReachUI(MenuLink);

const DropdownLink = ({ onSelect, tracking, ...props }) => {
  const { track } = useTracking(tracking, 'DropdownLink', events);

  return (
    <ReachMenuLink
      as={PreparedMenuLink}
      {...props}
      onSelect={(...args) => {
        track(EVENT_CLICK);
        if (typeof onSelect === 'function') onSelect(...args);
      }}
    />
  );
};

DropdownLink.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  tracking: trackingShape,
};

export default DropdownLink;
