/* eslint-disable max-len */
import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import * as TextExports from '.';
import Flex from '../utils/components/Flex';
import Contain from '../utils/components/Contain';
import Box from '../Box';
import Card from '../Card';
// eslint-disable-next-line
import TextSrc from '!raw-loader!.';

const { default: Text } = TextExports;
const docs = generateDocs(TextSrc, resolver.findAllExportedComponentDefinitions);

const displayExample = {
  name: 'Display Text',
  description: 'Display text is good for headings, introductions and callouts.',
  render: () => (
    <Flex direction="column">
      <Text type="display1">This is rendered in Display 1</Text>
      <Text type="display2">This is rendered in Display 2</Text>
      <Text type="display3">This is rendered in Display 3</Text>
      <Text type="display4">This is rendered in Display 4</Text>
      <Text type="display5">This is rendered in Display 5</Text>
      <Text type="display6">This is rendered in Display 6</Text>
    </Flex>
  ),
  code: `
<Text type="display1">This is rendered in Display 1</Text>
<Text type="display2">This is rendered in Display 2</Text>
<Text type="display3">This is rendered in Display 3</Text>
<Text type="display4">This is rendered in Display 4</Text>
<Text type="display5">This is rendered in Display 5</Text>
<Text type="display6">This is rendered in Display 6</Text>
  `,
};

const bodyExample = {
  name: 'Body Text',
  description: 'Body text is smaller, appropriate for standard UI copy and long form copy.',
  render: () => (
    <Flex direction="column">
      <Contain width="300">
        <Text type="body">
          This is some longer form copy rendered in <strong>Body</strong> that
          wraps on to multiple lines. It should be clear and readable.
        </Text>
      </Contain>

      <Contain width="300">
        <Text type="body2">
          This is some longer form copy rendered in <strong>Body 2 </strong>
          that wraps on to multiple lines. It should be clear and readable.
        </Text>
      </Contain>
    </Flex>
  ),
  code: `
{/** Demo constrained to 300px wide */ }
<Box style={{width: 300}}>
  <Text type="body">
    This is some longer form copy rendered in
    <strong>Body</strong> that wraps on to
    multiple lines. It should be clear and
    readable.
  </Text>
</Box>

<Box style={{width: 300}}>
  <Text type="body2">
    This is some longer form copy rendered in
    <strong>Body 2</strong> that wraps on to
    multiple lines. It should be clear and
    readable.
  </Text>
</Box>
  `,
};

const captionExample = {
  name: 'Caption Text',
  description: 'Caption text is very small, it should only be used where the information is supplementary not critical.',
  render: () => (
    <Flex direction="column">
      <Text type="caption">
        This text is using the Caption style.
      </Text>
    </Flex>
  ),
  code: `
<Text type="caption">
  This text is using the Caption style.
</Text>
  `,
};

const emphasisExample = {
  name: 'Emphasis',
  description: 'Text styles with lighter weights can have segments of text emphasized with <strong> tags.',
  render: () => (
    <Flex direction="column">
      <Text type="display4">
        You can use <strong>strong tags</strong> to call out parts of the text.
      </Text>

      <Text type="body">
        It can be used in <strong>any</strong> of the lighter styles.
      </Text>

      <Text type="caption">
        Even the <strong>super small</strong> ones.
      </Text>
    </Flex>
  ),
  code: `
<Text type="display4">
  You can use <strong>strong tags</strong> to call out parts of the text.
</Text>

<Text type="body">
  It can be used in <strong>any</strong> of the lighter styles.
</Text>

<Text type="caption">
  Even the <strong>super small</strong> ones.
</Text>
  `,
};

const mutedExample = {
  name: 'Muted',
  description: 'You can tone down the Body, Body 2 and Caption styles by applying a muted prop',
  render: () => (
    <Flex direction="column">
      <Text muted type="body">
        This is muted Body copy
      </Text>

      <Text muted type="body2">
        This is muted Body 2 copy
      </Text>

      <Text muted type="caption">
        This is muted Caption copy
      </Text>
    </Flex>
  ),
  code: `
<Text muted type="body">
  This is muted Body copy
</Text>

<Text muted type="body2">
  This is muted Body 2 copy
</Text>

<Text muted type="caption">
  This is muted Caption copy
</Text>
  `,
};

const positioningExample = {
  name: 'Positioning',
  description: 'Text displays inline by default. You can set it to be a block element using the display="block" prop. Similarly, you can use display="inline-block" when necessary.',
  render: () => (
    <Flex direction="column">
      <div>
        <Text muted type="display3">This text is Display 3</Text>
        <Text muted type="display4"> and this is Display 4</Text>
      </div>

      <div>
        <Text muted type="display3" display="block">This one is marked as block</Text>
        <Text muted type="display4">So now this line sits below</Text>
      </div>
    </Flex>
  ),
  code: `
<div>
  <Text muted type="display3">This text is Display 3</Text>
  <Text muted type="display4"> and this is Display 4</Text>
</div>

<div>
  <Text muted type="display3" display="block">This one is marked as block</Text>
  <Text muted type="display4">So now this line sits below</Text>
</div>
  `,
};

const truncateExample = {
  name: 'Truncation',
  description: "You can truncate text by passing it a line clamp equal to the number of the lines you'd like to show before truncating. It's also best to take care of basic usablity and accessibility by passing in an accessible title where necessary.",
  render: () => (
    <Box style={{ maxWidth: 300 }}>
      <Card>
        <Box padding="xl">
          <Text
            lineClamp={1}
            type="display5"
            title="Some pretty long artwork name"
            marginBottom="xxs"
          >
            Some pretty long artwork name
          </Text>
          <Text muted>
            by an awesome <strong>Artist</strong>
          </Text>
        </Box>
      </Card>
    </Box>
  ),
  code: `
<Box style={{ maxWidth: 300 }}>
  <Card>
    <Box padding="xl">
      <Text
        lineClamp={1}
        type="display5"
        title="Some pretty long artwork name"
        marginBottom="xxs"
      >
        Some pretty long artwork name
      </Text>
      <Text muted>
        by an awesome <strong>Artist</strong>
      </Text>
    </Box>
  </Card>
</Box>
`,
};

const elementExample = {
  name: 'Custom Elements',
  description: 'Text displays in a span by default. You can choose from a valid text HTML element using the element prop.',
  render: () => (
    <Flex direction="column">
      <Text type="display5">This is using a span</Text>
      <Text type="display5" element="h1">This is using an h1</Text>
      <Text type="display5" element="h2">This is using an h2</Text>
      <Text type="display5" element="h3">This is using an h3</Text>
      <Text type="display5" element="h4">This is using an h4</Text>
      <Text type="display5" element="h5">This is using an h5</Text>
      <Text type="display5" element="h6">This is using an h6</Text>
      <Text type="display5" element="p">This is using a p</Text>
    </Flex>
  ),
  code: `
<Text type="display5">This is using a span</Text>
<Text type="display5" element="h1">This is using an h1</Text>
<Text type="display5" element="h2">This is using an h2</Text>
<Text type="display5" element="h3">This is using an h3</Text>
<Text type="display5" element="h4">This is using an h4</Text>
<Text type="display5" element="h5">This is using an h5</Text>
<Text type="display5" element="h6">This is using an h6</Text>
<Text type="display5" element="p">This is using a p</Text>
  `,
};

const loading = {
  name: 'Loading placeholders',
  description: 'Text can be rendered in a `loading` state which works well for skeleton or placeholder layouts while content is loading.',
  render: () => (
    <Box display="flex" flexDirection="column" style={{ maxWidth: 400 }}>
      <Text type="display3" loading display="block">
        Some heading text
      </Text>

      <Box marginBottom="xxs" />

      <Text type="body" loading>
        This is some longer form copy rendered in <strong>Body 2 </strong>
        that wraps on to multiple lines.
      </Text>
    </Box>
  ),
  code: `
{/** Demo constrained to 400px wide */}
<Box display="flex" flexDirection="column" style={{ maxWidth: 400 }}>
  <Text type="display3" loading display="block">
    Some heading text
  </Text>

  <Box marginBottom="xxs" />

  <Text type="body" loading>
    This is some longer form copy rendered in <strong>Body 2 </strong>
    that wraps on to multiple lines.
  </Text>
</Box>
  `,
};

const spacingExample = {
  name: 'Spacing',
  description: 'When required you can set margin directly on `Text` similar to that of `Box`.',
  render: () => (
    <>
      <Text display="block" marginBottom="m" type="display1">Text with bottom margin</Text>
      <Text display="block" marginTop="m" marginBottom="xs" type="display2">Text with top and bottom margin</Text>
    </>
  ),
  code: `
<Text display="block" marginBottom="m" type="display1">Text with bottom margin</Text>
<Text display="block" marginLeft="m" marginBottom="xs" type="display2">Text with top and bottom margin</Text>
  `,
};


const Demo = {
  title: Text.displayName,
  description: 'Displays text in a predefined set of styles.',
  slug: 'text',
  exports: Object.keys(TextExports),
  docGen: docs,
  examples: [
    displayExample,
    bodyExample,
    captionExample,
    emphasisExample,
    mutedExample,
    positioningExample,
    loading,
    truncateExample,
    elementExample,
    spacingExample,
  ],
};

export default Demo;
