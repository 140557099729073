import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import Image from '../Image';
import _styles from './styles.scss';
import Skeleton from '../Skeleton';

const LicensingMark = ({
  imageSrc,
  rightsholderName,
  intellectualPropertyName,
  legalLine,
  styles,
  loading,
}) => (
  <div data-testid="licensing-mark">
    <div className={styles.logo}>
      {
        loading ? (
          <Skeleton
            data-testid="loading-logo"
            width={64}
            height={64}
          />
        ) : (
          <Image
            data-testid="logo"
            key={imageSrc}
            src={imageSrc}
            alt={rightsholderName}
          />
        )
      }
    </div>
    <div>
      <Text
        data-testid="ip-name"
        type="display5"
        display="block"
        loading={loading}
      >
        {intellectualPropertyName}
      </Text>
      <Text
        data-testid="legal-line"
        type="body"
        display="block"
        loading={loading}
      >
        {legalLine}
      </Text>
    </div>
  </div>
);

LicensingMark.propTypes = {
  /**
   * HTML <img> src
   */
  imageSrc: PropTypes.string.isRequired,
  /**
   * Name of the rights holder (eg. Adult Swim)
   */
  rightsholderName: PropTypes.string.isRequired,
  /**
   * Name of the intellectual property (eg. Rick & Morty)
   */
  intellectualPropertyName: PropTypes.string.isRequired,
  /**
   * CR - Copyright
   */
  legalLine: PropTypes.string.isRequired,
  /**
    * The CSS classNames for this component. NEVER manually specify this prop
    */
  styles: PropTypes.shape({}),
};

LicensingMark.defaultProps = {
  styles: _styles,
};

LicensingMark.displayName = 'LicensingMark';

export default LicensingMark;
