import React from 'react';
import PropTypes from 'prop-types';
import { parse as generateDocs, resolver } from 'react-docgen';
import Card, { CardBody } from '../Card';

import * as LabelExports from '.';

// eslint-disable-next-line
import LabelSrc from '!raw-loader!.';

const { default: Label } = LabelExports;
const docs = generateDocs(LabelSrc, resolver.findAllExportedComponentDefinitions);

const LabelRow = ({ children }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    { React.Children.map(children, b => <div style={{ marginRight: '8px' }}>{b}</div>) }
  </div>
);

LabelRow.propTypes = {
  children: PropTypes.node.isRequired,
};

const labelBasic = {
  name: 'Simple usage',
  description: 'Labels can be rendered with different intents.',
  muted: true,
  render: () => (
    <React.Fragment>
      <Card>
        <CardBody>
          <LabelRow>
            <Label>Default</Label>
            <Label intent="success">Success</Label>
            <Label intent="information">Information</Label>
            <Label intent="warning">Warning</Label>
            <Label intent="error">Error</Label>
          </LabelRow>
        </CardBody>
      </Card>
    </React.Fragment>
  ),
  code: `
<Label>Default</Label>
<Label intent="success">Success</Label>
<Label intent="information">Information</Label>
<Label intent="warning">Warning</Label>
<Label intent="error">Error</Label>
  `,
};

const labelSubtle = {
  name: 'Subtle label',
  description: 'These can be useful when there are many of them in one screen and you want to reduce visual noise.',
  muted: true,
  render: () => (
    <React.Fragment>
      <Card>
        <CardBody>
          <LabelRow>
            <Label subtle>Default</Label>
            <Label intent="success" subtle>Success</Label>
            <Label intent="information" subtle>Information</Label>
            <Label intent="warning" subtle>Warning</Label>
            <Label intent="error" subtle>Error</Label>
          </LabelRow>
        </CardBody>
      </Card>
    </React.Fragment>
  ),
  code: `
<Label subtle>Default</Label>
<Label intent="success" subtle>Success</Label>
<Label intent="information" subtle>Information</Label>
<Label intent="warning" subtle>Warning</Label>
<Label intent="error" subtle>Error</Label>
  `,
};

const labelOverflow = {
  name: 'Long labels',
  description: 'If your content is long it will truncate and will not wrap.',
  muted: true,
  render: () => (
    <React.Fragment>
      <Card>
        <CardBody>
          <LabelRow>
            <Label subtle>Default label with long text</Label>
          </LabelRow>
        </CardBody>
      </Card>
    </React.Fragment>
  ),
  code: `
<Label subtle>Default label with long text</Label>
  `,
};

const Demo = {
  title: Label.displayName,
  description: ' Labels are used to tag content by type or status.',
  docGen: docs,
  slug: 'Label',
  exports: Object.keys(LabelExports),
  examples: [
    labelBasic,
    labelSubtle,
    labelOverflow,
  ],
  notes: `
  `,
};

export default Demo;
