import React from 'react';
import groupBy from 'lodash/groupBy';
import Color from 'color';
import cnames from 'classnames';
import useResponsive from '../../../hooks/useResponsive';
import useDOMState from '../../../hooks/useDOMState';
import Markdown from '../../../components/Markdown';
import Box from '../../../../react/Box';
import Image from '../../../../react/Image';
import * as constants from '../../../../react/constants';
import Text from '../../../../react/Text';
import TokenDictionary from '../../../../react/themes/tokenDictionary';
import * as tokenTypes from '../../../../react/themes/tokenTypes';
import IntroSection from './Intro/IntroSection';
import { resolveTokenValue } from '../../../../react/themes/utils';

import illoAccessibility from './Overview/color-accessibility.svg';
import illoRestraint from './Overview/color-restraint.svg';
import illoConsistency from './Overview/color-consistency.svg';
import hint from './hint.svg';

import styles from './Color.css';
import { demos, copy } from './dynamicIncludes';

// Determine if a color is light enough to need a dark background
const isLight = hexColor => Color(hexColor).luminosity() > 0.95;

// Determine if a color is transparent
const isAlpha = hexColor => Color(hexColor).valpha < 1;

const Palette = ({ intro, tokens, hints }) => {
  const { mobile } = useResponsive();
  const isMobile = mobile;

  return (
    <Box marginBottom={2} paddingBottom={2}>
      <Box marginBottom={2}>
        { intro }
      </Box>
      <Box display="flex" flexDirection="column" marginTop={1}>
        {tokens.map((token) => {
          const name = token.name.replace(/-|ds|color/g, ' ');
          return (
            <Box
              className={styles.paletteContainer}
              display="flex"
              alignItems="center"
            >
              <Box
                className={cnames(styles.paletteSwatchOuter, {
                  [styles.transparent]: isAlpha(token.value),
                })}
                marginRight={1}
              >
                <Box
                  className={cnames(styles.paletteSwatchInner, {
                    [styles.border]: isLight(token.value),
                  })}
                  style={{
                    backgroundColor: token.value,
                  }}
                />
              </Box>
              <Box
                display="flex"
                flexDirection={isMobile ? 'column' : 'row'}
                alignItems={isMobile ? 'flex-start' : 'center'}
                flexWrap="wrap"
                paddingTop={0.5}
              >
                <Box
                  className={styles.monoLabel}
                  display="inline-block"
                  marginRight={0.5}
                  marginBottom={isMobile && 0.25}
                >
                  <Text type="caption" display="block">{token.value}</Text>
                </Box>
                <Text type="caption" display="block" muted={isMobile}>{name}</Text>
              </Box>
            </Box>
          );
        })}
      </Box>
      {hints && (
        <React.Fragment>
          <Box
            display="flex"
            alignItems="center"
            padding={1}
            marginTop={1}
            style={{
              background: 'var(--ds-color-background-alt-50',
              borderRadius: 'var(--ds-radius-medium)',
            }}
          >
            <Image size={constants.TINY} src={hint} />
            <Box paddingLeft={1}>
              { hints }
            </Box>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};


const ColorPage = () => {
  const readyState = useDOMState();
  if (readyState !== 'complete') return null;

  // Limit to color tokens only
  let colorTokenTypes = Object.entries(TokenDictionary).filter(e => (
    e[1].type === tokenTypes.COLOR
  ));

  // Map to simple array of object with type, name and value from current theme
  colorTokenTypes = colorTokenTypes.map(([token, definition]) => ({
    type: definition.semanticType,
    name: token,
    value: resolveTokenValue(token),
  }));

  colorTokenTypes = groupBy(colorTokenTypes, 'type');

  return (
    <Box>
      <Markdown source={copy.intro} />

      <Box marginTop="xl" marginBottom="xl" />

      <Markdown source={copy.introOverview} />

      <Box paddingTop={2} />

      <IntroSection imageOn="right" imageSrc={illoAccessibility}>
        <Markdown source={copy.introAccessibility} />
      </IntroSection>

      <IntroSection imageSrc={illoRestraint}>
        <Markdown source={copy.introRestraint} />
      </IntroSection>

      <IntroSection imageOn="right" imageSrc={illoConsistency}>
        <Markdown source={copy.introConsistency} />
      </IntroSection>

      <Box marginTop="xl" marginBottom="xl" />

      <Markdown source={copy.tokensOverview} />
      <Box marginBottom={2} paddingBottom={2} />

      <Markdown source={copy.tokensCore} />
      <Box marginBottom={2} paddingBottom={1} />

      <Palette
        intro={(
          <Markdown source={copy.brand} />
        )}
        tokens={colorTokenTypes[tokenTypes.BRAND]}
      />

      <Palette
        intro={(
          <Markdown source={copy.primary} />
        )}
        tokens={colorTokenTypes[tokenTypes.PRIMARY]}
      />

      <Palette
        intro={(
          <Markdown source={copy.neutrals} />
        )}
        tokens={colorTokenTypes[tokenTypes.NEUTRAL]}
      />
      <Markdown source={copy.intents} />

      {
        [
          [copy.informationIntent, 'information'],
          [copy.successIntent, 'success'],
          [copy.warningIntent, 'warning'],
          [copy.errorIntent, 'error'],
        ].map(([c, i]) => (
          <Palette
            intro={(
              <Markdown source={c} />
            )}
            tokens={colorTokenTypes[tokenTypes.INTENT].filter(t => t.name.includes(i))}
          />
        ))
      }

      <Markdown source={copy.expressive} />

      {
        [
          [copy.blue, 'blue'],
          [copy.green, 'green'],
          [copy.red, 'red'],
          [copy.yellow, 'yellow'],
          [copy.pink, 'pink'],
          [copy.teal, 'teal'],
          [copy.purple, 'purple'],
          [copy.orange, 'orange'],
        ].map(([c, i]) => (
          <Palette
            intro={(
              <Markdown source={c} />
            )}
            tokens={
              colorTokenTypes[tokenTypes.EXPRESSIVE].filter(t => t.name.includes(i))
            }
          />
        ))
      }

      <Palette
        intro={(
          <Markdown source={copy.common} />
        )}
        tokens={colorTokenTypes[tokenTypes.COMMON]}
      />

      <Box marginBottom={2} paddingBottom={1} />
      <Markdown source={copy.tokensSemantic} />
      <Box marginBottom={2} paddingBottom={1} />

      <Palette
        intro={(
          <Markdown source={copy.text} />
        )}
        demo={(
          <React.Fragment>
            <demos.Text />
          </React.Fragment>
        )}
        tokens={colorTokenTypes[tokenTypes.TEXT]}
        hints={(
          <React.Fragment>
            {/* eslint-disable max-len */}
            <Text type="body2" display="block">Be careful when using muted text on grey backgrounds and make sure they have enough contrast.</Text>
            {/* eslint-enable max-len */}
          </React.Fragment>
        )}
      />
      <Markdown source={copy.backgroundColors} />

      <Palette
        intro={(
          <Markdown source={copy.uiBackground} />
        )}
        tokens={colorTokenTypes[tokenTypes.BACKGROUND].filter(t => t.name.includes('ui'))}
        demo={(
          <demos.UiBackground />
        )}
      />

      <Palette
        demo={(
          <demos.AltBackground />
        )}
        intro={(
          <Markdown source={copy.altBackground} />
        )}
        tokens={colorTokenTypes[tokenTypes.BACKGROUND].filter(t => t.name.includes('alt'))}
      />

      <Palette
        demo={(
          <demos.AppBackground />
        )}
        intro={(
          <Markdown source={copy.appBackground} />
        )}
        tokens={colorTokenTypes[tokenTypes.BACKGROUND].filter(t => t.name.includes('app'))}
      />

      <Palette
        demo={(
          <demos.OverlayBackground />
        )}
        intro={(
          <Markdown source={copy.overlayBackground} />
        )}
        tokens={colorTokenTypes[tokenTypes.BACKGROUND].filter(t => t.name.includes('overlay'))}
      />

      <Palette
        demo={(
          <demos.Borders />
        )}
        intro={(
          <Markdown source={copy.borders} />
        )}
        tokens={colorTokenTypes[tokenTypes.BORDER]}
      />

      <Palette
        demo={(
          <demos.StateColors />
        )}
        intro={(
          <Markdown source={copy.stateColors} />
        )}
        tokens={colorTokenTypes[tokenTypes.STATE]}
      />
    </Box>
  );
};

export default ColorPage;
