export default `
## CSS Custom Properties

In Redbubble's Design System, tokens take the form of [CSS Custom Properties](https://developer.mozilla.org/en-US/docs/Web/CSS/--*) - a relatively new form of "variable" that allows the sharing of values between CSS rules.


For example, to give two elements the same text color and keep them in sync with one another:

\`\`\`
:root {
  --shared-color: #00ff00;
}

.itemA {
  color: var(--shared-color);
}

.itemB {
  color: var(--shared-color);
}
\`\`\`

If we change the value of \`--shared-color\` on the \`:root\`, both the items would change to reflect that.

## Usage

### Installation and Configuration


To use our design tokens you'll need to wrap your application in the \`DesignSystemProvider\` and provide it with a theme. Themes are predefined sets of tokens that represent a visual language. You can read more about themes [here](/guidelines/theming).

\`\`\`
// e.g. my/app/app.js

import theme from '@redbubble/design-system/react/themes/default';
import DesignSystemProvider from '@redbubble/design-system/react/DesignSystemProvider';

const App = (props) => (
  <DesignSystemProvider config={{ theme }}>
    <div>
      Your app tree
    </div>
  </DesignSystemProvider>
);
\`\`\`

The \`DesignSystemProvider\` will inject the tokens for the given theme onto the \`:root\` element making them accessible to both your application's CSS and the Design System components.

### Tokens in Design System components

Tokens are used to style all the components in the Design System. For example our \`Card\` and \`Alert\` share the same border radius by referencing the same token. By using tokens, we can make sweeping visual changes to our components by changing tokens in one central place.

When you use our components, your UI is automatically using tokens, no additional effort is required.

### Tokens in your application's CSS

If you find yourself in a situation where you can't use Design System components to achieve your desired UI, you should still use the tokens in your own CSS. Since they're on the \`:root\` element, they're available globally in your application styles - no import necessary.

\`\`\`
// e.g my/app/components/StickyNav/styles.css

.stickyNav {
  background: var(--ds-color-background-ui);
}
\`\`\`

## Naming Conventions

The Design System's tokens are all prefixed with \`--ds\` to make them easy to recognize and to ensure they don't clash with application-specific custom properties.

> 💡 **Remember**
>
> CSS Custom Properties are not a capability of the Design System, but rather they're a part of the CSS language which you can use in your own apps for you own styling abstractions.

Along with the \`--ds\` prefix, our tokens mostly adhere to the following naming convention:

\`--ds-{attributeName}-{modifiers}\`

**attributeName**

Which attribute of the visual language this token relates to.

\`--ds-\`**\`color\`**\`-neutral-500: #xxxxxx;\`

\`--ds-\`**\`radius\`**\`-small: 4px;\`

\`--ds-\`**\`size\`**\`-dimension-01: 4px;\`

**modifiers**

Semantic or stateful modifiers that scope the token to a more specific use case.

\`--ds-color-\`**\`body-contrast\`**\`: #xxxxxx;\`

\`--ds-color-\`**\`primary-200\`**\`: #xxxxxx;\`

\`--ds-radius-\`**\`small\`**\`: 4px;\`


## Browser Compatibility

CSS Custom Properties are not supported in all browsers. We recommend that you polyfill them where possible.

You can use the [postcss-custom-properties](https://github.com/postcss/postcss-custom-properties) plugin and provide it with the tokens from the theme you're using:

\`\`\`

import tokens from '@redbubble/design-system/react/themes/default/tokens';

// PostCSS config
postcssCustomProperties({ importFrom: [{ customProperties: tokens }] });
\`\`\`

## SCSS Variables

The Design System has historically used SCSS variables as a means of sharing design tokens.
This approach is now ⚠️  officially deprecated in favour of our new Design Tokens described above.

Please refer to our [SCSS Deprecation](/guidelines/sass-deprecator) Guidelines for how to migrate.
`;
