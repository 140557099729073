import image from '../assets/how-we-speak.svg';

/* eslint-disable max-len */
const markdown = `
Now that we know who Redbubble is, let’s discuss how Redbubble speaks. Not just the words we use, but how we choose to use them. What language best reflects the heart, brain, and soul of the company. Here are a few things to consider.
### Be real
Our customers are real people, not numbers on a marketing chart. Let’s treat them that way. Whenever possible, avoid technical jargon, industry clichés, and ornate vocabulary. Our voice is human and approachable.
### Keep it simple
Always get straight to the point. Be focused and efficient. Use short, digestible sentences. Our goal is to make life better for people, not waste their time with verbose speeches. Instead of “utilize,” say “use.” Everyone will be that much better for it.
### Keep it moving
We are progressive and proactive. Having said that, there is no room to be idle or passive. We are working right now, at this very moment, to provide the best experience possible for artists and customers. Always write in the active voice.
### Have a sense of humor
Yes, this is a business. And yes, we do take it very seriously. But we’re human, remember? As a creative and friendly brand, a few clever jokes (and some bad puns) only serves to underline our personality. But always remember where you are and who you’re talking to.
### Be consistent
The voice should appear in everything we say and write. The site. Marketing. Customer service. The works. Only then will we have a brand voice that is both recognizable and true to who we are.
### No! Need! To! Shout!
What we’re offering is exciting enough. And there’s so much noise out there already. So please, put down the exclamation points and back away slowly. Of course, there are always exceptions. Customer service is one example. Thanks for reading!
`;

const OurTone = {
  markdown,
  image,
  title: 'Our tone',
  subTitle: 'How we speak',
  slug: 'our-tone',
};

export default OurTone;
