import { useEffect, useState } from 'react';

function useDOMState() {
  const [readyState, setReadyState] = useState(document.readyState);

  function handleDOM() {
    setReadyState(document.readyState);
  }

  useEffect(() => {
    document.addEventListener('readystatechange', handleDOM);
    return () => {
      document.removeEventListener('readystatechange', handleDOM);
    };
  }, []);

  return readyState;
}

export default useDOMState;
