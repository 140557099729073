import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import {
  RovingTabIndexProvider,
  useRovingTabIndex,
  useFocusEffect,
} from 'react-roving-tabindex';
import * as constants from '../constants';
import useTracking, { trackingShape, EVENT_CLICK } from '../hooks/useTracking';
import Box from '../Box';
import styles from './styles.scss';
import getAriaProps from '../utils/getAriaProps';
import getDataProps from '../utils/getDataProps';

const Toolbar = ({ children, ...props }) => {
  return (
    <Box
      className={styles.toolbar}
      data-testid="ds-toolbar"
      role="toolbar"
      display="flex"
      {...getAriaProps(props)}
      {...getDataProps(props)}
    >
      <RovingTabIndexProvider>
        {React.Children.map(children, child => (
          <Box marginRight="xs" display="flex">
            {child}
          </Box>
        ))}
      </RovingTabIndexProvider>
    </Box>
  );
};

Toolbar.propTypes = {
  /**
   * The content of the toolbar
   */
  children: PropTypes.node.isRequired,
};

Toolbar.defaultProps = {};

Toolbar.displayName = 'Toolbar';

/* Toolbar Divider */

const ToolbarDivider = () => {
  return <Box className={styles.ToolbarDivider} />;
};

ToolbarDivider.propTypes = {};

ToolbarDivider.defaultProps = {};

ToolbarDivider.displayName = 'ToolbarDivider';

/* Toolbar Button */

const { DESTRUCTIVE, NEUTRAL } = constants;

const getClassNames = (props) => {
  const { intent, elevation, disabled, active, children } = props;

  return cnames(styles.button, {
    [styles.destructive]: intent === DESTRUCTIVE,
    [styles.neutral]: intent === NEUTRAL,
    [styles.elevation]: !!elevation,
    [styles.elevationLow]: elevation === constants.ELEVATION_LOW,
    [styles.elevationMedium]: elevation === constants.ELEVATION_MEDIUM,
    [styles.elevationHigh]: elevation === constants.ELEVATION_HIGH,
    [styles.active]: active,
    [styles.disabled]: disabled,
    [styles.hasChildren]: typeof children !== 'undefined',
  });
};

const preventDefault = (props) => {
  const { href, disabled } = props;

  return href === '' || disabled;
};

/* Toolbar Button */
const ToolbarButton = (props) => {
  const ref = useRef(null);

  const [
    tabIndex,
    focused,
    handleKeyDown,
    handleClickRoving,
  ] = useRovingTabIndex(ref);

  useFocusEffect(focused, ref);

  const {
    children,
    intent,
    iconBefore,
    iconAfter,
    className,
    disabled,
    elevation,
    active,
    onClick,
    tracking,
    ...rest
  } = props;

  const { track } = useTracking(tracking, 'ToolbarButton');

  const handleClick = (e) => {
    handleClickRoving(e);
    track(EVENT_CLICK);

    if (preventDefault(props)) {
      e.preventDefault();
    }

    if (disabled) {
      return;
    }

    if (typeof onClick === 'function') {
      onClick(e);
    }
  };

  return (
    <button
      onKeyDown={handleKeyDown}
      onClick={handleClick}
      disabled={!!disabled}
      type="button"
      ref={ref}
      tabIndex={tabIndex}
      data-testid="ds-toolbar-button"
      {...rest}
      className={getClassNames(props)}
    >
      {iconBefore || iconAfter ? (
        <React.Fragment>
          {iconBefore && (
            <span className={styles.iconBefore}>{iconBefore}</span>
          )}
          {children && <span className={styles.children}>{children}</span>}
          {iconAfter && <span className={styles.iconAfter}>{iconAfter}</span>}
        </React.Fragment>
      ) : (
        <span className={styles.children}>{children}</span>
      )}
    </button>
  );
};

ToolbarButton.displayName = 'ToolbarButton';

ToolbarButton.propTypes = {
  /**
   * The text to be shown in the button
   */
  children: PropTypes.node,
  /**
   * Fired when the button is clicked
   */
  onClick: PropTypes.func,
  /**
   * Changes the appearance of the button based on it's semantic purpose.
   */
  intent: PropTypes.oneOf([DESTRUCTIVE, NEUTRAL]),
  /**
   * How high (shadow) is the button floating above other content on the page
   */
  elevation: PropTypes.oneOf([
    constants.ELEVATION_LOW,
    constants.ELEVATION_MEDIUM,
    constants.ELEVATION_HIGH,
    null,
  ]),
  /**
   * When true, disables interactions with the button and visually grays it out
   */
  disabled: PropTypes.bool,
  /**
   * An <Icon /> to show on the right of the button
   */
  iconAfter: PropTypes.node,
  /**
   * An <Icon /> to show on the left of the button
   */
  iconBefore: PropTypes.node,
  /**
   * Whether a button should appear to be toggled on or not
   */
  active: PropTypes.bool,
  /**
   * Tracking configuration for auto tracking
   */
  tracking: trackingShape,
  /**
   * Used to indicate a value for the button in a ToolbarRadioButtonGroup
   */
  value: PropTypes.string,
};

ToolbarButton.defaultProps = {
  onClick: null,
  iconBefore: null,
  iconAfter: null,
  intent: NEUTRAL,
  elevation: null,
  disabled: false,
  active: false,
  tracking: null,
  value: null,
};

const ToolbarRadioButtonGroup = ({ children, value, onChange, ...props }) => {
  return (
    <ToolbarButtonGroup {...props}>
      {
        React.Children.map(children, (child) => {
          const active = value && child.props.value === value;

          return React.cloneElement(child, {
            active,
            onClick: (e) => {
              if (typeof onChange === 'function') {
                onChange(child.props.value);
              }

              if (typeof child.props.onClick === 'function') {
                child.props.onClick(e);
              }
            },
          });
        })
      }
    </ToolbarButtonGroup>
  );
};

ToolbarRadioButtonGroup.propTypes = {
  /**
   * The value of the active button
   */
  value: PropTypes.string,
  /**
   * ToolbarButtons
   */
  children: PropTypes.node.isRequired,
  /**
   * Called with any clicked button's value
   */
  onChange: PropTypes.func,
};

ToolbarRadioButtonGroup.defaultProps = {};

const ToolbarButtonGroup = ({ children }) => {
  return (
    <Box className={styles.toolbarButtonGroup} display="flex">
      {children}
    </Box>
  );
};

ToolbarButtonGroup.propTypes = {
  /**
   * The content of the toolbar group
   */
  children: PropTypes.node.isRequired,
};

ToolbarButtonGroup.defaultProps = {};

ToolbarButtonGroup.displayName = 'ToolbarButtonGroup';

export default Toolbar;
export {
  ToolbarDivider,
  ToolbarButton,
  ToolbarButtonGroup,
  ToolbarRadioButtonGroup,
};
