import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import get from 'lodash/get';
import _styles from './styles.scss';
import getElementType from '../utils/getElementType';
import childTypeEquals from '../utils/childTypeEquals';
import useTracking, { trackingShape } from '../hooks/useTracking';
import ChevronRightIcon from '../Icons/ChevronRightBig';
import Box from '../Box';

const ListMedia = (props) => {
  const {
    children,
    className,
    styles,
  } = props;

  const classNames = cnames(styles.listMedia, {
    [className]: className,
  });

  return (
    <div className={classNames}>
      { children }
    </div>
  );
};

ListMedia.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  styles: PropTypes.shape({}),
};

ListMedia.defaultProps = {
  className: '',
  styles: _styles,
};

ListMedia.displayName = 'ListMedia';

const ListContent = (props) => {
  const {
    children,
    className,
    styles,
  } = props;

  const classNames = cnames(styles.listContent, {
    [className]: className,
  });

  return (
    <div className={classNames}>
      { children }
    </div>
  );
};

ListContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  styles: PropTypes.shape({}),
};

ListContent.defaultProps = {
  className: '',
  styles: _styles,
};

ListContent.displayName = 'ListContent';

const ListItem = (props) => {
  const {
    children,
    className,
    arrow,
    element,
    styles,
    bulletMedia,
    ...rest
  } = props;

  const Element = getElementType(ListItem, props);

  const containsMediaOrContent = React.Children
    .toArray(children)
    .some(child => childTypeEquals(child, [ListMedia.displayName, ListContent.displayName]));

  const classNames = cnames(styles.listItem, {
    [className]: className,
    [styles.flex]: containsMediaOrContent,
    [styles.arrow]: arrow,
    [styles.customBulletedListItem]: bulletMedia,
  });

  return (
    <Element className={classNames} {...rest}>
      { bulletMedia && <div className={styles.bulletMedia}>{bulletMedia}</div> }
      { children }
      { arrow && <Box className={styles.arrowIcon}><ChevronRightIcon /></Box>}
    </Element>
  );
};

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  /**
    * Define what element to use for the list
  */
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  /**
    * Display an arrow at the end of the list item
    */
  arrow: PropTypes.bool,
  /**
    * Display a custom bullet
    */
  bulletMedia: PropTypes.node,
  /**
    * Custom styles on the list item
    */
  styles: PropTypes.shape({}),
};

ListItem.defaultProps = {
  className: '',
  element: 'li',
  arrow: false,
  bulletMedia: null,
  styles: _styles,
};

ListItem.displayName = 'ListItem';

const List = forwardRef((props, ref) => {
  const {
    children,
    className,
    ordered,
    bulleted,
    bulletMedia,
    divided,
    relaxed,
    padded,
    styles,
    borderTop,
    borderBottom,
    tracking,
    ...rest
  } = props;
  const { TrackingContextProvider } = useTracking(tracking, 'List');

  const Element = ordered ? 'ol' : getElementType(List, props);

  const classNames = cnames(styles.list, {
    [className]: className,
    [styles.bulleted]: bulleted || bulletMedia,
    [styles.customBulleted]: bulletMedia,
    [styles.divided]: divided,
    [styles.relaxed]: relaxed || divided,
    [styles.padded]: padded,
    [styles.borderTop]: borderTop,
    [styles.borderBottom]: borderBottom,
  });

  return (
    <TrackingContextProvider>
      <Element
        className={classNames}
        {...rest}
        ref={ref}
      >
        {
        React.Children
          .toArray(children)
          .filter(Boolean)
          .map(child => (
            React.cloneElement(child, {
              bulletMedia: get(child, 'props.bulletMedia') || bulletMedia,
            })
          ))
        }
      </Element>
    </TrackingContextProvider>
  );
});

List.propTypes = {
  /**
    * The content of the list, typically ListItems
    */
  children: PropTypes.node.isRequired,
  /**
    * Custom className. Recommended for positioning in a layout only
    */
  className: PropTypes.string,
  /**
    * The HTML element to use for the List
    */
  element: PropTypes.string,
  /**
    * When true, displays numbered bullets if bulleted is also true
    */
  ordered: PropTypes.bool,
  /**
    * When true, displays bullets for each ListItem
    */
  bulleted: PropTypes.bool,
  /**
    * When true, displays lines between ListItems
    */
  divided: PropTypes.bool,
  /**
    * When true, leaves more space between each ListItem
    */
  relaxed: PropTypes.bool,
  /**
    * When true, adds padding on the left and right of the ListItems
    */
  padded: PropTypes.bool,
  /**
    * The CSS classNames for this component. NEVER manually specify this prop
    */
  styles: PropTypes.shape({}),
  /**
    * Display a separating border above the first list item
    */
  borderTop: PropTypes.bool,
  /**
    * Display a separating border below the last list item
    */
  borderBottom: PropTypes.bool,
  /**
    * Tracking configuration for auto tracking
    */
  tracking: trackingShape,
  /**
   * Display a custom bullet point
   */
  bulletMedia: PropTypes.node,
};

List.defaultProps = {
  className: '',
  element: 'ul',
  ordered: false,
  bulleted: false,
  divided: false,
  relaxed: false,
  padded: false,
  styles: _styles,
  borderTop: false,
  borderBottom: false,
  tracking: null,
};

List.displayName = 'List';

export { ListItem, ListContent, ListMedia };
export default List;
