/* eslint-disable max-len */
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import PageIntro from '../../../components/PageIntro';
import PageDescription from '../../../components/PageDescription';
import PageTitle from '../../../components/PageTitle';
import Text from '../../../../react/Text';
import Box from '../../../../react/Box';
import TextLink from '../../../../react/TextLink';
import Label from '../../../../react/Label';
import List, { ListItem } from '../../../../react/List';
import styles from './Typography.css';
import Code from '../../../components/Code';

const WEBPACK_URL = 'https://github.com/redbubble/design/tree/master/WEBPACK_GUIDE.md#fonts';

const Paragraph = props => (
  <Text type="body" element="p" className={styles.paragraph} display="block" {...props} />
);

const Heading = props => (
  <Text type="display2" element="h2" className={styles.heading} display="block" {...props} />
);

const SubHeading = props => (
  <Text type="display5" element="h3" display="block" {...props} />
);

const Link = props => <ReactRouterLink className={styles.link} {...props} />;
// eslint-disable-next-line
const A = props => <a className={styles.link} {...props} />;

const Typography = () => {
  return (
    <div>
      <PageIntro>
        <PageTitle>Typography</PageTitle>
        <PageDescription>
          Typography is critical to creating a hierarchy of content on our pages. It tells a story and guides our users to the most important areas of the experience.
        </PageDescription>
      </PageIntro>

      <Heading>
        Font Stack
      </Heading>

      <Paragraph>
        The Redbubble Design System uses a combination of <TextLink href="https://developer.apple.com/fonts/" target="_blank">Apple System Fonts</TextLink> and <TextLink href="https://rsms.me/inter/" target="_blank">Inter</TextLink> in these weights:
      </Paragraph>

      <Box marginBottom="xs" display="flex" alignItems="center">
        <Label subtle>400</Label>
        <Text marginLeft="xs">Regular</Text>
      </Box>

      <Box marginBottom="xs" display="flex" alignItems="center">
        <Label subtle>600</Label>
        <Text type="display5" marginLeft="xs">Semibold</Text>
      </Box>

      <Heading>
        Type
      </Heading>
      <Paragraph>
        Our typographic scale is limited to 9 types for consistency and ease of use.
      </Paragraph>

      <table className={styles.table} cellSpacing="0">
        <tr>
          <th>
            <Text type="display5" element="h3" display="block">Display</Text>
          </th>
          <th>
            <Text type="display5" element="h3" display="block">Size</Text>
          </th>
          <th>
            <Text type="display5" element="h3" display="block">Line height</Text>
          </th>
          <th>
            <Text type="display5" element="h3" display="block">Weight</Text>
          </th>
        </tr>
        <tr>
          <td>
            <Text type="display1">Display1</Text>
          </td>
          <td>
            <Text type="body">36px</Text>
          </td>
          <td>
            <Text type="body">48px</Text>
          </td>
          <td>
            <Text type="body">600</Text>
            <Text type="body" muted> (Semibold)</Text>
          </td>
        </tr>
        <tr>
          <td>
            <Text type="display2">Display2</Text>
          </td>
          <td>
            <Text type="body">24px</Text>
          </td>
          <td>
            <Text type="body">32px</Text>
          </td>
          <td>
            <Text type="body">600</Text>
            <Text type="body" muted> (Semibold)</Text>
          </td>
        </tr>
        <tr>
          <td>
            <Text type="display3">Display3</Text>
          </td>
          <td>
            <Text type="body">20px</Text>
          </td>
          <td>
            <Text type="body">30px</Text>
          </td>
          <td>
            <Text type="body">600</Text>
            <Text type="body" muted> (Semibold)</Text>
          </td>
        </tr>
        <tr>
          <td>
            <Text type="display4">Display4</Text>
          </td>
          <td>
            <Text type="body">20px</Text>
          </td>
          <td>
            <Text type="body">30px</Text>
          </td>
          <td>
            <Text type="body">400</Text>
            <Text type="body" muted> (Regular)</Text>
          </td>
        </tr>
        <tr>
          <td>
            <Text type="display5">Display5</Text>
          </td>
          <td>
            <Text type="body">16px</Text>
          </td>
          <td>
            <Text type="body">26px</Text>
          </td>
          <td>
            <Text type="body">600</Text>
            <Text type="body" muted> (Semibold)</Text>
          </td>
        </tr>
        <tr>
          <td>
            <Text type="display6">Display6</Text>
          </td>
          <td>
            <Text type="body">14px</Text>
          </td>
          <td>
            <Text type="body">24px</Text>
          </td>
          <td>
            <Text type="body">600</Text>
            <Text type="body" muted> (Semibold)</Text>
          </td>
        </tr>
        <tr>
          <td>
            <Text type="body">Body</Text>
          </td>
          <td>
            <Text type="body">16px</Text>
          </td>
          <td>
            <Text type="body">26px</Text>
          </td>
          <td>
            <Text type="body">400</Text>
            <Text type="body" muted> (Regular)</Text>
          </td>
        </tr>
        <tr>
          <td>
            <Text type="body">body2</Text>
          </td>
          <td>
            <Text type="body">14px</Text>
          </td>
          <td>
            <Text type="body">24px</Text>
          </td>
          <td>
            <Text type="body">400</Text>
            <Text type="body" muted> (Regular)</Text>
          </td>
        </tr>
        <tr>
          <td>
            <Text type="caption">Caption</Text>
          </td>
          <td>
            <Text type="body">12px</Text>
          </td>
          <td>
            <Text type="body">16px</Text>
          </td>
          <td>
            <Text type="body">400</Text>
            <Text type="body" muted> (Regular)</Text>
          </td>
        </tr>
      </table>

      <Heading>
        Usage
      </Heading>

      <Paragraph>
        To use the Design System font, simply follow
        the <Link className={styles.link} to="/guidelines/getting-started">Getting Started</Link> guide,
        ensuring you include the core CSS in your app:
      </Paragraph>

      <Code className={styles.code}>
        {`
@import '~@redbubble/design-system/styles/core.scss';
        `}
      </Code>

      <Paragraph>
        You’ll also need to <TextLink href={WEBPACK_URL} target="_blank">configure Webpack</TextLink> for font loading to work.
      </Paragraph>

      <Paragraph>
        We recommend using the <Link className={styles.link} to="/components/text">Text</Link> component to adhere to our typography guidelines.
      </Paragraph>

      <Heading>
        JavaScript API
      </Heading>

      <Paragraph>
        We also expose our fonts and their metadata as a JavaScript export:
      </Paragraph>

      <Code className={styles.code}>
        {`
import fonts from '@redbubble/design-system/styles/fonts';

// [{ name: 'regular', path: 'http://your.app/static/erg8489g.woff2' }, ...]
        `}
      </Code>

      <SubHeading>
        Font Properties
      </SubHeading>

      <Paragraph>
        <List className={styles.fontProperties}>
          <ListItem>
            <Text>
              <strong>name</strong>: The name of the font, one of{' '}
              <code>regular</code>, <code>medium</code> or <code>semibold</code>.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              <strong>path</strong>: The url to the file on disk (created by Webpack).
            </Text>
          </ListItem>
        </List>
      </Paragraph>
    </div>
  );
};

export default Typography;
