import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Redirect, useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import Menu, { MenuLink, MenuSection } from '../../../react/Menu';
import Text from '../../../react/Text';
import Select from '../../../react/Form/Select';
import PageBanner from '../../components/Banner';

import artwork from './assets/artwork.jpg';

// Subpages
import DesignTokens from './DesignTokens';
import Accessibility from './Accessibility';
import DeveloperIcons from './DeveloperIcons';
import GettingStarted from './GettingStarted';
import SassDeprecator from './SassDeprecator';
import Theming from './Theming';
import Tracking from './Tracking';
import Linting from './LintRules';

const routes = [
  {
    label: 'Getting Started',
    path: '/getting-started',
    tab: '',
    component: GettingStarted,
  },
  {
    label: 'Accessibility',
    path: '/accessibility',
    tab: '',
    component: Accessibility,
  },
  {
    label: 'Design Tokens',
    path: '/design-tokens',
    tab: '/developer-guide',
    component: DesignTokens,
  },
  {
    label: 'Developer Icons',
    path: '/developer-icons',
    tab: '',
    component: DeveloperIcons,
  },
  {
    label: 'Linting',
    path: '/lint',
    tab: '/js',
    component: Linting,
  },
  {
    label: 'Sass Deprecator',
    path: '/sass-deprecator',
    tab: '/overview',
    component: SassDeprecator,
  },
  {
    label: 'Theming',
    path: '/theming',
    tab: '/developer-guide',
    component: Theming,
  },
  {
    label: 'Tracking',
    path: '/tracking',
    tab: '/developer-guide',
    component: Tracking,
  },
];

const Identity = ({ title }) => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route exact path={path}>
        <Redirect to={`${path}/getting-started`} />
      </Route>
      { routes.map(route => (
        <Route
          path={path + route.path}
          render={({ match }) => (
            <>
              <Helmet>
                <title>{title} - {route.label}</title>
                <meta property="og:title" content={route.label} />
              </Helmet>
              <route.component match={match} />
            </>
          )}
        />
      ))}
    </>
  );
};

const NavMenuItem = (props) => {
  // const { pathname } = useLocation();
  const history = useHistory();
  const match = useRouteMatch(props.href.replace('examples', ':tab'));

  function handleClick(e) {
    e.preventDefault();
    history.push(props.href);
  }

  return (
    <MenuLink
      {...props}
      onClick={handleClick}
      active={!!match}
    />
  );
};

const Sidebar = () => {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  return (
    <>
      <Text type="display3" display="block" marginBottom={1}>Guides</Text>
      <Menu>
        <MenuSection>
          {
            routes.map(item => (
              <NavMenuItem
                href={url + item.path + item.tab}
                key={item.label}
                active={url + item.path === pathname}
              >
                {item.label}
              </NavMenuItem>
            ))
          }
        </MenuSection>
      </Menu>
    </>
  );
};

const Banner = ({ mobile }) => {
  return (
    <PageBanner
      artwork={artwork}
      shopLink="https://www.redbubble.com/people/buko/shop"
      artworkLink="https://www.redbubble.com/shop/ap/25968659"
      avatar="https://ih1.redbubble.net/avatar.1026504.140x140.jpg"
      title="Night Air"
      artist="buko"
      mobile={mobile}
    />
  );
};

const Nav = () => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { pathname } = useLocation();

  return (
    <>
      { /* eslint-disable-next-line */ }
      <label>
        <Text type="display5" display="block" marginBottom="s">Guides</Text>
        <Select
          label="Guides"
          type="select"
          name="guides"
          placeholder="Guides"
          value={pathname}
          fluid
          onChange={values => history.push(values)}
          options={
            routes.map(item => (
              { value: url + item.path + item.tab, label: item.label }
            ))
          }
        />
      </label>
    </>
  );
};

export {
  Nav,
  Sidebar,
  Banner,
  routes,
};

export default Identity;
