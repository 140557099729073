import React, { useCallback, useState, useRef, useMemo, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import cnames from 'classnames';
import PropTypes from 'prop-types';
import { useSelect } from 'downshift';
import { usePopper } from 'react-popper';
import { RemoveScroll } from 'react-remove-scroll';
import get from 'lodash/get';
import maxSize from 'popper-max-size-modifier';
import styles from './styles.scss';
import Box from '../../Box';
import Card from '../../Card';
import ChevronDownIcon from '../../Icons/ChevronDown';
import * as constants from '../../constants';
import { OverlayContext } from '../../Modal/ModalOverlay';
import sizes from '../sizes';

const { DESKTOP, MOBILE } = sizes;
const testId = 'ds-select';
const placeholderText = 'Select...';

const SelectMobile = ({
  intent,
  width,
  name,
  onFocus,
  disabled,
  showPlaceholder,
  handleChange,
  onBlur,
  placeholder,
  selectedValue,
  options,
}) => (
  <select
    data-testid={testId}
    className={
      cnames(styles.input, styles.native, {
        [styles[intent]]: intent && styles[intent],
        [styles.disabled]: disabled,
        [styles.nativePlaceholder]: showPlaceholder,
      })
    }
    style={{ width }}
    name={name}
    onChange={e => handleChange(e.target.value)}
    onFocus={onFocus}
    onBlur={onBlur}
    disabled={disabled}
    value={(showPlaceholder && placeholder) || selectedValue}
  >
    { showPlaceholder
        && <option disabled>{placeholder}</option>
    }
    {
      options.map(option => (
        <option
          value={option.value}
          key={`${option.label}-${option.value}`}
          disabled={option.isDisabled}
        >
          {option.label}
        </option>
      ))
    }
  </select>
);

const SelectDesktop = ({
  width,
  intent,
  fluid,
  disabled,
  options,
  handleChange,
  onBlur,
  onFocus,
  showPlaceholder,
  placeholder,
  selectedValue,
}) => {
  // get selected item from the shared value
  const selectedItem = options.find(option => option.value === selectedValue) || { label: '' };
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: options,
    itemToString: item => item.label,
    // set selectedItem to shared value
    selectedItem,
    onSelectedItemChange: changes => handleChange(get(changes, 'selectedItem.value')),
  });

  const overlayContext = useContext(OverlayContext);
  const overlayPortalTarget = get(overlayContext, 'portalTarget.current');

  // calculate height and width of popper
  const [menuScrollHeight, setMenuScrollHeight] = useState(0);
  // Use callback ref to get scrollHeight of the Menu
  // so that we get notified when the  Menus (<ul>) is shown/hidden.
  // This way we could accurately keep track of the Menu's scrollHeight.
  const menuRef = useCallback((node) => {
    if (node !== null) {
      setMenuScrollHeight(node.scrollHeight);
    }
  }, []);
  const applySize = useMemo(() => {
    return {
      name: 'applyMaxSize',
      enabled: true,
      phase: 'beforeWrite',
      requires: ['maxSize'],
      fn({ state }) {
        const defaultStyles = {
          maxHeight: '250px',
          width: '300px',
        };
        try {
          const { height: popperHeight } = state.modifiersData.maxSize;
          const itemHeight = menuScrollHeight / options.length;
          const menuHeight = Math.min(Math.ceil(popperHeight / itemHeight) - 0.5, 6.5) * itemHeight;

          // eslint-disable-next-line no-param-reassign
          state.styles.popper = {
            ...state.styles.popper,
            maxHeight: `${menuHeight}px`,
            width: `${state.rects.reference.width}px`,
          };
        } catch (e) {
          // eslint-disable-next-line no-param-reassign
          state.styles.popper = {
            ...state.styles.popper,
            ...defaultStyles,
          };
        }
      },
    };
  }, [menuScrollHeight, options]);

  // set popper
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles: popperStyle, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      maxSize,
      applySize,
    ],
  });

  const { maxHeight: popperMaxHeight, ...popperStyles } = (popperStyle.popper || {});

  // TODO consider best check for SSR
  const portalTarget = useRef(typeof window !== 'undefined' ? document.createElement('div') : null);

  useEffect(() => {
    document.body.appendChild(portalTarget.current);
  }, []);

  const createPortal = (jsx) => {
    if (typeof window !== 'undefined') {
      // client
      const target = overlayPortalTarget || portalTarget.current;
      return ReactDOM.createPortal(jsx, target);
    }

    return jsx;
  };

  const handleToggle = useCallback(() => {
    if (typeof update === 'function') update();
  }, [update]);

  return (
    <React.Fragment>
      <Box style={{ width }}>
        <button
          data-testid={testId}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          type="button"
          style={popperStyle.reference}
          className={
            cnames(
              styles.input,
              styles[intent],
              {
                [styles.fluid]: fluid,
                [styles.disabled]: disabled,
              },
            )
          }
          {...getToggleButtonProps({
            ref: setReferenceElement,
            onBlur: e => !isOpen && onBlur(e),
            onFocus,
            disabled,
            onClick: handleToggle,
            onKeyDown: handleToggle,
          })}
        >
          <Box element="span" className={styles.innerText}>
            { showPlaceholder
              ? <span className={styles.placeholderText}>{placeholder}</span>
              : selectedItem.label
            }
          </Box>
          <Box element="span" className={cnames({ [styles.disabledIcon]: disabled })}>
            <ChevronDownIcon />
          </Box>
        </button>
        <React.Fragment>
          {
            createPortal(
              <div
                ref={setPopperElement}
                style={popperStyles}
                {...attributes.popper}
                className={cnames(styles.selectMenu, {
                  [styles.isVisuallyHidden]: !isOpen,
                })}
              >
                <Card elevation={constants.ELEVATION_HIGH}>
                  <RemoveScroll enabled={isOpen} noIsolation>
                    <ul
                      className={styles.list}
                      {...getMenuProps({ ref: menuRef })}
                      style={{
                        maxHeight: popperMaxHeight,
                        width,
                      }}
                    >
                      {isOpen && (
                        options.map((item, index) => (
                          <li
                            className={
                              cnames(
                                styles.listItem,
                                {
                                  [styles.listItemHover]: highlightedIndex === index,
                                  [styles.listItemActive]: (
                                    selectedValue && (selectedValue === item.value)
                                  ),
                                },
                              )
                            }
                            key={`${item.value}${item.label}`}
                            {...getItemProps({
                              item,
                              index,
                            })}
                          >
                            {item.label}
                          </li>
                        ))
                      )}
                    </ul>
                  </RemoveScroll>
                </Card>
              </div>,
            )
          }
        </React.Fragment>
      </Box>
    </React.Fragment>
  );
};

const Select = ({
  fluid,
  intent,
  name,
  value,
  profile,
  disabled,
  options,
  placeholder: userPlaceholder,
  onChange,
  onBlur,
  onFocus,
  defaultValue,
}) => {
  const placeholder = userPlaceholder || placeholderText;

  // TODO rewrite so that this doesn't cause conditional hook error for all hooks below it
  if (!Array.isArray(options) || options.length === 0) return null;

  const [showPlaceholder, setShowPlaceholder] = useState(!value && !defaultValue);
  const [selectedValue, setSelectedValue] = useState(value || defaultValue);

  useEffect(() => {
    if (value) {
      setSelectedValue(value);
      setShowPlaceholder(false);
    }
  }, [value]);

  // calculate width of input field
  let longestLabel = placeholder ? placeholder.length : 0;

  if (options.length) {
    const longestOption = options.reduce((m, o) => (
      o.label.length > m.label.length ? o : m
    ), options[0]);

    longestLabel = placeholder
      ? Math.max(longestOption.label.length, placeholder.length)
      : longestOption.label.length;
  }
  const width = fluid ? '100%' : `${longestLabel + 10}ch`;

  // onChange handler
  const handleChange = useCallback((handleValue) => {
    if (!value) {
      setSelectedValue(handleValue);
      setShowPlaceholder(false);
    }
    if (typeof onChange === 'function') onChange(handleValue);
  }, [onChange, setSelectedValue, value]);

  // eslint-disable-next-line max-len
  const SelectForProfile = [MOBILE, constants.MOBILE].includes(profile) ? SelectMobile : SelectDesktop;

  return (
    <SelectForProfile
      intent={intent}
      width={width}
      name={name}
      disabled={disabled}
      showPlaceholder={showPlaceholder}
      onFocus={onFocus}
      handleChange={handleChange}
      onBlur={onBlur}
      placeholder={placeholder}
      selectedValue={selectedValue}
      options={options}
      fluid={![MOBILE, constants.MOBILE].includes(profile) && fluid}
    />
  );
};

Select.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  fluid: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  profile: PropTypes.oneOf([MOBILE, DESKTOP, constants.MOBILE, constants.DESKTOP]),
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  intent: PropTypes.oneOf([
    constants.INFORMATION,
    constants.ERROR,
    constants.WARNING,
    constants.SUCCESS,
    constants.NEUTRAL,
  ]),
};

Select.defaultProps = {
  disabled: false,
  fluid: false,
  profile: constants.MOBILE,
  onBlur: f => f,
  onChange: f => f,
  intent: constants.NEUTRAL,
  placeholder: placeholderText,
};

export default Select;
