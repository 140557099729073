import React, { useState, useEffect, useCallback } from 'react';
import cnames from 'classnames';
import Slider, { Handle as RCHandle } from 'rc-slider';
import useResponsive from '../../hooks/useResponsive';
import 'rc-slider/assets/index.css';
import Box from '../../../react/Box';
import IconSlideHorizontal from '../../../react/Icons/SlideHorizontal';
import Label from '../../../react/Label';
import Img from '../../../react/Image';
import * as constants from '../../../react/constants';
import styles from './PhotographySlider.css';

import expressiveSpaceSmall from './media/expressiveSpace-small.jpg';
import expressiveSpaceLarge from './media/expressiveSpace-large.jpg';
import expressiveBubbleGumSmall from './media/expressiveBubbleGum-small.jpg';
import expressiveBubbleGumLarge from './media/expressiveBubbleGum-large.jpg';
import expressiveCactusRickSmall from './media/expressiveCactusRick-small.jpg';
import expressiveCactusRickLarge from './media/expressiveCactusRick-large.jpg';
import expressiveBeachVibesSmall from './media/expressiveBeachVibes-small.jpg';
import expressiveBeachVibesLarge from './media/expressiveBeachVibes-large.jpg';
import lifestyleGuitarSmall from './media/lifestyleGuitar-small.jpg';
import lifestyleGuitarLarge from './media/lifestyleGuitar-large.jpg';
import productlifestyleStickerBottherSmall from './media/productlifestyleStickerBottle-small.jpg';
import productlifestyleStickerBottherLarge from './media/productlifestyleStickerBottle-large.jpg';
import functionalClothingSmall from './media/functionalClothing-small.jpg';
import functionalClothingLarge from './media/functionalClothing-large.jpg';
import functionalCoasterWalletSmall from './media/functionalCoasterWallet-small.jpg';
import functionalCoasterWalletLarge from './media/functionalCoasterWallet-large.jpg';
import functionalStickerSmall from './media/functionalSticker-small.jpg';
import functionalStickerLarge from './media/functionalSticker-large.jpg';

const images = [
  {
    small: expressiveSpaceSmall,
    large: expressiveSpaceLarge,
  },
  {
    small: expressiveBubbleGumSmall,
    large: expressiveBubbleGumLarge,
  },
  {
    small: expressiveCactusRickSmall,
    large: expressiveCactusRickLarge,
  },
  {
    small: expressiveBeachVibesSmall,
    large: expressiveBeachVibesLarge,
  },
  {
    small: lifestyleGuitarSmall,
    large: lifestyleGuitarLarge,
  },
  {
    small: productlifestyleStickerBottherSmall,
    large: productlifestyleStickerBottherLarge,
  },
  {
    small: functionalClothingSmall,
    large: functionalClothingLarge,
  },
  {
    small: functionalCoasterWalletSmall,
    large: functionalCoasterWalletLarge,
  },
  {
    small: functionalStickerSmall,
    large: functionalStickerLarge,
  },
];

const Handle = (props) => {
  return (
    <RCHandle {...props}>
      <Box className={styles.handleContainer}>
        <Box className={styles.handle}>
          <IconSlideHorizontal />
        </Box>
      </Box>
    </RCHandle>
  );
};

const PhotographySlider = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const [handleMoved, setHandleMoved] = useState(false);
  const { mobile } = useResponsive();

  const imageSize = mobile ? 'small' : 'large';

  useEffect(() => {
    if (handleMoved) {
      images.forEach((image) => {
        new Image().src = image[imageSize];
      });
    }
  }, [handleMoved]);

  const handleSlide = useCallback((e) => {
    // calculate index based on slider value
    const index = Math.floor(e / (100 / images.length));

    // set state and show the right image based on Slider index
    setImageIndex(index < images.length ? index : index - 1);

    // preload images
    setHandleMoved(true);
  }, [setImageIndex, setHandleMoved]);

  const sliderClasses = cnames(styles.slider, {
    [styles.pulse]: !handleMoved,
  });

  return (
    <>
      <Box className={styles.sliderContainer}>
        <Slider
          step={0.1}
          max={100}
          defaultValue={4}
          onChange={e => handleSlide(e)}
          handle={Handle}
          className={sliderClasses}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={styles.range}
            paddingBottom="xl"
          >
            <Box
              className={styles.rangeAnchor}
              paddingRight="xl"
            >
              <Label
                intent={imageIndex === 0 ? constants.INFORMATION : constants.NEUTRAL}
              >
                Expressive
              </Label>
            </Box>
            <Box
              padding="xs"
              className={styles.rangeSteps}
              flex="1"
            />
            <Box
              className={styles.rangeAnchor}
              paddingLeft="xl"
            >
              <Label
                intent={
                  imageIndex === images.length - 1 ? constants.INFORMATION : constants.NEUTRAL
                }
              >
                Functional
              </Label>
            </Box>
          </Box>
          <Box className={styles.imageContainer}>
            <Img
              src={images[imageIndex][imageSize]}
              roundedCorners
              fluid
            />
          </Box>
        </Slider>
      </Box>
    </>
  );
};

export default PhotographySlider;
