import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import posed from 'react-pose';
import _styles from './styles.scss';

const Bar = posed.div({
  empty: { width: 0 },
  full: {
    width: ({ width }) => width,
    transition: {
      ease: [0, 0, 0.2, 1.2],
      duration: 600,
    },
  },
});

const ProgressBar = ({ percentage, color, animated, styles }) => (
  <div className={styles.track}>
    {
      animated ? (
        <Bar className={cnames(styles.bar, styles[color])} initialPose="empty" pose="full" width={`${percentage}%`} />
      ) : (
        <div className={cnames(styles.bar, styles[color])} style={{ width: `${percentage}%` }} />
      )
    }
  </div>
);

ProgressBar.displayName = 'ProgressBar';

ProgressBar.propTypes = {
  /**
    * How much to fill the progress bar as a percentage of it's width
    */
  percentage: PropTypes.number,
  /**
    * What color to use for the bar
    */
  color: PropTypes.oneOf(['information', 'warning', 'success', 'error']),
  /**
    * When true, progress animates from 0 on mount
    */
  animated: PropTypes.bool,
  /**
    * The CSS classNames for this component. NEVER manually specify this prop
    */
  styles: PropTypes.shape({}),
};

ProgressBar.defaultProps = {
  percentage: 0,
  color: 'information',
  animated: false,
  styles: _styles,
};

export default ProgressBar;
