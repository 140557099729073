/* eslint-disable max-len */
import React from 'react';
import MarkdownContents from 'markdown-contents';
import Markdown from '../../../components/Markdown';
import TabBar, { Tab } from '../../../components/TabBar';

const devGuide = `
## Introduction

The Design System has historically used SCSS variables as a means of sharing design tokens.

We used these variables in our components and encouraged app maintainers to use them in their own app styles by importing the variables from the Design System.

e.g.

\`\`\`
// boom-app/app/shared/components/ProductDetails.css
@import "~@redbubble/design-system/styles/variables.scss";

.wrapper {
    padding: $unit;
    border: 1px solid $neutral500;
}
\`\`\`

This approach is now ⚠️  officially deprecated in favour of our new [Design Tokens](/guidelines/design-tokens/developer-guide) which use CSS Custom Properties.

CSS Custom Properties allow us to change the values of the tokens/variables at run time - meaning we can apply user specific themes, skin just certain segments of the page and more.

We encourage you to migrate to the new CSS Custom Properties tokens as per the following process.

## Explore the Deprecation Tool

If you have the Design System installed then you'll already have access to the handy deprecation CLI tool.

This tool was designed to assist you in programatically replacing SCSS variables with new token equivalents.

There are a handful of ways you can run the tool:

\`\`\`
// Install and use npx
npx design-system-deprecate-sass path/to/style/files
// or
./node_modules/.bin/design-system-deprecate-sass path/to/style/files
// or add it as a script to package.json { deprecateSass: "design-system-deprecate-sass" } and then
yarn run deprecateSass path/to/style/files
\`\`\`

## Usage

Run the tool with the directory within which you'd like to programatically replace SCSS vars with Design System tokens

e.g.

\`\`\`
npx design-system-deprecate-sass path/to/**/*.css

Welcome to the Design System SCSS Deprecation Tool
Operations will be run in: /Users/youruser/path/to/**/*.css
--------

  We recommend you run the following choices in order.

  First REPLACE as much SCSS with tokens automatically as you can.
  Then, DISCOVER opportunities to use tokens that our tool wasn’t able to apply for you.
  Lastly, CLEANUP by showing all SCSS variables remaining in your project.

--------
? What would you like to do? (Use arrow keys)
❯ REPLACE: Replace SCSS variables with Design System Tokens
  DISCOVER: Find opportunities to use Design System tokens
  CLEANUP: Show all SCSS variables to see what you have left


\`\`\`

As per the introduction, we recommend you run the tool in the following order:

### Replace

Automatically (or file by file) replace SCSS variables with their Design Token equivalents where it's possible to map them 1:1.

### Discover

Once all automatic replacements are complete, run the Discover tool to find remaining opportunities to convert SCSS vars to tokens _manually_.
You can think of this as a nudge in the right direction.

### Cleanup

Once all automatic and manual replacements are complete, run the cleanup tool to find any remaining SCSS vars in your project. This will help you identify if you've
found and replaced all variables.

### Need help?

Please contact the Design System team if you need assistance.
We recommend you refer to our [Theming](/guidelines/theming/developer-guide) and [Tokens](/guidelines/design-tokens/developer-guide) documentation to better understand the new tokens and how you might use them in your application.

`;

const toc = MarkdownContents(devGuide);

const Theming = () => {
  return (
    <React.Fragment>
      <Markdown>
        {`
# SCSS Deprecation

#### This guide covers off how to approach migrating from SCSS variables to our new CSS Custom Property Design Tokens
  `}
      </Markdown>

      <TabBar rootPath="/guides/sass-deprecator">
        <Tab label="Overview" path="">
          <Markdown dedent={false}>
            { toc.markdown() }
          </Markdown>
          <Markdown>
            { devGuide }
          </Markdown>
        </Tab>
      </TabBar>
    </React.Fragment>
  );
};

export default Theming;
