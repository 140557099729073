import { defineMessages } from 'react-intl';

const messages = defineMessages({
  favorites: {
    defaultMessage: 'Favorites received',
    description: 'Label for favorites icon in Header',
  },
  followers: {
    defaultMessage: 'Followers',
    description: 'Label for followers icon in Header',
  },
  productsSold: {
    defaultMessage: 'Products sold',
    description: 'Label for products sold icon in Header',
  },
  dashboardLabel: {
    defaultMessage: 'Dashboard',
    description: 'Label for dashboard tooltip in Header',
  },
  viewDashboard: {
    defaultMessage: 'View Dashboard',
    description: 'Label for dashboard link in Header',
  },
});

export default messages;
