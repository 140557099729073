import image from '../assets/rules-and-stuff.svg';

/* eslint-disable max-len */
const markdown = `
### Acronyms

No need to use a period after each letter of an acronym.
- USA not U.S.A.

If there’s a chance your reader isn’t familiar with the acronym you’re using, spell it out the first time you mention it, then use the acronym afterwards.
- Redbubble’s customer success team is called CS internally.

### Active/Passive voice

Write in active voice. Which is when the subject does the action, not the other way around.
- No: The copy was written in passive voice by the writer.
- Yes: The writer wrote copy in active voice.

### American English vs. Australian English

Redbubble was founded in Australia. But the majority of writing happens in the United States. So that means we stick to the American way of spelling words. It’s just easier that way. We have to be consistent.
- Color not Colour
- Favorite not favourite
- Realize not realise

### Apostrophes

If the word or name ends in s, just add an apostrophe. Don’t add an apostrophe s.
- Chris’ painting was awesome.

If the word is plural and ends in s (artists), add an apostrophe. If the word is plural and doesn’t end in s (women), add an apostrophe s.
- Many artists’ designs look great on tote bags.
- Women’s t-shirts are on sale.

When abbreviating a decade or specific year, an apostrophe goes before the numbers, not after.
- That design is a throwback to the ‘80s.

### Artists

The Redbubble artist community is over 700k illustrators, graphic designers, typographers, photographers, and more. A global community of pros, hobbyists, fans, and everything in-between. When communicating with or about these wonderful people, call them:

- Independent artists
- Artists
- Creative people (only after introducing them as independent artists)

Example:

- REDBUBBLE PURCHASES SUPPORT INDEPENDENT ARTISTS
    * Everything in the Redbubble marketplace is designed by creative people from around the world. And every purchase puts money in their pockets.


Do not call them creators, creatives, indie artists, or bubblers.


### Bulleted lists

Capitalize the first word of each point. Don’t use periods at the end of bullets unless the point is a complete sentence. Use a colon before the list.

- Megan needed a few things from the art store:
    * Paint
    * Brushes
    * Canvas

### Buttons

Buttons contain calls to action (CTAs). So they should be brief, but engaging. It’s okay to have fun with a CTA. If you can be creative, yet functional, go for it. Capitalize each letter in a button, even articles. Occasionally we’ll use all-caps, but that’s a stylistic call.

- Shop Now
- Sign Up
- Let’s Do This
- Find Your Thing
- Go For It


### Capitalization

Capitalize proper nouns (names of individuals and places)

Avoid using ALL CAPS for emphasis. Especially in subject lines to emphasize promos. We try to keep things pretty lowkey. There are some exceptions (CTAs), but otherwise, we don’t want to yell at anyone.


### Colons

Use a colon before lists:
- The artist drew 3 animals: lions, tigers, and ligers.

Do not capitalize the first letter after a colon:
- The artist has several favorite tools: pencils, inks, and paints.

Unless what follows the colon is a complete sentence:
- The artist has several favorite tools: Pencil is her standard, but she’s starting to experiment with oil paints.

Commas

We love the Oxford comma (aka serial comma). It’s a bit of controversial subject, and some may say it’s outdated. But we do us when it comes to commas. The Oxford/serial makes things more clear.
- Bobby frequently uses the colors red, black, and white.

If writing directly to someone, place a comma before their name:
- How many artworks did you upload, Lucy?

Contractions

Don’t hold back. Contractions make statements conversational. We’re all friends here. No need to be formal. Just make sure you use them correctly.
- You are = You’re (not your)
- They are = They’re (not their or there)
- Should have = Should’ve (not should of)


### Customers vs Consumers vs Buyers

The people who purchase products from the Redbubble marketplace are called customers. We do not call them consumers or buyers.

### Dates

Format dates the following way: [month] [day], [year]
- December 12, 2018
- If abbreviating month, add a period (Dec.)


### Ellipses

Also known as the 3 dots used to create a pause for dramatic effect or show that something has been omitted from a sentence. If using them, put a space after the third dot. Never use 4 dots. This is a common mistake.
- You can use ellipses for dramatic… effect.


### Emoji

Emoji can be cool. Especially if you’re short on character count. But emoji can also be a bit grating. If you use them, do it creatively. If emoji don’t add anything to your statement, leave it/them out. But if you can make ‘em worth it and fun, then go forth and emoji.

Good examples:
- Last day for posters & stickers by 🎄
- 🐚👹🤖🥑💾🏈?  
  That's confusing. But this sitewide sale isn't. Get the coolest stuff at 20% off.
- Are the kids saying 💣again?
- If yes, know this 20% off sale is 💣(or is it 💣.com. Sigh. We don't know anymore).


### Exclamation points

Exclamation points should be used somewhere between once in a while and never. And only to actually emphasize a point. It’s tempting to do otherwise, but remember, it doesn’t make you sound friendly or enthusiastic, it makes you sound like you’re yelling for the sake of yelling.

- No: 20% off cases! Use promo code YELLING20 at checkout!!!!!
- Yes: 20% off cases. Use promo code YELLING20 at checkout.
- Yes (sometimes): Pow! Get 20% off for Boxing Day. Use code PUNCH at checkout.

There are cases when you can use exclamation points for clarity, such as at the end of a “Thanks!” when signing off an email to a customer. But again, tread lightly in exclamation point-land.

### Gender

If you’re unsure about a person’s pronouns (he/she, him/her) use they/them/their.
Use spouse or partner rather than husband and wife; use parent instead of mother and father.

### Hang/Hung/Hanged

You hang a framed print. A framed print hung on the wall. The outlaw in the cowboy movie was hanged for his crimes. We shouldn’t have to use the word hanged at Redbubble. Hopefully.

### Job titles

Do not capitalize job titles. Unless the title is part of someone’s name (i.e. Officer Smith). But we don’t really have that Redbubble. So for the most part, titles should be lowercase.
- Nancy Smith is chief marketing officer.
- Redbubble is hiring a data scientist.
- Marketing managers are good at managing markets.

### Numbers

Use the numeral unless it starts a sentence.
- There are over 60 products available on the Redbubble marketplace.
- Sixty products are available on the Redbubble marketplace.

Exceptions for promo messages:
- 20% off sitewide
- Save 30% on all apparel

### Our

Redbubble is strictly a marketplace. We don't make anything. We simply set the stage for the relationship between artists, buyers, and printers.
As such, we need to be wary of using the possessive “our” when we refer to things. Avoid using phrases like:
- Our products
- Our artists
- Our third party printers
Whenever necessary, go with more general terms like:
- Artists on Redbubble
- Products on the website
- Third party printing partners

This is very important when discussing products. They are not “our t-shirts.” Redbubble does not produce the products that are sold on the marketplace. Fulfillers do. Redbubble facilitates the business transaction between the customer and artist. It does not create the art and it does not make the products. This is a very important rule to remember as it can open up Redbubble to a number of legal and financial issues.

### Percentages

Always use the % symbol. Never write out the word percent.

### Periods

Periods (or full stops) go at the end of sentences. Obvi. Sometimes people double-space after them. That’s an old fashioned typewriter rule that doesn’t really have a place in the digital world. So one tap of the spacebar after a period will suffice. There are some additional period things to consider:

- Abbreviations
- When writing the shorter form of a longer word, like Sept. for September, place a period at the end.
- If you have an abbreviation at the end of a sentence, do not use a second period.
- Acronyms
- When writing acronyms, do not use periods after each letter. (USA not U.S.A.)

### Product names

We don’t capitalize product names unless they appear at the start of a sentence. If the name of the product contains a hyphen (t-shirt) and appears at the start of a sentence, the letter following the hyphen should be lowercase (T-shirt).

And remember, never refer to them as “our” products. For more on this, please see this section (link to OUR)

### Quotation marks

We use double quotation marks:
- “These”
- Not ‘these’

If quoting someone, writing dialogue, or mentioning the title of an artwork, use quotation marks:
- The title of the work is “Punctuation.”

Use single quotation marks if mentioning the title of an artwork or quoting someone within a quotation.
- “The title of my work is ‘Punctuation,’ and it’s for sale,” Danny said.

Commas and periods go inside quotation marks. Question marks can go outside or inside, but it depends. Is the whole sentence a question or just the quotation? Both of these examples are correct:
- Would you like a tote bag featuring the design “Punctuation”?
- “Would you like a tote bag featuring my work?” Danny said.

Colons go outside quotation marks.
- Here’s what Danny used to create “Punctuation”:
    * Oil paints
    * Photoshop
    * Blood, sweat, and tears

### Redbubble

Redbubble is one word and the first b is lowercase. We’re not Red Bubble. We’re not Red bubble. We’re not RedBubble.


Occasionally Redbubble can be shortened to RB, but that’s usually for internal purposes or when character space is limited, such as in hashtags for social campaigns. #MyRBThing is okay because #MyRedbubbleThing is too many characters. In most cases, just use Redbubble.

When referring to Redbubble use we not it. Sounds more personal.

### Time

Write am and pm lowercase and without periods. Use a space between the time and am/pm. Always use time zones when talking about specific events (like promo and contest deadlines).
- Expires July 13, 2017 at 11:59 pm PT.

Time zones:

Pacific time: PT
Eastern time: ET
Central time: CT
Mountain time: MT
Greenwich Mean Time: GMT


### Titles

Title case for blog posts, videos, and more.  This means each word in a title is capitalized except for articles like the, of, a, an, etc. Though “the” should be capitalized if it starts the title.

Italics for titles of long works like books, movies, or albums.

Quotation marks for titles of artworks, articles, songs.
- If artists use non-traditional capitalization in the titles of their works, adhere to what they chose to use (“my TITLE is LikE thiS” by The arTisT)

We can use sentence case for titles on a case by case basis. This means the first letter of the title and proper nouns are capitalized. But this is all based on style, format, and layout.

`;

const RulesAndStuff = {
  markdown,
  image,
  title: 'Rules and stuff',
  subTitle: 'How we write',
  slug: 'rules-and-stuff',
};

export default RulesAndStuff;
