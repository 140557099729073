import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import * as CardExports from '.';
import Text from '../Text';
import List, { ListItem, ListMedia, ListContent } from '../List';
import Image, { SMALL, TINY } from '../Image';
import Box from '../Box';

// eslint-disable-next-line
import CardSrc from '!raw-loader!./';

const { default: Card, CardBody, CardHeader, LOW, MEDIUM, HIGH } = CardExports;
const docs = generateDocs(CardSrc, resolver.findAllExportedComponentDefinitions);

const imageSrc = 'https://images.unsplash.com/photo-1503640838962-d3d3cadf1847';
const squareImageSrc = 'https://images.unsplash.com/photo-1505773573366-a42de6e725d4';

const textExample = {
  name: 'Text',
  description: '',
  render: () => (
    <Card>
      <CardBody>
        <Text>
          Text example
        </Text>
      </CardBody>
    </Card>
  ),
  code: `
<Box padding="m">
  <Card>
    <CardBody>
      <Text>
        Text example
      </Text>
    </CardBody>
  </Card>
</Box>
  `,
};

const onClickExample = {
  name: 'Clickable Cards',
  description: 'Cards can be clicked using an onClick prop.',
  render: () => (
    // eslint-disable-next-line no-alert
    <Card onClick={() => alert('Clicked')}>
      <CardBody>
        <Text>
          Click me :)
        </Text>
      </CardBody>
    </Card>
  ),
  code: `
<Box padding="m">
  <Card onClick={() => alert('Clicked')}>
    <CardBody>
      <Text>
        Click me :)
      </Text>
    </CardBody>
  </Card>
</Box>
  `,
};

const roundedExample = {
  name: 'Rounded Corners',
  description: '',
  render: () => (
    <Box display="flex">
      <Box marginRight="m">
        <Card>
          <CardBody>
            <Text>
              Rounded by default
            </Text>
          </CardBody>
        </Card>
      </Box>
      <Card roundedCorners={false}>
        <CardBody>
          <Text>
            Rounded corners set to false
          </Text>
        </CardBody>
      </Card>
    </Box>
  ),
  code: `
<Box padding="m">
  <Box marginBottom="m">
    <Card>
      <CardBody>
        <Text>
          Rounded by default
        </Text>
      </CardBody>
    </Card>
  </Box>
  <Card roundedCorners={false}>
    <CardBody>
      <Text>
        Rounded corners set to false
      </Text>
    </CardBody>
  </Card>
</Box>
  `,
};

const transparentExample = {
  name: 'Transparent Cards',
  description: 'Cards can have no white background. CardBody’s have no padding in transparent cards.',
  render: () => (
    <Box display="flex">
      <Box marginRight="m" style={{ minWidth: '300px' }}>
        <Card transparent>
          <CardBody>
            <Text>
              This is a transparent card
            </Text>
          </CardBody>
        </Card>
      </Box>
      <Box marginRight="m" style={{ minWidth: '300px' }}>
        <Card transparent>
          <Image fluid src={imageSrc} alt="test image" />
          <CardBody>
            <Text>
              This is a transparent card
            </Text>
          </CardBody>
        </Card>
      </Box>
      <Box marginRight="m" style={{ minWidth: '300px' }}>
        <Card transparent roundedCorners={false}>
          <Image fluid src={imageSrc} alt="test image" />
          <CardBody>
            <Text>
              This one has no roundedCorners
            </Text>
          </CardBody>
        </Card>
      </Box>
    </Box>
  ),
  code: `
<Box padding="m">
  <Card transparent>
    <CardBody>
      <Text>
        This is a transparent card
      </Text>
    </CardBody>
  </Card>
  <Card transparent>
    <Image fluid src="${imageSrc}" alt="test image" />
    <CardBody>
      <Text>
        This is a trasparent card
      </Text>
    </CardBody>
  </Card>
  <Card transparent roundedCorners={false}>
    <Image fluid src="${imageSrc}" alt="test image" />
    <CardBody>
      <Text>
        This one has no roundedCorners
      </Text>
    </CardBody>
  </Card>
</Box>
  `,
};

const elevationsExample = {
  name: 'Card Elevation',
  description: 'Cards can have different drop shadows to indicate their elevation in the document.',
  render: () => (
    <Box display="flex">
      <Box marginRight="m">
        <Card elevation={LOW}>
          <CardBody>
            <Text>
              A low card (default)
            </Text>
          </CardBody>
        </Card>
      </Box>
      <Box marginRight="m">
        <Card elevation={MEDIUM}>
          <CardBody>
            <Text>
              A medium card
            </Text>
          </CardBody>
        </Card>
      </Box>
      <Box>
        <Card elevation={HIGH}>
          <CardBody>
            <Text>
              A high card
            </Text>
          </CardBody>
        </Card>
      </Box>
    </Box>
  ),
  code: `
<Box padding="m">
  <Box marginBottom="m">
    <Card elevation="${LOW}">
      <CardBody>
        <Text>
          A low card (default)
        </Text>
      </CardBody>
    </Card>
  </Box>
  <Box marginBottom="m">
    <Card elevation="${MEDIUM}">
      <CardBody>
        <Text>
          A medium card
        </Text>
      </CardBody>
    </Card>
  </Box>
  <Box>
    <Card elevation="${HIGH}">
      <CardBody>
        <Text>
          A high card
        </Text>
      </CardBody>
    </Card>
  </Box>
</Box>
  `,
};

const edgeToEdgeExample = {
  name: 'Edge to edge content',
  description: 'If you don’t use a CardBody, content will be edge to edge.',
  render: () => (
    <Box display="flex">
      <Box marginRight="m" style={{ minWidth: '400px' }}>
        <Card>
          <Image fluid src={imageSrc} alt="test image" />
          <CardBody>
            <Text>
              CardBody is padded but image above is not
            </Text>
          </CardBody>
        </Card>
      </Box>
      <Box style={{ minWidth: '400px' }}>
        <Card>
          <CardBody>
            <Text>
              CardBody is padded but image below is not
            </Text>
          </CardBody>
          <Image fluid src={imageSrc} alt="test image" />
        </Card>
      </Box>
    </Box>
  ),
  code: `
<Box padding="m">
  <Box marginBottom="m">
    <Card>
      <Image fluid src="${imageSrc}" alt="test image" />
      <CardBody>
        <Text>
          CardBody is padded but image above is not
        </Text>
      </CardBody>
    </Card>
  </Box>

  <Box>
    <Card>
      <CardBody>
        <Text>
          CardBody is padded but image below is not
        </Text>
      </CardBody>
      <Image fluid src="${imageSrc}" alt="test image" />
    </Card>
  </Box>
</Box>
  `,
};

const listExample = {
  name: 'List in a card',
  description: 'You can render lists inside Cards',
  render: () => (
    <Box display="flex">
      <Box marginRight="m" style={{ width: '300px' }}>
        <Card>
          <CardHeader>
            <Text display="block" type="display3">
              Latest Blog Posts
            </Text>
            <Text>Curated by us just for you</Text>
          </CardHeader>
          <List relaxed divided>
            {
              [1, 2, 3].map(i => (
                <ListItem key={i} arrow element="a" href="#link">
                  <ListMedia>
                    <Image roundedCorners size={SMALL} src={squareImageSrc} />
                  </ListMedia>

                  <ListContent>
                    <Text display="block" type="display6">Title One</Text>
                    <Text display="block" type="body2">A simple description</Text>
                  </ListContent>
                </ListItem>
              ))
            }
          </List>
        </Card>
      </Box>

      <Box style={{ width: '300px' }}>
        <Card>
          <CardHeader>
            <Text display="block" type="display3">
              Latest Blog Posts
            </Text>
            <Text>Curated by us just for you</Text>
          </CardHeader>
          <List divided>
            {
              [1, 2, 3].map(i => (
                <ListItem key={i}>
                  <ListMedia>
                    <Image roundedCorners size={TINY} src={squareImageSrc} />
                  </ListMedia>

                  <ListContent>
                    <Text>A simple description</Text>
                  </ListContent>
                </ListItem>
              ))
            }
          </List>
        </Card>
      </Box>
    </Box>
  ),
  code: `
<Box padding="m">
  <Card>
    <CardHeader>
      <Text display="block"type="display3">
        Latest Blog Posts
      </Text>
      <Text>Curated by us just for you</Text>
    </CardHeader>
    <List relaxed divided>
      {
        [1, 2, 3].map(i => (
          <ListItem key={i} arrow element="a" href="#link">
            <ListMedia>
              <Image roundedCorners size="${SMALL}" src="${squareImageSrc}" />
            </ListMedia>

            <ListContent>
              <Text display="block"type="display6">Title One</Text>
              <Text display="block"type="body2">A simple description</Text>
            </ListContent>
          </ListItem>
        ))
      }
    </List>
  </Card>
</Box>
  `,
};

const overflowExample = {
  name: 'Overflow',
  description: 'Cards overflow is hidden by default. You can set overflow to false to allow content to escape the card.',
  render: () => (
    <Box style={{ width: '400px' }}>
      <Card overflowHidden={false}>
        <Image
          style={{ width: '500px' }}
          src={imageSrc}
          alt="test image"
        />
        <CardBody>
          <Text>
            Text content in the body
          </Text>
        </CardBody>
      </Card>
    </Box>
  ),
  code: `
<Box padding="m" style={{ width: '400px' }}>
  <Card overflowHidden={false}>
    <Image
      style={{ width: '500px' }}
      src="${imageSrc}"
      alt="test image"
    />
    <CardBody>
      <Text>
        Text content in the body
      </Text>
    </CardBody>
  </Card>
</Box>
  `,
};

const Demo = {
  title: Card.displayName,
  description: 'A simple card for displaying a variety of content',
  docGen: docs,
  slug: 'card',
  exports: Object.keys(CardExports),
  examples: [
    textExample,
    onClickExample,
    transparentExample,
    roundedExample,
    elevationsExample,
    edgeToEdgeExample,
    listExample,
    overflowExample,
  ].map(e => ({ ...e, muted: true })),
};

export default Demo;
