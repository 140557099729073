import React from 'react';
import PropTypes from 'prop-types';
import dedentMarkdown from 'dedent';
import ReactMarkdown from 'react-markdown';
import Text from '../../../react/Text';
import Box from '../../../react/Box';
import TextLink from '../../../react/TextLink';
import List, { ListItem } from '../../../react/List';
import Code from '../Code';
import TextContainer from '../TextContainer';
import styles from './styles.css';

const tokenize = (str = '') => str.toLowerCase().split(' ').join('-');

const Markdown = ({ source, children, dedent }) => {
  return (
    <ReactMarkdown
      renderers={{
        root: TextContainer,
        // eslint-disable-next-line
        inlineCode: p => <code className={styles.inlineCode}>{p.value}</code>,
        code: p => (
          <Code roundedCorners>
            {`
${p.value}
            `}
          </Code>
        ),
        link: ({ href, ...p }) => <TextLink href={href} {...p} />,
        paragraph: p => <Text {...p} element="p" className={styles.p} />,
        heading: (p) => {
          // eslint-disable-next-line react/prop-types
          if (!p.children.length) return null;
          return <Text className={styles.markdownHeading} element={`h${p.level}`} display="block" type={`display${p.level}`} id={tokenize(p.children[0].props.value)}>{p.children}</Text>;
        },
        list: (p) => {
          // eslint-disable-next-line react/prop-types
          return <List bulleted ordered={p.ordered}>{ p.children }</List>;
        },
        thematicBreak: () => {
          // eslint-disable-next-line react/prop-types
          return <div className={styles.thematicBreak} />;
        },
        listItem: (p) => {
          // eslint-disable-next-line react/prop-types
          return <ListItem><Text>{ p.children }</Text></ListItem>;
        },
        blockquote: (p) => {
          // eslint-disable-next-line react/prop-types
          return (
            <Box
              marginTop="m"
              marginBottom="m"
              paddingLeft="m"
              display="inline-block"
              className={styles.blockquote}
            >
              { p.children }
            </Box>
          );
        },
      }}
      source={dedent ? dedentMarkdown(source || children) : (source || children)}
    />
  );
};


Markdown.propTypes = {
  source: PropTypes.node,
  children: PropTypes.node,
  dedent: PropTypes.bool,
};

Markdown.defaultProps = {
  source: '',
  children: '',
  dedent: true,
};

export default Markdown;
