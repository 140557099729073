import React from 'react';
import { defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import Avatar from '../../Avatar';
import Box from '../../Box';
import { SMALL } from '../../constants';
import List, { ListItem } from '../../List';
import Text from '../../Text';

const messages = defineMessages({
  accountsMenu: 'User account menu',
});

const AuthenticatedHeader = ({
  userInfo,
  openUserAccountMenu,
  intl,
}) => (
  <Box
    style={{ backgroundColor: 'var(--ds-color-background-alt-100)' }}
  >
    <List relaxed>
      <ListItem
        arrow
        onClick={openUserAccountMenu}
        role="button"
        aria-labelledby={intl.formatMessage(messages.accountsMenu)}
      >
        <Box display="flex" alignItems="center">
          <Box marginRight="m" marginLeft="m" style={{ width: 'var(--ds-size-dimension-xxl)' }}>
            <Avatar
              size={SMALL}
              username={userInfo.username}
              src={userInfo.avatar}
              fluid
            />
          </Box>
          <Text type="display5" display="block">
            {userInfo.username}
          </Text>
        </Box>
      </ListItem>
    </List>
  </Box>
);

AuthenticatedHeader.propTypes = {
  userInfo: PropTypes.shape({
    avatar: PropTypes.string,
    username: PropTypes.string,
    locale: PropTypes.string,
    country: PropTypes.string,
    isLoggedIn: PropTypes.bool,
  }).isRequired,
  openUserAccountMenu: PropTypes.func.isRequired,
};

export default AuthenticatedHeader;
