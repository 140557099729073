import React from 'react';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { useCombobox } from 'downshift';
import { makeProductContextForSearch, AnalyticsContextTypes, AnalyticsLocalStorageKeys, AnalyticsStorage } from '../utils';
import { ARTISTS } from '../constants';
import { analyticsPayload } from '../../../lib/analytics';
import { DESKTOP } from '../../../../constants';

export default function useCompletions({
  term,
  productContext,
  items,
  initialIsOpen,
  storeAsRecentSearch,
  doSearch,
  onSearchClose,
  currentInputValue,
  setCurrentInputValue,
  profile,
  onSearchOpen,
  logEvent,
  blurInputOnClose,
  onSearchCallback,
}) {
  const debouncedInputUpdate = React.useRef(null);

  if (!debouncedInputUpdate.current) {
    debouncedInputUpdate.current = debounce((inputValue, isOpen) => {
      if (isOpen) setCurrentInputValue(inputValue);
    }, 100);
  }

  const initialIsOpenValue = initialIsOpen !== undefined
    ? initialIsOpen
    : (!!term || !!get(productContext, 'id'));

  const logItemClicked = ({ inputValue, keywords, itemIaCode, itemType, itemPosition }) => {
    logEvent({
      analytics: analyticsPayload.typeaheadExperienceItemClicked({
        inputValue,
        productContextIaCode: get(productContext, 'id', null),
        keywords,
        itemIaCode,
        itemType,
        itemPosition,
      }),
    });
  };

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    closeMenu: downshiftCloseMenu,
    setInputValue,
  } = useCombobox({
    initialIsOpen: initialIsOpenValue,
    initialInputValue: term,
    items,
    itemToString: item => get(item, 'keywords', ''),
    stateReducer: (state, actionAndChanges) => {
      switch (actionAndChanges.type) {
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...actionAndChanges.changes,
            isOpen: true,
          };
        default:
          return actionAndChanges.changes;
      }
    },
    onSelectedItemChange: (changes) => {
      if (!changes.selectedItem) return;
      const searchTerm = get(changes, 'selectedItem.keywords', '');
      const type = get(changes, 'selectedItem.type', '');
      const position = get(changes, 'selectedItem.position', '');
      const url = get(changes, 'selectedItem.url', '');

      if (!searchTerm) return;

      const isLink = get(changes, 'selectedItem.linkLabel', false);
      const newProductContext = makeProductContextForSearch(
        isLink ? null : productContext,
        changes.selectedItem,
      );

      logItemClicked({
        inputValue: currentInputValue,
        keywords: searchTerm,
        itemIaCode: newProductContext.id,
        itemType: type,
        itemPosition: position,
      });
      if (type === ARTISTS && url) {
        storeAsRecentSearch({ term: searchTerm, originalItem: changes.selectedItem });
        window.location = url;
      } else {
        doSearch(searchTerm, newProductContext);
      }
      if (typeof onSearchClose === 'function') {
        onSearchClose();
      }
    },
    onInputValueChange: ({ inputValue, isOpen: openWhenChanged }) => {
      setCurrentInputValue(inputValue);
      debouncedInputUpdate.current(inputValue, openWhenChanged);
    },
  });

  const closeMenu = (e, logCloseEvent = true) => {
    if (e) {
      e.target.blur();
    }
    if (typeof onSearchClose === 'function') {
      onSearchClose();
    }
    if (isOpen && logCloseEvent) {
      logEvent({
        analytics: analyticsPayload.typeaheadExperienceClosed({
          inputValue: currentInputValue,
          productContextIaCode: get(productContext, 'id', null),
        }),
      });
    }

    if (!e && blurInputOnClose) {
      const searchInput = document.querySelector('header input[type="search"]');
      if (searchInput) {
        searchInput.blur();
      }
    }

    downshiftCloseMenu();
  };

  const inputProps = getInputProps({
    onFocus: () => {
      openMenu();
      if (profile === DESKTOP && !isOpen) {
        logEvent({
          analytics: analyticsPayload.typeaheadExperienceOpened({
            inputValue: currentInputValue,
            productContextIaCode: get(productContext, 'id', null),
          }),
        });
      }
      if (typeof onSearchOpen === 'function') {
        onSearchOpen();
      }
    },
    onKeyDown: (e) => {
      if (e.key === 'Enter') {
        if (highlightedIndex === -1) {
          e.nativeEvent.preventDownshiftDefault = true;
          logEvent({
            analytics: analyticsPayload.typeaheadExperienceReturned({
              inputValue: e.target.value,
              productContextIaCode: get(productContext, 'id', null),
            }),
          });
          if (onSearchCallback) {
            onSearchCallback();
          } else {
            AnalyticsStorage.setValue(
              AnalyticsLocalStorageKeys.RefContextType,
              AnalyticsContextTypes.SearchBox,
            );
          }
          doSearch(e.target.value, productContext);
          closeMenu(e, false);
        } else {
          closeMenu(e, false);
        }
      }
      if (e.key === 'Escape') {
        closeMenu(e);
      }
    },
    refKey: 'innerRef',
  });

  return {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    closeMenu,
    setInputValue,
    inputProps,
    logItemClicked,
  };
}
