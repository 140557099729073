import React from 'react';
import styles from '../styles.scss';

const SVG = ({
  fillColor,
  brandMarkColor = '#e41321',
}) => (
  <svg
    role="img"
    data-testid="ds-2019-brandmark-only"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.svg}
  >
    <title>Redbubble brandmark</title>
    <path
      data-testid="ds-brandmark"
      fill={fillColor || brandMarkColor}
      d="M22.18 21.77h-4.27a.44.44 0 01-.43-.43V10.63a.44.44 0 01.43-.43h4c3 0 3.59 1.75 3.59 3.22a2.88 2.88 0 01-.68 2 2.88 2.88 0 011.68 2.93c0 2.13-1.6 3.41-4.28 3.41m-6.2 0H7.13a.43.43 0 01-.43-.43v-10.7a.43.43 0 01.43-.43h4.14c2.58 0 4.12 1.4 4.12 3.73a3.33 3.33 0 01-2 3.26l2.94 3.86a.43.43 0 01-.32.72M16 0a16 16 0 1016 16A16 16 0 0016 0"
    />
  </svg>
);

export default SVG;
