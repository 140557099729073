import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';

import * as BoxExports from '.';

// eslint-disable-next-line
import BoxSrc from '!raw-loader!.';

const { default: Box } = BoxExports;
const docs = generateDocs(BoxSrc, resolver.findAllExportedComponentDefinitions);

const basicExample = {
  name: 'Simple Example',
  description: 'Boxes can be used for spacing',
  overflowVisual: false,
  render: () => (
    <Box style={{ border: '1px solid #6551CC' }} padding="m">
      <Box style={{ border: '1px solid #6551CC' }} padding="xs" marginBottom="m">
        Content
      </Box>

      <Box style={{ border: '1px solid #6551CC' }} padding="xs">
        Content
      </Box>
    </Box>
  ),
  code: `
// Border styles for demonstration purposes only
<Box style={{ border: '1px solid #6551CC' }} padding="m">
  <Box style={{ border: '1px solid #6551CC' }} padding="xs" marginBottom="m">
    Content
  </Box>

  <Box style={{ border: '1px solid #6551CC' }} padding="xs">
    Content
  </Box>
</Box>
  `,
};

const flexExample = {
  name: 'Flex Example',
  description: 'Set the display type of a Box to create layouts, such as a flex based layout.',
  overflowVisual: false,
  render: () => (
    <Box
      style={{ border: '1px solid #6551CC' }}
      padding="m"
      display="flex"
      justifyContent="space-between"
    >
      <Box style={{ border: '1px solid #6551CC' }} padding="xs" flex="1" marginRight="m">
        Content
      </Box>

      <Box style={{ border: '1px solid #6551CC' }} padding="xs" flex="2">
        Content
      </Box>
    </Box>
  ),
  code: `
// Border styles for demonstration purposes only
<Box
  style={{ border: '1px solid #6551CC' }}
  padding="m"
  display="flex"
  justifyContent="space-between"
>
  <Box style={{ border: '1px solid #6551CC' }} padding="xs" flex="1" marginRight="m">
    Content
  </Box>

  <Box style={{ border: '1px solid #6551CC' }} padding="xs" flex="2">
    Content
  </Box>
</Box>
  `,
};

const Demo = {
  title: Box.displayName,
  description: 'Box is a utility component to assist with spacing and layout',
  docGen: docs,
  slug: 'Box',
  exports: Object.keys(BoxExports),
  examples: [
    basicExample,
    flexExample,
  ],
  notes: `
### Why use Box instead of CSS?

The \`Box\` component uses an atomic CSS stylesheet. This means that there is a finite amount of CSS that supports all of the possible uses of \`Box\`.

We believe that \`Box\` alone should solve 90% of simple layout / spacing challenges typically solved with CSS. Therefore, instead of you having to write another CSS rule _just_ to apply some \`margin\` or \`padding\`, you can use \`Box\` and save the extra lines of CSS.

Over time, if you use Box for all your layout / spacing you'll end up sending significantly less spacing/layout related CSS to your end user.

\`Box\` aims to replace familiar situations like this:

\`\`\`
.wrapper {
  padding: $unit;
  display: flex;
}

.sideContent {
  flex: 1;
  margin-right: $unit * 0.5;
  padding: $unit;
}

.mainContent {
  flex: 2;
  padding: $unit;
}

<div className={styles.wrapper}>
  <div className={styles.sideContent}>
    ...
  </div>

  <div className={styles.mainContent}>
    ...
  </div>
</div>
\`\`\`

with something simpler like this:

\`\`\`
<Box padding="m" display="flex">
  <Box flex="1" marginRight="xs" padding="m">
    ...
  </Box>

  <Box flex="2" padding="m">
    ...
  </Box>
</Box>
\`\`\`

**What is atomic CSS?**

What it boils down to is classes that do one (or very few) things. For e.g.

\`\`\`
.padding-1 {
  padding: 16px
}

.padding-05 {
  padding: 8px
}

.display-flex {
  display: flex;
}
\`\`\`

By using atomic CSS rules, you can compose many unique styles for your \`Box\` without needing many bespoke rules. \`Box\` provides a declarative React props API which composes these atomic classes behind the scenes for you.

### Custom elements

The \`Box\` component renders a \`div\` by default. Whilst this is useful for most layout needs, sometimes it's preferable to render other semantic HTML elements such as \`section\` or \`article\`.

The \`Box\` is capable of rendering *any* valid HTML element via the \`element\` prop, although we recommend you use this with discretion, and don't stray too far from the intended use of enabling semantic / accessible elements.

e.g.

\`\`\`
// React
<Box element="article">
  <Box element="section">
    <Box>Content</Box>
  </Box>
</Box>

// HTML
<article>
  <section>
    <div>Content</div>
  </section>
</article>
\`\`\`

  `,
};

export default Demo;
