let cmsEntries = require.context('../../../_cms/guideline', false, /.json$/);

cmsEntries = cmsEntries.keys().reduce((memo, slug) => {
  const entry = cmsEntries(slug);
  return ({
    ...memo,
    [entry.title.toLowerCase()]: entry,
  });
}, {});

export default { ...cmsEntries };
