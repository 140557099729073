import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { ScrollLink } from 'react-scroll';
import { StickyContainer, Sticky } from 'react-sticky';
import cnames from 'classnames';
import ResponsiveConsumer from '../ResponsiveConsumer';
import Markdown from '../Markdown';
import Example from '../Example';
import { PanelFooter } from '../Panel';
import PageIntro from '../PageIntro';
import PageTitle from '../PageTitle';
import Code from '../Code';
import PropList from '../PropList';
import TabBar, { Tab } from '../TabBar';
import UsageTab from '../Usage';
import PageDescription from '../PageDescription';
import BetaWarning from '../BetaWarning';
import Text from '../../../react/Text';
import Box from '../../../react/Box';
import Button from '../../../react/Button';
import TextLink from '../../../react/TextLink';
import * as constants from '../../../react/constants';
import styles from './styles.css';
import cmsEntries from './guidelines';

const ScrollButton = ScrollLink((props) => {
  return (
    <Box className={props.className} marginY={0.5}>
      <Text type="body2">
        <TextLink
          subtle
          size={constants.SMALL}
          href={`#${props.href}`}
          {...props}
        />
      </Text>
    </Box>
  );
});

const ExamplesMenu = React.memo(({ responsive, examples, onLinkClicked }) => (
  <React.Fragment>
    <Box padding="xl">
      { !responsive.mobile && (
        <Text type="display3">Examples</Text>
      )}
      <Box marginTop="m" className={styles.stickyInner}>
        {examples.map((example) => {
          const name = example.name.toLowerCase().replace(/ /g, '-');

          return (
            <ScrollButton
              to={name}
              href={name}
              key={name}
              className={styles.anchorLink}
              smooth
              spy
              hashSpy
              offset={-160}
              activeClass={styles.active}
              onClick={responsive.mobile ? onLinkClicked : undefined}
            >
              {example.name}
            </ScrollButton>
          );
        })}
      </Box>
    </Box>
  </React.Fragment>
));

class ComponentDemo extends Component {
  constructor() {
    super();

    this.state = {
      showShortcuts: false,
    };

    this.toggleShortcuts = this.toggleShortcuts.bind(this);
  }

  toggleShortcuts() {
    const { showShortcuts } = this.state;
    this.setState({ showShortcuts: !showShortcuts });
  }

  render() {
    const {
      title,
      customImportName,
      customImportPath,
      slug,
      exports,
      description,
      examples,
      callout,
      beta,
      docGen,
      notes,
      relatedContent,
    } = this.props;

    const componentSlug = slug.replace('/components/', '').toLowerCase();
    const cmsUsage = cmsEntries[componentSlug];
    const modules = exports.length > 1 ? `, { ${exports.filter(e => e !== 'default').join(', ')} }` : '';

    const defaultModuleName = customImportName || title;
    const defaultModulePath = customImportPath || `@redbubble/design-system/react/${title}`;

    const { showShortcuts } = this.state;
    return (
      <ResponsiveConsumer>
        {
          responsive => (
            <React.Fragment>
              <Box>
                <Box display="flex">
                  <Box
                    flex="3"
                    className={styles.demoContainer}
                  >
                    <PageIntro>
                      <PageTitle>{title}</PageTitle>
                      <PageDescription>{ cmsUsage ? cmsUsage.blurb : description}</PageDescription>
                      { beta && <BetaWarning /> }
                    </PageIntro>
                    { callout }

                    <TabBar rootPath={slug}>
                      <Tab label="Examples">
                        { responsive.mobile && (
                          <Box display="flex" justifyContent="flex-end">
                            <TextLink
                              strong
                              onClick={this.toggleShortcuts}
                            >
                              Overview
                            </TextLink>
                          </Box>
                        )}
                        {
                          examples.map(example => (
                            <Example
                              key={example.name}
                              name={example.name}
                              description={example.description}
                              config={example.config}
                              code={example.code}
                              callout={example.callout}
                              render={example.render}
                              muted={example.muted}
                              carded={example.carded}
                              dark={example.dark}
                              disableLiveEdit={example.disableLiveEdit}
                              overflowVisual={example.overflowVisual}
                              allowFullscreen={example.allowFullscreen}
                            />
                          ))
                        }
                      </Tab>
                      <Tab label="API Docs">
                        <Text type="display3" display="block" id="props">Import</Text>

                        <Box marginTop="m" marginBottom="xl">
                          <div className={styles.codeWrapper}>
                            <Code>
                              {
                                `
    import ${defaultModuleName}${modules} from '${defaultModulePath}';
                                `
                              }
                            </Code>
                          </div>
                        </Box>

                        {
                          notes && (
                            <React.Fragment>
                              <Box marginTop="m" marginBottom="xl">
                                <Markdown source={notes} />
                              </Box>
                            </React.Fragment>
                          )
                        }

                        {
                          docGen && Object.keys(docGen).length > 0 && (
                            <React.Fragment>
                              <Text type="display3" display="block" id="props">Supported Props</Text>

                              <Box marginTop="m" marginBottom="xl">
                                { docGen
                                  ? <PropList docGen={docGen} /> : <Text>Not yet documented</Text>
                                }
                              </Box>
                            </React.Fragment>
                          )
                        }
                      </Tab>
                      {
                        cmsUsage && (
                          <Tab label="Usage">
                            <UsageTab usage={cmsUsage} />
                          </Tab>
                        )
                      }
                    </TabBar>
                  </Box>
                  <Route
                    exact
                    path={`${slug}/examples`}
                    render={() => (
                      <Box
                        display="flex"
                        flex="1"
                        flexDirection="column"
                        alignItems="flex-start"
                        className={cnames(styles.anchorLinksOuter, { [styles.in]: showShortcuts })}
                      >
                        <div
                          tabIndex={0}
                          role="button"
                          className={styles.backdrop}
                          onClick={this.toggleShortcuts}
                        />
                        <div className={styles.anchorLinksInner}>
                          { responsive.mobile && (
                            <Box display="flex" justifyContent="flex-end">
                              <Button
                                strong
                                onClick={this.toggleShortcuts}
                              >
                                Close
                              </Button>
                            </Box>
                          )}
                          <StickyContainer className={styles.stickyOuter}>
                            <Sticky>
                              {({ style }) => (
                                <div style={style}>
                                  <ExamplesMenu
                                    responsive={responsive}
                                    examples={examples}
                                    onLinkClicked={this.toggleShortcuts}
                                  />
                                </div>
                              )}
                            </Sticky>
                          </StickyContainer>
                        </div>
                      </Box>
                    )}
                  />
                </Box>

                <PanelFooter relatedContent={relatedContent} />
              </Box>
            </React.Fragment>
          )
        }
      </ResponsiveConsumer>
    );
  }
}

ComponentDemo.propTypes = {
  title: PropTypes.string.isRequired,
  customImportName: PropTypes.string,
  customImportPath: PropTypes.string,
  description: PropTypes.string.isRequired,
  examples: PropTypes.arrayOf(PropTypes.object).isRequired,
  exports: PropTypes.arrayOf(PropTypes.string),
  beta: PropTypes.bool,
  docGen: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
  slug: PropTypes.string.isRequired,
  notes: PropTypes.string,
};

ComponentDemo.defaultProps = {
  beta: false,
  exports: [],
  docGen: {},
  notes: '',
  customImportName: null,
  customImportPath: null,
};

export default ComponentDemo;
