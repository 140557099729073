import {
  useState,
  useEffect,
} from 'react';

import {
  encode as base64encode,
  decode as base64decode,
} from 'base64-url';

import defaultStore from './recentSearchesDefaultStore';

const getRecentSearches = (namespace, store) => {
  const storage = store.get(namespace);
  return storage ? JSON.parse(base64decode(storage)) : [];
};

const useRecentSearches = (limit, namespace = 'rb-recent-searches', store = defaultStore) => {
  const [recentSearches, setRecentSearches] = useState();

  useEffect(() => {
    if (!recentSearches) {
      setRecentSearches(getRecentSearches(namespace, store));
    }
  }, []);

  const storeAsRecentSearch = ({ term, context, originalItem }) => {
    if (term !== '') {
      const existing = recentSearches.filter(({ term: t }) => t !== term);
      const searches = [...[{ term, context, originalItem }], ...existing].slice(0, limit);
      const storage = base64encode(JSON.stringify(searches));

      store.set(namespace, storage);
      setRecentSearches(searches);
    }
  };

  const deleteRecentSearch = (term) => {
    const searches = recentSearches || getRecentSearches(namespace, store);
    const newSearches = searches.filter(({ term: t }) => t !== term);
    const storage = base64encode(JSON.stringify(newSearches));

    store.set(namespace, storage);
    setRecentSearches(newSearches);
  };

  const clearRecentSearches = () => {
    store.clear(namespace);
    setRecentSearches([]);
  };

  return {
    recentSearches: recentSearches || getRecentSearches(namespace, store),
    storeAsRecentSearch,
    deleteRecentSearch,
    clearRecentSearches,
  };
};

export default useRecentSearches;
