// This module and it's dependencies need
// to be in common-js to be importable at config time in Boom apps

const types = require('./tokenTypes');

module.exports = {
  // COLORS

  // Primary
  '--ds-color-primary': {
    type: types.COLOR,
    semanticType: types.PRIMARY,
    description: 'Primary action color',
  },

  // Neutral
  '--ds-color-neutral-50': {
    type: types.COLOR,
    semanticType: types.NEUTRAL,
    description: 'Neutral 50',
  },
  '--ds-color-neutral-100': {
    type: types.COLOR,
    semanticType: types.NEUTRAL,
    description: 'Neutral 100',
  },
  '--ds-color-neutral-200': {
    type: types.COLOR,
    semanticType: types.NEUTRAL,
    description: 'Neutral 200',
  },
  '--ds-color-neutral-300': {
    type: types.COLOR,
    semanticType: types.NEUTRAL,
    description: 'Neutral 300',
  },
  '--ds-color-neutral-500': {
    type: types.COLOR,
    semanticType: types.NEUTRAL,
    description: 'Neutral 500',
  },
  '--ds-color-neutral-800': {
    type: types.COLOR,
    semanticType: types.NEUTRAL,
    description: 'Neutral 800',
  },
  '--ds-color-neutral-900': {
    type: types.COLOR,
    semanticType: types.NEUTRAL,
    description: 'Neutral 900',
  },

  // Success
  '--ds-color-success-100': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Notification Success 100',
  },
  '--ds-color-success-200': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Notification Success 200',
  },
  '--ds-color-success-500': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Notification Success 500',
  },
  '--ds-color-success-600': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Notification Success 600',
  },
  '--ds-color-success-700': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Notification Success 700',
  },

  // Information
  '--ds-color-information-100': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Notification Information 100',
  },
  '--ds-color-information-200': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Notification Information 200',
  },
  '--ds-color-information-300': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Notification Information 300',
  },
  '--ds-color-information-500': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Notification Information 500',
  },
  '--ds-color-information-600': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Notification Information 600',
  },
  '--ds-color-information-700': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Notification Information 700',
  },

  // Warning
  '--ds-color-warning-100': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Notification Warning 100',
  },
  '--ds-color-warning-200': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Notification Warning 200',
  },
  '--ds-color-warning-500': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Notification Warning 500',
  },
  '--ds-color-warning-600': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Notification Warning 600',
  },
  '--ds-color-warning-700': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Notification Warning 700',
  },

  // Error
  '--ds-color-error-100': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Notification Error 100',
  },
  '--ds-color-error-200': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Notification Error 200',
  },
  '--ds-color-error-500': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Notification Error 500',
  },
  '--ds-color-error-600': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Notification Error 600',
  },
  '--ds-color-error-700': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Notification Error 700',
  },

  // Destructive
  '--ds-color-destructive-100': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Destructive action 100',
  },
  '--ds-color-destructive-200': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Destructive action 100',
  },
  '--ds-color-destructive-500': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Destructive action 500',
  },
  '--ds-color-destructive-600': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Destructive action 600',
  },
  '--ds-color-destructive-700': {
    type: types.COLOR,
    semanticType: types.INTENT,
    description: 'Destructive action 700',
  },

  // Expressive Green
  '--ds-color-expressive-green-100': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive green 100',
  },
  '--ds-color-expressive-green-300': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive green 300',
  },
  '--ds-color-expressive-green-500': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive green 500',
  },
  '--ds-color-expressive-green-700': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive green 700',
  },

  // Expressive blue
  '--ds-color-expressive-blue-100': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive blue 100',
  },
  '--ds-color-expressive-blue-300': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive blue 300',
  },
  '--ds-color-expressive-blue-500': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive blue 500',
  },
  '--ds-color-expressive-blue-700': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive blue 700',
  },

  // Expressive red
  '--ds-color-expressive-red-100': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive red 100',
  },
  '--ds-color-expressive-red-300': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive red 300',
  },
  '--ds-color-expressive-red-500': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive red 500',
  },
  '--ds-color-expressive-red-700': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive red 700',
  },

  // Expressive yellow
  '--ds-color-expressive-yellow-100': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive yellow 100',
  },
  '--ds-color-expressive-yellow-300': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive yellow 300',
  },
  '--ds-color-expressive-yellow-500': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive yellow 500',
  },
  '--ds-color-expressive-yellow-700': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive yellow 700',
  },

  // Expressive pink
  '--ds-color-expressive-pink-100': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive pink 100',
  },
  '--ds-color-expressive-pink-300': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive pink 300',
  },
  '--ds-color-expressive-pink-500': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive pink 500',
  },
  '--ds-color-expressive-pink-700': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive pink 700',
  },

  // Expressive teal
  '--ds-color-expressive-teal-100': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive teal 100',
  },
  '--ds-color-expressive-teal-300': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive teal 300',
  },
  '--ds-color-expressive-teal-500': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive teal 500',
  },
  '--ds-color-expressive-teal-700': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive teal 700',
  },

  // Expressive purple
  '--ds-color-expressive-purple-100': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive purple 100',
  },
  '--ds-color-expressive-purple-300': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive purple 300',
  },
  '--ds-color-expressive-purple-500': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive purple 500',
  },
  '--ds-color-expressive-purple-700': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive purple 700',
  },

  // Expressive orange
  '--ds-color-expressive-orange-100': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive orange 100',
  },
  '--ds-color-expressive-orange-300': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive orange 300',
  },
  '--ds-color-expressive-orange-500': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive orange 500',
  },
  '--ds-color-expressive-orange-700': {
    type: types.COLOR,
    semanticType: types.EXPRESSIVE,
    description: 'Expressive orange 700',
  },

  // Brand
  '--ds-color-brand': {
    type: types.COLOR,
    semanticType: types.BRAND,
    description: 'Our brand color',
  },

  // Common
  '--ds-color-white': {
    type: types.COLOR,
    semanticType: types.COMMON,
    description: 'Pure white',
  },
  '--ds-color-black': {
    type: types.COLOR,
    semanticType: types.COMMON,
    description: 'Pure black',
  },
  '--ds-color-transparent': {
    type: types.COLOR,
    semanticType: types.COMMON,
    description: 'Transparent',
  },

  // SEMANTIC: BORDER
  '--ds-color-border': {
    type: types.COLOR,
    semanticType: types.BORDER,
    description: 'Our default border color',
  },
  '--ds-color-border-light': {
    type: types.COLOR,
    semanticType: types.BORDER,
    description: 'A lighter border color to use when the default is too emphasized',
  },

  // SEMANTIC: TEXT
  '--ds-color-text': {
    type: types.COLOR,
    semanticType: types.TEXT,
    description: 'The text color used for our body text',
  },
  '--ds-color-text-muted': {
    type: types.COLOR,
    semanticType: types.TEXT,
    description: 'Text color used to reduce contrast and create visual hierarchy',
  },
  '--ds-color-text-contrast': {
    type: types.COLOR,
    semanticType: types.TEXT,
    description: 'The text color used for our body text on dark backgrounds',
  },
  '--ds-color-text-muted-contrast': {
    type: types.COLOR,
    semanticType: types.TEXT,
    description: 'Text color used to reduce contrast and create visual hierarchy on dark backgrounds',
  },

  // SEMANTIC: BACKGROUND

  // UI
  '--ds-color-background-ui': {
    type: types.COLOR,
    semanticType: types.BACKGROUND,
    description: '',
  },
  '--ds-color-background-ui-contrast': {
    type: types.COLOR,
    semanticType: types.BACKGROUND,
    description: '',
  },
  '--ds-color-background-ui-medium': {
    type: types.COLOR,
    semanticType: types.BACKGROUND,
    description: '',
  },
  '--ds-color-background-ui-high': {
    type: types.COLOR,
    semanticType: types.BACKGROUND,
    description: '',
  },

  // Alternate
  '--ds-color-background-alt-50': {
    type: types.COLOR,
    semanticType: types.BACKGROUND,
    description: '',
  },
  '--ds-color-background-alt-100': {
    type: types.COLOR,
    semanticType: types.BACKGROUND,
    description: '',
  },
  '--ds-color-background-alt-200': {
    type: types.COLOR,
    semanticType: types.BACKGROUND,
    description: '',
  },
  '--ds-color-background-alt-500': {
    type: types.COLOR,
    semanticType: types.BACKGROUND,
    description: '',
  },

  // Application
  '--ds-color-background-app': {
    type: types.COLOR,
    semanticType: types.BACKGROUND,
    description: '',
  },
  '--ds-color-background-app-light': {
    type: types.COLOR,
    semanticType: types.BACKGROUND,
    description: '',
  },
  '--ds-color-background-app-dark': {
    type: types.COLOR,
    semanticType: types.BACKGROUND,
    description: '',
  },

  // SEMANTIC: OVERLAY
  '--ds-color-background-overlay-transparent': {
    type: types.COLOR,
    semanticType: types.BACKGROUND,
    description: '',
  },
  '--ds-color-background-overlay': {
    type: types.COLOR,
    semanticType: types.BACKGROUND,
    description: '',
  },

  // Disabled State
  '--ds-color-state-disabled-text': {
    type: types.COLOR,
    semanticType: types.STATE,
    description: 'Used to indicate elements that are disabled',
  },
  '--ds-color-state-disabled-background': {
    type: types.COLOR,
    semanticType: types.STATE,
    description: 'Used to indicate elements that are disabled',
  },

  // Focus State
  '--ds-color-state-focus-border': {
    type: types.COLOR,
    semanticType: types.STATE,
    description: 'Used to indicate elements that are focused',
  },

  // Loading State
  '--ds-color-state-loading-background': {
    type: types.COLOR,
    semanticType: types.STATE,
    description: 'Used to indicate elements that are placeholders or loading indicators.',
  },
  '--ds-color-state-loading-shimmer': {
    type: types.COLOR,
    semanticType: types.STATE,
    description: 'Used to highlight the shimmer shown on animated placeholders or loading indicators.',
  },

  // Hover State
  '--ds-color-state-hover-overlay': {
    type: types.COLOR,
    semanticType: types.STATE,
    description: 'This is the color used to create a hover effect on interactive elements',
  },
  '--ds-color-state-hover-overlay-contrast': {
    type: types.COLOR,
    semanticType: types.STATE,
    description: 'This is the color used to create a hover effect on interactive elements',
  },


  // FONT_FAMILY
  '--ds-font-family': {
    type: types.FONT_FAMILY,
    description: 'The core font family for your apps',
  },

  // FONT_WEIGHT
  '--ds-font-weight-light': {
    type: types.FONT_WEIGHT,
    description: 'The default weight for body copy',
  },
  '--ds-font-weight-normal': {
    type: types.FONT_WEIGHT,
    description: 'Used sparingly for small text that otherwise would be difficult to read',
  },
  '--ds-font-weight-bold': {
    type: types.FONT_WEIGHT,
    description: 'Used to emphasize text and actions',
  },
  '--ds-font-weight-display1': {
    type: types.FONT_WEIGHT,
    description: 'Constant font weight token for font size 1',
  },
  '--ds-font-weight-display2': {
    type: types.FONT_WEIGHT,
    description: 'Constant font weight token for font size 2',
  },
  '--ds-font-weight-display3': {
    type: types.FONT_WEIGHT,
    description: 'Constant font weight token for font size 3',
  },
  '--ds-font-weight-display4': {
    type: types.FONT_WEIGHT,
    description: 'Constant font weight token for font size 4',
  },
  '--ds-font-weight-display5': {
    type: types.FONT_WEIGHT,
    description: 'Constant font weight token for font size 5',
  },
  '--ds-font-weight-display6': {
    type: types.FONT_WEIGHT,
    description: 'Constant font weight token for font size 6',
  },
  '--ds-font-weight-body': {
    type: types.FONT_WEIGHT,
    description: 'Constant font weight token for our body font-weight',
  },
  '--ds-font-weight-body2': {
    type: types.FONT_WEIGHT,
    description: 'Constant font weight token for body font-weight small',
  },
  '--ds-font-weight-caption': {
    type: types.FONT_WEIGHT,
    description: 'Constant font weight token for our caption font-weight',
  },

  // FONT_SIZE
  '--ds-font-size-display1': {
    type: types.FONT_SIZE,
    description: 'Constant font size token for font size 1',
  },
  '--ds-font-size-display2': {
    type: types.FONT_SIZE,
    description: 'Constant font size token for font size 2',
  },
  '--ds-font-size-display3': {
    type: types.FONT_SIZE,
    description: 'Constant font size token for font size 3',
  },
  '--ds-font-size-display4': {
    type: types.FONT_SIZE,
    description: 'Constant font size token for font size 4',
  },
  '--ds-font-size-display5': {
    type: types.FONT_SIZE,
    description: 'Constant font size token for font size 5',
  },
  '--ds-font-size-display6': {
    type: types.FONT_SIZE,
    description: 'Constant font size token for font size 6',
  },
  '--ds-font-size-body': {
    type: types.FONT_SIZE,
    description: 'Constant font size token for our body font-size',
  },
  '--ds-font-size-body2': {
    type: types.FONT_SIZE,
    description: 'Constant font size token for body font-size small',
  },
  '--ds-font-size-caption': {
    type: types.FONT_SIZE,
    description: 'Constant font size token for our caption font-size',
  },


  // LINE_HEIGHT
  '--ds-line-height-display1': {
    type: types.LINE_HEIGHT,
    description: 'Constant line-height token for font size 1',
  },
  '--ds-line-height-display2': {
    type: types.LINE_HEIGHT,
    description: 'Constant line-height token for font size 2',
  },
  '--ds-line-height-display3': {
    type: types.LINE_HEIGHT,
    description: 'Constant line-height token for font size 3',
  },
  '--ds-line-height-display4': {
    type: types.LINE_HEIGHT,
    description: 'Constant line-height token for font size 4',
  },
  '--ds-line-height-display5': {
    type: types.LINE_HEIGHT,
    description: 'Constant line-height token for font size 5',
  },
  '--ds-line-height-display6': {
    type: types.LINE_HEIGHT,
    description: 'Constant line-height token for font size 6',
  },
  '--ds-line-height-body': {
    type: types.LINE_HEIGHT,
    description: 'Constant line-height token for our body line-height',
  },
  '--ds-line-height-body2': {
    type: types.LINE_HEIGHT,
    description: 'Constant line-height token for body line-height small',
  },
  '--ds-line-height-caption': {
    type: types.LINE_HEIGHT,
    description: 'Constant line-height token for our caption line-height',
  },

  // RADIUS
  '--ds-radius-small': {
    type: types.RADIUS,
    description: 'The default radius for most RADIUSs like cards, alerts and labels',
  },
  '--ds-radius-medium': {
    type: types.RADIUS,
    description: 'Used for form fields and focus states outside the default radius',
  },
  '--ds-radius-pill': {
    type: types.RADIUS,
    description: 'Used for pill shapes and circles',
  },

  // Size
  '--ds-size-border-width-hairline': {
    type: types.SIZE,
    semanticType: types.BORDER_WIDTH,
    description: 'Thin borders used to separate items in things like lists',
  },
  '--ds-size-border-width-small': {
    type: types.SIZE,
    semanticType: types.BORDER_WIDTH,
    description: 'Our default border width used to separate sections and contain content like buttons and forms.',
  },
  '--ds-size-border-width-medium': {
    type: types.SIZE,
    semanticType: types.BORDER_WIDTH,
    description: 'Used as focus state width',
  },

  // DEPRECATED DIMENSIONS
  '--ds-size-dimension-01': {
    type: types.SIZE,
    semanticType: types.DIMENSION,
    description: '',
    deprecated: true,
  },
  '--ds-size-dimension-02': {
    type: types.SIZE,
    semanticType: types.DIMENSION,
    description: '',
    deprecated: true,
  },
  '--ds-size-dimension-03': {
    type: types.SIZE,
    semanticType: types.DIMENSION,
    description: '',
    deprecated: true,
  },
  '--ds-size-dimension-04': {
    type: types.SIZE,
    semanticType: types.DIMENSION,
    description: '',
    deprecated: true,
  },
  '--ds-size-dimension-05': {
    type: types.SIZE,
    semanticType: types.DIMENSION,
    description: '',
    deprecated: true,
  },
  '--ds-size-dimension-06': {
    type: types.SIZE,
    semanticType: types.DIMENSION,
    description: '',
    deprecated: true,
  },
  '--ds-size-dimension-07': {
    type: types.SIZE,
    semanticType: types.DIMENSION,
    description: '',
    deprecated: true,
  },
  '--ds-size-dimension-08': {
    type: types.SIZE,
    semanticType: types.DIMENSION,
    description: '',
    deprecated: true,
  },
  '--ds-size-dimension-09': {
    type: types.SIZE,
    semanticType: types.DIMENSION,
    description: '',
    deprecated: true,
  },
  '--ds-size-dimension-10': {
    type: types.SIZE,
    semanticType: types.DIMENSION,
    description: '',
    deprecated: true,
  },

  // DIMENSIONS
  '--ds-size-dimension': {
    type: types.SIZE,
    semanticType: types.DIMENSION,
    description: '',
  },
  '--ds-size-dimension-xxxs': {
    type: types.SIZE,
    semanticType: types.DIMENSION,
    description: '',
  },
  '--ds-size-dimension-xxs': {
    type: types.SIZE,
    semanticType: types.DIMENSION,
    description: '',
  },
  '--ds-size-dimension-xs': {
    type: types.SIZE,
    semanticType: types.DIMENSION,
    description: '',
  },
  '--ds-size-dimension-s': {
    type: types.SIZE,
    semanticType: types.DIMENSION,
    description: '',
  },
  '--ds-size-dimension-m': {
    type: types.SIZE,
    semanticType: types.DIMENSION,
    description: '',
  },
  '--ds-size-dimension-l': {
    type: types.SIZE,
    semanticType: types.DIMENSION,
    description: '',
  },
  '--ds-size-dimension-xl': {
    type: types.SIZE,
    semanticType: types.DIMENSION,
    description: '',
  },
  '--ds-size-dimension-xxl': {
    type: types.SIZE,
    semanticType: types.DIMENSION,
    description: '',
  },
  '--ds-size-dimension-xxxl': {
    type: types.SIZE,
    semanticType: types.DIMENSION,
    description: '',
  },
  '--ds-size-dimension-xxxxl': {
    type: types.SIZE,
    semanticType: types.DIMENSION,
    description: '',
  },

  // DEPRECATED SPACING
  '--ds-spacing-legacy-unit': {
    type: types.SPACING,
    description: '',
    deprecated: true,
  },
  '--ds-spacing': {
    type: types.SPACING,
    description: '',
    deprecated: true,
  },
  '--ds-spacing-01': {
    type: types.SPACING,
    description: '',
    deprecated: true,
  },
  '--ds-spacing-02': {
    type: types.SPACING,
    description: '',
    deprecated: true,
  },
  '--ds-spacing-03': {
    type: types.SPACING,
    description: '',
    deprecated: true,
  },
  '--ds-spacing-04': {
    type: types.SPACING,
    description: '',
    deprecated: true,
  },
  '--ds-spacing-05': {
    type: types.SPACING,
    description: '',
    deprecated: true,
  },
  '--ds-spacing-06': {
    type: types.SPACING,
    description: '',
    deprecated: true,
  },
  '--ds-spacing-07': {
    type: types.SPACING,
    description: '',
    deprecated: true,
  },
  '--ds-spacing-08': {
    type: types.SPACING,
    description: '',
    deprecated: true,
  },
  '--ds-spacing-09': {
    type: types.SPACING,
    description: '',
    deprecated: true,
  },
  '--ds-spacing-10': {
    type: types.SPACING,
    description: '',
    deprecated: true,
  },

  // SPACING
  '--ds-spacing-xxxs': {
    type: types.SPACING,
    description: '',
  },
  '--ds-spacing-xxs': {
    type: types.SPACING,
    description: '',
  },
  '--ds-spacing-xs': {
    type: types.SPACING,
    description: '',
  },
  '--ds-spacing-s': {
    type: types.SPACING,
    description: '',
  },
  '--ds-spacing-m': {
    type: types.SPACING,
    description: '',
  },
  '--ds-spacing-l': {
    type: types.SPACING,
    description: '',
  },
  '--ds-spacing-xl': {
    type: types.SPACING,
    description: '',
  },
  '--ds-spacing-xxl': {
    type: types.SPACING,
    description: '',
  },
  '--ds-spacing-xxxl': {
    type: types.SPACING,
    description: '',
  },

  // LAYOUT
  '--ds-layout': {
    type: types.LAYOUT,
    description: '',
    deprecated: true,
  },
  '--ds-layout-01': {
    type: types.LAYOUT,
    description: '',
    deprecated: true,
  },
  '--ds-layout-02': {
    type: types.LAYOUT,
    description: '',
    deprecated: true,
  },
  '--ds-layout-03': {
    type: types.LAYOUT,
    description: '',
    deprecated: true,
  },
  '--ds-layout-04': {
    type: types.LAYOUT,
    description: '',
    deprecated: true,
  },
  '--ds-layout-05': {
    type: types.LAYOUT,
    description: '',
    deprecated: true,
  },
  '--ds-layout-06': {
    type: types.LAYOUT,
    description: '',
    deprecated: true,
  },


  // SHADOW
  '--ds-shadow-300': {
    type: types.SHADOW,
    description: 'Used as the lowest elevation like a basic card on an app background',
  },
  '--ds-shadow-500': {
    type: types.SHADOW,
    description: 'Used as the medium level elevation for things like button overlays',
  },
  '--ds-shadow-700': {
    type: types.SHADOW,
    description: 'Used as the top level elevation for things like Modals, Drawers and Toasts',
  },

  // TRANSITION
  '--ds-transition-fast': {
    type: types.TRANSITION,
    description: '',
  },
  '--ds-transition-standard': {
    type: types.TRANSITION,
    description: '',
  },

  // RAINBOW
  '--ds-color-rainbow-orange': {
    type: types.COLOR,
    semanticType: types.RAINBOW,
    description: 'Rainbow border orange',
  },
  '--ds-color-rainbow-red': {
    type: types.COLOR,
    semanticType: types.RAINBOW,
    description: 'Rainbow border red',
  },
  '--ds-color-rainbow-pink': {
    type: types.COLOR,
    semanticType: types.RAINBOW,
    description: 'Rainbow border pink',
  },
  '--ds-color-rainbow-navy': {
    type: types.COLOR,
    semanticType: types.RAINBOW,
    description: 'Rainbow border navy',
  },
  '--ds-color-rainbow-turquoise': {
    type: types.COLOR,
    semanticType: types.RAINBOW,
    description: 'Rainbow border turquoise',
  },
  '--ds-color-rainbow-teal': {
    type: types.COLOR,
    semanticType: types.RAINBOW,
    description: 'Rainbow border teal',
  },

  // HOLIDAY RAINBOW
  '--ds-color-holiday-rainbow-red': {
    type: types.COLOR,
    semanticType: types.HOLIDAY_RAINBOW,
    description: 'Holiday rainbow border red',
  },
  '--ds-color-holiday-rainbow-orange': {
    type: types.COLOR,
    semanticType: types.HOLIDAY_RAINBOW,
    description: 'Holiday rainbow border orange',
  },
  '--ds-color-holiday-rainbow-turqoise': {
    type: types.COLOR,
    semanticType: types.HOLIDAY_RAINBOW,
    description: 'Holiday rainbow border turqoise',
  },
  '--ds-color-holiday-rainbow-green': {
    type: types.COLOR,
    semanticType: types.HOLIDAY_RAINBOW,
    description: 'Holiday rainbow border green',
  },
  '--ds-color-holiday-rainbow-blue': {
    type: types.COLOR,
    semanticType: types.HOLIDAY_RAINBOW,
    description: 'Holiday rainbow border blue',
  },
};
