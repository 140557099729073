import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import Box from '../Box';
import Text from '../Text';
import Button from '../Button';
import Tooltip from '../Tooltip';
import Modal, { ModalBody } from '../Modal';
import Form, { FieldSet, Field } from '../Form';
import * as IconExports from '.';
import * as icons from '../Icons';
import * as constants from '../constants';
import Code from '../../src/components/Code';
import styles from './Icon.scss';

// eslint-disable-next-line react/prop-types
const IconPreview = ({ children, label, size }) => {
  function downloadSvg(e) {
    const parent = e.target.closest('#download');
    const svg = parent.querySelector('svg');
    const src = `data:image/svg+xml;utf8,${svg.outerHTML}`;
    const link = document.createElement('a');
    link.setAttribute('href', src);
    link.setAttribute('download', label);
    link.setAttribute('target', '_blank');
    link.style.display = 'none';
    link.click();
  }

  const svg = React.cloneElement(children, { size });

  return (
    <Modal
      accessibleTitle={`Icon details for: ${label}`}
      size="medium"
      trigger={({ onClick }) => (
        <Tooltip
          placement={constants.TOP}
          trigger={tooltipEvents => (
            <Button
              size={size === 'xlarge' ? constants.LARGE : constants.MEDIUM}
              tabIndex="0"
              onClick={onClick}
              {...tooltipEvents}
            >
              { children }
            </Button>
          )}
          accessibleTitle={label}
        >
          { label }
        </Tooltip>
      )}
    >
      <ModalBody>
        <Box display="flex" flexWrap="wrap" alignItems="center">
          <Box style={{ minWidth: '300px' }}>
            <Text display="block" type="display2">{ label }</Text>
          </Box>
          <Box id="download" flex="flex" flexWrap="wrap" style={{ marginLeft: 'auto', textAlign: 'right' }}>
            <span style={{ display: 'none' }}>{ svg }</span>
            <Button role="button" intent={constants.PRIMARY} size={constants.SMALL} onClick={e => downloadSvg(e)}>
              Download SVG
            </Button>
          </Box>
        </Box>
        <Box marginBottom="m" />
        <Box padding="xl" className={styles.iconPreview}>
          { svg }
        </Box>
        <Box marginBottom="xl" />
        <Text type="display3" display="block">Usage</Text>
        <Box marginBottom="m" />
        <Code disableEditLink roundedCorners>
          {`
  import ${label} from '@redbubble/design-system/react/Icons/${label.replace('Icon', '')}';

  const YourComponent = () => (
    <div>
      <${label} size="${size}" />
    </div>
  );
          `}
        </Code>
      </ModalBody>
    </Modal>
  );
};
// eslint-disable-next-line
import IconSrc from '!raw-loader!.';

const { default: Icon } = IconExports;
const docs = generateDocs(IconSrc, resolver.findAllExportedComponentDefinitions);

const basicExample = {
  name: 'Simple Example',
  description: 'Click an icon for implementation details and downloads. Alternatively you can <a href="https://github.com/redbubble/design/tree/master/react/Icon/svgs" target="_blank" ref="noopener noreferrer">download all icons</a> from GitHub.',
  overflowVisual: true,
  render: () => {
    const sortedIcons = Object.values(icons).reduce((memo, icon) => {
      if (icon.displayName.includes('Social')) {
        memo.social.push(icon);
      } else {
        memo.system.push(icon);
      }

      return memo;
    }, { system: [], social: [] });

    return (
      <Box display="flex" flexDirection="column">
        <Form initialValues={{ size: 'medium' }}>
          {
            ({ values }) => (
              <React.Fragment>
                <FieldSet>
                  <Field
                    type="select"
                    options={[
                      { value: 'small', label: 'Small' },
                      { value: 'medium', label: 'Medium' },
                      { value: 'large', label: 'Large' },
                      { value: 'xlarge', label: 'Extra Large' },
                    ]}
                    name="size"
                    label="Icon size"
                  />
                </FieldSet>

                <Text type="display5" display="block" marginBottom="xs">System Icons</Text>
                <Box display="flex" flexWrap="wrap" marginBottom="xl">
                  {
                    sortedIcons.system.map(EachIcon => (
                      <Box className={styles.iconWrapper} key={EachIcon.displayName} margin="xxs" display="flex" alignItems="center" justifyContent="center">
                        <IconPreview label={EachIcon.displayName} size={values.size}>
                          <EachIcon size={values.size} />
                        </IconPreview>
                      </Box>
                    ))
                  }
                </Box>

                <Text type="display5" display="block" marginBottom="xs">Social Icons</Text>
                <Box display="flex" flexWrap="wrap">
                  {
                    sortedIcons.social.map(EachIcon => (
                      <Box className={styles.iconWrapper} key={EachIcon.displayName} margin="xxs" display="flex" alignItems="center" justifyContent="center">
                        <IconPreview label={EachIcon.displayName} size={values.size}>
                          <EachIcon size={values.size} />
                        </IconPreview>
                      </Box>
                    ))
                  }
                </Box>
              </React.Fragment>
            )
          }
        </Form>
      </Box>
    );
  },
  code: `
{ /*
  import SearchIcon from '@redbubble/design-system/react/Icons/Search';
  import * as constants from '@redbubble/design-system/react/constants';
*/ }
<SearchIcon size={constants.SMALL} />
  `,
};

// Don't document dangerous internal prop
if (docs[0] && docs[0].props) {
  delete docs[0].props.dangerouslySetIcon;
}

const Demo = {
  title: Icon.displayName,
  description: 'Semantic symbols to aid communication.',
  customImportPath: '@redbubble/design-system/react/Icons/Xxx',
  customImportName: 'Xxx',
  docGen: docs,
  slug: 'Icon',
  exports: Object.keys(IconExports),
  examples: [
    basicExample,
  ],
  notes: `
  `,
  relatedContent: [
    {
      name: 'Iconography guidelines',
      link: '/guidelines/iconography',
    },
    {
      name: 'Adding new icons',
      link: '/guidelines/icons',
    },
  ],
};

export default Demo;
