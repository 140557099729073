import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import Box from '../Box';
import Text from '../Text';
import Card, { CardBody } from '../Card';
import Form, { FieldSet, FieldRow, Field } from '../Form';

import * as AlertExports from '.';

// eslint-disable-next-line
import AlertSrc from '!raw-loader!.';

const { default: Alert } = AlertExports;
const docs = generateDocs(AlertSrc, resolver.findAllExportedComponentDefinitions);

// eslint-disable-next-line react/prop-types
const ConfigForm = ({ children, intent }) => (
  <Form initialValues={{ intent: 'information' }} profile="DESKTOP">
    {
      ({ values }) => (
        <React.Fragment>
          <FieldSet>
            <FieldRow>
              {intent && (
                <Field
                  type="select"
                  options={[
                    { value: 'success', label: 'Success' },
                    { value: 'information', label: 'Information' },
                    { value: 'warning', label: 'Warning' },
                    { value: 'error', label: 'Error' },
                  ]}
                  name="intent"
                  label="Intent"
                />
              )}
            </FieldRow>
          </FieldSet>
          { children(values) }
        </React.Fragment>
      )
    }
  </Form>
);

const alertBasic = {
  name: 'Simple usage',
  description: 'Alerts can be rendered with different intents.',
  muted: true,
  render: () => (
    <ConfigForm intent>
      {
        values => (
          <Box marginBottom="m">
            <Card>
              <CardBody>
                <Box marginBottom="m" marginTop="m">
                  <Alert
                    intent={values.intent}
                    title="A title describing the alert"
                  >
                    { /* eslint-disable max-len */ }
                    <Text display="block">More information about why the alert was shown can appear here.</Text>
                  </Alert>
                </Box>
              </CardBody>
            </Card>
          </Box>
        )
      }
    </ConfigForm>
  ),
  code: `
<Alert intent="information" title="A title describing the alert">
  <Text display="block">More information about why the alert was shown can appear here.</Text>
</Alert>
  `,
};

const alertLevels = {
  name: 'Subtle',
  description: 'An alert can be made less prominent by telling it to be subtle.',
  muted: true,
  render: () => (
    <ConfigForm subtle intent>
      {
        values => (
          <Box marginBottom="m">
            <Card>
              <CardBody>
                <Box marginBottom="m" marginTop="m">
                  <Alert
                    intent={values.intent}
                    subtle
                    title="A title describing the alert"
                  >
                    { /* eslint-disable max-len */ }
                    <Text display="block">More information about why the alert was shown can appear here.</Text>
                  </Alert>
                </Box>
              </CardBody>
            </Card>
          </Box>
        )
      }
    </ConfigForm>
  ),
  code: `
<Alert intent="information" title="A title describing the alert" subtle>
  <Text display="block">More information about why the alert was shown can appear here.</Text>
</Alert>
  `,
};

const alertBanner = {
  name: 'Banner',
  description: 'An alert could appear full-width.',
  muted: true,
  render: () => (
    <Box padding="m">
      <ConfigForm intent>
        {
          values => (
            <Box marginBottom="m">
              <Card>
                <Box marginBottom="m" marginTop="m">
                  <Alert
                    intent={values.intent}
                    title="A title describing the alert"
                    banner
                  />
                </Box>
              </Card>
            </Box>
          )
        }
      </ConfigForm>
    </Box>
  ),
  code: `
<Alert intent="information" title="A title describing the alert" />
  `,
};

const alertCentered = {
  name: 'Centered',
  description: 'An alert can have it‘s content centered.',
  muted: true,
  render: () => (
    <Box padding="m">
      <ConfigForm intent>
        {
          values => (
            <Box marginBottom="m">
              <Card>
                <Box padding="m">
                  <Alert
                    centered
                    intent={values.intent}
                    title="A title describing the alert"
                    label={false}
                  >
                    Content displayed inside the alert goes here
                  </Alert>
                </Box>
              </Card>
            </Box>
          )
        }
      </ConfigForm>
    </Box>
  ),
  code: (_, values) => `
<Alert
  centered
  intent="${values.intent}"
  title="A title describing the alert"
  label={false}
>
  Content displayed inside the alert goes here
</Alert>
  `,
};

const alertDismissable = {
  name: 'Dismissable',
  description: 'You can make the alert dismissable.',
  muted: true,
  render: () => (
    <Box padding="m">
      <ConfigForm intent>
        {
          values => (
            <Box marginBottom="m">
              <Card>
                <CardBody>
                  <Alert
                    intent={values.intent}
                    title="A title describing the alert"
                    dismissable
                  />
                </CardBody>
              </Card>
            </Box>
          )
        }
      </ConfigForm>
    </Box>
  ),
  code: `
<Alert intent="information" title="A title describing the alert" dismissable />
  `,
};

const alertCustomizeLabel = {
  name: 'Customized label',
  description: 'You can choose to disable or change the text in the label. Use this with caution, the label improves accessibility for people with color blindness.',
  muted: true,
  render: () => (
    <Box padding="m">
      <Card>
        <CardBody>
          <Alert
            title="A title describing the alert"
            label={false}
          />
          <Box marginBottom="m" />
          <Alert
            label="Promotion"
          >
            <Text display="block">Buy any 4 and get 25% off</Text>
            <Text display="block">Buy any 10 and get 50% off</Text>
          </Alert>
        </CardBody>
      </Card>
    </Box>
  ),
  code: `
<Alert title="A title describing the alert" label={false} />

<Alert title="A title describing the alert" label="Promotion">
  <Text display="block">Buy any 4 and get 25% off</Text>
  <Text display="block">Buy any 10 and get 50% off</Text>
</Alert>
  `,
};

const Demo = {
  title: Alert.displayName,
  description: 'Alerts are messages that effectively communicate specific information to users.',
  docGen: docs,
  slug: 'Alert',
  exports: Object.keys(AlertExports),
  examples: [
    alertBasic,
    alertLevels,
    alertBanner,
    alertCentered,
    alertDismissable,
    alertCustomizeLabel,
  ],
  notes: `
  `,
};

export default Demo;
