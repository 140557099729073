import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTopOnMount = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (window.scrollTo) window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTopOnMount;
