import React from 'react';
import PropTypes from 'prop-types';
import { parse as generateDocs, resolver } from 'react-docgen';
import State from '@reach/component-component';
import * as constants from '../constants';
import Box from '../Box';
import MediaCard from '../MediaCard';
import StarIcon from '../Icons/Star';
import CloseIcon from '../Icons/Close';
import Text from '../Text';
import Card, { CardBody } from '../Card';
import Avatar from '../Avatar';
import RulerIcon from '../Icons/Ruler';
import SearchIcon from '../Icons/Search';
import ChevronRight from '../Icons/ChevronRightSmall';

import * as ButtonExports from '.';

// eslint-disable-next-line
import ButtonSrc from '!raw-loader!./';
import imageSrc from '../Avatar/assets/rb-avatar-bird.svg';

// eslint-disable-next-line
const ButtonColumn = ({ children, center }) => (
  <div
    style={{
      display: 'inline-flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: center ? 'center' : 'flex-start',
      marginBottom: '16px',
    }}
  >
    {React.Children.map(children, b => (
      <div style={{ marginRight: '16px', marginBottom: '16px' }}>{b}</div>
    ))}
  </div>
);

const { default: Button } = ButtonExports;

const docs = generateDocs(
  ButtonSrc,
  resolver.findAllExportedComponentDefinitions,
);

ButtonColumn.propTypes = {
  children: PropTypes.node.isRequired,
};

const buttonTypes = {
  name: 'Intents',
  description: `
    Use different intents for different actions, to indicate to the user what effect the Button may have.
  `,
  render: () => (
    <React.Fragment>
      <ButtonColumn>
        <Button tracking="Batman">Default</Button>
      </ButtonColumn>
      <ButtonColumn>
        <Button intent={constants.PRIMARY}>Primary</Button>
      </ButtonColumn>
      <ButtonColumn>
        <Button intent={constants.EXPRESSIVE}>Expressive</Button>
      </ButtonColumn>
      <ButtonColumn>
        <Button intent={constants.DESTRUCTIVE}>Destructive</Button>
      </ButtonColumn>
    </React.Fragment>
  ),
  code: `
<Button>Default</Button>
<Button intent={constants.PRIMARY}>Primary</Button>
<Button intent={constants.EXPRESSIVE}>Expressive</Button>
<Button intent={constants.DESTRUCTIVE}>Destructive</Button>
  `,
};

const buttonOverlay = {
  name: 'Elevation',
  muted: true,
  description: `
    An elevated button is used when it sits on top top of other content.
  `,
  render: () => (
    <React.Fragment>
      <Box display="flex">
        <Box style={{ maxWidth: 200 }} marginRight="xl">
          <MediaCard
            href="#"
            primaryActionSlot={(
              <Button
                elevation={constants.ELEVATION_MEDIUM}
                circle
                size={constants.SMALL}
                iconAfter={<StarIcon size={constants.SMALL} />}
              />
)}
            imageSrc="https://bit.ly/2Uk9Jpg"
            title="Title"
            caption="Caption"
          />
        </Box>
        <Box style={{ maxWidth: 200 }}>
          <MediaCard
            href="#"
            imageSrc="https://bit.ly/2OEC442"
            primaryActionSlot={(
              <Button
                elevation={constants.ELEVATION_MEDIUM}
                strong
                circle
                intent={constants.DESTRUCTIVE}
                iconAfter={<CloseIcon />}
                size={constants.SMALL}
              />
)}
            title="Title"
            caption="And so will a really long caption"
          />
        </Box>
      </Box>
    </React.Fragment>
  ),
  code: `
<Button>Default</Button>
<Button intent={constants.PRIMARY}>Primary</Button>
<Button intent={constants.DESTRUCTIVE}>Destructive</Button>
  `,
};

const buttonStrong = {
  name: 'Strong',
  description: `
    You can make your button stronger in appearance.
  `,
  render: () => (
    <React.Fragment>
      <ButtonColumn>
        <Button strong>Default</Button>
      </ButtonColumn>
      <ButtonColumn>
        <Button intent={constants.PRIMARY} strong>
          Primary
        </Button>
      </ButtonColumn>
      <ButtonColumn>
        <Button intent={constants.EXPRESSIVE} strong>
          Expressive
        </Button>
      </ButtonColumn>
      <ButtonColumn>
        <Button intent={constants.DESTRUCTIVE} strong>
          Destructive
        </Button>
      </ButtonColumn>
    </React.Fragment>
  ),
  code: `
<Button strong>Default</Button>
<Button intent={constants.PRIMARY} strong>Primary</Button>
<Button intent={constants.EXPRESSIVE} strong>Expressive</Button>
<Button intent={constants.DESTRUCTIVE} strong>Destructive</Button>
  `,
};

const buttonBorder = {
  name: 'Border',
  description: `
    Give your button a border to make it more obvious than the default button, but not quite an intense as the strong button.
  `,
  render: () => (
    <React.Fragment>
      <ButtonColumn>
        <Button border>Default</Button>
      </ButtonColumn>
      <ButtonColumn>
        <Button intent={constants.PRIMARY} border>
          Primary
        </Button>
      </ButtonColumn>
      <ButtonColumn>
        <Button intent={constants.EXPRESSIVE} border>
          Expressive
        </Button>
      </ButtonColumn>
      <ButtonColumn>
        <Button intent={constants.DESTRUCTIVE} border>
          Destructive
        </Button>
      </ButtonColumn>
    </React.Fragment>
  ),
  code: `
<Button border>Default</Button>
<Button intent={constants.PRIMARY} border>Primary</Button>
<Button intent={constants.EXPRESSIVE} border>Expressive</Button>
<Button intent={constants.DESTRUCTIVE} border>Destructive</Button>
  `,
};

const buttonSizes = {
  name: 'Size',
  description: `
    Each button comes in 3 different sizes.
  `,
  render: () => (
    <React.Fragment>
      <ButtonColumn center>
        <Button size={constants.SMALL}>Small</Button>
        <Button size={constants.MEDIUM}>Medium</Button>
        <Button size={constants.LARGE}>Large</Button>
      </ButtonColumn>
      <ButtonColumn center>
        <Button size={constants.SMALL} strong>
          Small
        </Button>
        <Button size={constants.MEDIUM} strong>
          Medium
        </Button>
        <Button size={constants.LARGE} strong>
          Large
        </Button>
      </ButtonColumn>
      <ButtonColumn center>
        <Button intent={constants.PRIMARY} size={constants.SMALL} strong>
          Small
        </Button>
        <Button intent={constants.PRIMARY} size={constants.MEDIUM} strong>
          Medium
        </Button>
        <Button intent={constants.PRIMARY} size={constants.LARGE} strong>
          Large
        </Button>
      </ButtonColumn>
    </React.Fragment>
  ),
  code: `
<Button size={constants.SMALL}>Small</Button>
<Button size={constants.MEDIUM}>Medium</Button>
<Button size={constants.LARGE}>Large</Button>

<Button size={constants.SMALL} strong>Small</Button>
<Button size={constants.MEDIUM} strong>Medium</Button>
<Button size={constants.LARGE} strong>Large</Button>

<Button intent={constants.PRIMARY} size={constants.SMALL} strong>Small</Button>
<Button intent={constants.PRIMARY} size={constants.MEDIUM} strong>Medium</Button>
<Button intent={constants.PRIMARY} size={constants.LARGE} strong>Large</Button>
  `,
};

const buttonFluid = {
  name: 'Fluid',
  description: `
    You can make your button take up the full width of it's parent container.
  `,
  muted: true,
  render: () => (
    <Box display="flex" style={{ maxWidth: 300 }}>
      <Card>
        <CardBody>
          <Text type="display3" display="block">
            Keep up to date
          </Text>
          <Box marginBottom="xl" marginTop="xxs">
            <Text display="block">
              Get notified of product launches, promotions and tips on how to
              connect with the community.
            </Text>
          </Box>
          <Box marginBottom="m" marginTop="xxs">
            <Button intent={constants.PRIMARY} fluid strong>
              Enable notifications
            </Button>
          </Box>
        </CardBody>
      </Card>
    </Box>
  ),
  code: `
<Box display="flex" style={{ maxWidth: 300 }}>
  <Card>
    <CardBody>
      <Text type="display3" display="block">Keep up to date</Text>
      <Box marginBottom="xl" marginLeft="xxs">
        <Text display="block">
          Get notified of product launches, promotions and tips on how to connect with the community.
        </Text>
      </Box>
      <Box marginBottom="m" marginLeft="xxs">
        <Button intent={constants.PRIMARY} fluid strong>
          Enable notifications
        </Button>
      </Box>
    </CardBody>
  </Card>
</Box>
  `,
};

const buttonIcon = {
  name: 'Icons',
  description: `
    You can use <strong>Icons</strong> in buttons to better communicate their purpose
  `,
  muted: true,
  render: () => (
    <Box style={{ maxWidth: 320 }}>
      <Card>
        <CardBody>
          <Box display="flex" marginBottom="xs">
            <Box marginRight="m">
              <Avatar />
            </Box>
            <Box>
              <Text type="display5" display="block">
                Two-can
              </Text>
              <Text type="caption" muted display="block">
                Melbourne, Australia
              </Text>
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end" marginTop="m">
            <Button iconAfter={<ChevronRight />} strong href="#">
              Browse works
            </Button>
          </Box>
        </CardBody>
      </Card>
    </Box>
  ),
  code: `
<Box style={{ maxWidth: 320 }}>
  <Card>
    <CardBody>
      <Box display="flex" marginBottom="xs">
        <Box marginRight="m">
          <Avatar src="${imageSrc}" />
        </Box>
        <Box>
          <Text type="display5" display="block">Two-can</Text>
          <Text type="caption" muted display="block">
            Melbourne, Australia
          </Text>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" marginLeft="m">
        <Button iconAfter={<ChevronRightIcon />} strong href="#">
          Browse works
        </Button>
      </Box>
    </CardBody>
  </Card>
</Box>
  `,
};

const buttonCircle = {
  name: 'Circle',
  description: `
    You can use circular buttons.
  `,
  render: () => (
    <React.Fragment>
      <Box display="flex">
        {['XS', 'S', 'M', 'L'].map(s => (
          <Box key={s} marginRight="xs">
            <Button circle strong>
              {s}
            </Button>
          </Box>
        ))}
        <Button iconBefore={<RulerIcon />} circle strong />
      </Box>
    </React.Fragment>
  ),
  code: `
<Box display="flex">
  {
    ['XS', 'S', 'M', 'L'].map(s => (
      <Box marginRight="xs">
        <Button circle strong>
          { s }
        </Button>
      </Box>
    ))
  }
  <Button iconBefore={<RulerIcon />} circle strong />
</Box>
  `,
};

const buttonDisabled = {
  name: 'Disabled',
  description: `
    Each button has the same appearance when it is disabled.
  `,
  render: () => (
    <React.Fragment>
      <ButtonColumn>
        <Button disabled>Default</Button>
      </ButtonColumn>
      <ButtonColumn>
        <Button disabled strong>
          Default
        </Button>
      </ButtonColumn>
      <ButtonColumn>
        <Button intent={constants.PRIMARY} disabled>
          Primary
        </Button>
      </ButtonColumn>
    </React.Fragment>
  ),
  code: `
<Button disabled>Default</Button>
<Button disabled strong>Default</Button>
<Button intent={constants.PRIMARY} disabled>Primary</Button>
  `,
};

const buttonLoading = {
  name: 'Loading',
  description: `
    Our buttons have loading states.
  `,
  render: () => (
    <State initialState={{ loading: false }}>
      {({ setState, state }) => (
        <Box display="flex">
          <Button
            loading={state.loading}
            onClick={() => setState({ loading: true })}
          >
            Click to load
          </Button>
          <Box padding="xxs" />
          <Button
            intent={constants.PRIMARY}
            loading={state.loading}
            strong
            onClick={() => setState({ loading: true })}
          >
            Click to load
          </Button>
          <Box padding="xxs" />
          <Button
            iconBefore={<SearchIcon />}
            loading={state.loading}
            onClick={() => setState({ loading: true })}
            circle
          />
        </Box>
      )}
    </State>
  ),
  code: `
<State initialState={{ loading: false }}>
  {
    ({ setState, state }) => (
      <Box display="flex">
        <Button
          loading={state.loading}
          onClick={() => setState({ loading: true })}
        >
            Click to load
        </Button>
        <Box padding="xxs" />
        <Button
          intent={constants.PRIMARY}
          loading={state.loading}
          strong
          onClick={() => setState({ loading: true })}
        >
            Click to load
        </Button>
        <Box padding="xxs" />
        <Button
          iconBefore={<SearchIcon />}
          loading={state.loading}
          onClick={() => setState({ loading: true })}
          circle
        />
      </Box>
    )
  }
</State>
  `,
};

const buttonLink = {
  name: 'Buttons as links',
  description: `
    If you provide an <code>href</code> to a Button, it will render a semantic anchor tag.

    <br />
    <br />

    <strong>Proceed with caution:</strong> This may affect accessibility of our experiences
    as some screen readers will call out all semantic buttons on a page and would miss a
    button with this prop.
  `,
  render: () => (
    <React.Fragment>
      <ButtonColumn>
        <Button href="https://redbubble.design" title="Redbubble.design">
          View page
        </Button>
      </ButtonColumn>
      <ButtonColumn>
        <Button
          intent={constants.PRIMARY}
          href="https://redbubble.design"
          target="_blank"
          title="Redbubble.design"
        >
          Visit us
        </Button>
      </ButtonColumn>
    </React.Fragment>
  ),
  code: `
<Button
  href="https://redbubble.design"
  title="Redbubble.design"
>
  View page
</Button>

<Button
  intent={constants.PRIMARY}
  href="https://redbubble.design"
  target="_blank"
  title="Redbubble.design"
>
  Visit us
</Button>
`,
};

const buttonInverse = {
  name: 'Inverse',
  dark: true,
  description: `
    Use the inverse prop for buttons that are on a dark background.
  `,
  render: () => (
    <React.Fragment>
      <ButtonColumn>
        <Button inverse>Default</Button>
        <Button inverse strong>
          Default
        </Button>
      </ButtonColumn>
      <ButtonColumn>
        <Button intent={constants.PRIMARY} inverse>
          Primary
        </Button>
        <Button intent={constants.PRIMARY} inverse strong>
          Primary
        </Button>
      </ButtonColumn>
      <ButtonColumn>
        <Button intent={constants.EXPRESSIVE} inverse>
          Expressive
        </Button>
        <Button intent={constants.EXPRESSIVE} inverse strong>
          Expressive
        </Button>
      </ButtonColumn>
      <ButtonColumn>
        <Button intent={constants.DESTRUCTIVE} inverse>
          Destructive
        </Button>
        <Button intent={constants.DESTRUCTIVE} inverse strong>
          Destructive
        </Button>
      </ButtonColumn>
    </React.Fragment>
  ),
  code: `
<Button inverse>
  Default
</Button>
<Button inverse strong>
  Default
</Button>

<Button intent={constants.PRIMARY} inverse>
  Primary
</Button>
<Button intent={constants.PRIMARY} inverse strong>
  Primary
</Button>

<Button intent={constants.EXPRESSIVE} inverse>
  Expressive
</Button>
<Button intent={constants.EXPRESSIVE} inverse strong>
  Expressive
</Button>

<Button intent={constants.DESTRUCTIVE} inverse>
  Destructive
</Button>
<Button intent={constants.DESTRUCTIVE} inverse strong>
  Destructive
</Button>
  `,
};

// This removes and filters out any props related to the ButtonToggle component
try {
  delete docs[0].props.active;
  docs[0].props.intent.type.value = docs[0].props.intent.type.value.filter(
    item => item.value !== 'constants.TOGGLE',
  );
} catch (e) {
  // eslint-disable-next-line no-console
  console.log('Failed to update Button docs');
}

const Demo = {
  title: Button.displayName,
  description: `
    Buttons are used when changing the state of the current experience, submitting a form or as primary call to actions.
  `,
  docGen: docs,
  slug: 'button',
  exports: Object.keys(ButtonExports),
  examples: [
    buttonTypes,
    buttonOverlay,
    buttonStrong,
    buttonBorder,
    buttonSizes,
    buttonFluid,
    buttonIcon,
    buttonCircle,
    buttonLoading,
    buttonDisabled,
    buttonLink,
    buttonInverse,
  ],
};

export default Demo;
