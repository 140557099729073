import React from 'react';
import State from '@reach/component-component';
import { parse as generateDocs, resolver } from 'react-docgen';
import * as ToolbarExports from '.';
import AlignCenterMiddleIcon from '../Icons/AlignCenterMiddle';
import AlignCenterIcon from '../Icons/AlignCenter';
import AlignRightIcon from '../Icons/AlignRight';
import AlignLeftIcon from '../Icons/AlignLeft';
import RotateRightIcon from '../Icons/RotateRight';
import RotateLeftIcon from '../Icons/RotateLeft';
import ResizeIcon from '../Icons/Resize';

// eslint-disable-next-line
import ToolbarSrc from '!raw-loader!./';

const {
  default: Toolbar,
  ToolbarButtonGroup,
  ToolbarRadioButtonGroup,
  ToolbarButton,
  ToolbarDivider,
} = ToolbarExports;
const docs = generateDocs(
  ToolbarSrc,
  resolver.findAllExportedComponentDefinitions,
);

const defaultExample = {
  name: 'Simple example',
  description: 'You can compose actions inside the `Toolbar` using buttons, dividers and groups.',
  render: () => (
    <Toolbar>
      <ToolbarButtonGroup>
        <ToolbarButton iconBefore={<AlignCenterMiddleIcon />} />
        <ToolbarButton iconBefore={<AlignCenterIcon />} />
        <ToolbarButton iconBefore={<AlignLeftIcon />} />
        <ToolbarButton iconBefore={<AlignRightIcon />} />
      </ToolbarButtonGroup>
      <ToolbarDivider />
      <ToolbarButton iconBefore={<RotateLeftIcon />} />
      <ToolbarButton iconBefore={<RotateRightIcon />} />
      <ToolbarButton> Reset </ToolbarButton>
      <ToolbarDivider />
      <ToolbarButton iconBefore={<ResizeIcon />} />
    </Toolbar>
  ),
  code: `
<Toolbar>
  <ToolbarButtonGroup>
    <ToolbarButton iconBefore={<AlignCenterMiddleIcon />} />
    <ToolbarButton iconBefore={<AlignCenterIcon />} />
    <ToolbarButton iconBefore={<AlignLeftIcon />} />
    <ToolbarButton iconBefore={<AlignRightIcon />} />
  </ToolbarButtonGroup>
  <ToolbarDivider />
  <ToolbarButton iconBefore={<RotateLeftIcon />} />
  <ToolbarButton iconBefore={<RotateRightIcon />} />
  <ToolbarButton> Reset </ToolbarButton>

  <ToolbarDivider />
  <ToolbarButton iconBefore={<ResizeIcon />} />
</Toolbar>
  `,
};

const statefulExample = {
  name: 'Stateful Toolbars',
  description: '`ToolbarButton`s can be toggled on / off or grouped in a `ToolbarRadioButtonGroup` to provide stateful `Toolbar` interactions.',
  render: () => (
    <State initialState={{ align: 'center-middle', toggle: true, radio: '1' }}>
      {
        ({ state, setState }) => (
          <Toolbar>
            <ToolbarButtonGroup>
              <ToolbarButton
                active={state.align === 'center-middle'}
                onClick={() => setState({ align: 'center-middle' })}
                iconBefore={<AlignCenterMiddleIcon />}
              />
              <ToolbarButton
                active={state.align === 'center'}
                onClick={() => setState({ align: 'center' })}
                iconBefore={<AlignCenterIcon />}
              />
              <ToolbarButton
                active={state.align === 'left'}
                onClick={() => setState({ align: 'left' })}
                iconBefore={<AlignLeftIcon />}
              />
              <ToolbarButton
                active={state.align === 'right'}
                onClick={() => setState({ align: 'right' })}
                iconBefore={<AlignRightIcon />}
              />
            </ToolbarButtonGroup>
            <ToolbarDivider />
            <ToolbarButton
              active={state.toggle}
              onClick={() => setState({ toggle: !state.toggle })}
            >
              { state.toggle ? 'On' : 'Off' }
            </ToolbarButton>
            <ToolbarDivider />
            <ToolbarRadioButtonGroup
              value={state.radio}
              onChange={v => setState({ radio: v })}
            >
              <ToolbarButton value="1">1</ToolbarButton>
              <ToolbarButton value="2">2</ToolbarButton>
              <ToolbarButton value="3">3</ToolbarButton>
            </ToolbarRadioButtonGroup>
          </Toolbar>
        )
      }
    </State>
  ),
  code: `
  <State initialState={{ align: 'center-middle', toggle: true, radio: '1' }}>
    {
      ({ state, setState }) => (
        <Toolbar>
          <ToolbarButtonGroup>
            <ToolbarButton
              active={state.align === 'center-middle'}
              onClick={() => setState({ align: 'center-middle' })}
              iconBefore={<AlignCenterMiddleIcon />}
            />
            <ToolbarButton
              active={state.align === 'center'}
              onClick={() => setState({ align: 'center' })}
              iconBefore={<AlignCenterIcon />}
            />
            <ToolbarButton
              active={state.align === 'left'}
              onClick={() => setState({ align: 'left' })}
              iconBefore={<AlignLeftIcon />}
            />
            <ToolbarButton
              active={state.align === 'right'}
              onClick={() => setState({ align: 'right' })}
              iconBefore={<AlignRightIcon />}
            />
          </ToolbarButtonGroup>
          <ToolbarDivider />
          <ToolbarButton
            active={state.toggle}
            onClick={() => setState({ toggle: !state.toggle })}
          >
            { state.toggle ? 'On' : 'Off' }
          </ToolbarButton>
          <ToolbarDivider />
          <ToolbarRadioButtonGroup value={state.radio} onChange={(v) => { setState({ radio: v }) }}>
            <ToolbarButton value="1">1</ToolbarButton>
            <ToolbarButton value="2">2</ToolbarButton>
            <ToolbarButton value="3">3</ToolbarButton>
          </ToolbarRadioButtonGroup>
        </Toolbar>
      )
    }
  </State>
  `,
};

const Demo = {
  title: Toolbar.displayName,
  slug: 'Toolbar',
  exports: Object.keys(ToolbarExports),
  docGen: docs,
  examples: [defaultExample, statefulExample],
  notes: `
  ### Toolbar Button

  Buttons accept a subset of props that are available in the regular \`Button\` component.
  Some props are not available since the toolbar buttons are consistent in size, shape and possible actions.

  ### Accessibility

  Consider the following when making the Toolbar more accessible to users on assistive technologies:

  #### Set an \`aria-label\` prop
  This value would label the element which allows screen readers to call out the element.

  #### Set an \`aria-controls\` prop
  This would indentify the element whose contents are controlled by the current element (see Usage for more information)

  #### Ensure the content that the Toolbar is controlling comes right after in the DOM.
  This ensures proper heiarchy when used on screen readers.

  `,
};

export default Demo;
