import hexToRgb from './hexToRgb';

export const tokensToCSS = (tokens) => {
  return Object.entries(tokens || {}).reduce((css, [property, attrs]) => {
    const value = typeof attrs === 'string' ? attrs : attrs.value;
    return `${css}\n${property}: ${value};`;
  }, '');
};

export const deVar = v => v.replace('var(', '').replace(/\)$/, '').trim();

export const resolveTokenValue = (name) => {
  const result = getComputedStyle(document.documentElement).getPropertyValue(name).trim();

  return result;
};

// Determine hex and rgb values for a given color value
export const getColorDetails = value => ({
  hex: value.includes('#') ? value : '',
  rgb: ((v) => {
    if (v.includes('#')) {
      const { r, g, b } = hexToRgb(v);
      return `rgb(${r}, ${g}, ${b})`;
    }

    if (v.includes('rgb')) return v;

    return '';
  })(value),
});


export default { tokensToCSS };
