import React from 'react';
import cnames from 'classnames';
import { defineMessages } from 'react-intl';
import Box from '../../../../../Box';
import * as constants from '../../../../../constants';
import Input from '../../../../../Form/Input';
import Button from '../../../../../Button';
import ButtonToggle from '../../../../../ButtonToggle';
import CrossDiscIcon from '../../../../../Icons/CrossDisc';
import Menu from '../Menu';
import Discovery from './Discovery';
import styles from './styles.scss';
import useTypeahead from '../../hooks/useTypeahead';

import { analyticsPayload } from '../../../../lib/analytics';

const messages = defineMessages({
  placeholder: 'Search {context}',
  defaultPlaceholder: 'Search Redbubble',
  cancel: 'Cancel',
});

const TypeaheadMobile = ({
  onSearchClose,
  logEvent: defaultLogEvent,
  doSearch: providedDoSearch,
  intl,
  term = '',
  locale = 'en',
  productContextIaCode: defaultProductContextIaCode,
  productContextLabel: defaultProductContextLabel,
  baseUrl,
  disableEventLogging,
  onTypeaheadResultClick,
  onSearchCallback,
}) => {
  const logEvent = disableEventLogging ? () => {} : defaultLogEvent;
  const {
    isOpen,
    getMenuProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    showProductContext,
    productContextLabel,
    inputProps,
    types,
    searchWords,
    searchTerm,
    productContextIaCode,
    logView,
    discoveryMode,
    logItemClicked,
    setProductContext,
    currentInputValue,
    prevTerm,
    doSearch,
    popularProducts,
    quickLinks,
    otherLinks,
    topArtists,
    topArtistsLoading,
  } = useTypeahead({
    term,
    defaultProductContextIaCode,
    defaultProductContextLabel,
    providedDoSearch,
    locale,
    intl,
    logEvent,
    onSearchClose,
    profile: constants.MOBILE,
    baseUrl,
    onSearchCallback,
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={cnames(styles.typeaheadModal, {
        [styles.withMenuOpen]: isOpen,
      })}
    >
      <Box
        display="flex"
        flexDirection="column"
        padding="m"
        className={styles.combobox}
        {...getComboboxProps({ refKey: 'innerRef' })}
        marginBottom={showProductContext ? 0.5 : 1}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box flex="1" marginRight="xs">
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Input
                fluid
                placeholder={
                  productContextLabel ? (
                    intl.formatMessage(messages.placeholder, { context: productContextLabel })
                  ) : (
                    intl.formatMessage(messages.defaultPlaceholder)
                  )
                }
                type="search"
                {...inputProps}
              />
            </form>
          </Box>
          <Box>
            <Button
              size={constants.SMALL}
              onClick={() => {
                logEvent({
                  analytics: analyticsPayload.typeaheadExperienceClosed({
                    inputValue: currentInputValue,
                    productContextIaCode,
                  }),
                });

                onSearchClose();
              }}
            >
              { intl.formatMessage(messages.cancel) }
            </Button>
          </Box>
        </Box>
        {
          showProductContext && (
            <Box
              paddingTop="m"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <ButtonToggle
                size={constants.SMALL}
                active
                data-testid="ds-typeahead-clear-context-mobile"
                iconAfter={(
                  <CrossDiscIcon
                    size={constants.MEDIUM}
                    color="var(--ds-color-background-alt-200)"
                  />
                )}
                onClick={() => setProductContext(null)}
              >
                { productContextLabel }
              </ButtonToggle>
            </Box>
          )
        }
      </Box>

      <Menu
        {...({
          isOpen,
          types,
          prevTerm,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          searchWords,
          searchTerm,
          intl,
          withProductContext: productContextIaCode,
          logView,
          onTouchStart: () => {
            // Close keyboard on mobile
            // TODO check safety
            document.activeElement.blur();
          },
          onTypeaheadResultClick,
        })}
      />

      {
        discoveryMode && (
          <Discovery
            onSearchClose={onSearchClose}
            baseUrl={baseUrl}
            trending={types.trending || []}
            artists={topArtists || []}
            topArtistsLoading={topArtistsLoading}
            products={types.products || []}
            popularProducts={popularProducts}
            quickLinks={quickLinks}
            otherLinks={otherLinks}
            intl={intl}
            doSearch={doSearch}
            logItemClicked={logItemClicked}
            logView={logView}
            onTypeaheadResultClick={onTypeaheadResultClick}
          />
        )
      }
    </Box>
  );
};

export default TypeaheadMobile;
