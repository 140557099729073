import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import * as constants from '../constants';
import _styles from './styles.scss';

export const XLARGE = 'xlarge';

const Icon = ({ color, dangerouslySetIcon, size, styles }) => {
  return (
    <span
      style={{ color }}
      className={cnames(styles.svg, styles[size])}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: dangerouslySetIcon }}
    />
  );
};

Icon.displayName = 'Icon';

Icon.propTypes = {
  /**
    * Dangerously sets the given svg value as innterHTML
    * For use by safely generated Icon components only
    */
  dangerouslySetIcon: PropTypes.string.isRequired,
  /**
    * What size to render the icon at
    */
  size: PropTypes.oneOf([
    constants.SMALL,
    constants.MEDIUM,
    constants.LARGE,
    constants.XLARGE,
    XLARGE,
  ]),
  /**
    * What color the icon should be. Default is the current value of the CSS color property
    */
  color: PropTypes.string,
  /**
    * The CSS classNames for this component. NEVER manually specify this prop
    */
  styles: PropTypes.shape({}),
};

Icon.defaultProps = {
  size: constants.MEDIUM,
  color: 'currentColor',
  styles: _styles,
};

export { SMALL, MEDIUM, LARGE } from '../constants';
export default Icon;
