import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import * as ImageExports from '.';
import * as constants from '../constants';
import Box from '../Box';
import Flex from '../utils/components/Flex';
// eslint-disable-next-line
import ImageSrc from '!raw-loader!.';

const { default: Image } = ImageExports;
const docs = generateDocs(ImageSrc, resolver.findAllExportedComponentDefinitions);

const imageSrc = 'http://placekitten.com/400/300';
const imageSrc2x = 'http://placekitten.com/800/600';
const imagePlaceholder = 'http://placekitten.com/200/150';

const basicExample = {
  name: 'Basic',
  description: 'Works with all the native `img` attributes but displays as a block element.',
  render: () => (
    <Image src={imagePlaceholder} alt="kitten" />
  ),
  code: `
<Image src="${imagePlaceholder}" alt="kitten" />
  `,
};

const roundedExample = {
  name: 'Rounded Corners',
  description: 'Images can have subtly rounded corners.',
  render: () => (
    <Image src={imagePlaceholder} alt="kitten" roundedCorners />
  ),
  code: `
<Image src="${imagePlaceholder}" alt="kitten" roundedCorners />
  `,
};

const sizesExample = {
  name: 'Sizes',
  description: 'Images can be `fluid` to scale with its container or to a set of predetermined sizes.',
  render: () => (
    <Flex direction="column">
      <Image src={imageSrc} alt="kitten" fluid />
      <Image src={imageSrc} alt="kitten" size={constants.TINY} />
      <Image src={imageSrc} alt="kitten" size={constants.SMALL} />
      <Image src={imageSrc} alt="kitten" size={constants.MEDIUM} />
      <Image src={imageSrc} alt="kitten" size={constants.LARGE} />
      <Image src={imageSrc} alt="kitten" size={constants.XLARGE} />
      <Image src={imageSrc} alt="kitten" size={constants.JUMBO} />
    </Flex>
  ),
  code: `
<Image src="${imageSrc}" alt="kitten" fluid/> // 100%
<Image src="${imageSrc}" alt="kitten" size={constants.TINY} /> // 32px
<Image src="${imageSrc}" alt="kitten" size={constants.SMALL} /> // 64px
<Image src="${imageSrc}" alt="kitten" size={constants.MEDIUM} /> // 128px
<Image src="${imageSrc}" alt="kitten" size={constants.LARGE} /> // 256px
<Image src="${imageSrc}" alt="kitten" size={constants.XLARGE} /> // 512px
<Image src="${imageSrc}" alt="kitten" size={constants.JUMBO} /> // 1024px
  `,
};

const ratio = {
  name: 'Ratio',
  description: 'You can set the ratio of an image to either type **square** or **portrait**. If you do not want the image to fill the container you can set the ratio mode to `contain`.',
  render: () => (
    <Box display="flex">
      <Box marginRight="m" flex={1}>
        <Box style={{ maxWidth: '140px', background: 'var(--ds-color-neutral-100)' }}>
          <Image src={imageSrc} alt="kitten" ratio={constants.RATIO_SQUARE} />
        </Box>
      </Box>
      <Box marginRight="m" flex={1}>
        <Box style={{ maxWidth: '140px', background: 'var(--ds-color-neutral-100)' }}>
          <Image src={imageSrc} alt="kitten" ratio={constants.RATIO_PORTRAIT} />
        </Box>
      </Box>
      <Box marginRight="m" flex={1}>
        <Box style={{ maxWidth: '140px', background: 'var(--ds-color-neutral-100)' }}>
          <Image src={imageSrc} alt="kitten" ratio={{ type: constants.RATIO_SQUARE, mode: constants.RATIO_CONTAIN }} />
        </Box>
      </Box>
      <Box marginRight="m" flex={1}>
        <Box style={{ maxWidth: '140px', background: 'var(--ds-color-neutral-100)' }}>
          <Image src={imageSrc} alt="kitten" ratio={{ type: constants.RATIO_PORTRAIT, mode: constants.RATIO_CONTAIN }} />
        </Box>
      </Box>
    </Box>
  ),
  code: `
<Box display="flex">
  <Box marginRight="m" flex={1}>
    <Box style={{ maxWidth: '140px', background: 'var(--ds-color-neutral-100)' }}>
      <Image src="${imageSrc}" alt="kitten" ratio={constants.RATIO_SQUARE} />
    </Box>
  </Box>
  <Box marginRight="m" flex={1}>
    <Box style={{ maxWidth: '140px', background: 'var(--ds-color-neutral-100)' }}>
      <Image src="${imageSrc}" alt="kitten" ratio={constants.RATIO_PORTRAIT} />
    </Box>
  </Box>
  <Box marginRight="m" flex={1}>
    <Box style={{ maxWidth: '140px', background: 'var(--ds-color-neutral-100)' }}>
      <Image src="${imageSrc}" alt="kitten" ratio={{ type: constants.RATIO_SQUARE, mode: constants.RATIO_CONTAIN }} />
    </Box>
  </Box>
  <Box marginRight="m" flex={1}>
    <Box style={{ maxWidth: '140px', background: 'var(--ds-color-neutral-100)' }}>
      <Image src="${imageSrc}" alt="kitten" ratio={{ type: constants.RATIO_PORTRAIT, mode: constants.RATIO_CONTAIN }} />
    </Box>
  </Box>
</Box>
  `,
};

const loadOnVisibleExample = {
  name: 'Load on visible',
  description: 'Image will only display when it is visible in the viewport.',
  render: () => (
    <Box display="flex">
      <Box marginRight="m">
        <Image src={imageSrc} alt="kitten" loadOnVisible fluid roundedCorners />
        <p>Enabled</p>
      </Box>
      <Box marginRight="m">
        <Image src={imageSrc} alt="kitten" loadOnVisible={{ placeholder: imagePlaceholder, offsetTopBoundaryByPx: -100 }} fluid roundedCorners />
        <p>Custom placeholder</p>
      </Box>
      <Box marginRight="m">
        <Image src={imageSrc} alt="kitten" loadOnVisible={{ offsetTopBoundaryByPx: 200 }} fluid roundedCorners />
        <p>Load early</p>
      </Box>
    </Box>
  ),
  code: `
<Box display="flex">
  <Box>
    <Image src="${imageSrc}" alt="kitten" loadOnVisible fluid roundedCorners />
    <p>Enabled</p>
  </Box>
  <Box>
    <Image src="${imageSrc}" alt="kitten" loadOnVisible={{ placeholder: "${imagePlaceholder}", offsetTopBoundaryByPx: -100 }} fluid roundedCorners />
    <p>Custom placeholder</p>
  </Box>
  <Box>
    <Image src="${imageSrc}" alt="kitten" loadOnVisible={{ offsetTopBoundaryByPx: 200 }} fluid roundedCorners />
    <p>Load early</p>
  </Box>
</Box>
  `,
};

const srcSetExample = {
  name: 'Source sets',
  description: 'Source sets will be respected.',
  render: () => (
    <Image src={imageSrc} alt="kitten" srcSet={`${imageSrc} 1x, ${imageSrc2x} 2x`} />
  ),
  code: `
<Image src="${imageSrc}" alt="kitten" srcSet="${`${imageSrc} 1x, ${imageSrc2x} 2x`}" />
  `,
};

const Demo = {
  title: Image.displayName,
  description: 'Displays images just like an <img> tag with a few enhancements.',
  slug: 'image',
  exports: Object.keys(ImageExports),
  docGen: docs,
  examples: [
    basicExample,
    roundedExample,
    loadOnVisibleExample,
    ratio,
    sizesExample,
    srcSetExample,
  ],
  notes: `
### Placeholders and Lazy Loading

Images can be lazy loaded by using the \`loadonVisible\` prop.

If you specify \`true\` the Image will only load when it is visible (inside the user's viewport).

\`\`\`
<Image
  {...otherProps}
  loadOnVisible
/>
\`\`\`

You can specify additional configuration for this behaviour as follows:

\`\`\`
<Image
  {...otherProps}
  loadOnVisible={{
    // Whether this feature is enabled or not (defaults to false)
    enabled: true | false,
    // A path to a lightweight placeholder image to be shown whilst the actual image is loading
    placeholder: 'path/to/placeholder_img.png',
    // How close to being in view should the image be before we load it, (defaults to 0)
    offsetTopBoundaryByPx: 100,
  }}
/>
\`\`\`

**Placeholders**

The default placeholder is a data url for a neutral colored gif.

You can specify your own placeholder image using either:

- a url to an image
- a data url

To assist in creating placeholder data urls, we provide a \`generatePlaceholderSrc\` function.
It generates a neutral png image with the given dimensions.

e.g.

\`\`\`
<Image
  {...otherProps}
  loadOnVisible={{
    placeholder: generatePlaceholderSrc({ width: 30, height: 40 }),
  }}
/>
\`\`\`
  `,
};

export default Demo;
