import React from 'react';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

const Header = ({ mobile }) => {
  const Head = mobile ? MobileHeader : DesktopHeader;

  return (
    <Head />
  );
};

export default Header;
