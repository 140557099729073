import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import flattenChildren from 'react-keyed-flatten-children';
import cnames from 'classnames';
import Box from '../Box';
import * as constants from '../constants';
import styles from './styles.scss';

const sizes = [
  'none',
  'xxxs',
  'xxs',
  'xs',
  's',
  'm',
  'l',
  'xl',
  'xxl',
  'xxxl',
];

const flexAlignments = [
  'normal',
  'stretch',
  'center',
  'start',
  'end',
  'flex-start',
  'flex-end',
  'baseline',
];

const flexJustifications = [
  'normal',
  'center',
  'start',
  'end',
  'flex-start',
  'flex-end',
  'left',
  'right',
  'space-between',
  'space-around',
  'space-evenly',
  'stretch',
];

const directions = {
  [constants.VERTICAL]: 'column',
  [constants.HORIZONTAL]: 'row',
};

const Stack = forwardRef(({
  children,
  spacing,
  direction,
  /** Supported Box props */
  padding,
  paddingTop,
  paddingBottom,
  paddingRight,
  paddingLeft,
  paddingX,
  paddingY,
  alignItems,
  justifyContent,
  /* Unsupported Box props */
  display,
  flexDirection,
  flexWrap,
  margin,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  textAlign,
  /* Remove className and style */
  style,
  className,
  ...props
}, ref) => {
  const stackItems = flattenChildren(children);

  const memoizedChildrenMap = useMemo(() => {
    return React.Children.map(stackItems, (child) => {
      if (!child) return null;

      return (
        <Box
          className={cnames({
            [styles.boxVertical]: direction === constants.VERTICAL,
            [styles.boxHorizontal]: direction === constants.HORIZONTAL,
          })}
          display={direction === constants.HORIZONTAL ? 'inline-flex' : 'block'}
        >
          {child}
        </Box>
      );
    });
  }, [stackItems, spacing, direction]);

  return (
    <Box
      ref={ref}
      style={{ '--ds-js-stack-spacing': spacing === 'none' ? 0 : `var(--ds-spacing-${spacing})` }}
      display="flex"
      flexDirection={directions[direction]}
      flexWrap={direction === constants.HORIZONTAL ? 'nowrap' : undefined}
      {...({
        padding,
        paddingTop,
        paddingBottom,
        paddingRight,
        paddingLeft,
        paddingX,
        paddingY,
        alignItems,
        justifyContent,
      })}
      {...props}
    >
      {memoizedChildrenMap}
    </Box>
  );
});

Stack.displayName = 'Stack';

Stack.propTypes = {
  children: PropTypes.node,
  /**
    * Spacing between the stack's elements
  */
  spacing: PropTypes.oneOf(sizes),
  /**
    * Direction in which elements are stacked
  */
  direction: PropTypes.oneOf([constants.HORIZONTAL, constants.VERTICAL]),
  /**
    * Padding on stack
  */
  padding: PropTypes.oneOf(sizes),
  /**
    * Padding on the top of stack
  */
  paddingTop: PropTypes.oneOf(sizes),
  /**
    * Padding on the right of stack
  */
  paddingRight: PropTypes.oneOf(sizes),
  /**
    * Padding on the bottom of stack
  */
  paddingBottom: PropTypes.oneOf(sizes),
  /**
    * Padding on the left of stack
  */
  paddingLeft: PropTypes.oneOf(sizes),
  /**
    * Padding on the left and right of stack
  */
  paddingX: PropTypes.oneOf(sizes),
  /**
    * Padding on the top and bottom of stack
  */
  paddingY: PropTypes.oneOf(sizes),
  /**
    * Align items inside stack with flex alignments
  */
  alignItems: PropTypes.oneOf(flexAlignments),
  /**
    * Align items inside stack with flex justifications
  */
  justifyContent: PropTypes.oneOf(flexJustifications),
};

Stack.defaultProps = {
  direction: constants.VERTICAL,
  spacing: 'none',
};

export default Stack;
