import React from 'react';
import Box from '../../../../../../react/Box';
import Button from '../../../../../../react/Button';
import MediaCard from '../../../../../../react/MediaCard';
import * as constants from '../../../../../../react/constants';

export default () => (
  <Box display="flex" flexDirection="column" flex="1">
    <Box
      padding="xl"
      paddingBottom="none"
      flex="1"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Button size={constants.SMALL} strong disabled>Disabled</Button>
      <Box
        style={{
          border: '4px solid var(--ds-color-focus-border)',
          display: 'inline-block',
          borderRadius: 'var(--ds-radius-pill)',
          pointerEvents: 'none',
        }}
        marginLeft="m"
      >
        <Button size={constants.SMALL}>Focus</Button>
      </Box>
      <Box
        style={{
          display: 'inline-block',
          borderRadius: 'var(--ds-radius-pill)',
          background: 'var(--ds-color-hover-overlay)',
          pointerEvents: 'none',
        }}
        marginLeft="m"
      >
        <Button size={constants.SMALL}>Hover</Button>
      </Box>
    </Box>
    <Box
      padding="xl"
      flex="1"
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{
        backgroundColor: 'var(--ds-color-background-app-dark)',
        borderRadius: '0 0 var(--ds-radius-medium) var(--ds-radius-medium)',
        pointerEvents: 'none',
      }}
    >
      <Box
        style={{
          maxWidth: '120px',
          pointerEvents: 'none',
        }}
      >
        <MediaCard
          href="#"
          title="Product name"
          loading
          caption="Artist"
          imageRatio={constants.RATIO_SQUARE}
          imageSrc="https://bit.ly/2OEC442"
          transparent
        />
      </Box>
      <Box
        style={{
          display: 'inline-block',
          borderRadius: 'var(--ds-radius-pill)',
          background: 'var(--ds-color-hover-overlay-contrast)',
          pointerEvents: 'none',
        }}
        marginLeft="m"
      >
        <Button size={constants.SMALL} inverse>Hover</Button>
      </Box>
    </Box>
  </Box>
);
