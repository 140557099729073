import React from 'react';
import cnames from 'classnames';
import { useHistory } from 'react-router-dom';
import styles from './RouteLink.css';

const RouteLink = ({ children }) => {
  const history = useHistory();

  const classNames = cnames(children.props.className, styles.routeLink);

  function handleClick(e) {
    e.preventDefault();
    history.push(children.props.href);
  }
  return (
    React.cloneElement(children, { onClick: handleClick, className: classNames })
  );
};

export default RouteLink;
