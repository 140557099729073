import PropTypes from 'prop-types';
import React, { useMemo, useEffect, useContext } from 'react';
import TrackingContext from '../../contexts/TrackingContext';

export const EVENT_CLICK = 'click';
export const EVENT_DISMISS = 'dismiss';
export const EVENT_OPEN = 'open';
export const EVENT_MOUNT = 'mount';

const ALL_EVENTS = {
  EVENT_CLICK,
  EVENT_DISMISS,
  EVENT_OPEN,
};

const TrackingContextProvider = ({ children }) => children;

export default function useTracking(configOverrides, component) {
  const { track, parent } = useContext(TrackingContext);

  // Default Config
  let config = {
    isolate: false,
    disabled: false,
    on: Object.values(ALL_EVENTS),
    component,
    onMount: false,
    label: configOverrides || '',
  };

  // Apply overrides
  if (configOverrides && typeof configOverrides !== 'string') {
    config = {
      ...config,
      label: '',
      ...configOverrides,
    };
  }

  // Early return
  if (!configOverrides || !track || config.disabled) {
    return {
      track: () => {},
      TrackingContextProvider,
    };
  }

  // Should track for any given event
  const shouldTrack = (event) => {
    if (typeof config.on === 'function') {
      return config.on(Object.values(ALL_EVENTS)).includes(event);
    }

    if (Array.isArray(config.on)) return config.on.includes(event);

    return false;
  };

  if (!config.isolate) {
    config.label = [parent, config.label].filter(Boolean).join('.');
  }

  // Track on mount
  useEffect(() => {
    if (config.onMount || shouldTrack(EVENT_MOUNT)) {
      track({
        component: config.component,
        label: config.label,
        event: EVENT_MOUNT,
      });
    }
  }, [config.component, config.label]);

  const MemoizedTrackingContextProvider = useMemo(() => ({ children }) => (
    <TrackingContext.Provider value={{ track, parent: config.label }}>
      { children }
    </TrackingContext.Provider>
  ), [config.label, track]);

  // Hook return value
  return {
    track: (event, label) => {
      if (!shouldTrack(event)) return;

      track({
        component: config.component,
        label: [config.label, label].filter(Boolean).join(' : '),
        event,
      });
    },
    TrackingContextProvider: MemoizedTrackingContextProvider,
  };
}

export const trackingShape = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    label: PropTypes.string,
    onMount: PropTypes.bool,
  }),
]);

export const fwdTrackingWithComponent = (tracking, component) => {
  let trackingProp;

  if (typeof tracking === 'string') {
    trackingProp = { component, label: tracking };
  } else if (tracking) {
    trackingProp = { component, ...tracking };
  }

  return trackingProp;
};
