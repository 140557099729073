import React, { forwardRef } from 'react';

const wrapForReachUI = Component => forwardRef((props, ref) => {
  // Convert @reach selected state to our own prop
  const highlighted = typeof props['data-selected'] !== 'undefined';

  return <Component highlighted={highlighted} ref={ref} {...props} />;
});

export default wrapForReachUI;
