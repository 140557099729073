import React from 'react';
import PropTypes from 'prop-types';
import useImagePalette, { defaultFormat, defaultCrossOrigin } from '../hooks/useImagePalette';
import * as constants from '../constants';

const ImagePalette = ({ children, src, crossOrigin, format }) => {
  const result = useImagePalette({ src, crossOrigin, format });
  return <React.Fragment>{ children(result) }</React.Fragment>;
};

ImagePalette.displayName = 'ImagePalette';

ImagePalette.propTypes = {
  children: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,
  crossOrigin: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  format: PropTypes.oneOf([
    constants.FORMAT_HEX,
    constants.FORMAT_RGB,
  ]),
};

ImagePalette.defaultProps = {
  crossOrigin: defaultCrossOrigin,
  format: defaultFormat,
};

export default ImagePalette;
