import React, { useEffect } from 'react';
import { defineMessages } from 'react-intl';
import Box from '../../../../../Box';
import MenuSection, { MenuSectionLoading } from '../MenuList';
import StaticLinks from '../StaticLinks';
import ArtistsSection from '../ArtistsSection';
import { MOBILE } from '../../../../../constants';
import styles from './styles.scss';

const messages = defineMessages({
  trendingHeading: 'Trending searches',
  artistHeading: 'Top artists',
});

const DiscoveryMenu = ({
  intl,
  doSearch,
  trending,
  artists,
  popularProducts,
  quickLinks,
  otherLinks,
  baseUrl,
  logItemClicked,
  logView,
  onSearchClose,
  topArtistsLoading,
  onTypeaheadResultClick,
}) => {
  const loading = !Array.isArray(trending) || trending.length === 0;

  useEffect(() => {
    if (!loading) logView({ screen: 'discovery' });
  }, [loading]);

  if (loading) {
    return (
      <Box paddingLeft="m" paddingRight="m" data-testid="ds-typeahead-discovery-loading">
        <Box marginBottom="xl">
          <ul className={styles.menuReset}>
            <MenuSectionLoading
              heading={intl.formatMessage(messages.trendingHeading)}
              itemCount={5}
            />
          </ul>
        </Box>
        <Box marginBottom="xl">
          <ArtistsSection
            artists={[]}
            loading={loading}
            intl={intl}
            baseUrl={baseUrl}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box paddingLeft="m" paddingRight="m" data-testid="ds-typeahead-discovery">
      <Box marginBottom="xl">
        <ul className={styles.menuReset}>
          <MenuSection
            heading={intl.formatMessage(messages.trendingHeading)}
            intl={intl}
            items={trending}
            doSearch={doSearch}
            onSearchClose={onSearchClose}
            logItemClicked={logItemClicked}
            onTypeaheadResultClick={onTypeaheadResultClick}
          />
        </ul>
      </Box>
      <Box marginBottom="xl">
        <ArtistsSection
          artists={artists}
          loading={topArtistsLoading}
          intl={intl}
          baseUrl={baseUrl}
          profile={MOBILE}
        />
      </Box>
      <StaticLinks
        data={{
          popularProducts,
          quickLinks,
          otherLinks,
        }}
        profile={MOBILE}
      />
    </Box>
  );
};

export default DiscoveryMenu;
