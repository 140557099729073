import { useEffect, useRef } from 'react';

/**
  * useEffect but NOT on first render/mount
  */
const useEffectOnUpdate = (callback, dependencies) => {
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      callback();
    } else {
      mounted.current = true;
    }
  }, dependencies);
};

export default useEffectOnUpdate;
