import React from 'react';
import State from '@reach/component-component';
import * as constants from '../constants';
import Box from '../Box';
import Text from '../Text';
import Image from '../Image';
import Button from '../Button';

import * as ToastExports from '.';

const { default: Toast, openToast, updateToast } = ToastExports;

const src = 'https://ih1.redbubble.net/image.489326058.6013/flat,500x,075,f.jpg';

const AddedToList = ({ list, count = 25 }) => (
  <Box display="flex" alignItems="center" marginRight="m">
    <Box marginRight="m">
      <Image style={{ width: 56, height: 56 }} src={src} roundedCorners />
    </Box>
    <Box>
      <Text display="block" type="display5">Added to { list }</Text>
      <Text display="block" type="caption" muted>{ count } items</Text>
    </Box>
  </Box>
);

const AddedToListCode = ({ list, count = 25 }) => `          <Box display="flex" alignItems="center" marginRight="m">
            <Box marginRight="m">
              <Image style={{ width: 56, height: 56 }} src="${src}" roundedCorners />
            </Box>
            <Box>
              <Text display="block" type="display5">Added to ${list}</Text>
              <Text display="block" type="caption" muted>${count} items</Text>
            </Box>
          </Box>`;

const simple = {
  name: 'Simple example',
  description: 'Open a toast with some content. It‘ll close automatically.',
  overflowVisual: false,
  render: () => {
    return (
      <Box display="flex" flexDirection="column">
        <Box marginBottom="m">
          <Button strong onClick={() => openToast('It works')}>
            Open toast
          </Button>
        </Box>
      </Box>
    );
  },
  code: `
<Button strong onClick={() => openToast('It works')}>
  Open toast
</Button>
  `,
};

const richExample = {
  name: 'Rich Example',
  description: 'You can render rich content inside a Toast',
  overflowVisual: false,
  render: () => {
    return (
      <Box display="flex" flexDirection="column">
        <Box marginBottom="m">
          <Button
            strong
            onClick={() => {
              openToast(<AddedToList list="Favorites" />);
            }}
          >
            Add to list
          </Button>
        </Box>
      </Box>
    );
  },
  code: `
<Box display="flex" flexDirection="column">
  <Box marginBottom="m">
    <Button
      strong
      onClick={() => {
        openToast(
${AddedToListCode('Favorites')}
        );
      }}
    >
      Add to list
    </Button>
  </Box>
</Box>
  `,
};

const customActionExample = {
  name: 'Custom Action Example',
  description: 'You can provide a custom action to replace the close button',
  overflowVisual: false,
  render: () => {
    return (
      <Box display="flex" flexDirection="column">
        <Box marginBottom="m">
          <Button
            strong
            onClick={() => {
              openToast((
                <AddedToList list="Favorites" />
              ), {
                action: ({ closeToast }) => (
                  <Button
                    onClick={closeToast}
                    intent={constants.PRIMARY}
                    size={constants.SMALL}
                  >
                    Change
                  </Button>
                ),
              });
            }}
          >
            Add with custom action
          </Button>
        </Box>
      </Box>
    );
  },
  code: `
  <Box display="flex" flexDirection="column">
  <Box marginBottom="m">
    <Button
      strong
      onClick={() => {
        openToast(
          ${AddedToListCode('Favorites')},
          {
            action: ({ closeToast }) => (
              <Button
                onClick={closeToast}
                intent={constants.PRIMARY}
                size={constants.SMALL}
              >
                Change
              </Button>
            ),
          }
        );
      }}
    >
      Add with custom action
    </Button>
  </Box>
</Box>
  `,
};

const replaceExample = {
  name: 'Update Example',
  description: 'You can update the content of existing toasts, by ID',
  overflowVisual: false,
  render: () => {
    return (
      <State initialState={{ id: null, count: 0 }}>
        {
          ({ state, setState }) => (
            <Box display="flex" flexDirection="column">
              <Box marginBottom="m">
                <Button
                  strong
                  onClick={() => {
                    setState({ count: state.count + 1 });

                    const onClose = () => setState({ id: null });
                    const render = <AddedToList list="Favorites" count={state.count + 1} />;

                    if (!state.id) {
                      const id = openToast(render, { onClose });

                      setState({ id });
                    } else {
                      updateToast(state.id, {
                        render,
                        onClose,
                      });
                    }
                  }}
                >
                  Add to Favorites
                </Button>
              </Box>
            </Box>
          )
        }
      </State>
    );
  },
  code: `
<State initialState={{ id: null, count: 0 }}>
  {
    ({ state, setState }) => (
      <Box display="flex" flexDirection="column">
        <Box marginBottom="m">
          <Button
            strong
            onClick={() => {
              setState({ count: state.count + 1 });
              const onClose = () => setState({ id: null });
              const render = (
${AddedToListCode({ list: 'Favorites', count: '{ state.count + 1}' })}
              );

              if (!state.id) {
                const id = openToast(render, { onClose });

                setState({ id });
              } else {
                updateToast(state.id, {
                  render,
                  onClose,
                });
              }
            }}
          >
            Add to Favorites
          </Button>
        </Box>
      </Box>
    )
  }
</State>
  `,
};

const Demo = {
  title: Toast.displayName,
  description: 'Pop up toast messages',
  docGen: {},
  slug: 'Toast',
  customImportName: `{ ${Object.keys(ToastExports).filter(e => !['ToastContainer', 'default', 'Toast'].includes(e)).join(',')} }`,
  examples: [
    simple,
    richExample,
    customActionExample,
    replaceExample,
  ],
  notes: `
### Enabling Toasts

To enable Toasts in your application, you'll need to specify \`useToast: true\` in your \`DesignSystemProvider\` configuration at the root of your application:

\`\`\`
import theme from '@redbubble/design-system/react/themes/default';
import DesignSystemProvider from '@redbubble/design-system/react/DesignSystemProvider';

const App = (props) => (
  <DesignSystemProvider config={{ theme, useToasts: true }}>
    <div>
      Your app tree
    </div>
  </DesignSystemProvider>
);
\`\`\`

You can also specify global configuration for the toasts here, currently limited to a \`profile\`:

\`\`\`
<DesignSystemProvider
  config={{
    theme,
    useToasts: {
      profile: constants.DESKTOP, // or constants.MOBILE
    }
  }}
>
\`\`\`

### Opening Toasts

To open a Toast, enable them as specified above then import and use the \`openToast\` method:

\`\`\`
import Button from '@redbubble/design-system/react/Button';
import { openToast } from '@redbubble/design-system/react/Toast';

<Button onClick={openToast(content, options)}>
  Open
</Button>
\`\`\`

**Toast content**

The content of the Toast can be any valid React node

**Toast Options**

- \`action\` A valid React node to be rendered as an action **or** a function returning the same node. If a function is specified, it will be called with an object containing the following methods:
  - \`closeToast\`: Closes the current Toast
- \`autoClose\` By default, Toasts close on their own after a short while. If you specify \`autoClose: false\`, they'll remain open until dismissed.
- \`onClose\` Called when the Toast closes or is dismissed

### Updating Toasts

When you open a Toast the \`openToast\` method returns a unique ID. You can use that ID to update that existing Toast as long as it is open:

\`\`\`
import Button from '@redbubble/design-system/react/Button';
import { openToast, updateToast } from '@redbubble/design-system/react/Toast';

let id;

const openOrUpdateToast = (content) => {
  const onClose =  () => { id = null; },
  if (!id) {
    id = openToast(content, { autoClose: false, onClose });
  } else {
    updateToast(id, { render: content, onClose });
  }
}
\`\`\`

The \`updateToast\` method takes the ID to update as the first argument and the same options as \`openToast\` as the second argument. If you want to update the content of the toast, specify the \`render\` propert in the options as shown above.

  `,
};

export default Demo;
