import React, { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '../Box';
import useTracking, { trackingShape } from '../hooks/useTracking';
import MenuContext, { MenuParentContext } from './MenuContext';

const MenuList = forwardRef((props, ref) => {
  const {
    children,
    tracking,
    padded,
    ...rest
  } = props;

  const { TrackingContextProvider } = useTracking(tracking, 'Menu');
  const { isMenu } = useContext(MenuParentContext);

  return (
    <TrackingContextProvider>
      <MenuContext.Provider value={{ padded, isMenu }}>
        <Box
          {...rest}
          display="flex"
          flexDirection="column"
          innerRef={ref}
        >
          {
            React.Children.map(children, (child, index) => {
              return React.cloneElement(child, { index });
            })
          }
        </Box>
      </MenuContext.Provider>
    </TrackingContextProvider>
  );
});

const Menu = props => (
  <MenuParentContext.Provider value={{ isMenu: true }}>
    <MenuList {...props} />
  </MenuParentContext.Provider>
);

Menu.propTypes = {
  /**
    * MenuSections containing MenuItems and MenuLinks
    */
  children: PropTypes.node,
  /**
    * Tracking configuration for auto tracking
    */
  tracking: trackingShape,
  /**
    * Set this to true if rendering inside a horizontally bounded container such as a Card
    */
  padded: PropTypes.bool,
};

Menu.displayName = 'Menu';

export { default as MenuSection } from './MenuSection';
export { default as MenuLink } from './MenuLink';
export { default as MenuItem } from './MenuItem';
export { MenuList };

export default Menu;
