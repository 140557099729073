import React from 'react';
import { useHistory } from 'react-router-dom';
import Text from '../../../react/Text';
import Grid from '../../../react/Grid';
import Box from '../../../react/Box';
import Label from '../../../react/Label';
import Card from '../../../react/Card';
import PageBanner from '../../components/Hero';
import InteractiveBox, { InteractiveArrow } from '../../components/InteractiveBox';
import RouteLink from '../../components/RouteLink';
import components from './assets/components.svg';
import brand from './assets/brand.svg';
import guides from './assets/guides.svg';
import foundations from './assets/foundations.svg';
import color from './assets/scene-color.svg';
import type from './assets/scene-type.svg';
import accessibility from './assets/scene-accessibility.svg';

import playroom from './assets/playroom.jpg';
import figma from './assets/figma.jpg';

import styles from './home.css';

const Home = ({ mobile }) => {
  return (
    <>
      <Box marginTop="xxl">
        <Text type="display2" marginBottom="xl" display="block">Popular</Text>
        <Grid columns={mobile ? 1 : 3}>
          <RouteLink>
            <InteractiveBox
              element="a"
              href="/foundations/color"
              marginBottom="xl"
              isAnimated={false}
              transparent
            >
              <InteractiveBox style={{ backgroundImage: `url(${color})` }} className={styles.coverImage} marginBottom="l" />
              <Text type="display3" display="block" marginBottom="xs">Color</Text>
              { /* eslint-disable max-len */ }
              <Text display="block" marginBottom="m">Thoughtful use of color ensures our experiences are easy to use, on brand and without competing with our artists&apos; beautiful artwork.</Text>
              <Label subtle>Foundation</Label>
            </InteractiveBox>
          </RouteLink>
          <RouteLink>
            <InteractiveBox
              element="a"
              href="/foundations/typography"
              marginBottom="xl"
              isAnimated={false}
              transparent
            >
              <InteractiveBox style={{ backgroundImage: `url(${type})` }} className={styles.coverImage} marginBottom="l" />
              <Text type="display3" display="block" marginBottom="s">Typography</Text>
              { /* eslint-disable max-len */ }
              <Text display="block" marginBottom="m">Typography is critical to creating a hierarchy of content on our pages. It tells a story and guides our users to the most important areas of the experience.</Text>
              <Label subtle>Foundation</Label>
            </InteractiveBox>
          </RouteLink>
          <RouteLink>
            <InteractiveBox
              element="a"
              href="/guides/accessibility"
              marginBottom="xl"
              isAnimated={false}
              transparent
            >
              <InteractiveBox style={{ backgroundImage: `url(${accessibility})` }} className={styles.coverImage} marginBottom="l" />
              <Text type="display3" display="block" marginBottom="xs">Accessibility</Text>
              { /* eslint-disable max-len */ }
              <Text display="block" marginBottom="m">At Redbubble we believe that the web should be accessible to everyone. So we are committed to building products that can be used by all.</Text>
              <Label subtle>Guide</Label>
            </InteractiveBox>
          </RouteLink>
        </Grid>
      </Box>
      <Box marginTop="xxl" paddingTop="xl">
        <Text type="display2" marginBottom="xl" display="block">Tools</Text>
        <Grid columns={mobile ? 1 : 2}>
          <InteractiveBox element="a" href="https://play.redbubble.design" target="_blank">
            <Box padding="xl" paddingBottom="none" className={styles.outlinedContainer}>
              <Text type="display2" display="block">Playroom</Text>
              <Text type="display4" display="block" marginTop="m" marginBottom="xl">
                Prototype and validate your ideas using components from the system straight in your browser.
              </Text>
              <Box
                className={styles.peak}
                marginTop="xxl"
                style={{ backgroundImage: `url(${playroom})` }}
              >
                <Box
                  className={styles.browserBar}
                  display="flex"
                  alignItems="center"
                >
                  <Box className={styles.browserAction} />
                  <Box className={styles.browserAction} />
                  <Box className={styles.browserAction} />
                </Box>
              </Box>
            </Box>
          </InteractiveBox>
          <InteractiveBox element="a" href="https://www.figma.com/file/prfbVvoL2KUA8YiOoDrRpZ" target="_blank">
            <Box padding="xl" paddingBottom="none" className={styles.outlinedContainer}>
              <Text type="display2" display="block">Figma</Text>
              <Text type="display4" display="block" marginTop="m" marginBottom="xl">
                Quickly create mockups using our design system library components and styles.
              </Text>
              <Box
                className={styles.peak}
                marginTop="xxl"
                style={{ backgroundImage: `url(${figma})` }}
              />
            </Box>
          </InteractiveBox>
        </Grid>
      </Box>
    </>
  );
};

const InteractiveCard = (props) => {
  const history = useHistory();

  function handleClick(e) {
    e.preventDefault();
    history.push(props.href);
  }

  return (
    <InteractiveBox
      {...props}
      element="a"
      href={props.href}
      onClick={handleClick}
    >
      <Card>
        <Box
          className={styles.cardImage}
          style={{ backgroundImage: `url(${props.image})` }}
        >
          <Text type={props.mobile ? 'display5' : 'display3'} display="block">{props.heading}</Text>
          {!props.mobile && <Text type="body" muted display="block">{props.subheading}</Text>}
          <Box marginTop={props.mobile ? 'l' : 'xl'}>
            <InteractiveArrow />
          </Box>
        </Box>
      </Card>
    </InteractiveBox>
  );
};

const Banner = ({ mobile }) => {
  return (
    <PageBanner
      title="Design System"
      description="Efficiency for teams and consistency for users"
      mobile={mobile}
    >
      <Grid columns={mobile ? 2 : 4}>
        <InteractiveCard
          href="/brand"
          image={brand}
          heading="Brand"
          subheading="Who we are"
          mobile={mobile}
        />
        <InteractiveCard
          href="/foundations"
          image={foundations}
          heading="Foundations"
          subheading="Our core"
          mobile={mobile}
        />
        <InteractiveCard
          href="/components"
          image={components}
          heading="Components"
          subheading="Our building blocks"
          mobile={mobile}
        />
        <InteractiveCard
          href="/guides"
          image={guides}
          heading="Guides"
          subheading="Getting started"
          mobile={mobile}
        />
      </Grid>
    </PageBanner>
  );
};

export { Banner };
export default Home;
