import React, { useState } from 'react';
import posed from 'react-pose';
import cnames from 'classnames';
import Box from '../../../react/Box';
import PageSection from '../../../react/PageSection';
import Image from '../../../react/Image';
import ChevronDown from '../../../react/Icons/ChevronDownBig';
import CloseBig from '../../../react/Icons/CloseBig';
import useImagePalette from '../../../react/hooks/useImagePalette';
import ArtistAttribution from '../ArtistAttribution';
import InteractiveBox from '../InteractiveBox';

import { ReactComponent as BannerDivider } from '../../images/cloudDivider.svg';

import styles from './Banner.css';

const BannerAnimation = posed.div({
  collapsed: {
    height: ({ collapsedHeight }) => collapsedHeight,
    transition: {
      height: { type: 'spring', damping: '20', mass: '0.5' },
    },
  },
  expanded: {
    height: ({ expandedHeight }) => expandedHeight,
    transition: {
      height: { type: 'spring', damping: '16', mass: '0.8' },
    },
  },
});

const Banner = ({
  artwork,
  artworkLink,
  avatar,
  artist,
  title,
  shopLink,
  mobile,
}) => {
  const [open, setOpen] = useState(false);
  const { colors, loading } = useImagePalette(artwork);

  const bannerClasses = cnames(styles.banner, {
    [styles.loading]: loading,
    [styles.open]: open,
  });

  return (
    <BannerAnimation
      pose={open ? 'expanded' : 'collapsed'}
      collapsedHeight={mobile ? '100px' : '200px'}
      expandedHeight={mobile ? '70vh' : '90vh'}
    >
      <Box
        className={bannerClasses}
        style={{
          backgroundColor: colors[0],
          backgroundImage: `url(${artwork})`,
        }}
      >
        <Box
          className={styles.overlay}
          style={{ backgroundColor: colors[0] }}
        />
        <PageSection>
          <Box className={cnames(styles.trigger, loading && styles.hidden)}>
            <InteractiveBox
              className={styles.customButton}
              element="button"
              padding="xxs"
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={() => setOpen(!open)}
              isAnimated={false}
              aria-label="Show banner artwork"
              aria-pressed={open}
            >
              <Box className={styles.customIcon}>
                {open ? <CloseBig /> : <ChevronDown />}
              </Box>
            </InteractiveBox>
          </Box>
        </PageSection>
        <Box
          className={styles.bannerInner}
          aria-hidden={!open}
          style={{ visibility: !open && 'hidden' }}
        >
          <Box
            className={styles.attribution}
            display="flex"
            justifyContent={mobile ? 'center' : 'flex-end'}
            flexDirection="column"
            alignItems="center"
          >
            <Box className={styles.container}>
              <InteractiveBox
                element="a"
                target="_blank"
                href={artworkLink}
                className={styles.image}
                display="inline-block"
              >
                <Image src={artwork} roundedCorners alt={title} />
              </InteractiveBox>
            </Box>
          </Box>
          <Box className={styles.artistAttribution}>
            <PageSection padding={mobile ? 'l' : 'xxl'}>
              <InteractiveBox
                href={shopLink}
                target="_blank"
                element="a"
                display="inline-block"
              >
                <ArtistAttribution title={title} avatar={avatar} artist={artist} />
              </InteractiveBox>
            </PageSection>
          </Box>
        </Box>
        <BannerDivider className={styles.bannerDivider} />
      </Box>
    </BannerAnimation>
  );
};

export default Banner;
