/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import { Link as ReactRouterLink } from 'react-router-dom';
import * as constants from '../constants';
import Box from '../Box';
import Card, { CardBody } from '../Card';
import Text from '../Text';
import ChevronRightIcon from '../Icons/ChevronRight';
import FilterIcon from '../Icons/Filter';
import HeartFilledIcon from '../Icons/HeartFilled';
import List, { ListItem } from '../List';

import * as LinkExports from '.';

// eslint-disable-next-line
import LinkSrc from '!raw-loader!.';

const { default: TextLink } = LinkExports;
const docs = generateDocs(LinkSrc, resolver.findAllExportedComponentDefinitions);

const basicExample = {
  name: 'Links in text',
  description: `
Links in text will inherit their surrounding text color and size.

In body and caption text, they will stand apart with a contrasting font-weight.

In conjunction with the [Text](/components/Text/examples) component you can create links that appear like all our supported typography types.

Links indicate their interactivity on hover (desktop only).
  `,
  overflowVisual: false,
  muted: true,
  render: () => (
    <Box>
      <Box marginBottom="m">
        <Card>
          <CardBody>
            <Text type="display3" display="block">
              <TextLink href="#">Cookie Policy</TextLink>
            </Text>
            <Box paddingBottom="xs" />
            <Text display="block" element="p">
              I acknowledge that this website <TextLink href="#">redbubble.com</TextLink> uses cookies and other tracers
for the purposes of audience building, targeted advertising, analytics, website
performance monitoring and management.
            </Text>
            <Box paddingBottom="m" />
            <Text display="block" type="caption" muted element="p">
              For more information (in particular regarding cookies / tracers
              opt-out), please see our <TextLink href="#">Privacy Policy</TextLink>.
            </Text>
          </CardBody>
        </Card>
      </Box>
    </Box>
  ),
  code: `
<Box>
  <Box marginBottom="m">
    <Card>
      <CardBody>
        <Text type="display3" display="block">
          <TextLink href="#">Cookie Policy</TextLink>
        </Text>
        <Box paddingTop="xs" />
        <Text display="block" element="p">
          I acknowledge that this website <TextLink href="#">redbubble.com</TextLink> uses cookies and other tracers
for the purposes of audience building, targeted advertising, analytics, website
performance monitoring and management.
        </Text>
        <Box paddingTop="m" />
        <Text display="block" type="caption" muted element="p">
          For more information (in particular regarding cookies / tracers
          opt-out), please see our <TextLink href="#">Privacy Policy</TextLink>.
        </Text>
      </CardBody>
    </Card>
  </Box>
</Box>
  `,
};

const uiExample = {
  name: 'Links in UI',
  description: `
Links can also be used stand-alone in UI.

You can use subtle links to create contrast between links or when you don't want them to stand out as much.

Eye-catching Primary links are great for CTA's.

Use icons to further clarify the intention / purpose of a link.
  `,
  overflowVisual: false,
  muted: true,
  render: () => (
    <Box>
      <Box marginBottom="m">
        <Card>
          <CardBody>
            <Text type="display3" display="block">Latest Offers</Text>
            <Box paddingBottom="xxs" />
            <Text display="block" element="p">
              We have something for everyone this holiday season.
            </Text>
            <Box marginBottom="m" />
            <Text display="block" element="p">
              <TextLink
                intent={constants.PRIMARY}
                href="#"
              >
                Read More
              </TextLink>
            </Text>
          </CardBody>
        </Card>
      </Box>

      <Box marginBottom="m">
        <Card>
          <CardBody>
            <TextLink href="#">Regular Link</TextLink>
            <Box margin="m" />
            <TextLink subtle href="#">Subtle Link</TextLink>
          </CardBody>
        </Card>
      </Box>

      <Box marginBottom="m">
        <Card>
          <CardBody>
            <Text type="display3">Settings</Text>
          </CardBody>
          <List divided borderTop>
            <ListItem>
              <TextLink iconBefore={<FilterIcon />} subtle href="#">Notifications</TextLink>
            </ListItem>
            <ListItem>
              <TextLink iconBefore={<HeartFilledIcon />} subtle href="#">Favorites</TextLink>
            </ListItem>
          </List>
        </Card>
      </Box>

      <Box marginBottom="m">
        <Card>
          <CardBody>
            <Text display="block" element="p">
              <TextLink href="#">Clothing</TextLink>
              <ChevronRightIcon />
              <TextLink href="#">T-Shirts</TextLink>
              <ChevronRightIcon />
              <TextLink subtle href="#">V-Neck</TextLink>
            </Text>
          </CardBody>
        </Card>
      </Box>

      <Box marginBottom="m">
        <Card>
          <CardBody>
            <List>
              <ListItem>
                <TextLink href="#">Clothing</TextLink>
              </ListItem>
              <ListItem>
                <Box paddingLeft="m">
                  <TextLink subtle href="#">Dresses</TextLink>
                </Box>
              </ListItem>
              <ListItem>
                <Box paddingLeft="m">
                  <TextLink subtle href="#">T-Shirts</TextLink>
                </Box>
              </ListItem>
              <ListItem>
                <TextLink href="#">Bags</TextLink>
              </ListItem>
              <ListItem>
                <TextLink href="#">Wall Art</TextLink>
              </ListItem>
              <ListItem>
                <TextLink href="#">Stickers</TextLink>
              </ListItem>
            </List>
          </CardBody>
        </Card>
      </Box>

      <Box marginBottom="m">
        <Card>
          <CardBody>
            <Text type="display5" display="block">
              <TextLink href="#">A cool artwork</TextLink>
            </Text>
            <Text type="body2" muted>
              By a cool <TextLink href="#">Artist</TextLink>
            </Text>
          </CardBody>
        </Card>
      </Box>
    </Box>
  ),
  code: `
<Box>
  <Box marginBottom="m">
    <Card>
      <CardBody>
        <Text type="display3" display="block">Latest Offers</Text>
        <Box paddingTop="xxs" />
        <Text display="block" element="p">
          We have something for everyone this holiday season.
        </Text>
        <Box marginBottom="m" />
        <Text display="block" element="p">
          <TextLink
            intent={constants.PRIMARY}
            href="#"
          >
            Read More
          </TextLink>
        </Text>
      </CardBody>
    </Card>
  </Box>

  <Box marginBottom="m">
    <Card>
      <CardBody>
        <Text type="display3">Settings</Text>
      </CardBody>
      <List divided borderTop>
        <ListItem>
          <TextLink iconBefore={<FilterIcon />} subtle href="#">Notifications</TextLink>
        </ListItem>
        <ListItem>
          <TextLink iconBefore={<HeartFilledIcon />} subtle href="#">Favorites</TextLink>
        </ListItem>
      </List>
    </Card>
  </Box>

  <Box marginBottom="m">
    <Card>
      <CardBody>
        <Text display="block" element="p">
          <TextLink href="#">Clothing</TextLink>
          <ChevronRightIcon />
          <TextLink href="#">T-Shirts</TextLink>
          <ChevronRightIcon />
          <TextLink subtle href="#">V-Neck</TextLink>
        </Text>
      </CardBody>
    </Card>
  </Box>

  <Box marginBottom="m">
    <Card>
      <CardBody>
        <List>
          <ListItem>
            <TextLink href="#">Clothing</TextLink>
          </ListItem>
          <ListItem>
            <Box paddingLeft="m">
              <TextLink subtle href="#">Dresses</TextLink>
            </Box>
          </ListItem>
          <ListItem>
            <Box paddingLeft="m">
              <TextLink subtle href="#">T-Shirts</TextLink>
            </Box>
          </ListItem>
          <ListItem>
            <TextLink href="#">Bags</TextLink>
          </ListItem>
          <ListItem>
            <TextLink href="#">Wall Art</TextLink>
          </ListItem>
          <ListItem>
            <TextLink href="#">Stickers</TextLink>
          </ListItem>
        </List>
      </CardBody>
    </Card>
  </Box>

  <Box marginBottom="m">
    <Card>
      <CardBody>
        <Text type="display5" display="block">
          <TextLink href="#">A cool artwork</TextLink>
        </Text>
        <Text type="body2" muted>
          By a cool <TextLink href="#">Artist</TextLink>
        </Text>
      </CardBody>
    </Card>
  </Box>
</Box>
  `,
};

const customElementExample = {
  name: 'Custom link component',
  description: `
Sometimes the need arises to use a custom link component for e.g. to achieve client-side routing. This is supported via a render prop
  `,
  overflowVisual: false,
  render: () => (
    <Box>
      <Box marginBottom="m">
        <TextLink>
          {
            props => (
              <ReactRouterLink
                to={{ pathname: '', hash: 'react-router' }}
                {...props}
              >
                React Router Link
              </ReactRouterLink>
            )
          }
        </TextLink>
      </Box>
    </Box>
  ),
  code: `
<Box>
  <Box marginBottom="m">
    <TextLink>
      {
        props => (
          <ReactRouterLink
            to={{ pathname: '', hash: 'react-router' }}
            {...props}
          >
            React Router Link
          </ReactRouterLink>
        )
      }
    </TextLink>
  </Box>
</Box>
  `,
};

const externalExample = {
  name: 'External Links',
  description: `
Opening links to external sites in new windows requires some additional care to ensure it‘s done securely.

Use the \`external\` prop to ensure your link to an external site is opened securely in a new window.
  `,
  overflowVisual: false,
  render: () => (
    <Box>
      <Box marginBottom="m">
        <TextLink href="#" external>Safe external TextLink</TextLink>
      </Box>
    </Box>
  ),
  code: `
<Box>
  <Box marginBottom="m">
    <TextLink href="#" external>Safe external TextLink</TextLink>
  </Box>
</Box>
  `,
};

const Demo = {
  title: TextLink.displayName,
  description: 'TextLinks allow users to navigate to different pages / information. They can appear within other text or as standalone actions.',
  docGen: docs,
  slug: 'TextLink',
  exports: Object.keys(LinkExports),
  examples: [
    basicExample,
    uiExample,
    externalExample,
    customElementExample,
  ],
  notes: `
  `,
};

export default Demo;
