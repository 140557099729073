import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import Text from '../Text';
import Box from '../Box';
import MenuContext from './MenuContext';
import styles from './styles.scss';
import useTracking, { trackingShape } from '../hooks/useTracking';

const MenuSection = ({ children, title, hideTitle, index, tracking }) => {
  const { padded, isMenu } = useContext(MenuContext);
  const { TrackingContextProvider } = useTracking(tracking, 'MenuSection');

  const classNames = cnames(styles.section, {
    [styles.padded]: padded,
  });

  const menuGroupProps = isMenu ? {
    role: 'group',
    'aria-label': title,
  } : {};

  const sectionMarginTop = isMenu ? 'xl' : 'm';

  return (
    <TrackingContextProvider>
      <Box
        {...menuGroupProps}
        display="flex"
        flexDirection="column"
        className={classNames}
      >
        { title && !hideTitle && (
          <Box
            marginTop={index === 0 ? 0 : sectionMarginTop}
            marginBottom="xs"
            className={styles.sectionHeading}
          >
            <Text
              type="caption"
              aria-hidden="true"
              display="block"
            >
              <strong>
                {title}
              </strong>
            </Text>
          </Box>
        )}

        { children }
      </Box>
    </TrackingContextProvider>
  );
};

MenuSection.propTypes = {
  /**
    * The MenuItems and MenuLinks for this section
    */
  children: PropTypes.node,
  /**
    * A title to label this section (for screen readers as well)
    */
  title: PropTypes.string.isRequired,
  /**
    * Visually hide the section title (not hidden from screen readers)
    */
  hideTitle: PropTypes.bool,
  /**
    * Private: The index of this section among it's peers
    */
  index: PropTypes.number,
  /**
    * Tracking configuration for auto tracking
    */
  tracking: trackingShape,
};

MenuSection.defaultProps = {
  hideTitle: false,
};

MenuSection.displayName = 'MenuSection';

export default MenuSection;
