import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import isArray from 'lodash/isArray';
import styles from './styles.scss';
import Button from '../Button';
import Box from '../Box';
import * as constants from '../constants';
import { doSearch } from '../headerAndFooter/lib/doSearch';
import EngagingSearchPlaceholder from '../EngagingSearchPlaceholder';
import RainbowStarSearchIcon from '../Icons/RainbowStarSearch';

const messages = defineMessages({
  searchTermPlaceholder: 'Hi! Describe what you are looking for.',
});

// Check if valid placeholder
function isValidPlaceholderMessages(messagesArray) {
  return (
    isArray(messagesArray) && messagesArray.length > 2 && messagesArray.every(m => typeof m === 'string')
  );
}

const SimpleSearch = ({
  intl,
  engagingPlaceholderMessages,
  baseUrl,
  productContext,
}) => {
  const engagingTextEnabled = isValidPlaceholderMessages(
    engagingPlaceholderMessages,
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [isInFocus, setIsInFocus] = useState(false);
  const [rotatingPlaceholder, setRotatingPlaceholder] = useState(false);
  const defaultPlaceholderText = intl.formatMessage(
    messages.searchTermPlaceholder,
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    doSearch(searchTerm, productContext, baseUrl);
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleMouseEnter = () => setIsInFocus(true);
  const handleMouseLeave = () => setIsInFocus(false);

  const handleFocus = () => {
    setIsInFocus(true);
    setRotatingPlaceholder(true);
  };

  const handleBlur = () => {
    setIsInFocus(false);
    setRotatingPlaceholder(false);
  };

  return (
    <div className={isInFocus ? `${styles.conditionalBorder}` : `${styles.rainbowWrapper}`}>
      <div
        role="searchbox"
        tabIndex="0"
        onClick={handleFocus}
        onMouseOver={handleMouseEnter}
        onMouseOut={handleMouseLeave}
        onFocus={handleMouseEnter}
        onBlur={handleBlur}
      >
        <form className={styles.searchForm} onSubmit={handleSubmit}>
          <input
            type="search"
            value={searchTerm}
            onChange={handleInputChange}
            placeholder={!engagingTextEnabled ? defaultPlaceholderText : null}
          />
          {engagingTextEnabled && searchTerm.length < 1 && (
            <EngagingSearchPlaceholder
              data-testid="engaging-search-placeholder"
              placeholderTexts={engagingPlaceholderMessages}
              pauseTextAnimation={rotatingPlaceholder}
            />
          )}
          <Box className={styles.buttonBackground}>
            <Button type="submit" circle>
              <RainbowStarSearchIcon size={constants.LARGE} />
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
};

SimpleSearch.displayName = 'SimpleSearch';

SimpleSearch.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  engagingPlaceholderMessages: PropTypes.arrayOf(PropTypes.string),
  baseUrl: PropTypes.string,
  productContext: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  }),
};

SimpleSearch.defaultProps = {
  engagingPlaceholderMessages: null,
  baseUrl: '',
  productContext: null,
};

export default SimpleSearch;
