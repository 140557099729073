import React from 'react';
import MarkdownContents from 'markdown-contents';
import base64 from 'base64-url';
import dedent from 'dedent';
import Markdown from '../../../components/Markdown';
import TabBar, { Tab } from '../../../components/TabBar';
import Label from '../../../../react/Label';
import Text from '../../../../react/Text';
import devGuide from './developerGuide';
import * as constants from '../../../../react/constants';

import * as AlertComponent from '../../../../react/Alert';
import * as CardComponent from '../../../../react/Card';
import * as MenuComponent from '../../../../react/Menu';
import * as MasonryComponent from '../../../../react/Masonry';
import * as GridComponent from '../../../../react/Grid';
import * as ListComponent from '../../../../react/List';
import * as TextLinkComponent from '../../../../react/TextLink';
import * as MediaCardComponent from '../../../../react/MediaCard';
import * as ButtonComponent from '../../../../react/Button';
import * as ButtonToggleComponent from '../../../../react/ButtonToggle';
import * as ModalComponent from '../../../../react/Modal';
import * as PopoverComponent from '../../../../react/Popover';
import * as TableComponent from '../../../../react/Table';
import * as ToastComponent from '../../../../react/Toast';
import * as DrawerComponent from '../../../../react/Drawer';
import * as BoxComponent from '../../../../react/Box';
import * as PageSectionComponent from '../../../../react/PageSection';

const Box = BoxComponent.default;
const Button = ButtonComponent.default;

const toc = MarkdownContents(devGuide);

const ROUTE_ROOT = '/guides/tracking';

const clicksMessage = component => `${component}s track all clicks on them including keyboard "clicks".`;
const contextMessage = component => `**Context**  \nThe ${component}’s tracking prop will contextualize tracking inside the ${component}`;
const triggerOrControlMessage = component => `Open / dismiss events will be tracked regardless of whether you use a trigger or control the ${component} via props.`;

const makeUrl = () => {
  const url = new URL(window.location.href);

  let { origin } = new URL(window.location.href);

  if (origin.includes('localhost')) return origin.replace('3000', '9000');

  if (origin.includes('branch')) {
    origin = origin.replace('branch', 'branch.play');
  } else {
    origin = `${url.protocol}//play.${url.hostname}`;
  }

  return origin;
};

const COMPONENTS = [
  {
    ...AlertComponent,
    providesContext: true,
    notes: `
      Alert's track when they are dismissed
    `,
    code: `
      <Alert dismissable tracking="track-me" title="Example alert">
        <Text display="block">Content of the Alert goes here</Text>
        <Button strong tracking="child">I track with context</Button>
      </Alert>
    `,
  },
  {
    ...BoxComponent,
    providesContext: true,
    code: `
      <Box tracking="track-me">
        <Button strong tracking="child">I track with context</Button>
      </Box>
    `,
  },
  {
    ...ButtonComponent,
    notes: `
      ${clicksMessage('Button')}
    `,
    code: `
      <Button tracking="track-me">I track on click</Button>
    `,
  },
  {
    ...ButtonToggleComponent,
    notes: `
      ${clicksMessage('ButtonToggle')}

      Click tracking on ButtonToggles will not indicate their state. Change the \`tracking\` prop yourself to track different states if you require that information.
    `,
    code: `
      <ButtonToggle tracking="track-me-on">I track on click</ButtonToggle>
      <ButtonToggle active tracking="track-me-off">I track on click</ButtonToggle>
    `,
  },
  {
    ...CardComponent,
    notes: `
      ${clicksMessage('Card')}. If the Card has no \`onClick\` behaviour, clicks will **not** be tracked.
    `,
    code: `
      <Card tracking="track-me" onClick={() => console.log('')}>
        <Box padding={1}>
          <Button tracking="child">I track with context</Button>
        </Box>
      </Card>
    `,
  },
  {
    ...DrawerComponent,
    providesContext: true,
    notes: `
      ${triggerOrControlMessage('Drawer')}
    `,
    code: `
      <Drawer
        accessibleTitle="demo"
        trigger={triggerProps => (
          <Button
            {...Object.assign({}, triggerProps, { strong: true })}
          >
            Open
          </Button>
        )}
        tracking="track-me"
      >
        {({ close }) => (
          <Box padding={1}>
            <Section
              title="Tracking inside the Drawer"
              caption="Tracking on items inside the Drawer works as expected too."
            >
              <Button strong tracking="track-me">
                I track inside the Drawer
              </Button>
              <Button strong onClick={close}>
                Close
              </Button>
            </Section>
          </Box>
        )}
      </Drawer>
    `,
  },
  {
    ...GridComponent,
    providesContext: true,
    code: `
      <Grid
        columns={2}
        tracking="track-me"
      >
        <Button tracking="child-1">I track with context</Button>
        <Button tracking="child-2">I track with context</Button>
      </Grid>
    `,
  },
  {
    ...ListComponent,
    providesContext: true,
    code: `
      <List tracking="track-me">
        <ListItem>
          <Box display="flex" justifyContent="space-between">
            <Text>Item</Text>
            <Button strong tracking="child-1">
              Action 1
            </Button>
          </Box>
        </ListItem>
        <ListItem>
          <Box display="flex" justifyContent="space-between">
            <Text>Item</Text>
            <Button strong tracking="child-2">
              Action 2
            </Button>
          </Box>
        </ListItem>
      </List>
    `,
  },
  {
    ...MasonryComponent,
    providesContext: true,
    code: `
      <Masonry
        targetColumnWidth={200}
        tracking="track-me"
      >
        <Button tracking="child-1">I track with context</Button>
        <Button tracking="child-2">I track with context</Button>
      </Masonry>
    `,
  },
  {
    ...MediaCardComponent,
    providesContext: true,
    notes: `
      ${clicksMessage('MediaCard')}

      **Primary Action**  \nTracking should be added manually to actionable elements in the \`primaryActionSlot\`. These interactions will be contextualized to the \`MediaCard\`.

      **Secondary Actions**  \nTracked MediaCards will track when a user opens / dismisses their secondary actions menu.

      Tracking can be added to each \`secondaryAction\` to track them individually.

      **Custom content**  \nTracking should be added manually to actionable elements in the \`children\` slot. These interactions will be contextualized to the \`MediaCard\`. \n
    `,
    code: `
      <MediaCard
        imageSrc="https://bit.ly/2Uk9Jpg"
        title="Example"
        tracking="track-me"
        caption="Example"
        secondaryActions={[
          {
            render: "Batman",
            key: "batman",
            onClick: () => {},
            tracking: "batman"
          },
          {
            render: "Robin",
            key: "robin",
            onClick: () => {},
            tracking: "robin"
          }
        ]}
        primaryActionSlot={
          <Button
            tracking="favorite"
            circle
            elevation={constants.ELEVATION_LOW}
            iconAfter={<HeartSmallIcon />}
            size={constants.SMALL}
          />
        }
      >
        <Button
          strong
          fluid
          tracking="add-to-cart"
          size={constants.SMALL}
        >
          Add to cart
        </Button>
      </MediaCard>
    `,
  },
  {
    ...MenuComponent,
    providesContext: true,
    notes: `
      ${triggerOrControlMessage('Menu')}

      You can add a tracking prop to individual \`MenuItems\` to track when they are clicked.
    `,
    code: `
      <Menu
        accessibleTitle="demo"
        tracking="track-me"
        trigger={triggerProps => (
          <Button
            {...Object.assign({}, triggerProps, { strong: true })}
          >
            Open
          </Button>
        )}
      >
        <MenuItem tracking="batman">Batman</MenuItem>
        <MenuItem tracking="superman">Superman</MenuItem>
      </Menu>
    `,
  },
  {
    ...ModalComponent,
    providesContext: true,
    notes: `
      ${triggerOrControlMessage('Modal')}
    `,
    code: `
      <Modal
        accessibleTitle="demo"
        trigger={triggerProps => (
          <Button
            {...Object.assign({}, triggerProps, { strong: true })}
          >
            Open
          </Button>
        )}
        tracking="track-me"
      >
        {({ close }) => (
          <Box padding={1}>
            <Section
              title="Tracking inside the Modal"
              caption="Tracking on items inside the Modal works as expected too."
            >
              <Button strong tracking="track-me">
                I track inside the Modal
              </Button>
              <Button strong onClick={close}>
                Close
              </Button>
            </Section>
          </Box>
        )}
      </Modal>
    `,
  },
  {
    ...PageSectionComponent,
    providesContext: true,
    code: `
      <PageSection tracking="track-me">
        <Button strong tracking="child">I track with context</Button>
      </PageSection>
    `,
  },
  {
    ...PopoverComponent,
    providesContext: true,
    notes: `
      **Actions**

      If you specify actions for your Popover, you can add a tracking prop to them directly to track it within the context of the Popover.

      ${triggerOrControlMessage('Popover')}
    `,
    code: `
      <Popover
        accessibleTitle="demo"
        trigger={triggerProps => (
          <Button
            {...Object.assign({}, triggerProps, { strong: true })}
          >
            Open
          </Button>
        )}
        tracking="track-me"
      >
        {({ closePopover: close }) => (
          <Box padding={1}>
            <Section
              title="Tracking inside the Popover"
              caption="Tracking on items inside the Popover works as expected too."
            >
              <Box display="flex" flexDirection="column">
                <Button fluid strong tracking="track-me">
                  Tracked inside Popover
                </Button>
                <Box marginBottom={1} />
                <Button fluid strong onClick={close}>
                  Close
                </Button>
              </Box>
            </Section>
          </Box>
        )}
      </Popover>
    `,
  },
  {
    ...TableComponent,
    providesContext: true,
    code: `
      <Table
        tracking="track-me"
        rowKey="size"
        columns={[
          { dataIndex: "size", title: "Size", width: 150 },
          { dataIndex: "action", title: "Action" }
        ]}
        rows={[
          {
            size: "S",
            action: (
              <Button strong tracking="child-1">
                Apply
              </Button>
            )
          },
          {
            size: "M",
            action: (
              <Button strong tracking="child-2">
                Apply
              </Button>
            )
          },
          {
            size: "L",
            action: (
              <Button strong tracking="child-3">
                Apply
              </Button>
            )
          }
        ]}
      />
    `,
  },
  {
    ...TextLinkComponent,
    notes: `
      ${clicksMessage('TextLink')}
    `,
    code: '<TextLink tracking="track-me" href="#">I track on click</TextLink>',
  },
  {
    ...ToastComponent,
    providesContext: true,
    notes: `
      **Actions**

      If you specify an action for your Toast, you can add a tracking prop to it directly to track it within the context of the Toast.
    `,
    code: `
      <Button
        strong
        onClick={() => {
          openToast("Tracking inside a toast.", {
            tracking: "example-toast",
            action: ({ closeToast }) => (
              <Button strong tracking="track-me" onClick={closeToast}>
                I track with context
              </Button>
            )
          });
        }}
      >
        Open toast
      </Button>
    `,
  },
];

const Tracking = () => {
  return (
    <Box display="flex">
      <Box>
        <Markdown>
          {`
            # Tracking

            #### All Design System components have the opt-in ability to track user interactions with them via a centrally configured transport.
          `}
        </Markdown>
        <Box marginTop="m">
          <TabBar rootPath={ROUTE_ROOT}>
            <Tab label="Developer Guide">
              <Markdown>
                { toc.markdown() }
              </Markdown>
              <Markdown>
                { devGuide }
              </Markdown>
            </Tab>
            <Tab label="Component Events">
              {
                COMPONENTS.map(component => (
                  <Box paddingTop="m" paddingBottom="m" style={{ borderBottom: 'var(--ds-size-border-width-hairline) solid var(--ds-color-border)' }}>
                    <Text display="block" type="display2">{ component.default.displayName }</Text>

                    <Box paddingTop="xs" paddingBottom="xs">
                      {
                        Object.keys(component.events || {}).map(e => (
                          <Box marginRight="xs" display="inline-block">
                            <Label subtle>{ e }</Label>
                          </Box>
                        ))
                      }
                      {
                        component.providesContext && (
                          <Label subtle intent={constants.INFORMATION}>PROVIDES CONTEXT</Label>
                        )
                      }
                    </Box>

                    <Box paddingTop="xs" paddingBottom="xs">
                      <Markdown>
                        { component.notes }
                      </Markdown>

                      {
                        component.providesContext && (
                          <Markdown>
                            { contextMessage(component.default.displayName) }
                          </Markdown>
                        )
                      }
                    </Box>

                    {
                      component.code && (
                        <Box display="flex" justifyContent="flex-end">
                          <Button
                            target="_blank"
                            rel="noopener noferrer"
                            href={`${makeUrl()}/#?code=${base64.encode(dedent(component.code.trim()))}`}
                            size={constants.SMALL}
                          >
                            Open demo
                          </Button>
                        </Box>
                      )
                    }

                  </Box>
                ))
              }
            </Tab>
          </TabBar>
        </Box>
      </Box>
    </Box>
  );
};

export default Tracking;
