export default `
## Setup

You'll need to follow the [Getting Started](/guidelines/getting-started) guideline and wrap your application in the \`DesignSystemProvider\`.

Next, provide a tracker to be called when components log their interactions:

\`\`\`
// e.g. my/app/App.js

import React from 'react';
import DesignSystemProvider from '@redbubble/design-system/react/DesignSystemProvider';


// This could be a module you install, a class, etc.
// It must have a track() method
// You can do whatever you want with the interaction provided to the track method.
const MySimpleTracker = {
  track: (interaction) => {
    console.log(interaction);
  },
};


const App = (props) => (
  <DesignSystemProvider config={{ ...otherConfig, tracker: MySimpleTracker }}>
    <Box>
      Your app tree
    </Box>
  </DesignSystemProvider>
);
\`\`\`

Now \`MySimpleTracker.track()\` will be called every time a user interacts with a tracking-enabled component.

## Usage

To opt-in to tracking all interactions with a component, simply provide a \`tracking\` prop with a meaningful label:

\`\`\`
import React from 'react'';
import Button from '@redbubble/design-system/react/Button';


const ProductConfiguration = () => (
  <Box>
    <Button tracking="add-to-cart">Add to cart</Button>
  </Box>
);
\`\`\`

When a user clicks this Button, an interaction will be logged as follows:

\`\`\`
{
  component: 'Button',
  label: 'add-to-cart',
  event: 'click',
}
\`\`\`

## Contextual Tracking

It can be useful when reading tracking logs to have context of which exact component was interacted with.


You could provide an overly specific label e.g. \`storefront-left-hand-menu-filter-button-top-with-arrow\`.

This approach could quickly get out of hand and doesn't scale as you track more things.

Instead, you can use a \`tracking\` prop on container components to automatically provide context to interactions tracked within them.

\`\`\`
import React from 'react';
import PageSection from '@redbubble/design-system/react/PageSection';
import Card from '@redbubble/design-system/react/Card';
import Box from '@redbubble/design-system/react/Box';
import Button from '@redbubble/design-system/react/Button';


const AddToCartButton = () => <Button tracking="add-to-cart">Add to Cart</Button>;


const ProductConfiguration = () => (
  <PageSection tracking="product-configuration">
    <Card tracking="promotion">
      <Text>Buy 2 and get 1 free!</Text>
      <AddToCartButton quantity={2} />
    </Card>


    <Card>
      <AddToCartButton quantity={1} />
    </Card>
  </PageSection>
);
\`\`\`

A click on the first Button will log as:

\`\`\`
{
  component: 'Button',
  label: 'product-configuration.promotion.add-to-cart',
  event: 'click',
}
\`\`\`

Whereas on the second:

\`\`\`
{
  component: 'Button',
  label: 'product-configuration.add-to-cart',
  event: 'click',
}
\`\`\`

As you can see it becomes trivial to log and later query which components where clicked in which circumstances.

## Configuration

For more control over tracking behaviour, you can pass a configuration object to the \`tracking\` prop:

\`\`\`
<Button
  tracking={{
    label: 'add-to-cart',
    isolate: false,
    on: (events) => events,
    disabled: false,
  }}
>
  Add to Cart
</Button>
\`\`\`


### Configuration Properties


**label**

\`string | optional, no useful default\`\n

The label is typically reserved for identifying the specific instance of a component. For e.g. you might have a button with an "Add to cart" label, and another button with a "Follow artist" label.

The label is the primary way of identifying why the action a user performed is relevant.


**disabled**

\`boolean | optional, defaults to false\`\n

When set to \`false\` acts as though you did not specify a \`tracking\` prop at all, thus disabling all tracking for this component.


**isolate**

\`boolean | optional, defaults to false\`\n

When true, tracking label will not be nested within the parent tracking context but will start it's own new context. See [Context](#Context)


**on**

\`array or function | optional, defaults to all events\`\n

Determines which event types to track for this component.

If not specified, all interactions will be tracked.

If an array is specified, only the events in that array will be tracked.

If a function is specified, it'll be called with all events and you must return an array containing the events you want to track.


Learn more about [Events](#events) below


## Events

### Importing

Events can be imported as follows:

\`\`\`
import { EVENT_CLICK, EVENT_OPEN, EVENT_DISMISS } from '@redbubble/design-system/react/tracking';
\`\`\`


### Types

Each component will log a different subset of the following set of standard events.


\`EVENT_CLICK\`\n

The click event is the most common interaction. It encompasses clicks on things like Buttons, Links, Toggles etc.
Keyboard activation of these entities (for e.g. triggering a focused Button by hitting the spacebar) will also be tracked as a click.


\`EVENT_OPEN\`\n

The open event indicates that something has been opened / displayed. This applies to things like Modals, Drawers, Popovers and Toasts.


\`EVENT_DISMISS\`\n

The dismiss event indicates that something has been dismissed / hidden. This applies to things like Modals, Drawers, Popovers and Toasts.
Dismiss events are fired regardless of how the entity is dismissed (keyboard ESC key, clicking a backdrop, clicking a close Button etc).
`;
