import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import _styles from './styles.scss';
import * as constants from '../../constants';

const ButtonToggleField = ({
  options,
  form,
  field,
  intent,
  label,
  stacked,
  onFocus,
  onBlur,
  styles,
}) => {
  const active = options.find(o => o.value === field.value) || {};

  if (!Array.isArray(options) || options.length === 0) return null;

  return (
    <div
      className={cnames(styles.group, { [styles.stacked]: stacked })}
      role="radiogroup"
      aria-label={label}
    >
      {
        options.map((option) => {
          const classes = cnames(styles.label, styles[intent], {
            [styles.active]: active.value === option.value,
            [styles.disabled]: option.isDisabled,
          });

          return (
            // The input is nested inside the label
            /* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */
            <label
              tabIndex={-1}
              key={`${field.name}_${option.value}`}
              className={classes}
              title={option.label}
              aria-label={`${option.label}, ${label}`}
            >
              <input
                checked={active.value === option.value}
                value={option.value}
                type="radio"
                tabIndex={0}
                onFocus={onFocus}
                onBlur={(e) => {
                  onBlur(e);
                  field.onBlur(e);
                }}
                onChange={() => form.setFieldValue(field.name, option.value)}
                name={field.name}
                className={styles.radio}
                disabled={option.isDisabled}
              />
              <span className={styles.labelText}>{ option.label }</span>
            </label>
          );
        })
      }
    </div>
  );
};

ButtonToggleField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  field: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
  stacked: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  /**
    * The CSS classNames for this component. NEVER manually specify this prop
    */
  styles: PropTypes.shape({}),
  intent: PropTypes.oneOf([
    constants.INFORMATION,
    constants.ERROR,
    constants.WARNING,
    constants.SUCCESS,
    constants.NEUTRAL,
  ]),
};

ButtonToggleField.defaultProps = {
  options: [],
  stacked: false,
  onFocus: null,
  onBlur: f => f,
  styles: _styles,
  intent: constants.NEUTRAL,
};

export default ButtonToggleField;
