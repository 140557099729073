import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import Box from '../Box';
import * as constants from '../constants';
import { trackingShape, fwdTrackingWithComponent } from '../hooks/useTracking';
import _styles from './styles.scss';

const PageSection = ({
  children,
  styles,
  size,
  profile,
  backgroundColor,
  fluid,
  className, // eslint-disable-line
  style, // eslint-disable-line
  tracking,
  ...props
}) => (
  <Box display="flex" justifyContent="center" style={{ backgroundColor }}>
    <Box
      className={cnames(
        styles.section,
        {
          [styles.small]: size === constants.SMALL,
          [styles.medium]: size === constants.MEDIUM,
          [styles.large]: size === constants.LARGE,
          [styles.desktop]: profile === constants.DESKTOP,
          [styles.mobile]: profile === constants.MOBILE,
          [styles.fluid]: fluid,
        },
      )}
      tracking={fwdTrackingWithComponent(tracking, 'PageSection')}
      {...props}
    >
      { children }
    </Box>
  </Box>
);

PageSection.displayName = 'PageSection';

PageSection.propTypes = {
  /**
    * The children of the section.
    */
  children: PropTypes.node.isRequired,
  /**
    * The background color of the section
    */
  backgroundColor: PropTypes.string,
  /**
    * The CSS classNames for this component. NEVER manually specify this prop
    */
  styles: PropTypes.shape({}),
  /**
    * The maximum relative width of the centered content
    */
  size: PropTypes.oneOf([constants.SMALL, constants.MEDIUM, constants.LARGE]),
  /**
    * Pick a profile to set sensible defaults
    */
  profile: PropTypes.oneOf([constants.MOBILE, constants.DESKTOP]),
  /**
    * When true, the page section content stretches edge to edge
    */
  fluid: PropTypes.bool,
  /**
    * Tracking configuration for auto tracking
    */
  tracking: trackingShape,
};

PageSection.defaultProps = {
  styles: _styles,
  profile: constants.MOBILE,
  backgroundColor: 'transparent',
  size: constants.MEDIUM,
  fluid: false,
  tracking: null,
};

export default PageSection;
