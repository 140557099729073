import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import _styles from './styles.scss';

class Slide extends React.Component {
  render() {
    const { children, styles, inView, onUnmount, ...props } = this.props;

    return (
      <div {...props} className={cnames(styles.slideInner, inView ? '' : styles.placeholder)}>
        {inView ? children : false}
      </div>
    );
  }

  componentWillUnmount() {
    this.props.onUnmount();
  }
}

Slide.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  inView: PropTypes.bool,
  /**
    * The CSS classNames for this component. NEVER manually specify this prop
    */
  styles: PropTypes.shape({}),
};

Slide.defaultProps = {
  inView: false,
  styles: _styles,
};

export default Slide;
