import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import shortid from 'shortid';
import Image from '../Image';
import Box from '../Box';
import * as constants from '../constants';
import PageSection from '../PageSection';
import Card, { CardBody } from '../Card';

import * as GridExports from '.';

// eslint-disable-next-line
import GridSrc from '!raw-loader!.';

const { default: Grid } = GridExports;
const docs = generateDocs(GridSrc, resolver.findAllExportedComponentDefinitions);

const variableImages = new Array(15).fill(null).map((_, i) => {
  const ratio = i % 2 === 0 ? '300x400' : '300x300';
  return `https://dummyimage.com/${ratio}/000000/fff&text=${i + 1}`;
});

const fixedImages = new Array(15).fill(null).map((_, i) => {
  return `https://dummyimage.com/300x400/000000/fff&text=${i + 1}`;
});

const basicExample = {
  name: 'Simple Example',
  description: 'A grid can render items of regular or variable size, across any number of columns',
  overflowVisual: false,
  config: {
    initialValues: {
      size: 10,
      columns: 4,
      variable: 'fixed',
      spacing: 'normal',
    },
    fields: [
      {
        label: 'Items',
        name: 'size',
        type: 'select',
        options: [
          { value: 1, label: '1' },
          { value: 2, label: '2' },
          { value: 3, label: '3' },
          { value: 5, label: '5' },
          { value: 10, label: '10' },
          { value: 15, label: '15' },
        ],
      },
      {
        label: 'Columns',
        name: 'columns',
        type: 'select',
        options: [
          { value: 1, label: '1' },
          { value: 2, label: '2' },
          { value: 3, label: '3' },
          { value: 4, label: '4' },
          { value: 5, label: '5' },
          { value: 10, label: '10' },
          { value: 15, label: '15' },
        ],
      },
      // {
      //   label: 'Layout',
      //   name: 'layout',
      //   type: 'button-toggle',
      //   options: [
      //     { value: 'masonry', label: 'Masonry' },
      //     { value: 'fixed', label: 'Regular' },
      //   ],
      // },
      {
        label: 'Spacing',
        name: 'spacing',
        type: 'button-toggle',
        options: [
          { value: 'normal', label: 'Default' },
          { value: 'condensed', label: 'Condensed' },
        ],
      },
      {
        label: 'Item size',
        name: 'variable',
        type: 'button-toggle',
        options: [
          { value: 'fixed', label: 'Regular' },
          { value: 'variable', label: 'Variable' },
        ],
      },
    ],
  },
  muted: true,
  render: (r, values) => (
    <PageSection background={constants.COLOR_NEUTRAL_100}>
      <Card>
        <CardBody>
          Other content
        </CardBody>
      </Card>
      <Box marginTop="m" marginBottom="m">
        <Grid columns={values.columns} condensed={values.spacing === 'condensed'}>
          {
            /* eslint-disable react/no-array-index-key */
            new Array(values.size ? parseInt(values.size, 10) : 0).fill(null).map((_, i) => (
              <Card fluid key={shortid.generate()}>
                <Image
                  src={values.variable === 'variable' ? variableImages[i] : fixedImages[i]}
                  fluid
                />
                <Box padding="m">
                  Card {i}
                </Box>
              </Card>
            ))
          }
        </Grid>
      </Box>
      <Card>
        <CardBody>
          Other content
        </CardBody>
      </Card>
    </PageSection>
  ),
  code: (r, values) => `
<PageSection background={constants.COLOR_NEUTRAL_100}>
  <Card>
    <CardBody>
      Other content
    </CardBody>
  </Card>
  <Grid columns={${values.columns}}${values.spacing === 'condensed' ? ' condensed' : ''}>
    {
      /* eslint-disable react/no-array-index-key */
      new Array(${values.size ? parseInt(values.size, 10) : 0}).fill(null).map((_, i) => (
        <Card fluid>
          <Image
            src={\`https://dummyimage.com/300x400/000000/fff&text=$\{i + 1}\`}
            fluid
          />
          <Box padding="m">
            Card {i}
          </Box>
        </Card>
      ))
    }
  </Grid>
  <Card>
    <CardBody>
      Other content
    </CardBody>
  </Card>
</PageSection>
  `,
};

const Demo = {
  title: Grid.displayName,
  description: 'Progress bars for displaying percentage values.',
  docGen: docs,
  slug: 'Grid',
  exports: Object.keys(GridExports),
  examples: [
    basicExample,
  ],
  notes: `
  `,
};

export default Demo;
