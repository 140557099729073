import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import get from 'lodash/get';
import * as constants from '../constants';
import styles from './styles.scss';

const makeStyles = (props) => {
  const width = get(props, 'width', undefined);
  const height = props.ratio ? undefined : get(props, 'height', undefined);

  return { width, height };
};

const Skeleton = ({
  ratio,
  width,
  height,
  roundedCorners,
  circular,
  animated,
  'data-testid': testId,
}) => (
  <div
    data-testid={testId}
    style={makeStyles({ ratio, width, height })}
    className={
      cnames(
        styles.skeleton,
        {
          [styles.animated]: animated,
          [styles.roundedCorners]: roundedCorners,
          [styles.ratioSquare]: ratio === constants.RATIO_SQUARE,
          [styles.ratioPortrait]: ratio === constants.RATIO_PORTRAIT,
          [styles.ratioLandscape]: ratio === constants.RATIO_LANDSCAPE,
          [styles.circular]: circular,
        },
      )
    }
  />
);

Skeleton.displayName = 'Skeleton';

Skeleton.propTypes = {
  /**
   * Whether or not this skeleton should maintain a fixed ratio
   */
  ratio: PropTypes.oneOf([
    constants.RATIO_SQUARE,
    constants.RATIO_PORTRAIT,
    constants.RATIO_LANDSCAPE,
  ]),
  /**
   * The height of the skeleton as a valid CSS height value. If a ratio
   * is specified, this is ingored.
   */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * The width of the skeleton as a valid CSS height value.
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Round the corners of the skeleton
   */
  roundedCorners: PropTypes.bool,
  /**
   * Have the skeletons appear in a circular shape
   */
  circular: PropTypes.bool,
  /**
   * Have the skeletons show a CSS animation while active
   */
  animated: PropTypes.bool,
  /**
   * For targeting specific components in tests
   */
  'data-testid': PropTypes.string,
};

Skeleton.defaultProps = {
  ratio: null,
  width: null,
  height: null,
  roundedCorners: true,
  'data-testid': 'skeleton',
  animated: false,
  circular: false,
};

export default Skeleton;
