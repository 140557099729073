const getDataProps = (props) => {
  return Object.entries(props).reduce((dataProps, [propName, propValue]) => {
    if (propName.startsWith('data-')) {
      // eslint-disable-next-line no-param-reassign
      dataProps = { ...dataProps, [propName]: propValue };
    }

    return dataProps;
  }, {});
};

export default getDataProps;
