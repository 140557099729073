import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

// eslint-disable-next-line react/no-render-return-value
const renderApp = () => ReactDOM.render(<App />, document.getElementById('root'));

if (!global.Intl) {
  require.ensure([
    'intl',
    'intl/locale-data/jsonp/en.js',
  ], (require) => {
    require('intl');
    require('intl/locale-data/jsonp/en.js');
    renderApp();
  });
} else {
  renderApp();
}

registerServiceWorker();
