import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import _styles from './styles.scss';

const CardBody = ({ children, fluid, padded, styles, parentStyles }) => (
  <div
    className={cnames(
      styles.container,
      parentStyles.cardBody,
      {
        [styles.fluid]: fluid,
        [styles.padded]: padded,
      },
    )}
  >
    { children }
  </div>
);

CardBody.propTypes = {
  children: PropTypes.node.isRequired,
  fluid: PropTypes.bool,
  padded: PropTypes.bool,
  styles: PropTypes.shape({}),
  parentStyles: PropTypes.shape({}),
};

CardBody.defaultProps = {
  fluid: false,
  padded: true,
  styles: _styles,
  parentStyles: null,
};

CardBody.displayName = 'CardBody';

export default CardBody;
