import React from 'react';
import cnames from 'classnames';
import Box from '../../../react/Box';
import PageSection from '../../../react/PageSection';
import Text from '../../../react/Text';
// import Label from '../../../react/Label';
import useImagePalette from '../../../react/hooks/useImagePalette';
import styles from './Hero.css';
import { ReactComponent as BannerDivider } from '../../images/cloudDivider.svg';
import InteractiveBox from '../InteractiveBox';
import ArtistAttribution from '../ArtistAttribution';

import robertfarkasDesktop from './assets/606346899_desktop.jpg';
import robertfarkasMobile from './assets/606346899_mobile.jpg';
import robertfarkasThumbnail from './assets/606346899_thumbnail.jpg';

const heroCovers = [
  {
    artist: 'Robert Farkas',
    title: 'Forest triangle',
    avatar: 'https://ih0.redbubble.net/avatar.405690.140x140.jpg',
    shopUrl: 'https://www.redbubble.com/people/robertfarkas/shop',
    desktopImage: robertfarkasDesktop,
    mobileImage: robertfarkasMobile,
    thumbnail: robertfarkasThumbnail,
  },
];

// const heroIndex = Math.floor(Math.random() * Math.floor(heroCovers.length));
const heroIndex = 0;

const Hero = ({
  title,
  description,
  children,
  mobile,
}) => {
  const heroImage = mobile ? heroCovers[heroIndex].mobileImage : heroCovers[heroIndex].desktopImage;
  const { colors, loading } = useImagePalette(heroImage);

  const heroClasses = cnames(styles.heroOuter, {
    [styles.loading]: loading,
  });

  return (
    <Box
      className={heroClasses}
      style={{
        backgroundColor: colors[0],
        backgroundImage: `url(${heroImage})`,
      }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Box className={styles.overlay} />
      <PageSection>
        <Box
          className={styles.heroInner}
          display="flex"
          flexDirection="column"
          padding={mobile ? 'l' : 'xxl'}
        >
          <Box
            className={styles.heroContent}
          >
            <Text type="display1" display="block">{title}</Text>
            { description && (
              <Text type="display4" display="block" marginTop="m">{description}</Text>
            )}
            {
              !mobile && (
                <Box
                  padding="xxs"
                  paddingX="s"
                  display="inline-block"
                  className={styles.searchTip}
                  marginTop="xl"
                >
                  <Text type="body2" display="block">Press <strong>T</strong> to Search</Text>
                </Box>
              )
            }
          </Box>
          <Box className={styles.heroSpotlightOuter}>
            <PageSection
              padding={mobile ? 'none' : 'xxl'}
              paddingTop="none"
            >
              <InteractiveBox
                display="inline-block"
                element="a"
                href={heroCovers[heroIndex].shopUrl}
                target="_blank"
                marginBottom={mobile ? 'l' : 'xl'}
              >
                <ArtistAttribution
                  artist={heroCovers[heroIndex].artist}
                  title={heroCovers[heroIndex].title}
                  artwork={heroCovers[heroIndex].thumbnail}
                />
              </InteractiveBox>
              <Box className={styles.heroSpotlightInner}>
                {children}
              </Box>
            </PageSection>
          </Box>
        </Box>
      </PageSection>
      <Box className={styles.bannerDividerOuter}>
        <BannerDivider className={styles.bannerDividerInner} />
      </Box>
    </Box>
  );
};

export default Hero;
