/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, NavLink } from 'react-router-dom';
import Card, { CardBody } from '../../../../react/Card';
import Text from '../../../../react/Text';
import Box from '../../../../react/Box';
import InteractiveBox from '../../../components/InteractiveBox';
import PageIntro from '../../../components/PageIntro';
import PageTitle from '../../../components/PageTitle';
import Markdown from '../../../components/Markdown';
import home from './chapters/home';
import ourTone from './chapters/ourTone';
import ourVoice from './chapters/ourVoice';
import ourAudience from './chapters/ourAudience';
import rulesAndStuff from './chapters/rulesAndStuff';
import glossary from './chapters/glossary';
import styles from './VoiceAndTone.css';

const chapters = [
  home,
  ourVoice,
  ourTone,
  ourAudience,
  rulesAndStuff,
  glossary,
];

const VoiceAndTone = ({ match }) => {
  return (
    <React.Fragment>
      <Route
        path={`${match.url}`}
        render={(prop) => {
          const location = prop.location.pathname;
          const path = location.replace(match.path, '').replace(/\//g, '');
          const { markdown, title, slug } = chapters.find(chapter => chapter.slug === path);
          return (
            <React.Fragment key={slug}>
              <PageIntro>
                <PageTitle>{title}</PageTitle>
              </PageIntro>
              <Markdown source={markdown} />
            </React.Fragment>
          );
        }}
      />
      <Text type="display3" display="block" marginTop="xxl">Chapters</Text>
      <Box className={styles.grid} marginTop="xl">
        {
          chapters.map((chapter) => {
            const { slug, title, subTitle } = chapter;
            if (!slug) return null;
            const urlPaths = match.url.split('/').filter(Boolean);
            urlPaths.push(slug);
            return (
              <NavLink
                exact
                to={`/${urlPaths.join('/')}`}
                activeClassName={styles.itemActive}
                className={styles.item}
                key={slug}
              >
                <InteractiveBox>
                  <Card>
                    <CardBody>
                      <Text display="block" type="display3">{title}</Text>
                      <Text display="block" type="body" muted>{subTitle}</Text>
                    </CardBody>
                  </Card>
                </InteractiveBox>
              </NavLink>
            );
          })
        }
      </Box>
    </React.Fragment>
  );
};

VoiceAndTone.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default VoiceAndTone;
