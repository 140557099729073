import React from 'react';
import cnames from 'classnames';
import Box from '../../../react/Box';
import ArrowRightLargeIcon from '../../../react/Icons/ArrowRightLarge';
import styles from './InteractiveBox.css';

const InteractiveBox = ({ children, transparent, pill, className, isAnimated, ...rest }) => {
  const classnames = cnames(className, styles.interactiveBox, {
    [styles.transparent]: transparent,
    [styles.pill]: pill,
    [styles.isAnimated]: isAnimated !== false,
  });

  return (
    <Box className={classnames} {...rest}>
      {children}
    </Box>
  );
};

const InteractiveArrow = ({ display }) => {
  return (
    <Box display={display} className={styles.interactiveArrow}>
      <ArrowRightLargeIcon />
    </Box>
  );
};

export { InteractiveArrow };

export default InteractiveBox;
