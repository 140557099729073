import posed from 'react-pose';

// TODO(RD): Probably put this in a utility/helper function, as it's been copied a few times
const reducedMotion = typeof window !== 'undefined'
  && typeof window.matchMedia === 'function'
  && window.matchMedia('(prefers-reduced-motion)').matches;

// Shared transition details
const transition = {
  ease: 'easeInOut',
  duration: reducedMotion ? 0 : 400,
};

// Slides the parent sheet of the active sheet backwards slightly
export const BackOff = posed.div(({ useAnimations }) => (useAnimations !== false && {
  back: {
    x: '-20%',
    transition,
  },
  forward: {
    x: 0,
    transition,
  },
}));

// Moves sheets in from the right when they're active
export const SheetTransition = posed.div(({ useAnimations }) => (useAnimations !== false && {
  closed: {
    x: 'calc(100% + 16px)',
    transition,
  },
  open: {
    x: 0,
    transition,
  },
}));


export const AnimatedTitle = posed.div(({ useAnimations }) => (useAnimations !== false && {
  open: {
    opacity: 1,
    // beforeChildren: true,
  },
  closed: {
    opacity: 0,
    // beforeChildren: true,
  },
  back: {
    opacity: 0,
  },
  forward: {
    opacity: 1,
  },
}));

export const AnimatedTitleBar = posed.div(({ useAnimations }) => (useAnimations !== false && {
  closed: {
    x: '-90%',
    opacity: 0,
    transition,
  },
  open: {
    x: 0,
    opacity: 1,
    transition,
  },
}));
