import React, { useEffect } from 'react';
import cnames from 'classnames';
import { defineMessages } from 'react-intl';
import Box from '../../../../Box';
import MenuSection from './MenuList';
import styles from './styles.scss';
import {
  TYPE_ORDER_FOCUS,
  TYPE_ORDER_TYPING,
  POPULAR,
  RECENT,
  TRENDING,
  FALLBACK,
  ARTISTS,
  TYPING_SCREEN,
  FOCUS_SCREEN,
} from '../constants';

export const messages = defineMessages({
  [RECENT]: 'Recent searches',
  [POPULAR]: 'Popular searches',
  [TRENDING]: 'Trending searches',
  [ARTISTS]: 'Artists',
});

const Menu = ({
  types,
  prevTerm,
  withProductContext,
  getMenuProps,
  isOpen,
  onTouchStart,
  searchTerm,
  intl,
  logView,
  onTypeaheadResultClick,
  ...rest
}) => {
  const TYPE_ORDER = searchTerm ? TYPE_ORDER_TYPING : TYPE_ORDER_FOCUS;
  const screen = searchTerm ? TYPING_SCREEN : FOCUS_SCREEN;

  useEffect(() => {
    if (isOpen) logView({ inputValue: searchTerm, screen });
  }, [isOpen, screen]);

  return (
    <Box
      element="ul"
      className={cnames(styles.menu, styles.menuReset, { [styles.openMenu]: isOpen })}
      {...getMenuProps({ refKey: 'innerRef' })}
      onTouchStart={onTouchStart}
      data-testid="ds-typeahead-menu"
    >
      {
        // TYPES_ORDER ensures we iterate over the types of items in the same
        // order as Downshift has received the array of "items"
        isOpen && TYPE_ORDER.map((type, index) => {
          // When transitioning from no term to term, this is a "mode" change
          // ("focus" to "typing" which will change the visible sections, so
          // here we need them to be in sync before showing to avoid flickering.
          if (prevTerm === '' && searchTerm !== prevTerm) return null;

          const typeData = types[type] || [];

          // If there's no data for this type, render nothing
          if (!typeData.length) return null;

          // Downshift requires a continuous linear index
          // so because we're working in chunks we need to offset each chunk of items
          // by the number of items in the chunks preceding it
          let offset = 0;
          if (index > 0) {
            offset = TYPE_ORDER
              .slice(0, index)
              .reduce((count, prevType) => count + (types[prevType] || []).length, 0);
          }

          let heading = '';

          // TODO refactor this to a more readable manner
          if (!searchTerm || (searchTerm && type === ARTISTS && withProductContext)) {
            heading = messages[type] && intl.formatMessage(messages[type]);
          }

          const spacing = ((searchTerm !== '' && type === FALLBACK) || (heading && type === ARTISTS))
            ? <Box element="li" marginBottom="l" />
            : null;

          return (
            <React.Fragment key={type}>
              {spacing}
              <MenuSection
                highlight
                heading={heading}
                intl={intl}
                items={typeData}
                offset={offset}
                withProductContext={withProductContext}
                onTypeaheadResultClick={onTypeaheadResultClick}
                {...rest}
              />
              {searchTerm === '' && <Box element="li" marginBottom="l" />}
              {spacing}
            </React.Fragment>
          );
        })
      }
    </Box>
  );
};

export default Menu;
