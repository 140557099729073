import path from 'path';

const demosContext = require.context('./', true, /.demo.js$/);

const demos = demosContext.keys().reduce((memo, slug) => {
  const entry = demosContext(slug).default;
  const title = path.basename(slug).replace('.demo.js', '');
  return { ...memo, [title]: entry };
}, {});

const copyContext = require.context('./', true, /.md$/);

const copy = copyContext.keys().reduce((memo, slug) => {
  const entry = copyContext(slug);
  const title = path.basename(slug).replace('.md', '');
  return { ...memo, [title]: entry };
}, {});

export { demos, copy };
