import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import StarIcon from '../Icons/Star';
import Box from '../Box';

const GOLD = '#FFBC59';
const GREY = '#D6DADF';

/* eslint-disable react/no-array-index-key */
const StarRating = ({ rating, outOf }) => (
  <Box display="flex">
    {
      new Array(Math.round(rating)).fill(null).map((_, i) => (
        <Box key={shortid.generate()} style={{ marginRight: '2px' }}>
          <StarIcon key={`filledStar${i}`} size="small" color={GOLD} />
        </Box>
      ))
    }
    {
      new Array(outOf - Math.round(rating)).fill(null).map((_, i) => (
        <Box key={shortid.generate()} style={{ marginRight: '2px' }}>
          <StarIcon key={`emptyStar${i}`} size="small" color={GREY} />
        </Box>
      ))
    }
  </Box>
);
/* eslint-enable react/no-array-index-key */

StarRating.propTypes = {
  /**
    * How many stars out of props.outOf should be filled
    */
  rating: PropTypes.number.isRequired,
  /**
    * The upper bound of the rating
    */
  outOf: PropTypes.number,
};


StarRating.defaultProps = {
  outOf: 5,
};

StarRating.displayName = 'StarRating';

export default StarRating;
