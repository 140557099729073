import React from 'react';
import useResponsive from '../../../hooks/useResponsive';
import PageIntro from '../../../components/PageIntro';
import PageTitle from '../../../components/PageTitle';
import PageDescription from '../../../components/PageDescription';
import TextContainer from '../../../components/TextContainer';
import { PanelFooter } from '../../../components/Panel';
import IntroSection from './Intro/IntroSection';
import * as constants from '../../../../react/constants';
import Box from '../../../../react/Box';
import MediaCard from '../../../../react/MediaCard';
import Image, { generatePlaceholderSrc } from '../../../../react/Image';
import Grid from '../../../../react/Grid';
import Card from '../../../../react/Card';
import Text from '../../../../react/Text';
import Button from '../../../../react/Button';
import Tooltip from '../../../../react/Tooltip';
import ComponentUsage from '../../../components/ComponentUsage';
import Markdown from '../../../components/Markdown';
import EditIcon from '../../../../react/Icons/Edit';
// import SunIcon from '../../../../react/Icons/Sun';
import SunIcon from '../../../../react/Icons/Sun';
import ExternalLinkIcon from '../../../../react/Icons/ExternalLink';
import styles from './Illustrations.css';
import typeSpot from './assets/spot-dino.svg';
import typeSymbol from './assets/symbol-pen.svg';
import typeScene from './assets/scene-hiker.svg';
import elements from './assets/illustration-elements.svg';
import expression from './assets/illustration-expression.svg';
import principles from './assets/illustration-principles.svg';
import dontStretch from './assets/dontStretch.svg';
import dontDelete from './assets/dontDelete.svg';
import dontPixel from './assets/dontPixel.png';
import dontColor from './assets/dontColor.svg';
import doDefault from './assets/componentsDo.svg';
import painter from './assets/illo-painter.jpg';
import shipping from './assets/wwshipping.svg';
import clothingIllustration from './assets/clothing.svg';
import sun from './assets/sun.svg';
import illustrationPack from './assets/illustrationPack.jpg';
import onboarding from './assets/onboarding.jpg';
import simplify from './assets/simplify.jpg';
import clothingImage from './assets/clothingImage.jpg';

const relatedContent = [
  {
    name: 'Color guidelines',
    link: '/guidelines/color',
  },
  {
    name: 'Iconography guidelines',
    link: '/guidelines/iconography',
  },
  {
    name: 'Icon component',
    link: '/components/Icon/examples',
  },
];

const Illustrations = () => {
  const { mobile } = useResponsive();

  return (
    <Box>
      <Box className={styles.content}>
        <PageIntro>
          <PageTitle>Illustrations</PageTitle>
          <PageDescription>
            <Markdown
              source={`
                #### Illustration helps to make Redbubble feel like an inspirational, creative place. Our illustrations should contribute to the consistency and familiarity of the Redbubble experience across site, app, marketing and packaging. They can communicate nuance and emotion, and should always be created and employed with the personality traits in mind.
            `}
            />
          </PageDescription>
        </PageIntro>
        <IntroSection imageOn="right" imageSrc={principles}>
          <TextContainer>
            <Markdown
              source={`
                ### Principles

                Our illustration speaks to our adventurous and creative spirit. We play with weird and delightful creatures, and we represent diverse humans. We are sometimes conceptually left of field and surprising, but not confusing or too sophisticated.
              `}
            />
          </TextContainer>
        </IntroSection>
        <IntroSection imageOn="left" imageSrc={elements}>
          <TextContainer>
            <Markdown
              source={`
                ### Elements

                We want to communicate the feeling of a living world through our illustrations. Our lines are soft and loose to create a sense of freedom. We use outlines judiciously, letting color and white space breathe and collide.
              `}
            />
          </TextContainer>
        </IntroSection>
        <IntroSection imageOn="right" imageSrc={expression}>
          <TextContainer>
            <Markdown
              source={`
                ### Expression

                We bend the rules of proportion to add playfulness, and use off-kilter angles, white space and accents to communicate liveliness and movement. We simplfy, abstract and take liberties with reality.
              `}
            />
          </TextContainer>
        </IntroSection>

        {/* eslint-disable max-len */}
        <Box
          marginTop="xl"
        >
          <Markdown
            source={`


              ## Illustration types

              We group illustrations into three types, and use them based on our needs in different scenarios. Each varies in size, complexity, and color usage.
            `}
          />
          <Box paddingTop="xl" marginTop="m" paddingBottom="m">
            <Grid columns={mobile ? '1' : '3'}>
              <Box flex="1" flexWrap="wrap" marginBottom={mobile && '2'}>
                <Image src={typeSymbol} alt="Map with compass" fluid roundedCorners />
                <Markdown
                  source={`
                    ### Symbol

                    Simple single-focus image that works at smaller sizes. Use to direct a user’s attention and for quick reference, decision making, or confirmation.
                  `}
                />
              </Box>
              <Box flex="1" flexWrap="wrap" marginBottom={mobile && '2'}>
                <Image src={typeSpot} alt="Woman with monocular" fluid roundedCorners />
                <Markdown
                  source={`
                    ### Spot

                    More colors and greater level of detail for small to medium scale. Use to simplify complexities, chunk information, add visual balance, delight/congratulate or prompt action.
                  `}
                />
              </Box>
              <Box flex="1" flexWrap="wrap" marginBottom={mobile && '2'}>
                <Image src={typeScene} alt="Scene with spaceship" fluid roundedCorners />
                <Markdown
                  source={`
                    ### Scene

                    A celebration of our style, with more abstract or decorative concepts, for medium or large scale. Often full-bleed. Use to expand the ‘world’ of the creative wilds.
                  `}
                />
              </Box>
            </Grid>
          </Box>
        </Box>
        <Box paddingTop="xl" marginTop="m" paddingBottom="m">
          <Markdown
            source={`

          ## Libraries

          You can find our current illustrations in this Figma file. But be mindful of how to use them and ensure you've read the guidelines below first.
        `}
          />
        </Box>
        <Grid columns={mobile ? 1 : 2}>
          <Box className={styles.imageHoverContainer}>
            <MediaCard
              href="https://www.figma.com/file/nXv3wwV15kNmm9assf2mbi/"
              imageRender={() => (
                <Box className={styles.imageHover}>
                  <Image
                    fluid
                    src={illustrationPack}
                    loadOnVisible={{
                      placeholder: generatePlaceholderSrc({ width: 300, height: 100 }),
                    }}
                  />
                </Box>
              )}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                paddingLeft="xs"
              >
                <Text display="block" type="display5">
                  Figma
                </Text>
                <Box paddingLeft="m" paddingRight="xs" display="flex" alignItems="center">
                  <ExternalLinkIcon />
                </Box>
              </Box>
            </MediaCard>
          </Box>
        </Grid>
        <Box paddingTop="xl" marginTop="m">
          <Markdown
            source={`

          ## How to use Illustrations

          Exercise care when using illustration. Our illustrations represent the Redbubble brand and should be presented in a consistent and appropriate way.  Generally speaking, illustrations for internal use within Redbubble can be re-used from the libraries. Illustrations for external facing purposes should always be discussed with the creative team, and will usually be made specifically for each project.

          **Note:** When in doubt about using illustrations, contact the creative
          team. They can develop new images or help with application of illustrations from the
          libraries.
          `}
          />
        </Box>
        <Box paddingTop="xl" marginTop="m" paddingBottom="m">
          <Markdown
            source={`
          ### Composition

          Always use the entire illustration as provided from the library or illustrator.`}
          />
        </Box>
        <Grid columns={mobile ? 1 : 2}>
          <Box marginBottom="xl">
            <ComponentUsage
              type="do"
              background="neutral"
              description="Use the entire illustration as provided unless cropping of the image has been agreed on with the creative team."
            >
              <Box display="flex" justifyContent="center">
                <Image src={doDefault} fluid />
              </Box>
            </ComponentUsage>
          </Box>
          <Box marginBottom="xl">
            <ComponentUsage
              background="neutral"
              type="dont"
              description="Delete elements or combine illustrations. This goes against the intent of the images and leads to inconsistencies in our visual language."
            >
              <Box display="flex" justifyContent="center">
                <Image src={dontDelete} fluid />
              </Box>
            </ComponentUsage>
          </Box>
        </Grid>
        <Box paddingTop="xl" marginTop="m" paddingBottom="m">
          <Markdown
            source={`
          ### Scale

          Constrain proportions to the original ratio and consider the intended sizing of symbol, spot and scene illustrations.`}
          />
        </Box>
        <Grid columns={mobile ? 1 : 2}>
          <Box marginBottom="xl">
            <ComponentUsage
              type="dont"
              background="neutral"
              description="Stretch or warp the illustration to fit a space. The creative team can help with modification."
            >
              <Box display="flex" justifyContent="center">
                <Image src={dontStretch} height="201" />
              </Box>
            </ComponentUsage>
          </Box>
          <Box marginBottom="xl">
            <ComponentUsage
              background="neutral"
              type="dont"
              description="Use bitmap versions that may become pixelated."
            >
              <Box display="flex" justifyContent="center" flex="1">
                <Image src={dontPixel} height="201" />
              </Box>
            </ComponentUsage>
          </Box>
        </Grid>
        <Box paddingTop="xl" marginTop="m" paddingBottom="m">
          <Markdown
            source={`
          ### Color

Use the illustration as provided. Each illustration excepting some scene images will have a transparent background, suitable for application over white or a light neutral colour.  `}
          />
        </Box>
        <Grid columns={mobile ? 1 : 2}>
          <Box marginBottom="xl">
            <ComponentUsage
              type="dont"
              background="neutral"
              description="Change colours in the illustration. Each was carefully selected. The creative team can help with modification."
            >
              <Box display="flex" justifyContent="center">
                <Image src={dontColor} fluid />
              </Box>
            </ComponentUsage>
          </Box>
          <Box marginBottom="xl">
            <ComponentUsage
              type="caution"
              background="dark"
              description="Placing illustrations over dark or colored backgrounds can cause unwanted effects."
            >
              <Box display="flex" justifyContent="center">
                <Image src={doDefault} fluid />
              </Box>
            </ComponentUsage>
          </Box>
        </Grid>
        <Box paddingTop="xl" marginTop="m" paddingBottom="m">
          <Markdown
            source={`

          ## When to use Illustrations

          Illustration can help put Redbubble’s personality forward and engage users.
        `}
          />
        </Box>
        <Grid columns={mobile ? 1 : 2}>
          <Box marginBottom="xl">
            <ComponentUsage
              type="do"
              background="neutral"
              description="Give a friendly intro to the start of a flow or experience."
            >
              <Box display="flex" justifyContent="center">
                <Box className={styles.onboarding}>
                  <Image src={onboarding} fluid roundedCorners />
                </Box>
              </Box>
            </ComponentUsage>
          </Box>
          <Box marginBottom="xl">
            <ComponentUsage
              type="do"
              background="neutral"
              description="Use to help chunk information."
            >
              <Box display="flex" justifyContent="center">
                <Box className={styles.onboarding}>
                  <Image src={simplify} fluid roundedCorners />
                </Box>
              </Box>
            </ComponentUsage>
          </Box>
          <Box marginBottom="xl">
            <ComponentUsage
              type="do"
              background="neutral"
              description="Use it to encourage action."
            >
              <Box
                flex="1"
                style={{
                  background: `url(${painter})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'right center',
                  borderRadius: 'var(--ds-radius-small)',
                  paddingRight: '40%',
                }}
                padding="m"
              >
                <Text display="block" type="display5" marginBottom="m">
                  For every sale, an artist gets paid
                </Text>
                <Button size={constants.SMALL} strong inverse>Learn more</Button>
              </Box>
            </ComponentUsage>
          </Box>
          <Box marginBottom="xl">
            <ComponentUsage type="do" background="neutral" description="Use it to direct attention.">
              <Box flex="1">
                <Card>
                  <Box
                    display="flex"
                    padding="m"
                    alignItems="center"
                  >
                    <Image src={shipping} size={constants.SMALL} />
                    <Box paddingLeft="m">
                      <Text display="block" type="display5">
                        Sustainable
                      </Text>
                      <Text display="block" type="body2">
                        Printed just for you. Better for all.
                      </Text>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </ComponentUsage>
          </Box>
        </Grid>
        <Box paddingTop="xl" marginTop="m" paddingBottom="m">
          <Markdown
            source={`
          ### When not to use Illustrations

          Depending on the context and content. Sometimes photography or icons are more appropriate.
        `}
          />
        </Box>
        <Grid columns={mobile ? 1 : 2}>
          <Box marginBottom="xl">
            <ComponentUsage
              type="dont"
              background="neutral"
              description="Use it to represent products."
            >
              <Box flex="1" marginBottom="xl">
                <Card>
                  <Box display="flex" padding="m" alignItems="center">
                    <Image src={clothingIllustration} size={constants.SMALL} />
                    <Box paddingLeft="m">
                      <Text display="block" type="display5">
                        Clothing
                      </Text>
                      <Text display="block" type="body2">
                        Original designs by artists
                      </Text>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </ComponentUsage>
          </Box>
          <Box marginBottom="xl">
            <ComponentUsage type="do" background="neutral" description="Use photography instead.">
              <Box flex="1" marginBottom="xl">
                <Card>
                  <Box display="flex" alignItems="center">
                    <Image src={clothingImage} width="100" />
                    <Box padding="m">
                      <Text display="block" type="display5">
                        Clothing
                      </Text>
                      <Text display="block" type="body2">
                        Original designs by artists
                      </Text>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </ComponentUsage>
          </Box>
          <Box marginBottom="xl">
            <ComponentUsage
              type="dont"
              background="neutral"
              description="Don't show illustrations at a very small size."
            >
              <Image src={sun} width="32" />
            </ComponentUsage>
          </Box>
          <Box marginBottom="xl">
            <ComponentUsage
              type="do"
              background="neutral"
              description="Icons are more suitable for that."
            >
              <SunIcon />
            </ComponentUsage>
          </Box>
          <Box marginBottom="xl">
            <ComponentUsage
              type="dont"
              background="neutral"
              description="Don't use illustrations alone for actions."
            >
              <Tooltip
                placement={constants.TOP}
                trigger={triggerProps => (
                  <Button {...triggerProps} strong size={constants.LARGE}>
                    <Image src={typeSymbol} width="48" />
                  </Button>
                )}
                accessibleTitle="Upload"
              >
                Edit
              </Tooltip>
            </ComponentUsage>
          </Box>
          <Box marginBottom="xl">
            <ComponentUsage
              type="do"
              background="neutral"
              description="Icons are more suitable for that."
            >
              <Tooltip
                placement={constants.TOP}
                trigger={triggerProps => (
                  <Button {...triggerProps} circle strong size={constants.LARGE} iconAfter={<EditIcon />} />
                )}
                accessibleTitle="Upload"
              >
                Edit
              </Tooltip>
            </ComponentUsage>
          </Box>
        </Grid>
        {/* eslint-enable max-len */}

      </Box>
      <PanelFooter relatedContent={relatedContent} />
    </Box>
  );
};

export default Illustrations;
