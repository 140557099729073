import React, { useState } from 'react';
import posed from 'react-pose';
import { Link, useHistory } from 'react-router-dom';
import cnames from 'classnames';
import Box from '../../../../react/Box';
import Text from '../../../../react/Text';
import TextLink from '../../../../react/TextLink';
import List, { ListItem } from '../../../../react/List';
import Logo from '../../../../react/Logo';
import Button from '../../../../react/Button';
import HamburgerIcon from '../../../../react/Icons/Hamburger';
import CloseIcon from '../../../../react/Icons/CloseBig';
import styles from './MobileHeader.css';

const MobileHeader = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box className={styles.header}>
      <MobileNav isOpen={isOpen} setIsOpen={setIsOpen} />
      <Box className={styles.fab} marginBottom="xxl" paddingBottom="l" marginRight="xl">
        <Button
          iconBefore={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          size="large"
          intent="primary"
          strong
          onClick={() => setIsOpen(!isOpen)}
          inverse={isOpen}
          elevation="elevation-high"
        >
          {isOpen ? 'Close' : 'Menu' }
        </Button>
      </Box>
      <Box className={styles.logo} margin="l">
        <Link to="/">
          <Logo colored={false} />
        </Link>
      </Box>
    </Box>
  );
};

const NavMenu = posed.nav({
  open: { staggerChildren: 60 },
});

const NavItem = posed.div({
  start: {
    opacity: 0,
    x: '100%',
  },
  open: {
    opacity: 1,
    x: '0%',
    transition: {
      type: 'spring',
      mass: 0.4,
    },
  },
  closed: {
    opacity: 0,
    x: '150%',
  },
});

const NavLink = ({ title, href, isOpen, setIsOpen }) => {
  const history = useHistory();

  function handleClick(e) {
    e.preventDefault();
    history.push(href);
    setIsOpen(!isOpen);
  }

  return (
    <NavItem>
      <Box className={styles.navItem} display="inline-block">
        <Text type="display1">
          <TextLink onClick={handleClick}>{title}</TextLink>
        </Text>
      </Box>
    </NavItem>
  );
};

const MobileNav = ({ isOpen, setIsOpen }) => {
  return (
    <Box className={cnames(styles.mobileNavOuter, isOpen && styles.isOpen)}>
      <Box
        className={styles.underlay}
        onClick={() => setIsOpen(!isOpen)}
      />
      <Box
        className={styles.mobileNavInner}
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        paddingX="xl"
      >
        <NavMenu pose={isOpen ? 'open' : 'closed'} initalPose="start">
          <List>
            <ListItem>
              <NavLink href="/brand" title="Brand" setIsOpen={setIsOpen} isOpen />
            </ListItem>
            <ListItem>
              <NavLink href="/foundations" title="Foundations" setIsOpen={setIsOpen} isOpen />
            </ListItem>
            <ListItem>
              <NavLink href="/components" title="Components" setIsOpen={setIsOpen} isOpen />
            </ListItem>
            <ListItem>
              <NavLink href="/guides" title="Guides" setIsOpen={setIsOpen} isOpen />
            </ListItem>
          </List>
        </NavMenu>
      </Box>
    </Box>
  );
};

export default MobileHeader;
