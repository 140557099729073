import React, { Component } from 'react';
import PropTypes from 'prop-types';
import enhanceWithClickOutside from 'react-click-outside';
import { defineMessages } from 'react-intl';
import Modal from '../../../../Modal';
import Nav from './Nav';

const messages = defineMessages({
  account: '{username} account',
});

const InAModal = ({ children, onClose, intl, username }) => (
  <Modal
    accessibleTitle={intl.formatMessage(messages.account, { username })}
    open
    onCloseRequested={onClose}
    getApplicationNode={() => { return document.getElementById('root') || document.getElementById('app'); }}
  >
    {children}
  </Modal>
);

InAModal.propTypes = {
  // eslint-disable-next-line react/no-typos
  intl: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  username: PropTypes.string.isRequired,
};

InAModal.defaultProps = {
  onClose: () => {},
};

class InAPopOut extends Component {
  handleClickOutside(e) {
    this.props.onClickOutside(e);
  }

  render() {
    return this.props.children;
  }
}

InAPopOut.propTypes = {
  onClickOutside: PropTypes.func,
  children: PropTypes.node.isRequired,
};

InAPopOut.defaultProps = {
  onClickOutside: () => {},
};

const Menu = ({ isDesktop, onClose, onClickOutside, intl, username, ...rest }) => {
  const EnhancedMenu = isDesktop
    ? enhanceWithClickOutside(InAPopOut)
    : InAModal;

  return (
    <EnhancedMenu onClose={onClose} onClickOutside={onClickOutside} intl={intl} username={username}>
      <Nav intl={intl} username={username} {...rest} />
    </EnhancedMenu>
  );
};

Menu.propTypes = {
  // eslint-disable-next-line react/no-typos
  intl: PropTypes.shape({}).isRequired,
  isDesktop: PropTypes.bool,
};

Menu.defaultProps = {
  isDesktop: false,
};

export default Menu;
