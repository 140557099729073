import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import State from '@reach/component-component';
import Box from '../Box';
import StarIcon from '../Icons/Star';
import Text from '../Text';
import Label from '../Label';
import Button from '../Button';
import * as constants from '../constants';

import * as PopoverExports from '.';

// eslint-disable-next-line
import PopoverSrc from '!raw-loader!.';

const { default: Popover } = PopoverExports;
const docs = generateDocs(PopoverSrc, resolver.findAllExportedComponentDefinitions);

const basicExample = {
  name: 'Simple Example',
  description: 'A Popover will render it’s children when triggered',
  overflowVisual: true,
  render: () => (
    <Box>
      <Popover
        trigger={({ onClick }) => (
          <Button strong onClick={onClick}>Trigger</Button>
        )}
        accessibleTitle="A Popover demo"
      >
        <Box padding="m">
          The children of the Popover
        </Box>
      </Popover>
    </Box>
  ),
  code: `
<Popover
  trigger={({ onClick }) => (
    <Button strong onClick={onClick}>Trigger</Button>
  )}
  accessibleTitle="A Popover demo"
>
  <Box padding="m">
    The children of the Popover
  </Box>
</Popover>
  `,
};

const placementExample = {
  name: 'Placement',
  description: 'A Popover can be placed in different directions relative to the wrapped element.',
  overflowVisual: true,
  config: {
    initialValues: {
      placement: constants.BOTTOM,
    },
    fields: [
      {
        label: 'Placement',
        name: 'placement',
        type: 'select',
        options: [
          { value: constants.TOP, label: 'Top' },
          { value: constants.TOP_RIGHT, label: 'Top Right' },
          { value: constants.TOP_LEFT, label: 'Top Left' },
          { value: constants.BOTTOM, label: 'Bottom' },
          { value: constants.BOTTOM_RIGHT, label: 'Bottom Right' },
          { value: constants.BOTTOM_LEFT, label: 'Bottom Left' },
          { value: constants.LEFT, label: 'Left' },
          { value: constants.RIGHT, label: 'Right' },
        ],
      },
    ],
  },
  render: (_, values) => (
    <Box style={{ width: '100%', padding: '120px 80px' }} display="flex" justifyContent="center">
      <Popover
        placement={values.placement}
        trigger={({ onClick }) => (
          <Button strong onClick={onClick}> Trigger: {values.placement} </Button>
        )}
        accessibleTitle="A Popover demo"
      >
        <Box padding="m">
          Hello
        </Box>
      </Popover>
    </Box>
  ),
  code: (_, values) => `
<Box style={{ width: '100%', padding: '120px 80px' }} display="flex" justifyContent="center">
  <Popover
    placement="${values.placement}"
    trigger={({ onClick }) => (
      <Button strong onClick={onClick}>Trigger: "${values.placement}"</Button>
    )}
    accessibleTitle="A Popover demo"
  >
    <Box padding="m">
      Hello
    </Box>
  </Popover>
</Box>
  `,
};


const actionExample = {
  name: 'Actions',
  description: 'A Popover can have primary and secondary actions.',
  overflowVisual: true,
  render: () => (
    <Box>
      <Popover
        size={constants.LARGE}
        primaryAction={({ closePopover }) => (
          <Button
            onClick={closePopover}
            size={constants.SMALL}
            intent={constants.PRIMARY}
          >
            See Similar Designs
          </Button>
        )}
        secondaryAction={({ closePopover }) => (
          <Button
            onClick={closePopover}
            size={constants.SMALL}
          >
            Okay
          </Button>
        )}
        trigger={({ onClick }) => (
          <Button strong onClick={onClick}> Trigger </Button>
        )}
        accessibleTitle="A Popover with actions"
      >
        <Box padding="m">
          <Label intent={constants.INFORMATION}>Save</Label>
          <Box marginTop="xs" />
          <Text display="block" type="display3">Sticker Bundles</Text>
          <Box marginTop="xxs" />
          <Text>Add <strong>3 more stickers</strong> to get a <strong>25% discount</strong></Text>
        </Box>
      </Popover>
    </Box>
  ),
  code: `
<Popover
  size={constants.LARGE}
  primaryAction={({ closePopover }) => (
    <Button
      onClick={closePopover}
      size={constants.SMALL}
      intent={constants.PRIMARY}
    >
      See Similar Designs
    </Button>
  )}
  secondaryAction={({ closePopover }) => (
    <Button
      onClick={closePopover}
      size={constants.SMALL}
    >
      Okay
    </Button>
  )}
  trigger={({ onClick }) => (
    <Button strong onClick={onClick}> Trigger </Button>
  )}
  accessibleTitle="A Popover with actions"
>
  <Box padding="m">
    <Label intent={constants.INFORMATION}>Save</Label>
    <Box marginLeft="xs" />
    <Text display="block" type="display3">Sticker Bundles</Text>
    <Box marginLeft="xxs" />
    <Text>Add <strong>3 more stickers</strong> to get a <strong>25% discount</strong></Text>
  </Box>
</Popover>
    `,
};

const sizesExample = {
  name: 'Sizes',
  description: `
  Popovers have a dynamic height, but you can control their width.

  Choose from one of 3 predefined widths (default: medium), or specify \`fit\` to have the Popover scale to fit its content.
  `,
  overflowVisual: false,
  render: () => (
    <Box display="flex">
      {
        [
          constants.SMALL,
          constants.MEDIUM,
          constants.LARGE,
        ].map(size => (
          <Box marginRight="m">
            <Popover
              trigger={({ onClick }) => (
                <Button strong onClick={onClick}>Trigger {size} </Button>
              )}
              size={size}
              accessibleTitle="A size example:"
            >
              <Box padding="m">
                <Text>
                  A { size } Popover
                </Text>
              </Box>
            </Popover>
          </Box>
        ))
      }
      <Box marginRight="m">
        <Popover
          trigger={({ onClick }) => (
            <Button strong onClick={onClick}>Trigger fit </Button>
          )}
          fit
          accessibleTitle="A size example:"
        >
          <Box padding="m">
            <Text>
              A Popover that fits to its content..................................................
            </Text>
          </Box>
        </Popover>
      </Box>
    </Box>
  ),
  code: `
{
  [
    constants.SMALL,
    constants.MEDIUM,
    constants.LARGE,
  ].map(size => (
    <Box marginRight="m">
      <Popover
        trigger={({ onClick }) => (
          <Button strong onClick={onClick}>Trigger {size} </Button>
        )}
        size={size}
        accessibleTitle="A size example:"
      >
        <Box padding="m">
          <Text>
            A { size } Popover
          </Text>
        </Box>
      </Popover>
    </Box>
  ))
}
<Box marginRight="m">
  <Popover
    trigger={({ onClick }) => (
      <Button strong onClick={onClick}>Trigger fit </Button>
    )}
    fit
    accessibleTitle="A size example:"
  >
    <Box padding="m">
      <Text>
        A Popover that fits to its content..................................................
      </Text>
    </Box>
  </Popover>
</Box>
    `,
};

const richerExample = {
  name: 'Richer example',
  description: 'You can create quite complex interactions inside a Popover',
  overflowVisual: true,
  render: () => (
    <Box>
      <State initialState={{ rating: null }}>
        {
          ({ state, setState }) => (
            <Popover
              fit
              trigger={({ onClick }) => (
                <Button
                  strong
                  onClick={onClick}
                  circle={!state.rating}
                  iconBefore={<StarIcon size={constants.SMALL} />}
                >
                  {state.rating}
                </Button>
              )}
              accessibleTitle="Select a rating:"
            >
              {
                ({ closePopover }) => (
                  <Box padding="m">
                    <Text type="display3" display="block">Choose a rating</Text>
                    <Text type="body2" display="block">Rate your purchase experience</Text>

                    <Box display="flex" marginTop="m">
                      {
                        new Array(10).fill('').map((_, i) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Box marginRight="xs" key={i}>
                            <Button
                              size={constants.SMALL}
                              strong
                              onClick={() => {
                                setState({ rating: i + 1 });
                                closePopover();
                              }}
                            >
                              { i + 1 }
                            </Button>
                          </Box>
                        ))
                      }
                    </Box>
                  </Box>
                )
              }
            </Popover>
          )
        }
      </State>
    </Box>
  ),
  code: `
<State initialState={{ rating: null }}>
  {
    ({ state, setState }) => (
      <Popover
        fit
        trigger={({ onClick }) => (
        <Button strong onClick={onClick} circle={!state.rating} iconBefore={<StarIcon size={constants.SMALL}/>}>{state.rating}</Button>
        )}
        accessibleTitle="Select a rating:"
      >
        {
          ({ closePopover }) => (
            <Box padding="m">
              <Text type="display3" display="block">Choose a rating</Text>
              <Text type="body2" display="block">Rate your purchase experience</Text>

              <Box display="flex" marginLeft="m">
                {
                  new Array(10).fill('').map((_, i) => (
                    <Box marginRight="xs" key={i}>
                      <Button
                        size={constants.SMALL}
                        strong
                        onClick={() => {
                          setState({ rating: i + 1 });
                          closePopover();
                        }}
                      >
                        { i + 1 }
                      </Button>
                    </Box>
                  ))
                }
              </Box>
            </Box>
          )
        }
      </Popover>
    )
  }
</State>
  `,
};

const Demo = {
  title: 'Popover',
  description: 'Popovers can be used to show additional content in a light-weight popup that appears over the top of other content.',
  docGen: docs,
  slug: 'Popover',
  exports: Object.keys(PopoverExports),
  examples: [
    basicExample,
    placementExample,
    actionExample,
    sizesExample,
    richerExample,
  ],
  notes: `
  `,
};

export default Demo;
