import get from 'lodash/get';

const buildUrl = (baseUrl, params = {}) => {
  const queryString = Object.entries(params)
    .filter(([, value]) => value)
    .map(([name, value]) => `${encodeURIComponent(name)}=${encodeURIComponent(value)}`)
    .join('&');

  return `${baseUrl}/shop/${queryString && `?${queryString}`}`;
};

const buildArtistShopUrl = (baseUrl, artistName) => {
  return `${baseUrl}/people/${artistName}/shop?ref=typeahead`;
};

const doSearch = (searchTerm, productContext, baseUrl, override) => {
  if (typeof override === 'function') {
    override(searchTerm, productContext);
  } else {
    window.location = buildUrl(baseUrl, {
      query: searchTerm,
      iaCode: get(productContext, 'id', null),
      ref: 'search_box',
    });
  }
};

export {
  doSearch,
  buildUrl,
  buildArtistShopUrl,
};
