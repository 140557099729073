import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import * as LogoExports from '.';

// eslint-disable-next-line
import LogoSrc from '!raw-loader!.';

const { default: Logo } = LogoExports;
const docs = generateDocs(LogoSrc, resolver.findAllExportedComponentDefinitions);

const logo = {
  name: 'Logo',
  description: 'The default Logo is always full-color. There is also a contrast version for use on dark backgrounds.',
  muted: true,
  render: () => (
    <div style={{ maxWidth: '250px' }}>
      <Logo />
    </div>
  ),
  code: `
<Logo />
  `,
};

const logoContrast = {
  name: 'Logo contrast',
  dark: true,
  render: () => (
    <div style={{ maxWidth: '250px' }}>
      <Logo colored={false} contrast />
    </div>
  ),
  code: `
<Logo colored={false} contrast />
  `,
};

const brandMark = {
  name: 'Brandmark',
  description: 'The brandmark can be used in isolation (without the wordmark). The default is full-color and there is a contrast version for use on dark backgrounds.',
  muted: true,
  render: () => (
    <div style={{ maxWidth: '45px' }}>
      <Logo brandmarkOnly />
    </div>
  ),
  code: `
<Logo brandmarkOnly />
  `,
};

const brandMarkContrast = {
  name: 'Brandmark contrast',
  dark: true,
  render: () => (
    <div style={{ maxWidth: '45px' }}>
      <Logo brandmarkOnly colored={false} contrast />
    </div>
  ),
  code: `
<Logo brandmarkOnly colored={false} contrast />
  `,
};

const logoStacked = {
  name: 'Logo stacked',
  description: 'In some cases a stacked Logo is required. This version also defaults to full-color and has a contrast version for use of dark backgrounds.',
  muted: true,
  render: () => (
    <div style={{ maxWidth: '140px' }}>
      <Logo stacked />
    </div>
  ),
  code: `
<Logo stacked />
  `,
};

const logoStackedContrast = {
  name: 'Logo stacked contrast',
  description: '',
  dark: true,
  render: () => (
    <div style={{ maxWidth: '140px' }}>
      <Logo stacked colored={false} contrast />
    </div>
  ),
  code: `
<Logo stacked colored={false} contrast />
  `,
};

const logoBauble = {
  name: 'Logo Bauble',
  description: 'Logo with a festive bauble',
  render: () => (
    <div style={{ maxWidth: '140px' }}>
      <Logo bauble />
    </div>
  ),
  code: `
    <Logo bauble />
  `,
};

const Demo = {
  title: Logo.displayName,
  description: 'This is the Logo component.',
  docGen: docs,
  slug: 'Logo',
  exports: Object.keys(LogoExports),
  examples: [
    logo,
    logoContrast,
    brandMark,
    brandMarkContrast,
    logoStacked,
    logoStackedContrast,
    logoBauble,
  ],
  notes: `
  `,
};

export default Demo;
