import React from 'react';
import PropTypes from 'prop-types';
import parse from 'date-fns/parse';

export const format = (num) => {
  const absNum = Math.abs(num);
  return absNum < 10 ? `0${absNum}` : `${absNum}`;
};

export const timeUntil = (end) => {
  const startDate = new Date();
  const endDate = parse(end);

  const diff = (endDate - startDate) / 1000;
  if (diff <= 0) { return '00:00:00'; }

  return [
    format(Math.floor((diff / (60 * 60)))), // Hours
    format(Math.floor((diff / 60) % 60)), // Minutes
    format(Math.floor(diff % 60)), // Seconds
  ].join(':');
};

class TimeRemaining extends React.Component {
  constructor(props) {
    super(props);

    const initialTimeRemaining = props.until ? timeUntil(props.until) : null;

    this.state = {
      timeRemaining: initialTimeRemaining,
    };

    this.countdown = this.countdown.bind(this);
  }

  countdown() {
    const { until } = this.props;

    this.setState({
      timeRemaining: timeUntil(until),
    });
  }

  componentDidMount() {
    if (this.props.until) {
      this.interval = setInterval(this.countdown, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { until, children } = this.props;
    const { timeRemaining } = this.state;

    if (typeof children !== 'function') return null;

    return children(until ? timeRemaining : null);
  }
}

TimeRemaining.propTypes = {
  children: PropTypes.func.isRequired,
  until: PropTypes.string,
};

TimeRemaining.displayName = 'TimeRemaining';

export default TimeRemaining;
