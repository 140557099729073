import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import * as FormExports from '.';
import Box from '../Box';
import Input from './Input';
import Select from './Select';
import TextLink from '../TextLink';
import Text from '../Text';
import styles from './Form.scss';
/* eslint-disable */
import FormSrc from '!raw-loader!./';
import FieldRowSrc from '!raw-loader!./FieldRow';
import FieldSetSrc from '!raw-loader!./FieldSet';
import FieldSrc from '!raw-loader!./Field';
/* eslint-enable */
import FieldMessage from './FieldMessage';
import sizes from './sizes';
import * as constants from '../constants';

const {
  default: Form,
  FieldSet,
  Field,
  FieldRow,
} = FormExports;

const docs = [
  FormSrc,
  FieldSrc,
  FieldSetSrc,
  FieldRowSrc,
].map(src => generateDocs(src, resolver.findAllExportedComponentDefinitions))
  .reduce((m, d) => m.concat(d), []);

const basicExample = {
  name: 'Large Form',
  description: `
    A simple form with a mixture of input fields. <br /><br />
    <strong>DESKTOP</strong> profile allows fuzzy text searching in Select boxes. <strong>MOBILE</strong> does not.
  `,
  render: responsive => (
    <>
      <Form
        initialValues={{
          name: '',
          age: '',
          size: '',
          logo: '',
          neckline: 'crew-neck',
          shirtSize: ['xs'],
          shirtColor: '',
          description: '',
          finish: '',
        }}
        onSubmit={(form) => {
          // eslint-disable-next-line
          console.log(form.values);
        }}
        onChange={(form, oldForm) => {
          // eslint-disable-next-line
          console.log(form, oldForm);
        }}
        profile={responsive.desktop ? sizes.DESKTOP : sizes.MOBILE}
      >
        {
          form => (
            <Box display="flex" justifyContent="space-between" flexWrap="wrap">
              <Box flex="1" style={{ width: '100%' }}>
                <FieldSet>
                  <FieldRow>
                    <Field
                      label="Name"
                      type="text"
                      name="name"
                      placeholder="e.g. Sarah"
                      fluid
                    />

                    <Field
                      label="Age"
                      fluid
                      type="select"
                      name="age"
                      placeholder="Pick an age"
                      options={[
                        { value: '0-10', label: '0-10' },
                        { value: '10-20', label: '10-20' },
                        { value: '20-30', label: '20-30' },
                        { value: '30-40', label: '30-40' },
                        { value: '40-50', label: '40-50' },
                        { value: '50-60', label: '50-60' },
                        { value: '60+', label: '60+' },
                      ]}
                    />
                  </FieldRow>

                  <Field
                    label="Logo"
                    type="file"
                    name="logo"
                    fluid
                    placeholder="Upload an image"
                    accept=".jpg, .png"
                  />

                  <Field
                    label="Size"
                    type="button-toggle"
                    name="size"
                    options={[
                      { value: 's', label: 'Small' },
                      { value: 'm', label: 'Medium' },
                      { value: 'l', label: 'Large' },
                    ]}
                  />

                  <Field
                    label="Size (Single item)"
                    type="button-toggle"
                    name="sizeSingleRow"
                    fluid
                    options={[
                      { value: 's', label: 'Small' },
                    ]}
                  />

                  <Field
                    label="Finish"
                    type="radio-selector"
                    name="finish"
                    options={[
                      { value: 'glossy', label: 'Glossy' },
                      { value: 'matte', label: 'Matte' },
                      { value: 'transparent', label: 'Transparent' },
                      { value: 'striped', label: 'Striped' },
                      { value: 'rainbow', label: 'Rainbow' },
                      { value: 'walnut', label: 'Walnut' },
                      { value: 'river-red', label: 'River Red Gum' },
                    ]}
                  />

                  <Field
                    label="Description"
                    type="textarea"
                    name="description"
                    placeholder="Describe something"
                    fluid
                    resizable
                    rows="3"
                    cols="20"
                  />

                  <Field
                    label="Neckline"
                    type="radio"
                    name="neckline"
                    options={[
                      { value: 'crew-neck', label: 'Crew Neck' },
                      { value: 'v-neck', label: 'V-Neck' },
                      { value: 'scoop-neck', label: 'Scoop Neck' },
                    ]}
                  />

                  <Field
                    type="radio"
                    name="house"
                    label="House"
                    stacked
                    options={[
                      { value: 'gryffindor', label: 'Gryffindor' },
                      { value: 'ravenclaw', label: 'Ravenclaw' },
                      { value: 'hufflepuff', label: 'Hufflepuff' },
                      { value: 'slytherin', label: 'Slytherin' },
                    ]}
                  />

                  <Field
                    label="Shirt Size"
                    type="checkbox"
                    name="shirtSize"
                    stacked
                    fluid
                    options={[
                      { value: 'xs', label: 'X Small' },
                      { value: 's', label: 'Small' },
                      { value: 'm', label: 'Medium' },
                    ]}
                  />

                  <Field
                    label="Shirt Color (Multiple)"
                    type="color"
                    name="shirtColorMulti"
                    multiple
                    fluid
                    options={[
                      ['ffffff', 'white', 'White'],
                      ['f89f2b', 'yellow', 'Yellow'],
                      ['ff4c00', 'orange', 'Orange'],
                      ['f21f60', 'pink', 'Pink'],
                      ['dd2121', 'red', 'Red'],
                      ['5a1f32', 'brown', 'Brown'],
                      ['462445', 'dark-brown', 'Dark Brown'],
                    ].map(([hexColor, value, label]) => ({
                      hexColor,
                      value,
                      label,
                    }))}
                  />

                  <Field
                    label="Shirt Color"
                    type="color"
                    name="shirtColor"
                    fluid
                    options={[
                      ['ffffff', 'white', 'White'],
                      ['f89f2b', 'yellow', 'Yellow'],
                      ['ff4c00', 'orange', 'Orange'],
                      ['f21f60', 'pink', 'Pink'],
                      ['dd2121', 'red', 'Red'],
                      ['5a1f32', 'brown', 'Brown'],
                      ['462445', 'dark-brown', 'Dark Brown'],
                      ['b6b6b6', 'heather-grey', 'Heather Grey', 'https://images.ctfassets.net/1nk1ldud5dsb/heather_grey_swatch/ad635b3bc761d6412c3b10f6055b0fae/heather_grey.jpeg'],
                    ].map(([hexColor, value, label, imageUrl]) => ({
                      hexColor,
                      value,
                      label,
                      imageUrl,
                    }))}
                  />
                </FieldSet>
              </Box>
              <Box flex={1} marginLeft="xl">
                <pre className={styles.pre}>
                  {JSON.stringify(form, null, 4)}
                </pre>
              </Box>
            </Box>
          )
        }
      </Form>
    </>
  ),
  code: responsive => `
<Form
  initialValues={{
    name: '',
    age: '',
    size: '',
    logo: '',
    neckline: 'crew-neck',
    shirtSize: 'l',
  }}
  onSubmit={(values) => {
    console.log(values);
  }}
  onChange={(values) => {
    console.log(values);
  }} ${responsive.desktop ? (`
  // MOBILE would use a native mobile dropdown
  profile={constants.DESKTOP}`) : (`
  // DESKTOP would enable fuzzy searching in native desktop style drop downs.
  profile={constants.MOBILE}`)}
>
  {
    form => (
      <FieldSet>
        <FieldRow>
          <Field
            label="Name"
            type="text"
            name="name"
            placeholder="e.g. Sarah"
            fluid
          />

          <Field
            label="Age"
            type="select"
            name="age"
            placeholder="Pick an age"
            options={[
              { value: '0-10', label: '0-10' },
              { value: '10-20', label: '10-20' },
              { value: '20-30', label: '20-30' },
              { value: '30-40', label: '30-40' },
              { value: '40-50', label: '40-50' },
              { value: '50+', label: '50+' },
            ]}
          />
        </FieldRow>

        <Field
          label="Logo"
          type="file"
          name="logo"
          placeholder="Upload an image"
          accept=".jpg, .png"
        />

        <Field
          label="Size"
          type="button-toggle"
          name="size"
          options={[
            { value: 's', label: 'Small' },
            { value: 'm', label: 'Medium' },
            { value: 'l', label: 'Large' },
          ]}
        />

        <Field
        label="Size (Single item)"
        type="button-toggle"
        name="sizeSingleRow"
        fluid
        options={[
          { value: 's', label: 'Small' },
        ]}
      />

        <Field
        label="Finish"
        type="radio-selector"
        name="finish"
        options={[
          { value: 'glossy', label: 'Glossy' },
          { value: 'matte', label: 'Matte' },
          { value: 'transparent', label: 'Transparent' },
          { value: 'striped', label: 'Striped' },
          { value: 'rainbow', label: 'Rainbow' },            
          { value: 'walnut', label: 'Walnut' },
          { value: 'river-red', label: 'River Red Gum' },
        ]}
      />

        <Field
          label="Description"
          type="textarea"
          name="description"
          placeholder="Describe something"
          fluid
          resizable
          rows="3"
          cols="20"
        />

        <Field
          label="Neckline"
          type="radio"
          name="neckline"
          options={[
            { value: 'crew-neck', label: 'Crew Neck' },
            { value: 'v-neck', label: 'V-Neck' },
            { value: 'scoop-neck', label: 'Scoop Neck' },
          ]}
        />

        <Field
          type="radio"
          name="house"
          label="House"
          stacked
          options={[
            { value: 'gryffindor', label: 'Gryffindor' },
            { value: 'ravenclaw', label: 'Ravenclaw' },
            { value: 'hufflepuff', label: 'Hufflepuff' },
            { value: 'slytherin', label: 'Slytherin' },
          ]}
        />

        <Field
          label="Shirt Size"
          type="checkbox"
          name="shirtSize"
          stacked
          fluid
          options={[
            { value: 'xs', label: 'X Small' },
            { value: 's', label: 'Small' },
            { value: 'm', label: 'Medium' },
          ]}
        />

        <Field
          label="Shirt Color (Multiple)"
          type="color"
          name="shirtColorMulti"
          multiple
          fluid
          options={[
            ['ffffff', 'white', 'White'],
            ['f89f2b', 'yellow', 'Yellow'],
            ['ff4c00', 'orange', 'Orange'],
            ['f21f60', 'pink', 'Pink'],
            ['dd2121', 'red', 'Red'],
            ['5a1f32', 'brown', 'Brown'],
            ['462445', 'dark-brown', 'Dark Brown'],
          ].map(([hexColor, value, label]) => ({
            hexColor,
            value,
            label,
          }))}
        />

        <Field
          label="Shirt Color"
          type="color"
          name="shirtColor"
          fluid
          options={[
            ['ffffff', 'white', 'White'],
            ['f89f2b', 'yellow', 'Yellow'],
            ['ff4c00', 'orange', 'Orange'],
            ['f21f60', 'pink', 'Pink'],
            ['dd2121', 'red', 'Red'],
            ['5a1f32', 'brown', 'Brown'],
            ['462445', 'dark-brown', 'Dark Brown'],
          ].map(([hexColor, value, label]) => ({
            hexColor,
            value,
            label,
          }))}
        />
      </FieldSet>
    )
  }
</Form>
  `,
};


const labelSubtitle = {
  name: 'Label Subtitles',
  description: 'The <strong>labelSubtitle</strong> prop for the <strong>Field</strong> component optionally allows rendering of supplementary text next to the primary label',
  render: () => (
    <>
      <Field
        label="Primary label"
        labelSubtitle="Subtitle"
        type="text"
        name="field"
      />
      <Field
        label="Action Slot"
        labelSubtitle="Subtitle"
        actionSlot={<TextLink intent={constants.PRIMARY}>Press me</TextLink>}
        fluid
        type="text"
        name="fieldWithActionSlot"
      />

      <Field
        label="Shirt Color"
        type="color"
        labelSubtitle="Subtitle"
        name="shirtColor"
        fluid
        options={[
          ['ffffff', 'white', 'White'],
          ['f89f2b', 'yellow', 'Yellow', true],
          ['ff4c00', 'orange', 'Orange'],
          ['f21f60', 'pink', 'Pink'],
          ['dd2121', 'red', 'Red'],
          ['5a1f32', 'brown', 'Brown'],
          ['462445', 'dark-brown', 'Dark Brown'],
        ].map(([hexColor, value, label, isDisabled]) => ({
          hexColor,
          value,
          label,
          isDisabled,
        }))}
      />
    </>
  ),
  code: `
  <>
  <Field
    label="Primary label"
    labelSubtitle="Subtitle"
    type="text"
    name="field"
  />
  <Field
    label="Action Slot"
    labelSubtitle="Subtitle"
    actionSlot={<TextLink intent={constants.PRIMARY}>Press me</TextLink>}
    fluid
    type="text"
    name="fieldWithActionSlot"
  />

  <Field
    label="Shirt Color"
    type="color"
    labelSubtitle="Subtitle"
    name="shirtColor"
    fluid
    options={[
      ['ffffff', 'white', 'White'],
      ['f89f2b', 'yellow', 'Yellow', true],
      ['ff4c00', 'orange', 'Orange'],
      ['f21f60', 'pink', 'Pink'],
      ['dd2121', 'red', 'Red'],
      ['5a1f32', 'brown', 'Brown'],
      ['462445', 'dark-brown', 'Dark Brown'],
    ].map(([hexColor, value, label, isDisabled]) => ({
      hexColor,
      value,
      label,
      isDisabled,
    }))}
  />
</>
  `,
};

const singleInlineExample = {
  name: 'Single Field',
  description: 'A form with a single checkbox/input.',
  render: () => (
    <Form
      initialValues={{
        mailingList: [],
      }}
      onSubmit={(form) => {
        // eslint-disable-next-line
        console.log(form.values);
      }}
      onChange={(form, oldForm) => {
        // eslint-disable-next-line
        console.log(form, oldForm);
      }}
    >
      {
        form => (
          <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            <Box flex="1" style={{ width: '100%' }}>
              <FieldSet>
                <Field
                  label="Would you like to sign up to our emails?"
                  hideLabel
                  type="checkbox"
                  name="mailingList"
                  options={[
                    { value: 'y', label: 'Yes, I would like the emails ✉️' },
                  ]}
                />
              </FieldSet>
            </Box>
            <Box flex={1} marginLeft="xl">
              <pre className={styles.pre} style={{ minHeight: '200px' }}>
                {JSON.stringify(form.values, null, 4)}
              </pre>
            </Box>
          </Box>
        )
      }
    </Form>
  ),
  code: `
<Form
  initialValues={{
    mailingList: [],
  }}
  onSubmit={(form) => {
    // eslint-disable-next-line
    console.log(form.values);
  }}
  onChange={(form, oldForm) => {
    // eslint-disable-next-line
    console.log(form, oldForm);
  }}
>
  {
    form => (
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <Box flex="1" style={{ width: '100%' }}>
          <FieldSet>
            <Field
              label="Would you like to sign up to our emails?"
              hideLabel
              type="checkbox"
              name="mailingList"
              options={[
                { value: 'y', label: 'Yes, I would like the emails ✉️' },
              ]}
            />
          </FieldSet>
        </Box>
      </Box>
    )
  }
</Form>
  `,
};

const disabledExample = {
  name: 'Disabled Field',
  description: `
    Fields can be disabled, as can options in fields that present multiple choices.
  `,
  render: responsive => (
    <Form
      initialValues={{
        name: '',
        age: '',
        size: '',
        logo: '',
        neckline: 'crew-neck',
        shirtSize: ['xs'],
      }}
      profile={responsive.desktop ? sizes.DESKTOP : sizes.MOBILE}
    >
      {
        form => (
          <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            <Box flex="1" style={{ width: '100%' }}>
              <FieldSet>
                <FieldRow>
                  <Field
                    disabled
                    label="Name"
                    type="text"
                    name="name"
                    placeholder="e.g. Sarah"
                    fluid
                  />

                  <Field
                    disabled
                    label="Age"
                    type="select"
                    name="age"
                    placeholder="Pick an age"
                    options={[
                      { value: '0-10', label: '0-10' },
                      { value: '10-20', label: '10-20' },
                      { value: '20-30', label: '20-30' },
                      { value: '30-40', label: '30-40' },
                      { value: '40-50', label: '40-50' },
                      { value: '50+', label: '50+' },
                    ]}
                  />
                </FieldRow>

                <Field
                  disabled
                  label="Logo"
                  type="file"
                  name="logo"
                  placeholder="Upload an image"
                  accept=".jpg, .png"
                />

                <Field
                  label="Size"
                  type="button-toggle"
                  name="size"
                  options={[
                    { value: 's', label: 'Small' },
                    { value: 'm', label: 'Medium', isDisabled: true },
                    { value: 'l', label: 'Large' },
                  ]}
                />

                <Field
                  label="Finish"
                  type="radio-selector"
                  name="finish"
                  options={[
                    { value: 'glossy', label: 'Glossy' },
                    { value: 'matte', label: 'Matte', isDisabled: true },
                    { value: 'transparent', label: 'Transparent' },
                    { value: 'striped', label: 'Striped' },
                    { value: 'rainbow', label: 'Rainbow' },
                    { value: 'walnut', label: 'Walnut' },
                    { value: 'river-red', label: 'River Red Gum' },
                  ]}
                />

                <Field
                  label="Description"
                  type="textarea"
                  disabled
                  name="description"
                  placeholder="Describe something"
                  fluid
                  resizable
                  rows="3"
                  cols="20"
                />

                <Field
                  label="Neckline"
                  type="radio"
                  name="neckline"
                  options={[
                    { value: 'crew-neck', label: 'Crew Neck' },
                    { value: 'v-neck', label: 'V-Neck', isDisabled: true },
                    { value: 'scoop-neck', label: 'Scoop Neck' },
                  ]}
                />

                <Field
                  type="radio"
                  name="house"
                  label="House"
                  stacked
                  options={[
                    { value: 'gryffindor', label: 'Gryffindor' },
                    { value: 'ravenclaw', label: 'Ravenclaw' },
                    { value: 'hufflepuff', label: 'Hufflepuff', isDisabled: true },
                    { value: 'slytherin', label: 'Slytherin' },
                  ]}
                />

                <Field
                  label="Shirt Size"
                  type="checkbox"
                  name="shirtSize"
                  stacked
                  fluid
                  options={[
                    { value: 'xs', label: 'X Small' },
                    { value: 's', label: 'Small', isDisabled: true },
                    { value: 'm', label: 'Medium' },
                  ]}
                />

                <Field
                  label="Shirt Color"
                  type="color"
                  name="shirtColor"
                  fluid
                  options={[
                    ['ffffff', 'white', 'White'],
                    ['f89f2b', 'yellow', 'Yellow', true],
                    ['ff4c00', 'orange', 'Orange'],
                    ['f21f60', 'pink', 'Pink'],
                    ['dd2121', 'red', 'Red'],
                    ['5a1f32', 'brown', 'Brown'],
                    ['462445', 'dark-brown', 'Dark Brown'],
                  ].map(([hexColor, value, label, isDisabled]) => ({
                    hexColor,
                    value,
                    label,
                    isDisabled,
                  }))}
                />
              </FieldSet>
            </Box>
            <Box flex={1} marginLeft="xl">
              <pre className={styles.pre}>
                {JSON.stringify(form.values, null, 4)}
              </pre>
            </Box>
          </Box>
        )
      }
    </Form>
  ),
  code: responsive => `
<Form
  initialValues={{
    name: '',
    age: '',
    size: '',
    logo: '',
    neckline: 'crew-neck',
    shirtSize: 'l',
  }}
  onSubmit={(values) => {
    console.log(values);
  }}
  onChange={(values) => {
    console.log(values);
  }} ${responsive.desktop ? (`
  // MOBILE would use a native mobile dropdown
  profile={constants.DESKTOP}`) : (`
  // DESKTOP would enable fuzzy searching in native desktop style drop downs.
  profile={constants.MOBILE}`)}
>
  {
    form => (
      <FieldSet>
        <FieldRow>
          <Field
            label="Name"
            type="text"
            name="name"
            placeholder="e.g. Sarah"
            fluid
          />

          <Field
            label="Age"
            type="select"
            name="age"
            placeholder="Pick an age"
            options={[
              { value: '0-10', label: '0-10' },
              { value: '10-20', label: '10-20' },
              { value: '20-30', label: '20-30' },
              { value: '30-40', label: '30-40' },
              { value: '40-50', label: '40-50' },
              { value: '50+', label: '50+' },
            ]}
          />
        </FieldRow>

        <Field
          label="Logo"
          type="file"
          name="logo"
          placeholder="Upload an image"
          accept=".jpg, .png"
        />

        <Field
          label="Size"
          type="button-toggle"
          name="size"
          options={[
            { value: 's', label: 'Small' },
            { value: 'm', label: 'Medium' },
            { value: 'l', label: 'Large' },
          ]}
        />

        <Field
        label="Finish"
        type="radio-selector"
        name="finish"
        options={[
          { value: 'glossy', label: 'Glossy' },
          { value: 'matte', label: 'Matte' },
          { value: 'transparent', label: 'Transparent' },
          { value: 'striped', label: 'Striped' },
          { value: 'rainbow', label: 'Rainbow' },            
          { value: 'walnut', label: 'Walnut' },
          { value: 'river-red', label: 'River Red Gum' },
        ]}
      />

        <Field
          label="Neckline"
          type="radio"
          name="neckline"
          options={[
            { value: 'crew-neck', label: 'Crew Neck' },
            { value: 'v-neck', label: 'V-Neck' },
            { value: 'scoop-neck', label: 'Scoop Neck' },
          ]}
        />

        <Field
          type="radio"
          name="house"
          label="House"
          stacked
          options={[
            { value: 'gryffindor', label: 'Gryffindor' },
            { value: 'ravenclaw', label: 'Ravenclaw' },
            { value: 'hufflepuff', label: 'Hufflepuff' },
            { value: 'slytherin', label: 'Slytherin' },
          ]}
        />

        <Field
          label="Shirt Size"
          type="checkbox"
          name="shirtSize"
          stacked
          fluid
          options={[
            { value: 'xs', label: 'X Small' },
            { value: 's', label: 'Small' },
            { value: 'm', label: 'Medium' },
          ]}
        />

        <Field
          label="Shirt Color"
          type="color"
          name="shirtColor"
          fluid
          options={[
            ['ffffff', 'white', 'White'],
            ['f89f2b', 'yellow', 'Yellow', true],
            ['ff4c00', 'orange', 'Orange'],
            ['f21f60', 'pink', 'Pink'],
            ['dd2121', 'red', 'Red'],
            ['5a1f32', 'brown', 'Brown'],
            ['462445', 'dark-brown', 'Dark Brown'],
          ].map(([hexColor, value, label, isDisabled]) => ({
            hexColor,
            value,
            label,
            isDisabled,
          }))}
        />
      </FieldSet>
    )
  }
</Form>
  `,
};

const formValidationExample = {
  name: 'Form validation',
  description: `
Form fields can have messages displayed beneath them to guide the user through filling the form out in a valid way.

Form messages can be rendered with different intents based on the information being show and information about the field is
available to assist in determing which message to show and when to show it.
  `,
  render: responsive => (
    <Form
      profile={responsive.desktop ? constants.DESKTOP : constants.MOBILE}
      initialValues={{
        username: '',
        takenUsername: '',
        longUsername: '',
        availableUsername: '',
        style: null,
        color: '',
        secondColor: '',
        finish: '',
      }}
    >
      {
        form => (
          <FieldSet>
            <FieldRow>
              <Field
                label="Username"
                type="text"
                name="username"
              >
                <FieldMessage intent={constants.NEUTRAL}>
                  This hint is always visible
                </FieldMessage>
              </Field>
            </FieldRow>

            <FieldRow>
              <Field
                label="Focus to see hint"
                type="text"
                name="username"
              >
                {
                  field => (
                    field.focused && (
                      <FieldMessage intent={constants.INFORMATION}>
                        Only letters, numbers, and &apos;-&apos;
                        , no spaces, <b>4-15</b> characters.
                      </FieldMessage>
                    )
                  )
                }
              </Field>
            </FieldRow>

            <FieldRow>
              <Field
                label="Username"
                type="text"
                name="takenUsername"
                intent="error"
              >
                {
                  field => (
                    field.focused ? (
                      <FieldMessage intent={constants.INFORMATION}>
                        Only letters, numbers, and &apos;-&apos;
                        , no spaces, <b>4-15</b> characters.
                      </FieldMessage>
                    ) : (
                      <FieldMessage intent={constants.ERROR}>
                        Unfortunately this username is already taken.
                      </FieldMessage>
                    )
                  )
                }
              </Field>
            </FieldRow>

            <FieldRow>
              <Field
                label="Username"
                type="text"
                name="longUsername"
                intent={constants.WARNING}
              >
                {
                  field => (
                    field.focused ? (
                      <FieldMessage intent={constants.INFORMATION}>
                        Only letters, numbers, and &apos;-&apos;
                        , no spaces, <b>4-15</b> characters.
                      </FieldMessage>
                    ) : (
                      <FieldMessage intent={constants.WARNING}>
                        The username is too long.
                      </FieldMessage>
                    )
                  )
                }
              </Field>
            </FieldRow>

            <FieldRow>
              <Field
                label="Username"
                type="text"
                name="availableUsername"
                intent={constants.SUCCESS}
              >
                {
                  field => (
                    field.focused ? (
                      <FieldMessage intent={constants.INFORMATION}>
                        Only letters, numbers, and &apos;-&apos;
                        , no spaces, <b>4-15</b> characters.
                      </FieldMessage>
                    ) : (
                      <FieldMessage intent={constants.SUCCESS}>
                        The username is available.
                      </FieldMessage>
                    )
                  )
                }
              </Field>
            </FieldRow>

            <FieldRow>
              <Field
                label="Description"
                type="textarea"
                name="description"
                placeholder="Describe something"
                fluid
                resizable
                rows="3"
                cols="20"
              >
                <FieldMessage intent={constants.NEUTRAL}>
                  Tell us a bit more about yourself
                </FieldMessage>
              </Field>
            </FieldRow>

            <FieldRow>
              <Field
                label="Color"
                type="radio"
                name="color"
                options={[
                  { value: 'red', label: 'Red' },
                  { value: 'blue', label: 'Blue' },
                  { value: 'yellow', label: 'Yellow' },
                ]}
              >
                {
                  form.values.color === '' && (
                    <FieldMessage intent={constants.ERROR}>
                      Please choose an option.
                    </FieldMessage>
                  )
                }
              </Field>
            </FieldRow>

            <FieldRow>
              <Field
                label="Color"
                type="button-toggle"
                name="coloralt"
                intent={!form.values.coloralt && constants.ERROR}
                options={[
                  { value: 'red', label: 'Red' },
                  { value: 'blue', label: 'Blue' },
                  { value: 'yellow', label: 'Yellow' },
                ]}
              >
                {
                  !form.values.coloralt && (
                    <FieldMessage intent={constants.ERROR}>
                      Please choose an option.
                    </FieldMessage>
                  )
                }
              </Field>
            </FieldRow>
            <FieldRow>
              <Field
                label="Finish"
                type="radio-selector"
                name="finish"
                intent={!form.values.finish && constants.ERROR}
                options={[
                  { value: 'glossy', label: 'Glossy' },
                  { value: 'matte', label: 'Matte' },
                  { value: 'transparent', label: 'Transparent' },
                  { value: 'striped', label: 'Striped' },
                  { value: 'rainbow', label: 'Rainbow' },
                  { value: 'walnut', label: 'Walnut' },
                  { value: 'river-red', label: 'River Red Gum' },
                ]}
              >
                {
                  !form.values.finish && (
                    <FieldMessage intent={constants.ERROR}>
                      Please choose an option.
                    </FieldMessage>
                  )
                }
              </Field>
            </FieldRow>

            <FieldRow>
              <Field
                label="Style"
                type="select"
                name="style"
                placeholder="Select a style"
                intent={!form.values.style && constants.ERROR}
                options={[
                  { value: 'tallMug', label: 'Tall Mug' },
                  { value: 'classMug', label: 'Classic Mug' },
                ]}
              >
                {
                  !form.values.style && (
                    <FieldMessage intent={constants.ERROR}>
                      Please choose an option.
                    </FieldMessage>
                  )
                }
              </Field>
            </FieldRow>

            <FieldRow>
              <Field
                label="Second color"
                type="checkbox"
                name="secondColor"
                stacked
                options={[
                  { value: 'red', label: 'Red' },
                  { value: 'blue', label: 'Blue' },
                  { value: 'yellow', label: 'Yellow' },
                ]}
              >
                {
                  form.values.secondColor === '' && (
                    <FieldMessage intent={constants.ERROR}>
                      Please choose an option.
                    </FieldMessage>
                  )
                }
              </Field>
            </FieldRow>
          </FieldSet>
        )
      }
    </Form>
  ),
  code: `

    <Form
      initialValues={{
        username: '',
        takenUsername: '',
        longUsername: '',
        availableUsername: '',
        style: null,
        color: '',
        secondColor: '',
      }}
    >
      {
        form => (
          <FieldSet>
            <FieldRow>
              <Field
                label="Username"
                type="text"
                name="username"
              >
                <FieldMessage intent={constants.NEUTRAL}>
                  This hint is always visible
                </FieldMessage>
              </Field>
            </FieldRow>

            <FieldRow>
              <Field
                label="Focus to see hint"
                type="text"
                name="username"
              >
                {
                  field => (
                    field.focused && (
                      <FieldMessage intent={constants.INFORMATION}>
                        Only letters, numbers, and &apos;-&apos;
                        , no spaces, <b>4-15</b> characters.
                      </FieldMessage>
                    )
                  )
                }
              </Field>
            </FieldRow>

            <FieldRow>
              <Field
                label="Username"
                type="text"
                name="takenUsername"
                intent="error"
              >
                {
                  field => (
                    field.focused ? (
                      <FieldMessage intent={constants.INFORMATION}>
                        Only letters, numbers, and &apos;-&apos;
                        , no spaces, <b>4-15</b> characters.
                      </FieldMessage>
                    ) : (
                      <FieldMessage intent={constants.ERROR}>
                        Unfortunately this username is already taken.
                      </FieldMessage>
                    )
                  )
                }
              </Field>
            </FieldRow>

            <FieldRow>
              <Field
                label="Username"
                type="text"
                name="longUsername"
                intent={constants.WARNING}
              >
                {
                  field => (
                    field.focused ? (
                      <FieldMessage intent={constants.INFORMATION}>
                        Only letters, numbers, and &apos;-&apos;
                        , no spaces, <b>4-15</b> characters.
                      </FieldMessage>
                    ) : (
                      <FieldMessage intent={constants.WARNING}>
                        The username is too long.
                      </FieldMessage>
                    )
                  )
                }
              </Field>
            </FieldRow>

            <FieldRow>
              <Field
                label="Username"
                type="text"
                name="availableUsername"
                intent={constants.SUCCESS}
              >
                {
                  field => (
                    field.focused ? (
                      <FieldMessage intent={constants.INFORMATION}>
                        Only letters, numbers, and &apos;-&apos;
                        , no spaces, <b>4-15</b> characters.
                      </FieldMessage>
                    ) : (
                      <FieldMessage intent={constants.SUCCESS}>
                        The username is available.
                      </FieldMessage>
                    )
                  )
                }
              </Field>
            </FieldRow>

            <FieldRow>
              <Field
                label="Description"
                type="textarea"
                name="description"
                placeholder="Describe something"
                fluid
                resizable
                rows="3"
                cols="20"
              >
                <FieldMessage intent={constants.NEUTRAL}>
                  Tell us a bit more about yourself
                </FieldMessage>
              </Field>
            </FieldRow>

            <FieldRow>
              <Field
                label="Color"
                type="radio"
                name="color"
                options={[
                  { value: 'red', label: 'Red' },
                  { value: 'blue', label: 'Blue' },
                  { value: 'yellow', label: 'Yellow' },
                ]}
              >
                {
                  form.values.color === '' && (
                    <FieldMessage intent={constants.ERROR}>
                      Please choose an option.
                    </FieldMessage>
                  )
                }
              </Field>
            </FieldRow>

            <FieldRow>
              <Field
                label="Color"
                type="button-toggle"
                name="coloralt"
                intent={!form.values.coloralt && constants.ERROR}
                options={[
                  { value: 'red', label: 'Red' },
                  { value: 'blue', label: 'Blue' },
                  { value: 'yellow', label: 'Yellow' },
                ]}
              >
                {
                  !form.values.coloralt && (
                    <FieldMessage intent={constants.ERROR}>
                      Please choose an option.
                    </FieldMessage>
                  )
                }
              </Field>
            </FieldRow>

            <FieldRow>
              <Field
                label="Style"
                type="select"
                name="styleselect"
                placeholder="Select a style"
                intent={!form.values.styleselect && constants.ERROR}
                options={[
                  { value: 'tallMug', label: 'Tall Mug' },
                  { value: 'classMug', label: 'Classic Mug' },
                ]}
              >
                {
                  !form.values.styleselect && (
                    <FieldMessage intent={constants.ERROR}>
                      Please choose an option.
                    </FieldMessage>
                  )
                }
              </Field>
            </FieldRow>

            <FieldRow>
              <Field
                label="Second color"
                type="checkbox"
                name="secondColor"
                stacked
                options={[
                  { value: 'red', label: 'Red' },
                  { value: 'blue', label: 'Blue' },
                  { value: 'yellow', label: 'Yellow' },
                ]}
              >
                {
                  form.values.secondColor === '' && (
                    <FieldMessage intent={constants.ERROR}>
                      Please choose an option.
                    </FieldMessage>
                  )
                }
              </Field>
            </FieldRow>
          </FieldSet>
        )
      }
    </Form>
  `,
};

const standaloneExample = {
  name: 'Standalone Input',
  description: `
For situations where you want to use form input elements outside of the context of a Form, you can use the \`Input\` component. Note that unlike \`Field\`, these inputs are not inherently accessible.

[Learn more about the Input component](/components/form/api-docs#standalone-inputs)
  `,
  carded: true,
  render: responsive => (
    <Box>
      <Box marginBottom="m">
        <Input type="text" name="demo-text" placeholder="Text input" />
      </Box>

      <Box marginBottom="m">
        <Input type="email" name="demo-email" placeholder="Email input" />
      </Box>

      <Box marginBottom="m">
        <Input intent={constants.SUCCESS} type="email" name="demo-email" placeholder="Input supports intent" />
      </Box>

      <Box marginBottom="m">
        <Input fluid type="email" name="demo-email" placeholder="Input supports fluid" />
      </Box>

      <Box marginBottom="m">
        <Text type="display5" display="block" marginBottom="xs">Regular Select</Text>
        <Select
          name="age"
          placeholder="Pick an age"
          profile={responsive.desktop ? sizes.DESKTOP : sizes.MOBILE}
          options={[
            { value: '0-10', label: '0-10' },
            { value: '10-20', label: '10-20' },
            { value: '20-30', label: '20-30' },
          ]}
        />
      </Box>
      <Box marginBottom="m">
        <Text type="display5" display="block" marginBottom="xs">Fluid Select</Text>
        <Select
          name="age"
          fluid
          placeholder="Pick an age"
          profile={responsive.desktop ? sizes.DESKTOP : sizes.MOBILE}
          options={[
            { value: '0-10', label: '0-10' },
            { value: '10-20', label: '10-20' },
            { value: '20-30', label: '20-30' },
          ]}
        />
      </Box>

      <Box marginBottom="m" display="flex">
        <Input type="checkbox" name="demo-checkbox-a" />
        <Box marginRight="xl" />
        <Input type="checkbox" name="demo-checkbox-b" disabled />
        <Box marginRight="xl" />
        <Input type="checkbox" name="demo-checkbox-c" defaultChecked />
      </Box>

      <Box marginBottom="m" display="flex">
        <Input type="radio" name="demo-radio" value="a" />
        <Box marginRight="xs" />
        <Input type="radio" name="demo-radio" value="b" />
        <Box marginRight="xs" />
        <Input type="radio" name="demo-radio" value="c" disabled />
        <Box marginRight="xs" />
        <Input type="radio" name="demo-radio" value="d" defaultChecked />
      </Box>

      <Box marginBottom="m">
        <Input type="file" name="demo-file" placeholder="Choose a file" />
      </Box>
    </Box>
  ),
  code: responsive => `
<Box>
  <Box marginBottom="m">
    <Input type="text" name="demo-text" placeholder="Text input" />
  </Box>

  <Box marginBottom="m">
    <Input type="email" name="demo-email" placeholder="Email input" />
  </Box>

  <Box marginBottom="m">
    <Input intent={constants.SUCCESS} type="email" name="demo-email" placeholder="Input supports intent" />
  </Box>

  <Box marginBottom="m">
    <Input fluid type="email" name="demo-email" placeholder="Input supports fluid" />
  </Box>

  <Box marginBottom="m">
    <Text type="display5" display="block" marginBottom="xs">Regular Select</Text>
    <Select
      name="age"
      placeholder="Pick an age"
      ${responsive.desktop ? (`
      // MOBILE would use a native mobile dropdown
      profile={constants.DESKTOP}`) : (`
      // DESKTOP would enable fuzzy searching in native desktop style drop downs.
      profile={constants.MOBILE}`)}
      options={[
        { value: '0-10', label: '0-10' },
        { value: '10-20', label: '10-20' },
        { value: '20-30', label: '20-30' },
      ]}
    />
  </Box>
  <Box marginBottom="m">
    <Text type="display5" display="block" marginBottom="xs">Fluid Select</Text>
    <Select
      name="age"
      fluid
      placeholder="Pick an age"
      ${responsive.desktop ? (`
      // MOBILE would use a native mobile dropdown
      profile={constants.DESKTOP}`) : (`
      // DESKTOP would enable fuzzy searching in native desktop style drop downs.
      profile={constants.MOBILE}`)}
      options={[
        { value: '0-10', label: '0-10' },
        { value: '10-20', label: '10-20' },
        { value: '20-30', label: '20-30' },
      ]}
    />
  </Box>

  <Box marginBottom="m" display="flex">
    <Input type="checkbox" name="demo-checkbox-a" />
    <Box marginRight="xl" />
    <Input type="checkbox" name="demo-checkbox-b" disabled />
    <Box marginRight="xl" />
    <Input type="checkbox" name="demo-checkbox-c" defaultChecked />
  </Box>

  <Box marginBottom="m" display="flex">
    <Input type="radio" name="demo-radio" value="a" />
    <Box marginRight="xs" />
    <Input type="radio" name="demo-radio" value="b" />
    <Box marginRight="xs" />
    <Input type="radio" name="demo-radio" value="c" disabled />
    <Box marginRight="xs" />
    <Input type="radio" name="demo-radio" value="d" defaultChecked />
  </Box>

  <Box marginBottom="m">
    <Input type="file" name="demo-file" placeholder="Choose a file" />
  </Box>
</Box>
  `,
};

const Demo = {
  title: Form.displayName,
  description: `
  Forms are used for configuration or collecting user data.
      `,
  slug: 'form',
  exports: Object.keys(FormExports),
  docGen: docs,
  examples: [
    basicExample,
    singleInlineExample,
    labelSubtitle,
    disabledExample,
    formValidationExample,
    standaloneExample,
  ],
  notes: `
### Using \`profile\` for Desktop and Mobile forms

Setting a \`DESKTOP\` profile will enable users to type into a \`<Field type="select" />\` and fuzzy find matching options.

Setting a \`MOBILE\` profile will disable this feature so that users aren't
presented with a keyboard when interacting with select fields on mobile

In the future, we may tweak form behaviour further between profiles, so it's *worthwhile setting them*.

### Field Action

Fields accept an \`actionSlot\`. This is an opens lot for you to render something into, to the right of the field's label.

It should be reserved for links/actions relating to that field.

### Field Types

The \`<Field />\` component accepts a \`type\` prop which determines the type of input control that is rendered.

##### Text Inputs

The following types will render as a text input field: \`text\`, \`email\`, \`tel\`.

\`\`\`
<Field type="text" name="firstName" label="First Name" />
\`\`\`

Text input is also the default type, for when no input type is specified.

The \`text\` input type also has \`autoComplete\` and \`autoCapitalize\` which are optional props and are set to \`on\` as default.

The \`autoComplete\` prop set to \`on\`, allows the browser to automatically complete the input.

The \`autoCapitalize\` prop set to \`on\`, allows the first letter of each sentence to default to a capital letter and all other to letters default to lowercase.

##### Select Inputs

The \`select\` type will render as a Select dropdown control. It accepts a list of \`options\` each with a \`value\` and \`label\`.

\`\`\`
<Field
  type="select"
  name="subscription"
  label="Subscription"
  options={[
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'annually', label: 'Anually' },
  ]}
/>
\`\`\`

##### Button Toggle Inputs

The \`button-toggle\` type will render as a Button Toggle control. It accepts a list of \`options\` each with a \`value\`, \`label\` and optionally \`isDisabled\`

\`\`\`
<Field
  type="button-toggle"
  name="subscription"
  label="Subscription"
  options={[
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'annually', label: 'Anually' },
    { value: 'millenially', label: 'Millenially', isDisabled: true },
  ]}
/>
\`\`\`

The \`button-toggle\` type can optionally be \`stacked\` vertically using the \`stacked\` prop.

##### Radio Selector Inputs

The \`radio-selector\` type will render as a Radio Selector control. It accepts a list of \`options\` each with a \`value\`, \`label\` and optionally \`isDisabled\`

\`\`\`
<Field
  type="radio-selector"
  name="finish"
  label="Finish"
  options={[
    { value: 'oak', label: 'Oak' },
    { value: 'walnut', label: 'Walnut' },
    { value: 'river-red', label: 'River Red Gum' },
    { value: 'eucalyptus', label: 'Eucalyptus', isDisabled: true },
  ]}
/>
\`\`\`

The \`radio-selector\` is almost identical to \`button-toggle\` except for appearance.

##### Radio Inputs

The \`radio\` type will render as a group of radio inputs.

- The \`label\` prop is mandatory, and will render for the whole group of radios.
- The \`hideLabel\` prop is optional, and will visually hide the label, but still render it in the page for accessibility purposes.
- The \`options\` prop accepts an array of objects, each with a \`value\`, \`label\` and optionally \`isDisabled\`
- The \`stacked\` prop is optional, and will display them vertically.
- The \`fluid\` prop is optional, and will make each radio option take as much space as available

\`\`\`
<Field
  type="radio"
  name="house"
  label="House"
  options={[
    { value: 'gryffindor', label: 'Gryffindor' },
    { value: 'ravenclaw', label: 'Ravenclaw' },
    { value: 'hufflepuff', label: 'Hufflepuff' },
    { value: 'slytherin', label: 'Slytherin', isDisabled: true },
  ]}
/>
\`\`\`

##### Checkboxes

The \`checkbox\` input has the exact same API as Radios and Button Toggles, you just need to specify the \`type='checkbox'\` prop

\`\`\`
<Field
  type="checkbox"
  name="subjects"
  label="Subjects"
  options={[
    { value: 'transfiguration', label: 'Transfiguration' },
    { value: 'charms', label: 'Charms' },
    { value: 'potions', label: 'Potions' },
    { value: 'divination', label: 'Divination', isDisabled: true },
  ]}
/>
\`\`\`

##### TextAreas

The \`textarea\` field is used for entering multiple lines of text.

\`\`\`
<Field
  type="textarea"
  name="description"
  label="Description"
  rows="3"
  resizable
/>
\`\`\`

The \`textarea\` field also supports the following props:

- **maxLength**: Specifies a maximum number of characters the TextArea can contain
- **minLength**: Specifies a minimum number of characters the TextArea can contain
- **cols**: The visible width of the text control, in average character widths.
- **rows**: The number of visible lines of text
- **wrap**: How text should wrap: \`hard\`, \`soft\` or \`off\`.
- **resizable**: Whether or not the TextArea should be resizable by the user (default: false)


### Reset a form

To reset a form, use the \`resetForm\` function provided on the \`form\` object in the render prop:

\`\`\`
{
<Form initialValues={{ name: '' }}>
  {
    (form) => (
      <FieldSet>
        ...fields...
        <button
          onClick={() => form.resetForm(form.initialValues)}
        >
          Reset
        </button>
      </FieldSet>
    )
  }
</Form>
\`\`\`

### Standalone Inputs

> **Note**
> Whilst we support the use of these standalone elements, we encourage you to use them with care. Without proper treatement and due-diligence these inputs can create interfaces that are **not accessible**.
>
> We recommend you use the \`Form\` and it's associated \`Field\`s wherever possible.

When you need to render a form control on it's own without being coupled to \`Form\`, \`Field\` or their underlying implementation, you can use one of the following components:

\`\`\`
import Input from '@redbubble/design-system/react/Form/Input';
import TextArea from '@redbubble/design-system/react/Form/TextArea';
\`\`\`

**Input**

Currently it supports the following types:

- file
- checkbox
- radio
- text
- email
- tel
- number
- password

All props passed to the \`Input\` will be forwarded to a native \`input\` behind the scenes with the exception of \`className\` to prevent styling overrides.

Additionally, \`Input\` supports two custom props. Note the following do not apply to radio or checkbox inputs:

- **fluid**: Makes the input stretch to fill it's container
- **intent**: Use color to indicate different intents / states (e.g. error state)

**TextArea**

All props passed to the \`TextArea\` will be forwarded to a native \`textarea\` behind the scenes with the exception of \`className\` to prevent styling overrides.

Additionally, \`TextArea\` supports these custom props:

- **fluid**: Makes the input stretch to fill it's container
- **intent**: Use color to indicate different intents / states (e.g. error state)
- **resizable**: Whether or not the TextArea should be resizable by the user (default: false)

### A note on the implementation

Our forms are based on the [formik](https://jaredpalmer.com/formik/) library with a slightly simplified interface.

Presently, we **don't** support all of Formiks features including:

- Validation
- Form submission
- Dirty checking

These features will be made available in the near future.

For now, forms are purely an interface with an \`onChange\` hook to subscribe to their state.

  `,
};

export default Demo;
