import { useState, useEffect } from 'react';
import get from 'lodash/get';
import useRecentSearches from './useRecentSearches';
import useTypeaheadData from './useTypeaheadData';
import useCompletions from './useCompletions';
import { analyticsPayload } from '../../../lib/analytics';
import {
  validProductContext,
  formatRecent,
  formatShowAll,
} from '../utils';
import {
  RECENT,
  TYPE_ORDER_FOCUS,
  TYPE_ORDER_TYPING,
} from '../constants';

const RECENT_SEARCH_HIST_SIZE = 5;
const RECENT_SEARCH_NAMESPACE = 'typeahead-recent-searches';

export default function useTypeahead({
  term,
  defaultProductContextIaCode,
  defaultProductContextLabel,
  providedDoSearch,
  locale,
  intl,
  logEvent,
  onSearchOpen,
  onSearchClose,
  initialIsOpen,
  profile,
  baseUrl,
  blurInputOnClose,
  onSearchCallback,
}) {
  const productContextFromProps = {
    id: defaultProductContextIaCode,
    label: defaultProductContextLabel,
  };

  const defaultProductContext = validProductContext(productContextFromProps)
    ? productContextFromProps
    : null;

  const [currentInputValue, setCurrentInputValue] = useState(term);
  const [productContext, setProductContext] = useState(defaultProductContext);

  const productContextIaCode = get(productContext, 'id', null);
  const productContextLabel = get(productContext, 'label', '');

  const {
    popular,
    trending,
    artists,
    topArtists,
    currentSearchTerm,
    topArtistsLoading,
    popularProducts,
    quickLinks,
    otherLinks,
  } = useTypeaheadData(
    currentInputValue,
    locale,
    productContextIaCode,
    baseUrl,
    profile,
    logEvent,
  );

  const {
    recentSearches,
    storeAsRecentSearch,
    deleteRecentSearch,
  } = useRecentSearches(
    RECENT_SEARCH_HIST_SIZE,
    RECENT_SEARCH_NAMESPACE,
  );

  const types = {
    recent: formatRecent(recentSearches, RECENT, deleteRecentSearch),
    popular,
    trending,
    artists,
    topArtists,
    ...formatShowAll(
      popular,
      currentSearchTerm,
      productContextIaCode,
      intl,
    ),
  };

  const TYPE_ORDER = currentInputValue ? TYPE_ORDER_TYPING : TYPE_ORDER_FOCUS;
  const items = TYPE_ORDER.reduce((memo, type) => {
    return [...memo, ...types[type]];
  }, []);

  const prevTerm = currentSearchTerm;

  const doSearch = (searchTerm, context) => {
    storeAsRecentSearch({ term: searchTerm, context });
    providedDoSearch(searchTerm, context);
  };

  const logView = ({ inputValue = '', screen }) => {
    logEvent({
      analytics: analyticsPayload.typeaheadExperienceViewed({
        inputValue,
        productContextIaCode,
        screen,
      }),
    });
  };

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    closeMenu,
    setInputValue,
    inputProps,
    logItemClicked,
  } = useCompletions({
    term,
    productContext,
    initialIsOpen,
    items,
    storeAsRecentSearch,
    doSearch,
    currentInputValue,
    setCurrentInputValue,
    profile,
    onSearchClose,
    onSearchOpen,
    logEvent,
    blurInputOnClose,
    onSearchCallback,
  });

  // Search term (what the user typed, as a string + array of words)
  const searchTerm = inputProps.value || '';
  const searchWords = searchTerm.split(' ');

  // If there's no search term yet and the menu isn't open
  // show the discovery page
  const discoveryMode = !searchTerm && !isOpen;

  // If there is a product context, show it on the menu screens
  // but not on discovery
  const showProductContext = productContextIaCode && !discoveryMode;

  // These effect is needed for desktop
  useEffect(() => {
    let updatable = true;
    if (updatable) {
      setProductContext(defaultProductContext);
      setCurrentInputValue(term);
      setInputValue(term);
    }
    return () => { updatable = false; };
  }, [term, defaultProductContextIaCode, defaultProductContextLabel]);

  return {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    showProductContext,
    productContextLabel,
    inputProps,
    types,
    searchWords,
    searchTerm,
    productContextIaCode,
    logView,
    discoveryMode,
    logItemClicked,
    setProductContext,
    currentInputValue,
    prevTerm,
    doSearch,
    closeMenu,
    setInputValue,
    items,
    popularProducts,
    quickLinks,
    otherLinks,
    topArtists,
    topArtistsLoading,
  };
}
