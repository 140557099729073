/* eslint-disable max-len */
import React from 'react';
import Markdown from '../../../components/Markdown';

const WEBPACK_URL = 'https://github.com/redbubble/design/tree/master/WEBPACK_GUIDE.md#fonts';

const Typography = () => {
  return (
    <Markdown>
      {`
# Getting Started

The Redbubble Design System is delivered as a single package.

The package exports a set of rich React components and patterns as well as some core CSS and CSS custom properties.

## Installation

To use the Design System, first install it with **yarn** or **npm**:

\`\`\`
  yarn add @redbubble/design-system
\`\`\`

## Webpack

The Design System package contains the source code of our React components and styles. Since this code is not precompiled, the design system relies heavily on Webpack.

### Boom Applications

Apps built on the boom-platform will support importing Design System components out of the box.
You’ll need \`boom-cli@6.6.0\`.

### Non-Boom Applications

You’ll need to follow our [Webpack Configuration guide](${WEBPACK_URL}) to configure your app.

## Styles

### Core CSS and Fonts

The design system components rely on a simple CSS reset. We use **normalize.css** under the hood with a few minor adjustments.
Our core CSS also includes some critical \`font-face\` rules to ensure you’re using the design system font.

Include the core stylesheet **ONCE** at the root level of your app’s CSS.

\`\`\`
// e.g. my_app/app.css

@import '~@redbubble/design-system/styles/core.scss';
\`\`\`

### Component Styles and Theming

The Design System requires you to provide a theme at the root level which governs the look and feel of components below it in the tree.

At the very least, it is **required** that you import and configure the default theme.

To do so, import the \`DesignSystemProvider\` and the \`defaultTheme\` then wrap your app with the provider, passing it a config option that includes the theme.

[Read more about Theming](/guidelines/theming)

\`\`\`
// e.g. my_app/app.js

import theme from '@redbubble/design-system/react/themes/default';
import DesignSystemProvider from '@redbubble/design-system/react/DesignSystemProvider';

const App = (props) => (
  <DesignSystemProvider config={{ theme }}>
    <div>
      Your app tree
    </div>
  </DesignSystemProvider>
);
\`\`\`

${''
// ### CSS Custom Properties
//
// We export a set of CSS custom properties (variables) to help you stay consistent with the design system when building your own UIs.
//
// When you configure a theme (see above) you will automatically have access to that theme's proprties. You can use them in your CSS with the \`var()\` function:
//
// \`\`\`
// // e.g. my_app/components/MyCoolWidget.css
//
// .someRule {
//   color: var(--colors-primary);
// }
// \`\`\`
//
// View the complete list of available custom properties [here](https://github.com/redbubble/design/tree/master/react/themes/default.js).
}

# React

Once you’ve configured Webpack, our React components work like any other React component, simply import and use them in your app:

\`\`\`
import Text from '@redbubble/design-system/react/Text';

<Text type="display3">Text goes here</Text>
\`\`\`
`}
    </Markdown>
  );
};

export default Typography;
