import React from 'react';
import Box from '../../../../../../react/Box';

export default () => (
  <Box display="flex" flexDirection="column" flex="1">
    <Box
      padding="xl"
      flex="1"
      style={{ backgroundColor: 'var(--ds-color-background-app)' }}
    />
    <Box
      padding="xl"
      flex="1"
      style={{ backgroundColor: 'var(--ds-color-background-app-light)' }}
    />
    <Box
      padding="xl"
      flex="1"
      style={{ backgroundColor: 'var(--ds-color-background-app-dark)', borderRadius: '0 0 var(--ds-radius-medium) var(--ds-radius-medium)' }}
    />
  </Box>
);
