import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {
  handleMouseDown,
  ACTION_TYPE_RELATIVE_NAME,
  ACTION_TYPE_ABSOLUTE_NAME,
} from '../utils';

const ItemLink = ({ children, action, logEvent, onMouseDown, ...rest }) => {
  const actionUrl = get(action, 'parameters.url', null);

  return (
    <a
      href={actionUrl}
      onMouseDown={() => {
        if (onMouseDown) onMouseDown();
        handleMouseDown(actionUrl, logEvent);
      }}
      {...rest}
    >
      {children}
    </a>
  );
};

ItemLink.propTypes = {
  children: PropTypes.node.isRequired,
  action: PropTypes.shape({
    type: PropTypes.string,
    parameters: PropTypes.shape({
      urlType: PropTypes.oneOf([ACTION_TYPE_RELATIVE_NAME, ACTION_TYPE_ABSOLUTE_NAME]),
      url: PropTypes.string,
    }),
  }),
  tag: PropTypes.string,
  logEvent: PropTypes.func,
  onMouseDown: PropTypes.func,
};

ItemLink.defaultProps = {
  action: null,
  logEvent: () => {},
};

export default ItemLink;
