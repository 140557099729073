/* eslint-disable max-len */
import React from 'react';
import MarkdownContents from 'markdown-contents';
import Markdown from '../../../components/Markdown';
import TabBar, { Tab } from '../../../components/TabBar';

const devGuide = `
## Usage

If you followed the [Getting Started](/guidelines/getting-started) and wrapped your application in the \`DesignSystemProvider\` then you are already using a theme, most likely the default theme.

Here's a reminder on how to do that:

\`\`\`
// e.g. my/app/app.js

import theme from '@redbubble/design-system/react/themes/default';
import DesignSystemProvider from '@redbubble/design-system/react/DesignSystemProvider';

const App = (props) => (
  <DesignSystemProvider config={{ theme }}>
    <div>
      Your app tree
    </div>
  </DesignSystemProvider>
);
\`\`\`

## How does it work?

When you pass a theme to the \`DesignSystemProvider\`, it injects the [Design Tokens](/guidelines/design-tokens) for that theme onto the \`:root\` of your application.

As a result, all the Design System components will now be styled by those tokens. Different tokens result in a different appearance for components.

You will most likely have to use some tokens in your own styles for the little edge cases not covered by Design System components.

## Switching themes

You can change the theme of your application by passing a different theme to the \`DesignSystemProvider\`. You can do this at build time or runtime.

Changing themes at runtime means you can allow users to toggle them live in the browser. If you load numerous themes, it might be worth considering a lazy loading strategy for the themes so they're only imported on demand.

Below is an example of toggling between the default theme and a dark version of it:

\`\`\`
// e.g. my/app/app.js

import defaultTheme from '@redbubble/design-system/react/themes/default';
import defaultThemeDark from '@redbubble/design-system/react/themes/defaultDark';
import DesignSystemProvider from '@redbubble/design-system/react/DesignSystemProvider';

const App = (props) => {
  const [theme, setTheme] = useState(defaultTheme);

  return (
    <DesignSystemProvider config={{ theme }}>
      <Box>
        <Button
          onClick={() => setTheme(defaultThemeDark)
        >
          Turn Dark Mode on
        </Button>
      </Box>
    </DesignSystemProvider>
  );
}
\`\`\`

## Authoring

When creating tokens, try and adhere to the specified token set and consider the different themes.
There are some circumstances where a component within the Design System needs a token bespoke to that component, and should not be overridden by a theme.
In this scenario, a token should be prefixed with \`--ds-local\`, to make it clear they are local to the file.

For example, the Table component is one such use case. You'll see that \`var(--ds-local-table-cell-background)\` is used > 3 times:

- \`var(--ds-table-body-background, var(--ds-local-table-cell-background))\`
- \`var(--ds-table-placeholder-background, var(--ds-local-table-cell-background))\`
- \`var(--ds-table-cell-background, var(--ds-local-table-cell-background))\`

## Themes in Playroom

The Redbubble Design System [Playroom](https://play.redbubble.design) supports themes - you'll find that previews are visible for each theme and browser size.

## Authoring themes

For the time being, themes are only authored by the Design System team. We look forward to publishing documentation here in the near future on how to author your own themes.
`;

const toc = MarkdownContents(devGuide);

const Theming = () => {
  return (
    <React.Fragment>
      <Markdown>
        {`
# Theming

Themes are predefined sets of [design tokens](/guidelines/design-tokens) that represent a visual language / style.
They allow us to render the same semantic UI with entirely different aesthetics.
  `}
      </Markdown>

      <TabBar rootPath="/guides/theming">
        <Tab label="Developer Guide">
          <Markdown>
            { toc.markdown() }
          </Markdown>
          <Markdown>
            { devGuide }
          </Markdown>
        </Tab>
      </TabBar>
    </React.Fragment>
  );
};

export default Theming;
