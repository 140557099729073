import React from 'react';
import { Provider } from 'react-redux';
import { parse as generateDocs, resolver } from 'react-docgen';
import { IntlProvider, injectIntl } from 'react-intl';
import { createStore, compose } from 'redux';
import * as SimpleSearchExports from '.';

// eslint-disable-next-line
import SimpleSearchSrc from '!raw-loader!.';

const { default: SimpleSearch } = SimpleSearchExports;
const docs = generateDocs(
  SimpleSearchSrc,
  resolver.findAllExportedComponentDefinitions,
);

const store = createStore(() => { });

const SimpleSearchComponent = compose(injectIntl)(SimpleSearch);

export const engagingPlaceholderMessages = [
  'Search for Stranger Things fan art',
  'Discover a most peculiar floral pattern',
  'Find a phone case made just for you',
  'Explore mind-warping wall art',
  'Discover an artist who gets you',
];

const simpleSearchWithContent = {
  name: 'Example usage',
  description: 'The "Simple Search" feature distinguishes itself from the search box located in the header. It allows users to perform targeted searches for specific terms without necessitating the opening of suggestion modals or similar elements.',
  render: () => (
    <Provider store={store}>
      <IntlProvider locale="en">
        <SimpleSearchComponent
          baseUrl="http://redbubble.com"
          engagingPlaceholderMessages={engagingPlaceholderMessages}
        />
      </IntlProvider>
    </Provider>
  ),
  code: `
 <SimpleSearch intl={intl} engagingPlaceholderMessages={messages} baseUrl="url goes here" productContext={context}  />
  `,
};

const Demo = {
  title: SimpleSearch.displayName,
  description: '',
  docGen: docs,
  slug: 'SimpleSearch',
  exports: Object.keys(SimpleSearchExports),
  examples: [simpleSearchWithContent],
};

export default Demo;
