// This module and it's dependencies need
// to be in common-js to be importable at config time in Boom apps

// Converts hex colors to semi-transparent rbga's that appear
// like the given hex color when used on the given background
//
// alphaize(desired, background);
const alphaize = require('../alphaize');

// Converts a hex and opacity to an rgba() string
//
// rgba(#ffffff, 0.2)
const rgba = require('../rgba');

module.exports = {
  // COLORS

  // SEMANTIC: COLOR
  '--ds-color-brand': '#E41421',

  // Primary
  '--ds-color-primary': '#FF596F',

  // Neutral
  '--ds-color-neutral-50': '#F9F9FB',
  '--ds-color-neutral-100': '#F2F2F6',
  '--ds-color-neutral-200': '#D6DADF',
  '--ds-color-neutral-300': '#9695A8',
  '--ds-color-neutral-500': '#757195',
  '--ds-color-neutral-800': '#19124F',
  '--ds-color-neutral-900': '#07002f',

  // Success
  '--ds-color-success-100': '#E7F8EF',
  '--ds-color-success-200': '#C1F2DA',
  '--ds-color-success-500': '#34C665',
  '--ds-color-success-600': '#10834C',
  '--ds-color-success-700': '#0B532F',

  // Information
  '--ds-color-information-100': '#ECE9FF',
  '--ds-color-information-200': '#CDC3FF',
  '--ds-color-information-300': '#AFA3F2',
  '--ds-color-information-500': '#755EED',
  '--ds-color-information-600': '#5137D7',
  '--ds-color-information-700': '#36139A',

  // Warning
  '--ds-color-warning-100': '#FFE9D8',
  '--ds-color-warning-200': '#FFD6AD',
  '--ds-color-warning-500': '#FFBB77',
  '--ds-color-warning-600': '#AD5D02',
  '--ds-color-warning-700': '#723D0A',

  // Error
  '--ds-color-error-100': '#FFECEC',
  '--ds-color-error-200': '#FFCCCB',
  '--ds-color-error-500': '#ED3432',
  '--ds-color-error-600': '#CD1C1A',
  '--ds-color-error-700': '#810D0C',

  // Destructive
  '--ds-color-destructive-100': '#FFECEC',
  '--ds-color-destructive-200': '#FFCCCB',
  '--ds-color-destructive-500': '#ED3432',
  '--ds-color-destructive-600': '#CD1C1A',
  '--ds-color-destructive-700': '#810D0C',

  // Common
  '--ds-color-white': '#FFFFFF',
  '--ds-color-black': '#000000',
  '--ds-color-transparent': 'transparent',

  // Expressive
  '--ds-color-expressive-green-100': '#E3F8E9',
  '--ds-color-expressive-green-300': '#51BA88',
  '--ds-color-expressive-green-500': '#0E844C',
  '--ds-color-expressive-green-700': '#0B6339',

  '--ds-color-expressive-blue-100': '#F0F6FF',
  '--ds-color-expressive-blue-300': '#ADCEFF',
  '--ds-color-expressive-blue-500': '#0066FF',
  '--ds-color-expressive-blue-700': '#0652C3',

  '--ds-color-expressive-yellow-100': '#FAF2D9',
  '--ds-color-expressive-yellow-300': '#FFE9A1',
  '--ds-color-expressive-yellow-500': '#FFD54D',
  '--ds-color-expressive-yellow-700': '#D8A709',

  '--ds-color-expressive-red-100': '#FFD6DD',
  '--ds-color-expressive-red-300': '#F77990',
  '--ds-color-expressive-red-500': '#D92D02',
  '--ds-color-expressive-red-700': '#9A0D27',

  '--ds-color-expressive-pink-100': '#FFECF4',
  '--ds-color-expressive-pink-300': '#FFADCE',
  '--ds-color-expressive-pink-500': '#D32168',
  '--ds-color-expressive-pink-700': '#C30652',

  '--ds-color-expressive-teal-100': '#E3F7F8',
  '--ds-color-expressive-teal-300': '#5BCBD1',
  '--ds-color-expressive-teal-500': '#19AEAE',
  '--ds-color-expressive-teal-700': '#086166',

  '--ds-color-expressive-purple-100': '#FDDDFF',
  '--ds-color-expressive-purple-300': '#F1A7F4',
  '--ds-color-expressive-purple-500': '#C511CE',
  '--ds-color-expressive-purple-700': '#86338B',

  '--ds-color-expressive-orange-100': '#FFEBD8',
  '--ds-color-expressive-orange-300': '#FFAF75',
  '--ds-color-expressive-orange-500': '#EA872D',
  '--ds-color-expressive-orange-700': '#B06215',

  // Borders
  '--ds-color-border': 'var(--ds-color-neutral-200)', // neutral-200
  '--ds-color-border-light': 'var(--ds-color-neutral-100)', // neutral-100

  // Text
  '--ds-color-text': 'var(--ds-color-neutral-800)',
  '--ds-color-text-contrast': '#ffffff',
  '--ds-color-text-muted': 'var(--ds-color-neutral-500)',
  '--ds-color-text-muted-contrast': 'var(--ds-color-neutral-300)',

  // Ui Backgrounds
  '--ds-color-background-ui': 'var(--ds-color-white)',
  '--ds-color-background-ui-contrast': 'var(--ds-color-neutral-900)',
  '--ds-color-background-ui-medium': 'var(--ds-color-white)',
  '--ds-color-background-ui-high': 'var(--ds-color-white)',

  // Alternate Backgrounds
  '--ds-color-background-alt-50': alphaize('#F9F9FB', '#FFFFFF'),
  '--ds-color-background-alt-100': alphaize('#F2F2F6', '#FFFFFF'),
  '--ds-color-background-alt-200': alphaize('#D6DADF', '#FFFFFF'),
  '--ds-color-background-alt-500': alphaize('#8C95A5', '#FFFFFF'),

  // Application backgrounds
  '--ds-color-background-app': 'var(--ds-color-white)',
  '--ds-color-background-app-light': 'var(--ds-color-white)',
  '--ds-color-background-app-dark': 'var(--ds-color-neutral-900)',

  // Overlays
  '--ds-color-background-overlay': rgba('#8C95A5', 0.6),
  '--ds-color-background-overlay-transparent': rgba('#8C95A5', 0),

  // Disabled State
  '--ds-color-state-disabled-text': 'var(--ds-color-neutral-300)',
  '--ds-color-state-disabled-background': 'var(--ds-color-background-alt-100)',

  // Focus State
  '--ds-color-state-focus-border': 'var(--ds-color-information-300)',

  // Hover State
  '--ds-color-state-hover-overlay': rgba('#1A1239', '0.05'),
  '--ds-color-state-hover-overlay-contrast': rgba('#ffffff', '0.1'),

  // Loading State
  '--ds-color-state-loading-background': 'var(--ds-color-background-alt-200)',
  '--ds-color-state-loading-shimmer': 'var(--ds-color-background-alt-50)',

  // FONT_FAMILY
  '--ds-font-family': '-apple-system, BlinkMacSystemFont, Inter, Arial, sans-serif',

  // FONT_WEIGHT
  '--ds-font-weight-light': '400',
  '--ds-font-weight-normal': '400',
  '--ds-font-weight-bold': '600',
  '--ds-font-weight-display1': 'var(--ds-font-weight-bold)',
  '--ds-font-weight-display2': 'var(--ds-font-weight-bold)',
  '--ds-font-weight-display3': 'var(--ds-font-weight-bold)',
  '--ds-font-weight-display4': 'var(--ds-font-weight-normal)',
  '--ds-font-weight-display5': 'var(--ds-font-weight-bold)',
  '--ds-font-weight-display6': 'var(--ds-font-weight-bold)',
  '--ds-font-weight-body': 'var(--ds-font-weight-normal)',
  '--ds-font-weight-body2': 'var(--ds-font-weight-normal)',
  '--ds-font-weight-caption': 'var(--ds-font-weight-normal)',

  // FONT_SIZE
  '--ds-font-size-display1': '36px',
  '--ds-font-size-display2': '24px',
  '--ds-font-size-display3': '20px',
  '--ds-font-size-display4': '20px',
  '--ds-font-size-display5': '16px',
  '--ds-font-size-display6': '14px',
  '--ds-font-size-body': '16px',
  '--ds-font-size-body2': '14px',
  '--ds-font-size-caption': '12px',


  // LINE_HEIGHT
  '--ds-line-height-display1': '48px',
  '--ds-line-height-display2': '32px',
  '--ds-line-height-display3': '30px',
  '--ds-line-height-display4': '30px',
  '--ds-line-height-display5': '26px',
  '--ds-line-height-display6': '24px',
  '--ds-line-height-body': '26px',
  '--ds-line-height-body2': '24px',
  '--ds-line-height-caption': '16px',

  // RADIUS
  '--ds-radius-small': '4px',
  '--ds-radius-medium': '8px',
  '--ds-radius-large': '12px',
  '--ds-radius-pill': '200vh',

  // Size
  '--ds-size-border-width-hairline': '1px',
  '--ds-size-border-width-small': '2px',
  '--ds-size-border-width-medium': '4px',

  // NEW SPACING
  '--ds-spacing-xxxs': '2px',
  '--ds-spacing-xxs': '4px',
  '--ds-spacing-xs': '8px',
  '--ds-spacing-s': '12px',
  '--ds-spacing-m': '16px',
  '--ds-spacing-l': '24px',
  '--ds-spacing-xl': '32px',
  '--ds-spacing-xxl': '48px',
  '--ds-spacing-xxxl': '64px',

  // LEGACY SPACING
  '--ds-spacing-legacy-unit': '16px',
  '--ds-spacing': 'var(--ds-spacing-xxxs)',
  '--ds-spacing-01': 'var(--ds-spacing-xxxs)',
  '--ds-spacing-02': 'var(--ds-spacing-xxs)',
  '--ds-spacing-03': 'var(--ds-spacing-xs)',
  '--ds-spacing-04': 'var(--ds-spacing-s)',
  '--ds-spacing-05': 'var(--ds-spacing-m)',
  '--ds-spacing-06': 'var(--ds-spacing-m)',
  '--ds-spacing-07': 'var(--ds-spacing-l)',
  '--ds-spacing-08': 'var(--ds-spacing-l)',
  '--ds-spacing-09': 'var(--ds-spacing-xl)',
  '--ds-spacing-10': 'var(--ds-spacing-xxl)',

  // NEW DIMENSIONS
  '--ds-size-dimension': 'var(--ds-spacing-xxs)',
  '--ds-size-dimension-xxxs': 'var(--ds-size-dimension)', // 4px
  '--ds-size-dimension-xxs': 'calc(var(--ds-size-dimension) * 2)', // 8px
  '--ds-size-dimension-xs': 'calc(var(--ds-size-dimension) * 3)', // 12px
  '--ds-size-dimension-s': 'calc(var(--ds-size-dimension) * 4)', // 16px
  '--ds-size-dimension-m': 'calc(var(--ds-size-dimension) * 6)', // 24px
  '--ds-size-dimension-l': 'calc(var(--ds-size-dimension) * 8)', // 32px
  '--ds-size-dimension-xl': 'calc(var(--ds-size-dimension) * 10)', // 40px
  '--ds-size-dimension-xxl': 'calc(var(--ds-size-dimension) * 12)', // 48px
  '--ds-size-dimension-xxxl': 'calc(var(--ds-size-dimension) * 16)', // 64px
  '--ds-size-dimension-xxxxl': 'calc(var(--ds-size-dimension) * 24)', // 96px

  // OLD DIMENSIONS
  '--ds-size-dimension-01': 'var(--ds-size-dimension-xxxs)', // 4px
  '--ds-size-dimension-02': 'var(--ds-size-dimension-xxs)', // 8px
  '--ds-size-dimension-03': 'var(--ds-size-dimension-xs)', // 12px
  '--ds-size-dimension-04': 'var(--ds-size-dimension-s)', // 16px
  '--ds-size-dimension-05': 'var(--ds-size-dimension-m)', // 24px
  '--ds-size-dimension-06': 'var(--ds-size-dimension-l)', // 32px
  '--ds-size-dimension-07': 'var(--ds-size-dimension-xl)', // 40px
  '--ds-size-dimension-08': 'var(--ds-size-dimension-xxl)', // 48px
  '--ds-size-dimension-09': 'var(--ds-size-dimension-xxxl)', // 64px
  '--ds-size-dimension-10': 'var(--ds-size-dimension-xxxxl)', // 96px

  // LAYOUT
  '--ds-layout': 'var(--ds-spacing-xs)',
  '--ds-layout-01': 'var(--ds-spacing-xs)', // 8px
  '--ds-layout-02': 'var(--ds-spacing-m)', // 16px
  '--ds-layout-03': 'var(--ds-spacing-l)', // 24px
  '--ds-layout-04': 'var(--ds-spacing-xl)', // 32px
  '--ds-layout-05': 'var(--ds-spacing-xxl)', // 48px
  '--ds-layout-06': 'var(--ds-spacing-xxxl)', // 64px

  // SHADOW
  '--ds-shadow-300': '0 1px 1px -1px rgba(0,0,0,.15), 0 1px 2px 0 rgba(0,0,0,.1), 0 1px 4px 0 rgba(0,0,0,.1)',
  '--ds-shadow-500': '0 3px 4px -1px rgba(0,0,0,.15), 0 5px 10px 0 rgba(0,0,0,.1), 0 1px 12px 0 rgba(0,0,0,.1)',
  '--ds-shadow-700': '0 8px 10px -5px rgba(0,0,0,.2), 0 16px 24px 2px rgba(0,0,0,.14), 0 6px 30px 5px rgba(0,0,0,.1)',

  // TRANSITION
  '--ds-transition-fast': '150ms',
  '--ds-transition-standard': '300ms',

  // RAINBOW
  '--ds-color-rainbow-orange': '#FF596F',
  '--ds-color-rainbow-red': '#E41421',
  '--ds-color-rainbow-pink': '#ED207B',
  '--ds-color-rainbow-navy': '#6551CC',
  '--ds-color-rainbow-turquoise': '#47CCCC',
  '--ds-color-rainbow-teal': '#99CCBB',

  // HOLIDAY RAINBOW
  '--ds-color-holiday-rainbow-red': '#D92D02',
  '--ds-color-holiday-rainbow-orange': '#FA8F0F',
  '--ds-color-holiday-rainbow-turqoise': '#51BA88',
  '--ds-color-holiday-rainbow-green': '#50C581',
  '--ds-color-holiday-rainbow-blue': '#78EAF5',
};
