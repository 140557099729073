import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import Card from '../Card';
import * as constants from '../constants';

import * as PageSectionExports from '.';

/* eslint-disable */
import PageSectionSrc from '!raw-loader!.';
import BoxSrc from '!raw-loader!../Box';
/* eslint-enable */

const { default: PageSection } = PageSectionExports;
const docs = generateDocs(PageSectionSrc, resolver.findAllExportedComponentDefinitions);
const boxDocs = generateDocs(BoxSrc, resolver.findAllExportedComponentDefinitions);

const demoConfig = responsive => ({
  profile: 'DESKTOP',
  initialValues: {
    backgroundColor: 'transparent',
    size: constants.MEDIUM,
    profile: responsive.desktop ? constants.DESKTOP : constants.MOBILE,
  },
  fields: [
    {
      type: 'button-toggle',
      options: [
        { value: constants.MOBILE, label: 'Mobile' },
        { value: constants.DESKTOP, label: 'Desktop' },
      ],
      name: 'profile',
      label: 'Profile',
    },
    {
      type: 'button-toggle',
      options: [
        { value: 'transparent', label: 'Default' },
        { value: constants.COLOR_WHITE, label: 'White' },
        { value: constants.COLOR_NEUTRAL_100, label: 'Neutral' },
      ],
      name: 'backgroundColor',
      label: 'Background',
    },
    {
      type: 'button-toggle',
      options: [
        { value: constants.SMALL, label: 'Small' },
        { value: constants.MEDIUM, label: 'Medium' },
        { value: constants.LARGE, label: 'Large' },
      ],
      name: 'size',
      label: 'Size',
    },
  ],
});

const basicExample = {
  name: 'Variations',
  description: 'Page sections have a few simple variations. View <strong>full screen</strong>.',
  overflowVisual: false,
  config: responsive => demoConfig(responsive),
  render: (responsive, values) => (
    <PageSection
      backgroundColor={values.backgroundColor}
      profile={values.profile}
      size={values.size}
      paddingTop="m"
      paddingBottom="m"
    >
      <Card>
        Section content in a card
      </Card>
    </PageSection>
  ),
  code: (responsive, values) => {
    const background = {
      [constants.COLOR_NEUTRAL_100]: '\n  backgroundColor={constants.COLOR_NEUTRAL_100}',
      [constants.COLOR_WHITE]: '\n  backgroundColor={constants.COLOR_WHITE}',
      transparent: '',
    }[values.backgroundColor];

    const size = {
      [constants.SMALL]: 'constants.SMALL',
      [constants.MEDIUM]: 'constants.MEDIUM',
      [constants.LARGE]: 'constants.LARGE',
    }[values.size];

    const profile = {
      [constants.DESKTOP]: 'constants.DESKTOP',
      [constants.MOBILE]: 'constants.MOBILE',
    }[values.profile];

    return `
<PageSection ${background}
  profile={${profile}}
  size={${size}}
  paddingTop="m"
  paddingTop="m"
>
  <Card>
    Section content in a card
  </Card>
</PageSection>
    `;
  },
};

docs[0].props = {
  ...boxDocs[0].props,
  ...docs[0].props,
};

delete docs[0].props.className;
delete docs[0].props.style;
delete docs[0].props.styles;

const Demo = {
  title: PageSection.displayName,
  description: 'PageSections provide sensible max widths and paddings for horizontal bands of page content',
  docGen: docs,
  slug: 'page-section',
  exports: Object.keys(PageSectionExports),
  examples: [
    basicExample,
  ],
  notes: `
  `,
};

export default Demo;
