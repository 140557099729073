export const SEARCH_CATEGORY = 'Search';
const TYPEAHEAD_SHOWN = 'typeahead-shown';
const TYPEAHEAD_SELECTED = 'typeahead-selected';

const TYPEAHEAD_EXPERIENCE_VIEWED = 'typeahead-experience-viewed';
const TYPEAHEAD_EXPERIENCE_OPENED = 'typeahead-experience-opened';
const TYPEAHEAD_EXPERIENCE_CLOSED = 'typeahead-experience-closed';
const TYPEAHEAD_EXPERIENCE_RETURNED = 'typeahead-experience-returned';
const TYPEAHEAD_EXPERIENCE_ITEM_CLICKED = 'typeahead-experience-item-clicked';

const makeLabel = ({
  screen,
  keywords,
  itemIaCode,
  itemType,
  itemPosition,
  inputValue,
  productContextIaCode,
}) => {
  const clicked = [
    keywords,
    itemIaCode,
    itemType,
    `${itemPosition || ''}`,
  ].map(e => e || '').join(':');

  const context = [
    productContextIaCode,
    inputValue,
  ].map(e => e || '').join(':');

  return [
    screen,
    clicked,
    context,
  ].map(e => e || '').join('|');
};

// eslint-disable-next-line import/prefer-default-export
export const analyticsPayload = {
  typeaheadShown: () => ({
    category: SEARCH_CATEGORY,
    action: TYPEAHEAD_SHOWN,
  }),
  typeaheadSelected: (selected, textInBox) => ({
    category: SEARCH_CATEGORY,
    action: TYPEAHEAD_SELECTED,
    label: `${selected}:${textInBox}`,
  }),
  typeaheadExperienceOpened: opts => ({
    category: SEARCH_CATEGORY,
    action: TYPEAHEAD_EXPERIENCE_OPENED,
    label: makeLabel(opts),
    transport: 'beacon',
  }),
  typeaheadExperienceClosed: opts => ({
    category: SEARCH_CATEGORY,
    action: TYPEAHEAD_EXPERIENCE_CLOSED,
    label: makeLabel(opts),
    transport: 'beacon',
  }),
  typeaheadExperienceItemClicked: opts => ({
    category: SEARCH_CATEGORY,
    action: TYPEAHEAD_EXPERIENCE_ITEM_CLICKED,
    label: makeLabel(opts),
    transport: 'beacon',
  }),
  typeaheadExperienceReturned: opts => ({
    category: SEARCH_CATEGORY,
    action: TYPEAHEAD_EXPERIENCE_RETURNED,
    label: makeLabel(opts),
    transport: 'beacon',
  }),
  typeaheadExperienceViewed: opts => ({
    category: SEARCH_CATEGORY,
    action: TYPEAHEAD_EXPERIENCE_VIEWED,
    label: makeLabel(opts),
    transport: 'beacon',
  }),
};
