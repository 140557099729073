import React from 'react';
import get from 'lodash/get';
import cnames from 'classnames';
import ToastContainer from '../Toast/async';
import { tokensToCSS } from '../themes/utils';
import DesignSystemContext from '../contexts/DesignSystemContext';
import TrackingContext from '../contexts/TrackingContext';

const DesignSystemProvider = ({ config, children, className, style, ...props }) => {
  const toastConfig = get(config, 'useToasts', false);
  const trackingConfig = get(config, 'tracker', {});

  const adjustedConfig = { ...config };
  delete adjustedConfig.tracker;

  const classes = cnames(`ds-theme-${get(config, 'theme.id')}`,
    {
      [className]: className,
    });

  return (
    <React.Fragment>
      <style>
        {
          `
            :root {
              ${tokensToCSS(get(config, 'theme.tokens'))}
            }
          `
        }
      </style>
      <DesignSystemContext.Provider value={adjustedConfig} {...props}>
        <TrackingContext.Provider value={trackingConfig}>
          <div className={classes} style={style}>
            { children }
          </div>
          {
            toastConfig && (
              <ToastContainer {...toastConfig} />
            )
          }
        </TrackingContext.Provider>
      </DesignSystemContext.Provider>
    </React.Fragment>
  );
};

export default DesignSystemProvider;
