import React from 'react';
import get from 'lodash/get';
import Box from '../Box';
import Label, { INFORMATION } from '../Label';

const NavBadge = ({ badge }) => {
  const label = get(badge, 'label');
  if (!label) return null;

  return (
    <Box element="span" marginLeft="xs" display="flex" alignItems="center">
      <Label intent={INFORMATION}>{label}</Label>
    </Box>
  );
};

export default NavBadge;
