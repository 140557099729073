import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '../../Box';
import { OverlayContext } from '../ModalOverlay';
import dismissSvg from './dismiss.svg.raw';
import styles from './styles.scss';

const ModalBody = ({ children, padded }) => {
  const paddingProps = padded ? {
    paddingTop: 1.5,
    paddingBottom: 1.5,
    paddingLeft: 1,
    paddingRight: 1,
  } : {};

  const { handleClose, hideCloseButton } = useContext(OverlayContext);

  return (
    <div className={styles.container}>
      {!hideCloseButton && (
        <button type="button" aria-label="Dismiss modal" onClick={handleClose} className={styles.dismiss}>
          {/* eslint-disable react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: dismissSvg }} />
          {/* eslint-enable react/no-danger */}
        </button>
      )}
      <Box {...paddingProps}>
        { children }
      </Box>
    </div>
  );
};

ModalBody.propTypes = {
  /**
    * The content of the Modal body.
    */
  children: PropTypes.node.isRequired,
  /**
    * Whether or not the modal body has a default padding surrounding it
    */
  padded: PropTypes.bool,
};

ModalBody.defaultProps = {
  padded: true,
};

ModalBody.displayName = 'ModalBody';

export default ModalBody;
