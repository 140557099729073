import React from 'react';
import useResponsive from '../../../hooks/useResponsive';
import PageIntro from '../../../components/PageIntro';
import PageTitle from '../../../components/PageTitle';
import PageDescription from '../../../components/PageDescription';
import Markdown from '../../../components/Markdown';
import Hint from '../../../components/Hint';
import ComponentUsage from '../../../components/ComponentUsage';
import DownloadIcon from '../../../../react/Icons/Download';
import ExternalLinkIcon from '../../../../react/Icons/ExternalLink';
import ArrowRightIcon from '../../../../react/Icons/ArrowRight';
import BarChartIcon from '../../../../react/Icons/BarChart';
import HeartIcon from '../../../../react/Icons/HeartLarge';
import SearchIcon from '../../../../react/Icons/Search';
import CheckDiscIcon from '../../../../react/Icons/CheckDisc';
import HeartFilledIcon from '../../../../react/Icons/HearLargeFilled';
import ChevronRightBigIcon from '../../../../react/Icons/ChevronRightBig';
import GlobeIcon from '../../../../react/Icons/Globe';
import MediaCard from '../../../../react/MediaCard';
import Button from '../../../../react/Button';
import Menu, { MenuItem } from '../../../../react/Menu';
import Image, { generatePlaceholderSrc } from '../../../../react/Image';
import Text from '../../../../react/Text';
import Box from '../../../../react/Box';
import Card from '../../../../react/Card';
import Popover from '../../../../react/Popover';
import TextLink from '../../../../react/TextLink';
import List, { ListItem } from '../../../../react/List';
import Grid from '../../../../react/Grid';
import Tooltip from '../../../../react/Tooltip';
import * as constants from '../../../../react/constants';
import styles from './Iconography.css';

import aiTemplateImage from './media/ai-grid-download.jpg';
import aiTemplate from './media/ai_icons_template_24.ai';
import figmaTemplateImage from './media/figma-grid-download.jpg';
import featherIconsImage from './media/feather-icons.jpg';
import iconLibraryImage from './media/icon-library.jpg';

const Iconography = () => {
  const { mobile } = useResponsive();

  return (
    <>
      <Box className={styles.content}>
        <PageIntro>
          {/* eslint-disable max-len */}
          <PageTitle>Iconography</PageTitle>
          <PageDescription>
            <Text display="block" type="display4">
              Icons communicate meaning quickly and reduce language barriers.
            </Text>
          </PageDescription>
          {/* eslint-enable max-len */}
        </PageIntro>
        {/* eslint-enable max-len */}
        {/* eslint-disable */}
        <Markdown
          source={`


## Overview

We use icons to help people interpret key actions, process messages and navigate experiences efficiently, particularly where space is limited. Icons are a powerful tool, but they should be used sparingly to prevent clutter and distraction.

`}
        />
        <Markdown
          source={`

        ### Libraries

        Our [Icon library](/components/icon/examples) is designed to be recognisable, modern & friendly. Each icon is reduced to its most minimal form and uses simple shapes to ensure legibility at a small scale.

        We leverage [Feather](https://feathericons.com/), an open source icon library as a starting point for icon design. If you need something more specific or custom, follow the visual cues and take note of the guidelines below to create your own.

        `}
        />
        <Box paddingTop={1} paddingBottom={2}>
          <Grid columns={mobile ? 1 : 2}>
            <Box className={styles.imageHoverContainer}>
              <MediaCard
                href="/components/icon/examples"
                imageRender={() => (
                  <Box className={styles.imageHover}>
                    <Image
                      fluid
                      src={iconLibraryImage}
                      loadOnVisible={{
                        placeholder: generatePlaceholderSrc({ width: 300, height: 100 }),
                      }}
                    />
                  </Box>
                )}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  paddingLeft={0.5}
                >
                  <Text display="block" type="display5">
                    Redbubble Icons
                  </Text>
                  <Box paddingLeft={1} paddingRight={0.5} display="flex" alignItems="center">
                    <ChevronRightBigIcon />
                  </Box>
                </Box>
              </MediaCard>
            </Box>
            <Box className={styles.imageHoverContainer}>
              <MediaCard
                href="https://feathericons.com"
                imageRender={() => (
                  <Box className={styles.imageHover}>
                    <Image
                      fluid
                      src={featherIconsImage}
                      loadOnVisible={{
                        placeholder: generatePlaceholderSrc({ width: 300, height: 100 }),
                      }}
                    />
                  </Box>
                )}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  paddingLeft={0.5}
                >
                  <Text display="block" type="display5">
                    Feather Icons
                  </Text>
                  <Box paddingLeft={1} paddingRight={0.5} display="flex" alignItems="center">
                    <ExternalLinkIcon />
                  </Box>
                </Box>
              </MediaCard>
            </Box>
          </Grid>
        </Box>
        <Markdown
          source={`

        ## Composition

        `}
        />
        <Box display="flex" className={styles.gridDetails} paddingTop={1}>
          <Box marginRight={2} paddingBottom={0.75} className={styles.gridDetailsItem} flex="1">
            <Text display="block" type="display6">
              Grid
            </Text>
            <Text display="block" type="display4">
              24px
            </Text>
          </Box>
          <Box marginRight={2} paddingBottom={0.75} className={styles.gridDetailsItem} flex="1">
            <Text display="block" type="display6">
              Gutter
            </Text>
            <Text display="block" type="display4">
              1px
            </Text>
          </Box>
          <Box marginRight={2} paddingBottom={0.75} className={styles.gridDetailsItem} flex="1">
            <Text display="block" type="display6">
              Stroke
            </Text>
            <Text display="block" type="display4">
              2px
            </Text>
          </Box>
          <Box marginRight={2} paddingBottom={0.75} className={styles.gridDetailsItem} flex="1">
            <Text display="block" type="display6">
              Stroke cap
            </Text>
            <Text display="block" type="display4">
              Rounded
            </Text>
          </Box>
        </Box>
        <Markdown
          source={`
          ### Shape

          We use outlined icons as our default option that rely on simple shapes like squares, circles, or rectangles.

          If you can't find a suitable icon in our current library nor in the Feather set. These templates may help you craft your own.

          `}
        />
        <Box paddingTop={1} marginBottom={1}>
          <Grid columns={mobile ? 1 : 2}>
            <Box className={styles.imageHoverContainer}>
              <MediaCard
                href={aiTemplate}
                imageRender={() => (
                  <Box className={styles.imageHover}>
                    <Image
                      fluid
                      src={aiTemplateImage}
                      loadOnVisible={{
                        placeholder: generatePlaceholderSrc({ width: 300, height: 100 }),
                      }}
                    />
                  </Box>
                )}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  paddingLeft={0.5}
                >
                  <Text display="block" type="display5">
                    Adobe Illustrator
                  </Text>
                  <Box paddingLeft={1} paddingRight={0.5} display="flex" alignItems="center">
                    <DownloadIcon />
                  </Box>
                </Box>
              </MediaCard>
            </Box>
            <Box className={styles.imageHoverContainer}>
              <MediaCard
                href="https://www.figma.com/file/iX1G84a7d4A6vw1is9IcFI/Icon-Template"
                imageRender={() => (
                  <Box className={styles.imageHover}>
                    <Image
                      fluid
                      src={figmaTemplateImage}
                      loadOnVisible={{
                        placeholder: generatePlaceholderSrc({ width: 300, height: 100 }),
                      }}
                    />
                  </Box>
                )}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  paddingLeft={0.5}
                >
                  <Text display="block" type="display5">
                    Figma
                  </Text>
                  <Box paddingLeft={1} paddingRight={0.5} display="flex" alignItems="center">
                    <ExternalLinkIcon />
                  </Box>
                </Box>
              </MediaCard>
            </Box>
          </Grid>
        </Box>
        <Box marginTop={2}>
          <Hint showSymbol>
            <Box display="flex">
              <Box marginLeft={0.5}>
                <Text type="body2">
                  Remember to outline or expand icons before exporting them and adding them to the
                  system.&nbsp;
                  <Popover
                    trigger={({ onClick }) => (
                      <TextLink onClick={onClick}> Outline Shapes</TextLink>
                    )}
                    accessibleTitle="How to outline icons"
                  >
                    <Box padding={1}>
                      <Text type="display6" display="block">
                        Figma
                      </Text>
                      <Text type="body2">Object &rarr; Outlines</Text>
                      <Box marginBottom={0.5} />
                      <Text type="display6" display="block">
                        Illustrator
                      </Text>
                      <Text type="body2">Object &rarr; Path &rarr; Outline stroke</Text>
                    </Box>
                  </Popover>
                </Text>
              </Box>
            </Box>
          </Hint>
        </Box>
        <Box paddingTop="xl" />
        <Markdown
          source={`
        ## Usage
            `}
        />
        <Box paddingTop={2} marginTop={1} paddingBottom={1}>
          <Text type="display5" display="block">
            Outlines
          </Text>
          <Box marginTop={0.25} />
          <Text>
            Use outlined icons as default and filled icons to indicate an active icon state.
          </Text>
        </Box>
        <Grid columns={mobile ? 1 : 2}>
          <ComponentUsage
            type="do"
            description="Use outline icons as default and filled as active state."
          >
            <Box display="flex" justifyContent="center">
              <HeartIcon />
              <Box marginRight={1} />
              <HeartFilledIcon color="var(--ds-color-primary)" />
            </Box>
          </ComponentUsage>
          <ComponentUsage
            type="dont"
            description="Don't use filled icons as default or outlines as active. It dilutes the purpose of their intended roles."
          >
            <Box display="flex" justifyContent="center">
              <HeartFilledIcon />
              <Box marginRight={1} />
              <HeartIcon color="var(--ds-color-primary)" />
            </Box>
          </ComponentUsage>
        </Grid>
        <Box paddingTop={2} marginTop={1} paddingBottom={1}>
          <Text type="display5" display="block">
            System Icons
          </Text>
          <Box marginTop={0.25} />
          <Text display="block">Use system icons for actions and notifications.</Text>
        </Box>
        <Grid columns={mobile ? 1 : 2}>
          <ComponentUsage
            type="do"
            description="Use system icons to reinforce actions and notifications."
          >
            <Box display="flex" justifyContent="center">
              <Box display="flex" flexDirection="column">
                <Box display="flex" padding={1} justifyContent="center">
                  <Menu
                    profile={mobile ? constants.MOBILE : constants.DESKTOP}
                    accessibleTitle="Language menu"
                    trigger={({ onClick }) => (
                      <Tooltip
                        placement={constants.TOP}
                        trigger={triggerProps => (
                          <Button iconBefore={<GlobeIcon />} onClick={onClick} {...triggerProps}>
                            English
                          </Button>
                        )}
                        accessibleTitle="Change language"
                      >
                        Change language
                      </Tooltip>
                    )}
                  >
                    <MenuItem>German</MenuItem>
                    <MenuItem>French</MenuItem>
                    <MenuItem>Spanish</MenuItem>
                  </Menu>
                </Box>
                <Box marginTop={1} />
                <Card elevation={constants.ELEVATION_HIGH}>
                  <Box display="flex" alignItems="center" padding={1} paddingLeft={0.75}>
                    <CheckDiscIcon color="var(--ds-color-success-500)" />
                    <Box marginLeft={0.5} />
                    <Text type="display5">Successfully added!</Text>
                  </Box>
                </Card>
              </Box>
            </Box>
          </ComponentUsage>
          <ComponentUsage
            type="dont"
            description="Don't use system icons to communicate brand and value props. Instead use more expressive symbols or illustrations."
          >
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
              <HeartFilledIcon size={constants.LARGE} />
              <Box marginTop={1} />
              <Text type="display5">Socially Responsible</Text>
              <Text type="body2">Production and Emissions</Text>
              <Box marginTop={0.5} />
              <Text type="body2">
                <TextLink>Learn more</TextLink>
              </Text>
            </Box>
          </ComponentUsage>
        </Grid>
        <Box paddingTop={2} marginTop={1} paddingBottom={1}>
          <Text type="display5" display="block">
            Tooltips
          </Text>
          <Box marginTop={0.25} />
          <Text>Combine Tooltips and Buttons to help users identify interactivity.</Text>
        </Box>
        <Grid columns={mobile ? 1 : 2}>
          <ComponentUsage type="do" description="Ensure the tooltip is short and descriptive.">
            <Box display="flex" justifyContent="center">
              <Tooltip
                trigger={triggerProps => (
                  <Button iconBefore={<BarChartIcon />} circle {...triggerProps} />
                )}
                accessibleTitle="Dashboard"
              >
                Dashboard
              </Tooltip>
            </Box>
          </ComponentUsage>
          <ComponentUsage
            type="caution"
            description="You can choose not to use tooltips. But be cautious and ensure that the action is clear enough to the user even without it."
          >
            <Box display="flex" justifyContent="center">
              <Button iconBefore={<SearchIcon />} circle />
            </Box>
          </ComponentUsage>
        </Grid>
      </Box>
      <Box padding={mobile ? 1 : 2} className={styles.contentFooter}>
        <Markdown
          source={`
  ### Related

  ##### Components
            `}
        />
        <List bulletMedia={<ArrowRightIcon />}>
          <ListItem>
            <TextLink href="/components/Icon/examples" subtle>
              Icon
            </TextLink>
          </ListItem>
          <ListItem>
            <TextLink href="/components/Button/examples" subtle>
              Button
            </TextLink>
          </ListItem>
        </List>
        <Markdown
          source={`
    ##### Guidelines
              `}
        />
        <List bulletMedia={<ArrowRightIcon />}>
          <ListItem>
            <TextLink href="/guidelines/icons" subtle>
              Adding new icons
            </TextLink>
          </ListItem>
          <ListItem>
            <TextLink href="/guidelines/typography" subtle>
              Typography
            </TextLink>
          </ListItem>
        </List>
      </Box>
    </>
  );
};

export default Iconography;
