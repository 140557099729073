import ButtonDemo from '../../../react/Button/Button.demo';
import ButtonToggleDemo from '../../../react/ButtonToggle/ButtonToggle.demo';
import CardDemo from '../../../react/Card/Card.demo';
import ImageDemo from '../../../react/Image/Image.demo';
import TextDemo from '../../../react/Text/Text.demo';
import ListDemo from '../../../react/List/List.demo';
import DividerDemo from '../../../react/Divider/Divider.demo';
import MenuDemo from '../../../react/Menu/Menu.demo';
import DropdownDemo from '../../../react/Dropdown/Dropdown.demo';
import CarouselDemo from '../../../react/Carousel/Carousel.demo';
import AvatarDemo from '../../../react/Avatar/Avatar.demo';
import ModalDemo from '../../../react/Modal/Modal.demo';
import DrawerDemo from '../../../react/Drawer/Drawer.demo';
import FormDemo from '../../../react/Form/Form.demo';
import TableDemo from '../../../react/Table/Table.demo';
import ProgressBarDemo from '../../../react/ProgressBar/ProgressBar.demo';
import SkeletonDemo from '../../../react/Skeleton/Skeleton.demo';
import PopoverDemo from '../../../react/Popover/Popover.demo';
import TooltipDemo from '../../../react/Tooltip/Tooltip.demo';
import ToastDemo from '../../../react/Toast/Toast.demo';
import SheetsDemo from '../../../react/Sheets/Sheets.demo';
import PageSectionDemo from '../../../react/PageSection/PageSection.demo';
import StackDemo from '../../../react/Stack/Stack.demo';
import IconDemo from '../../../react/Icon/Icon.demo';
import AlertDemo from '../../../react/Alert/Alert.demo';
import LabelDemo from '../../../react/Label/Label.demo';
import MasonryDemo from '../../../react/Masonry/Masonry.demo';
import GridDemo from '../../../react/Grid/Grid.demo';
import MediaCard from '../../../react/MediaCard/MediaCard.demo';
import TextLink from '../../../react/TextLink/TextLink.demo';
import LogoDemo from '../../../react/Logo/Logo.demo';
import HeaderDemo from '../../../react/Header.demo';
import FooterDemo from '../../../react/Footer.demo';
import ProductNavDemo from '../../../react/ProductNav/demo/productNav.demo';
import LicensingMarkDemo from '../../../react/LicensingMark/LicensingMark.demo';
import ReviewDemo from '../../../react/Review/Review.demo';
import StarRatingDemo from '../../../react/StarRating/StarRating.demo';
import BoxDemo from '../../../react/Box/Box.demo';
import CollapsibleDemo from '../../../react/Collapsible/Collapsible.demo';
import ImagePaletteDemo from '../../../react/ImagePalette/ImagePalette.demo';
import ToolbarDemo from '../../../react/Toolbar/Toolbar.demo';
import SimpleSearch from '../../../react/SimpleSearch/SimpleSearch.demo';

const demos = [
  {
    demo: CardDemo,
    section: 'LAYOUT',
  },
  {
    demo: DividerDemo,
    section: 'LAYOUT',
  },
  {
    demo: PageSectionDemo,
    section: 'LAYOUT',
  },
  {
    demo: StackDemo,
    section: 'LAYOUT',
  },
  {
    demo: BoxDemo,
    section: 'LAYOUT',
  },
  {
    demo: GridDemo,
    section: 'LAYOUT',
  },
  {
    demo: MasonryDemo,
    section: 'LAYOUT',
  },
  {
    demo: ButtonToggleDemo,
    section: 'ACTIONS',
  },
  {
    demo: ButtonDemo,
    section: 'ACTIONS',
  },
  {
    demo: DropdownDemo,
    section: 'ACTIONS',
  },
  {
    demo: MenuDemo,
    section: 'ACTIONS',
  },
  {
    demo: ToolbarDemo,
    section: 'ACTIONS',
  },
  {
    demo: SimpleSearch,
    section: 'INPUT',
  },
  {
    demo: FormDemo,
    section: 'INPUT',
  },
  {
    demo: AvatarDemo,
    section: 'CONTENT',
  },
  {
    demo: LogoDemo,
    section: 'CONTENT',
  },
  {
    demo: ImageDemo,
    section: 'CONTENT',
  },
  {
    demo: TextDemo,
    section: 'CONTENT',
  },
  {
    demo: CarouselDemo,
    section: 'CONTENT',
  },
  {
    demo: SkeletonDemo,
    section: 'CONTENT',
  },
  {
    demo: MediaCard,
    section: 'CONTENT',
  },
  {
    demo: LicensingMarkDemo,
    section: 'CONTENT',
  },
  {
    demo: ReviewDemo,
    section: 'CONTENT',
  },
  {
    demo: StarRatingDemo,
    section: 'CONTENT',
  },
  {
    demo: ListDemo,
    section: 'CONTENT',
  },
  {
    demo: IconDemo,
    section: 'CONTENT',
  },
  {
    demo: ModalDemo,
    section: 'DIALOG',
  },
  {
    demo: DrawerDemo,
    section: 'DIALOG',
  },
  {
    demo: PopoverDemo,
    section: 'DIALOG',
  },
  {
    demo: ToastDemo,
    section: 'FEEDBACK',
  },
  {
    demo: SheetsDemo,
    section: 'NAVIGATION',
  },
  {
    demo: LabelDemo,
    section: 'FEEDBACK',
  },
  {
    demo: AlertDemo,
    section: 'FEEDBACK',
  },
  {
    demo: TooltipDemo,
    section: 'FEEDBACK',
  },
  {
    demo: TableDemo,
    section: 'DATA DISPLAY',
  },
  {
    demo: ProgressBarDemo,
    section: 'DATA DISPLAY',
  },
  {
    demo: TextLink,
    section: 'NAVIGATION',
  },
  {
    demo: HeaderDemo,
    section: 'APPLICATION FRAMES',
  },
  {
    demo: FooterDemo,
    section: 'APPLICATION FRAMES',
  },
  {
    demo: ProductNavDemo,
    section: 'APPLICATION FRAMES',
  },
  {
    demo: CollapsibleDemo,
    section: 'UTILITIES',
  },
  {
    demo: ImagePaletteDemo,
    section: 'UTILITIES',
  },
];

export default demos;
