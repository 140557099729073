import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import cnames from 'classnames';
import Text from '../../../Text';
import styles from './CartCountBadge.scss';

const ANIMATION_DURATION = 420;
const ANIMATION_RESET_DELAY = 1500;

const Badge = ({ children, ...props }) => (
  <Transition {...props} timeout={ANIMATION_DURATION}>
    {
      state => (
        <div className={cnames(styles.cartCount, styles[state])}>
          <Text type="caption">
            <strong>{children}</strong>
          </Text>
        </div>
      )
    }
  </Transition>
);

Badge.propTypes = {
  children: PropTypes.node.isRequired,
};

class CartCountBadge extends React.Component {
  constructor(props) {
    super(props);

    this.state = { success: false };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({ success: !prevState.success }));
  }

  componentWillReceiveProps(newProps) {
    if (newProps.count > this.props.count) {
      this.toggle();

      setTimeout(this.toggle, ANIMATION_RESET_DELAY);
    }
  }

  render() {
    if (!this.props.count) return null;

    return (
      <Badge in={this.state.success}>
        {this.props.count}
      </Badge>
    );
  }
}

CartCountBadge.propTypes = {
  count: PropTypes.number,
};

CartCountBadge.defaultProps = {
  count: null,
};

CartCountBadge.displayName = 'CartCountBadge';

export default CartCountBadge;
