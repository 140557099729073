import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Text from '../../../Text';
import Button from '../../../Button';
import Popover from '../../../Popover';
import Tooltip from '../../../Tooltip';
import Box from '../../../Box';
import BarChartIcon from '../../../Icons/BarChart';
import HeartFilledLargeIcon from '../../../Icons/HearLargeFilled';
import StoreIcon from '../../../Icons/Store';
import PersonAdd from '../../../Icons/PersonAdd';
import * as constants from '../../../constants';

import messages from './messages';

const SI_SYMBOLS = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

const formatNumber = (number) => {
  // eslint-disable-next-line no-bitwise
  const tier = Math.log10(number) / 3 | 0;
  if (tier === 0) return number;

  const suffix = SI_SYMBOLS[tier];
  const scale = 10 ** (tier * 3);
  const scaled = number / scale;
  return scaled.toFixed(1) + suffix;
};

const ArtistMetrics = ({
  favorites,
  followers,
  productsSold,
  baseUrl,
  className,
  intl,
}) => {
  const dashboardUrl = `${baseUrl}/studio/dashboard?ref=artist-header-metric`;
  const [disableTooltip, setDisableTooltip] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  if ([favorites, followers, productsSold].every(count => count === 0)) return null;

  return (
    <div className={className}>
      <Box>
        <Popover
          onCloseRequested={() => { setDisableTooltip(false); setPopoverOpen(false); }}
          open={popoverOpen}
          primaryAction={({ closePopover }) => (
            <Button
              href={dashboardUrl}
              onClick={closePopover}
              size={constants.SMALL}
              intent={constants.PRIMARY}
              data-testid="ds-header-dashboard-popover-link"
            >
              {intl.formatMessage(messages.viewDashboard)}
            </Button>
          )}
          trigger={() => (
            <Tooltip
              trigger={triggerProps => (
                <Box {...(disableTooltip ? {} : triggerProps)}>
                  <Button
                    circle
                    size={constants.LARGE}
                    onClick={() => {
                      triggerProps.onMouseLeave();
                      setDisableTooltip(!disableTooltip);
                      setPopoverOpen(!popoverOpen);
                    }}
                    iconBefore={<BarChartIcon />}
                    data-testid="ds-header-dashboard-popover-action"
                  />
                </Box>
              )}
              accessibleTitle={intl.formatMessage(messages.dashboardLabel)}
            >
              {intl.formatMessage(messages.dashboardLabel)}
            </Tooltip>
          )}
          accessibleTitle="Popover"
        >
          <Box padding="m">
            <Box display="flex" flexDirection="column">
              <Box paddingBottom="m" display="flex">
                <Box paddingRight="m">
                  <PersonAdd color="var(--ds-color-text-muted)" />
                </Box>
                <Box>
                  <Text type="display5" display="block">{formatNumber(followers)}</Text>
                  <Text type="caption" display="block">{intl.formatMessage(messages.followers)}</Text>
                </Box>
              </Box>
              <Box paddingBottom="m" display="flex">
                <Box paddingRight="m">
                  <HeartFilledLargeIcon color="var(--ds-color-text-muted)" />
                </Box>
                <Box>
                  <Text type="display5" display="block">{formatNumber(favorites)}</Text>
                  <Text type="caption" display="block">{intl.formatMessage(messages.favorites)}</Text>
                </Box>
              </Box>
              {
                productsSold !== 0 && (
                  <Box display="flex">
                    <Box paddingRight="m">
                      <StoreIcon color="var(--ds-color-text-muted)" />
                    </Box>
                    <Box>
                      <Text type="display5" display="block">{formatNumber(productsSold)}</Text>
                      <Text type="caption" display="block">{intl.formatMessage(messages.productsSold)}</Text>
                    </Box>
                  </Box>
                )
              }
            </Box>
          </Box>
        </Popover>
      </Box>
    </div>
  );
};

ArtistMetrics.propTypes = {
  favorites: PropTypes.number.isRequired,
  followers: PropTypes.number.isRequired,
  productsSold: PropTypes.number.isRequired,
  baseUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ArtistMetrics.defaultProps = {
  className: '',
};

export default ArtistMetrics;
