import React from 'react';
import PropTypes from 'prop-types';
import { MenuSection } from '../Menu';

const DropdownSection = React.forwardRef((props, ref) => {
  // Force the index to be > 0 to avoid no margin-top on first child
  return (
    <MenuSection {...({ ...props, index: props.index || 1 })} ref={ref} />
  );
});

DropdownSection.propTypes = {
  /**
    * The DropdownItems and DropdownLinks for this section
    */
  children: PropTypes.node,
};

export default DropdownSection;
