import React from 'react';
import PropTypes from 'prop-types';

class OnUpdate extends React.Component {
  componentDidUpdate(prevProps) {
    this.props.onChange(this.props.form, prevProps.form);
  }

  render() {
    return null;
  }
}

OnUpdate.propTypes = {
  onChange: PropTypes.func.isRequired,
  form: PropTypes.shape({}).isRequired,
};

export default OnUpdate;
