import React from 'react';
import PageIntro from '../../../components/PageIntro';
import PageTitle from '../../../components/PageTitle';
import PageDescription from '../../../components/PageDescription';
import Markdown from '../../../components/Markdown';
import Code from '../../../components/Code';
import Text from '../../../../react/Text';
import Box from '../../../../react/Box';
import Image, { generatePlaceholderSrc } from '../../../../react/Image';
import ChevronRightBigIcon from '../../../../react/Icons/ChevronRightBig';
import MediaCard from '../../../../react/MediaCard';
import { PanelFooter } from '../../../components/Panel';
import styles from './Spacing.css';

import formExample from './media/form.png';
import titleExample from './media/title.png';
import buttonExample from './media/buttons.png';
import cardExample from './media/card.png';
import stackExample from './media/stack.png';

const relatedContent = [
  {
    name: 'Spacing Tokens',
    link: '/guidelines/design-tokens/token-reference#Spacing',
  },
  {
    name: 'Stack',
    link: '/components/stack/examples',
  },
  {
    name: 'Layout',
    link: '/guidelines/layout',
  },
];

const Spacing = () => {
  return (
    <>
      <Box>
        <PageIntro>
          <PageTitle>Spacing</PageTitle>
          <PageDescription>
            {/* eslint-disable max-len */}
            Spacing sets the vertical and horizontal rhythm of our experiences.
            {/* eslint-enable max-len */}
          </PageDescription>
        </PageIntro>
        <Markdown
          source={`

  ## Overview

  We use a scale that is loosely based on a 16px unit as it’s an optimal default for font size, and a factor of most screen resolutions.
  Spatial patterns are woven through our entire system, they set the foundation of our page layouts, inform padding and margins between design elements and serve as a basis for our typographic scale.

  To help us achieve consistent spacing we use t-shirt size based spacing tokens and values ranging from \`xxxs\` to \`xxxl\`.
  This spacing scale is flexible and can be used for both general, as well as layout-specific purposes.


  ## Scale

  `}
        />
        <div className={styles.tableWrap}>
          <table className={styles.table} cellSpacing="0">
            <tbody>
              <tr>
                <th>
                  <Text type="display5" element="h3" display="block">Size</Text>
                </th>
                <th>
                  <Text type="display5" element="h3" display="block">Value</Text>
                </th>
                <th>
                  <Text type="display5" element="h3" display="block">Horizontal</Text>
                </th>
                <th>
                  <Text type="display5" element="h3" display="block">Vertical</Text>
                </th>
              </tr>
              <tr>
                <td>
                  <Text type="display5">xxxs</Text>
                </td>
                <td>
                  <Text type="body">2px</Text>
                </td>
                <td>
                  <Box
                    style={{
                      width: 'var(--ds-spacing-xxxs)',
                      height: 'var(--ds-spacing-m)',
                      backgroundColor: 'var(--ds-color-information-300)',
                    }}
                  />
                </td>
                <td>
                  <Box
                    style={{
                      width: 'var(--ds-spacing-m)',
                      height: 'var(--ds-spacing-xxxs)',
                      backgroundColor: 'var(--ds-color-information-300)',
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Text type="display5">xxs</Text>
                </td>
                <td>
                  <Text type="body">4px</Text>
                </td>
                <td>
                  <Box
                    style={{
                      width: 'var(--ds-spacing-xxs)',
                      height: 'var(--ds-spacing-m)',
                      backgroundColor: 'var(--ds-color-information-300)',
                    }}
                  />
                </td>
                <td>
                  <Box
                    style={{
                      width: 'var(--ds-spacing-m)',
                      height: 'var(--ds-spacing-xxs)',
                      backgroundColor: 'var(--ds-color-information-300)',
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Text type="display5">xs</Text>
                </td>
                <td>
                  <Text type="body">8px</Text>
                </td>
                <td>
                  <Box
                    style={{
                      width: 'var(--ds-spacing-xs)',
                      height: 'var(--ds-spacing-m)',
                      backgroundColor: 'var(--ds-color-information-300)',
                    }}
                  />
                </td>
                <td>
                  <Box
                    style={{
                      width: 'var(--ds-spacing-m)',
                      height: 'var(--ds-spacing-xs)',
                      backgroundColor: 'var(--ds-color-information-300)',
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Text type="display5">s</Text>
                </td>
                <td>
                  <Text type="body">12px</Text>
                </td>
                <td>
                  <Box
                    style={{
                      width: 'var(--ds-spacing-s)',
                      height: 'var(--ds-spacing-m)',
                      backgroundColor: 'var(--ds-color-information-300)',
                    }}
                  />
                </td>
                <td>
                  <Box
                    style={{
                      width: 'var(--ds-spacing-m)',
                      height: 'var(--ds-spacing-s)',
                      backgroundColor: 'var(--ds-color-information-300)',
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Text type="display5">m</Text>
                </td>
                <td>
                  <Text type="body">16px</Text>
                </td>
                <td>
                  <Box
                    style={{
                      width: 'var(--ds-spacing-m)',
                      height: 'var(--ds-spacing-m)',
                      backgroundColor: 'var(--ds-color-information-300)',
                    }}
                  />
                </td>
                <td>
                  <Box
                    style={{
                      width: 'var(--ds-spacing-m)',
                      height: 'var(--ds-spacing-m)',
                      backgroundColor: 'var(--ds-color-information-300)',
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Text type="display5">l</Text>
                </td>
                <td>
                  <Text type="body">24px</Text>
                </td>
                <td>
                  <Box
                    style={{
                      width: 'var(--ds-spacing-l)',
                      height: 'var(--ds-spacing-m)',
                      backgroundColor: 'var(--ds-color-information-300)',
                    }}
                  />
                </td>
                <td>
                  <Box
                    style={{
                      width: 'var(--ds-spacing-m)',
                      height: 'var(--ds-spacing-l)',
                      backgroundColor: 'var(--ds-color-information-300)',
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Text type="display5">xl</Text>
                </td>
                <td>
                  <Text type="body">32px</Text>
                </td>
                <td>
                  <Box
                    style={{
                      width: 'var(--ds-spacing-xl)',
                      height: 'var(--ds-spacing-m)',
                      backgroundColor: 'var(--ds-color-information-300)',
                    }}
                  />
                </td>
                <td>
                  <Box
                    style={{
                      width: 'var(--ds-spacing-m)',
                      height: 'var(--ds-spacing-xl)',
                      backgroundColor: 'var(--ds-color-information-300)',
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Text type="display5">xxl</Text>
                </td>
                <td>
                  <Text type="body">48px</Text>
                </td>
                <td>
                  <Box
                    style={{
                      width: 'var(--ds-spacing-xxl)',
                      height: 'var(--ds-spacing-m)',
                      backgroundColor: 'var(--ds-color-information-300)',
                    }}
                  />
                </td>
                <td>
                  <Box
                    style={{
                      width: 'var(--ds-spacing-m)',
                      height: 'var(--ds-spacing-xxl)',
                      backgroundColor: 'var(--ds-color-information-300)',
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Text type="display5">xxxl</Text>
                </td>
                <td>
                  <Text type="body">64px</Text>
                </td>
                <td>
                  <Box
                    style={{
                      width: 'var(--ds-spacing-xxxl)',
                      height: 'var(--ds-spacing-m)',
                      backgroundColor: 'var(--ds-color-information-300)',
                    }}
                  />
                </td>
                <td>
                  <Box
                    style={{
                      width: 'var(--ds-spacing-m)',
                      height: 'var(--ds-spacing-xxxl)',
                      backgroundColor: 'var(--ds-color-information-300)',
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Markdown source={`

  ## Usage

  Smaller spacing values should be used between elements that are themselves small, or elements that are grouped together by functionality.
  In a similar manner, if the elements are large or are not functionally related, larger spacing values should be used.

  ### Smaller values
  `}
        />
        <Box marginTop="m" className={styles.examples}>
          <Box>
            <Image
              className={styles.exampleImg}
              src={buttonExample}
              fluid
            />
            <Markdown source={`
  \`xs\` is used to space out smalle items like icon and text.
  The [Button](/components/button/examples) component supports this spacing out of the box.
  `}
            />
          </Box>
          <Box>
            <Image
              className={styles.exampleImg}
              src={titleExample}
              fluid
            />
            <Markdown source={`
  \`xs\` is also used to space out closely related items within a group, like title and the input field.
  The [Form](/components/form/examples) fields have this spacing built-in.
  `}
            />
          </Box>
        </Box>
        <Markdown source="### Larger values" />
        <Box marginTop="m" marginBottom="xl" className={styles.examples}>
          <Box>
            <Image
              className={styles.exampleImg}
              src={cardExample}
              fluid
            />
            <Markdown source={`
  \`m\` and \`l\` can be used as padding in cards.
  The [Card](/components/card/examples) component has a vertical padding of \`l\` and a horizontal padding of \`m\` built-in.
  `}
            />
          </Box>
          <Box>
            <Image
              className={styles.exampleImg}
              src={formExample}
              fluid
            />
            <Markdown source={`
  \`l\` can be used to vertically space out groups of related content.
  The [Form](/components/form/examples) component has this group spacing built in.
  `}
            />
          </Box>
        </Box>
        <Markdown source={`

  ## Spacing components

  On top of [Box](/utilities/Box/examples) and [Text](/components/text/examples) components that allow application of margins and paddings directly,
  the system provides some components purpose-built for spacing out children elements.

  The [Stack](/components/stack/examples) component can be used to evenly space out arbitrary elements in either vertical stack or a horizontal row.

  `}
        />
        <Box marginY="xl" className={styles.examples}>
          <Box className={styles.imageHoverContainer}>
            <MediaCard
              href="https://tinyurl.com/y3cf4fp6"
              imageRender={() => (
                <Box className={styles.imageHover}>
                  <Image
                    fluid
                    src={stackExample}
                    loadOnVisible={{
                      placeholder: generatePlaceholderSrc({ width: 500, height: 350 }),
                    }}
                  />
                </Box>
              )}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                paddingLeft="xs"
              >
                <Text display="block" type="display5">
                Stack Playroom
                </Text>
                <Box paddingLeft="m" paddingRight="xs" display="flex" alignItems="center">
                  <ChevronRightBigIcon />
                </Box>
              </Box>
            </MediaCard>
          </Box>
        </Box>
        <Markdown source={`

  ## Application

  The spacing scale can be applied in CSS via tokens or, alternatiely, be passed as a prop to components that support it.
  The following are some examples of the appropriate applications.

  ### CSS
  `}
        />
        <Code language="scss">
          {`
  margin: var(--ds-spacing-s) var(--ds-spacing-m) 0;
  padding: var(--ds-spacing-m);
          `}
        </Code>
        <Markdown source="### JavaScript" />
        <Code>
          {`
  <Box padding="s" marginTop="m" marginX="s">
    Content
  </Box>
          `}
        </Code>
      </Box>
      <PanelFooter relatedContent={relatedContent} />
    </>
  );
};

export default Spacing;
