import image from '../assets/our-audience.svg';

/* eslint-disable max-len */
const markdown = `
## Customers

We’re not writing for a website. We’re writing for people. Human people. So, when we create content for customers, we should always ask: would someone I know be happy they read this? And would this information help someone find their thing?

In every communication, we should reinforce our brand voice. Because our brand voice is us. And we’re pretty nice. But there is a line we have to walk when communicating with customers through marketing channels and customer success channels. There are 2 sides to the customer coin.

### Marketing

Marketing is where we speak in the purest form of the Redbubble voice. It’s all about connecting. So we’re able to really strut our “wildly creative” stuff in emails, videos, and social posts that are meant to engage, entertain, retain, and of course, sell stuff to customers. The marketing team works hard to bring out the **human**, **playful**, **smart**, **unexpected**, and **respectful** elements of our brand voice.

So when communicating through marketing channels, let Redbubble be Redbubble. Be creative. Be open. Be fun. But within reason, of course.

### Customer Success

While the focus of Redbubble’s marketing is to bring customers in using proactive methods, the goal of customer success is to keep customers happy by providing reactive solutions. The customers’ needs sit at the heart of our voice. Therefore, we have to be mindful about using the brand attributes in our responses

The Redbubble brand touches all aspects of the company. However, when it comes to delivering great customer experience, we need to approach our voice a little differently.

The customer’s needs should still sit at the center of everything we do and that means being mindful of their problems and states of mind. We can’t exactly be playful and imaginative about a late birthday gift or an upset recipient.

Good rule of thumb:

The more stressful a situation is and the more money and time it costs the customer, the more buttoned-up, attentive, and serious we are.

When a customer approaches us with support issues or questions, we want to make sure to resolve them quickly, but also reinforce our voice and brand.
When addressing our customers:
- Keep it as brief as possible. Provide all the necessary info, but don’t be extraneous.
- Be sincere, but not overly pandering.
- Maintain a sense of humor, where appropriate (e.g. if someone is angry, don’t make jokes, that’ll just fan the flames).
- Try to avoid exclamation points unless you absolutely need them for clarity.


While we always try to be **human** and **respectful**, we can't always have an **unexpected** or **playful** element in our responses to customers. It's not appropriate to crack jokes about the customer’s lost order or their already-late birthday gift. That’d be weird.

Because every person you encounter will have a different issue and experience, every interaction should be approached carefully. You can determine the appropriate tone to use in your response based on the customer's problem, the facts, and their overall tone.


## Artists

Redbubble is a home for every voice and is reflective of almost every form of creative expression imaginable. Our recent research (conversations with artists and buyers across all regions) suggests a new form of “art” has emerged, where it’s not just about talent, but effort. Art is not a skill, it’s a voice.

Having said that, there are many contributors who consider themselves artists, and quite a few who don’t. Everyone from graphic designers to dabblers. And we want all these people to feel welcome. We want them to know that the Redbubble marketplace is open for them. So keep the **human**, **playful**, **smart**, **unexpected**, and **respectful** elements of our brand voice up front in every communication. But calibrate based on situation, of course. Always remember: balance is everything.

Some things to keep in mind:

We’re fun, approachable, and genuine:
- Be light, positive, creative, and humorous if the situation calls for it.
- Avoid marketing buzzwords, legalese or other technical language.
- Show empathy. Especially when delivering bad news.

We respect artists’ time:
- Artists want to make art. And we know that. So be clear, succinct, and don’t waste a moment with them.
- Keep sentences short.
- Stay on topic.

We understand their experience and point of view:
- If we write technical “how to” blog posts and help center articles we understand how they use Redbubble, and can guide them.
- Use straightforward, accessible language.
- We demonstrate an understanding of their creative process.
- We know how artists think.

We champion their creativity and provide support:
- We have their interests at the heart of what we do. We want them to be as successful as possible.
- We acknowledge that Redbubble would not exist without them, and we’re not afraid to let them know.
- We’re a business and sometimes that means making hard decisions (e.g. retiring products) but we genuinely want to do a great job for the artist community and it’s always a key part of our decision making.
`;

const OurAudience = {
  markdown,
  image,
  title: 'Our audience',
  subTitle: 'Who we talk to',
  slug: 'our-audience',
};

export default OurAudience;
