
/* eslint-disable no-alert */
import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';

import Card from '../Card';
import Box from '../Box';
import List, { ListItem } from '../List';
import Text from '../Text';
import * as DividerExports from '.';

// eslint-disable-next-line
import DividerSrc from '!raw-loader!.';

const { default: Divider } = DividerExports;
const docs = generateDocs(DividerSrc, resolver.findAllExportedComponentDefinitions);

const basicExample = {
  name: 'Basic',
  description: 'Dividers are thin, full-width, horizontal lines.',
  overflowVisual: true,
  render: () => (
    <Divider marginY="xs" />
  ),
  code: () => `
<Divider marginY={0.5} />
  `,
};

const contextExample = {
  name: 'In context',
  description: 'Dividers are used create distinct visual areas / groups in our UI.',
  overflowVisual: true,
  muted: true,
  render: () => (
    <Box padding="m" display="flex">
      <Card fluid>
        <Box display="flex" flexDirection="column" style={{ height: '100%' }}>
          <Box padding="m">
            <Text display="block" type="display3">Heading</Text>
            <Text>Text for the content of the Card</Text>
          </Box>
          <Divider marginTop="auto" />
          <Box padding="m">
            Card footer
          </Box>
        </Box>
      </Card>
      <Box marginX="m" />
      <Card fluid>
        <Box padding="m">
          <Text display="block" type="display5">List One</Text>
          <List>
            <ListItem>Item one</ListItem>
            <ListItem>Item two</ListItem>
            <ListItem>Item three</ListItem>
          </List>
        </Box>
        <Divider marginY="xxs" />
        <Box padding="m">
          <Text display="block" type="display5">List Two</Text>
          <List>
            <ListItem>Item one</ListItem>
            <ListItem>Item two</ListItem>
            <ListItem>Item three</ListItem>
          </List>
        </Box>
      </Card>
    </Box>
  ),
  code: () => `
<Box padding="m" display="flex">
  <Card fluid>
    <Box display="flex" flexDirection="column" style={{ height: '100%' }}>
      <Box padding="m">
        <Text display="block" type="display3">Heading</Text>
        <Text>Text for the content of the Card</Text>
      </Box>
      <Divider marginTop="auto" />
      <Box padding="m">
        Card footer
      </Box>
    </Box>
  </Card>
  <Box marginX={1} />
  <Card fluid>
    <Box padding="m">
      <Text display="block" type="display5">List One</Text>
      <List>
        <ListItem>Item one</ListItem>
        <ListItem>Item two</ListItem>
        <ListItem>Item three</ListItem>
      </List>
    </Box>
    <Divider marginY={0.25} />
    <Box padding="m">
      <Text display="block" type="display5">List Two</Text>
      <List>
        <ListItem>Item one</ListItem>
        <ListItem>Item two</ListItem>
        <ListItem>Item three</ListItem>
      </List>
    </Box>
  </Card>
</Box>
  `,
};

const Demo = {
  title: Divider.displayName,
  description: 'Separate content into clear groups with a horizontal Divider',
  docGen: docs,
  slug: 'Divider',
  exports: Object.keys(DividerExports),
  examples: [
    basicExample,
    contextExample,
  ],
  notes: `
  `,
};

export default Demo;
