import tokens from './tokens';
import { FIND_YOUR_THING } from '../ids';

const theme = {
  name: 'Find Your Thing',
  id: FIND_YOUR_THING,
  tokens,
};

export default theme;
