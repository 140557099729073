import React from 'react';
import useTypeahead from '../../hooks/useTypeahead';
import Typeahead from '../Box';
import { DESKTOP } from '../../../../../constants';


const TypeaheadDesktop = ({
  onSearchClose,
  onSearchOpen,
  logEvent: defaultLogEvent,
  doSearch: providedDoSearch,
  intl,
  term = '',
  locale = 'en',
  productContextIaCode: defaultProductContextIaCode,
  productContextLabel: defaultProductContextLabel,
  baseUrl,
  disableEventLogging,
  useRainbowStyle,
  engagingPlaceholderMessages,
  onTypeaheadResultClick,
  onSearchCallback,
}) => {
  const logEvent = disableEventLogging ? () => { } : defaultLogEvent;
  const {
    isOpen,
    getMenuProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    productContextLabel,
    inputProps,
    types,
    items,
    searchWords,
    searchTerm,
    productContextIaCode,
    logView,
    setProductContext,
    closeMenu,
    setInputValue,
    doSearch,
    popularProducts,
    quickLinks,
    otherLinks,
    topArtists,
    topArtistsLoading,
  } = useTypeahead({
    term,
    locale,
    defaultProductContextIaCode,
    defaultProductContextLabel,
    logEvent,
    intl,
    providedDoSearch,
    onSearchClose,
    onSearchOpen,
    initialIsOpen: false,
    profile: DESKTOP,
    baseUrl,
    onSearchCallback,
  });

  // Render
  return (
    <Typeahead
      intl={intl}
      term={searchTerm}
      baseUrl={baseUrl}
      completions={items}
      isOpen={isOpen}
      useRainbowStyle={useRainbowStyle}
      inputProps={inputProps}
      productContextLabel={productContextLabel}
      productContext={{ id: productContextIaCode, label: productContextLabel }}
      getComboboxProps={getComboboxProps}
      getMenuProps={getMenuProps}
      getItemProps={getItemProps}
      highlightedIndex={highlightedIndex}
      closeMenu={closeMenu}
      searchWords={searchWords}
      types={types}
      searchTerm={searchTerm}
      logView={logView}
      reset={setInputValue}
      setProductContext={setProductContext}
      doSearch={doSearch}
      popularProducts={popularProducts}
      quickLinks={quickLinks}
      otherLinks={otherLinks}
      topArtists={topArtists}
      topArtistsLoading={topArtistsLoading}
      logEvent={logEvent}
      engagingPlaceholderMessages={engagingPlaceholderMessages}
      onTypeaheadResultClick={onTypeaheadResultClick}
      onSearchCallback={onSearchCallback}
    />
  );
};

export default TypeaheadDesktop;
