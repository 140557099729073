import image from '../assets/who-we-are.svg';

/* eslint-disable max-len */
const markdown = `
Redbubble is the home for wild creativity. We want artists, customers, and employees to join us on an adventure. So we developed a personality that’s fun and welcoming. A personality you wouldn’t hate getting stuck in an elevator with.

Our voice is a huge part of how we bring that personality to life. It’s all about the words we use and how we use them. Everything we write needs to embody who we are. So when creating any communication, you should use the Redbubble voice. Let’s breakdown what the heck the Redbubble voice is.

### Human
Redbubble is full of sincere, passionate people who aren’t afraid to be honest and authentic. We don’t judge. We welcome challenging ideas, different perspectives, and new experiences.

### Playful
Being serious isn’t really our thing. We like to mess around. Maybe even get a little weird from time to time. An occasional sprinkling of absurdity never hurt nobody.

### Smart
We’re clever. And pretty witty. But not in that annoying, know-it-all kind of way. We’re not aiming for a full-on “LOL,” but an “LQTM” (Laugh Quietly To Myself) would be pretty nice.

### Unexpected
Your first idea shouldn’t necessarily be your final idea. Push yourself. Aim for surprise and delight, not obvious and blah. Leave clichés at the door. Which was kind of a cliché, tbh.

### Respectful
We’re respectful of time. Of points of view. Of the fact that customers may just be here to buy something quickly and easily. So keep things brief, useful, and engaging.
`;

const OurVoice = {
  markdown,
  image,
  title: 'Our voice',
  subTitle: 'Who we are',
  slug: 'our-voice',
};

export default OurVoice;
