import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import Input from '../Input';
import Box from '../../Box';
import _styles from './styles.scss';

const RadioField = ({
  options,
  form,
  field,
  label,
  stacked,
  onFocus,
  onBlur,
  styles,
  fluid,
}) => {
  const active = options.find(o => o.value === field.value) || {};

  if (!Array.isArray(options) || options.length === 0) return null;

  return (
    <div
      className={cnames(styles.group, { [styles.stacked]: stacked })}
      role="radiogroup"
      aria-label={label}
    >
      {
        options.map((option) => {
          const classes = cnames(styles.radio, {
            [styles.selected]: active.value === option.value,
            [styles.disabled]: option.isDisabled,
            [styles.fluid]: fluid,
          });

          return (
            // The input is nested inside the label
            /* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */
            <label
              tabIndex={-1}
              key={`${field.name}_${option.value}`}
              className={classes}
            >
              <Box className={styles.radioInput}>
                <Input
                  checked={active.value === option.value}
                  type="radio"
                  tabIndex={0}
                  onFocus={onFocus}
                  onBlur={(e) => {
                    onBlur(e);
                    field.onBlur(e);
                  }}
                  onChange={() => form.setFieldValue(field.name, option.value)}
                  name={field.name}
                  className={styles.radioInput}
                  disabled={option.isDisabled}
                />
              </Box>
              <span className={styles.radioLabel}>{ option.label }</span>
            </label>
          );
        })
      }
    </div>
  );
};

RadioField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
    isDisabled: PropTypes.bool,
  })),
  field: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
  stacked: PropTypes.bool,
  fluid: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  /**
    * The CSS classNames for this component. NEVER manually specify this prop
    */
  styles: PropTypes.shape({}),
};

RadioField.defaultProps = {
  options: [],
  stacked: false,
  fluid: false,
  onFocus: null,
  onBlur: f => f,
  styles: _styles,
};

export default RadioField;
