import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import ResponsiveConsumer from '../ResponsiveConsumer';
import Box from '../../../react/Box';
import Text from '../../../react/Text';
import ArrowRight from '../../../react/Icons/ArrowRight';
import Grid from '../../../react/Grid';
import styles from './styles.css';

class PropList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayName: this.types[0].displayName,
    };

    this.update = this.update.bind(this);
  }

  update(e) {
    this.setState({ displayName: e.target.value });
  }

  get types() {
    const { docGen } = this.props;
    let types;

    if (!Array.isArray(docGen)) {
      types = [{ displayName: docGen.displayName, props: docGen.props }];
    } else {
      types = docGen.map(doc => ({ displayName: doc.displayName, props: doc.props }));
    }

    return types;
  }

  render() {
    const { props } = this.types.find(t => t.displayName === this.state.displayName);

    if (!props) return null;

    return (
      <ResponsiveConsumer>
        {
          responsive => (
            <>
              {
                this.types.length > 1 && (
                  <Box display="flex" paddingTop="xl" marginBottom="m">
                    <Text type="body">Show props for</Text>
                    <select className={styles.pickComponent} onChange={this.update}>
                      {
                      this.types
                        .filter(componentName => componentName.props)
                        .map(t => (
                          <option key={t.displayName} value={t.displayName}>{t.displayName}</option>
                        ))
                      }
                    </select>
                  </Box>
                )
              }
              <Box>
                { !responsive.mobile && (
                  <Grid columns={responsive.mobile ? 1 : 2}>
                    <Text type="display5">Prop</Text>
                    <Text type="display5">Description</Text>
                  </Grid>
                )}
                {
                  Object.entries(props).map(([propName, data]) => {
                    const typeName = get(data, 'type.name');
                    const typeValue = get(data, 'type.value');
                    const defaultValue = get(data, 'defaultValue.value');
                    const isEnum = typeName === 'enum';
                    const description = get(data, 'description') || 'This prop needs a description';

                    if (description.toLowerCase().includes('private')) return null;

                    return (
                      <Box paddingY="m" className={styles.propsRow}>
                        <Grid key={propName} columns={responsive.mobile ? 1 : 2}>
                          <Box>
                            <span className={styles.propName}>
                              <Text type="body2">{ propName }</Text>
                            </span>
                            <Box paddingLeft="xs" display="inline-block">
                              <span className={styles.propType}>
                                <Text type="body2">{ typeName === 'arrayOf' ? `arrayOf: ${typeValue.name}` : typeName }</Text>
                              </span>
                            </Box>
                            {
                              defaultValue && (
                                <Box
                                  paddingLeft="xs"
                                  display="inline-block"
                                  className={styles.monospace}
                                >
                                  <ArrowRight /><Text type="body2" muted marginLeft="xxs">{ defaultValue }</Text>
                                </Box>
                              )
                            }
                            {
                              data.required && (
                                <Text type="body2" display="block" muted marginTop="xxs">
                                  Required
                                </Text>
                              )
                            }
                          </Box>
                          <Box>
                            {
                              isEnum && (
                                <Box marginBottom="xs">
                                  <Text type="display6" display="block">One of:</Text>
                                  {
                                    typeValue.map(v => (
                                      <Box key={v.value} marginLeft="m" className={styles.enum}>
                                        { v.value },
                                      </Box>
                                    ))
                                  }
                                </Box>
                              )
                            }

                            { description }

                          </Box>
                        </Grid>
                      </Box>
                    );
                  })
                }
                <Box paddingY="m" paddingBottom="l">
                  <Text type="display5" display="block" marginTop="l" marginBottom="m">Legend</Text>
                  <Box display="inline-block" marginRight="xs">
                    <span className={styles.propName}>
                      <Text type="body2">name</Text>
                    </span>
                  </Box>
                  <Box display="inline-block" marginRight="xs">
                    <span className={styles.propType}>
                      <Text type="body2">type</Text>
                    </span>
                  </Box>
                  <Box display="inline-block">
                    <span className={styles.monospace}>
                      <ArrowRight />
                      <Text type="body2" muted marginLeft="xxs">default value</Text>
                    </span>
                  </Box>
                </Box>
              </Box>
            </>
          )
        }
      </ResponsiveConsumer>
    );
  }
}

PropList.propTypes = {
  docGen: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
};

PropList.defaultProps = {
  docGen: {},
};

export default PropList;
