import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { defineMessages, FormattedMessage } from 'react-intl';
import { InView } from 'react-intersection-observer';
import Text from '../../../Text';
import List, { ListItem } from '../../../List';
import FooterLegal from './FooterLegal';
import FooterNav from './FooterNav';
import FooterApps from './FooterApps';
import FooterSettings from './FooterSettings';
import FooterPayments, { PaymentMethods } from './FooterPayments';

import styles from './Footer.scss';

const EARLY_LOAD_LAZY_ELEMENTS_BY_PIXELS = 1600;

const messages = defineMessages({
  studentDiscount: 'Student Discount',
});

export { PaymentMethods };

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = { currentURL: '', isInView: false };
    this.handleToggleMatureContent = this.handleToggleMatureContent.bind(this);
    this.handleInView = this.handleInView.bind(this);
    this.studentDiscountComponent = this.studentDiscountComponent.bind(this);
  }

  handleToggleMatureContent(e) {
    e.preventDefault();

    const { onToggleMatureContent, userInfo } = this.props;

    if (typeof onToggleMatureContent === 'function') {
      onToggleMatureContent(userInfo ? !userInfo.showMatureContent : false);
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ currentURL: window.location.href });
    }
  }

  studentDiscountComponent(country) {
    const { intl } = this.props;

    let postfix = '';
    switch (country) {
      case 'GB':
      case 'IE':
      case 'JE':
      case 'IM':
      case 'GG':
        postfix = 'uk';
        break;
      case undefined:
      case '':
        postfix = 'us';
        break;
      default:
        postfix = `${country.toLowerCase()}`;
    }
    const url = `https://connect.studentbeans.com/v4/hosted/redbubble/${postfix}`;

    return (
      <ListItem>
        <Text type="body">
          <a className={styles.link} href={url} rel="nofollow">
            {intl.formatMessage(messages.studentDiscount)}
          </a>
        </Text>
      </ListItem>
    );
  }

  handleInView(isInView) {
    if (isInView) {
      this.setState({ isInView });
    }
  }

  render() {
    const {
      intl,
      baseUrl,
      showApp,
      userInfo,
      showNavigation,
      showUserSettings,
      showCookiePolicyLink,
      paymentMethods,
    } = this.props;

    const { isInView } = this.state;
    const { country } = userInfo || {};
    const locale = get(userInfo, 'locale', 'en');
    const baseUrlWithLocale = `${baseUrl}${locale === 'en' ? '' : `/${locale}`}`;

    return (
      <InView
        tag="footer"
        rootMargin={`0px 0px ${EARLY_LOAD_LAZY_ELEMENTS_BY_PIXELS}px 0px`}
        onChange={this.handleInView}
        className={styles.footer}
        role="contentinfo"
      >
        {showUserSettings && (
          <FooterSettings
            baseUrl={baseUrlWithLocale}
            intl={intl}
            userInfo={userInfo}
            returnUrl={this.state.currentURL}
            onToggleMatureContent={this.handleToggleMatureContent}
            parentStyles={styles}
            isInView={isInView}
          />
        )}
        {showNavigation && (
          <FooterNav
            userInfo={userInfo}
            baseUrl={baseUrlWithLocale}
            studentDiscountComponent={this.studentDiscountComponent}
            country={country}
            locale={locale}
            parentStyles={styles}
            intl={intl}
          />
        )}
        <FooterLegal
          parentStyles={styles}
          baseUrl={baseUrlWithLocale}
          intl={intl}
          country={country}
          locale={locale}
          showCookiePolicyLink={showCookiePolicyLink}
        />
        {paymentMethods.length > 0 && (
          <FooterPayments
            parentStyles={styles}
            intl={intl}
            paymentMethods={paymentMethods}
          />
        )}
        {showApp && (
          <FooterApps
            locale={locale}
            parentStyles={styles}
            intl={intl}
          />
        )}
        <div className={styles.footerBottom}>
          <div className={styles.footerContainer}>
            <List className={styles.listHorizontal}>
              <ListItem>
                <Text type="caption">
                  <FormattedMessage defaultMessage="&copy; Redbubble. All Rights Reserved" />
                </Text>
              </ListItem>
            </List>
          </div>
        </div>
      </InView>
    );
  }
}

Footer.propTypes = {
  // eslint-disable-next-line react/no-typos
  intl: PropTypes.shape({}).isRequired,
  userInfo: PropTypes.shape({
    locale: PropTypes.string,
    country: PropTypes.string,
  }),
  showCookiePolicyLink: PropTypes.bool,
  onToggleMatureContent: PropTypes.func,
  showUserSettings: PropTypes.bool,
  showApp: PropTypes.bool,
  showNavigation: PropTypes.bool,
  baseUrl: PropTypes.string,
  paymentMethods: PropTypes.arrayOf(PropTypes.string),
};

Footer.defaultProps = {
  userInfo: {},
  onToggleMatureContent: () => { },
  showUserSettings: true,
  showApp: true,
  showNavigation: true,
  baseUrl: '',
  showCookiePolicyLink: false,
  paymentMethods: [],
};

Footer.displayName = 'Footer';

export default Footer;
