import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import RCTable from 'rc-table';
import styles from './styles.scss';
import Text from '../Text';
import useTracking, { trackingShape } from '../hooks/useTracking';

const TH = ({ children, isBold = true, ...props }) => {
  return (
    <th {...props}>
      <Text type={isBold ? 'display5' : 'body'}>{ children }</Text>
    </th>
  );
};

const TD = ({ children, ...props }) => (
  <td {...props}>
    <Text>{ children }</Text>
  </td>
);

const Table = ({
  columns,
  rows,
  onRow,
  fixedFirstColumn,
  headingFirstColumn,
  rowKey,
  tracking,
}) => {
  const { TrackingContextProvider } = useTracking(tracking, 'Table');
  const clickable = typeof onRow === 'function';

  const classNames = cnames(styles.table, {
    [styles.notFixed]: !fixedFirstColumn,
    [styles.clickable]: clickable,
  });

  return (
    <TrackingContextProvider>
      <div className={classNames}>
        <RCTable
          onRow={onRow}
          style={{ width: '100%' }}
          scroll={fixedFirstColumn ? { x: true } : { x: false }}
          rowKey={rowKey}
          columns={columns.map((c, i) => ({
            ...(i === 0 && fixedFirstColumn ? { fixed: 'left' } : {}),
            dataIndex: c.dataIndex,
            title: c.title,
            width: c.width,
            align: c.align,
            onCell: c.onCell,
            onHeaderCell: c.onHeaderCell,
          }))}
          data={rows}
          components={{
            header: {
              cell: props => <TH {...props} scope="col" />,
            },
            body: {
              row: ({ children, ...props }) => {
                return (
                  <tr {...props}>
                    {React.Children.map(children, (child, index) => {
                      return React.cloneElement(child, {
                        component: index === 0 && headingFirstColumn ? ownProps => <TH {...ownProps} scope="row" /> : TD,
                      });
                    })}
                  </tr>
                );
              },
            },
          }}
        />
      </div>
    </TrackingContextProvider>
  );
};

Table.displayName = 'Table';

Table.propTypes = {
  /**
    * Information about each column in the table
    */
  columns: PropTypes.arrayOf(PropTypes.shape({
    /**
      * The key of each row of data to show in this column
      */
    dataIndex: PropTypes.string.isRequired,
    /**
      * The human readable title displayed at the top of this column
      */
    title: PropTypes.string.isRequired,
    /**
      * The width of the column
      */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
      * The alignment of text in the column
      */
    align: PropTypes.oneOf(['left', 'right', 'center']),
    /**
      * Function to set custom props per each cell
      */
    onCell: PropTypes.func,
    /**
      * Function to set custom props per each header cell
      */
    onHeaderCell: PropTypes.func,
  })).isRequired,
  /**
    * Rows of data to be displayed in the table
    */
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  /**
    * For attaching events to table rows
    */
  onRow: PropTypes.func,
  /**
    * Which key of your data rows to use as the React key
    */
  rowKey: PropTypes.string.isRequired,
  /**
    * Whether or not to fix the first column in place as a contextual column
    */
  fixedFirstColumn: PropTypes.bool,
  /**
    * Whether or not to make the first column appear as headings
    */
  headingFirstColumn: PropTypes.bool,
  /**
    * Tracking configuration for auto tracking
    */
  tracking: trackingShape,
};

Table.defaultProps = {
  fixedFirstColumn: false,
  headingFirstColumn: false,
  tracking: null,
};

export default Table;
