import { useState, useEffect } from 'react';
import get from 'lodash/get';
import {
  handleFetchError,
  formatData,
  formatArtists,
  typeaheadRequest,
} from '../utils';
import { analyticsPayload } from '../../../lib/analytics';
import {
  POPULAR,
  TRENDING,
  ARTISTS,
} from '../constants';
import { MOBILE } from '../../../../constants';

const POPULAR_DISPLAY_SIZE = 4;
const TRENDING_DISPLAY_SIZE = 3;
const ARTISTS_DISPLAY_SIZE = 3;

export default function useTypeaheadData(
  term = '',
  locale,
  productContextIaCode,
  baseUrl,
  profile,
  logEvent,
) {
  const [typeaheadData, setTypeaheadData] = useState();
  const [typeaheadStaticLinks, setTypeaheadStaticLinks] = useState();
  const [currentSearchTerm, setCurrentSearchTerm] = useState(term);
  const [topArtists, setTopArtists] = useState([]);
  const [topArtistsLoading, setTopArtistsLoading] = useState(false);

  const abortController = typeof window !== 'undefined' && window.AbortController
    ? new window.AbortController()
    : undefined;

  const abort = () => {
    if (typeof get(abortController, 'abort') === 'function') {
      abortController.abort();
    }
  };

  const fetchTypeaheadData = (params) => {
    typeaheadRequest(params, abortController)
      .then((data) => {
        setTypeaheadData(get(data, 'data', {}));
        setCurrentSearchTerm(term);
      })
      .catch((e) => {
        handleFetchError(e);
      });
  };

  const fetchTypeaheadStaticLinks = (params) => {
    typeaheadRequest(params)
      .then((data) => {
        setTypeaheadStaticLinks(get(data, 'data', {}));
      })
      .catch((e) => {
        handleFetchError(e);
      });
  };

  const fetchTopArtists = (params) => {
    typeaheadRequest(params)
      .then((data) => {
        setTopArtists(get(data, 'data.artists', []));
        setTopArtistsLoading(false);
      })
      .catch((e) => {
        setTopArtistsLoading(false);
        handleFetchError(e);
      });
  };

  useEffect(() => {
    let updatable = true;

    if (updatable) {
      // Initial request for top artists regardless of the term
      setTopArtistsLoading(true);
      fetchTopArtists({ term: '', locale });
      fetchTypeaheadStaticLinks({ static: true, locale });

      if (profile === MOBILE) {
        logEvent({
          analytics: analyticsPayload.typeaheadExperienceOpened({
            inputValue: term,
            productContextIaCode,
          }),
        });
      }
    }

    return () => {
      updatable = false;
      abort();
    };
  }, []);

  useEffect(() => {
    let updatable = true;

    if (updatable) {
      fetchTypeaheadData({ term, locale });
    }

    return () => {
      updatable = false;
      abort();
    };
  }, [term, locale, productContextIaCode]);

  const popular = formatData(
    get(typeaheadData, 'popular_searches', []),
    POPULAR,
    productContextIaCode,
    POPULAR_DISPLAY_SIZE,
  );

  const trending = formatData(
    get(typeaheadData, 'trending_searches', []),
    TRENDING,
    productContextIaCode,
    TRENDING_DISPLAY_SIZE,
  );

  const artists = formatArtists(
    get(typeaheadData, 'artists', []),
    ARTISTS,
    baseUrl,
    ARTISTS_DISPLAY_SIZE,
  );

  const popularProducts = get(typeaheadStaticLinks, 'popularProducts', {});
  const otherLinks = get(typeaheadStaticLinks, 'otherLinks', {});
  const quickLinks = get(typeaheadStaticLinks, 'quickLinks', {});

  return {
    popular,
    trending,
    artists,
    topArtists,
    topArtistsLoading,
    currentSearchTerm,
    popularProducts,
    otherLinks,
    quickLinks,
  };
}
