import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import Box from '../Box';
import Text from '../Text';
import Drawer from '../Drawer';
import * as constants from '../constants';
import Button from '../Button';

import * as SheetsExports from '.';

// eslint-disable-next-line
import SheetsSrc from '!raw-loader!.';

const { default: Sheets, Sheet } = SheetsExports;
let docs = generateDocs(SheetsSrc, resolver.findAllExportedComponentDefinitions);

const simpleExample = {
  name: 'Simple Example',
  description: `
Sheets fill their container, and are best used in views that are edge to edge like <code>Modals</code> and <code>Drawers</code>.

<br /><br />

Note that a custom action can be rendered in the top right of the Sheets.
  `,
  overflowVisual: false,
  muted: true,
  render: responsive => (
    <Drawer
      accessibleTitle="Test"
      trigger={({ onClick }) => (
        <Button strong intent={constants.PRIMARY} onClick={onClick}>Open drawer</Button>
      )}
      size={responsive.desktop ? constants.SMALL : constants.LARGE}
      from={responsive.desktop ? constants.RIGHT : constants.BOTTOM}
    >
      {
        ({ close }) => (
          <Sheets
            title="Settings"
            rightAction={(
              <Button
                onClick={close}
                size={constants.SMALL}
                intent={constants.PRIMARY}
              >
                Done
              </Button>
            )}
            sheets={{
              general: (
                <Sheet
                  title="General"
                  sheets={{
                    about: (
                      <Sheet title="About">
                        <Box padding="m">
                          <Text>
                            This is the content of the About page
                          </Text>
                        </Box>
                      </Sheet>
                    ),
                  }}
                >
                  {({ openSheet }) => (
                    <Box padding="m">
                      <Text display="block">
                        This is the content of the General page
                      </Text>

                      <Box padding="xs" />
                      <Button strong fluid onClick={() => openSheet('about')}>Go to About</Button>
                    </Box>
                  )}
                </Sheet>
              ),
            }}
          >
            {({ openSheet }) => (
              <Box padding="m">
                <Text>
                  This is the content of the Settings page
                </Text>

                <Box padding="xs" />
                <Button strong fluid onClick={() => openSheet('general')}>Go to General</Button>
              </Box>
            )}
          </Sheets>
        )
      }
    </Drawer>
  ),
  code: responsive => `
<Drawer
  accessibleTitle="Test"
  trigger={({ onClick }) => (
    <Button strong intent={constants.PRIMARY} onClick={onClick}>Open drawer</Button>
  )}
  size={${responsive.desktop ? 'constants.SMALL' : 'constants.LARGE'}}
  from={${responsive.desktop ? 'constants.RIGHT' : 'constants.BOTTOM'}}
>
  {
    ({ close }) => (
      <Sheets
        title="Settings"
        rightAction={(
          <Button
            onClick={close}
            size={constants.SMALL}
            intent={constants.PRIMARY}
          >
            Done
          </Button>
        )}
        sheets={{
          general: (
            <Sheet
              title="General"
              sheets={{
                about: (
                  <Sheet title="About">
                    <Box padding="m">
                      <Text>
                        This is the content of the About page
                      </Text>
                    </Box>
                  </Sheet>
                ),
              }}
            >
              {({ openSheet }) => (
                <Box padding="m">
                  <Text display="block">
                    This is the content of the General page
                  </Text>

                  <Box padding="xs" />
                  <Button strong fluid onClick={() => openSheet('about')}>Go to About</Button>
                </Box>
              )}
            </Sheet>
          ),
        }}
      >
        {({ openSheet }) => (
          <Box padding="m">
            <Text>
              This is the content of the Settings page
            </Text>

            <Box padding="xs" />
            <Button strong fluid onClick={() => openSheet('general')}>Go to General</Button>
          </Box>
        )}
      </Sheets>
    )
  }
</Drawer>
  `,
};
const multipleSheetsExample = {
  name: 'Multiple Sheets',
  description: '`Sheets` can have several `Sheet` components nested within one another.',
  overflowVisual: false,
  muted: true,
  render: responsive => (
    <Drawer
      accessibleTitle="Test"
      trigger={({ onClick }) => (
        <Button strong intent={constants.PRIMARY} onClick={onClick}>Open drawer</Button>
      )}
      size={responsive.desktop ? constants.SMALL : constants.LARGE}
      from={responsive.desktop ? constants.RIGHT : constants.BOTTOM}
    >
      <Sheets
        title="Root"
        sheets={{
          sheetA: (
            <Sheet
              title="Sheet A"
              sheets={{
                sheetA1: (
                  <Sheet title="Sheet A.1">
                    <Box padding="m">
                      <Text>
                        This is the content of Sheet A.1
                      </Text>
                    </Box>
                  </Sheet>
                ),
                sheetA2: (
                  <Sheet title="Sheet A.2">
                    <Box padding="m">
                      <Text>
                        This is the content of Sheet A.2
                      </Text>
                    </Box>
                  </Sheet>
                ),
              }}
            >
              {({ openSheet, goBack }) => (
                <Box padding="m">
                  <Text display="block">
                    This is the content of Sheet A
                  </Text>

                  <Box padding="xs" />
                  <Button strong fluid onClick={() => openSheet('sheetA1')}>Go to Sheet A.1</Button>
                  <Box padding="xs" />
                  <Button strong fluid onClick={() => openSheet('sheetA2')}>Go to Sheet A.2</Button>
                  <Box padding="xs" />
                  <Button fluid onClick={() => goBack()}>Go back to Root</Button>
                </Box>
              )}
            </Sheet>
          ),
          sheetB: (
            <Sheet
              title="Sheet A"
              sheets={{
                sheetB1: (
                  <Sheet title="Sheet B.1">
                    <Box padding="m">
                      <Text>
                        This is the content of Sheet B.1
                      </Text>
                    </Box>
                  </Sheet>
                ),
                sheetB2: (
                  <Sheet title="Sheet B.2">
                    <Box padding="m">
                      <Text>
                        This is the content of Sheet B.2
                      </Text>
                    </Box>
                  </Sheet>
                ),
              }}
            >
              {({ openSheet, goBack }) => (
                <Box padding="m">
                  <Text display="block">
                    This is the content of Sheet B
                  </Text>

                  <Box padding="xs" />
                  <Button strong fluid onClick={() => openSheet('sheetB1')}>Go to Sheet B.1</Button>
                  <Box padding="xs" />
                  <Button strong fluid onClick={() => openSheet('sheetB2')}>Go to Sheet B.2</Button>
                  <Box padding="xs" />
                  <Button fluid onClick={() => goBack()}>Go back to Root</Button>
                </Box>
              )}
            </Sheet>
          ),
        }}
      >
        {({ openSheet }) => (
          <Box padding="m">
            <Text>
              This is the content of the Root page
            </Text>

            <Box padding="xs" />
            <Button strong fluid onClick={() => openSheet('sheetA')}>Go to Sheet A</Button>
            <Box padding="xs" />
            <Button strong fluid onClick={() => openSheet('sheetB')}>Go to Sheet B</Button>
          </Box>
        )}
      </Sheets>
    </Drawer>
  ),
  code: responsive => `
<Drawer
  accessibleTitle="Test"
  trigger={({ onClick }) => (
    <Button strong intent={constants.PRIMARY} onClick={onClick}>Open drawer</Button>
  )}
  size={${responsive.desktop ? 'constants.SMALL' : 'constants.LARGE'}}
  from={${responsive.desktop ? 'constants.RIGHT' : 'constants.BOTTOM'}}
>
  <Sheets
    title="Root"
    sheets={{
      sheetA: (
        <Sheet
          title="Sheet A"
          sheets={{
            sheetA1: (
              <Sheet title="Sheet A.1">
                <Box padding="m">
                  <Text>
                    This is the content of Sheet A.1
                  </Text>
                </Box>
              </Sheet>
            ),
            sheetA2: (
              <Sheet title="Sheet A.2">
                <Box padding="m">
                  <Text>
                    This is the content of Sheet A.2
                  </Text>
                </Box>
              </Sheet>
            ),
          }}
        >
          {({ openSheet, goBack }) => (
            <Box padding="m">
              <Text display="block">
                This is the content of Sheet A
              </Text>

              <Box padding="xs" />
              <Button strong fluid onClick={() => openSheet('sheetA1')}>Go to Sheet A.1</Button>
              <Box padding="xs" />
              <Button strong fluid onClick={() => openSheet('sheetA2')}>Go to Sheet A.2</Button>
              <Box padding="xs" />
              <Button fluid onClick={() => goBack()}>Go back to Root</Button>
            </Box>
          )}
        </Sheet>
      ),
      sheetB: (
        <Sheet
          title="Sheet A"
          sheets={{
            sheetB1: (
              <Sheet title="Sheet B.1">
                <Box padding="m">
                  <Text>
                    This is the content of Sheet B.1
                  </Text>
                </Box>
              </Sheet>
            ),
            sheetB2: (
              <Sheet title="Sheet B.2">
                <Box padding="m">
                  <Text>
                    This is the content of Sheet B.2
                  </Text>
                </Box>
              </Sheet>
            ),
          }}
        >
          {({ openSheet, goBack }) => (
            <Box padding="m">
              <Text display="block">
                This is the content of Sheet B
              </Text>

              <Box padding="xs" />
              <Button strong fluid onClick={() => openSheet('sheetB1')}>Go to Sheet B.1</Button>
              <Box padding="xs" />
              <Button strong fluid onClick={() => openSheet('sheetB2')}>Go to Sheet B.2</Button>
              <Box padding="xs" />
              <Button fluid onClick={() => goBack()}>Go back to Root</Button>
            </Box>
          )}
        </Sheet>
      ),
    }}
  >
    {({ openSheet }) => (
      <Box padding="m">
        <Text>
          This is the content of the Root page
        </Text>

        <Box padding="xs" />
        <Button strong fluid onClick={() => openSheet('sheetA')}>Go to Sheet A</Button>
        <Box padding="xs" />
        <Button strong fluid onClick={() => openSheet('sheetB')}>Go to Sheet B</Button>
      </Box>
    )}
  </Sheets>
</Drawer>
  `,
};

const hideTitleBarExample = {
  name: 'No title bar',
  description: 'You can hide the title bar, and build your own navigation if necessary',
  overflowVisual: false,
  muted: true,
  render: responsive => (
    <Drawer
      accessibleTitle="Test"
      trigger={({ onClick }) => (
        <Button strong intent={constants.PRIMARY} onClick={onClick}>Open drawer</Button>
      )}
      size={responsive.desktop ? constants.SMALL : constants.LARGE}
      from={responsive.desktop ? constants.RIGHT : constants.BOTTOM}
    >
      <Sheets
        title="One"
        hideTitleBar
        sheets={{
          two: (
            <Sheet
              title="Two"
              sheets={{
                three: (
                  <Sheet title="Three">
                    {
                      ({ goBack }) => (
                        <Box padding="m">
                          <Text>
                            This is Page 3
                          </Text>

                          <Box padding="xs" />
                          <Button strong fluid onClick={() => goBack()}>Back</Button>
                        </Box>
                      )
                    }
                  </Sheet>
                ),
              }}
            >
              {({ openSheet, goBack }) => (
                <Box padding="m">
                  <Text display="block">
                    This is Page 2
                  </Text>

                  <Box padding="xs" />
                  <Button strong fluid onClick={() => openSheet('three')}>Next</Button>
                  <Box padding="xs" />
                  <Button strong fluid onClick={() => goBack()}>Back</Button>
                </Box>
              )}
            </Sheet>
          ),
        }}
      >
        {({ openSheet }) => (
          <Box padding="m">
            <Text display="block">
              This is Page 1
            </Text>

            <Box padding="xs" />
            <Button strong fluid onClick={() => openSheet('two')}>Next</Button>
          </Box>
        )}
      </Sheets>
    </Drawer>
  ),
  code: responsive => `
<Drawer
  accessibleTitle="Test"
  trigger={({ onClick }) => (
    <Button strong intent={constants.PRIMARY} onClick={onClick}>Open drawer</Button>
  )}
  size={${responsive.desktop ? 'constants.SMALL' : 'constants.LARGE'}}
  from={${responsive.desktop ? 'constants.RIGHT' : 'constants.BOTTOM'}}
>
  <Sheets
    title="One"
    hideTitleBar
    sheets={{
      two: (
        <Sheet
          title="Two"
          sheets={{
            three: (
              <Sheet title="Three">
                {
                  ({ goBack }) => (
                    <Box padding="m">
                      <Text>
                        This is Page 3
                      </Text>

                      <Box padding="xs" />
                      <Button strong fluid onClick={() => goBack()}>Back</Button>
                    </Box>
                  )
                }
              </Sheet>
            ),
          }}
        >
          {({ openSheet, goBack }) => (
            <Box padding="m">
              <Text display="block">
                This is Page 2
              </Text>

              <Box padding="xs" />
              <Button strong fluid onClick={() => openSheet('three')}>Next</Button>
              <Box padding="xs" />
              <Button strong fluid onClick={() => goBack()}>Back</Button>
            </Box>
          )}
        </Sheet>
      ),
    }}
  >
    {({ openSheet }) => (
      <Box padding="m">
        <Text display="block">
          This is Page 1
        </Text>

        <Box padding="xs" />
        <Button strong fluid onClick={() => openSheet('two')}>Next</Button>
      </Box>
    )}
  </Sheets>
</Drawer>
  `,
};

docs = docs.map(doc => ({
  ...doc,
  props: Object.entries(doc.props || {}).reduce((memo, [name, details]) => {
    if (details.description.toLowerCase() === 'private') return memo;

    return { ...memo, [name]: details };
  }, {}),
}));

const Demo = {
  title: Sheets.displayName,
  description: 'Sheets are designed for presenting complex, nested sheets of content.',
  docGen: docs,
  slug: 'Sheets',
  exports: Object.keys(SheetsExports),
  examples: [
    simpleExample,
    multipleSheetsExample,
    hideTitleBarExample,
  ],
  notes: `
  `,
};

export default Demo;
