import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';

const FileField = ({
  field,
  form,
  placeholder,
  accept,
  onBlur,
  onFocus,
  disabled,
}) => (
  <Input
    type="file"
    disabled={disabled}
    name={field.name}
    onChange={e => form.setFieldValue(field.name, e.currentTarget.files[0])}
    onFocus={onFocus}
    onBlur={(e) => {
      onBlur(e);
      form.setFieldTouched(field.name, true);
    }}
    accept={accept}
    placeholder={placeholder}
  />
);

FileField.propTypes = {
  field: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
  placeholder: PropTypes.string,
  accept: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

FileField.defaultProps = {
  placeholder: '',
  accept: null,
  onFocus: null,
  onBlur: f => f,
  disabled: false,
};

export default FileField;
