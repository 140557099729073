import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import List, { ListItem } from '../../List';
import Button from '../../Button';
import * as constants from '../../constants';
import Text from '../../Text';
import Box from '../../Box';
import Avatar from '../../Avatar';
import ChevronLeftIcon from '../../Icons/ChevronLeft';
import { appendLocaleToUrl } from '../utils';

const messages = defineMessages({
  avatarAlt: 'Avatar for {username}',
  dashboard: 'Dashboard',
  activityFeed: 'Activity Feed',
  orderHistory: 'Order History',
  bubbleMail: 'BubbleMail',
  lists: 'Your Lists',
  accountSettings: 'Account Settings',
  addNewWork: 'Add New Work',
  managePortfolio: 'Manage Portfolio',
  rbBlog: 'RB Blog',
  logout: 'Logout',
  shop: 'View Shop',
  favorites: 'Favorites',
  sellYourArt: 'Sell Your Art',
});

const ArtistCallToAction = ({ intl, onboardedAt, baseUrl, showBottomBorder }) => {
  const addNewWork = intl.formatMessage(messages.addNewWork);
  const sellYourArt = intl.formatMessage(messages.sellYourArt);
  const style = showBottomBorder ? {
    borderBottom: 'var(--ds-size-border-width-small) solid var(--ds-color-border-light)',
  } : {};

  return (
    <Box
      padding="m"
      marginBottom="xs"
      style={style}
    >
      <Button
        strong
        size={constants.LARGE}
        intent={constants.PRIMARY}
        href={onboardedAt
          ? `${baseUrl}/portfolio/images/new?ref=account-nav-dropdown`
          : `${baseUrl}/studio/dashboard`}
        fluid
      >
        { onboardedAt ? addNewWork : sellYourArt}
      </Button>
    </Box>
  );
};

const UserNav = ({
  intl,
  locale,
  username,
  isArtist,
  avatar,
  onboardedAt,
  closeUserNav,
}) => {
  const baseUrl = appendLocaleToUrl('https://www.redbubble.com', locale);
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        padding="m"
        marginBottom="xs"
        style={{ backgroundColor: 'var(--ds-color-background-alt-100)' }}
      >
        <Box style={{ position: 'absolute' }}>
          <Button
            strong
            circle
            onClick={closeUserNav}
            elevation={constants.ELEVATION_LOW}
            iconBefore={<ChevronLeftIcon />}
          />
        </Box>
        <Box
          paddingLeft="xl"
          paddingRight="xl"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          flex="1"
          style={{ textAlign: 'center' }}
        >
          <Avatar
            src={avatar}
            size={constants.SMALL}
            alt={intl.formatMessage(messages.avatarAlt, { username })}
          />
          <Box marginBottom="xs" />
          <Text type="display5" display="block">{ username }</Text>
        </Box>
      </Box>
      <List>
        {isArtist && (
          <>
            <ListItem
              element="a"
              href={`${baseUrl}/studio/dashboard?ref=account-nav-dropdown`}
            >
              <Box padding="m" paddingTop="xs" paddingBottom="xs">
                <Text display="block">
                  {intl.formatMessage(messages.dashboard)}
                </Text>
              </Box>
            </ListItem>
            <ListItem
              element="a"
              href={`${baseUrl}/people/${username}/shop?ref=account-nav-dropdown`}
            >
              <Box padding="m" paddingTop="xs" paddingBottom="xs">
                <Text type="body" display="block">
                  {intl.formatMessage(messages.shop)}
                </Text>
              </Box>
            </ListItem>
            <ListItem
              element="a"
              href={`${baseUrl}/studio/activity?ref=account-nav-dropdown`}
            >
              <Box padding="m" paddingTop="xs" paddingBottom="xs">
                <Text display="block">
                  {intl.formatMessage(messages.activityFeed)}
                </Text>
              </Box>
            </ListItem>
            <ListItem
              element="a"
              href={`${baseUrl}/portfolio/manage_works?ref=account-nav-dropdown`}
            >
              <Box padding="m" paddingTop="xs" paddingBottom="xs">
                <Text display="block">
                  {intl.formatMessage(messages.managePortfolio)}
                </Text>
              </Box>
            </ListItem>
            <ArtistCallToAction
              baseUrl={baseUrl}
              intl={intl}
              onboardedAt={onboardedAt}
              showBottomBorder
            />
          </>
        )}
        <ListItem
          element="a"
          href={`${baseUrl}/orders?ref=account-nav-dropdown`}
        >
          <Box
            padding="m"
            paddingTop={isArtist ? 0.25 : 0.5}
            paddingBottom={isArtist ? 0.25 : 0.5}
          >
            <Text display="block" type={isArtist ? 'body2' : 'body'}>
              {intl.formatMessage(messages.orderHistory)}
            </Text>
          </Box>
        </ListItem>
        <ListItem
          element="a"
          href={`${baseUrl}/account/bubblemails?ref=account-nav-dropdown`}
        >
          <Box
            padding="m"
            paddingTop={isArtist ? 0.25 : 0.5}
            paddingBottom={isArtist ? 0.25 : 0.5}
          >
            <Text display="block" type={isArtist ? 'body2' : 'body'}>
              {intl.formatMessage(messages.bubbleMail)}
            </Text>
          </Box>
        </ListItem>
        <ListItem
          element="a"
          href={`${baseUrl}/account/settings?ref=account-nav-dropdown`}
        >
          <Box
            padding="m"
            paddingTop={isArtist ? 0.25 : 0.5}
            paddingBottom={isArtist ? 0.25 : 0.5}
          >
            <Text display="block" type={isArtist ? 'body2' : 'body'}>
              {intl.formatMessage(messages.accountSettings)}
            </Text>
          </Box>
        </ListItem>

        {!isArtist && (
          <>
            <ListItem
              element="a"
              href={`${baseUrl}/portfolio/manage_works?ref=account-nav-dropdown`}
            >
              <Box padding="m" paddingTop="xs" paddingBottom="xs">
                <Text display="block">
                  {intl.formatMessage(messages.managePortfolio)}
                </Text>
              </Box>
            </ListItem>
            <ArtistCallToAction baseUrl={baseUrl} intl={intl} onboardedAt={onboardedAt} />
          </>
        )}
      </List>
      <Box
        marginBottom="xs"
        marginTop="xs"
        style={{ borderTop: 'var(--ds-size-border-width-small) solid var(--ds-color-border-light)' }}
      />
      <List>
        <ListItem
          element="a"
          href={`https://blog.redbubble.com/${locale === 'en' ? '' : `${locale}/`}`}
        >
          <Box padding="m" paddingTop="xxs" paddingBottom="xxs">
            <Text type="body2" display="block">
              {intl.formatMessage(messages.rbBlog)}
            </Text>
          </Box>
        </ListItem>
        <Box
          marginBottom="xs"
          marginTop="xs"
          style={{ borderTop: 'var(--ds-size-border-width-small) solid var(--ds-color-border-light)' }}
        />
        <ListItem
          element="a"
          href={`${baseUrl}/auth/logout?ref=account-nav-dropdown`}
        >
          <Box
            padding="m"
            paddingTop="xxs"
            paddingBottom="xxs"
            style={{ color: 'var(--ds-color-primary)' }}
          >
            <Text type="body2" display="block">
              {intl.formatMessage(messages.logout)}
            </Text>
          </Box>
        </ListItem>
      </List>
      <Box paddingBottom="xl" />
    </>
  );
};

UserNav.propTypes = {
  // eslint-disable-next-line react/no-typos
  intl: PropTypes.shape({}).isRequired,
  locale: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  isArtist: PropTypes.bool.isRequired,
  closeUserNav: PropTypes.func.isRequired,
};

export default UserNav;
