import React from 'react';
import Markdown from '../../../components/Markdown';
import PageIntro from '../../../components/PageIntro';
import PageTitle from '../../../components/PageTitle';
import PageDescription from '../../../components/PageDescription';

const PersonalityPage = () => {
  return (
    <>
      <PageIntro>
        <PageTitle>Personality</PageTitle>
        <PageDescription>
          At Redbubble, we know that the personality of our brand is a big part of our success.
          So when creating any communication, keep the following principles in mind.
        </PageDescription>
      </PageIntro>
      <Markdown
        source={`
          ## Human

          Redbubble is full of sincere, passionate people who aren’t afraid to be honest and authentic. We don’t judge. We welcome challenging ideas, different perspectives, and new experiences.

          ## Playful

          Being serious isn’t really our thing. We like to mess around. Maybe even get a little weird from time to time. An occasional sprinkling of absurdity never hurt nobody.

          ## Smart

          We’re clever. And pretty witty. But not in that annoying, know-it-all kind of way. We’re not aiming for a full-on “LOL,” but an “LQTM” (Laugh Quietly To Myself) would be pretty nice.

          ## Unexpected

          Your first idea shouldn’t necessarily be your final idea. Push yourself. Aim for surprise and delight, not obvious and blah. Leave clichés at the door. Which was kind of a cliché, tbh.

          ## Respectful

          We’re respectful of time. Of points of view. Of the fact that customers may just be here to buy something quickly and easily. So keep things brief, useful, and engaging.
`}
      />
    </>
  );
};

export default PersonalityPage;
