import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import Avatar from '../../../../Avatar';
import * as constants from '../../../../constants';

const messages = defineMessages({
  account: '{username} account',
});

const AvatarWrapper = ({
  username,
  intl,
  avatar,
}) => (
  <Avatar
    username={username}
    src={avatar}
    size={constants.TINY}
    alt={intl.formatMessage(messages.account, { username })}
  />
);

AvatarWrapper.propTypes = {
  // eslint-disable-next-line react/no-typos
  intl: PropTypes.shape({}).isRequired,
  username: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
};

export default AvatarWrapper;
