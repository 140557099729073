import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import { defineMessages } from 'react-intl';
import styles from './styles.scss';
import Text from '../../../../../Text';
import List, { ListItem } from '../../../../../List';
import Avatar from '../../../../../Avatar';
import Button from '../../../../../Button';
import Box from '../../../../../Box';
import * as constants from '../../../../../constants';

const messages = defineMessages({
  avatarAlt: 'Avatar for {username}',
  activityFeed: 'Activity Feed',
  accountSettings: 'Account Settings',
  addNewWork: 'Add New Work',
  bubbleMail: 'BubbleMail',
  dashboard: 'Dashboard',
  lists: 'Your Lists',
  logout: 'Logout',
  managePortfolio: 'Manage Portfolio',
  orderHistory: 'Order History',
  rbBlog: 'RB Blog',
  sellYourArt: 'Sell Your Art',
  shop: 'View Shop',
});

const ArtistCallToAction = ({ intl, onboardedAt, baseUrl }) => {
  const addNewWork = intl.formatMessage(messages.addNewWork);
  const sellYourArt = intl.formatMessage(messages.sellYourArt);
  return (
    <Box padding="xs" paddingLeft="xl" paddingRight="xl">
      <Button
        strong
        intent={constants.PRIMARY}
        href={onboardedAt
          ? `${baseUrl}/portfolio/images/new?ref=account-nav-dropdown`
          : `${baseUrl}/studio/dashboard`}
        fluid
      >
        { onboardedAt ? addNewWork : sellYourArt}
      </Button>
    </Box>
  );
};

// eslint-disable-next-line react/prop-types
const NavHeader = ({ avatar, username, href, intl }) => (
  <a href={href} className={styles.profileLink}>
    <Box padding="m" display="flex" flexDirection="column" justifyContent="center" alignItems="center" className={styles.navHeader}>
      <Avatar
        src={avatar}
        size={constants.SMALL}
        alt={intl.formatMessage(messages.avatarAlt, { username })}
      />
      <Box marginBottom="xs" />
      <Text type="display5" display="block">{ username }</Text>
    </Box>
  </a>
);

// eslint-disable-next-line react/prop-types
const NavListItem = ({ href, text, icon, subnav, primary, iconBefore }) => (
  <ListItem className={cnames(styles.listItem, { [styles.withIcon]: !!icon })}>
    <a className={styles.listLink} href={href} title={text}>
      <Box
        padding="xl"
        paddingTop={subnav ? 0.25 : 0.5}
        paddingBottom={subnav ? 0.25 : 0.5}
        style={{ color: primary && 'var(--ds-color-primary)' }}
        display="flex"
        alignItems="center"
      >
        {iconBefore && (
          <Box marginRight="m" display="flex">{iconBefore}</Box>
        )}
        <Text type={subnav ? 'body2' : 'body'}>{ text }</Text>
      </Box>
    </a>
  </ListItem>
);

const Nav = ({ username, avatar, intl, baseUrl, isArtist, locale, onboardedAt }) => {
  const profileUrl = `${baseUrl}/people/${username}`;
  return (
    <Box className={styles.wrapper}>
      <NavHeader href={profileUrl} avatar={avatar} username={username} intl={intl} />
      <Box
        paddingBottom="xs"
        paddingTop="xs"
      >
        {isArtist && (
          <List>
            <NavListItem
              href={`${baseUrl}/studio/dashboard?ref=account-nav-dropdown`}
              text={intl.formatMessage(messages.dashboard)}
            />
            <NavListItem
              href={`${baseUrl}/people/${username}/shop?ref=account-nav-dropdown`}
              text={intl.formatMessage(messages.shop)}
            />
            <NavListItem
              href={`${baseUrl}/studio/activity?ref=account-nav-dropdown`}
              text={intl.formatMessage(messages.activityFeed)}
            />
            <NavListItem
              href={`${baseUrl}/portfolio/manage_works?ref=account-nav-dropdown`}
              text={intl.formatMessage(messages.managePortfolio)}
            />
            <ArtistCallToAction intl={intl} baseUrl={baseUrl} onboardedAt={onboardedAt} />
            <Box
              marginTop="xs"
              marginBottom="xs"
              style={{ borderTop: 'var(--ds-size-border-width-small) solid var(--ds-color-border-light)' }}
            />
          </List>
        )}
        <List>
          <NavListItem
            href={`${baseUrl}/orders?ref=account-nav-dropdown`}
            text={intl.formatMessage(messages.orderHistory)}
            subnav={isArtist}
          />
          <NavListItem
            href={`${baseUrl}/account/bubblemails?ref=account-nav-dropdown`}
            text={intl.formatMessage(messages.bubbleMail)}
            subnav={isArtist}
          />
          <NavListItem
            href={`${baseUrl}/account/settings?ref=account-nav-dropdown`}
            text={intl.formatMessage(messages.accountSettings)}
            subnav={isArtist}
          />
          {
          !isArtist && (
          <NavListItem
            href={`${baseUrl}/portfolio/manage_works?ref=account-nav-dropdown`}
            text={intl.formatMessage(messages.managePortfolio)}
          />
          )
          }
          {isArtist && (
            <Box
              marginBottom="xs"
              marginTop="xs"
              style={{ borderTop: 'var(--ds-size-border-width-small) solid var(--ds-color-border-light)' }}
            />
          )}
          {!isArtist && (
            <>
              <ArtistCallToAction intl={intl} baseUrl={baseUrl} onboardedAt={onboardedAt} />
              <Box
                marginBottom="xs"
                marginTop="xs"
                style={{ borderTop: 'var(--ds-size-border-width-small) solid var(--ds-color-border-light)' }}
              />
            </>
          )}
          <NavListItem
            href={locale === 'en' ? 'https://blog.redbubble.com/' : `https://blog.redbubble.com/${locale}/`}
            text={intl.formatMessage(messages.rbBlog)}
            subnav
          />
          <NavListItem
            href={`${baseUrl}/auth/logout?ref=account-nav-dropdown`}
            text={intl.formatMessage(messages.logout)}
            subnav
            primary
          />
        </List>
      </Box>
    </Box>
  );
};

Nav.propTypes = {
  // eslint-disable-next-line react/no-typos
  intl: PropTypes.shape({}).isRequired,
  baseUrl: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  isArtist: PropTypes.bool,
  locale: PropTypes.string.isRequired,
};

Nav.defaultProps = {
  isArtist: false,
};

Nav.displayName = 'Nav';

export default Nav;
