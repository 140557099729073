import React from 'react';
import Box from '../../../../../../react/Box';
import ButtonToggle from '../../../../../../react/ButtonToggle';
import List, { ListItem } from '../../../../../../react/List';
import * as constants from '../../../../../../react/constants';

export default () => (
  <Box display="flex" flexDirection="column" flex="1">
    <Box
      padding="xl"
      flex="1"
    >
      <List divided>
        <ListItem><Box display="flex" alignItems="center" justifyContent="space-between">Border<ButtonToggle size={constants.SMALL}>Border light</ButtonToggle></Box></ListItem>
        <ListItem><Box display="flex" alignItems="center" justifyContent="space-between">Border<ButtonToggle size={constants.SMALL}>Border light</ButtonToggle></Box></ListItem>
      </List>
    </Box>
  </Box>
);
