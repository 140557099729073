const withNextAction = (url) => {
  const urlObject = new URL(url, window.location.href);
  const currentUrl = new URL(window.location.href);

  const currentUrlAsAction = [currentUrl.pathname, currentUrl.search, currentUrl.hash].join('');
  urlObject.searchParams.append('next_action', currentUrlAsAction);

  return urlObject.href;
};

export default withNextAction;
