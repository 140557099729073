
const redirects = [
  {
    old: '/identity/logo/',
    new: '/brand/logo/',
  },
  {
    old: '/identity/photography/',
    new: '/brand/photography/',
  },
  {
    old: '/identity/principles/',
    new: '/brand/principles/',
  },
  {
    old: '/identity/voice-and-tone/',
    new: '/brand/voice-and-tone/',
  },
  {
    old: '/guidelines/color/',
    new: '/foundations/color/',
  },
  {
    old: '/guidelines/illustrations/',
    new: '/brand/illustrations/',
  },
  {
    old: '/guidelines/iconography/',
    new: '/foundations/iconography/',
  },
  {
    old: '/guidelines/typography/',
    new: '/foundations/typography/',
  },
  {
    old: '/guidelines/spacing/',
    new: '/foundations/spacing/',
  },
  {
    old: '/guidelines/layout/',
    new: '/foundations/layout/',
  },
];

export default redirects;
