import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../react/Text';
import styles from './PageTitle.css';

const PageTitle = ({ children }) => {
  return (
    <Text type="display1" element="h1" display="block" className={styles.pageTitle}>
      {children}
    </Text>
  );
};

PageTitle.propTypes = {
  children: PropTypes.string.isRequired,
};

export default PageTitle;
