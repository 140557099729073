import React from 'react';
import cnames from 'classnames';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

import List, { ListItem } from '../../../../List';
import Image from '../../../../Image';
import Box from '../../../../Box';
import _styles from './themes/default.scss';

import appStoreEn from './assets/appStoreBadges/appStoreEn.svg';
import appStoreDe from './assets/appStoreBadges/appStoreDe.svg';
import appStoreFr from './assets/appStoreBadges/appStoreFr.svg';
import appStoreEs from './assets/appStoreBadges/appStoreEs.svg';

import playStoreEn from './assets/playStoreBadges/playStoreEn.png';
import playStoreDe from './assets/playStoreBadges/playStoreDe.png';
import playStoreFr from './assets/playStoreBadges/playStoreFr.png';
import playStoreEs from './assets/playStoreBadges/playStoreEs.png';

const appStoreImages = locale => ({
  de: appStoreDe,
  fr: appStoreFr,
  es: appStoreEs,
})[locale] || appStoreEn;

const playStoreImages = locale => ({
  de: playStoreDe,
  fr: playStoreFr,
  es: playStoreEs,
})[locale] || playStoreEn;

const messages = defineMessages({
  appStoreAlt: 'Download on the App Store',
  playStoreAlt: 'Get it on Google Play',
});

const FooterApps = ({ locale, styles, parentStyles, intl }) => (
  <Box className={styles.footerBottom} padding="m">
    <div className={styles.footerContainer}>
      <List
        relaxed
        className={cnames(
          styles.list,
          parentStyles.listHorizontal,
        )}
      >
        <ListItem>
          <a href="https://redbubble.app.link/ios-footer" rel="nofollow">
            <Image
              className={styles.appstoreGraphic}
              src={appStoreImages(locale)}
              alt={intl.formatMessage(messages.appStoreAlt)}
            />
          </a>
        </ListItem>
        <ListItem>
          <a href="https://redbubble.app.link/android-footer" rel="nofollow">
            <Image
              className={styles.appstoreGraphic}
              src={playStoreImages(locale)}
              alt={intl.formatMessage(messages.playStoreAlt)}
            />
          </a>
        </ListItem>
      </List>
    </div>
  </Box>
);

FooterApps.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  styles: PropTypes.shape({}),
  parentStyles: PropTypes.shape({}).isRequired,
  locale: PropTypes.string.isRequired,
};

FooterApps.defaultProps = {
  styles: _styles,
};

FooterApps.displayName = 'FooterApps';

export default FooterApps;
