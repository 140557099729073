import React from 'react';
import cnames from 'classnames';
import PropTypes from 'prop-types';
import { XMasonry, XBlock } from 'react-xmasonry';
import Box from '../Box';
import useTracking, { trackingShape } from '../hooks/useTracking';
import styles from './styles.scss';

const Masonry = ({ children, targetColumnWidth, maxColumns, condensed, tracking }) => {
  const { TrackingContextProvider } = useTracking(tracking, 'Masonry');

  return children && (
    <TrackingContextProvider>
      <Box
        style={{
          '--ds-local-masonry-col-count': maxColumns,
          '--ds-local-masonry-col-width': `${targetColumnWidth * 0.85}px`,
        }}
      >
        <XMasonry
          className={cnames(styles.masonry, { [styles.condensed]: condensed })}
          targetBlockWidth={targetColumnWidth}
          maxColumns={maxColumns}
        >
          {
            React.Children.map(children, child => (
              <XBlock key={child.key} width={1}>
                <Box padding={condensed ? 0.25 : 0.75}>
                  <Box className={styles.blockInner}>
                    { child }
                  </Box>
                </Box>
              </XBlock>
            ))
          }
        </XMasonry>
      </Box>
    </TrackingContextProvider>
  );
};

Masonry.displayName = 'Masonry';

Masonry.propTypes = {
  /**
    * Items to be displayed in the Masonry grid
    */
  children: PropTypes.node.isRequired,
  /**
    * The maximum number of columns in the Masonry grid
    */
  maxColumns: PropTypes.number,
  /**
    * The desired (but not guaranteed) pixel width of each column
    */
  targetColumnWidth: PropTypes.number,
  /**
    * A condensed grid has less space between items inside it
    */
  condensed: PropTypes.bool,
  /**
    * Tracking configuration for auto tracking
    */
  tracking: trackingShape,
};

Masonry.defaultProps = {
  targetColumnWidth: 180,
  condensed: false,
  tracking: null,
};

export default Masonry;
