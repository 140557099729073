/* eslint-disable react/require-default-props */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import styles from './styles.scss';
import getElementType from '../utils/getElementType';
import useTracking, { trackingShape } from '../hooks/useTracking';
import { LEFT, RIGHT, CENTER } from '../constants';

const AXIS_STYLE_PROPS = [
  'marginX',
  'marginY',
  'paddingX',
  'paddingY',
];

const STYLE_PROPS = [
  'display',
  'flexDirection',
  'flexWrap',
  'alignItems',
  'justifyContent',
  'padding',
  'paddingTop',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'margin',
  'marginTop',
  'marginBottom',
  'marginLeft',
  'marginRight',
  'textAlign',
];

const AXIS_STYLE_REGEX = /(padding|margin)([XY])/;

const processValue = (declaration, props) => {
  const value = props[declaration];

  if (typeof value !== 'undefined' && value !== null) {
    if (declaration.startsWith('padding') || declaration.startsWith('margin')) {
      return `${value}`.replace('.', '');
    }

    return value;
  }

  return null;
};

const Box = forwardRef(({
  className,
  style,
  children,
  flex,
  innerRef,
  tracking,
  ...props
}, ref) => {
  const { TrackingContextProvider } = useTracking(tracking, 'Box');

  let classNames = cnames(styles.box, { [className]: className });

  STYLE_PROPS.forEach((declaration) => {
    const value = processValue(declaration, props);
    if (!value) return;

    classNames = cnames(classNames, styles[`${declaration}-${value}`]);
  });

  const axisSelectors = AXIS_STYLE_PROPS
    .reduce((selectors, declaration) => {
      const value = processValue(declaration, props);
      if (!value) return selectors;

      // eslint-disable-next-line no-unused-vars
      const [_declaration, type, axis] = AXIS_STYLE_REGEX.exec(declaration);

      const directions = axis === 'X' ? ['Left', 'Right'] : ['Top', 'Bottom'];

      const newSelectors = directions
        .filter(direction => !classNames.includes(`${type}${direction}`))
        .map(direction => styles[`${type}${direction}-${value}`]);

      return [...selectors, ...newSelectors];
    }, []);

  classNames = cnames(classNames, axisSelectors);

  const restProps = Object.entries(props).reduce((memo, [name, value]) => {
    if ([...STYLE_PROPS, ...AXIS_STYLE_PROPS].includes(name)) return memo;
    if (name === 'element') return memo;

    return { ...memo, [name]: value };
  }, {});

  const Element = getElementType(Box, props);

  return (
    <TrackingContextProvider>
      <Element
        ref={ref || innerRef}
        style={{
          flex,
          ...style,
        }}
        className={classNames}
        data-testid="ds-box"
        {...restProps}
      >
        { children }
      </Element>
    </TrackingContextProvider>
  );
});

const displays = [
  'flex',
  'inline-flex',
  'block',
  'inline-block',
  'inline',
  'none',
];

const flexAlignments = [
  'normal',
  'stretch',
  'center',
  'start',
  'end',
  'flex-start',
  'flex-end',
  'baseline',
];

const flexJustifications = [
  'normal',
  'center',
  'start',
  'end',
  'flex-start',
  'flex-end',
  'left',
  'right',
  'space-between',
  'space-around',
  'space-evenly',
  'stretch',
];

const flexDirections = [
  'row',
  'column',
  'row-reverse',
  'column-reverse',
];

const flexWraps = [
  'initial',
  'nowrap',
  'wrap',
  'wrap-reverse',
];

const sizes = [
  0,
  0.25,
  0.5,
  0.75,
  1,
  1.25,
  1.5,
  1.75,
  2,
  'none',
  'xxxs',
  'xxs',
  'xs',
  's',
  'm',
  'l',
  'xl',
  'xxl',
  'xxxl',
];

const propTypes = {
  /**
    * The contents of the Box
    */
  children: PropTypes.node,
  /**
    * A custom className to be applied to the Box
    */
  className: PropTypes.string,
  /**
    * A custom style object to be applied to the Box
    */
  style: PropTypes.shape({}),
  /**
    * Accepts any valid CSS flex rule, e.g. "1 0 100px" or "1"
    */
  flex: PropTypes.string,
  /**
    * Specify how flex children should wrap (depends on display="flex")
    */
  flexWrap: PropTypes.oneOf(flexWraps),
  /**
    * Specify the direction flex children should flow (depends on display="flex")
    */
  flexDirection: PropTypes.oneOf(flexDirections),
  /**
    * Specify how flex children should be justified (depends on display="flex")
    */
  justifyContent: PropTypes.oneOf(flexJustifications),
  /**
    * Specify how flex children should be aligned (depends on display="flex")
    */
  alignItems: PropTypes.oneOf(flexAlignments),
  /**
    * Specify all around margin as a multiplier of 16px
    */
  margin: PropTypes.oneOf([...sizes, 'auto']),
  /**
    * Specify a margin left and right as a multiplier of 16px
    */
  marginX: PropTypes.oneOf([...sizes, 'auto']),
  /**
    * Specify a margin top and bottom as a multiplier of 16px
    */
  marginY: PropTypes.oneOf([...sizes, 'auto']),
  /**
    * Specify a margin top as a multiplier of 16px
    */
  marginTop: PropTypes.oneOf([...sizes, 'auto']),
  /**
    * Specify a margin bottom as a multiplier of 16px
    */
  marginBottom: PropTypes.oneOf([...sizes, 'auto']),
  /**
    * Specify a margin left as a multiplier of 16px
    */
  marginLeft: PropTypes.oneOf([...sizes, 'auto']),
  /**
    * Specify a margin right as a multiplier of 16px
    */
  marginRight: PropTypes.oneOf([...sizes, 'auto']),
  /**
    * Specify all around padding as a multiplier of 16px
    */
  padding: PropTypes.oneOf(sizes),
  /**
    * Specify a padding left and right as a multiplier of 16px
    */
  paddingX: PropTypes.oneOf(sizes),
  /**
    * Specify a padding top and bottom as a multiplier of 16px
    */
  paddingY: PropTypes.oneOf(sizes),
  /**
    * Specify a padding top as a multiplier of 16px
    */
  paddingTop: PropTypes.oneOf(sizes),
  /**
    * Specify a padding bottom as a multiplier of 16px
    */
  paddingBottom: PropTypes.oneOf(sizes),
  /**
    * Specify a padding left as a multiplier of 16px
    */
  paddingLeft: PropTypes.oneOf(sizes),
  /**
    * Specify a padding right as a multiplier of 16px
    */
  paddingRight: PropTypes.oneOf(sizes),
  /**
    * Specify how the Box should be displayed
    */
  display: PropTypes.oneOf(displays),
  /**
    * Align the text content of the children
    */
  textAlign: PropTypes.oneOf([LEFT, RIGHT, CENTER]),
  /**
    * Specify which valid HTML element the Box should render
    */
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType,
  ]),
  /**
    * Tracking configuration for auto tracking
    */
  tracking: trackingShape,
};

Box.defaultProps = {
  element: 'div',
  tracking: null,
};

Box.propTypes = propTypes;
Box.propNames = Object.keys(propTypes);

Box.displayName = 'Box';

export default Box;
