import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import _styles from './styles.scss';
import * as constants from '../../constants';

const RadioSelectorField = ({
  options,
  form,
  field,
  intent,
  label,
  onFocus,
  onBlur,
  styles,
}) => {
  if (!Array.isArray(options) || options.length === 0) return null;

  const active = options.find(o => o.value === field.value) || {};

  return (
    <div className={cnames(styles.group)} role="radiogroup" aria-label={label}>
      {options.map((option) => {
        const classes = cnames(styles.label, styles[intent], {
          [styles.active]: active.value === option.value,
          [styles.disabled]: option.isDisabled,
        });

        return (
          /* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */
          <label
            tabIndex={-1}
            key={`${field.name}_${option.value}`}
            title={option.label}
            className={classes}
          >
            <input
              checked={active.value === option.value}
              type="radio"
              value={option.value}
              tabIndex={0}
              onFocus={onFocus}
              onBlur={(e) => {
                onBlur(e);
                field.onBlur(e);
              }}
              onChange={() => form.setFieldValue(field.name, option.value)}
              name={field.name}
              className={styles.radio}
              disabled={option.isDisabled}
            />
            <span className={styles.labelText}>{option.label}</span>
          </label>
        );
      })}
    </div>
  );
};

RadioSelectorField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  field: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  /**
   * The CSS classNames for this component. NEVER manually specify this prop
   */
  styles: PropTypes.shape({}),
  intent: PropTypes.oneOf([
    constants.INFORMATION,
    constants.ERROR,
    constants.WARNING,
    constants.SUCCESS,
    constants.NEUTRAL,
  ]),
};

RadioSelectorField.defaultProps = {
  options: [],
  onFocus: null,
  onBlur: f => f,
  styles: _styles,
  intent: constants.NEUTRAL,
};

export default RadioSelectorField;
