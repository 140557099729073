import image from '../assets/how-we-speak.svg';

/* eslint-disable max-len */
const markdown = `
## So what is this thing anyway?
Style guide? Voice and tone bible? A collection of very specific examples and rules that will help you write micro-copy on behalf of Redbubble that is clear, consistent, and in line with our brand?

Yep. Exactly.

Whatever you’re trying to communicate on behalf of Redbubble, and wherever your words may appear (email, CS reply, recruitment, etc.), this simple guide is here to help.

`;

const Home = {
  markdown,
  image,
  title: 'Voice and tone',
  subTitle: 'What is this thing?',
  slug: '',
};

export default Home;
