import React from 'react';
import cnames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import List, { ListItem } from '../../../../List';
import * as constants from '../../../../constants';
import Box from '../../../../Box';
import Modal, { ModalBody } from '../../../../Modal';
import Text from '../../../../Text';
import Logo from '../../../../Logo';
import _styles from './themes/default.scss';

const displayImpressum = (country, locale) => { return ['DE', 'CH', 'AT'].includes(country) || locale === 'de'; };

const FooterLegal = ({ baseUrl, country, locale, styles, parentStyles, showCookiePolicyLink }) => (
  <div className={cnames(styles.footerLegal, parentStyles.footerLegal)}>
    <div className={styles.footerContainer}>
      <List className={styles.listHorizontal}>
        <ListItem>
          <a href={baseUrl} className={styles.redbubbleLogo} rel="nofollow">
            <Logo colored={false} contrast />
          </a>
        </ListItem>
      </List>
      <Box marginTop="m" />
      <List className={styles.listHorizontal}>
        <ListItem>
          <Text type="body2">
            <a
              className={styles.link}
              href={`${baseUrl}/agreement`}
              rel="nofollow"
            >
              <FormattedMessage defaultMessage="User Agreement" />
            </a>
          </Text>
        </ListItem>
        <ListItem>
          <Text type="body2">
            <a
              className={styles.link}
              href={`${baseUrl}/privacy`}
              rel="nofollow"
            >
              <FormattedMessage defaultMessage="Privacy Policy" />
            </a>
          </Text>
        </ListItem>
        {country === 'US' && (
          <ListItem>
            <Text type="body2">
              <a
                className={styles.link}
                href="https://help.redbubble.com/hc/en-us/articles/360037614352"
                rel="nofollow"
              >
                Do not sell my personal information
              </a>
            </Text>
          </ListItem>
        )}
        <ListItem>
          <Text type="body2">
            <a className={styles.link} href={showCookiePolicyLink ? `${baseUrl}/cookie-policy` : `${baseUrl}/privacy#cookies`}>
              <FormattedMessage defaultMessage="Cookie Policy" />
            </a>
          </Text>
        </ListItem>
        {displayImpressum(country, locale) && (
          <ListItem>
            <Modal
              trigger={({ onClick }) => (
                <button type="button" className={styles.link} onClick={onClick}>
                  <Text type="body2">
                    Impressum
                  </Text>
                </button>
              )}
              accessibleTitle="Impressum"
              getApplicationNode={() => document.getElementById('root')}
              size={constants.MEDIUM}
            >
              <ModalBody>
                <Box paddingLeft="m" paddingRight="m" paddingBottom="xl">
                  <Box marginBottom="xl" element="h2">
                    <Text display="block" type="display2">
                      Impressum
                    </Text>
                  </Box>

                  <Box marginBottom="xs">
                    <Text element="p">
                      <strong>www.redbubble.com</strong> (einschließlich
                      {' '}
                      <strong>
                        www.redbubble.com/de
                      </strong>
                      ) ist ein Angebot der:
                    </Text>
                  </Box>

                  <Box padding="l">
                    <address className={styles.address}>
                      <Box paddingBottom="xxs">
                        <Text display="block" type="body">
                          <strong>Redbubble Inc.</strong>
                          , Aktiengesellschaft mit Sitz in Delaware, USA.
                        </Text>
                      </Box>
                      <Box paddingBottom="xxs">
                        <Text display="block" type="body">
                          <strong>Adresse:</strong>
                          {' '}
                          111 Sutter St, 17th fl, San Francisco, CA 94104, USA
                        </Text>
                      </Box>
                      <Box paddingBottom="xxs">
                        <Text display="block" type="body">
                          <strong>Telefon:</strong> <a href="tel:+493056837185">+49 (0) 30 5683 7185</a>
                        </Text>
                      </Box>
                      <Box paddingBottom="xxs">
                        <Text display="block" type="body">
                          <strong>E-Mail:</strong>
                          {' '}
                          <a href="mailto:legal@redbubble.com">legal@redbubble.com</a>
                        </Text>
                      </Box>
                      <Box paddingBottom="xxs">
                        <Text display="block" type="body">
                          <strong>Steuernummer:</strong> 205-4102-65
                        </Text>
                      </Box>
                      <Box paddingBottom="xxs">
                        <Text display="block" type="body">
                          <strong>Gesetzlicher Vertreter von Redbubble Inc.:</strong>
                          {' '}
                          Frau Corina Davis
                        </Text>
                      </Box>
                    </address>
                  </Box>

                  <Box padding="xxs">
                    <Text display="block" type="body">
                      <strong>Rechtlicher Hinweis:</strong> Die Europäische
                      Kommission stellt eine Plattform zur{' '}
                      Online-Streitbeilegung zur Verfügung, die du unter{' '}
                      <a
                        href="https://ec.europa.eu/consumers/odr/"
                        title="Online-Streitbeilegung"
                      >
                        https://ec.europa.eu/consumers/odr/
                      </a>
                      {' '}
                      abrufen kannst.
                    </Text>
                  </Box>
                </Box>


              </ModalBody>
            </Modal>
          </ListItem>
        )}
      </List>
    </div>
  </div>
);

FooterLegal.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  country: PropTypes.string,
  showCookiePolicyLink: PropTypes.bool,
  locale: PropTypes.string,
  styles: PropTypes.shape({}),
  parentStyles: PropTypes.shape({}).isRequired,
};

FooterLegal.defaultProps = {
  country: null,
  locale: '',
  styles: _styles,
  showCookiePolicyLink: false,
};

FooterLegal.displayName = 'FooterLegal';

export default FooterLegal;
