import React from 'react';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';
import cnames from 'classnames';
import ease from 'easy-ease';
import _styles from './styles.scss';
import Button from '../Button';
import Box from '../Box';
import ChevronLeftBigIcon from '../Icons/ChevronLeftBig';
import ChevronRightBigIcon from '../Icons/ChevronRightBig';
import * as constants from '../constants';

const MARGIN = 16;

class Carousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Keeps a list of which slides are visible / not, in order
      slides: React.Children.toArray(props.children).map(() => false),
      disableNext: false,
      disablePrev: false,
    };


    // Holds refs to each slide DOM node
    this.slides = {};

    // Function bindings
    this.handleUnmount = this.handleUnmount.bind(this);
    this.handleVisiblityChange = this.handleVisiblityChange.bind(this);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);

    if (typeof this.props.getControls === 'function') {
      this.props.getControls({
        next: this.next,
        prev: this.prev,
      });
    }
  }

  // Updates the visibility of slides as they go in / out of view
  handleVisiblityChange(visibility, changedIndex) {
    this.setState(oldState => ({
      ...oldState,
      slides: oldState.slides.map((visible, i) => (
        i === changedIndex ? visibility : visible
      )),
    }), () => {
      if (typeof this.props.reportOnVisibility === 'function') {
        this.props.reportOnVisibility(this.state.slides);
      }
    });
  }

  static getDerivedStateFromProps(props, state) {
    const childrenArray = React.Children.toArray(props.children);
    if (childrenArray.length !== state.slides.length) {
      return {
        ...state,
        slides: childrenArray.map(() => false),
      };
    }

    return state;
  }

  // Handle when a child leaves the DOM
  handleUnmount(index) {
    // Remove the old ref to this child
    delete this.slides[`slide${index}`];
    // Remove the slide visibility marker for this this child
    this.setState(oldState => ({
      ...oldState,
      slides: oldState.slides.filter((_, i) => i !== index),
    }));
  }

  // Move to the last visible slide
  next() {
    this.moveToSlide(this.lastVisible);
  }

  // Move to the set of slides preceding the first visible slide
  prev() {
    if (this.slidesPerView === 1) {
      this.moveToSlide(this.firstVisible - 1);
    } else {
      this.moveToSlide(this.firstVisible + 1, true);
    }
  }

  // Move the carousel to a given slide index
  moveToSlide(index, alignToEnd) {
    const targetSlide = this.slides[`slide${index}`];

    if (!targetSlide) return;

    if (!alignToEnd) {
      this.ease(targetSlide.node.offsetLeft - MARGIN);
    } else {
      this.ease(targetSlide.node.offsetLeft - this.carouselNode.offsetWidth);
    }
  }

  // Ease the carousel from it's current position to a new offset
  // TODO: Make this interruptible
  ease(to) {
    ease({
      startValue: this.carouselNode.scrollLeft,
      endValue: to,
      durationMs: 600,
      onStep: (value) => { this.carouselNode.scrollLeft = value; },
    });
  }

  get eagerLazySlides() {
    const { slidesPerView, profile } = this.props;

    if (slidesPerView === 'auto') {
      return profile === constants.DESKTOP ? 10 : 5;
    }

    return this.slidesPerView + 1;
  }

  get firstVisible() {
    const first = this.state.slides.slice().findIndex(v => v);
    return first < 0 ? 0 : first;
  }

  get lastVisible() {
    const numVisible = this.state.slides.filter(Boolean).length;
    let last = this.firstVisible + numVisible - 1;

    if (this.slidesPerView === 1) {
      last += 1;
    }

    return last < 0 ? 0 : last;
  }

  get slideWidthPerc() {
    return `calc(((100% + 16px) / ${this.slidesPerView}) - ${MARGIN}px)`;
  }

  // For lazy loading, when true the slide will load
  shouldShow(index) {
    return index <= this.lastVisible + this.eagerLazySlides;
  }

  get isInactive() {
    if (this.props.slidesPerView === 'auto') {
      // Can't determine if controls are needed or not, so show them in case
      if (!this.carouselNode) return false;

      // If all the slides fit inside the carousel, don't show the controls
      const slidesWidth = Object.entries(this.slides).reduce((total, entry) => {
        return total + entry[1].node.offsetWidth + MARGIN;
      }, 0);

      return slidesWidth <= this.carouselNode.offsetWidth;
    }

    // If we know how many slides we have and how many to show per view
    // we know if we need controls or not
    const numChildren = React.Children.toArray(this.props.children).length;
    return this.props.slidesPerView >= numChildren;
  }

  get slidesPerView() {
    const { slidesPerView, profile, showHint } = this.props;

    if (slidesPerView === 'auto') return 'auto';

    if ((profile === constants.MOBILE || showHint) && !this.isInactive) {
      return slidesPerView + 0.25;
    }

    return slidesPerView;
  }

  render() {
    const { children, profile, styles, buttonsPosition } = this.props;

    const widthStyle = this.slidesPerView === 'auto' ? {} : {
      minWidth: this.slideWidthPerc,
      width: this.slideWidthPerc,
    };

    return (
      <React.Fragment>
        <div
          className={cnames(styles.carouselWrapper, { [styles.inactive]: this.isInactive })}
          style={buttonsPosition && { '--ds-local-carousel-button-position': buttonsPosition }}
        >
          <div
            className={styles.carouselOuter}
            ref={(r) => { this.carouselNode = r; }}
          >
            <div
              ref={(r) => { this.carouselInner = r; }}
              className={styles.carouselInner}
            >
              {
                React.Children.map(children, (child, index) => (
                  <InView
                    // eslint-disable-next-line react/no-array-index-key
                    key={`slide_${child.key}_${index}`}
                    root={this.carouselNode}
                    ref={(n) => { this.slides[`slide${index}`] = n; }}
                    onChange={inView => this.handleVisiblityChange(inView, index)}
                    className={styles.slide}
                    style={widthStyle}
                  >
                    {
                      index === 0 && (
                        <InView
                          onChange={disablePrev => this.setState({ disablePrev })}
                          className={styles.startSlither}
                        />
                      )
                    }
                    {
                      React.cloneElement(
                        child,
                        {
                          onUnmount: () => this.handleUnmount(index),
                          inView: this.shouldShow(index),
                        },
                      )
                    }
                    {
                      index === this.state.slides.length - 1 && (
                        <InView
                          onChange={disableNext => this.setState({ disableNext })}
                          className={styles.endSlither}
                        />
                      )
                    }
                  </InView>
                ))
              }
            </div>
          </div>
          {
            profile === constants.DESKTOP && !this.isInactive && !this.props.hideControls && (
              <React.Fragment>
                {
                  this.carouselNode && this.carouselNode.scrollLeft !== 0 && (
                    <Box
                      display="inline"
                      className={
                        cnames(styles.previousButton, { [styles.disabled]: this.state.disablePrev })
                      }
                    >
                      <Button
                        size={constants.MEDIUM}
                        type="button"
                        inverse
                        disabled={this.state.disablePrev}
                        onClick={this.prev}
                        circle
                        strong
                        iconBefore={<ChevronLeftBigIcon size={constants.LARGE} />}
                      />
                    </Box>
                  )
                }
                <Box
                  display="inline"
                  className={
                    cnames(styles.nextButton, { [styles.disabled]: this.state.disableNext })
                  }
                >
                  <Button
                    size={constants.MEDIUM}
                    type="button"
                    inverse
                    disabled={this.state.disableNext}
                    onClick={this.next}
                    circle
                    strong
                    iconBefore={<ChevronRightBigIcon size={constants.LARGE} />}
                    data-testid="ds-carousel-button-next"
                  />
                </Box>
              </React.Fragment>
            )
          }
        </div>
      </React.Fragment>
    );
  }
}

Carousel.displayName = 'Carousel';

Carousel.propTypes = {
  /**
    * The Slides to be shown in the carousel
    */
  children: PropTypes.node,
  /**
    * How many slides to show in the viewport. An integer value or "auto".
    * On mobile, users will see an additional quarter of a slide.
    */
  slidesPerView: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  /**
    * When true, forces the carousel to show an additional quarter slide to hint that
    * there is swipeable content.
    *
    * Defaults to true on Mobile, false on Desktop.
    */
  showHint: PropTypes.bool, // eslint-disable-line react/require-default-props
  /**
    * A function to be called with data about slide visibility as slides enter / leave view
    */
  reportOnVisibility: PropTypes.func,
  /**
    * Choose between Desktop or Mobile for sensible defaults
    */
  profile: PropTypes.oneOf([constants.MOBILE, constants.DESKTOP]),
  /**
    * A function for a consumer to trigger the next/previous actions
    */
  getControls: PropTypes.func,
  /**
    * Hide the default next/prev buttons on Desktop
    */
  hideControls: PropTypes.bool,
  /**
    * The CSS classNames for this component. NEVER manually specify this prop
    */
  styles: PropTypes.shape({}),

  /**
   * Value in pixel (negative or positive) that will be used to position the left/right buttons.
   *
   * Defaults to: calc(var(--ds-spacing-s) * -0.75)
   */
  buttonsPosition: PropTypes.string,
};

Carousel.defaultProps = {
  children: [],
  slidesPerView: 2,
  reportOnVisibility: null,
  profile: constants.MOBILE,
  styles: _styles,
  getControls: null,
  hideControls: false,
};

export { default as Slide } from './Slide';
export default Carousel;
