import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import Text from '../Text';
import Button from '../Button';
import Box from '../Box';
import * as DrawerExports from '.';
import * as constants from '../constants';

/* eslint-disable */
import DrawerSrc from '!raw-loader!./Drawer';
/* eslint-enable */

const demoConfig = responsive => ({
  profile: responsive.desktop ? 'DESKTOP' : 'MOBILE',
  initialValues: {
    attach: responsive.desktop ? constants.LEFT : constants.BOTTOM,
    size: responsive.desktop ? constants.SMALL : constants.LARGE,
    fit: 'dont-fit',
    drawerSize: '2',
  },
  fields: [
    {
      type: 'select',
      options: [
        { value: constants.LEFT, label: 'Left' },
        { value: constants.RIGHT, label: 'Right' },
        { value: constants.TOP, label: 'Top' },
        { value: constants.BOTTOM, label: 'Bottom' },
      ],
      name: 'attach',
      label: 'Enter from:',
    },
    {
      type: 'select',
      name: 'size',
      options: [
        { value: constants.SMALL, label: 'Small' },
        { value: constants.MEDIUM, label: 'Medium' },
        { value: constants.LARGE, label: 'Large' },
      ],
      label: 'Size',
    },
  ],
});

const { default: Drawer } = DrawerExports;
const docs = [
  DrawerSrc,
].map(src => generateDocs(src, resolver.findAllExportedComponentDefinitions))
  .reduce((m, d) => m.concat(d), []);

const drawerDemo = {
  name: 'Simple Example',
  description: 'Specify the size of your drawer and the direction it should enter from.',
  config: responsive => demoConfig(responsive),
  render: (responsive, values) => (
    <Drawer
      trigger={({ onClick }) => (
        <Button strong intent={constants.PRIMARY} onClick={onClick}>Trigger</Button>
      )}
      size={values.size}
      from={values.attach}
      accessibleTitle="My Drawer Demo"
      getApplicationNode={() => document.getElementById('root')}
    >
      {
        args => (
          <Box padding="m">
            <Text display="block" type="display3">Drawer content</Text>
            <Box padding="xxs" />
            <Text display="block">
              Use a Button or similar action to give the user a way to close the drawer.
            </Text>
            <Box padding="m" justifyContent="flex-end" display="flex">
              <Button intent={constants.PRIMARY} strong onClick={args.close}>Okay</Button>
            </Box>
          </Box>
        )
      }
    </Drawer>
  ),
  code: (responsive, values) => `
<Drawer
  trigger={({ onClick }) => (
    <Button strong intent={constants.PRIMARY} onClick={onClick}>Trigger</Button>
  )}
  size="${values.size}"
  from="${values.attach}"
  accessibleTitle="My Drawer Demo"
  getApplicationNode={() => document.getElementById('root')}
>
  <Box padding="m">
    <Text display="block" type="display3">Drawer content</Text>
    <Text display="block">You can use the Box component to layout the content of your drawer.</Text>
  </Box>
</Drawer>
  `,
};

const scrollingExample = {
  name: 'Scrolling Content',
  description: 'The drawer will automatically scroll if its content is longer than its height.',
  config: responsive => demoConfig(responsive),
  render: (r, values) => (
    <Drawer
      size={values.size}
      from={values.attach}
      trigger={({ onClick }) => (
        <Button strong intent={constants.PRIMARY} onClick={onClick}>Trigger</Button>
      )}
      titleText="My drawer demo"
    >
      <Box padding="m">
        <Text display="block" type="display2">Reviews</Text>
        <Text display="block" type="display4">
          A subheading
        </Text>
      </Box>
      <Box padding="m">
        {
          new Array(10).fill('').map((_, i) => (
            // eslint-disable-next-line
            <Text element="p" key={i}>
              Vestibulum congue, leo ut rhoncus semper, purus sapien fermentum
              libero, at sollicitudin sem eros sit amet risus. Etiam rutrum, turpis
              eget aliquam hendrerit, arcu lorem semper quam, vel suscipit nunc
              libero at erat. Etiam id ornare elit, eget tincidunt tortor.
              Vestibulum ac ante ac dui placerat vulputate ut vitae velit. Mauris
              ultrices est quis est interdum accumsan. In porta mauris sed sagittis
              ultricies. Ut ipsum dolor, laoreet eget iaculis a, pulvinar in erat.
              Donec in molestie velit. Fusce scelerisque porttitor diam ut
              efficitur. Phasellus egestas ante dolor, eget dictum ex imperdiet id.
              Vestibulum lobortis congue condimentum. Integer pharetra odio tellus.
              Ut odio erat, dictum maximus velit eu, congue tincidunt nisl. Ut
              vitae facilisis erat, quis interdum dui.
            </Text>
          ))
        }

      </Box>
    </Drawer>
  ),
  code: (r, values) => `
<Drawer
  size="${values.size}"
  from="${values.attach}"
  trigger={({ onClick }) => (
    <Button strong intent={constants.PRIMARY} onClick={onClick}>Trigger</Button>
  )}
  titleText="My drawer demo"
>
  <Box padding="m">
    <Text display="block" type="display2">Reviews</Text>
    <Text display="block" type="display4">
      A subheading
    </Text>
  </Box>
  <Box padding="m">
    {
      new Array(10).fill('').map((_, i) => (
        // eslint-disable-next-line
        <Text element="p" key={i}>
          Vestibulum congue, leo ut rhoncus semper, purus sapien fermentum
          libero, at sollicitudin sem eros sit amet risus. Etiam rutrum, turpis
          eget aliquam hendrerit, arcu lorem semper quam, vel suscipit nunc
          libero at erat. Etiam id ornare elit, eget tincidunt tortor.
          Vestibulum ac ante ac dui placerat vulputate ut vitae velit. Mauris
          ultrices est quis est interdum accumsan. In porta mauris sed sagittis
          ultricies. Ut ipsum dolor, laoreet eget iaculis a, pulvinar in erat.
          Donec in molestie velit. Fusce scelerisque porttitor diam ut
          efficitur. Phasellus egestas ante dolor, eget dictum ex imperdiet id.
          Vestibulum lobortis congue condimentum. Integer pharetra odio tellus.
          Ut odio erat, dictum maximus velit eu, congue tincidunt nisl. Ut
          vitae facilisis erat, quis interdum dui.
        </Text>
      ))
    }

  </Box>
</Drawer>
  `,
};

const fitDemo = {
  name: 'Fit drawer to content',
  description: 'Specify fit, to have the drawer fit its contents. Size becomes a maximum.',
  config: responsive => ({
    ...demoConfig(responsive),
    fields: [
      ...demoConfig(responsive).fields,
      {
        type: 'select',
        options: [
          { value: 'dont-fit', label: 'False' },
          { value: 'fit', label: 'True' },
        ],
        name: 'fit',
        label: 'Fit',
      },
      {
        type: 'text',
        name: 'drawerSize',
        label: 'Number of items in drawer',
      },
    ],
  }),
  render: (responsive, values) => (
    <Drawer
      trigger={({ onClick }) => (
        <Button strong intent={constants.PRIMARY} onClick={onClick}>Trigger</Button>
      )}
      size={values.size}
      from={values.attach}
      fit={values.fit === 'fit'}
      accessibleTitle="My Drawer Demo"
      getApplicationNode={() => document.getElementById('root')}
    >
      <Box padding="m">
        <Text display="block" type="display3">Drawer content</Text>
        {
          new Array(parseInt(values.drawerSize, 10) || 1).fill('').map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Text display="block" key={i}>This is item {i}</Text>
          ))
        }
      </Box>
    </Drawer>
  ),
  code: (responsive, values) => `
<Drawer
  trigger={({ onClick }) => (
    <Button strong intent={constants.PRIMARY} onClick={onClick}>Trigger</Button>
  )}
  size="${values.size}"
  from="${values.attach}"${values.fit === 'fit' ? '\n  fit' : ''}
  accessibleTitle="My Drawer Demo"
  getApplicationNode={() => document.getElementById('root')}
>
  <Box padding="m">
    <Text display="block" type="display3">Drawer content</Text>
    <Text display="block">You can use the Box component to layout the content of your drawer.</Text>
  </Box>
</Drawer>
  `,
};

const Demo = {
  title: Drawer.displayName,
  description: 'Drawers slide in from the screen edge, containing additional in-context information',
  docGen: docs,
  slug: 'Drawer',
  exports: Object.keys(DrawerExports),
  examples: [
    drawerDemo,
    scrollingExample,
    fitDemo,
  ],
};

export default Demo;
