import React from 'react';
import { Provider } from 'react-redux';
import { IntlProvider, injectIntl } from 'react-intl';
import { createStore, compose } from 'redux';
import FooterComponent, { PaymentMethods } from './Footer';

const store = createStore(() => { });

const Footer = compose(injectIntl)(FooterComponent);

const props = {
  userInfo: { currency: 'USD', locale: 'en', country: 'US' },
  // eslint-disable-next-line no-alert
  onToggleMatureContent: () => alert('Mature content toggled!'),
};

const footerExample = {
  name: 'Default',
  description: 'The footer will render all sections by default (except for payment methods).',
  muted: true,
  render: () => (
    <Provider store={store}>
      <IntlProvider locale="en">
        <Footer {...props} />
      </IntlProvider>
    </Provider>
  ),
  code: `
  const props = {
    userInfo: { currency: 'USD' },
    onToggleMatureContent: () => alert('Mature content toggled!'),
  };

  <Footer {...props} />
  `,
};

const footerExampleMinimal = {
  name: 'Minimum',
  description: 'You can choose to hide individual sections of the Footer. This is the footer when all sections have been hidden.',
  muted: true,
  render: () => (
    <Provider store={store}>
      <IntlProvider locale="en">
        <Footer
          showUserSettings={false}
          showNavigation={false}
          showApp={false}
          {...props}
        />
      </IntlProvider>
    </Provider>
  ),
  code: `
  const props = {
    userInfo: { currency: 'USD' },
    onToggleMatureContent: () => alert('Mature content toggled!'),
    showUserSettings={false},
    showNavigation={false},
    showApp={false},
  };

  <Footer {...props} />
  `,
};

const footerExampleMinimalWithImpressum = {
  name: 'With imprint',
  description: 'This is the bare minimum, when all the optional sections have been removed, as it is displayed when an imprint is requried.',
  muted: true,
  render: () => (
    <Provider store={store}>
      <IntlProvider locale="en">
        <Footer
          showUserSettings={false}
          showNavigation={false}
          showApp={false}
          {...props}
          userInfo={
            {
              ...props.userInfo,
              locale: 'de',
            }
          }
        />
      </IntlProvider>
    </Provider>
  ),
  code: `
  const props = {
    userInfo: { currency: 'USD', country: 'AU' },
    onToggleMatureContent: () => alert('Mature content toggled!'),
    showUserSettings={false},
    showNavigation={false},
    showApp={false},
  };

  <Footer {...props} />
  `,
};

const footerExampleWithoutPromo = {
  name: 'Without promo',
  description: '',
  muted: true,
  render: () => (
    <Provider store={store}>
      <IntlProvider locale="en">
        <Footer {...props} />
      </IntlProvider>
    </Provider>
  ),
  code: `
  const props = {
    userInfo: { currency: 'USD' },
    onToggleMatureContent: () => alert('Mature content toggled!'),
  };

  <Footer {...props} />
  `,
};

const footerExampleWithoutUserSettings = {
  name: 'Without settings',
  description: '',
  muted: true,
  render: () => (
    <Provider store={store}>
      <IntlProvider locale="en">
        <Footer
          showUserSettings={false}
          {...props}
        />
      </IntlProvider>
    </Provider>
  ),
  code: `
  const props = {
    userInfo: { currency: 'USD' },
    onToggleMatureContent: () => alert('Mature content toggled!'),
    showUserSettings={false},
  };

  <Footer {...props} />
  `,
};

const footerExampleWithoutNavigation = {
  name: 'Without navigation',
  description: '',
  muted: true,
  render: () => (
    <Provider store={store}>
      <IntlProvider locale="en">
        <Footer
          showNavigation={false}
          {...props}
        />
      </IntlProvider>
    </Provider>
  ),
  code: `
  const props = {
    userInfo: { currency: 'USD' },
    onToggleMatureContent: () => alert('Mature content toggled!'),
    showNavigation={false},
  };

  <Footer {...props} />
  `,
};

const footerExampleWithoutPaymentMethods = {
  name: 'Without payments',
  description: '',
  muted: true,
  render: () => (
    <Provider store={store}>
      <IntlProvider locale="en">
        <Footer
          // By default no payment methods are displayed
          {...props}
        />
      </IntlProvider>
    </Provider>
  ),
  code: `
  const props = {
    userInfo: { currency: 'USD' },
    onToggleMatureContent: () => alert('Mature content toggled!'),
    // By default no payment methods are displayed
  };

  <Footer {...props} />
  `,
};

const footerExampleWithDefaultPaymentMethods = {
  name: 'With default payments',
  description: '',
  muted: true,
  render: () => (
    <Provider store={store}>
      <IntlProvider locale="en">
        <Footer
          paymentMethods={PaymentMethods.defaults}
          {...props}
        />
      </IntlProvider>
    </Provider>
  ),
  code: `

  import { PaymentMethods } from '@redbubble/design-system/react/Footer';

  const props = {
    userInfo: { currency: 'USD' },
    onToggleMatureContent: () => alert('Mature content toggled!'),
    paymentMethods={PaymentMethods.defaults},
  };

  <Footer {...props} />
  `,
};

const footerExampleWithoutApp = {
  name: 'Without app links',
  description: '',
  muted: true,
  render: () => (
    <Provider store={store}>
      <IntlProvider locale="en">
        <Footer
          showApp={false}
          {...props}
        />
      </IntlProvider>
    </Provider>
  ),
  code: `
  const props = {
    userInfo: { currency: 'USD' },
    onToggleMatureContent: () => alert('Mature content toggled!'),
    showApp={false},
  };

  <Footer {...props} />
  `,
};

export default {
  title: FooterComponent.displayName,
  description: 'A footer used across different apps',
  slug: 'footer',
  examples: [
    footerExample,
    footerExampleMinimal,
    footerExampleMinimalWithImpressum,
    footerExampleWithoutPromo,
    footerExampleWithoutUserSettings,
    footerExampleWithoutNavigation,
    footerExampleWithoutPaymentMethods,
    footerExampleWithDefaultPaymentMethods,
    footerExampleWithoutApp,
  ],
  notes: `
  ### Payment Method Badges

  The \`Footer\` takes a \`paymentMethods\` prop to display the appropriate payment method badges in the footer. The actual logic to decide which badges should be displayed (depending on currency, locale etc.) currently sits in the consuming apps. By default if no \`paymentMethods\` are passed in the footer will show no badges at all. 
  
  #### Examples
  
  You can display the default payment options only:

  \`\`\`
  import { PaymentMethods } from '@redbubble/design-system/react/Footer';

  <Footer paymentMethods={PaymentMethods.defaults} />
  \`\`\`
  
  If it exists - you can tap into the existing payment option logic of the consuming app with a hook:
  
  \`\`\`
  const footerPaymentMethods = useFooterPaymentMethods(userInfo);

  <Footer paymentMethods={footerPaymentMethods}/>
  \`\`\`
  `,
};
