import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import Box from '../Box';

import * as StarRatingExports from '.';

// eslint-disable-next-line
import StarRatingSrc from '!raw-loader!.';

const { default: StarRating } = StarRatingExports;
const docs = generateDocs(StarRatingSrc, resolver.findAllExportedComponentDefinitions);

const basicExample = {
  name: 'Simple Example',
  description: 'Star ratings with different limits/values',
  overflowVisual: false,
  render: () => (
    <Box>
      <Box marginBottom="m">
        <StarRating outOf={10} rating={5} />
      </Box>
      <Box marginBottom="m">
        <StarRating outOf={5} rating={3} />
      </Box>
      <Box marginBottom="m">
        <StarRating outOf={5} rating={2.3} />
      </Box>
      <Box marginBottom="m">
        <StarRating outOf={5} rating={2.6} />
      </Box>
    </Box>
  ),
  code: `
<Box>
  <Box marginBottom="m">
    <StarRating outOf={10} rating={5} />
  </Box>
  <Box marginBottom="m">
    <StarRating outOf={5} rating={3} />
  </Box>
  <Box marginBottom="m">
    <StarRating outOf={5} rating={2.3} />
  </Box>
  <Box marginBottom="m">
    <StarRating outOf={5} rating={2.6} />
  </Box>
</Box>
  `,
};

const Demo = {
  title: StarRating.displayName,
  description: 'Star ratings for indicating a score out of total.',
  docGen: docs,
  slug: 'StarRating',
  exports: Object.keys(StarRatingExports),
  examples: [
    basicExample,
  ],
  notes: `
  `,
};

export default Demo;
