import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import Card, { HIGH } from '../../../Card';
import { sortItems, itemPropShape } from '../../utils';
import ItemLink from '../../ItemLink';
import NavBadge from '../../NavBadge';
import styles from './styles.scss';

class Item extends Component {
  constructor() {
    super();

    this.state = {
      isMouseOver: false,
      settingsForSubItem: {
        height: 0,
        xPosition: 0,
      },
      delta: 0,
    };

    this.handleOnMouseEnter = this.handleOnMouseEnter.bind(this);
    this.handleOnContextMenu = this.handleOnContextMenu.bind(this);
    this.handleOnMouseLeave = this.handleOnMouseLeave.bind(this);
    this.isRightClicking = false;
  }

  handleOnMouseEnter() {
    const { itemNode, subItemsNode } = this;
    const { settingsForSubItem, settingsOfSubItem, handleHover } = this.props;

    handleHover();

    this.isRightClicking = false;

    if (window.handleOnMouseLeaveTimeout) {
      window.clearTimeout(window.handleOnMouseLeaveTimeout);
    }

    this.setState({ isMouseOver: true }, () => {
      const { xPosition, height } = settingsForSubItem;
      const delta = xPosition === 0 ? 0 : subItemsNode.getBoundingClientRect().left - xPosition;
      const endingSubItemSettings = {
        height: subItemsNode.clientHeight,
        xPosition: subItemsNode.getBoundingClientRect().left,
      };

      this.setState({
        settingsForSubItem: height > 0 ? settingsForSubItem : endingSubItemSettings,
        delta,
      }, () => {
        setTimeout(() => {
          let itemWidth = 0;
          let subItemWidth = 0;
          if (itemNode && subItemsNode) {
            itemWidth = itemNode.getBoundingClientRect().width;
            subItemWidth = subItemsNode.getBoundingClientRect().width;
          }
          this.setState({
            settingsForSubItem: endingSubItemSettings,
            itemWidth,
            subItemWidth,
          }, () => {
            settingsOfSubItem(endingSubItemSettings);
          });
        }, 0);
      });
    });
  }

  handleOnContextMenu() {
    this.isRightClicking = true;
  }

  handleOnMouseLeave() {
    const { settingsOfSubItem } = this.props;

    if (!this.isRightClicking) {
      this.setState({
        isMouseOver: false,
        settingsForSubItem: {
          height: 0,
          xPosition: 0,
        },
        delta: 0,
      });
    }

    window.clearTimeout(window.handleOnMouseLeaveTimeout);

    window.handleOnMouseLeaveTimeout = window.setTimeout(() => {
      settingsOfSubItem({ height: 0, xPosition: 0 });
    }, 100);
  }

  render() {
    const {
      rootItem,
      isActive,
      isInSecondHalf,
      shouldShowSubItems,
      isAnimationEnabled,
      logEvent,
      onNavItemClick,
    } = this.props;
    const {
      itemWidth,
      subItemWidth,
      settingsForSubItem,
      isMouseOver,
      showTakeOver,
      delta,
    } = this.state;
    const itemClass = cnames(styles.item, {
      [styles.isMouseOver]: isMouseOver || this.isRightClicking || shouldShowSubItems,
      [styles.active]: isActive,
      [styles.isInSecondHalf]: isInSecondHalf,
      [styles.showTakeover]: showTakeOver,
      [styles.isAnimationEnabled]: isAnimationEnabled,
    });
    const subItemsStyle = settingsForSubItem.height > 0
      ? {
        height: `${settingsForSubItem.height}px`,
        marginLeft: `${-(delta)}px`,
        transform: `translate(${delta}px)`,
        left: isInSecondHalf
          ? `-${((subItemWidth - itemWidth) + (itemWidth / 2)) - 54}px`
          : `${(itemWidth / 2) - 54}px`,
      }
      : {};
    const allProps = {
      onContextMenu: this.handleOnContextMenu,
    };
    const itemProps = {
      key: rootItem.id,
      className: itemClass,
    };

    if (Array.isArray(rootItem.items) && rootItem.items.length) {
      itemProps.onMouseEnter = () => this.handleOnMouseEnter();
      itemProps.onMouseLeave = () => this.handleOnMouseLeave();
      itemProps.onKeyDown = e => this.props.handleKeyDown(e, rootItem.name);
    }

    // TODO: EXTRACT OUT INTO A FUNCTION.
    const sortedRootItems = [...rootItem.items].sort(sortItems);

    return (
      <li
        {...itemProps}
        {...allProps}
        role="menuitem"
        aria-haspopup={isMouseOver}
        ref={(itemNode) => { this.itemNode = itemNode; }}
      >
        <ItemLink
          action={rootItem.action}
          className={styles.link}
          logEvent={logEvent}
          onMouseDown={() => {
            if (onNavItemClick) onNavItemClick(rootItem);
          }}
          {...allProps}
        >
          {rootItem.label}
          <NavBadge badge={rootItem.badge} />
        </ItemLink>

        {
          !!rootItem.items.length && (
            <div
              {...allProps}
              className={styles.subItems}
              ref={(subItemsNode) => { this.subItemsNode = subItemsNode; }}
              style={subItemsStyle}
              aria-hidden={!shouldShowSubItems || !showTakeOver}
            >
              <Card {...allProps} elevation={HIGH} className={styles.card}>
                <ul
                  {...allProps}
                  className={styles.subList}
                  role="menu"
                >
                  {
                    sortedRootItems.map(item => (
                      <li
                        {...allProps}
                        key={`global-nav_${item.id}`}
                        onClick={() => this.setState({ isMouseOver: false })}
                        className={styles.subItem}
                        role="menuitem"
                      >
                        <ItemLink
                          action={item.action}
                          className={styles.subLink}
                          logEvent={logEvent}
                          onMouseDown={() => {
                            if (onNavItemClick) onNavItemClick(item, rootItem);
                          }}
                          {...allProps}
                        >
                          {item.label}
                          <NavBadge badge={item.badge} />
                        </ItemLink>
                      </li>
                    ))
                  }
                </ul>
              </Card>
              <span className={styles.arrow} />
            </div>
          )
        }
      </li>
    );
  }
}

Item.propTypes = {
  // eslint-disable-next-line react/no-typos
  rootItem: itemPropShape.isRequired,
  settingsForSubItem: PropTypes.shape({}),
  settingsOfSubItem: PropTypes.func,
  handleKeyDown: PropTypes.func,
  isInSecondHalf: PropTypes.bool,
  open: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  shouldShowSubItems: PropTypes.bool,
  isOpen: PropTypes.bool,
  handleHover: PropTypes.func,
  isAnimationEnabled: PropTypes.bool,
  logEvent: PropTypes.func,
  onNavItemClick: PropTypes.func,
};

Item.defaultProps = {
  settingsForSubItem: {},
  settingsOfSubItem: () => {},
  handleKeyDown: () => {},
  isInSecondHalf: false,
  isActive: false,
  shouldShowSubItems: false,
  isOpen: false,
  handleHover: () => {},
  isAnimationEnabled: false,
  logEvent: () => {},
};

Item.displayName = 'Item';

export default Item;
