import React, { useState, useCallback, useEffect } from 'react';
import posed from 'react-pose';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import { usePopper } from 'react-popper';
import onlyText from '../utils/onlyText';
import Box from '../Box';
import Text from '../Text';
import * as constants from '../constants';
import styles from './styles.scss';

const validDisplayTypes = ['block', 'inline-block', 'inline'];
const defaultDisplayType = 'inline-block';

const ease = [0.2, 1, 0.4, 1];

const reducedMotion = typeof window !== 'undefined'
  && typeof window.matchMedia === 'function'
  && window.matchMedia('(prefers-reduced-motion)').matches;

/**
 * Animated Tooltip (fade and scale in, transform origins in CSS)
 */
const Tip = posed.div({
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      ease,
      duration: reducedMotion ? 0 : 300,
    },
  },
  hidden: {
    opacity: 0,
    scale: 0.9,
    transition: {
      ease,
      duration: reducedMotion ? 0 : 150,
    },
  },
});

const PLACEMENT_MAP = {
  [constants.TOP_LEFT]: 'top-start',
  [constants.TOP_RIGHT]: 'top-end',
  [constants.BOTTOM_LEFT]: 'bottom-start',
  [constants.BOTTOM_RIGHT]: 'bottom-end',
};

/**
 * Converts our placement constants to PopperJS constants.
 */
const convertPlacement = (placement) => {
  if (Object.keys(PLACEMENT_MAP).includes(placement)) {
    return PLACEMENT_MAP[placement];
  }

  return placement;
};

const isControlled = open => typeof open === 'boolean';

let rbDSZeroDelay = false;
let rbDSZeroDelayTimeout;
let rbDSEnterTimeout;
let rbDSTooltipEntered = true;

const Tooltip = ({
  trigger,
  children,
  accessibleTitle,
  placement,
  open,
  helpCursor,
  enterDelay,
  display,
  ...props
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles: style, attributes } = usePopper(referenceElement, popperElement, {
    placement: convertPlacement(placement),
    strategy: 'fixed',
  });

  /**
   * The openTooltip and closeTooltip functions
   * allow us to open Tooltips with a delay if they're the first
   * Tooltip to be opened in a while. Closing is always "instant".
   */
  const openTooltip = useCallback(() => {
    if (!isControlled(open)) {
      clearTimeout(rbDSEnterTimeout);
      rbDSTooltipEntered = false;

      rbDSEnterTimeout = setTimeout(() => {
        rbDSTooltipEntered = true;
        setTooltipOpen(true);
      }, rbDSZeroDelay ? 0 : enterDelay);
    }
  }, [open, rbDSTooltipEntered, rbDSZeroDelay, enterDelay]);

  const closeTooltip = useCallback(() => {
    if (!isControlled(open)) {
      clearTimeout(rbDSEnterTimeout);
      clearTimeout(rbDSZeroDelayTimeout);

      if (rbDSTooltipEntered) {
        rbDSZeroDelay = true;
        rbDSZeroDelayTimeout = setTimeout(() => {
          rbDSZeroDelay = false;
        }, 1000);
      }
      setTooltipOpen(false);
    }
  }, [open, rbDSZeroDelayTimeout, rbDSZeroDelay]);

  // The requested placement is not guaranteed, the actualPlacement is the direction
  // the Tooltip has actually rendered in based on screen dimensions / position etc.
  const actualPlacement = get(attributes, ['popper', 'data-popper-placement'], '');

  // Respect the boolean open prop to control the Tooltip
  useEffect(() => {
    if (isControlled(open)) setTooltipOpen(open);
  }, [open]);

  return (
    <React.Fragment>
      <Box
        display={validDisplayTypes.includes(display) ? display : defaultDisplayType}
        aria-haspopup="true"
        aria-label={accessibleTitle}
        aria-expanded={tooltipOpen ? 'true' : 'false'}
        {...props}
      >
        <Box
          className={cnames(
            styles.trigger,
            { [styles.helpCursor]: helpCursor },
          )}
          innerRef={setReferenceElement}
          style={style.reference}
        >
          {
            typeof trigger === 'function' && (
              trigger({
                onMouseEnter: () => openTooltip(),
                onMouseLeave: () => closeTooltip(),
                onFocus: () => openTooltip(),
                onBlur: () => closeTooltip(),
              })
            )
          }
        </Box>

        {tooltipOpen && (
          <div
            ref={setPopperElement}
            className={cnames(
              styles.tooltip,
              {
                [styles.visuallyHidden]: !tooltipOpen,
              },
            )}
            style={style.popper}
            {...attributes.popper}
          >
            <Tip
              placement={actualPlacement || convertPlacement(placement)}
              className={styles.offset}
              initialPose="hidden"
              pose={tooltipOpen ? 'visible' : 'hidden'}
            >
              <Box
                padding="xxs"
                paddingLeft="xs"
                paddingRight="xs"
                display="flex"
                alignItems="center"
                justifyContent="center"
                className={styles.tipBody}
              >
                <Text type="caption">
                  {onlyText(children)}
                </Text>
              </Box>
            </Tip>
          </div>
        )}
      </Box>
    </React.Fragment>
  );
};

Tooltip.propTypes = {
  /**
    * A function that returns the node to be used as a trigger to toggle the Tooltip
    * Provides props to be spread onto the triggering element
    */
  trigger: PropTypes.func.isRequired,
  /**
    * Whether to display a help cursor when hovering the tooltip trigger
    */
  helpCursor: PropTypes.bool,
  /**
    * The content of the Tooltip
    */
  children: PropTypes.node.isRequired,
  /**
    * Describe to screen readers what this Tooltip is for
    */
  accessibleTitle: PropTypes.string.isRequired,
  /**
    * Where to place the Tooltip relative to it's trigger
    */
  placement: PropTypes.oneOf([
    constants.TOP,
    constants.RIGHT,
    constants.BOTTOM,
    constants.LEFT,
    constants.TOP_LEFT,
    constants.TOP_RIGHT,
    constants.BOTTOM_LEFT,
    constants.BOTTOM_RIGHT,
  ]),
  /**
    * How long after interacting with the trigger before the Tooltip appears
    */
  enterDelay: PropTypes.number,
  /**
    * Display mode for the Tooltip wrapper
    */
  display: PropTypes.oneOf(validDisplayTypes),
};

Tooltip.defaultProps = {
  helpCursor: false,
  placement: constants.BOTTOM,
  enterDelay: 300,
  display: defaultDisplayType,
};

Tooltip.displayName = 'Tooltip';

export default Tooltip;
