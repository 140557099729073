import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import cnames from 'classnames';
import Avatar from './Avatar';
import Menu from './Menu';
import Popover from '../../../Popover';
import Tooltip from '../../../Tooltip';
import Box from '../../../Box';
import Button from '../../../Button';
import * as constants from '../../../constants';
import styles from './styles.scss';

const messages = defineMessages({
  accountLabel: 'Account',
  accountMenu: 'User account menu',
});

const UserMenu = ({ className, profile, intl, onTypeaheadResultClick, ...rest }) => {
  const [disableTooltip, setDisableTooltip] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <div className={cnames(styles.userMenu, className)}>
      <Popover
        onCloseRequested={() => { setDisableTooltip(false); setPopoverOpen(false); }}
        open={popoverOpen}
        trigger={() => (
          <Tooltip
            trigger={triggerProps => (
              <Box {...(disableTooltip ? {} : triggerProps)}>
                <Button
                  onClick={() => {
                    triggerProps.onMouseLeave();
                    setDisableTooltip(!disableTooltip);
                    setPopoverOpen(!popoverOpen);
                  }}
                  circle
                  size={constants.LARGE}
                >
                  <Avatar intl={intl} {...rest} />
                </Button>
              </Box>
            )}
            accessibleTitle={intl.formatMessage(messages.accountMenu)}
          >
            {intl.formatMessage(messages.accountLabel)}
          </Tooltip>
        )}
        size={constants.LARGE}
        accessibleTitle={intl.formatMessage(messages.accountMenu)}
      >
        <Menu
          isDesktop={profile === constants.DESKTOP}
          intl={intl}
          onTypeaheadResultClick={onTypeaheadResultClick}
          {...rest}
        />
      </Popover>
    </div>
  );
};

UserMenu.propTypes = {
  // eslint-disable-next-line react/no-typos
  intl: PropTypes.shape({}).isRequired,
  baseUrl: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  isArtist: PropTypes.bool,
  className: PropTypes.string,
  onTypeaheadResultClick: PropTypes.func,
};

UserMenu.defaultProps = {
  isArtist: false,
  className: '',
};

export default UserMenu;
