import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../react/Text';
import styles from './PageDescription.css';

const PageIntro = ({ children }) => {
  return (
    <Text type="display4" element="p" display="block" className={styles.description}>
      {children}
    </Text>
  );
};

PageIntro.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageIntro;
