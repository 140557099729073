import React from 'react';
import { IntlProvider, injectIntl } from 'react-intl';
import { parse as generateDocs, resolver } from 'react-docgen';
// eslint-disable-next-line
import LicensingMarkSrc from '!raw-loader!./';
import LicensingMarkComponent from '.';
import exampleLogo from './exampleLogo.svg';

const LicensingMark = injectIntl(LicensingMarkComponent);

const docs = generateDocs(LicensingMarkSrc, resolver.findAllExportedComponentDefinitions);

const basicExample = {
  name: 'Basic Usage',
  description: 'A licensing mark for an hypothetical intellectual property called Redbubble Logo which rights holder is an hypothetical company called Redbubble.',
  muted: true,
  render: () => (
    <IntlProvider locale="en">
      <LicensingMark
        imageSrc={exampleLogo}
        rightsholderName="Redbubble"
        intellectualPropertyName="Redbubble Logo"
        legalLine="© 2018 Redbubble. All Rights Reserved"
      />
    </IntlProvider>
  ),
  code: `
<LicensingMark
    imageSrc="https://www.example.com/exampleLogo.svg"
    rightsholderName='Redbubble'
    intellectualPropertyName='Redbubble Logo'
    legalLine='© 2018 Redbubble. All Rights Reserved'
/>
  `,
};

const Demo = {
  title: LicensingMarkComponent.displayName,
  description: 'A licensing mark with rights holder logo/indentifying image, copyright language and legal lines.',
  docGen: docs,
  slug: 'licensing-mark',
  examples: [
    basicExample,
  ],
};

export default Demo;
