import React from 'react';
import styles from '../styles.scss';

const SVG = ({
  fillColor,
  brandMarkColor = '#e1295a',
}) => (
  <svg
    role="img"
    data-testid="ds-2020-brandmark-only"
    viewBox="0 0 31.25 32"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.svg}
  >
    <title>Redbubble brandmark</title>
    <path
      data-testid="ds-brandmark"
      fill={fillColor || brandMarkColor}
      d="M15.06 0a18.26 18.26 0 018.09 1.46.23.23 0 01.05.28c-.22.36-.74 1.14-1.29 2-.87 1.32-1.74 2.69-2.59 4-.3.45-.56.84-.8 1.22-.94 1.44-1.77 2.8-2.52 4.11s-1.29 2.21-1.67 2.93l-.22.36a21.86 21.86 0 00-1.52 2.84c0 .1-.09.2-.16.36v.06a4.06 4.06 0 00-.52 1.9 1.35 1.35 0 00.67 1.28 2.11 2.11 0 002-.36A8.87 8.87 0 0016.06 21l.46-.54c.3-.37.62-.76 1-1.23l.69-.9.12-.16.59-.76a26.23 26.23 0 013.18-3.57l.13-.12c.25-.23.39-.36.57-.51a4.79 4.79 0 01.61-.44 1.22 1.22 0 01.85-.26c.16 0 .28.15.37.37a1.91 1.91 0 01-.35 1.5 7.36 7.36 0 01-.52.83c-.18.27-.34.48-.63.85l-.18.24a2.76 2.76 0 01-.17.22c-3.11 4.05-4.46 6.59-4 7.62a.89.89 0 00.8.52c.79.07 1.44-.36 2.25-1.27l.3-.35.28-.33c.31-.34.64-.7 1-1.12l.71-.74.18-.19.07-.08.26-.27c.16-.18.32-.37.52-.62l.16-.22.15-.19a7.2 7.2 0 01.47-.58l.24-.28.14-.17a2.13 2.13 0 01.36-.37.65.65 0 01.23-.14h.16a.47.47 0 01.08.4 1.64 1.64 0 01-.22.53l-.07.12-.05.1-.05.11-.33.66-.29.69c-.11.23-.14.32-.2.44s-.1.25-.15.35-.11.23-.3.58l-.27.51c-.15.29-.27.54-.37.79-.38.91-.43 1.58 0 2a1.58 1.58 0 00.59.38H25.58c.17.08.35.16.59.25l.3.11.45.17.83.32a.31.31 0 01.09.41 13.28 13.28 0 01-7.64 4.74 15.55 15.55 0 01-15-4.17 16.34 16.34 0 01-4.91-9A15.73 15.73 0 013.56 5 13.46 13.46 0 0111.13.5a17.87 17.87 0 013.93-.5zm9.79 2.34a13 13 0 015.67 8 21.67 21.67 0 01.62 7.35 16.89 16.89 0 01-2.14 7.4.36.36 0 01-.46.15l-.2-.07h-.12c-.29-.1-.57-.21-1-.4l-.16-.08-.28-.12a3.58 3.58 0 01-.68-.38.5.5 0 01-.21-.24c-.05-.14 0-.31.14-.6l2-4a3.12 3.12 0 00.26-2.35 1 1 0 00-1-.69 2.19 2.19 0 00-1.5.79l-.21.23-.15.16-.92 1.05c-.8.92-1.24 1.42-1.74 2s-1.09 1.17-1.58 1.65c-.25.24-.45.42-.65.59l-.07.06c-.22.19-.38.3-.45.29h-.05a.69.69 0 010-.48 5.57 5.57 0 011-1.78c.3-.41.64-.83 1-1.29a21.35 21.35 0 003.68-6.08c.45-1.13.36-1.94-.29-2.44a1.81 1.81 0 00-2 0 8.53 8.53 0 00-2.19 1.66c-.62.6-1.25 1.28-2 2.13-.28.33-.51.61-1 1.19l-.27.33c-.05.06-.13.18-.26.34a37.33 37.33 0 01-3.5 4.12c-.57.52-.88.67-1 .59s0-.74.21-1.17A150.07 150.07 0 0124.63 2.4a.24.24 0 01.22-.06z"
    />
  </svg>
);

export default SVG;
