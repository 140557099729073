export const RECENT = 'recent';
export const POPULAR = 'popular';
export const TRENDING = 'trending';
export const CATEGORIES = 'categories';
export const ARTISTS = 'artists';
export const FALLBACK = 'fallback';

export const TYPE_ORDER_FOCUS = [RECENT, TRENDING];
export const TYPE_ORDER_TYPING = [POPULAR, TRENDING, ARTISTS, FALLBACK, CATEGORIES];

export const FOCUS_SCREEN = 'focus_screen';
export const TYPING_SCREEN = 'typing_screen';
