import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import Card, { CardBody } from '../Card';
import Box from '../Box';
import Text from '../Text';
import Image from '../Image';
import * as CarouselExports from '.';
import { MOBILE, DESKTOP } from '../constants';

// eslint-disable-next-line
import CarouselSrc from '!raw-loader!.';

const imageCodes = [
  '731857684.6255',
  '694989391.5815',
  '670810291.9109',
  '679731368.1216',
  '353642383.8437',
  '353636435.7705',
  '395629752.5123',
  '507851276.3195',
  '45015703.8073',
  '33940845.2869',
  '581646829.4538',
  '486295688.7137',
  '212697748.3284',
  '214298707.0188',
  '507851089.8185',
  '235946401.5897',
  '377782299.4700',
];
const largeImages = imageCodes.map(c => `https://ih0.redbubble.net/image.${c}/raf,1000x1000,075,t,fafafa:ca443f4786.jpg`);
const smallImages = imageCodes.map(c => `https://ih0.redbubble.net/image.${c}/raf,200x400,075,t,fafafa:ca443f4786.jpg`);

const { default: Carousel, Slide } = CarouselExports;
const docs = generateDocs(CarouselSrc, resolver.findAllExportedComponentDefinitions);

const demoConfig = responsive => ({
  profile: 'DESKTOP',
  initialValues: {
    profile: responsive.desktop ? DESKTOP : MOBILE,
  },
  fields: [
    {
      type: 'button-toggle',
      options: [
        { value: MOBILE, label: 'Mobile' },
        { value: DESKTOP, label: 'Desktop' },
      ],
      name: 'profile',
      label: 'Profile',
    },
  ],
});

// eslint-disable-next-line
const CardWrapper = ({ children, profile }) => (
  <Card>
    <CardBody>
      <Text type="display3">Portfolio</Text>
      <Box marginBottom="xs" />
      { children }
      <Text muted type="caption">Artwork by kostolom3000</Text>
    </CardBody>
  </Card>
);

const CardWrapperCode = children => `
<Card>
  <CardBody>
    <Text type="display3">Portfolio</Text>
    ${children}
    <Text muted type="caption">Artwork by kostolom3000</Text>
  </CardBody>
</Card>
`;

const generateSlides = (n) => {
  const arrayRange = [...Array(n).keys()];

  return arrayRange.map(i => (
    <Slide key={`some-key-${i}`}>
      <Image fluid src={largeImages[i] || largeImages[0]} alt="An artwork" />
    </Slide>
  ));
};

const lazyChild = img => `{
          lazify => (
            <Image
              {
                ...lazify({
                  fluid: true,
                  src: "${img}",
                  alt: 'An Artwork',
                })
              }
            />
          )
        }`;

const child = img => `<Image fluid src="${img}" alt="An artwork" />`;

const generateSlidesCode = (n, lazy) => {
  const arrayRange = [...Array(n).keys()];

  return arrayRange.map(i => `
      <Slide key="some-key-${i}">
        ${lazy ? lazyChild(largeImages[i] || largeImages[0]) : child(largeImages[i] || largeImages[0])}
      </Slide>\n`).join('');
};


const defaultExample = {
  name: 'Default Carousel',
  muted: true,
  overflowVisual: true,
  description: 'Carousel with default props in Mobile / Desktop',
  config: responsive => demoConfig(responsive),
  render: (responsive, { profile }) => (
    <CardWrapper profile={profile}>
      <Carousel profile={profile} slidesPerView={responsive.desktop ? 4 : 2}>
        { generateSlides(10) }
      </Carousel>
    </CardWrapper>
  ),
  code: (_, { profile }) => CardWrapperCode(`
    { /* Not all slides shown for brevity, all Slides must have a unique key */ }
      <Carousel profile={${profile === MOBILE ? 'constants.MOBILE' : 'constants.DESKTOP'}}>
        ${generateSlidesCode(4)}
      </Carousel>
  `, profile),
};


const autoExample = {
  name: 'Fixed or Variable slide width',
  muted: true,
  config: responsive => ({
    ...demoConfig(responsive),
    initialValues: {
      ...demoConfig(responsive).initialValues,
      sizes: 'variable',
    },
    fields: [
      ...demoConfig(responsive).fields,
      {
        type: 'button-toggle',
        options: [
          { value: 'fixed', label: 'Fixed' },
          { value: 'variable', label: 'Variable' },
        ],
        name: 'sizes',
        label: 'Sizes',
      },
    ],
  }),
  overflowVisual: false,
  description: 'Pass a value of <code>auto</code> to <code>slidesPerView</code> to automatically fit slides into the viewport, regardless of whether they are fixed / variable width.',
  render: (_, { profile, sizes }) => (
    <CardWrapper profile={profile}>
      <Carousel profile={profile} slidesPerView="auto">
        {
          [...Array(imageCodes.length).keys()].map((i) => {
            let size = 'large';
            if (sizes === 'variable') size = Math.random() >= 0.5 ? 'large' : 'small';

            return (
              <Slide key={`auto-key-${i}`}>
                <Box style={{ width: size === 'large' ? 200 : 100 }} key={`some-key-${i}`}>
                  <Card>
                    <Image
                      fluid
                      src={size === 'large' ? largeImages[i] : smallImages[i]}
                      alt="An artwork"
                    />
                  </Card>
                </Box>
              </Slide>
            );
          })
        }
      </Carousel>
    </CardWrapper>
  ),
  code: (_, { profile, sizes }) => CardWrapperCode(`
    { /* Not all slides shown for brevity, all Slides must have a unique key */ }
    <Carousel profile={${profile === MOBILE ? 'constants.MOBILE' : 'constants.DESKTOP'}} slidesPerView="auto">${
  [...Array(6).keys()].map((i) => {
    let size = 'large';
    if (sizes === 'variable') size = Math.random() >= 0.5 ? 'large' : 'small';

    return `
      <Slide key="auto-key-${i}">
        <Box style={{ width: ${size === 'large' ? 200 : 100}}} key="some-key-${i}">
          <Card>
            <Image
              fluid
              src="${size === 'large' ? largeImages[i] : smallImages[i]}"
              alt="An artwork"
            />
          </Card>
        </Box>
      </Slide>
    `;
  }).join('')
}</Carousel>
  `, profile),
};

const adjustableSlidesExample = {
  name: 'Adjust number of visible slides',
  muted: true,
  overflowVisual: false,
  config: responsive => ({
    ...demoConfig(responsive),
    initialValues: {
      ...demoConfig(responsive).initialValues,
      slidesPerView: responsive.desktop ? 5 : 3,
    },
    fields: [
      ...demoConfig(responsive).fields,
      {
        type: 'text',
        name: 'slidesPerView',
        label: 'Slides',
      },
    ],
  }),
  description: `
  Use the <code>slidesPerView</code> prop to customize how many slides are visible. <br /> When using the mobile profile, an additional 0.25 is added to <code>slidesPerView</code>
  `,
  render: (_, { profile, slidesPerView }) => {
    const numSlides = parseFloat(slidesPerView, 10) || 1;

    return (
      <CardWrapper profile={profile}>
        <Carousel profile={profile} slidesPerView={numSlides}>
          { generateSlides(Math.round(numSlides) * 2) }
        </Carousel>
      </CardWrapper>
    );
  },
  code: (_, { profile, slidesPerView }) => CardWrapperCode(`
    { /* Not all slides shown for brevity, all Slides must have a unique key */ }
    <Carousel profile={${profile === MOBILE ? 'constants.MOBILE' : 'constants.DESKTOP'}} slidesPerView={${parseFloat(slidesPerView, 10) || 1}}>
      ${generateSlidesCode(6)}
    </Carousel>
  `, profile),
};

const inactiveExample = {
  name: 'Inactive Carousel',
  muted: true,
  overflowVisual: false,
  config: demoConfig,
  description: `
    If the number of slides in a carousel is less than or equal to slidesPerView,
    then the carousel will be rendered inactive.
  `,
  render: (_, { profile }) => (
    <CardWrapper profile={profile}>
      <Carousel profile={profile} slidesPerView={3} showControls>
        { generateSlides(3) }
      </Carousel>
    </CardWrapper>
  ),
  code: (_, { profile }) => CardWrapperCode(`
    { /* Not all slides shown for brevity, all Slides must have a unique key */ }
    <Carousel profile={${profile === MOBILE ? 'constants.MOBILE' : 'constants.DESKTOP'}} slidesPerView={3}>
      ${generateSlidesCode(3)}
    </Carousel>
  `, profile),
};

const variantsExample = {
  name: 'Carousel Variants',
  muted: true,
  overflowVisual: false,
  config: demoConfig,
  description: `
    There's no real limit to what you can put in a carousel slide
  `,
  render: (responsive, { profile }) => (
    <CardWrapper profile={profile}>
      <Carousel profile={profile} slidesPerView={responsive.desktop ? 4 : 2}>
        {
          Array(Math.round(imageCodes.length / 2) - 1).fill(null).map((k, i) => (
            // eslint-disable-next-line
            <Slide key={i}>
              <Box marginBottom="m">
                <Image
                  fluid
                  src={largeImages[2 * i]}
                  alt="placeholder"
                />
              </Box>
              <Box>
                <Image
                  fluid
                  src={largeImages[2 * i + 1]}
                  alt="placeholder"
                />
              </Box>
            </Slide>
          ))
        }
      </Carousel>
    </CardWrapper>
  ),
  code: (_, { profile }) => CardWrapperCode(`
    { /* Not all slides shown for brevity, all Slides must have a unique key */ }
    <Carousel profile={${profile === MOBILE ? 'constants.MOBILE' : 'constants.DESKTOP'}} slidesPerView={3}>
      <Slide key="some-key-1">
        <Box marginBottom="m">
          <Image
            fluid
            src="${largeImages[0]}"
            alt="placeholder"
          />
        </Box>
        <Box>
          <Image
            fluid
            src="${largeImages[1]}"
            alt="placeholder"
          />
        </Box>
      </Slide>
      <Slide key="some-key-2">
        <Box marginBottom="m">
          <Image
            fluid
            src="${largeImages[2]}"
            alt="placeholder"
          />
        </Box>
        <Box>
          <Image
            fluid
            src="${largeImages[3]}"
            alt="placeholder"
          />
        </Box>
      </Slide>
    </Carousel>
  `, profile),
};

const reportExample = {
  name: 'Report on slide visibility',
  muted: true,
  overflowVisual: true,
  description: 'You can subscribe to which slides are visible/not',
  config: responsive => demoConfig(responsive),
  render: (responsive, { profile }) => (
    <CardWrapper profile={profile}>
      <Carousel
        profile={profile}
        slidesPerView={responsive.desktop ? 4 : 2}
        // eslint-disable-next-line no-console
        reportOnVisibility={slides => console.log(slides)}
      >
        { generateSlides(20) }
      </Carousel>
    </CardWrapper>
  ),
  code: (responsive, { profile }) => CardWrapperCode(`
    { /* Not all slides shown for brevity, all Slides must have a unique key */ }
    <Carousel
      profile={${profile === MOBILE ? 'constants.MOBILE' : 'constants.DESKTOP'}}
      slidesPerView={${responsive.desktop ? 4 : 2}}
      reportOnVisibility={slides => console.log(slides)}
    >
      ${generateSlidesCode(2)}
    </Carousel>
  `, profile),
};

const customButtonsPositionExample = {
  name: 'Desktop custom buttons position',
  muted: true,
  overflowVisual: true,
  description: 'Carousel with previous/next buttons having custom position on Desktop.',
  render: () => (
    <CardWrapper profile={DESKTOP}>
      <Carousel profile={DESKTOP} slidesPerView={4} buttonsPosition="var(--ds-spacing-s)">
        { generateSlides(10) }
      </Carousel>
    </CardWrapper>
  ),
  code: () => CardWrapperCode(`
      <Carousel profile={'constants.DESKTOP'} buttonsPosition="var(--ds-spacing-s)">
        ${generateSlidesCode(4)}
      </Carousel>
  `),
};

const Demo = {
  title: Carousel.displayName,
  description: 'Carousels are good for displaying a larger volume of content without taking up much vertical space on the page.',
  slug: 'carousel',
  exports: Object.keys(CarouselExports),
  docGen: docs,
  examples: [
    defaultExample,
    autoExample,
    adjustableSlidesExample,
    variantsExample,
    inactiveExample,
    reportExample,
    customButtonsPositionExample,
  ],
};

export default Demo;
