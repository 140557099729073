/* eslint-disable max-len */
import React from 'react';
import Markdown from '../../../components/Markdown';

const devGuide = `
## Preparing the SVG asset

Icons start with an SVG file that follows the rules of our [Iconography guidelines](/guidelines/iconography). Make sure that it is made up only of fills and not strokes.

Once you have an SVG of your icon you'll need to make the following adjustments to the SVG code:

##### Run the SVG through an optimizer

We recommend running the SVG through an optimizer like [SVGO](https://github.com/svg/svgo) ([GUI](https://jakearchibald.github.io/svgomg/)).
This should reduce the file size and complexity of the SVG asset.

##### Add / update the viewbox

If there is no \`viewbox\` attribute on the svg you'll need to add one.
Either add or update it to the following:

\`\`\`
viewBox="0 0 24 24"
\`\`\`


##### Remove widths and heights

You will need to remove any \`width\` and \`height\` attributes from the SVG. This allows it to be sized fluidly.


##### Replace fills with currentColor

Any \`fill\` attribute should be given the value of \`currentColor\`.

The \`currentColor\` variable is a built-in CSS property that always points to the closes current value of the CSS \`color\` rule.

This means that we can adjust the fill color of the svg by setting the \`color\` in CSS. It also keeps the SVG color aligned it's surrounding text automatically.

e.g.

\`\`\`
fill="currentColor"
\`\`\`

## Creating the Icon component

Once your SVG has had the above alterations made, you can drop it into the \`react/Icon/svgs\` directory.

Now, run the following to generate a React component version of your icon:

\`\`\`
yarn icon
\`\`\`

This script does a couple of things:

- Creates an Icon component in \`react/Icons/*.js\`
- Exposes this new Icon in Playroom
- Ensures this Icon is visible on the Icon documentation page

> Note that this script will generate icons for ALL new svgs in the \`react/Icon/svgs\` directory.

### Verify and Contribute

Verify that your Icon works correctly by visiting the [Icon demo page](/components/Icon/examples).

If you're happy with it, submit a pull request for review.
`;

const Theming = () => {
  return (
    <React.Fragment>
      <Markdown>
        {`
# Icons

#### Icons are React components generated from SVGs that follow a set of visual and semantic guidelines to ensure consistent appearance and behaviour.
  `}
      </Markdown>
      <Markdown>{devGuide}</Markdown>
    </React.Fragment>
  );
};

export default Theming;
