import React from 'react';
import cnames from 'classnames';
import useResponsive from '../../../hooks/useResponsive';
import PageIntro from '../../../components/PageIntro';
import PageTitle from '../../../components/PageTitle';
import PageDescription from '../../../components/PageDescription';
import Markdown from '../../../components/Markdown';
import PhotographySlider from '../../../components/PhotographySlider';
import { PanelFooter } from '../../../components/Panel';
import IntroSection from './Intro/IntroSection';
import TextContainer from '../../../components/TextContainer';
import ExternalLinkIcon from '../../../../react/Icons/ExternalLink';
import Text from '../../../../react/Text';
import Button from '../../../../react/Button';
import Box from '../../../../react/Box';
import Image, { generatePlaceholderSrc } from '../../../../react/Image';
import Grid from '../../../../react/Grid';
import MediaCard from '../../../../react/MediaCard';
import * as constants from '../../../../react/constants';
import styles from './Photography.css';

import brand1Small from './media/brand1-small.jpg';
import brand1Large from './media/brand1-large.jpg';
import brand2Small from './media/brand2-small.jpg';
import brand2Large from './media/brand2-large.jpg';
import brand3Small from './media/brand3-small.jpg';
import brand3Large from './media/brand3-large.jpg';
import brand4Small from './media/brand4-small.jpg';
import brand4Large from './media/brand4-large.jpg';

import lifestyle1Small from './media/lifestyle1-small.jpg';
import lifestyle1Large from './media/lifestyle1-large.jpg';
import lifestyle2Small from './media/lifestyle2-small.jpg';
import lifestyle2Large from './media/lifestyle2-large.jpg';
import lifestyle3Small from './media/lifestyle3-small.jpg';
import lifestyle3Large from './media/lifestyle3-large.jpg';
import lifestyle4Small from './media/lifestyle4-small.jpg';
import lifestyle4Large from './media/lifestyle4-large.jpg';

import previewLifestyle1Large from './media/previewlifestyle1-large.jpg';
import previewLifestyle1Small from './media/previewlifestyle1-small.jpg';
import previewLifestyle2Large from './media/previewlifestyle2-large.jpg';
import previewLifestyle2Small from './media/previewlifestyle2-small.jpg';
import previewLifestyle3Large from './media/previewlifestyle3-large.jpg';
import previewLifestyle3Small from './media/previewlifestyle3-small.jpg';
import previewLifestyle4Large from './media/previewlifestyle4-large.jpg';
import previewLifestyle4Small from './media/previewlifestyle4-small.jpg';

import preview1Large from './media/preview1-large.jpg';
import preview2Large from './media/preview2-large.jpg';
import preview3Large from './media/preview3-large.jpg';
import preview4Large from './media/preview4-large.jpg';
import preview1Small from './media/preview1-small.jpg';
import preview2Small from './media/preview2-small.jpg';
import preview3Small from './media/preview3-small.jpg';
import preview4Small from './media/preview4-small.jpg';

import cantoLink from './media/canto-link.jpg';

import inclusive from './media/photography-inclusive.svg';
import playful from './media/photography-playful.svg';
import helpful from './media/photography-helpful.svg';
import honor from './media/photography-honor.svg';

const images = {
  brand: [
    {
      small: brand1Small,
      large: brand1Large,
    },
    {
      small: brand2Small,
      large: brand2Large,
    },
    {
      small: brand3Small,
      large: brand3Large,
    },
    {
      small: brand4Small,
      large: brand4Large,
    },
  ],
  lifestyle: [
    {
      small: lifestyle1Small,
      large: lifestyle1Large,
    },
    {
      small: lifestyle2Small,
      large: lifestyle2Large,
    },
    {
      small: lifestyle3Small,
      large: lifestyle3Large,
    },
    {
      small: lifestyle4Small,
      large: lifestyle4Large,
    },
  ],
  previewLifestyle: [
    {
      small: previewLifestyle1Small,
      large: previewLifestyle1Large,
    },
    {
      small: previewLifestyle2Small,
      large: previewLifestyle2Large,
    },
    {
      small: previewLifestyle3Small,
      large: previewLifestyle3Large,
    },
    {
      small: previewLifestyle4Small,
      large: previewLifestyle4Large,
    },
  ],
  preview: [
    {
      small: preview1Small,
      large: preview1Large,
    },
    {
      small: preview2Small,
      large: preview2Large,
    },
    {
      small: preview3Small,
      large: preview3Large,
    },
    {
      small: preview4Small,
      large: preview4Large,
    },
  ],
};

const artists = [
  {
    name: 'Dan Elijah Fajardo',
    shopUrl: 'https://www.redbubble.com/people/dandingeroz/shop',
    avatar: 'https://ih1.redbubble.net/avatar.617598.140x140.jpg',
  },
];

const relatedContent = [
  {
    name: 'Personality',
    link: '/identity/personality',
  },
];

const IndexListItem = ({ linkTo, name, active }) => {
  const classnames = cnames(styles.indexListItem, {
    [styles.active]: active,
  });

  return (
    <Box
      element="li"
      className={classnames}
      paddingLeft="m"
    >
      <Button href={`#${linkTo}`}>{name}</Button>
    </Box>
  );
};

const IndexList = (props) => {
  return (
    <Box
      element="ul"
      className={styles.indexList}
    >
      {props.children}
    </Box>
  );
};

const Photography = () => {
  const { mobile } = useResponsive();
  return (
    <>
      {/* eslint-disable max-len */}
      <Box className={styles.content}>
        <PageIntro>
          <PageTitle>Photography</PageTitle>
          <PageDescription>
            <TextContainer>
              <Text type="display4">Visual communication is vital to expressing who we are. It’s how we highlight our creativity and sophisticated weirdness. Redbubble photography runs the spectrum from ultra expressive to super functional. But no matter what, all photography should adhere to the following principles.</Text>
            </TextContainer>
          </PageDescription>
        </PageIntro>
        <Box className={styles.indexContainer}>
          <IndexList>
            <IndexListItem linkTo="principles" name="Principles" active />
            <IndexListItem linkTo="overview" name="Overview" />
            <IndexListItem linkTo="library" name="Image Library" />
            <IndexListItem linkTo="guidelines" name="Guidelines" />
          </IndexList>
        </Box>
        <Box marginTop="xl" id="principles" />
        <TextContainer>
          <Markdown
            source={`

              ## Principles
            `}
          />
        </TextContainer>
        <IntroSection imageOn="right" imageSrc={inclusive}>
          <TextContainer>
            <Markdown
              source={`
                ### Inclusive

                We are a marketplace that connects a global community of artists with millions of customers through all kinds of artwork. Highlight diverse races, genders, body types, and ages. The realer the model, the better.
              `}
            />
          </TextContainer>
        </IntroSection>
        <IntroSection imageOn="left" imageSrc={playful}>
          <TextContainer>
            <Markdown
              source={`
                ### Playful

                Don’t be afraid to mess around. Avoid the middle of the road. Spice things up where appropriate. We’re here to celebrate creativity, so let’s add a healthy dose to everything we make. Keep things fun, colorful, and unexpected.
              `}
            />
          </TextContainer>
        </IntroSection>
        <IntroSection imageOn="right" imageSrc={helpful}>
          <TextContainer>
            <Markdown
              source={`
                ### Helpful

                Photography should be interesting, informative, and useful. Whether it’s meant to grab attention, sell a product, or show customers how a t-shirt fits. Every photo we take has a purpose. Keep it clear.
              `}
            />
          </TextContainer>
        </IntroSection>
        <IntroSection imageOn="left" imageSrc={honor}>
          <TextContainer>
            <Markdown
              source={`
                ### Honor the art

                We would be nothing without the designs artists have added to the products available on Redbubble. Never forget that the art should be front and center in every image. The photos, props, talent, and setting should support the art, and vice versa.
              `}
            />
          </TextContainer>
        </IntroSection>
        <Box marginTop="xl" id="overview" />
        <Markdown
          source={`


## Overview

Our photography spans from Expressive to Functional. We categorize it into four main groups: Brand, Lifestyle, Lifestyle Previews, and Previews.

Use the slider below to see examples.

`}
        />
        <Box paddingTop="xl" />
        <PhotographySlider />
        <Box paddingTop="xl" marginTop="m" paddingBottom="m" id="library">
          <Markdown
            source={`

          ## Libraries

          Our photography library is hosted in Canto.
        `}
          />
        </Box>
        <Grid columns={mobile ? 1 : 2}>
          <Box className={styles.imageHoverContainer}>
            <MediaCard
              href="https://redbubble.canto.com/"
              imageRender={() => (
                <Image
                  fluid
                  src={cantoLink}
                  loadOnVisible={{
                    placeholder: generatePlaceholderSrc({ width: 300, height: 100 }),
                  }}
                />
              )}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                paddingLeft="xs"
              >
                <Text display="block" type="display5">
                  Canto
                </Text>
                <Box paddingLeft="m" paddingRight="xs" display="flex" alignItems="center">
                  <ExternalLinkIcon />
                </Box>
              </Box>
            </MediaCard>
          </Box>
        </Grid>
        <Box paddingTop="xl" marginTop="xl" paddingBottom="xl" id="guidelines">
          <Markdown
            source={`


  ## Brand

  Celebrating the weirdly meaningful connections formed on the Redbubble marketplace through artists, their designs, and customers. This is where we strut our creative stuff and push the sophisticated weirdness. Tells a simple to understand, yet eye-catching story that begins with the artwork. At this level, Redbubble products may be shown in ways they are not normally used (ie, someone with stickers all over their face).
`}
          />
        </Box>
        <Grid columns={mobile ? 1 : 2}>
          <Box marginBottom="xl" paddingRight={!mobile && 2}>
            <Text type="display3" display="block" marginBottom="m">Lighting & Color</Text>
            <Text display="block">Well-lit, prevent harsh shadows and heavy contrast. Playful and expressive use of color, don’t overwhelm the artwork on products.</Text>
          </Box>
          <Box marginBottom="xl" paddingRight={!mobile && 2}>
            <Text type="display3" display="block" marginBottom="m">Environment</Text>
            <Text display="block">Shot in a studio, but set wherever your creative vision takes you. Outer space? The desert? A simple, solid seamless? Up to you. </Text>
          </Box>
          <Box marginBottom="xl" paddingRight={!mobile && 2}>
            <Text type="display3" display="block" marginBottom="m">Styling</Text>
            <Text display="block">Props and wardrobe should support the style and story. Never use anything that could be mistaken for a product sold on the Redbubble marketplace.</Text>
          </Box>
        </Grid>
        <Box className={styles.imageGallery} marginTop="xl">
          {images.brand.map(img => (
            <Box className={styles.imageGalleryItem}>
              <Image
                src={img.large}
                srcSet={`
                  ${img.small} 400w,
                  ${img.large} 800w,
                `}
                sizes="(min-width: 480px) 400px, 200px"
                fluid
                ratio={constants.RATIO_LANDSCAPE}
              />
            </Box>
          ))}
        </Box>
        <Box paddingTop="xl" marginTop="xl" paddingBottom="xl">
          <Markdown
            source={`
  ## Lifestyle

  How we show products (and the people who enjoy them) in the real world. Should be more grounded than brand photography, but not at the expense of creativity.  It’s okay to get weird, but pull it back a bit.
`}
          />
        </Box>
        <Grid columns={mobile ? 1 : 2}>
          <Box marginBottom="xl" paddingRight={!mobile && 2}>
            <Text type="display3" display="block" marginBottom="m">Lighting & Color</Text>
            <Text display="block">Well-lit to prevent harsh shadows and heavy contrast. Use bright, sunny light for outdoor shots.</Text>
          </Box>
          <Box marginBottom="xl" paddingRight={!mobile && 2}>
            <Text type="display3" display="block" marginBottom="m">Environment</Text>
            <Text display="block">Shot on location, indoors or out. Make it relatable and aspirational.</Text>
          </Box>
          <Box marginBottom="xl" paddingRight={!mobile && 2}>
            <Text type="display3" display="block" marginBottom="m">Styling</Text>
            <Text display="block">Styling should complement the product, not overshadow it.</Text>
          </Box>
        </Grid>
        <Box className={styles.imageGallery} marginTop="xl">
          {images.lifestyle.map(img => (
            <Box className={styles.imageGalleryItem}>
              <Image
                src={img.large}
                srcSet={`
                  ${img.small} 400w,
                  ${img.large} 800w,
                `}
                sizes="(min-width: 480px) 400px, 200px"
                fluid
                ratio={constants.RATIO_LANDSCAPE}
              />
            </Box>
          ))}
        </Box>
        <Box paddingTop="xl" marginTop="xl" paddingBottom="xl">
          <Markdown
            source={`
  ## Lifestyle Previews

  Product Lifestyle Previews communicate the scale, common applications or use cases of a particular product in a lifestyle setting. They are used to communicate the additional lifestyle benefits and aspirational qualities of a product.
`}
          />
        </Box>
        <Grid columns={mobile ? 1 : 2}>
          <Box marginBottom="xl" paddingRight={!mobile && 2}>
            <Text type="display3" display="block" marginBottom="m">Lighting & Color</Text>
            <Text display="block">Clean and neutral backgrounds that use shadow to provide dimension. Avoid using colorful backgrounds.</Text>
          </Box>
          <Box marginBottom="xl" paddingRight={!mobile && 2}>
            <Text type="display3" display="block" marginBottom="m">Environment</Text>
            <Text display="block">Set in a decorated studio environment. Make it feel authentic and lived-in. Avoid clutter that could distract too much from the product.</Text>
          </Box>
          <Box marginBottom="xl" paddingRight={!mobile && 2}>
            <Text type="display3" display="block" marginBottom="m">Styling</Text>
            <Text display="block">Use common objects that relate to the primary application or use case of the product. Try to find a balance of old and new that provides a variety of textures.</Text>
          </Box>
        </Grid>
        <Box className={styles.imageGallery} marginTop="xl">
          {images.previewLifestyle.map(img => (
            <Box className={styles.imageGalleryItem}>
              <Image
                src={img.small}
                srcSet={`
                  ${img.small} 400w,
                  ${img.large} 800w,
                `}
                sizes="(min-width: 480px) 400px, 200px"
                fluid
                ratio={constants.RATIO_LANDSCAPE}
              />
            </Box>
          ))}
        </Box>
        <Box marginTop="xl" />
        <Box paddingTop="xl" marginTop="xl" paddingBottom="xl">
          <Markdown
            source={`
  ## Previews

  Product previews are used to communicate the key details of the product and help consumers assess the product’s suitability for their needs. Brand expression, while still important, generally takes a back seat with product previews. The primary emphasis should always be on the product and the unique details that make it appealing for a consumer.
`}
          />
        </Box>
        <Grid columns={mobile ? 1 : 2}>
          <Box marginBottom="xl" paddingRight={!mobile && 2}>
            <Text type="display3" display="block" marginBottom="m">Lighting & Color</Text>
            <Text display="block">Clean and neutral backgrounds that use shadow to provide dimension. Avoid using colorful backgrounds.</Text>
          </Box>
          <Box marginBottom="xl" paddingRight={!mobile && 2}>
            <Text type="display3" display="block" marginBottom="m">Environment</Text>
            <Text display="block">Set in a neutral studio environment with minimal styling or decoration.</Text>
          </Box>
          <Box marginBottom="xl" paddingRight={!mobile && 2}>
            <Text type="display3" display="block" marginBottom="m">Styling</Text>
            <Text display="block">Only introduce props or other elements into the scene if necessary to communicate things like scale or application.</Text>
          </Box>
        </Grid>
        <Box className={styles.imageGallery} marginTop="xl">
          {images.preview.map(img => (
            <Box className={styles.imageGalleryItem}>
              <Image
                src={img.large}
                srcSet={`
                  ${img.small} 400w,
                  ${img.large} 800w,
                `}
                sizes="(min-width: 480px) 400px, 200px"
                fluid
                ratio={constants.RATIO_LANDSCAPE}
              />
            </Box>
          ))}
        </Box>
        <Box paddingTop="xl" marginTop="xl" />
      </Box>
      <PanelFooter artists={artists} relatedContent={relatedContent} />
    </>
  );
};

export default Photography;
