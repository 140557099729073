import React, { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '../Box';
import useMenuItem from './useMenuItem';
import useTracking, { trackingShape, EVENT_CLICK } from '../hooks/useTracking';
import MenuContext from './MenuContext';

const events = { EVENT_CLICK };

const MenuLink = forwardRef(({
  children,
  active,
  highlighted,
  iconBefore,
  iconAfter,
  tracking,
  onClick,
  style, // don't forward
  className, // don't forward
  ...rest
}, ref) => {
  const { track } = useTracking(tracking, 'MenuLink', events);
  const { isMenu } = useContext(MenuContext);

  const {
    classNames,
    IconBefore,
    IconAfter,
    Content,
  } = useMenuItem({
    active,
    highlighted,
    iconBefore,
    iconAfter,
  });

  return (
    <Box
      element="a"
      display="flex"
      alignItems="center"
      className={classNames}
      {...rest}
      innerRef={ref}
      onClick={(...args) => {
        // Only track if this MenuLink is inside a Menu
        if (isMenu) track(EVENT_CLICK);
        if (typeof onClick === 'function') onClick(...args);
      }}
    >
      <IconBefore />
      <Content>
        { children }
      </Content>
      <IconAfter />
    </Box>
  );
});

MenuLink.propTypes = {
  /**
    * The text for this menu item
    */
  children: PropTypes.node.isRequired,
  /**
    * Where to link to
    */
  href: PropTypes.string,
  /**
    * Whether or not to display this item as active
    */
  active: PropTypes.bool,
  /**
    * An <Icon /> to show on the right of the item
    */
  iconAfter: PropTypes.node,
  /**
    * An <Icon /> to show on the left of the item
    */
  iconBefore: PropTypes.node,
  /**
    * Tracking configuration for auto tracking
    */
  tracking: trackingShape,
};

MenuLink.defaultProps = {};

MenuLink.displayName = 'MenuLink';

export default MenuLink;
