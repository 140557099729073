import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import cnames from 'classnames';
import posed from 'react-pose';
import Text from '../Text';
import Label from '../Label';
import Box from '../Box';
import CloseIcon from '../Icons/Close';
import styles from './styles.scss';
import useTracking, { trackingShape, EVENT_DISMISS } from '../hooks/useTracking';
import * as constants from '../constants';

export const events = { EVENT_DISMISS };

export const INFORMATION = 'information';
export const WARNING = 'warning';
export const ERROR = 'error';
export const SUCCESS = 'success';

const AlertWrapper = posed.div({
  open: { height: 'auto', opacity: 1 },
  closed: { height: 0, opacity: 0, y: '-16px' },
});

const Alert = (props) => {
  const {
    children,
    subtle,
    title,
    intent,
    dismissable,
    banner,
    centered,
    label,
    onDismiss,
    tracking,
  } = props;

  const { track, TrackingContextProvider } = useTracking(tracking, 'Alert');
  const [closed, setClosed] = useState(false);

  const handleDismiss = () => {
    setClosed(true);
    track(EVENT_DISMISS);
    onDismiss();
  };

  if (!title && !children) return null;

  const labels = defineMessages({
    information: 'Info',
    success: 'Success',
    warning: 'Warning',
    error: 'Error',
  });

  const labelText = typeof label === 'string' ? label : <FormattedMessage {...labels[intent]} />;

  return (
    <TrackingContextProvider>
      <AlertWrapper
        pose={closed ? 'closed' : 'open'}
        className={
          cnames(
            styles.alert,
            styles[intent],
            {
              [styles.banner]: banner,
              [styles.subtle]: subtle,
            },
          )
        }
      >
        <div className={
            cnames(
              styles.container,
              {
                [styles.dismissable]: dismissable,
                [styles.centered]: centered,
              },
            )
          }
        >
          {
            dismissable && (
              <button
                type="button"
                aria-label="Close"
                className={styles.close}
                onClick={handleDismiss}
              >
                <CloseIcon size={constants.MEDIUM} />
              </button>
            )
          }
          {
            label && (
              <Box marginBottom="xs">
                <Label intent={intent} subtle>
                  { labelText }
                </Label>
              </Box>
            )
          }
          {
            title && (
              <div className={styles.title}>
                <Text type="display5" display="block">
                  { title }
                </Text>
              </div>
            )
          }
          { children }
        </div>
      </AlertWrapper>
    </TrackingContextProvider>
  );
};

Alert.displayName = 'Alert';

Alert.propTypes = {
  /**
    * The title of the notification
    */
  children: PropTypes.node,
  /**
    * The title of the notification
    */
  title: PropTypes.string,
  /**
    * The semantic intention of the notification
    */
  intent: PropTypes.oneOf([
    WARNING,
    INFORMATION,
    ERROR,
    SUCCESS,
  ]),
  /**
    * Make the alert less imposing
    */
  subtle: PropTypes.bool,
  /**
    * Called when the alert is dismissed
    */
  onDismiss: PropTypes.func,
  /**
    * When false, the close button doesn't show
    */
  dismissable: PropTypes.bool,
  /**
    * Show as a banner, edge to edge
    */
  banner: PropTypes.bool,
  /**
    * Centers all content inside the Alert including text
    */
  centered: PropTypes.bool,
  /**
    * This can be set to true, false or a string. If true it will default to the intent.
    */
  label: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  /**
    * Tracking configuration for auto tracking
    */
  tracking: trackingShape,
};

Alert.defaultProps = {
  children: null,
  centered: false,
  title: '',
  intent: INFORMATION,
  subtle: false,
  onDismiss: () => {},
  dismissable: false,
  banner: false,
  label: true,
  tracking: null,
};

export default Alert;
