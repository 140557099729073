import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import Box from '../Box';
import Image from '../Image';
import * as constants from '../constants';
import freeMinds from '../../src/images/artworks/freeMinds.jpg';
import freeMinds2 from '../../src/images/artworks/freeMinds2.jpg';
import gdBee from '../../src/images/artworks/gdBee.jpg';
import gdBee2 from '../../src/images/artworks/gdBee2.jpg';
import sylvainSarrailh from '../../src/images/artworks/sylvainSarrailh.jpg';

import * as ImagePaletteExports from '.';

// eslint-disable-next-line
import ImagePaletteSrc from '!raw-loader!.';

const { default: ImagePalette } = ImagePaletteExports;
const docs = generateDocs(ImagePaletteSrc, resolver.findAllExportedComponentDefinitions);

// const cors = url => `https://cors-anywhere.herokuapp.com/${url}`;

const EXAMPLE_URLS = [
  freeMinds,
  freeMinds2,
  gdBee,
  gdBee2,
  sylvainSarrailh,
];

const basicExample = {
  name: 'Simple Example',
  description: 'Provide an image and use the resulting colors in your UI',
  overflowVisual: false,
  render: () => (
    <>
      {
        EXAMPLE_URLS.map(url => (
          <ImagePalette src={url}>
            {
              ({ colors, loading, error }) => {
                if (loading) return <Box>Loading image...</Box>;

                if (error) {
                  return <Box>Error loading image...</Box>;
                }

                return (
                  <Box
                    marginBottom="m"
                    style={{ position: 'relative', width: 300, maxWidth: 300 }}
                  >
                    <Image
                      fluid
                      src={url}
                      ratio={constants.RATIO_SQUARE}
                    />

                    <Box display="flex" style={{ position: 'absolute', left: 0, bottom: 0, width: '100%' }}>
                      {
                        colors.map(value => (
                          <Box flex="1" key={value} style={{ background: value, height: 20 }} />
                        ))
                      }
                    </Box>
                  </Box>
                );
              }
            }
          </ImagePalette>
        ))
      }
    </>
  ),
  code: `
<ImagePalette src="https://placekitten.com/200/200">
  {
    ({ colors, loading, error }) => {
      if (loading) return <Box>Loading image...</Box>;

      if (error) {
        return <Box>Error loading image...</Box>;
      }

      return (
        <Box display="flex">
          <Image
            roundedCorners
            src={url}
            ratio={constants.RATIO_SQUARE}
            size={constants.LARGE}
          />

          <Box padding="m">
            {
              colors.map(value => (
                <Box key={value} display="flex" alignItems="center" marginBottom="m">
                  <ColorSwatch color={value} />
                  <Text marginLeft="m" type="display5">{value}</Text>
                </Box>
              ))
            }
          </Box>
        </Box>
      );
    }
  }
</ImagePaltte>
  `,
};

const Demo = {
  title: ImagePalette.displayName,
  description: 'The ImagePalette utility component extracts colors from an image for use in your UI.',
  docGen: docs,
  slug: 'ImagePalette',
  exports: Object.keys(ImagePaletteExports),
  examples: [
    basicExample,
  ],
  notes: `
    ### Usage

    **Component**

    The \`ImagePalette\` higher order component is a wrapper for the \`useImagePalette\` hook (see below).

    The hook will be called with the \`src\`, \`crossOrigin\` and \`format\` props.

    The provided \`children\` function will be called with the result of the hook.

    \`\`\`
    import ImagePalette from '@redbubble/design-system/react/ImagePalette';

    const MyComponent = () => {
      return (
        <ImagePalette src="https://tinyurl.com/color-example">
          {
            ({ colors, loading, error }) => {
              if (loading) return <Text>Loading...</Text>;
              if (error) return (
                <Alert intent={constants.ERROR}>Image failed to load</Alert>
              );

              return (
                <Box>
                  {
                    colors.map(color => <Box>{color}</Box>)
                  }
                </Box>
              );
            }
          }
        </ImagePalette>
      );
    }
    \`\`\`

    **Hook**

    The \`useImagePalette\` hook accepts a config object with the following properties:
    
    \`src\` - Image from which you'd like to extract the colors
    \`crossOrigin\` - for requesting images from other domains (default: \`true\`)
    \`format\` - the format you'd like the colors in (default: \`hex\`, one of: \`rgb\`, \`hex\`)

    The hook returns an object with the following properties:
    
    \`colors\` - an array of colors in the requested format
    \`loading\` - a boolean indicating whether the image is loading or not
    \`error\` - an error if any was encountered whilst attempting to extract colors

    \`\`\`
    import useImagePalette from '@redbubble/design-system/react/hooks/useImagePalette';

    const MyComponent = () => {
      const { colors, loading, error } = useImagePalette('https://tinyurl.com/color-example');

      if (loading) return <Text>Loading...</Text>;
      if (error) return (
        <Alert intent={constants.ERROR}>Image failed to load</Alert>
      );

      return (
        <Box>
          {
            colors.map(color => <Box>{color}</Box>)
          }
        </Box>
      );
    }
    \`\`\`

  `,
};

export default Demo;
