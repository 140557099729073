import React, { useState, useEffect, useRef } from 'react';
import posed from 'react-pose';
import cnames from 'classnames';
import Box from '../../../../react/Box';
import PageSection from '../../../../react/PageSection';
import Logo from '../../../../react/Logo';
import Button from '../../../../react/Button';
import Text from '../../../../react/Text';
import Tooltip from '../../../../react/Tooltip';
import SearchIcon from '../../../../react/Icons/Search';
import InteractiveBox from '../../InteractiveBox';
import RouteLink from '../../RouteLink';
import SearchInput from '../../SearchInput';
import styles from './DesktopHeader.css';

const Search = posed.nav({
  open: {
    y: 0,
    opacity: 1,
    transition: { duration: 200 },
  },
  closed: {
    y: 10,
    opacity: 0,
    transition: { duration: 100 },
  },
});

const DesktopHeader = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchRef = useRef(null);

  const searchTrigger = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const searchKey = 't';
  const escKey = 'Escape';

  function downHandler(e) {
    const { key, target } = e;
    if (key === searchKey) {
      if (['select', 'input', 'textarea'].includes(target.tagName)) return;
      setIsSearchOpen(true);
    }
    if (key === escKey) {
      setIsSearchOpen(false);
    }
  }

  useEffect(() => {
    // search input behaviour
    if (isSearchOpen) {
      searchRef.current.focus();
      searchRef.current.select();
    } else {
      searchRef.current.blur();
    }
  }, [isSearchOpen]);

  useEffect(() => {
    // attach click handler for search
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, []);

  return (
    <Box className={cnames(styles.header, isSearchOpen && styles.isSearchOpen)}>
      <Box
        className={styles.underlay}
        onClick={searchTrigger}
      />
      <Box className={styles.searchOuter}>
        <Search pose={isSearchOpen ? 'open' : 'closed'} initialPose="closed">
          <Box
            margin="auto"
            className={styles.searchInner}
          >
            <SearchInput forwardedRef={searchRef} searchTrigger={searchTrigger} />
          </Box>
        </Search>
      </Box>
      <PageSection padding="xxl">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <RouteLink>
            <InteractiveBox
              className={styles.logo}
              pill
              element="a"
              href="/"
              isAnimated={false}
              padding="xs"
              paddingRight="m"
            >
              <Logo colored={false} />
            </InteractiveBox>
          </RouteLink>
          <Box
            display="flex"
            alignItems="center"
            className={styles.nav}
          >
            <RouteLink><Button href="/brand">Brand</Button></RouteLink>
            <Box marginRight={0.5} />
            <RouteLink><Button href="/foundations">Foundations</Button></RouteLink>
            <Box marginRight={0.5} />
            <RouteLink><Button href="/components">Components</Button></RouteLink>
            <Box marginRight={0.5} />
            <RouteLink><Button href="/guides">Guides</Button></RouteLink>
            <Box marginRight={0.5} />
            <Tooltip
              trigger={triggerProps => (
                <Button
                  circle
                  iconAfter={<SearchIcon />}
                  onClick={searchTrigger}
                  size="large"
                  placement="bottom-right"
                  {...triggerProps}
                />
              )}
              accessibleTitle="Search"
            >
              Press <Text type="display6">T</Text> to Search
            </Tooltip>
          </Box>
        </Box>
      </PageSection>
    </Box>
  );
};

export default DesktopHeader;
