/* eslint-disable no-alert, no-console */
import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import * as constants from '../constants';
import Box from '../Box';
import ChevronDownBigIcon from '../Icons/ChevronDownBig';
import LockClosedIcon from '../Icons/LockClosed';
import TickIcon from '../Icons/Tick';
import CloseIcon from '../Icons/Close';
import ContextVerticalIcon from '../Icons/ContextVertical';

import Button from '../Button';

import * as DropdownExports from '.';

// eslint-disable-next-line
import DropdownSrc from '!raw-loader!.';

const { default: Dropdown, DropdownItem, DropdownSection, DropdownLink } = DropdownExports;
const docs = generateDocs(DropdownSrc, resolver.findAllExportedComponentDefinitions);

const basicExample = {
  name: 'Basic',
  description: 'A simple Dropdown with actions and links',
  overflowVisual: true,
  render: () => (
    <Box>
      <Dropdown
        trigger={(props, ref) => (
          <Button iconAfter={<ChevronDownBigIcon />} ref={ref} strong {...props}>
            Open
          </Button>
        )}
      >
        <DropdownSection title="ACTIONS">
          <DropdownItem onSelect={() => alert('Something')}>
            Alert something
          </DropdownItem>
          <DropdownItem onSelect={() => console.log('Something')}>
            Log something
          </DropdownItem>
        </DropdownSection>
        <DropdownSection title="NAVIGATION">
          <DropdownLink href="https://redbubble.design" target="_blank" title="Redbubble Design">
            Open a new window
          </DropdownLink>
          <DropdownLink href="#hash" onSelect={() => console.log('Something')}>
            Log something and add a hash param
          </DropdownLink>
        </DropdownSection>
      </Dropdown>
    </Box>
  ),
  code: () => `
<Dropdown
  trigger={(props, ref) => (
    <Button iconAfter={<ChevronDownBigIcon />} ref={ref} strong {...props}>
      Open
    </Button>
  )}
>
  <DropdownSection title="Actions">
    <DropdownItem onSelect={() => alert('Something')}>
      Alert something
    </DropdownItem>
    <DropdownItem onSelect={() => console.log('Something')}>
      Log something
    </DropdownItem>
  </DropdownSection>
  <DropdownSection title="Navigtation">
    <DropdownLink href="https://redbubble.design" target="_blank" title="Redbubble Design">
      Open a new window
    </DropdownLink>
    <DropdownLink href="#hash" onSelect={() => console.log('Something')}>
      Log something and add a hash param
    </DropdownLink>
  </DropdownSection>
</Dropdown>
  `,
};

const iconExample = {
  name: 'Icons',
  description: 'Add icons to Dropdown items to further communicate their intent.',
  overflowVisual: true,
  render: () => (
    <Box>
      <Dropdown
        trigger={(props, ref) => (
          <Button iconAfter={<ChevronDownBigIcon />} ref={ref} strong {...props}>
            Open
          </Button>
        )}
      >
        <DropdownSection title="Demo" hideTitle>
          <DropdownItem onSelect={() => alert('Approved')} iconBefore={<TickIcon />}>
            Approve
          </DropdownItem>
          <DropdownItem onSelect={() => alert('Rejected')} iconBefore={<CloseIcon />}>
            Reject
          </DropdownItem>
        </DropdownSection>
        <DropdownSection title="Demo" hideTitle>
          <DropdownLink
            href="https://redbubble.design"
            target="_blank"
            title="Redbubble Design"
            iconBefore={<LockClosedIcon />}
          >
            Mark as private
          </DropdownLink>
        </DropdownSection>
      </Dropdown>
    </Box>
  ),
  code: () => `
<Dropdown
  trigger={(props, ref) => (
    <Button iconAfter={<ChevronDownBigIcon />} ref={ref} strong {...props}>
      Open
    </Button>
  )}
>
  <DropdownSection title="Demo" hideTitle>
    <DropdownItem onSelect={() => alert('Approved')} iconBefore={<TickIcon />}>
      Approve
    </DropdownItem>
    <DropdownItem onSelect={() => alert('Rejected')} iconBefore={<CloseIcon />}>
      Reject
    </DropdownItem>
  </DropdownSection>
  <DropdownSection title="Demo" hideTitle>
    <DropdownLink
      href="https://redbubble.design"
      target="_blank"
      title="Redbubble Design"
      iconBefore={<LockClosedIcon />}
    >
      Mark as private
    </DropdownLink>
  </DropdownSection>
</Dropdown>
  `,
};

const placementExample = {
  name: 'Placement',
  description: 'You can use different placements depending on the alignment of your Dropdown.',
  overflowVisual: true,
  render: () => (
    <Box display="flex" justifyContent="space-between">
      <Dropdown
        placement={constants.BOTTOM_LEFT}
        trigger={(props, ref) => (
          <Button iconAfter={<ContextVerticalIcon />} ref={ref} circle strong {...props} />
        )}
      >
        <DropdownSection title="Demo" hideTitle>
          <DropdownLink href="">
            Item One
          </DropdownLink>
          <DropdownLink href="">
            Item Two
          </DropdownLink>
        </DropdownSection>
      </Dropdown>

      <Dropdown
        placement={constants.BOTTOM}
        trigger={(props, ref) => (
          <Button iconAfter={<ContextVerticalIcon />} ref={ref} circle strong {...props} />
        )}
      >
        <DropdownSection title="Demo" hideTitle>
          <DropdownLink href="">
            Item One
          </DropdownLink>
          <DropdownLink href="">
            Item Two
          </DropdownLink>
        </DropdownSection>
      </Dropdown>

      <Dropdown
        placement={constants.BOTTOM_RIGHT}
        trigger={(props, ref) => (
          <Button iconAfter={<ContextVerticalIcon />} ref={ref} circle strong {...props} />
        )}
      >
        <DropdownSection title="Demo" hideTitle>
          <DropdownLink href="">
            Item One
          </DropdownLink>
          <DropdownLink href="">
            Item Two
          </DropdownLink>
        </DropdownSection>
      </Dropdown>
    </Box>
  ),
  code: () => `
<Box display="flex" justifyContent="space-between">
  <Dropdown
    placement={constants.BOTTOM_LEFT}
    trigger={(props, ref) => (
      <Button iconAfter={<ContextVerticalIcon />} ref={ref} circle strong {...props} />
    )}
  >
    <DropdownSection title="Demo" hideTitle>
      <DropdownLink href="">
        Item One
      </DropdownLink>
      <DropdownLink href="">
        Item Two
      </DropdownLink>
    </DropdownSection>
  </Dropdown>

  <Dropdown
    placement={constants.BOTTOM}
    trigger={(props, ref) => (
      <Button iconAfter={<ContextVerticalIcon />} ref={ref} circle strong {...props} />
    )}
  >
    <DropdownSection title="Demo" hideTitle>
      <DropdownLink href="">
        Item One
      </DropdownLink>
      <DropdownLink href="">
        Item Two
      </DropdownLink>
    </DropdownSection>
  </Dropdown>

  <Dropdown
    placement={constants.BOTTOM_RIGHT}
    trigger={(props, ref) => (
      <Button iconAfter={<ContextVerticalIcon />} ref={ref} circle strong {...props} />
    )}
  >
    <DropdownSection title="Demo" hideTitle>
      <DropdownLink href="">
        Item One
      </DropdownLink>
      <DropdownLink href="">
        Item Two
      </DropdownLink>
    </DropdownSection>
  </Dropdown>
</Box>
  `,
};

const Demo = {
  title: Dropdown.displayName,
  description: 'Provide the user with additional navigation and/or actions in a contextual popover.',
  docGen: docs,
  slug: 'Dropdown',
  exports: Object.keys(DropdownExports),
  examples: [
    basicExample,
    iconExample,
    placementExample,
  ],
  notes: `
  `,
};

export default Demo;
