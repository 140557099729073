import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import useResponsive from '../../hooks/useResponsive';
import Box from '../../../react/Box';
import Avatar from '../../../react/Avatar';
import Text from '../../../react/Text';
import TextLink from '../../../react/TextLink';
import ExternalLinkIcon from '../../../react/Icons/ExternalLink';
import List, { ListItem } from '../../../react/List';
import * as constants from '../../../react/constants';

import Markdown from '../Markdown';
import styles from './Panel.css';

const PanelFooter = ({ artists, relatedContent }) => {
  if (!artists && !relatedContent) return null;

  return (
    <Box
      className={styles.contentFooter}
      marginTop="xxl"
      padding="xl"
    >
      {artists && (
        <>
          <Markdown
            source={`
## Featured Artists
Works from the following artists were featured on this page.
        `}
          />
          <Box marginTop="xl" display="flex">
            {artists.map(artist => (
              <Box
                className={styles.artistLink}
                element="a"
                target="_blank"
                rel="noopener nofollow"
                href={artist.shopUrl}
                marginRight="s"
                marginBottom="m"
                key={artist.name}
              >
                <Box
                  className={styles.artistLinkContent}
                  display="flex"
                  alignItems="center"
                  padding="m"
                >
                  <Avatar src={artist.avatar} bordered size={constants.SMALL} />
                  <Box paddingLeft="m" paddingRight="xl">
                    <Text display="block" type="display5">
                      {artist.name}
                    </Text>
                    <Box display="flex" alignItems="center">
                      <ExternalLinkIcon size={constants.SMALL} />
                      <Text type="body2" marginLeft="xxs">
                        Visit shop
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </>
      )}
      {relatedContent && (
        <>
          <Box marginTop="xl">
            <Markdown
              source={`
    ## Related
            `}
            />
            <List>
              {relatedContent.map(link => (
                <ListItem key={link.name}>
                  <TextLink href={link.link}>{link.name}</TextLink>
                </ListItem>
              ))}
            </List>
          </Box>
        </>
      )}
    </Box>
  );
};

const getPadding = (isPadded, isMobile) => {
  if (!isPadded) return undefined;

  return isMobile ? 1 : 2;
};

const Panel = ({ children, className, isPadded }) => {
  const responsive = useResponsive();

  return (
    <div
      className={
        cnames(className, styles.panel, { [styles.isPadded]: isPadded })
      }
    >
      <Box padding={getPadding(isPadded, responsive.mobile)}>{children}</Box>
    </div>
  );
};

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isPadded: PropTypes.bool,
};

Panel.defaultProps = {
  className: '',
  isPadded: true,
};

export { PanelFooter };
export default Panel;
