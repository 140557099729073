import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RemoveScroll } from 'react-remove-scroll';
import {
  Menu as ReachMenu,
  MenuButton as ReachMenuButton,
  MenuItems as ReachMenuItems,
  MenuPopover as ReachMenuPopover,
} from '@reach/menu-button';
import { usePopper } from 'react-popper';
import posed from 'react-pose';
import Box from '../Box';
import Card from '../Card';
import { MenuList as Menu } from '../Menu';
import styles from './styles.scss';
import * as constants from '../constants';
import useTracking, { trackingShape, EVENT_OPEN, EVENT_DISMISS } from '../hooks/useTracking';
import useEffectOnUpdate from '../hooks/useEffectOnUpdate';

const events = { EVENT_OPEN, EVENT_DISMISS };

const PLACEMENT_MAP = {
  [constants.BOTTOM_LEFT]: 'bottom-start',
  [constants.BOTTOM_RIGHT]: 'bottom-end',
};

const convertPlacement = (placement) => {
  if (Object.keys(PLACEMENT_MAP).includes(placement)) {
    return PLACEMENT_MAP[placement];
  }

  return placement;
};

const reducedMotion = typeof window !== 'undefined'
  && typeof window.matchMedia === 'function'
  && window.matchMedia('(prefers-reduced-motion)').matches;

const ease = [0.2, 1, 0.4, 1];

const Fade = posed.div({
  in: {
    opacity: 1,
    scale: 1,
    transition: {
      ease,
      duration: reducedMotion ? 0 : 350,
    },
  },
  out: {
    opacity: 0,
    scale: 0.9,
    transition: {
      ease,
      duration: reducedMotion ? 0 : 150,
    },
  },
});

/**
  * Dropdown
  *
  * A customizable trigger that opens a menu in a Dropdown
  */
const TriggerWrapper = React.forwardRef(({ trigger, ...props }, ref) => {
  return <React.Fragment>{ trigger(props, ref) }</React.Fragment>;
});

const IsOpenTracker = ({ track, open = null, updatePopper }) => {
  useEffectOnUpdate(() => {
    if (open === true) {
      updatePopper();
      track(events.EVENT_OPEN);
    } else if (open === false) {
      track(events.EVENT_DISMISS);
    }
  }, [open]);

  return <React.Fragment />;
};

const Dropdown = ({ children, trigger, placement, tracking }) => {
  const { track, TrackingContextProvider } = useTracking(tracking, 'Dropdown', events);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles: style, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: convertPlacement(placement),
    modifiers: [
      {
        name: 'flip',
        enabled: false,
        options: {
        },
      },
      {
        name: 'preventOverflow',
        options: {
          altAxis: true,
        },
      },
    ],
    strategy: 'fixed',
  });

  return (
    <TrackingContextProvider>
      <ReachMenu>
        {
          ({ isExpanded }) => (
            <React.Fragment>
              <IsOpenTracker
                track={track}
                open={isExpanded}
                updatePopper={update}
              />
              <ReachMenuButton
                as={TriggerWrapper}
                trigger={trigger}
                ref={setReferenceElement}
                style={style.reference}
              />

              <ReachMenuPopover
                ref={setPopperElement}
                className={styles.popover}
                style={style.popper}
                {...attributes.popper}
              >
                <Fade className={styles.animation} pose={isExpanded ? 'in' : 'out'} initialPose="out">
                  <Card elevation={constants.ELEVATION_MEDIUM}>
                    <RemoveScroll enabled={isExpanded} noIsolation>
                      <Box
                        paddingY="xxs"
                        className={styles.popoverScrollContainer}
                      >
                        <ReachMenuItems className={styles.menuItems}>
                          <Menu padded>
                            { children }
                          </Menu>
                        </ReachMenuItems>
                      </Box>
                    </RemoveScroll>
                  </Card>
                </Fade>
              </ReachMenuPopover>
            </React.Fragment>
          )
        }
      </ReachMenu>
    </TrackingContextProvider>
  );
};

Dropdown.displayName = 'Dropdown';

Dropdown.propTypes = {
  /**
    * A function that returns the node to be used as a trigger to toggle the Dropdown
    * Provides props to be spread onto the triggering element and a ref
    */
  trigger: PropTypes.func.isRequired,
  /**
    * The content of the Dropdown, DropdownSections containing one or more Dropdown(Item|Link)s
    */
  children: PropTypes.node.isRequired,
  /**
    * Where to place the Dropdown relative to it's trigger
    */
  placement: PropTypes.oneOf([
    constants.BOTTOM,
    constants.BOTTOM_LEFT,
    constants.BOTTOM_RIGHT,
  ]),
  /**
    * Tracking configuration for auto tracking
    */
  tracking: trackingShape,
};

Dropdown.defaultProps = {
  tracking: null,
  placement: constants.BOTTOM_LEFT,
};

export { default as DropdownItem } from './DropdownItem';
export { default as DropdownLink } from './DropdownLink';
export { default as DropdownSection } from './DropdownSection';
export default Dropdown;
