import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { defineMessages } from 'react-intl';
import Button from '../../../../../../Button';
import DSBox from '../../../../../../Box';
import { analyticsPayload } from '../../../../../lib/analytics';
import * as constants from '../../../../../../constants';
import CloseIcon from '../../../../../../Icons/Close';
import Pill from '../Pill';
import styles from './styles.scss';
import EngagingSearchPlaceholder from '../../../../../../EngagingSearchPlaceholder';
import { AnalyticsContextTypes, AnalyticsLocalStorageKeys, AnalyticsStorage } from '../../../utils';
import RainbowStarSearchIcon from '../../../../../../Icons/RainbowStarSearch';

const messages = defineMessages({
  clearSearchProductContextAccessibleLabel: 'Clear search product',
  searchTermPlaceholder: 'Hi! Describe what you are looking for.',
  searchTermLabel: 'Search term',
  clearSearchTermLabel: 'Clear search term',
  searchLabel: 'Search',
});

const BACKSPACE_KEY_CODE = 8;

// Check if valid placholder
function isValidPlaceholderMessages(messagesArray) {
  return isArray(messagesArray)
    && messagesArray.length > 2
    && messagesArray.every(m => typeof m === 'string');
}

class Box extends Component {
  constructor(props) {
    super(props);


    this.state = {
      focused: false,
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleContextClick = this.handleContextClick.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);

    this.searchRef = createRef();
  }

  static hasProductContext(productContext) {
    return productContext && productContext.id && productContext.label;
  }

  handleKeyDown(event) {
    const {
      productContext,
      inputProps: {
        onKeyDown,
        value,
      },
    } = this.props;

    if (
      !value
      && event.keyCode === BACKSPACE_KEY_CODE
      && Box.hasProductContext(productContext)
    ) {
      this.handleContextClick();
    }

    onKeyDown(event);
  }

  handleFocus(event) {
    const { inputProps: { onFocus } } = this.props;

    this.setState({ focused: true });
    onFocus(event);
  }

  handleBlur(event) {
    const { inputProps: { onBlur } } = this.props;

    this.setState({ focused: false });
    onBlur(event);
  }

  handleContextClick() {
    const { setProductContext } = this.props;
    setProductContext(null);
  }

  handleClearClick() {
    const { reset } = this.props;
    reset('');
  }

  handleSearchClick() {
    this.searchRef.current.blur();
    const {
      closeMenu,
      doSearch,
      inputProps: { value },
      productContext,
      logEvent,
      onSearchCallback,
    } = this.props;

    // GA4
    // This is required as useTypeahead doesnt provide an fn to run
    // searches manually and so if a user clicks the search button
    // this must be tracked seperately
    if (onSearchCallback) {
      onSearchCallback(value);
    } else {
      AnalyticsStorage.setValue(
        AnalyticsLocalStorageKeys.RefContextType,
        AnalyticsContextTypes.SearchBox,
      );
    }

    // GA3
    logEvent({
      analytics: analyticsPayload.typeaheadExperienceReturned({
        inputValue: value,
        productContextIaCode: get(productContext, 'id', null),
      }),
    });
    doSearch(value, productContext);
    closeMenu();
  }

  render() {
    const {
      intl,
      role,
      ariaExpanded,
      productContext,
      inputProps,
      isMenuOpen,
      engagingPlaceholderMessages,
      size,
    } = this.props;

    const { focused } = this.state;
    const hasProductContext = Box.hasProductContext(productContext);

    const formClassNames = cnames(
      styles.form,
      {
        [styles.focused]: focused,
        [styles.hasProductContext]: hasProductContext,
        [styles.small]: size === constants.SMALL,
      },
    );
    const engagingTextEnabled = isValidPlaceholderMessages(engagingPlaceholderMessages);

    const defaultPlaceholderText = intl.formatMessage(messages.searchTermPlaceholder);

    return (
      <DSBox className={formClassNames}>
        {
          hasProductContext && (
            <DSBox marginLeft={0.5}>
              <Pill
                label={productContext.label}
                accessibleLabel={
                  intl.formatMessage(messages.clearSearchProductContextAccessibleLabel)
                }
                onClick={this.handleContextClick}
                invert
              />
            </DSBox>
          )
        }
        <div className={styles.termWrapper}>
          {engagingTextEnabled && (
            <EngagingSearchPlaceholder
              placeholderTexts={engagingPlaceholderMessages}
              pauseTextAnimation={isMenuOpen}
              hidden={!!inputProps.value}
            />
          )}
          <input
            className={cnames(styles.term, { [styles.hiddenPlaceholder]: engagingTextEnabled })}
            type="search"
            name="query"
            data-testid="ds-typeahead-input-desktop"
            placeholder={defaultPlaceholderText}
            role={role}
            aria-label={intl.formatMessage(messages.searchTermLabel)}
            aria-expanded={ariaExpanded}
            aria-autocomplete={inputProps['aria-autocomplete']}
            aria-controls={inputProps['aria-controls']}
            aria-labelledby={inputProps['aria-labelledby']}
            autoComplete={inputProps.autoComplete}
            id={inputProps.id}
            ref={inputProps.innerRef}
            value={inputProps.value}
            onChange={inputProps.onChange}
            onKeyDown={this.handleKeyDown}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
          />

        </div>
        {!!inputProps.value && isMenuOpen && (
          <DSBox className={styles.clear}>
            <Button
              aria-label={intl.formatMessage(messages.clearSearchTermLabel)}
              size={constants.SMALL}
              circle
              onClick={this.handleClearClick}
              innerRef={this.clearRef}
            >
              <CloseIcon />
            </Button>
          </DSBox>
        )}

        <DSBox marginRight={0.5}>
          <Button
            circle
            type="submit"
            aria-label={intl.formatMessage(messages.searchTermLabel)}
            onClick={this.handleSearchClick}
            innerRef={this.searchRef}
            data-testid="ds-typeahead-search-button"
          >
            <RainbowStarSearchIcon size={constants.LARGE} />
          </Button>
        </DSBox>
      </DSBox>
    );
  }
}

Box.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  productContext: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  }),
  role: PropTypes.string,
  ariaExpanded: PropTypes.bool,
  reset: PropTypes.func.isRequired,
  setProductContext: PropTypes.func.isRequired,
  engagingPlaceholderMessages: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.oneOf([
    constants.SMALL,
    constants.MEDIUM,
    constants.LARGE,
  ]),
};

Box.defaultProps = {
  productContext: null,
  role: null,
  ariaExpanded: false,
  engagingPlaceholderMessages: null,
};

export default Box;
