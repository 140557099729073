import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';

const FieldSet = props => (
  <fieldset {...props} className={styles.fieldSet} />
);

FieldSet.displayName = 'FieldSet';

FieldSet.propTypes = {
  /**
    * What to render inside the FieldSet
    */
  children: PropTypes.node.isRequired,
};

export default FieldSet;
