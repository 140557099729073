import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import omit from 'lodash/omit';
import Box from '../Box';
import { LEFT, RIGHT, CENTER } from '../constants';
import _styles from './styles.scss';

const validDisplayTypes = ['block', 'inline-block', 'inline'];

const sizes = [
  0,
  0.25,
  0.5,
  0.75,
  1,
  1.25,
  1.5,
  1.75,
  2,
  'none',
  'xxxs',
  'xxs',
  'xs',
  's',
  'm',
  'l',
  'xl',
  'xxl',
  'xxxl',
];

const Text = (props) => {
  const {
    // eslint-disable-next-line max-len
    children, type, loading, muted, className, styles, element, block, lineClamp: textLineClamp, // Text props
    // eslint-disable-next-line max-len
    margin, marginTop, marginLeft, marginRight, marginBottom, marginX, marginY, display, textAlign, // Box props
    ...rest
  } = props;

  // validate lineClamp
  // handle it being a string (prop types warning)
  let lineClamp = parseInt(textLineClamp, 10);
  if (lineClamp < 0) {
    // eslint-disable-next-line no-console
    console.warn('LineClamp cannot have a negative value, the absolute value will be used instead.');
    lineClamp = Math.abs(lineClamp);
  }

  const isLineWrap = lineClamp && lineClamp === 1;
  const isLineClamp = !isLineWrap && !!lineClamp;

  // filter out Box props we don't need
  const remainingTextProps = omit(rest, Box.propNames);

  // handle applicable display types
  let displayType = (
    // override any passed in display prop if lineClamp is present
    (lineClamp && (lineClamp > 1 ? '-webkit-box' : 'block'))
    || (block && 'block')
    || display
  );
  if (!validDisplayTypes.includes(displayType)) displayType = undefined;

  const classNames = cnames(styles.text, {
    [styles[type]]: !!type,
    [styles.muted]: muted,
    [styles.lineClamp]: isLineClamp,
    [styles.nowrap]: isLineWrap,
    [className]: className,
  });

  const sharedProps = {
    display: displayType,
    className: classNames,
    element,
    margin,
    marginTop,
    marginLeft,
    marginRight,
    marginBottom,
    marginX,
    marginY,
    textAlign,
    ...remainingTextProps,
  };

  // Handle lineClamp, if it's 1 line, use nowrap instead
  const clampStyle = isLineClamp ? { WebkitLineClamp: lineClamp } : undefined;

  if (!loading) {
    return (
      <Box
        {...sharedProps}
        style={clampStyle}
      >
        { children }
      </Box>
    );
  }

  const { dangerouslySetInnerHTML, ...loadingRest } = sharedProps;

  return (
    <Box
      {...loadingRest}
      style={clampStyle}
    >
      <span
        className={styles.loading}
        {...({ dangerouslySetInnerHTML, children })}
      />
    </Box>
  );
};

Text.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  element: PropTypes.string,
  block: PropTypes.bool,
  loading: PropTypes.bool,
  muted: PropTypes.bool,
  type: PropTypes.string,
  margin: PropTypes.oneOf(sizes),
  marginX: PropTypes.oneOf(sizes),
  marginY: PropTypes.oneOf(sizes),
  marginTop: PropTypes.oneOf(sizes),
  marginRight: PropTypes.oneOf(sizes),
  marginBottom: PropTypes.oneOf(sizes),
  marginLeft: PropTypes.oneOf(sizes),
  display: PropTypes.oneOf(validDisplayTypes),
  textAlign: PropTypes.oneOf([LEFT, RIGHT, CENTER]),
  styles: PropTypes.shape({}),
  lineClamp: PropTypes.number,
};

Text.defaultProps = {
  className: '',
  element: 'span',
  type: 'body',
  loading: false,
  muted: false,
  styles: _styles,
};

Text.displayName = 'Text';

export default Text;
