import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import base64 from 'base64-url';
import styles from './Example.css';
import useResponsive from '../../hooks/useResponsive';
import useToggle from '../../hooks/useToggle';
import Code from '../Code';
import Markdown from '../Markdown';
import Text from '../../../react/Text';
import Card from '../../../react/Card';
import Box from '../../../react/Box';
import Button from '../../../react/Button';
import * as constants from '../../../react/constants';
import Form, { FieldSet, FieldRow, Field } from '../../../react/Form';

// eslint-disable-next-line react/prop-types
const ConfigForm = ({ config = {}, children }) => (
  <Form initialValues={config.initialValues} profile={config.profile}>
    {
      ({ values }) => (
        <React.Fragment>
          {
            config && Object.keys(config).length > 0 && (
              <div className={styles.exampleConfig}>
                <FieldSet>
                  <FieldRow>
                    {
                      config.fields.map(field => (
                        <Field {...field} key={field.name} />
                      ))
                    }
                  </FieldRow>
                </FieldSet>
              </div>
            )
          }
          { children(values) }
        </React.Fragment>
      )
    }
  </Form>
);

const getCode = (code, responsive, values) => {
  return typeof code === 'function' ? code(responsive, values) : code;
};

const Example = (props) => {
  const responsive = useResponsive();
  const [fullScreen, toggleFullScreen] = useToggle(false);
  const [showCode, toggleShowCode] = useToggle(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTop = 0;
    }
  }, [fullScreen]);

  const {
    render,
    name,
    description = '',
    callout,
    code,
    config,
    className,
    muted,
    carded,
    dark,
    allowFullscreen,
    overflowVisual,
    disableLiveEdit,
  } = props;

  const exampleClassName = cnames(styles.example, className, {
    [styles.isFullScreen]: fullScreen,
  });

  const hasHTMLInDescription = description.includes('<') && description.includes('>');

  return (
    <IntlProvider locale="en">
      <div className={exampleClassName} id={name.toLowerCase().replace(/ /g, '-')}>
        <Text type="display3" display="block" className={styles.name}>{name}</Text>

        {/* eslint-disable react/no-danger */}
        {
          description && hasHTMLInDescription && (
            <Text
              display="block"
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )
        }

        {
          description && !hasHTMLInDescription && (
            <Markdown className={styles.description} source={description.trim()} />
          )
        }
        { callout }
        <div className={styles.exampleBody}>
          <ConfigForm config={typeof config === 'function' ? config(responsive) : config}>
            {
              values => (
                <React.Fragment>
                  <Box
                    className={
                      cnames(
                        styles.visual,
                        {
                          [styles.overflowVisual]: overflowVisual,
                          [styles.muted]: muted,
                          [styles.dark]: dark,
                        },
                      )
                    }
                  >
                    <Card transparent={!carded} overflowHidden={false}>
                      <Box padding={carded ? 1 : 0}>
                        <Box className={styles.visualContainer}>
                          { render(responsive, values) }
                        </Box>
                      </Box>
                    </Card>
                  </Box>
                  <div
                    className={
                      cnames(styles.codeToolbar, {
                        [styles.codeOpen]: showCode,
                      })
                    }
                  >
                    <Box marginLeft="xs">
                      <Button
                        onClick={toggleShowCode}
                        size={constants.SMALL}
                      >
                        Code
                      </Button>
                    </Box>
                    <Box marginLeft="xs">
                      {
                        allowFullscreen && (
                          <Button
                            onClick={toggleFullScreen}
                            size={constants.SMALL}
                          >
                            Fullscreen
                          </Button>
                        )
                      }
                    </Box>
                    <Box marginLeft="xs">
                      { code && !disableLiveEdit && (
                        <Button
                          target="_blank"
                          rel="noopener noferrer"
                          href={`https://play.redbubble.design/#?code=${base64.encode(getCode(code, responsive, values).trim())}`}
                          size={constants.SMALL}
                        >
                          Playroom
                        </Button>
                      )}
                    </Box>
                  </div>
                  <Code name={name} open={showCode} roundedCorners={false}>
                    { getCode(code, responsive, values) }
                  </Code>
                </React.Fragment>
              )
            }
          </ConfigForm>
        </div>
      </div>
    </IntlProvider>
  );
};

Example.propTypes = {
  render: PropTypes.func,
  name: PropTypes.string,
  description: PropTypes.string,
  config: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  muted: PropTypes.bool,
  dark: PropTypes.bool,
  allowFullscreen: PropTypes.bool,
  overflowVisual: PropTypes.bool,
  disableLiveEdit: PropTypes.bool,
};

Example.defaultProps = {
  render: null,
  name: '',
  description: '',
  config: {},
  code: '',
  className: '',
  muted: false,
  dark: false,
  allowFullscreen: true,
  overflowVisual: false,
  disableLiveEdit: false,
};

export default Example;
