import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import Overlay from './ModalOverlay';
import ModalCard from './ModalCard';
import styles from './styles.scss';
import * as constants from '../constants';
import useTracking, { trackingShape } from '../hooks/useTracking';

const Modal = ({ children, size, tracking, ...props }) => {
  const { track, TrackingContextProvider } = useTracking(tracking, 'Modal');

  return (
    <TrackingContextProvider>
      <Overlay
        {...props}
        delayClose={0}
        track={track}
      >
        {
          args => (
            <div className={cnames(styles.modal, styles[size])}>
              <ModalCard>
                { typeof children === 'function' ? children(args) : children }
              </ModalCard>
            </div>
          )
        }
      </Overlay>
    </TrackingContextProvider>
  );
};

/* eslint-disable react/require-default-props */
Modal.propTypes = {
  /**
    * The content of the Modal, typically one or more ModalBody
    */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),

  /**
    * The width of the Modal container
    */
  size: PropTypes.oneOf([constants.SMALL, constants.MEDIUM, constants.LARGE]),
  /**
    * Called when the user dismisses the modal
    */
  onCloseRequested: PropTypes.func,
  /**
    * A title for screen readers to provide context about the Modal's contents / purpose.
    */
  accessibleTitle: PropTypes.string,
  /**
    * Use instead of trigger to control the Modal with external state
    */
  open: PropTypes.bool,
  /**
    * A render function. Provides an onClick handler so you can build a
    * trigger UI that will open the Modal when clicked.
    */
  trigger: PropTypes.func,
  /**
    * Configuration for auto tracking
    */
  tracking: trackingShape,
  /**
   * Allows clicks outside of the active Modal, which is disabled by default
   */
  allowClicksOutsideWhenActive: PropTypes.bool,
};
/* eslint-enable react/require-default-props */

Modal.defaultProps = {
  children: null,
  size: constants.SMALL,
};

Modal.displayName = 'Modal';

export default Modal;
