/* eslint-disable */
import React, { useState, useEffect } from 'react';
import cnames from 'classnames';
import reduceCSSCalc from 'reduce-css-calc';
import Color from 'color';
import Box from '../../../../react/Box';
import Label from '../../../../react/Label';
import Text from '../../../../react/Text';
import * as types from '../../../../react/themes/tokenTypes';
import { resolveTokenValue, getColorDetails } from '../../../../react/themes/utils';
import styles from './TokenReference.css';

// Determine if a color is light enough to need a dark background
const isLight = hexColor => Color(hexColor).luminosity() > 0.4;

// A simple table of details about a token's values
const Details = ({ values }) => {
  const sanitizedValues = values.filter(Boolean);
  if (!sanitizedValues.length) return null;

  return (
    sanitizedValues.map(v => (
      <Box display="flex" alignItems="center" className={styles.colorDetails}>
        <Text type="body2">{ v }</Text>
      </Box>
    ))
  );
};

// Renders a Preview thumbnail
const Preview = ({ details, children }) => (
  <Box>
    { children }
  </Box>
);

// Different components for rendering token previews based on type -> semantic type
const previews = {
  [types.FONT_FAMILY]: ({ name, resolvedValue }) => (
    <Preview details={[resolvedValue]}>
      <Box className={styles.previewFont} style={{ fontFamily: resolvedValue }}>Aa</Box>
    </Preview>
  ),

  [types.FONT_SIZE]: ({ name, resolvedValue }) => (
    <Preview details={[resolvedValue]}>
      <Box className={styles.previewFont} style={{ fontSize: resolvedValue }}>Aa</Box>
    </Preview>
  ),

  [types.FONT_WEIGHT]: ({ name, resolvedValue }) => (
    <Preview details={[resolvedValue]}>
      <Box className={styles.previewFont} style={{ fontWeight: resolvedValue }}>Aa</Box>
    </Preview>
  ),

  [types.LINE_HEIGHT]: ({ name, resolvedValue }) => (
    <Preview details={[resolvedValue]}>
      <Box className={styles.previewFont} style={{ lineHeight: resolvedValue }}>
        The quick brown fox jumps over the lazy dog
      </Box>
    </Preview>
  ),

  [types.SHADOW]: ({ name, resolvedValue }) => (
    <Preview details={[resolvedValue]}>
      <Box className={styles.previewShadow} style={{ boxShadow: resolvedValue }} />
    </Preview>
  ),

  [types.RADIUS]: ({ name, value, resolvedValue }) => (
    <Preview details={[resolvedValue]}>
      <Box className={styles.previewShape} style={{ borderRadius: value }} />
    </Preview>
  ),

  [types.SIZE]: {
    [types.DIMENSION]: ({ name, value, resolvedValue }) => (
      <Preview details={[resolvedValue]}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box style={{
            height: resolvedValue,
            width: resolvedValue,
            backgroundColor: 'var(--ds-color-information-300)',
          }}/>
        </Box>
      </Preview>
    ),
    [types.BORDER_WIDTH]: ({ name, resolvedValue }) => (
      <Preview details={[resolvedValue]}>
        <Box style={{ width: 50, height: 50, border: `${resolvedValue} solid var(--ds-color-border)` }} />
      </Preview>
    ),
  },

  [types.SPACING]: ({ name, value, resolvedValue}) => (
    <Preview details={[resolvedValue]}>
      <Box display="flex" justifyContent="center" style={{ width: '170px' }}>
        <Box className={styles.previewSpacing} />
        <Box className={styles.previewSpacingSpacer} style={{ width: value }} />
        <Box className={styles.previewSpacing} />
      </Box>
    </Preview>
  ),

  [types.COLOR]: {
    [types.STATE]: ({ name, resolvedValue }) => {
      const color = getColorDetails(resolvedValue);

      return (
        <Preview details={[color.hex, color.rgb]}>
          <Box className={styles.previewShape} style={{ background: resolvedValue }} />
        </Preview>
      );
    },

    [types.BACKGROUND]: ({ name, resolvedValue }) => {
      const color = getColorDetails(resolvedValue);

      return (
        <Preview details={[color.hex, color.rgb]}>
          <Box className={styles.previewShape} style={{ background: resolvedValue }} />
        </Preview>
      );
    },

    [types.BORDER]: ({ name, resolvedValue }) => {
      const color = getColorDetails(resolvedValue);

      return (
        <Preview details={[color.hex, color.rgb]}>
          <Box style={{ width: 50, height: 50, border: '2px solid', borderColor: resolvedValue }} />
        </Preview>
      );
    },

    [types.TEXT]: ({ name, value, resolvedValue }) => {
      const color = getColorDetails(resolvedValue);

      return (
        <Preview details={[color.hex, color.rgb]}>
          <Box padding={0.5} className={styles.previewTextColor} style={{ color: value, background: isLight(resolvedValue) ? 'var(--ds-color-background-app-dark)' : 'transparent' }}>
            <Text display="block" type="display3">Aa</Text>
            <Text display="block">Aa</Text>
          </Box>
        </Preview>
      );
    },

    [types.PRIMARY]: ({ name, value, resolvedValue }) => {
      const color = getColorDetails(resolvedValue);

      return (
        <Preview details={[color.hex, color.rgb]}>
          <Box className={styles.previewColor} style={{ background: value }} />
        </Preview>
      );
    },

    [types.COLOR]: ({ name, value, resolvedValue }) => {
      const color = getColorDetails(resolvedValue);

      return (
        <Preview details={[color.hex, color.rgb]}>
          <Box className={styles.previewColor} style={{ background: value }} />
        </Preview>
      );
    },

    [types.INTENT]: ({ name, value, resolvedValue }) => {
      const color = getColorDetails(resolvedValue);

      return (
        <Preview details={[color.hex, color.rgb]}>
          <Box className={styles.previewColor} style={{ background: value }} />
        </Preview>
      );
    },

    [types.COMMON]: ({ name, value, resolvedValue }) => {
      const color = getColorDetails(resolvedValue);

      return (
        <Preview details={[color.hex, color.rgb]}>
          <Box className={styles.previewColor} style={{ background: value }} />
        </Preview>
      );
    },

    [types.BRAND]: ({ name, value, resolvedValue }) => {
      const color = getColorDetails(resolvedValue);

      return (
        <Preview details={[color.hex, color.rgb]}>
          <Box className={styles.previewColor} style={{ background: value }} />
        </Preview>
      );
    },

    [types.NEUTRAL]: ({ name, value, resolvedValue }) => {
      const color = getColorDetails(resolvedValue);

      return (
        <Preview details={[color.hex, color.rgb]}>
          <Box className={styles.previewColor} style={{ background: value }} />
        </Preview>
      );
    },

    [types.EXPRESSIVE]: ({ name, value, resolvedValue }) => {
      const color = getColorDetails(resolvedValue);

      return (
        <Preview details={[color.hex, color.rgb]}>
          <Box className={styles.previewColor} style={{ background: value }} />
        </Preview>
      );
    },
  },

};

// Determine which preview component to use
// for a given token based on it's type and value
//
// Additionally, resolve the value to a computed style
const makePreview = (name, value, type, semantic) => {
  const props = { name, value, resolvedValue: resolveTokenValue(name) };

  if (type && semantic) {
    if (previews[type] && previews[type][semantic]) {
      return React.createElement(previews[type][semantic], props)
    }
  }

  if (type) {
    if (previews[type]) {
      return React.createElement(previews[type], props)
    }
  }

  return null;
};

const TokenReferenceTable = ({ tokens, type, semantic }) => (
  <Box marginBottom={2} paddingBottom={1}>
    {
      Object.entries(tokens).map(([name, data]) => {
        const resolvedValue = resolveTokenValue(name)
        const color = data.type === 'COLOR' && getColorDetails(resolvedValue)
        // console.log(data)
        return (
          <Box
            display="flex"
            padding={0.5}
            marginBottom={0.25}
            key={name}
            className={styles.row}
            alignItems="center"
          >
            <Box className={styles.preview} padding={0.25}>
              { makePreview(name, data.value, type, semantic) }
            </Box>
            <Box
              display="flex"
              alignItems="flex-start"
              flex="1"
            >
              <Box marginRight={1}>
                <Text type="display5" display="block">{name}</Text>
                { data.type === 'COLOR' && <Box display="flex" marginBottom={0.5}><Details values={[color.hex, color.rgb]} /></Box> }
                {
                  typeof data !== 'string' && (
                    <Text display="block" type="body2" muted>{data.description}</Text>
                  )
                }
              </Box>
            </Box>
          </Box>
        )
      })
    }
  </Box>
);

export default TokenReferenceTable;
