import React from 'react';
import cnames from 'classnames';
import PropTypes from 'prop-types';
import List, { ListItem } from '../../../../List';
import Image from '../../../../Image';
import Box from '../../../../Box';
import _styles from './themes/default.scss';
import amazonpayIcon from '../assets/paymentMethods/amazonpayLight.svg';
import americanExpressIcon from '../assets/paymentMethods/americanexpressLight.svg';
import applepayIcon from '../assets/paymentMethods/applepayLight.svg';
import mastercardIcon from '../assets/paymentMethods/mastercardLight.svg';
import paypalIcon from '../assets/paymentMethods/paypalLight.svg';
import sofortIcon from '../assets/paymentMethods/sofortLight.svg';
import visaIcon from '../assets/paymentMethods/visaLight.svg';
import afterpayIcon from '../assets/paymentMethods/afterpay_badge.svg';
import clearpayIcon from '../assets/paymentMethods/clearpay_badge.svg';

import {
  availablePaymentMethods,
  PaymentMethods,
} from '../utils';

export { PaymentMethods };

const EARLY_LOAD_LAZY_ELEMENTS_BY_PIXELS = 1600;

const paymentMethodIcons = {
  [PaymentMethods.AfterPay]: afterpayIcon,
  [PaymentMethods.AmazonPay]: amazonpayIcon,
  [PaymentMethods.AmericanExpress]: americanExpressIcon,
  [PaymentMethods.ApplePay]: applepayIcon,
  [PaymentMethods.Clearpay]: clearpayIcon,
  [PaymentMethods.Mastercard]: mastercardIcon,
  [PaymentMethods.PayPal]: paypalIcon,
  [PaymentMethods.Sofort]: sofortIcon,
  [PaymentMethods.Visa]: visaIcon,
};


const FooterPayments = ({ styles, parentStyles, intl, paymentMethods }) => (
  <Box className={styles.footerBottom} padding="m">
    <div className={styles.footerContainer}>
      <List
        relaxed
        className={cnames(
          styles.listHorizontal,
          parentStyles.listHorizontal,
          styles.payments,
          parentStyles.payments,
        )}
      >
        {
        availablePaymentMethods(intl, paymentMethods).map(item => (
          <ListItem key={item.text} className={cnames(styles.item)}>
            <Image
              className={cnames(styles.icon)}
              src={paymentMethodIcons[item.icon]}
              alt={item.text}
              loadOnVisible={
                { offsetTopBoundaryByPx: EARLY_LOAD_LAZY_ELEMENTS_BY_PIXELS }}
            />
          </ListItem>
        ))
      }
      </List>
    </div>
  </Box>
);

FooterPayments.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  styles: PropTypes.shape({}),
  parentStyles: PropTypes.shape({}).isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.strings).isRequired,
};

FooterPayments.defaultProps = {
  styles: _styles,
};

FooterPayments.displayName = 'FooterPayments';

export default FooterPayments;
