import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import * as constants from '../constants';
import useTracking, { trackingShape, EVENT_CLICK } from '../hooks/useTracking';
import styles from './styles.scss';

export const events = { EVENT_CLICK };

const {
  PRIMARY,
  NEUTRAL,
} = constants;

const TextLink = (props) => {
  const {
    children,
    href,
    block,
    external,
    intent,
    subtle,
    iconBefore,
    iconAfter,
    className,
    tracking,
    style,
    ...rest
  } = props;

  // const trackingConfig = tracking || onlyText(children);
  const { track } = useTracking(tracking, 'TextLink');

  const classnames = cnames(styles.link, {
    [styles.subtle]: subtle,
    [styles.block]: block,
    [styles.primary]: intent === PRIMARY,
  });

  const linkProps = {
    className: classnames,
    target: external ? '_blank' : undefined,
    rel: external ? 'noopener noreferrer' : undefined,
    ...rest,
    onClick: (e) => {
      track(EVENT_CLICK);
      if (typeof rest.onClick === 'function') {
        rest.onClick(e);
      }
    },
  };

  if (typeof children === 'function') {
    return children(linkProps);
  }

  return (
    <a {...linkProps} href={href}>
      {
        (iconBefore || iconAfter) && (
          <React.Fragment>
            { iconBefore && <span className={styles.iconBefore}>{ iconBefore }</span> }
            { children && <span className={styles.children}>{ children }</span> }
            { iconAfter && <span className={styles.iconAfter}>{ iconAfter }</span> }
          </React.Fragment>
        )
      }
      { (!iconBefore && !iconAfter) && <span className={styles.children}>{children}</span> }
    </a>
  );
};

TextLink.propTypes = {
  /**
    * The text content of the TextLink
    */
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  /**
    * The destination page / location to link to
    */
  href: PropTypes.string,
  /**
    * Whether or not to open this link securely in a new window
    */
  external: PropTypes.bool,
  /**
    * Changes the appearance of the TextLink based on it's semantic purpose.
    */
  intent: PropTypes.oneOf([
    PRIMARY,
    NEUTRAL,
  ]),
  /**
    * An <Icon /> to show on the right of the button
    */
  iconAfter: PropTypes.node,
  /**
    * An <Icon /> to show on the left of the button
    */
  iconBefore: PropTypes.node,
  /**
    * A subtle link will stand out less than a regular link
    */
  subtle: PropTypes.bool,
  /**
    * Display the link as a block-level element
    */
  block: PropTypes.bool,
  /**
    * Tracking configuration for auto tracking
    */
  tracking: trackingShape,
};

TextLink.defaultProps = {
  href: null,
  external: false,
  intent: NEUTRAL,
  iconBefore: null,
  iconAfter: null,
  subtle: false,
  tracking: null,
  block: false,
};

TextLink.displayName = 'TextLink';

export default TextLink;
