import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import Box from '../Box';
import StarIcon from '../Icons/Star';
import Text from '../Text';
import Button from '../Button';
import * as constants from '../constants';

import * as TooltipExports from '.';

// eslint-disable-next-line
import TooltipSrc from '!raw-loader!.';

const { default: Tooltip } = TooltipExports;
const docs = generateDocs(TooltipSrc, resolver.findAllExportedComponentDefinitions);

const basicExample = {
  name: 'Basic',
  description: 'Use Tooltips to clarify the purpose of or provide additional information about UI elements such as icon-only actions.',
  overflowVisual: true,
  render: () => (
    <Box>
      <Tooltip
        placement={constants.TOP}
        trigger={triggerProps => (
          <Button
            {...triggerProps}
            iconBefore={<StarIcon />}
            circle
          />
        )}
        accessibleTitle="Rate this artwork"
      >
        Rate this artwork
      </Tooltip>

      <Box marginBottom="xl" />

      <Text type="display5" display="block">Example Heading</Text>
      <Text element="p" display="block">
        This is some copy displayed within an{' '}
        <Tooltip
          accessibleTitle="Definition of HTML"
          trigger={triggerProps => (
            <dfn
              style={{
                textDecoration: 'underline',
                textDecorationStyle: 'dotted',
              }}
              {...triggerProps}
            >
              HTML
            </dfn>
          )}
        >
          HyperText Markup Language
        </Tooltip>
        {' '}tag.
      </Text>
    </Box>
  ),
  code: `
<Box>
  <Tooltip
    placement={constants.TOP}
    trigger={ triggerProps => (
      <Button {...triggerProps}>Hover me</Button>
    )}
    accessibleTitle="Rate this artwork"
  >
    Rate this artwork
  </Tooltip>

  <Box marginBottom="xl" />

  <Text type="display5" display="block">Example Heading</Text>
  <Text element="p" display="block">
    This is some copy displayed within an{' '}
    <Tooltip
      accessibleTitle="Definition of HTML"
      trigger={ triggerProps => (
        <dfn
          {...triggerProps}
        >
          HTML
        </dfn>
      )}
    >
      HyperText Markup Language
    </Tooltip>
    {' '}tag.
  </Text>
</Box>
  `,
};

const placementExample = {
  name: 'Placement',
  description: 'A Tooltip can be placed in different directions relative to the wrapped element.',
  overflowVisual: true,
  config: {
    initialValues: {
      placement: constants.BOTTOM,
    },
    fields: [
      {
        label: 'Placement',
        name: 'placement',
        type: 'select',
        options: [
          { value: constants.TOP, label: 'Top' },
          { value: constants.TOP_RIGHT, label: 'Top Right' },
          { value: constants.TOP_LEFT, label: 'Top Left' },
          { value: constants.BOTTOM, label: 'Bottom' },
          { value: constants.BOTTOM_RIGHT, label: 'Bottom Right' },
          { value: constants.BOTTOM_LEFT, label: 'Bottom Left' },
          { value: constants.LEFT, label: 'Left' },
          { value: constants.RIGHT, label: 'Right' },
        ],
      },
    ],
  },
  render: (_, values) => (
    <Box style={{ width: '100%', padding: '120px 80px' }} display="flex" justifyContent="center">
      <Tooltip
        placement={values.placement}
        trigger={triggerProps => (
          <Box {...triggerProps}>
            <Text>
              Hover to trigger from { values.placement }
            </Text>
          </Box>
        )}
        accessibleTitle="A Tooltip demo"
      >
        Appears at { values.placement }
      </Tooltip>
    </Box>
  ),
  code: (_, values) => `
<Box style={{ width: '100%', padding: '120px 80px' }} display="flex" justifyContent="center">
  <Tooltip
    placement={constants.${(values.placement).toUpperCase()}}
    trigger={triggerProps => (
      <Box {...triggerProps}>
        <Text>
          Hover to trigger from ${values.placement}
        </Text>
      </Box>
    )}
    accessibleTitle="A Tooltip demo"
  >
    Appears at ${values.placement}
  </Tooltip>
</Box>
  `,
};

const Demo = {
  title: 'Tooltip',
  description: 'Tooltips are used to show terse, low-priority information about user interface elements on hover / focus',
  docGen: docs,
  slug: 'Tooltip',
  exports: Object.keys(TooltipExports),
  examples: [
    basicExample,
    placementExample,
  ],
  notes: `
  `,
};

export default Demo;
