import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import styles from './styles.scss';
import CardBody from './CardBody';
import CardHeader from './CardHeader';
import List from '../List';
import Image from '../Image';
import childTypeEquals from '../utils/childTypeEquals';
import useTracking, { trackingShape, EVENT_CLICK } from '../hooks/useTracking';
import * as constants from '../constants';

export const events = { EVENT_CLICK };

const CARD_CHILDREN = [
  CardBody.displayName,
  CardHeader.displayName,
];

export const LOW = 'LOW';
export const MEDIUM = 'MEDIUM';
export const HIGH = 'HIGH';

const Card = React.forwardRef(({
  className,
  overflowHidden,
  onClick,
  children,
  elevation,
  roundedCorners,
  fluid,
  transparent,
  tracking,
  style,
  ...rest
}, ref) => {
  const { track, TrackingContextProvider } = useTracking(tracking, 'Card');

  const classNames = cnames(styles.card, className, {
    [styles.overflowHidden]: overflowHidden,
    [styles.rounded]: roundedCorners,
    [styles.fluid]: fluid,
    [styles.transparent]: transparent,
    [styles.elevationLow]: elevation === LOW || elevation === constants.ELEVATION_LOW,
    [styles.elevationMedium]: elevation === MEDIUM || elevation === constants.ELEVATION_MEDIUM,
    [styles.elevationHigh]: elevation === HIGH || elevation === constants.ELEVATION_HIGH,
    [styles.clickable]: onClick,
  });

  const onClickProps = onClick ? {
    onClick: (e) => {
      track(EVENT_CLICK);
      onClick(e);
    },
    role: 'button',
    tabIndex: '0',
  } : {};

  return (
    <TrackingContextProvider>
      <div {...rest} className={classNames} {...onClickProps} ref={ref}>
        {
          React.Children.map(children, (child) => {
            if (childTypeEquals(child, CARD_CHILDREN)) {
              return React.cloneElement(child, { padded: !transparent, parentStyles: styles });
            }

            if (childTypeEquals(child, Image.displayName)) {
              return React.cloneElement(child, { roundedCorners: transparent && roundedCorners });
            }

            if (childTypeEquals(child, List.displayName)) {
              return React.cloneElement(child, { padded: true });
            }

            if (typeof child === 'string') {
              return <CardBody parentStyles={styles}>{ child }</CardBody>;
            }

            return child;
          })
        }
      </div>
    </TrackingContextProvider>
  );
});

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  overflowHidden: PropTypes.bool,
  onClick: PropTypes.func,
  elevation: PropTypes.oneOf([
    LOW,
    MEDIUM,
    HIGH,
    constants.ELEVATION_LOW,
    constants.ELEVATION_MEDIUM,
    constants.ELEVATION_HIGH,
  ]),
  roundedCorners: PropTypes.bool,
  fluid: PropTypes.bool,
  transparent: PropTypes.bool,
  /**
    * Tracking configuration for auto tracking
    */
  tracking: trackingShape,
};

Card.defaultProps = {
  className: '',
  overflowHidden: true,
  elevation: LOW,
  roundedCorners: true,
  fluid: false,
  transparent: false,
  onClick: null,
  tracking: null,
};

Card.displayName = 'Card';

export {
  CardBody,
  CardHeader,
};

export default Card;
