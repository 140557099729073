import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import styles from './EngagingSearchPlaceholder.scss';

// time in milliseconds for the duration each message should be shown for
// this is here because we want to avoid different consumers of DS
// from overriding this value and providing an inconsistent experience
const ANIMATION_DELAY = 6000;

/**
 * This function checks if a client has prefers reduced motion active
 * @returns boolean
 */
export function queryReducedMotion() {
  return typeof window !== 'undefined'
    && typeof window.matchMedia === 'function'
    && window.matchMedia('(prefers-reduced-motion)').matches;
}

const useTypedText = (placeholderTexts, pauseTextAnimation) => {
  const [placeholderTextIndex, setPlaceholderTextIndex] = useState(0);
  const typedTextPhrase = placeholderTexts[placeholderTextIndex % placeholderTexts.length];

  useEffect(() => {
    let timeoutHandle = null;
    const reduceMotion = queryReducedMotion();

    if (!reduceMotion && !pauseTextAnimation) {
      timeoutHandle = setTimeout(() => {
        setPlaceholderTextIndex(v => v + 1);
      }, ANIMATION_DELAY);
    }
    return () => timeoutHandle && clearTimeout(timeoutHandle);
  }, [placeholderTextIndex, pauseTextAnimation]);

  return typedTextPhrase;
};

const EngagingSearchPlaceholder = ({
  placeholderTexts,
  pauseTextAnimation,
  hidden,
}) => {
  const reduceMotion = queryReducedMotion();
  const typedText = useTypedText([...placeholderTexts], pauseTextAnimation);

  const containerClasses = cnames(
    styles.scrollingTextContainer,
    { [styles.scrollingTextHidden]: hidden },
  );

  return (
    <div className={containerClasses} aria-hidden="true" data-testid="engaging-search-placeholder">
      {!pauseTextAnimation && (
        <p
          data-testid="typed-text"
          key={typedText}
          className={cnames(!reduceMotion ? styles.typedTextAnimation : '', styles.scrollingTextItem)}
        >
          {typedText}
        </p>
      )}
    </div>
  );
};

EngagingSearchPlaceholder.propTypes = {
  placeholderTexts: PropTypes.arrayOf(PropTypes.string).isRequired,
  pauseTextAnimation: PropTypes.bool.isRequired,
  hidden: PropTypes.bool,
};

EngagingSearchPlaceholder.defaultProps = {
  hidden: false,
};

export default EngagingSearchPlaceholder;
