import React, { useState } from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import _styles from './styles.scss';
import Box from '../Box';
import useTracking, { trackingShape, EVENT_DISMISS, EVENT_OPEN } from '../hooks/useTracking';

const AnimatedContents = posed.div({
  closed: {
    height: 0,
    opacity: 0,
    transition: ({ delay }) => ({ delay }),
    applyAtEnd: { display: 'none' },
  },
  open: {
    height: 'auto',
    opacity: 1,
    transition: ({ delay }) => ({ delay }),
    applyAtStart: { display: 'block' },
  },
});

AnimatedContents.displayName = 'AnimatedContents';

const StaticContents = ({ children, collapsed }) => (
  <Box display={collapsed ? 'none' : 'block'}>
    { children }
  </Box>
);

StaticContents.displayName = 'StaticContents';

StaticContents.propTypes = {
  children: PropTypes.node.isRequired,
  collapsed: PropTypes.bool,
};

StaticContents.defaultProps = {
  collapsed: true,
};

const Collapsible = ({
  defaultCollapsed,
  children,
  trigger,
  styles,
  animated,
  animationDelay,
  tracking,
}) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);
  const { track, TrackingContextProvider } = useTracking(tracking, 'Collapsible');

  const userPrefersReducedMotion = typeof window !== 'undefined'
    && typeof window.matchMedia === 'function'
    && window.matchMedia('(prefers-reduced-motion)').matches;

  const animationDisabled = !animated || userPrefersReducedMotion;

  return (
    <TrackingContextProvider>
      <div className={styles.collapsible}>
        {
          trigger({
            collapsed,
            toggleCollapsible: () => {
              if (collapsed) track(EVENT_OPEN);
              if (!collapsed) track(EVENT_DISMISS);
              setCollapsed(prevCollapsed => !prevCollapsed);
            },
          })
        }
        {
          animationDisabled ? (
            <StaticContents collapsed={collapsed}>
              { typeof children === 'function' ? children(collapsed) : children }
            </StaticContents>
          ) : (
            <AnimatedContents
              pose={collapsed ? 'closed' : 'open'}
              withParent={false}
              delay={animationDelay}
              className={styles.animatedContents}
            >
              { typeof children === 'function' ? children({ collapsed }) : children }
            </AnimatedContents>
          )
        }
      </div>
    </TrackingContextProvider>
  );
};

Collapsible.displayName = 'Collapsible';

Collapsible.propTypes = {
  /**
    * The contents of the collapsible that can be hidden or displayed
    */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  /**
    * The initial collapsed state of the Collapsible. If false, it'll render expanded initially.
    */
  defaultCollapsed: PropTypes.bool,
  /**
    * A function to render to the trigger. It is given `collapsed` (boolean) and
    `toogleCollapsible` (function) as render props.
    */
  trigger: PropTypes.func.isRequired,
  /**
    * The CSS classNames for this component. NEVER manually specify this prop
    */
  styles: PropTypes.shape({}),
  /**
    * Disables animation
    */
  animated: PropTypes.bool,
  /**
    * Applies animation delay
    */
  animationDelay: PropTypes.number,
  /**
    * Tracking configuration for auto tracking
    */
  tracking: trackingShape,
};

Collapsible.defaultProps = {
  styles: _styles,
  defaultCollapsed: true,
  animated: true,
  animationDelay: 0,
  tracking: null,
};

export default Collapsible;
