import React from 'react';
import Icon from '../Icon';
import styles from './styles/rainbowIconStyle.scss';

const RainbowStarSearchIcon = (props) => {
  return (
    <>
      <span>
        <Icon {...props} dangerouslySetIcon={'<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="m12.07664,4.61794l0.00204,0l0,0.00089c1.75843,0.00051 3.35069,0.71375 4.50293,1.86624c1.15033,1.15084 1.86293,2.7417 1.86407,4.49873l0.00089,0l0,0.00421l0,0.0051l-0.00089,0c-0.00064,0.70814 -0.11686,1.38964 -0.33056,2.02515c-0.03581,0.10666 -0.07327,0.20912 -0.11176,0.30698l0,0.00089c-0.18427,0.4664 -0.42244,0.90731 -0.70686,1.31344l3.70597,3.33094l0.00229,0.00204l0.02001,0.01861l0.0014,0.0014c0.20924,0.19918 0.32317,0.46589 0.33757,0.73656c0.01402,0.26761 -0.0692,0.54133 -0.25219,0.7609l-0.0014,0.00204l-0.0223,0.02587l-0.00446,0.00446l-0.01861,0.02039l-0.00204,0.00268c-0.19943,0.20924 -0.46564,0.32291 -0.73656,0.33718c-0.26722,0.01414 -0.5412,-0.06881 -0.7609,-0.25206l-0.00191,-0.0014l-0.026,-0.0223l-0.0037,-0.00306l-3.79046,-3.40676c-0.11214,0.07901 -0.22657,0.15407 -0.34241,0.22492c-0.15712,0.09621 -0.31985,0.18682 -0.4859,0.26952c-0.85367,0.42588 -1.81833,0.66545 -2.83816,0.66545l0,0.00089l-0.00204,0l0,-0.00089c-1.75843,-0.00051 -3.35095,-0.71375 -4.50319,-1.86624c-1.15071,-1.15084 -1.86293,-2.74195 -1.86407,-4.49847l-0.00089,0l0,-0.00421l0,-0.00204l0.00089,0c0.00051,-1.75843 0.71375,-3.35095 1.86624,-4.50319c1.15084,-1.1502 2.7417,-1.8628 4.49873,-1.86395l0,-0.00089l0.00421,0l0,0l0.00002,-0.00002zm0.00204,1.42851l0,0.00089l-0.00204,0l-0.00421,0l0,-0.00089c-1.36174,0.00089 -2.59604,0.55433 -3.48959,1.4475c-0.89342,0.89317 -1.44737,2.12875 -1.44775,3.49214l0.00089,0l0,0.00204l0,0.00421l-0.00089,0c0.00089,1.36174 0.55395,2.5954 1.4475,3.48921c0.89317,0.89355 2.12875,1.4475 3.49201,1.44775l0,-0.00089l0.00204,0l0.00421,0l0,0.00089c1.36174,-0.00089 2.59553,-0.55407 3.48934,-1.4475c0.89342,-0.89317 1.44737,-2.12837 1.44763,-3.49163l-0.00076,0l0,-0.00204l0,-0.00421l0.00076,0c-0.00076,-1.36174 -0.55433,-2.59604 -1.44737,-3.48959c-0.89317,-0.89355 -2.12837,-1.4475 -3.49176,-1.44788l0,0l-0.00001,0z" id="search"/></svg>'} />
      </span>
      <span className={styles.star1}>
        <Icon size={props.size} dangerouslySetIcon={'<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="rotate(11.643 7.2142 3.12134)"><path fill="currentColor" d="m10.16127,3.12134c-1.62819,0 -2.94706,-1.26035 -2.94706,-2.81407c0,1.55372 -1.31991,2.81407 -2.94706,2.81407c1.62715,0 2.94706,1.2593 2.94706,2.81407c0,-1.55478 1.31887,-2.81407 2.94706,-2.81407z" id="star1"/></g></svg>'} />
      </span>
      <span className={styles.star2}>
        <Icon size={props.size} dangerouslySetIcon={'<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="rotate(-8.8729 6.09134 19.01)" ><path fill="currentColor" d="m10.19869,19.01001c-2.26922,0 -4.10735,-1.83957 -4.10735,-4.10735c0,2.26778 -1.83957,4.10735 -4.10735,4.10735c2.26778,0 4.10735,1.83804 4.10735,4.10735c0,-2.26931 1.83813,-4.10735 4.10735,-4.10735z" id="star2"/></g></svg>'} />
      </span>
      <span className={styles.star3}>
        <Icon size={props.size} dangerouslySetIcon={'<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="rotate(3.91831 18.491 5.68025)"><path fill="currentColor" d="m20.42043,5.68025c-1.06598,0 -1.92944,-0.86415 -1.92944,-1.92944c0,1.0653 -0.86415,1.92944 -1.92944,1.92944c1.0653,0 1.92944,0.86343 1.92944,1.92944c0,-1.06602 0.86347,-1.92944 1.92944,-1.92944z" id="star3"/></g></svg>'} />
      </span>
    </>
  );
};

RainbowStarSearchIcon.displayName = 'RainbowStarSearchIcon';

export default RainbowStarSearchIcon;
