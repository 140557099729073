import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import cnames from 'classnames';
import { defineMessages } from 'react-intl';
import { RemoveScroll } from 'react-remove-scroll';
import Input from '../Box/Input';
import Menu from '../Menu';
import Box from '../../../../../Box';
import rainbowStyle from '../Box/rainbowStyle.scss';
import useTypeahead from '../../hooks/useTypeahead';
import { MOBILE, SMALL } from '../../../../../constants';
import CloseBig from '../../../../../Icons/CloseBig';
import Button from '../../../../../Button';
import ArtistsSection from '../ArtistsSection';
import StaticLinks from '../StaticLinks';
import { analyticsPayload } from '../../../../lib/analytics';
import styles from './styles.scss';

const messages = defineMessages({
  closeButton: 'Close search',
});

const TypeaheadMobileWithSearchBar = ({
  locale = 'en',
  baseUrl,
  intl,
  logEvent: defaultLogEvent,
  term = '',
  doSearch: providedDoSearch,
  onSearchOpen,
  onSearchClose,
  disableEventLogging,
  searchProductContext,
  engagingPlaceholderMessages,
  useRainbowStyle,
  onTypeaheadResultClick,
  onSearchCallback,
}) => {
  const logEvent = disableEventLogging ? () => { } : defaultLogEvent;
  const defaultProductContextIaCode = get(searchProductContext, 'id', null);
  const defaultProductContextLabel = get(searchProductContext, 'label', null);

  const {
    items: completions,
    inputProps,
    getComboboxProps,
    setInputValue,
    setProductContext,
    isOpen,
    closeMenu,
    doSearch,
    searchTerm,
    productContextLabel,
    productContextIaCode,
    types,
    prevTerm,
    getItemProps,
    getMenuProps,
    highlightedIndex,
    searchWords,
    logView,
    popularProducts,
    quickLinks,
    otherLinks,
    topArtists,
    topArtistsLoading,
    logItemClicked,
    currentInputValue,
  } = useTypeahead({
    term,
    locale,
    defaultProductContextIaCode,
    defaultProductContextLabel,
    logEvent,
    intl,
    providedDoSearch,
    onSearchClose,
    onSearchOpen,
    initialIsOpen: false,
    profile: MOBILE,
    baseUrl,
    blurInputOnClose: true,
    onSearchCallback,
  });

  const [buttonAnimating, setButtonAnimating] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setButtonAnimating(true);
    }
  }, [isOpen]);

  const searchBoxClass = cnames(styles.searchBox, {
    [rainbowStyle.searchBoxRainbow]: useRainbowStyle && !isOpen && !buttonAnimating,
  });

  const wrapperClassNames = cnames(
    styles.inputWrapper,
    {
      [rainbowStyle.inputWrapperRainbow]: useRainbowStyle,
    },
  );

  const searchSuggestionsListClassName = cnames(styles.searchSuggestionsList, {
    [styles.open]: completions.length,
    [styles.visuallyHidden]: !isOpen,
  });

  const comboboxProps = getComboboxProps({ refKey: 'innerRef' });

  return (
    <Box className={searchBoxClass} data-testid="ds-search-bar-with-typeahead">
      <Box flexDirection="row" justifyContent="space-between">

        {/* Search bar */}
        <Box display="flex" flexDirection="row" alignItems="center" className={styles.searchBox}>
          <Box className={wrapperClassNames} {...comboboxProps}>
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Input
                role="combobox"
                ariaExpanded={!!isOpen}
                intl={intl}
                productContext={{ id: productContextIaCode, label: productContextLabel }}
                inputProps={inputProps}
                reset={setInputValue}
                engagingPlaceholderMessages={engagingPlaceholderMessages}
                setProductContext={setProductContext}
                doSearch={doSearch}
                isMenuOpen={isOpen}
                closeMenu={closeMenu}
                logEvent={logEvent}
                size={SMALL}
                onSearchCallback={onSearchCallback}
              />
            </form>
          </Box>

          {/* Close button */}
          <Box
            id="close-button"
            className={isOpen ? styles.searchCloseButtonActive : styles.searchCloseButtonHidden}
          >
            <Button
              circle
              onClick={() => {
                logEvent({
                  analytics: analyticsPayload.typeaheadExperienceClosed({
                    inputValue: { currentInputValue },
                    productContextIaCode,
                  }),
                });
                /** For when useRainbowStyle is true, need to delay the rainbow effect when
                * search bar is closed. Otherwise close button has rainbow background because
                * searchBoxRainbow class gets assigned
                */
                closeMenu();
                setTimeout(() => {
                  setButtonAnimating(false);
                }, 200);
              }}
              iconBefore={<CloseBig />}
              aria-label={intl.formatMessage(messages.closeButton)}
              data-testid="ds-header-search-bar-close"
            />
          </Box>
        </Box>
      </Box>

      {/* Search suggestions and completions List */}
      <RemoveScroll enabled={isOpen}>
        <Box className={searchSuggestionsListClassName} data-testid="ds-search-bar-suggestions-list">
          <Box
            display="flex"
            flexDirection="column"
          >

            {/* Trending searches */}
            <Menu
              {...({
                isOpen,
                types,
                prevTerm,
                getItemProps,
                getMenuProps,
                highlightedIndex,
                searchWords,
                searchTerm,
                intl,
                withProductContext: productContextIaCode,
                logView,
                logItemClicked,
                closeMenu,
                onTouchStart: () => {
                  document.activeElement.blur();
                },
                onTypeaheadResultClick,
              })}
            />

            {!searchTerm && (
              <Box paddingX="m">
                {/* Top artists */}
                <Box marginBottom="xl">
                  <ArtistsSection
                    artists={topArtists}
                    loading={topArtistsLoading}
                    intl={intl}
                    baseUrl={baseUrl}
                    slidesPerView="auto"
                    profile={MOBILE}
                  />
                </Box>

                {/* Popular products and quick links */}
                <StaticLinks
                  data={{
                    popularProducts,
                    quickLinks,
                    otherLinks,
                  }}
                  profile={MOBILE}
                />
              </Box>
            )}
          </Box>
        </Box>
      </RemoveScroll>
    </Box>
  );
};

export default TypeaheadMobileWithSearchBar;
