import React from 'react';
import PropTypes from 'prop-types';
import styles from '../Field/styles.scss';

const FieldRow = props => (
  <div {...props} className={styles.fieldRow} />
);

FieldRow.displayName = 'FieldRow';

FieldRow.propTypes = {
  /**
    * What to render inside the FieldRow
    */
  children: PropTypes.node.isRequired,
};

FieldRow.defaultProps = {
};

export default FieldRow;
