import React from 'react';
import Markdown from '../../../components/Markdown';
import Box from '../../../../react/Box';

const Accessibility = () => {
  return (
    <Box>
      <Markdown
        source={`

      # Accessibility

      #### Making the web more accessible benefits not only individuals, but also our business and society as a whole. And at Redbubble we value inclusivity and diversity, so we are committed to building products that can be used by everyone.

      ### Content
      1. [Overview](#overview)
      2. [Principles](#principles)
      3. [Components](#components)

      ## Overview

      To make the web more accessible there are international standards that help define and guide what is needed for experiences to be accessible. We follow the World Wide Web Consortium's (W3C's) Web Content Accessibility Guidelines (WCAG) when designing and building out our experiences.

      ### Standards

      [WCAG 2.1](https://www.w3.org/WAI/standards-guidelines/wcag/glance/)${'  '}
      [Conformance AA](https://www.w3.org/WAI/WCAG21/Understanding/conformance)


      ## Principles

      Below are a few take aways from the W3C guidelines that does not only help users with disabilities, but that make the experience better for everyone. They are not a complete list of requirements. But they give a good idea of the foundations necessary for building accessible products.

      ### Perceivable

      Information and user interface components must be presentable to users in ways they can perceive. This means that users must be able to perceive the information being presented (it can't be invisible to all of their senses).

      ##### Examples
      - Provide text alternatives for any non-text elements like images, icons and buttons.
      - Provide alternatives for time-based media.
      - Create content that can be presented in similar ways without losing information or structure.
      - Separate foreground from background.

      [WCAG Perceivable Principle](https://www.w3.org/WAI/fundamentals/accessibility-principles/#perceivable)

      ### Operable

      User interface components and navigation must be operable. This means that users must be able to operate the interface (the interface cannot require interaction that a user cannot perform).

      ##### Examples
      - Make functionality available with a keyboard.
      - Give enough time to read and understand the content.
      - Do not create animations that is known to cause physical reactions/seizures.
      - Provide ways to help users navigate, find content and determine where they are.

      [WCAG Operable Principle](https://www.w3.org/WAI/fundamentals/accessibility-principles/#operable)

      ### Understandable

      Information and the operation of user interface must be understandable. This means that users must be able to understand the information as well as the operation of the user interface (the content or operation cannot be beyond their understanding).

      ##### Examples
      - Make text content easy to understand by using clear and descriptive copy.
      - Make content and navigation appear and operate in predictable ways.
      - Help users avoid and correct mistakes with input assistance.

      [WCAG Understandable Principle](https://www.w3.org/WAI/fundamentals/accessibility-principles/#understandable)

      ### Robust

      Content must be robust enough that it can be interpreted reliably by a wide variety of user agents, including assistive technologies. This means that users must be able to access the content as technologies advance (as technologies and user agents evolve, the content should remain accessible).

      ##### Examples
      - Maximise compatibility with current and future user agents, including assistive technologies.

      [WCAG Robust Principle](https://www.w3.org/WAI/fundamentals/accessibility-principles/#robust)

      ### References

      For a full list of these principles and guidelines please do familiarise yourself with the resources within the [W3C Accessibility Fundamentals](https://www.w3.org/WAI/fundamentals/).

      ## Components

      Our Design System components strive to support and encourage accessibility best practices. Below are a few common examples for how to use our components in an accessible way.

      ### Box

      Our [Box](/components/Box/examples) component is practically just a div. And as such, it takes the same accessible tags as a native \`div\` element. This is important to descrive different regions of the page like main, navigation, header, footer etc.

      \`\`\`
      <Box element="header" />
      <Box element="main" />
      <Box element="footer" />
      \`\`\`

      [WCAG Page Region Guidelines](https://www.w3.org/WAI/tutorials/page-structure/regions/)

      ### Text

      Our [Text](/components/Text/examples) component takes an \`element\` prop that can be used for semantically structuring content.

      \`\`\`
      <Text element="h1" />
      <Text element="h2" />
      <Text element="h3" />
      <Text element="p" />
      \`\`\`

      [WCAG Descriptive Heading Guidelines](https://www.w3.org/WAI/tutorials/page-structure/headings/)

      ### Image

      Our [Image](/components/Image/examples) component accepts the same accessible attributes as the native \`<img />\` element. You generally want to add alt tags to images to describe their content. But in some cases you might want to hide the image instead with aria labels if the image is purely decorative.

      \`\`\`
      <Image alt="Cats playing" />
      \`\`\`

      [WCAG Accessible Image Guidelines](https://www.w3.org/WAI/tutorials/images/)

      ### Button

      Our [Button](/components/Button/examples) component accepts the same accessible attributes as the native \`<button />\` element. This is important especially when a button only has an icon in it and you want to add a descriptive aria label.

      \`\`\`
      <Button iconBefore={<AddToCartIcon />} circle aria-label="Add to cart" />
      \`\`\`

      ### Tooltip

      Our [Tooltip](/components/Tooltip/examples) component accepts an \`accessibleTitle\` prop that should be used to describe the tooltip.

      \`\`\`
      <Tooltip
        trigger={triggerProps => (
          <Box {...triggerProps}>
            <StarRating outOf={5} rating={3} />
          </Box>
        )}
        accessibleTitle="Rating 3 of 5"
      >
        3 out of 5
      </Tooltip>
      \`\`\`

      ### Popover

      Our [Popover](/components/Popover/examples) component accepts an \`accessibleTitle\` prop that should be used to describe the popover.

      \`\`\`
      <Modal
        trigger={({ onClick }) => <Button>Edit</Button>}
        accessibleTitle="Change address"
      >
        Modal content
      </Modal>
      \`\`\`


      ### Modal

      Our [Modal](/components/Modal/examples) component accepts an \`accessibleTitle\` prop that should be used to describe the modal.

      \`\`\`
      <Popover
        trigger={({ onClick }) => <Button>Edit</Button>}
        accessibleTitle="Change address"
      >
        Popover content
      </Popover>
      \`\`\`
      `}
      />
    </Box>
  );
};

export default Accessibility;
