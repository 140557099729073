import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Text from '../Text';
import Box from '../Box';
import StarRating from '../StarRating';

const Review = ({
  rating,
  username,
  title,
  date,
  children,
}) => {
  return (
    <React.Fragment>
      {
        typeof rating === 'number' && rating >= 0 && rating <= 5 && (
          <Box marginBottom="xs">
            <StarRating rating={rating} />
          </Box>
        )
      }
      <Text display="block" type="display5">{title}</Text>
      {
        date ? (
          <FormattedMessage
            defaultMessage="by {username} on {date}"
            values={{
              username,
              date,
            }}
          >
            {
              text => (
                <Text display="block" muted>{text}</Text>
              )
            }
          </FormattedMessage>
        ) : (
          <FormattedMessage
            defaultMessage="by {username}"
            values={{ username }}
          >
            {
              text => (
                <Text display="block" muted>{text}</Text>
              )
            }
          </FormattedMessage>
        )
      }
      <Box marginTop="m">
        <Text>
          { children }
        </Text>
      </Box>
    </React.Fragment>
  );
};

Review.propTypes = {
  /**
    * Review score, whole integer out of 5
    */
  rating: PropTypes.number,
  /**
    * The username of the person who wrote the review
    */
  username: PropTypes.string,
  /**
    * A formatted, localized string indicating the date the review was made on
    */
  date: PropTypes.string,
  /**
    *  The body content of the review
    */
  children: PropTypes.node.isRequired,
  /**
    *  The short title of the review
    */
  title: PropTypes.string.isRequired,
};

Review.defaultProps = {
  rating: null,
  username: 'Anon',
  date: '',
};

Review.displayName = 'Review';

export default Review;
