import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import sizes from './sizes';
import * as constants from '../constants';
import OnUpdate from './OnUpdate';
import FormDeviceProfileContext from './FormDeviceProfileContext';

const { MOBILE, DESKTOP } = sizes;

const Form = (props) => {
  const { children, onChange, profile, formId, ...rest } = props;

  return (
    <FormDeviceProfileContext.Provider value={profile}>
      <Formik
        {...rest}
        render={({ handleSubmit, ...formikProps }) => {
          const data = {
            values: formikProps.values,
            touched: formikProps.touched,
            isSubmitting: formikProps.isSubmitting,
            submitCount: formikProps.submitCount,
            initialValues: formikProps.initialValues,
            errors: formikProps.errors,
            status: formikProps.status,
          };

          return (
            <form onSubmit={handleSubmit} id={formId}>
              <OnUpdate form={data} onChange={onChange} />
              {
                children({
                  ...data,
                  resetForm: formikProps.resetForm,
                })
              }
            </form>
          );
        }}
      />
    </FormDeviceProfileContext.Provider>
  );
};

Form.displayName = 'Form';

Form.propTypes = {
  /**
    *  A function, called with the current form state that should return the
    *  contents of the form, typically FieldSets, Fields etc.
    */
  children: PropTypes.func.isRequired,
  /**
    * A function called with the current form state whenever a value changes
    */
  onChange: PropTypes.func,
  /**
    * A function called with the form state when the form is submitted
    */
  onSubmit: PropTypes.func,
  /**
    * What kind of device to optimize for
    */
  profile: PropTypes.oneOf([MOBILE, DESKTOP, constants.MOBILE, constants.DESKTOP]),

  /**
   * Set the underlying form's ID attribute to this string, if one is supplied
   */
  formId: PropTypes.string,
};

Form.defaultProps = {
  onChange: () => {},
  onSubmit: () => {},
  profile: constants.MOBILE,
  formId: undefined,
};

export default Form;
export { MOBILE, DESKTOP };
export { FieldArray } from 'formik';
export { default as FieldSet } from './FieldSet';
export { default as FieldRow } from './FieldRow';
export { default as Field } from './Field';
export { default as FieldMessage } from './FieldMessage';
