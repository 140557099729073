import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import Button from '../Button';
import Box from '../Box';
import Card from '../Card';
import Text from '../Text';
import * as constants from '../constants';
import * as StackExports from '.';

// eslint-disable-next-line
import StackSrc from '!raw-loader!.';

const { default: Stack } = StackExports;
const docs = generateDocs(StackSrc, resolver.findAllExportedComponentDefinitions);

const basicExample = {
  name: 'Basic',
  description: 'Stacks are layout components to help you evenly space the elements on the page.',
  overflowVisual: true,
  render: () => (
    <Stack
      spacing="m"
    >
      <Card>
        <Box padding="m">Content</Box>
      </Card>
      <Card>
        <Box padding="m">Content</Box>
      </Card>
      <Card>
        <Box padding="m">Content</Box>
      </Card>
    </Stack>
  ),
  code: () => `
<Stack
  spacing="m"
>
  <Card>
    <Box padding="m">Content</Box>
  </Card>
  <Card>
    <Box padding="m">Content</Box>
  </Card>
  <Card>
    <Box padding="m">Content</Box>
  </Card>
</Stack>
  `,
};

const richExample = {
  name: 'Rich',
  description: 'You can use stacks to evenly space out various elements like `Text` and `Button`',
  overflowVisual: true,
  render: () => (
    <Card>
      <Stack
        padding="xl"
        spacing="m"
      >
        <Text type="caption" muted>SUBTITLE</Text>
        <Text type="display2">Title</Text>
        <Text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Quisque vel eros nec ante posuere ultricies.
          Nam pellentesque sed ipsum sed fermentum.
          Quisque aliquet enim dolor, id consequat mi molestie vel.
        </Text>
        <Button size="large" strong>Submit</Button>
      </Stack>
    </Card>
  ),
  code: () => `
<Card>
  <Stack
    padding="xl"
    spacing="m"
  >
    <Text type="caption" muted>SUBTITLE</Text>
    <Text type="display2">Title</Text>
    <Text>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      Quisque vel eros nec ante posuere ultricies.
      Nam pellentesque sed ipsum sed fermentum.
      Quisque aliquet enim dolor, id consequat mi molestie vel.
    </Text>
    <Button strong>Submit</Button>
  </Stack>
</Card>
  `,
};


const horizontalExample = {
  name: 'Horizontal',
  description: 'You can use `direction={constants.HORIZONTAL}` for spacing elements out horizontally when necessary',
  overflowVisual: true,
  render: () => (
    <Stack
      spacing="m"
      direction={constants.HORIZONTAL}
    >
      <Button intent={constants.PRIMARY} strong>Submit</Button>
      <Button strong>Learn more</Button>
    </Stack>
  ),
  code: () => `
<Stack
  spacing="m"
  direction={constants.HORIZONTAL}
>
  <Button intent={constants.PRIMARY} strong>Submit</Button>
  <Button strong>Learn more</Button>
</Stack>
  `,
};


const Demo = {
  title: Stack.displayName,
  description: 'Easily space out elements with Stack',
  docGen: docs,
  slug: 'Stack',
  exports: Object.keys(StackExports),
  examples: [
    basicExample,
    richExample,
    horizontalExample,
  ],
  notes: `
  `,
};

export default Demo;
