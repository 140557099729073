import get from 'lodash/get';
import PropTypes from 'prop-types';

// baseUrl = https://www.redbubble.com (no trailing slash)
// locale = 'en' or 'de'
const appendLocaleToUrl = (baseUrl, locale) => {
  const localeSuffix = locale === 'en' ? '' : `/${locale}`;
  return `${baseUrl}${localeSuffix}`;
};

const sortItems = (a, b) => ((a.position > b.position) ? 1 : -1);

const isNavigationItemActive = (rootItem) => {
  const urlWithoutSearch = url => url.split('?').shift();
  const rootItemUrl = get(rootItem, 'action.parameters.url');
  let isActive = false;

  if (typeof window !== 'undefined' && rootItemUrl) {
    const currentUrl = get(window, 'location.href', '');

    isActive = currentUrl.includes(urlWithoutSearch(rootItemUrl))
      || (
        Array.isArray(rootItem.items)
          && rootItem.items.length
          && rootItem.items
            .some(item => currentUrl.includes(urlWithoutSearch(get(item, 'action.parameters.url'))))
      );
  }

  return isActive;
};

const itemImagePropShape = PropTypes.shape({
  url: PropTypes.string,
  set: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      descriptior: PropTypes.string,
    }),
  ),
});

const ACTION_TYPE_ABSOLUTE_NAME = 'absolute';

const ACTION_TYPE_RELATIVE_NAME = 'relative';

const itemPropShape = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  position: PropTypes.number,
  color: PropTypes.string,
  icon: itemImagePropShape,
  banner: itemImagePropShape,
  action: PropTypes.shape({
    parameters: PropTypes.shape({
      urlType: PropTypes.oneOf(
        [ACTION_TYPE_ABSOLUTE_NAME, ACTION_TYPE_RELATIVE_NAME],
      ),
      url: PropTypes.string,
    }),
  }),
  items: PropTypes.array,
});

const itemsPropShape = PropTypes.arrayOf(
  itemPropShape,
);

itemsPropShape.items = PropTypes.arrayOf(itemsPropShape);

const handleMouseDown = (url, logEvent) => {
  if (url && typeof logEvent === 'function') {
    logEvent({
      analytics: {
        category: 'Global Nav',
        action: 'Click',
        label: 'global-nav-menu',
        transport: 'beacon',
        dimension29: url,
      },
    });
  }
};

export {
  appendLocaleToUrl,
  sortItems,
  isNavigationItemActive,
  itemImagePropShape,
  itemPropShape,
  itemsPropShape,
  handleMouseDown,
  ACTION_TYPE_ABSOLUTE_NAME,
  ACTION_TYPE_RELATIVE_NAME,
};
