import React from 'react';
import cnames from 'classnames';
import Text from '../../../react/Text';
import Box from '../../../react/Box';
import Label from '../../../react/Label';
import * as constants from '../../../react/constants';
import styles from './ComponentUsage.css';

const UsageLabel = ({ type }) => {
  const types = {
    do: { intent: constants.SUCCESS, children: 'Do' },
    dont: { intent: constants.ERROR, children: 'Don’t' },
    caution: { intent: constants.WARNING, children: 'Caution' },
  };

  return <Label {...types[type]} />;
};

const ComponentUsage = ({ children, type, description, background }) => {
  const demoClasses = cnames(styles.demo, styles[type], {
    [styles.dark]: background === 'dark',
    [styles.none]: background === 'none',
  });

  return (
    <Box>
      <Box
        padding="xl"
        className={demoClasses}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {children}
      </Box>
      <Box padding="xs" paddingLeft="none" paddingRight="none">
        {type && (
          <Box paddingBottom="xs">
            <UsageLabel type={type} />
          </Box>
        )}
        <Box>
          <Text display="block" type="body2">
            {description}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default ComponentUsage;
