import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import posed from 'react-pose';
import IconChevronDownLarge from '../Icons/ChevronDownLarge';
import Card from '../Card';
import Box from '../Box';
import Button from '../Button';
import styles from './Collapsible.scss';

import * as CollapsibleExports from '.';

// eslint-disable-next-line
import CollapsibleSrc from '!raw-loader!.';

const {
  default: Collapsible,
} = CollapsibleExports;
const docs = generateDocs(CollapsibleSrc, resolver.findAllExportedComponentDefinitions);

const basicCollapsible = {
  name: 'Simple usage',
  description: `A basic example of how to use the Collapsible component. As you can see
  it's completely unstyled; the consumer has complete flexibility on its appearance.`,
  muted: true,
  disableLiveEdit: true,
  render: () => {
    // eslint-disable-next-line react/prop-types
    const trigger = ({ toggleCollapsible }) => (
      <button
        onClick={() => toggleCollapsible()}
        tabIndex="0"
        type="button"
      >
        Marco!
      </button>
    );

    return (
      <Collapsible trigger={trigger}> Polo! </Collapsible>
    );
  },
  code: `
const trigger = ({ toggleCollapsible }) => (
  <button onClick={() => toggleCollapsible()}>
    Marco!
  </button>
);

return (
  <Collapsible trigger={trigger} > Polo! </Collapsible>
);
  `,
};

const animatedCollapsible = {
  name: 'Animated Trigger',
  description: `
    An example of how to animate the Collapsible Trigger using React Posed.
    The trigger is provided a prop of "collapsed", so it can utilise it however it
    likes to maintain its appearance of expanded/collapsed. You could also use CSS
    Classes if you like.

    This example also demonstrates how styling is pretty much up to you; very little
    is dictated by the Collapsible.
  `,
  muted: true,
  disableLiveEdit: true,
  render: () => {
    const CollapsibleArrow = posed.div({
      closed: { transform: 'rotate(0deg)' },
      open: { transform: 'rotate(180deg)' },
    });

    // eslint-disable-next-line react/prop-types
    const trigger = ({ collapsed, toggleCollapsible }) => (
      <div
        onClick={() => toggleCollapsible()}
        type="button"
        role="button"
        tabIndex="0"
        className={styles.trigger}
      >
        Accio!
        <CollapsibleArrow
          className={styles.triggerArrow}
          pose={collapsed ? 'closed' : 'open'}
        >
          <IconChevronDownLarge />
        </CollapsibleArrow>
      </div>
    );

    return (
      <Card>
        <Collapsible trigger={trigger}>
          <Box padding="m">
            Red hair crookshanks bludger Marauder’s Map Prongs sunshine daisies butter mellow Ludo
            Bagman. Beaters gobbledegook N.E.W.T., Honeydukes eriseD inferi Wormtail. Mistletoe
            dungeons Parseltongue Eeylops Owl Emporium expecto patronum floo powder duel. Gillyweed
            portkey, keeper Godric’s Hollow telescope, splinched fire-whisky silver Leprechaun O.W.L
            stroke the spine. Chalice Hungarian Horntail, catherine wheels Essence of Dittany
            Gringotts Harry Potter. Prophecies Yaxley green eyes Remembrall horcrux hand of the
            servant. Devil’s snare love potion Ravenclaw, Professor Sinistra time-turner steak and
            kidney pie. Cabbage Daily Prophet letters from no one Dervish and Banges leg.
            <br />
            Boggarts lavender robes, Hermione Granger Fantastic Beasts and Where to Find Them. Bee
            in your bonnet Hand of Glory elder wand, spectacles House Cup Bertie Bott’s Every Flavor
            Beans Impedimenta. Stunning spells tap-dancing spider Slytherin’s Heir mewing kittens
            Remus Lupin. Palominos scarlet train black robes, Metamorphimagus Niffler dead easy
            second bedroom. Padma and Parvati Sorting Hat Minister of Magic blue turban
            remember my last.
          </Box>
        </Collapsible>
      </Card>
    );
  },
  code: `
const CollapsibleArrow = posed.div({
  closed: { transform: 'rotate(0deg)' },
  open: { transform: 'rotate(180deg)' },
});

const trigger = ({ collapsed, toggleCollapsible }) => (
  <div onClick={() => toggleCollapsible()} className={styles.trigger}>
    Accio!
    <CollapsibleArrow className={styles.triggerArrow} pose={collapsed ? 'closed' : 'open'} >
      <IconChevronDownLarge />
    </CollapsibleArrow>
  </div>
);

return (
  <Card>
    <Collapsible trigger={trigger}>
      <Box padding="m">
        ...
      </Box>
    </Collapsible>
  </Card>
  `,
};

const renderPropCollapsible = {
  name: 'Passing render props to children',
  description: `
  The current open/closed state can be pased to the children of a Collapsible via a render prop.
  This can be used for things like managing keyboard tabbing when there are visible and hidden
  elements that can be focused on.
  `,
  muted: true,
  disableLiveEdit: true,
  render: () => {
    const CollapsibleArrow = posed.div({
      closed: { transform: 'rotate(0deg)' },
      open: { transform: 'rotate(180deg)' },
    });

    // eslint-disable-next-line react/prop-types
    const trigger = ({ collapsed, toggleCollapsible }) => (
      <button
        onClick={() => toggleCollapsible()}
        type="button"
        tabIndex="0"
        className={styles.trigger}
      >
        House
        <CollapsibleArrow
          className={styles.triggerArrow}
          pose={collapsed ? 'closed' : 'open'}
        >
          <IconChevronDownLarge />
        </CollapsibleArrow>
      </button>
    );

    return (
      <Card>
        <Collapsible trigger={trigger}>
          { ({ collapsed }) => {
            const buttonProps = { 'aria-hidden': false };
            if (collapsed) { buttonProps['aria-hidden'] = true; }

            return (
              <Box padding="m">
                <Button {...buttonProps}> Gryffindor </Button>
                <Button {...buttonProps}> Ravenclaw </Button>
                <Button {...buttonProps}> Hufflepuff </Button>
                <Button {...buttonProps}> Slytherin </Button>
              </Box>
            );
          }}
        </Collapsible>
        <Collapsible trigger={trigger}>
          { ({ collapsed }) => {
            const buttonProps = { 'aria-hidden': false };
            if (collapsed) { buttonProps['aria-hidden'] = true; }

            return (
              <Box padding="m">
                <Button {...buttonProps}> Stark </Button>
                <Button {...buttonProps}> Baratheon </Button>
                <Button {...buttonProps}> Lannister </Button>
                <Button {...buttonProps}> Targaryen </Button>
                <Button {...buttonProps}> Tully </Button>
                <Button {...buttonProps}> Martell </Button>
                <Button {...buttonProps}> Greyjoy </Button>
                <Button {...buttonProps}> Tyrell </Button>
              </Box>
            );
          }}
        </Collapsible>
        <Collapsible trigger={trigger}>
          { ({ collapsed }) => {
            const buttonProps = { 'aria-hidden': false };
            if (collapsed) { buttonProps['aria-hidden'] = true; }

            return (
              <Box padding="m">
                <Button {...buttonProps}> Gryffindor </Button>
                <Button {...buttonProps}> Ravenclaw </Button>
                <Button {...buttonProps}> Hufflepuff </Button>
                <Button {...buttonProps}> Slytherin </Button>
              </Box>
            );
          }}
        </Collapsible>
        <Collapsible trigger={trigger}>
          { ({ collapsed }) => {
            const buttonProps = { 'aria-hidden': false };
            if (collapsed) { buttonProps['aria-hidden'] = true; }

            return (
              <Box padding="m">
                <Button {...buttonProps}> Stark </Button>
                <Button {...buttonProps}> Baratheon </Button>
                <Button {...buttonProps}> Lannister </Button>
                <Button {...buttonProps}> Targaryen </Button>
                <Button {...buttonProps}> Tully </Button>
                <Button {...buttonProps}> Martell </Button>
                <Button {...buttonProps}> Greyjoy </Button>
                <Button {...buttonProps}> Tyrell </Button>
              </Box>
            );
          }}
        </Collapsible>
      </Card>
    );
  },
  code: `
    const trigger = ({ collapsed, toggleCollapsible }) => (
      <button
        onClick={() => toggleCollapsible()}
        type="button"
        role="button"
        tabIndex="0"
        className={styles.trigger}
      >
        House
        <CollapsibleArrow
          className={styles.triggerArrow}
          pose={collapsed ? 'closed' : 'open'}
        >
          <IconChevronDownLarge />
        </CollapsibleArrow>
      </button>
    );

    return (
      <Card>
        <Collapsible trigger={trigger}>
          { ({ collapsed }) => {
            const buttonProps = { 'aria-hidden': false };
            if (collapsed) { buttonProps['aria-hidden'] = true; }

            return (
              <Box padding="m">
                <Button {...buttonProps}> Gryffindor </Button>
                <Button {...buttonProps}> Ravenclaw </Button>
                <Button {...buttonProps}> Hufflepuff </Button>
                <Button {...buttonProps}> Slytherin </Button>
              </Box>
            );
          }}
        </Collapsible>
        <Collapsible trigger={trigger}>
          { ({ collapsed }) => {
            const buttonProps = { 'aria-hidden': false };
            if (collapsed) { buttonProps['aria-hidden'] = true; }

            return (
              <Box padding="m">
                <Button {...buttonProps}> Stark </Button>
                <Button {...buttonProps}> Baratheon </Button>
                //...
              </Box>
            );
          }}
        </Collapsible>
        //...
  `,
};

const initialState = {
  name: 'Collapsible that is expanded initally',
  description: `Allow the collapsible to default to an expanded state, and then toggle it
  to collapsed.`,
  muted: true,
  disableLiveEdit: true,
  render: () => {
    // eslint-disable-next-line react/prop-types
    const trigger = ({ toggleCollapsible }) => (
      <button onClick={() => toggleCollapsible()} tabIndex="0" type="button">
        Marco!
      </button>
    );

    return (
      <Collapsible trigger={trigger} defaultCollapsed={false}>
        Polo!
      </Collapsible>
    );
  },
  code: `
const trigger = ({ toggleCollapsible }) => (
  <button onClick={() => toggleCollapsible()} tabIndex="0">
    Marco!
  </button>
);

return (
  <Collapsible trigger={trigger} defaultCollapsed={false}>
    Polo!
  </Collapsible>
);
`,
};

const noAnimation = {
  name: 'Collapsible without animation',
  description: `The collapsible can expand and collapse without animations. It will
  also respect a users 'prefers-reduced-motion' setting, and if that is enabled,
  the animation will never be used.
  `,
  muted: true,
  disableLiveEdit: true,
  render: () => {
    // eslint-disable-next-line react/prop-types
    const trigger = ({ toggleCollapsible }) => (
      <button onClick={() => toggleCollapsible()} tabIndex="0" type="button">
        Marco!
      </button>
    );

    return (
      <Collapsible trigger={trigger} animated={false}>
        Polo!
      </Collapsible>
    );
  },
  code: `
const trigger = ({ toggleCollapsible }) => (
  <button onClick={() => toggleCollapsible()} tabIndex="0">
    Marco!
  </button>
);

return (
  <Collapsible trigger={trigger} animated={false}>
    Polo!
  </Collapsible>
);
  `,
};

const animationDelay = {
  name: 'Collapsible with animation delay',
  description: 'The collapsible can have a delay added to the animation.',
  muted: true,
  disableLiveEdit: true,
  render: () => {
    // eslint-disable-next-line react/prop-types
    const trigger = ({ toggleCollapsible }) => (
      <button onClick={() => toggleCollapsible()} tabIndex="0" type="button">
        Marco!
      </button>
    );

    return (
      <Collapsible trigger={trigger} animationDelay={1000}>
        Polo!
      </Collapsible>
    );
  },
  code: `
const trigger = ({ toggleCollapsible }) => (
  <button onClick={() => toggleCollapsible()} tabIndex="0">
    Marco!
  </button>
);

return (
  <Collapsible trigger={trigger} animationDelay={1000}>
    Polo!
  </Collapsible>
);
  `,
};

const Demo = {
  title: Collapsible.displayName,
  description: 'Basic Utility function to expand and collapse an arbitrary amount of contents.',
  docGen: docs,
  slug: 'Collapsible',
  exports: Object.keys(CollapsibleExports),
  examples: [
    basicCollapsible,
    animatedCollapsible,
    renderPropCollapsible,
    initialState,
    noAnimation,
    animationDelay,
  ],
  notes: `
### Overview

This is a fairly basic collapsible component, that will expand and collapse
when clicked. The styling is mostly left to be dictated by the consumer, with
the core functionality and animation provided by this component.

### Trigger

The trigger is a render prop, provided with two params:

**collapsed**

A boolean to indicate whether the component is expanded or collapsed

**toggleCollapsible**

A function to change the components collapsed state. This is a required function, and must be implemented by the trigger to work.

### Contents

The contents are whatever you provide as children to the Collapsible component.

It'll animate the height and opacity, but the rest of the childrens styling
is completely freeform and up to the consumer.
  `,
};

export default Demo;
