import React from 'react';
import PropTypes from 'prop-types';
import { parse as generateDocs, resolver } from 'react-docgen';
import Text from '../Text';
import Button from '../Button';
import Image from '../Image';
import Modal, { ModalBody } from '.';
import * as constants from '../constants';

/* eslint-disable */
import ModalSrc from '!raw-loader!./Modal';
import ModalBodySrc from '!raw-loader!./ModalBody';
/* eslint-enable */

const demoConfig = () => ({
  profile: 'DESKTOP',
  initialValues: {
    size: constants.SMALL,
  },
  fields: [
    {
      type: 'select',
      options: [
        { value: constants.SMALL, label: 'Small' },
        { value: constants.MEDIUM, label: 'Medium' },
        { value: constants.LARGE, label: 'Large' },
      ],
      name: 'size',
      label: 'Size',
    },
  ],
});

const docs = [
  ModalSrc,
  ModalBodySrc,
].map(src => generateDocs(src, resolver.findAllExportedComponentDefinitions))
  .reduce((m, d) => m.concat(d), []);

const imageSrc = 'https://images.unsplash.com/photo-1503640838962-d3d3cadf1847';

class ModalTrigger extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState(prevState => ({ modalOpen: !prevState.modalOpen }));
  }

  render() {
    return (
      <div>
        <div>
          <Button
            strong
            intent={constants.PRIMARY}
            onClick={this.toggleModal}
            type="button"
          >
            Trigger
          </Button>
        </div>

        {
          React.cloneElement(this.props.children, {
            open: this.state.modalOpen,
            onCloseRequested: this.toggleModal,
            accessibleTitle: 'My Modal Demo',
            getApplicationNode: () => document.getElementById('root'),
          })
        }
      </div>
    );
  }
}

ModalTrigger.propTypes = {
  children: PropTypes.element.isRequired,
};

const basicExample = {
  name: 'Simple Example',
  description: 'Just provide a trigger and some content and you’re done.',
  config: () => demoConfig(),
  render: (_, values) => (
    <Modal
      trigger={({ onClick }) => (
        <Button strong intent={constants.PRIMARY} onClick={onClick}>Trigger</Button>
      )}
      accessibleTitle="My Modal Demo"
      size={values.size}
      getApplicationNode={() => document.getElementById('root')}
    >
      <ModalBody>
        <Text>Hello from inside the modal!</Text>
      </ModalBody>
    </Modal>
  ),
  code: `
<Modal
  // The trigger render prop provides events for opening the modal
  trigger={({ onClick }) => <Button strong intent={constants.PRIMARY} onClick={onClick}>Trigger</Button>}
  // For screen-readers
  accessibleTitle="My Modal Demo"
  // Your applications outermost wrapper, will be aria-hidden to reduce
  // screen reader complexity
  getApplicationNode={() => document.getElementById('root')}
>
  <ModalBody>
    <Text>Hello from inside the modal!</Text>
  </ModalBody>
</Modal>
  `,
};

const richerExample = {
  name: 'Richer Content',
  description: 'Modals can have richer content like images in them',
  render: () => (
    <Modal
      trigger={({ onClick }) => (
        <Button strong intent={constants.PRIMARY} onClick={onClick}>Trigger</Button>
      )}
      titleText="My Modal Demo"
    >
      <Image src={imageSrc} fluid />
      <ModalBody>
        <Text display="block" type="display3">Reviews</Text>
        <Text display="block">
          Some content about the reviews
        </Text>
        <input type="text" name="title" placeholder="Title" />
      </ModalBody>
    </Modal>
  ),
  code: `
<Modal
  trigger={({ onClick }) => (
    <Button strong intent={constants.PRIMARY} onClick={onClick}>Trigger</Button>
  )}
  accessibleTitle="My Modal Demo"
  getApplicationNode={() => document.getElementById('root')}
>
  <Image src='${imageSrc}' fluid />
  <ModalBody>
    <Text display="block" type="display3">Reviews</Text>
    <Text display="block">
      Some content about the reviews
    </Text>
  </ModalBody>
</Modal>
  `,
};

const scrollingExample = {
  name: 'Scrolling Content',
  description: 'The ModalOverlay will automatically scroll if the modal goes beyond the bottom of the page.',
  render: () => (
    <Modal
      trigger={({ onClick }) => (
        <Button strong intent={constants.PRIMARY} onClick={onClick}>Trigger</Button>
      )}
      titleText="My Modal Demo"
    >
      <ModalBody>
        <Text display="block" type="display2">Reviews</Text>
        <Text display="block" type="display4">
          A subheading
        </Text>
      </ModalBody>
      <ModalBody>
        {
          new Array(10).fill('').map((_, i) => (
            // eslint-disable-next-line
            <Text element="p" key={i}>
              Vestibulum congue, leo ut rhoncus semper, purus sapien fermentum
              libero, at sollicitudin sem eros sit amet risus. Etiam rutrum, turpis
              eget aliquam hendrerit, arcu lorem semper quam, vel suscipit nunc
              libero at erat. Etiam id ornare elit, eget tincidunt tortor.
              Vestibulum ac ante ac dui placerat vulputate ut vitae velit. Mauris
              ultrices est quis est interdum accumsan. In porta mauris sed sagittis
              ultricies. Ut ipsum dolor, laoreet eget iaculis a, pulvinar in erat.
              Donec in molestie velit. Fusce scelerisque porttitor diam ut
              efficitur. Phasellus egestas ante dolor, eget dictum ex imperdiet id.
              Vestibulum lobortis congue condimentum. Integer pharetra odio tellus.
              Ut odio erat, dictum maximus velit eu, congue tincidunt nisl. Ut
              vitae facilisis erat, quis interdum dui.
            </Text>
          ))
        }

      </ModalBody>
    </Modal>
  ),
  code: `
<Modal
  trigger={({ onClick }) => (
    <Button strong intent={constants.PRIMARY} onClick={onClick}>Trigger</Button>
  )}
  titleText="My Modal Demo"
>
  <ModalBody>
    <Text display="block" type="display2">Reviews</Text>
    <Text display="block" type="display4">
      A subheading
    </Text>
  </ModalBody>
  <ModalBody>
    ${new Array(10).fill('').map((_, i) => (
    `<Text element="p" key='${i}'>
      Vestibulum congue, leo ut rhoncus semper, purus sapien fermentum
      libero, at sollicitudin sem eros sit amet risus. Etiam rutrum, turpis
      eget aliquam hendrerit, arcu lorem semper quam, vel suscipit nunc
      libero at erat. Etiam id ornare elit, eget tincidunt tortor.
      Vestibulum ac ante ac dui placerat vulputate ut vitae velit. Mauris
      ultrices est quis est interdum accumsan. In porta mauris sed sagittis
      ultricies. Ut ipsum dolor, laoreet eget iaculis a, pulvinar in erat.
      Donec in molestie velit. Fusce scelerisque porttitor diam ut
      efficitur. Phasellus egestas ante dolor, eget dictum ex imperdiet id.
      Vestibulum lobortis congue condimentum. Integer pharetra odio tellus.
      Ut odio erat, dictum maximus velit eu, congue tincidunt nisl. Ut
      vitae facilisis erat, quis interdum dui.
    </Text>`
  ))}
  </ModalBody>
</Modal>
  `,
};

const controlledExample = {
  name: 'Controlled Example',
  description: 'The modal accepts an "open" prop that controls its visibility programatically.',
  render: () => (
    <ModalTrigger>
      <Modal titleText="My Modal Demo">
        <ModalBody>
          <Text>Hello from inside the modal!</Text>
        </ModalBody>
      </Modal>
    </ModalTrigger>
  ),
  code: `
<State initialState={{ modalOpen: false }}>
  {
   ({ state, setState }) => (
     <div id="root">
       <Button onClick={() => setState({ modalOpen: true })} intent={constants.PRIMARY} strong type="button">Trigger</Button>
       <Modal
         accessibleTitle="My Modal Demo"
         open={state.modalOpen}
         onCloseRequested={() => setState({ modalOpen: false })}
         getApplicationNode={() => document.getElementById('root')}
       >
         <ModalBody>
           <Text>Hello from inside the modal!</Text>
         </ModalBody>
       </Modal>
     </div>
   )
 }
</State>
  `,
};

const Demo = {
  title: Modal.displayName,
  description: 'A simple modal for showing additional content in-context, overlaid on top of the original content.',
  docGen: docs,
  slug: 'modal',
  // TODO automate this once we deprecate the size and position constants exported by Modal
  exports: ['default', 'ModalBody'],
  examples: [
    basicExample,
    richerExample,
    scrollingExample,
    controlledExample,
  ],
};

export default Demo;
