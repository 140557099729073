import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import Box from '../Box';
import List, { ListItem } from '../List';

import * as ReviewExports from '.';

// eslint-disable-next-line
import ReviewSrc from '!raw-loader!.';

const { default: Review } = ReviewExports;
const docs = generateDocs(ReviewSrc, resolver.findAllExportedComponentDefinitions);

const basicExample = {
  name: 'Simple Example',
  description: 'Shows the rating, reviewer name and a portion of the review',
  overflowVisual: false,
  render: () => (
    <Box style={{ maxWidth: '375px' }}>
      <Review
        rating={3}
        username="Ted"
        title="Great t-shirts mate!"
        date="25 January 2019"
      >
        The content of the review goes here. It could be rather long,
        especially if the person is really really happy with their purchase and
        wants to tell us all about it.
      </Review>
    </Box>
  ),
  code: `
// Long lines of text are hard to read
// so we constrain the width here
<Box style={{ maxWidth: '375px' }}>
  <Review
    rating={3}
    username="Ted"
    title="Great t-shirts mate!"
    date="25 January 2019"
  >
    The content of the review goes here. It could be rather long,
    especially if the person is really really happy with their purchase and
    wants to tell us all about it.
  </Review>
</Box>
  `,
};

const listExample = {
  name: 'Reviews inside a List',
  description: 'Put Reviews in a list when you have a few of them.',
  overflowVisual: false,
  render: () => (
    <Box style={{ maxWidth: '375px' }}>
      <List relaxed>
        {
          new Array(3).fill(null).map((_, i) => (
            <ListItem>
              <Review
                rating={5 - i}
                username={`Reviewer #${i + 1}`}
                title="Great t-shirts mate!"
                date="25 January 2019"
              >
                The content of the review goes here. It could be rather long,
                especially if the person is really really happy with their purchase and
                wants to tell us all about it.
              </Review>
            </ListItem>
          ))
        }
      </List>
    </Box>
  ),
  code: `
// Long lines of text are hard to read
// so we constrain the width here
<Box style={{ maxWidth: '375px' }}>
  <List>
    {
      new Array(3).fill(null).map((_, i) => (
        <ListItem>
          <Review
            rating={5 - i}
            username={\`Review #\${i + 1}\`}
            title="Great t-shirts mate!"
            date="25 January 2019"
          >
            The content of the review goes here. It could be rather long,
            especially if the person is really really happy with their purchase and
            wants to tell us all about it.
          </Review>
        </ListItem>
      ))
    }
  </List>
</Box>
  `,
};


const Demo = {
  title: Review.displayName,
  description: 'A single review and rating from a user.',
  docGen: docs,
  slug: 'Review',
  exports: Object.keys(ReviewExports),
  examples: [
    basicExample,
    listExample,
  ],
  notes: `
  `,
};

export default Demo;
