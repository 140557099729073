import React from 'react';
import { defineMessages } from 'react-intl';
import Box from '../../../../../Box';
import Text from '../../../../../Text';
import Avatar from '../../../../../Avatar';
import Carousel, { Slide } from '../../../../../Carousel';
import * as constants from '../../../../../constants';
import { buildArtistShopUrl } from '../../../../lib/doSearch';
import styles from './styles.scss';

const messages = defineMessages({
  artistHeading: 'Top artists',
});

const ArtistsSection = ({
  intl,
  artists,
  loading,
  baseUrl,
  slidesPerView,
  profile,
}) => {
  const loadingArtists = new Array(10).fill('').map((_, i) => ({
    artist_name: `Loading ${i}`,
  }));

  if (loading) {
    return (
      <>
        <Text type="display6" loading>{ intl.formatMessage(messages.artistHeading) }</Text>
        <Box marginTop="xs">
          <Carousel slidesPerView="auto" profile={profile}>
            {
              loadingArtists.map(artist => (
                <Slide key={artist.artist_name}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Avatar
                      loading={loading}
                      src=""
                      size={constants.LARGE}
                      alt={`Avatar for ${artist.artist_name}`}
                    />
                    <Box marginBottom="xs" />
                    <Text type="body2" loading={loading}>{ artist.artist_name }</Text>
                  </Box>
                </Slide>
              ))
            }
          </Carousel>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box marginBottom="m">
        <Text type="display6">{ intl.formatMessage(messages.artistHeading) }</Text>
      </Box>

      <Box data-testid="ds-typeahead-artists-carousel">
        <Carousel slidesPerView={slidesPerView || 3} profile={profile}>
          {
            artists.map(artist => (
              <Slide key={artist.artist_name}>
                <a
                  className={styles.artistLink}
                  href={buildArtistShopUrl(baseUrl, artist.artist_name)}
                >
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Avatar
                      loading={loading}
                      src={artist.artist_avatar_url || ''}
                      size={constants.LARGE}
                      alt={`Avatar for ${artist.artist_name}`}
                    />
                    <Box marginTop="xs" className={styles.artistName}>
                      <Text type="caption" loading={loading}>
                        { artist.artist_name }
                      </Text>
                    </Box>
                  </Box>
                </a>
              </Slide>
            ))
          }
        </Carousel>
      </Box>
    </>
  );
};

export default ArtistsSection;
