import React from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';
import * as constants from '../../constants';
import sizes from '../sizes';

const { DESKTOP, MOBILE } = sizes;

const SelectField = ({
  options,
  field,
  form,
  fluid,
  profile,
  intent,
  disabled,
  placeholder,
  onFocus,
  onBlur,
}) => {
  return (
    <Select
      name={field.name}
      value={field.value}
      intent={intent}
      onChange={value => form.setFieldValue(field.name, value)}
      onFocus={onFocus}
      placeholder={placeholder}
      fluid={fluid}
      options={options}
      onBlur={() => {
        onBlur();
        // Instead of relying on the e having the right properties,
        // we can set the touched state manually
        // field.onBlur(e);
        form.setFieldTouched(field.name, true);
      }}
      disabled={disabled}
      profile={profile}
    />
  );
};

SelectField.propTypes = {
  disabled: PropTypes.bool,
  fluid: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  field: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
  profile: PropTypes.oneOf([MOBILE, DESKTOP, constants.MOBILE, constants.DESKTOP]),
  // eslint-disable-next-line react/require-default-props
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  intent: PropTypes.oneOf([
    constants.INFORMATION,
    constants.ERROR,
    constants.WARNING,
    constants.SUCCESS,
    constants.NEUTRAL,
  ]),
};

SelectField.defaultProps = {
  disabled: false,
  fluid: false,
  options: [],
  profile: constants.MOBILE,
  onFocus: null,
  onBlur: f => f,
  placeholder: 'Select...',
  intent: constants.NEUTRAL,
};

export default SelectField;
