import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import styles from './Flex.scss';

const Flex = ({ children, direction }) => {
  const className = cnames(styles.flex, {
    [styles[direction]]: !!direction,
  });

  return (
    <div className={className}>
      {
        React.Children.map(children, child => (
          <div className={styles.item}>{ child }</div>
        ))
      }
    </div>
  );
};

Flex.propTypes = {
  children: PropTypes.node.isRequired,
  direction: PropTypes.string,
};

Flex.defaultProps = {
  direction: 'row',
};

Flex.displayName = 'Flex';

export default Flex;
