import React from 'react';
import Text from '../../../react/Text';
import Alert from '../../../react/Alert';
import * as constants from '../../../react/constants';

const BetaWarning = () => {
  return (
    <Alert subtle label="Beta" intent={constants.INFORMATION}>
      <Text element="p">
        <strong>Heads up!</strong> This component is still in beta, meaning
        it’s likely to have some breaking changes made to its API soon.
      </Text>
    </Alert>
  );
};

export default BetaWarning;
