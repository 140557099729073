import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import get from 'lodash/get';
import enhanceWithClickOutside from 'react-click-outside';
import Input from './Input';
import Box from '../../../../../Box';
import Menu from '../Menu';
import StaticLinks from '../StaticLinks';
import ArtistsSection from '../ArtistsSection';
import styles from './styles.scss';
import rainbowStyle from './rainbowStyle.scss';
import { DESKTOP } from '../../../../../constants';

const searchedEvent = () => ({ name: 'searched' });

class Typeahead extends Component {
  constructor(props) {
    super(props);

    this.handleFormOnSubmit = this.handleFormOnSubmit.bind(this);
  }

  handleFormOnSubmit({ term }) {
    const { handleSubmit, handleEvent, clearCompletions, productContext } = this.props;

    handleSubmit(term, productContext);
    clearCompletions();
    handleEvent(searchedEvent());
  }

  handleClickOutside() {
    const { clearCompletions } = this.props;

    clearCompletions();
    this.props.closeMenu();
  }

  render() {
    const {
      completions,
      intl,
      term,
      baseUrl,
      isOpen,
      types,
      searchWords,
      searchTerm,
      logView,
      getItemProps,
      getMenuProps,
      highlightedIndex,
      productContext,
      getComboboxProps,
      doSearch,
      setProductContext,
      closeMenu,
      popularProducts,
      useRainbowStyle,
      quickLinks,
      otherLinks,
      topArtists,
      topArtistsLoading,
      logEvent,
      onTypeaheadResultClick,
      onSearchCallback,
    } = this.props;

    const searchBoxClass = cnames(styles.searchBox, {
      [styles.hasValue]: !!term,
      [rainbowStyle.searchBoxRainbow]: useRainbowStyle,
    });

    const wrapperClassNames = cnames(
      styles.inputWrapper,
      {
        [rainbowStyle.inputWrapperRainbow]: useRainbowStyle,
      },
    );

    const completionsListClassName = cnames(styles.completionsList, {
      [styles.open]: completions.length,
      [styles.visuallyHidden]: !isOpen,
    });

    const hasCompletions = true;
    const comboboxProps = getComboboxProps({ refKey: 'innerRef' });

    return (
      <div>
        <div
          className={searchBoxClass}
        >
          <Box
            className={wrapperClassNames}
            {...comboboxProps}
          >
            <Input
              intl={intl}
              productContext={productContext}
              onSearch={this.handleFormOnSubmit}
              role={hasCompletions ? 'combobox' : null}
              ariaExpanded={!!hasCompletions}
              inputProps={this.props.inputProps}
              reset={this.props.reset}
              engagingPlaceholderMessages={this.props.engagingPlaceholderMessages}
              setProductContext={setProductContext}
              doSearch={doSearch}
              isMenuOpen={isOpen}
              closeMenu={closeMenu}
              logEvent={logEvent}
              onSearchCallback={onSearchCallback}
            />
          </Box>
          <div className={completionsListClassName}>
            <Box
              display="flex"
              justifyContent="space-between"
              paddingTop="l"
            >
              <Box flex="1 0 auto" className={styles.completions}>
                <Menu
                  {...({
                    isOpen,
                    types,
                    getItemProps,
                    getMenuProps,
                    highlightedIndex,
                    searchWords,
                    searchTerm,
                    intl,
                    withProductContext: get(productContext, 'id', null),
                    logView,
                    closeMenu,
                    onTouchStart: () => {
                      // Close keyboard on mobile
                      // TODO check safety
                      document.activeElement.blur();
                    },
                    onTypeaheadResultClick,
                  })}
                />
              </Box>
              {!searchTerm && (
                <Box flex="0 1 50%" className={styles.staticLinks} paddingRight="l">
                  <Box marginBottom="m" className={styles.artists} paddingRight="m">
                    <ArtistsSection
                      artists={topArtists}
                      loading={topArtistsLoading}
                      intl={intl}
                      baseUrl={baseUrl}
                      slidesPerView="auto"
                      profile={DESKTOP}
                    />
                  </Box>
                  <StaticLinks
                    data={{
                      popularProducts,
                      quickLinks,
                      otherLinks,
                    }}
                    profile={DESKTOP}
                  />
                </Box>
              )}
            </Box>
          </div>
        </div>
      </div>

    );
  }
}

Typeahead.propTypes = {
  // eslint-disable-next-line react/no-typos
  intl: PropTypes.shape({}).isRequired,
  term: PropTypes.string,
  baseUrl: PropTypes.string,
  startSearchText: PropTypes.string,
  completions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    }),
  ),
  handleSubmit: PropTypes.func,
  handleEvent: PropTypes.func,
  clearCompletions: PropTypes.func,
  updateTerm: PropTypes.func,
  onSearchBoxFocus: PropTypes.func,
  isOpen: PropTypes.bool,
  productContext: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  }),
  engagingPlaceholderMessages: PropTypes.arrayOf(PropTypes.string),
  onTypeaheadResultClick: PropTypes.func,
};

Typeahead.defaultProps = {
  term: '',
  baseUrl: '',
  startSearchText: '',
  completions: [],
  handleSubmit: () => { },
  handleEvent: () => { },
  clearCompletions: () => { },
  updateTerm: () => { },
  onSearchBoxFocus: () => { },
  isOpen: false,
  productContext: null,
  engagingPlaceholderMessages: null,
};

Typeahead.displayName = 'SearchBox';

export default enhanceWithClickOutside(Typeahead);
