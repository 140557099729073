import React from 'react';
import Box from '../../../../../react/Box';
import Image from '../../../../../react/Image';
import useResponsive from '../../../../hooks/useResponsive';
import PageSection from '../../../../../react/PageSection';

const IntroSection = ({ children, imageSrc, imageOn, ...props }) => {
  const { mobile } = useResponsive();

  // Determine ping pong
  let flexDirection = imageOn === 'right' ? 'row-reverse' : 'row';
  if (mobile) flexDirection = 'column';

  // Determine padding
  let imagePadding = {
    paddingLeft: imageOn === 'right' ? 0 : 1,
    paddingRight: imageOn === 'right' ? 1 : 0,
  };
  let textPadding = {
    paddingLeft: imageOn === 'right' ? 0 : 2,
    paddingRight: imageOn === 'right' ? 2 : 0,
  };
  if (mobile) {
    imagePadding = {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 2,
    };
    textPadding = imagePadding;
  }

  return (
    <PageSection
      display="flex"
      alignItems={!mobile && 'center'}
      flexDirection={flexDirection}
      marginTop={mobile ? 0 : 2}
      marginBottom={mobile ? 0 : 2}
      {...props}
    >
      <Box
        style={{ width: mobile ? '67%' : '36%' }}
        {...imagePadding}
      >
        <Image src={imageSrc} fluid />
      </Box>
      <Box
        flex="1"
        {...textPadding}
      >
        {children}
      </Box>
    </PageSection>
  );
};

export default IntroSection;
