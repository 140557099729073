import React from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import _styles from './styles.scss';
import Input from '../Input';
import Box from '../../Box';

const CheckboxField = ({
  options,
  form,
  field,
  label,
  stacked,
  onFocus,
  onBlur,
  styles,
  fluid,
}) => {
  if (!Array.isArray(options) || options.length === 0) return null;
  const fieldValue = new Set(field.value);

  return (
    <div
      className={cnames(styles.group, { [styles.stacked]: stacked })}
      role="group"
      aria-label={label}
    >
      {
        options.map((option) => {
          const newValue = new Set(fieldValue);
          const optionIsSelected = fieldValue.has(option.value);

          const classes = cnames(styles.checkbox, {
            [styles.selected]: optionIsSelected,
            [styles.disabled]: option.isDisabled,
            [styles.fluid]: fluid,
          });

          if (optionIsSelected) {
            newValue.delete(option.value);
          } else {
            newValue.add(option.value);
          }

          return (
            // The input is nested inside the label
            /* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */
            <label
              tabIndex={-1}
              key={`${field.name}_${option.value}`}
              className={classes}
            >
              <Box className={styles.checkboxInput}>
                <Input
                  type="checkbox"
                  tabIndex={0}
                  checked={optionIsSelected}
                  onFocus={onFocus}
                  onBlur={(e) => {
                    onBlur(e);
                    field.onBlur(e);
                  }}
                  onChange={() => form.setFieldValue(field.name, [...newValue])}
                  name={field.name}
                  disabled={option.isDisabled}
                />
              </Box>
              <span className={styles.checkboxLabel}>{ option.label }</span>
            </label>
          );
        })
      }
    </div>
  );
};

CheckboxField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
    isDisabled: PropTypes.bool,
  })),
  field: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
  stacked: PropTypes.bool,
  fluid: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  /**
    * The CSS classNames for this component. NEVER manually specify this prop
    */
  styles: PropTypes.shape({}),
};

CheckboxField.defaultProps = {
  options: [],
  stacked: false,
  fluid: false,
  onFocus: null,
  onBlur: f => f,
  styles: _styles,
};

export default CheckboxField;
