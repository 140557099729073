import React from 'react';
import PropTypes from 'prop-types';
import { Field as FormikField } from 'formik';
import cnames from 'classnames';
import FileField from '../FileField';
import TextAreaField from '../TextAreaField';
import TextField from '../TextField';
import SelectField from '../SelectField';
import ButtonToggleField from '../ButtonToggleField';
import RadioField from '../RadioField';
import RadioSelectorField from '../RadioSelectorField';
import CheckboxField from '../CheckboxField';
import ColorField from '../ColorField';
import Text from '../../Text';
import Box from '../../Box';
import styles from './styles.scss';
import FormDeviceProfileContext from '../FormDeviceProfileContext';

class Field extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
    };
  }

  render() {
    const {
      type,
      label,
      actionSlot,
      children,
      labelSubtitle,
      'data-testid': testid,
      ...props
    } = this.props;
    const { focused } = this.state;
    let component;
    let LabelTag = 'label';
    let ariaLabel = '';

    switch (type) {
      case 'file':
        component = FileField;
        break;
      case 'textarea':
        component = TextAreaField;
        break;
      case 'select':
        component = SelectField;
        LabelTag = 'div';
        ariaLabel = label;
        break;
      case 'button-toggle':
        component = ButtonToggleField;
        LabelTag = 'div';
        break;
      case 'radio':
        component = RadioField;
        LabelTag = 'div';
        break;
      case 'radio-selector':
        component = RadioSelectorField;
        LabelTag = 'div';
        break;
      case 'checkbox':
        component = CheckboxField;
        LabelTag = 'div';
        break;
      case 'color':
        component = ColorField;
        LabelTag = 'div';
        break;
      default:
        component = TextField;
        break;
    }

    const isHidden = type === 'hidden';
    const hideLabel = props.hideLabel || isHidden;

    const labelSubtitleComponent = labelSubtitle
      && (
        <Text marginLeft="xs" type="body" display="inline" data-testid="ds-field-label-subtitle">
          {labelSubtitle}
        </Text>
      );

    const fieldLabelClasses = cnames(styles.fieldTop, {
      [styles.visuallyHidden]: hideLabel,
    });


    /* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */
    return (
      <Box
        paddingLeft={isHidden ? 0 : 0.5}
        paddingRight={isHidden ? 0 : 0.5}
        marginBottom={isHidden ? 0 : 1.25}
        className={cnames(styles.field, { [styles.fluid]: props.fluid })}
        /**
         * Even though this testId attribute name does not follow standard conventions,
         * we are keeping it here to prevent any breaking changes
         * which expect this attribute to be present.
         * This decision has been captured as item number 19 in the decision register
         * https://redbubble.atlassian.net/wiki/spaces/DDEP/pages/2941878407/Initiative+decision+log
         */
        data-test-id="Form_Field"
        data-testid={testid || `form-field-${type}`}
      >
        <Box
          element={LabelTag}
          display={isHidden ? 'block' : 'inline-block'}
          className={
            cnames(styles.label, { [styles.disabled]: props.disabled })
          }
        >
          <div className={fieldLabelClasses}>
            <span className={styles.labelText}>{label}{labelSubtitleComponent}</span>
            {
              actionSlot && (
                <span className={styles.actionSlot}>{actionSlot}</span>
              )
            }
          </div>

          <FormDeviceProfileContext.Consumer>
            {
              profile => (
                <React.Fragment>
                  <div aria-label={ariaLabel}>
                    <FormikField
                      {...props}
                      component={component}
                      inputType={type}
                      label={label}
                      profile={profile}
                      fluid={props.fluid}
                      onFocus={() => this.setState({ focused: true })}
                      onBlur={() => this.setState({ focused: false })}
                    />
                  </div>
                  {
                    typeof children === 'function' ? (
                      children({ focused })
                    ) : children
                  }
                </React.Fragment>
              )
            }
          </FormDeviceProfileContext.Consumer>
        </Box>
      </Box>
    );
    /* eslint-enable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */
  }
}

Field.displayName = 'Field';

Field.propTypes = {
  /**
    * The type of field to render
    */
  type: PropTypes.oneOf([
    'text',
    'textarea',
    'password',
    'email',
    'hidden',
    'tel',
    'select',
    'button-toggle',
    'file',
    'radio',
    'radio-selector',
    'checkbox',
    'color',
  ]).isRequired,
  /**
    * The label to shown above the field, inside <label> tag
    */
  label: PropTypes.string.isRequired,
  /**
    * When true, render the field full width
    */
  fluid: PropTypes.bool,
  /**
    * The HTML input "name" attribute
    */
  name: PropTypes.string.isRequired,
  /**
    * For types: select, button-toggle, radio-selector, radio, checkbox.
    * List of options each with a value and label
    */
  options: PropTypes.arrayOf(PropTypes.shape({})),
  /**
    * For type: button-toggle, radio, checkbox. Stack the buttons vertically
    */
  stacked: PropTypes.bool,
  /**
    * For type: color. Allow multiple options to be chosen
    */
  multiple: PropTypes.bool,
  /**
    * A slot to render into, on the right of the label
    */
  actionSlot: PropTypes.node,
  /**
   * Highlight the field to indicate some kind of intent (e.g. ERROR)
   */
  intent: PropTypes.string,
  /**
    * Render the label as visually hidden, but still on the page (for accessibility purposes).
    Can be considered to kind of be like 'alt' text, if you want to render the label in a different
    section for layout purposes.
    */
  hideLabel: PropTypes.bool,
  /**
   * Renders the field greyed out and innaccessible.
   */
  disabled: PropTypes.bool,
  /**
   * Optional string, which will render next to the primary field Label, as a subtitle
   */
  labelSubtitle: PropTypes.string,
};

Field.defaultProps = {
  fluid: false,
  options: [],
  stacked: false,
  multiple: false,
  actionSlot: null,
  intent: null,
  hideLabel: false,
  disabled: false,
  labelSubtitle: null,
};

export default Field;
