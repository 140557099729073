import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import DesignSystemContext from '../contexts/DesignSystemContext';
import Logo2019 from './assets/Logo2019';
import Logo2020 from './assets/Logo2020';
import Brandmark2019 from './assets/Brandmark2019';
import Brandmark2020 from './assets/Brandmark2020';
import LogoStacked2019 from './assets/LogoStacked2019';
import LogoStacked2020 from './assets/LogoStacked2020';
import LogoBauble2023 from './assets/LogoBauble2023';
import {
  CREATIVE_ADVENTURES,
  CREATIVE_ADVENTURES_DARK,
  FIND_YOUR_THING,
  FIND_YOUR_THING_DARK,
} from '../themes/ids';

// Brands
const REDBUBBLE = 'redbubble';

// eslint-disable-next-line consistent-return
const Logo = (props) => {
  const { theme } = useContext(DesignSystemContext);
  const {
    brand,
    colored,
    contrast,
    color,
    brandmarkOnly,
    stacked,
    bauble,
  } = props;

  let fillColor;
  let wordMarkColor;
  let brandMarkColor;

  if (typeof color === 'string') {
    fillColor = color;
  } else if (typeof color === 'object') {
    wordMarkColor = color.wordMark;
    brandMarkColor = color.brandMark;
  }

  const assetLookup = {
    [FIND_YOUR_THING]: {
      [REDBUBBLE]: {
        default: Logo2019,
        brandmarkOnly: Brandmark2019,
        stacked: LogoStacked2019,
        bauble: LogoBauble2023,
      },
    },
    [CREATIVE_ADVENTURES]: {
      [REDBUBBLE]: {
        default: Logo2020,
        brandmarkOnly: Brandmark2020,
        stacked: LogoStacked2020,
        bauble: LogoBauble2023,
      },
    },
  };

  assetLookup[FIND_YOUR_THING_DARK] = assetLookup[FIND_YOUR_THING];
  assetLookup[CREATIVE_ADVENTURES_DARK] = assetLookup[CREATIVE_ADVENTURES];

  let type = 'default';
  if (stacked) type = 'stacked';
  if (brandmarkOnly) type = 'brandmarkOnly';
  if (bauble) type = 'bauble';

  const LogoAsset = assetLookup[theme.id][brand][type];

  const colorProps = {
    fillColor,
    wordMarkColor,
    brandMarkColor,
  };
  if (colored && contrast) colorProps.wordMarkColor = 'white';
  if (!colored) colorProps.fillColor = contrast ? 'white' : '#19124F';

  return (
    <LogoAsset {...colorProps} />
  );
};

Logo.displayName = 'Logo';

Logo.propTypes = {
  /**
    * Private: Choose a brand to render the correct Logo, currently only 'redbubble' is available.
    */
  brand: PropTypes.oneOf([
    REDBUBBLE,
  ]),
  /**
    * Renders the Logo in it's default full-color version.
    */
  colored: PropTypes.bool,
  /**
    * Renders the Logo in the contrast version.
    */
  contrast: PropTypes.bool,
  /**
    * Renders the brandmark only without the wordmark (redbubble text).
    */
  brandmarkOnly: PropTypes.bool,
  /**
    * Renders the stacked Logo version (brandmark above wordmark).
    */
  stacked: PropTypes.bool,
  /**
    * Renders the bauble Logo version.
  */
  bauble: PropTypes.bool,
  /**
    * Private: Color gives you the option to change the full color of the Logo
    * or change the color of the brandmark and the wordmark independently.
    */
  color: PropTypes.string,
};

Logo.defaultProps = {
  contrast: false,
  colored: true,
  color: '',
  brand: REDBUBBLE,
  brandmarkOnly: false,
  stacked: false,
  bauble: false,
};

export default Logo;
