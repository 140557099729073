import React from 'react';
import PropTypes from 'prop-types';
import State from '@reach/component-component';
import cnames from 'classnames';
import * as constants from '../../constants';
import styles from './styles.scss';
import TickIcon from '../../Icons/Tick';

const TYPE_RADIO = 'radio';
const TYPE_CHECKBOX = 'checkbox';
const TYPE_FILE = 'file';

const FileInput = ({ intent, fluid, innerRef, ...props }) => {
  return (
    <State initialState={{ filename: '' }}>
      {
        ({ state: { filename }, setState }) => (
          <div
            className={
              cnames(
                styles.fileInputWrapper,
                {
                  [styles.fluid]: fluid,
                },
              )
            }
          >
            <input
              {...props}
              onChange={(e) => {
                setState({ filename: e.target.value });
                if (props.onChange) props.onChange(e);
              }}
              className={styles.ghostField}
              ref={innerRef}
            />
            <div
              className={
                cnames(styles.input, styles.uploadInput, { [styles.disabled]: props.disabled })
              }
              aria-hidden="true"
            >
              <div className={styles.uploadContent}>
                { filename || <span className={styles.placeholderText}>{ props.placeholder }</span>}
              </div>
              <div className={styles.uploadButton}>
                { filename ? 'Change' : 'Browse' }
              </div>
            </div>
          </div>
        )
      }
    </State>
  );
};

const RadioInput = ({ intent, fluid, innerRef, ...props }) => (
  <span>
    <input
      {...props}
      className={styles.radioInput}
      ref={innerRef}
    />
    <span className={styles.fakeRadio} />
  </span>
);

const CheckboxInput = ({ intent, fluid, innerRef, ...props }) => (
  <span className={styles.checkboxWrapper}>
    <input
      {...props}
      className={styles.checkboxInput}
      ref={innerRef}
    />
    <span className={styles.checkboxIcon}>
      <TickIcon />
    </span>
  </span>
);

const DefaultInput = ({ intent, fluid, innerRef, ...props }) => (
  <input
    {...props}
    ref={innerRef}
    className={
      cnames(
        styles.input,
        styles[intent],
        {
          [styles.fluid]: fluid,
          [styles.disabled]: props.disabled,
        },
      )
    }
  />
);

const Input = (props) => {
  switch (props.type) {
    case TYPE_FILE:
      return <FileInput {...props} />;
    case TYPE_RADIO:
      return <RadioInput {...props} />;
    case TYPE_CHECKBOX:
      return <CheckboxInput {...props} />;
    default:
      return <DefaultInput {...props} />;
  }
};

Input.propTypes = {
  intent: PropTypes.oneOf([
    constants.INFORMATION,
    constants.ERROR,
    constants.WARNING,
    constants.SUCCESS,
    constants.NEUTRAL,
  ]),
  fluid: PropTypes.bool,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  intent: constants.NEUTRAL,
  fluid: false,
  disabled: false,
};

export default Input;
