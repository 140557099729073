exports.STATE = 'STATE';
exports.NEUTRAL = 'NEUTRAL';
exports.INTENT = 'INTENT';
exports.EXPRESSIVE = 'EXPRESSIVE';
exports.COMMON = 'COMMON';
exports.BRAND = 'BRAND';
exports.PRIMARY = 'PRIMARY';
exports.BACKGROUND = 'BACKGROUND';
exports.BORDER = 'BORDER';
exports.FONT = 'FONT';
exports.FONT_FAMILY = 'FONT_FAMILY';
exports.FONT_SIZE = 'FONT_SIZE';
exports.FONT_WEIGHT = 'FONT_WEIGHT';
exports.LINE_HEIGHT = 'LINE_HEIGHT';
exports.COLOR = 'COLOR';
exports.TEXT = 'TEXT';
exports.TEXT_COLOR = 'TEXT_COLOR';
exports.RADIUS = 'RADIUS';
exports.SPACING = 'SPACING';
exports.LAYOUT = 'LAYOUT';
exports.SIZE = 'SIZE';
exports.DIMENSION = 'DIMENSION';
exports.BORDER_WIDTH = 'BORDER_WIDTH';
exports.TRANSITION = 'TRANSITION';
exports.SHADOW = 'SHADOW';
exports.NONE = 'NONE';
exports.RAINBOW = 'RAINBOW';
exports.HOLIDAY_RAINBOW = 'HOLIDAY_RAINBOW';
