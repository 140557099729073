import React from 'react';
import { Provider } from 'react-redux';
import { IntlProvider, injectIntl } from 'react-intl';
import { createStore, compose } from 'redux';
import { parse as generateDocs, resolver } from 'react-docgen';
import Header, { PromoMessage } from './Header';
import { DESKTOP, MOBILE } from './constants';
import birdAvatar from '../src/images/rb-avatar-bird.svg';
import PromoBanner from './headerAndFooter/components/PromoBanner';

// eslint-disable-next-line
import HeaderSrc from '!raw-loader!./headerAndFooter/components/Header';

const docs = generateDocs(HeaderSrc, resolver.findAllExportedComponentDefinitions);

const store = createStore(() => {
});

const HeaderComponent = compose(injectIntl)(Header);

const userInfo = {
  avatar: birdAvatar,
  username: 'DesignBaby',
  locale: 'en',
  country: 'AU',
  isLoggedIn: true,
};

const artistAccount = {
  isArtist: true,
  metrics: {
    followers: 9384,
    favorites: 10234,
    productsSold: 498,
  },
};

const cart = {
  itemsCount: 2,
};

const messages = [
  {
    endDate: new Date(Date.now() + 20000000).toUTCString(),
    message: '[c]Get <strong>30% off</strong> everything with <strong>30CYBER</strong> at checkout',
  },
];


const banners = [
  {
    endDate: new Date(Date.now() + 20000000).toUTCString(),
    message: '<a href="#"><strong>Only one banner is supported</strong> although it <em>comes in the shape of an array</em></a>',
  },
];

const demoConfig = responsive => ({
  profile: 'DESKTOP',
  initialValues: {
    profile: responsive.desktop ? DESKTOP : MOBILE,
  },
  fields: [
    {
      type: 'button-toggle',
      options: [
        { value: MOBILE, label: 'Mobile' },
        { value: DESKTOP, label: 'Desktop' },
      ],
      name: 'profile',
      label: 'Profile',
      hideLabel: true,
    },
  ],
});

const basic = {
  name: 'Member',
  description: 'The header when a user is logged in and is not an artist.',
  muted: true,
  overflowVisual: true,
  config: responsive => demoConfig(responsive),
  render: (responsive, { profile }) => (
    <Provider store={store}>
      <IntlProvider locale="en">
        {/* eslint-disable no-alert */}
        <HeaderComponent
          profile={profile}
          userInfo={userInfo}
          cart={cart}
        />
      </IntlProvider>
    </Provider>
  ),
  code: `
<Header profile={isMobile ? constants.MOBILE : constants.DESKTOP} cart={${JSON.stringify(cart)}} userInfo={${JSON.stringify(userInfo)}} />
  `,
};

const withProductContext = {
  name: 'Product context',
  description: 'The header sometimes has a product context present.',
  muted: true,
  overflowVisual: true,
  config: responsive => demoConfig(responsive),
  render: (responsive, { profile }) => (
    <Provider store={store}>
      <IntlProvider locale="en">
        {/* eslint-disable no-alert */}
        <HeaderComponent
          profile={profile}
          searchTerm="cat"
          searchProductContext={{ id: 'u-sticker', label: 'Stickers' }}
          cart={cart}
        />
      </IntlProvider>
    </Provider>
  ),
  code: `
<Header profile={isMobile ? constants.MOBILE : constants.DESKTOP} cart={${JSON.stringify(cart)}} searchTerm="cat" searchProductContext={{ id: 'u-sticker', label: 'Stickers' }} />
  `,
};

const promoBanner = {
  name: 'Promo banner',
  description: 'The promo banner will rotate if prefers reduce motion is disabled',
  muted: true,
  overflowVisual: true,
  config: responsive => demoConfig(responsive),
  render: (responsive, { profile }) => (
    <Provider store={store}>
      <IntlProvider locale="en">
        <PromoBanner banners={banners} />
        {/* eslint-disable no-alert */}
        <HeaderComponent
          banners={banners}
          profile={profile}
          cart={cart}
        />
      </IntlProvider>
    </Provider>
  ),
  code: `
<Header profile={isMobile ? constants.MOBILE : constants.DESKTOP} banners={${JSON.stringify(messages)}} cart={${JSON.stringify(cart)}} />
  `,
};

const promoMessage = {
  name: 'Promo message',
  description: 'The promo message will have a countdown if you give it an endDate',
  muted: true,
  overflowVisual: true,
  config: responsive => demoConfig(responsive),
  render: (responsive, { profile }) => (
    <Provider store={store}>
      <IntlProvider locale="en">
        {/* eslint-disable no-alert */}
        <HeaderComponent
          banners={messages}
          profile={profile}
          cart={cart}
        />
        <PromoMessage banners={messages} profile={profile} />
      </IntlProvider>
    </Provider>
  ),
  code: `
<Header profile={isMobile ? constants.MOBILE : constants.DESKTOP} banners={${JSON.stringify(messages)}} cart={${JSON.stringify(cart)}} />
  `,
};

const loggedOut = {
  name: 'Anonymous',
  description: 'The header when the user is not logged in.',
  config: responsive => demoConfig(responsive),
  muted: true,
  overflowVisual: true,
  render: (responsive, { profile }) => (
    <Provider store={store}>
      <IntlProvider locale="en">
        {/* eslint-disable no-alert */}
        <HeaderComponent
          profile={profile}
          cart={cart}
        />
      </IntlProvider>
    </Provider>
  ),
  code: `
<Header profile={isMobile ? constants.MOBILE : constants.DESKTOP} cart={${JSON.stringify(cart)}} />
  `,
};

const artistMetrics = {
  name: 'Artist',
  description: `
    The nav contains a link to the artist's dashboard, and a set of metrics are displayed in the header on desktop.
  `,
  muted: true,
  overflowVisual: true,
  config: responsive => demoConfig(responsive),
  render: (responsive, { profile }) => (
    <Provider store={store}>
      <IntlProvider locale="en">
        {/* eslint-disable no-alert */}
        <HeaderComponent
          profile={profile}
          userInfo={userInfo}
          userAccount={artistAccount}
          cart={cart}
        />
      </IntlProvider>
    </Provider>
  ),
  code: `
<Header profile={isMobile ? constants.MOBILE : constants.DESKTOP} userInfo={${JSON.stringify(userInfo)}} userAccount={${JSON.stringify(artistAccount)}} cart={${JSON.stringify(cart)}} />
  `,
};

const withoutSearchBoxExample = {
  name: 'Hidden search box',
  description: 'You can choose to explicitly hide the search box.',
  muted: true,
  overflowVisual: true,
  config: responsive => demoConfig(responsive),
  render: (responsive, { profile }) => (
    <Provider store={store}>
      <IntlProvider locale="en">
        {/* eslint-disable no-alert */}
        <HeaderComponent
          showSearchBox={false}
          profile={profile}
          cart={cart}
        />
      </IntlProvider>
    </Provider>
  ),
  code: `
<Header profile={isMobile ? constants.MOBILE : constants.DESKTOP} showSearchBox={false} cart={${JSON.stringify(cart)}} />
  `,
};

const withBaseUrlExample = {
  name: 'Custom base url',
  description: '',
  overflowVisual: true,
  render: () => (
    <Provider store={store}>
      <IntlProvider locale="en">
        {/* eslint-disable no-alert */}
        <HeaderComponent
          isServer
          baseUrl="https://www.redbubble-staging.com"
          cart={cart}
        />
      </IntlProvider>
    </Provider>
  ),
  code: `
<Header profile={isMobile ? constants.MOBILE : constants.DESKTOP} baseUrl="https://www.redbubble-staging.com" cart={${JSON.stringify(cart)}} isServer />
  `,
};

const withRainbowBorderStyle = {
  name: 'Rainbow border style',
  description: 'Rainbow search box styling can be optionally enabled',
  muted: true,
  overflowVisual: true,
  config: responsive => demoConfig(responsive),
  render: (responsive, { profile }) => (
    <Provider store={store}>
      <IntlProvider locale="en">
        {/* eslint-disable no-alert */}
        <HeaderComponent
          profile={profile}
          userInfo={userInfo}
          cart={cart}
          useRainbowStyle
        />
      </IntlProvider>
    </Provider>
  ),
  code: `
<Header profile={isMobile ? constants.MOBILE : constants.DESKTOP} useRainbowStyle cart={${JSON.stringify(cart)}} userInfo={${JSON.stringify(userInfo)}} />
  `,
};

const engagingTextStrings = [
  'Search for Stranger Things fan art',
  'Discover a most peculiar floral pattern',
  'Find a phone case made just for you',
  'Explore mind-warping wall art',
  'Discover an artist who gets you',
];

const withEngagingText = {
  name: 'Engaging Text',
  description: 'The placeholder text can be customized by supplying a list of strings, which will rotate at a regulat interval (4s). If users have enabled "Use Reduced Motion", the default placeholder text experience will be shown instead.',
  muted: true,
  overflowVisual: true,
  config: responsive => demoConfig(responsive),
  render: (responsive, { profile }) => (
    <Provider store={store}>
      <IntlProvider locale="en">
        <HeaderComponent
          profile={profile}
          userInfo={userInfo}
          searchProductContext={{ id: 'u-sticker', label: 'Stickers' }}
          cart={cart}
          engagingPlaceholderMessages={engagingTextStrings}
        />
      </IntlProvider>
    </Provider>
  ),
  code: `
<Header
profile={isMobile ? constants.MOBILE : constants.DESKTOP}
engagingPlaceholderMessages={${JSON.stringify(engagingTextStrings)}}
cart={${JSON.stringify(cart)}} userInfo={${JSON.stringify(userInfo)}} />
  `,
};

const withExpandedSearch = {
  name: 'Expanded Search',
  description: 'An option to show expanded search taking full screen width on mobile',
  muted: true,
  overflowVisual: true,
  render: () => (
    <Provider store={store}>
      <IntlProvider locale="en">
        <HeaderComponent
          profile="mobile"
          cart={cart}
          showExpandedMobileSearch
          useRainbowStyle
          engagingPlaceholderMessages={engagingTextStrings}
        />
      </IntlProvider>
    </Provider>
  ),
  code: `
    <Header
    profile={constants.MOBILE}
    cart={${JSON.stringify(cart)}} userInfo={${JSON.stringify(userInfo)}}
    showExpandedMobileSearch
    useRainbowStyle
    engagingPlaceholderMessages={engagingTextStrings}
  />
  `,
};

const withDesktopHamburgerMenu = {
  name: 'Desktop Hamburger Menu',
  description: 'An option to display the hamburger menu on desktop too for cases where you may not want to surface the product nav',
  muted: true,
  overflowVisual: true,
  config: responsive => demoConfig(responsive),
  render: (responsive, { profile }) => (
    <Provider store={store}>
      <IntlProvider locale="en">
        <HeaderComponent
          profile={profile}
          searchProductContext={{ id: 'u-sticker', label: 'Stickers' }}
          cart={cart}
          engagingPlaceholderMessages={engagingTextStrings}
          showHamburgerOnDesktop
        />
      </IntlProvider>
    </Provider>
  ),
  code: `
  <Header
  profile={isMobile ? constants.MOBILE : constants.DESKTOP}
  engagingPlaceholderMessages={${JSON.stringify(engagingTextStrings)}}
  cart={${JSON.stringify(cart)}} userInfo={${JSON.stringify(userInfo)}}
  showHamburgerOnDesktop
 />
  `,
};

const withFixedSearch = {
  name: 'Fixed Search',
  description: 'An option to make search bar fixed to the top when scrolling. On mobile, the search bar will also be animated depending on scroll direction',
  muted: true,
  overflowVisual: true,
  render: () => (
    <Provider store={store}>
      <IntlProvider locale="en">
        <HeaderComponent
          profile="mobile"
          cart={cart}
          showExpandedMobileSearch
          position="fixed"
        />
      </IntlProvider>
    </Provider>
  ),
  code: `
    <Header
    profile={constants.MOBILE}
    cart={${JSON.stringify(cart)}} userInfo={${JSON.stringify(userInfo)}}
    showExpandedMobileSearch
    position="fixed"
  />
  `,
};

export default {
  title: Header.displayName,
  description: 'A global header used across different apps',
  docGen: docs,
  slug: 'header',
  examples: [
    loggedOut,
    basic,
    artistMetrics,
    withProductContext,
    promoBanner,
    promoMessage,
    withoutSearchBoxExample,
    withBaseUrlExample,
    withRainbowBorderStyle,
    withEngagingText,
    withDesktopHamburgerMenu,
    withExpandedSearch,
    withFixedSearch,
  ],
};
