import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import State from '@reach/component-component';
import Card from '../Card';
import Text from '../Text';
import Box from '../Box';
import * as MenuExports from '.';
import DashboardIcon from '../Icons/Dashboard';
import StoreIcon from '../Icons/Store';
import TrendingUpIcon from '../Icons/TrendingUp';
import ChevronRightBigIcon from '../Icons/ChevronRightBig';

// eslint-disable-next-line
import MenuSrc from '!raw-loader!./';

const {
  default: Menu,
  MenuLink,
  MenuItem,
  MenuSection,
} = MenuExports;
const docs = generateDocs(MenuSrc, resolver.findAllExportedComponentDefinitions);

const Common = props => (
  <Menu {...props}>
    <MenuSection title="ARTIST TOOLS" divided>
      <MenuLink href="/">Dashboard</MenuLink>
      <MenuLink href="/">Activity Feed</MenuLink>
      <MenuLink href="/">Shop Settings</MenuLink>
      <MenuLink href="/">Edit profile</MenuLink>
      <MenuLink href="/">Edit payment details</MenuLink>
    </MenuSection>
    <MenuSection title="PORTFOLIO">
      <MenuLink href="/">Upload a new work</MenuLink>
      <MenuLink href="/">Collections</MenuLink>
      {/* eslint-disable-next-line no-alert */}
      <MenuItem onClick={() => alert('Uploading')}>
        Upload new work
      </MenuItem>
    </MenuSection>
  </Menu>
);

const basicExample = {
  name: 'Simple Example',
  description: 'Menus can stand alone or within a Card',
  render: responsive => (
    <Box display="flex" flexDirection={responsive.mobile ? 'column' : 'row'}>
      <Box padding="m" flex="1">
        <Common />
      </Box>
      <Box padding="m" flex="1">
        <Card>
          <Box paddingY="m">
            <Common padded />
          </Box>
        </Card>
      </Box>
    </Box>
  ),
  code: `
<Box display="flex" flexDirection={responsive.mobile ? 'column' : 'row'}>
  <Box padding="m" flex="1">
    <Menu>
      <MenuSection title="ARTIST TOOLS" divided>
        <MenuLink href="/">Dashboard</MenuLink>
        <MenuLink href="/">Activity Feed</MenuLink>
        <MenuLink href="/">Shop Settings</MenuLink>
        <MenuLink href="/">Edit profile</MenuLink>
        <MenuLink href="/">Edit payment details</MenuLink>
      </MenuSection>
      <MenuSection title="PORTFOLIO">
        <MenuLink href="/">Upload a new work</MenuLink>
        <MenuLink href="/">Collections</MenuLink>
        {/* eslint-disable-next-line no-alert */}
        <MenuItem onClick={() => alert('Uploading')}>
          Upload new work
        </MenuItem>
      </MenuSection>
    </Menu>
  </Box>
  <Box padding="m" flex="1">
    <Card>
      <Box paddingY={1}>
        <Menu padded>
          <MenuSection title="ARTIST TOOLS" divided>
            <MenuLink href="/">Dashboard</MenuLink>
            <MenuLink href="/">Activity Feed</MenuLink>
            <MenuLink href="/">Shop Settings</MenuLink>
            <MenuLink href="/">Edit profile</MenuLink>
            <MenuLink href="/">Edit payment details</MenuLink>
          </MenuSection>
          <MenuSection title="PORTFOLIO">
            <MenuLink href="/">Upload a new work</MenuLink>
            <MenuLink href="/">Collections</MenuLink>
            {/* eslint-disable-next-line no-alert */}
            <MenuItem onClick={() => alert('Uploading')}>
              Upload new work
            </MenuItem>
          </MenuSection>
        </Menu>
      </Box>
    </Card>
  </Box>
</Box>
  `,
};

const CommonIcon = ({ ...props }) => (
  <Menu {...props}>
    <MenuSection title="ARTIST TOOLS" divided>
      <MenuLink
        iconBefore={<DashboardIcon />}
        iconAfter={<ChevronRightBigIcon />}
        href="/"
      >
        Dashboard
      </MenuLink>
      <MenuLink
        iconBefore={<TrendingUpIcon />}
        iconAfter={<ChevronRightBigIcon />}
        href="/"
      >
        Activity Feed
      </MenuLink>
      <MenuLink
        iconBefore={<StoreIcon />}
        iconAfter={<ChevronRightBigIcon />}
        href="/"
      >
        Shop Settings
      </MenuLink>
    </MenuSection>
    <MenuSection title="PORTFOLIO">
      <MenuLink iconAfter={<ChevronRightBigIcon />} href="/">Upload a new work</MenuLink>
      <MenuLink iconAfter={<ChevronRightBigIcon />} href="/">Collections</MenuLink>
    </MenuSection>
  </Menu>
);

const iconExample = {
  name: 'Icons',
  description: 'MenuItems can have Icons before or after their contents.',
  render: responsive => (
    <Box display="flex" flexDirection={responsive.mobile ? 'column' : 'row'}>
      <Box padding="m" flex="1">
        <CommonIcon />
      </Box>
      <Box padding="m" flex="1">
        <Card>
          <Box paddingY="m">
            <CommonIcon padded />
          </Box>
        </Card>
      </Box>
    </Box>
  ),
  code: `
<Box display="flex" flexDirection={responsive.mobile ? 'column' : 'row'}>
  <Box padding="m" flex="1">
    <Menu>
      <MenuSection title="ARTIST TOOLS" divided>
        <MenuLink
          iconBefore={<DashboardIcon />}
          iconAfter={<ChevronRightBigIcon />}
          href="/"
        >
          Dashboard
        </MenuLink>
        <MenuLink
          iconBefore={<TrendingUpIcon />}
          iconAfter={<ChevronRightBigIcon />}
          href="/"
        >
          Activity Feed
        </MenuLink>
        <MenuLink
          iconBefore={<StoreIcon />}
          iconAfter={<ChevronRightBigIcon />}
          href="/"
        >
          Shop Settings
        </MenuLink>
      </MenuSection>
      <MenuSection title="PORTFOLIO">
        <MenuLink iconAfter={<ChevronRightBigIcon />} href="/">Upload a new work</MenuLink>
        <MenuLink iconAfter={<ChevronRightBigIcon />} href="/">Collections</MenuLink>
      </MenuSection>
    </Menu>
  </Box>
  <Box padding="m" flex="1">
    <Card>
      <Box paddingY={1}>
        <Menu padded>
          <MenuSection title="ARTIST TOOLS" divided>
            <MenuLink
              iconBefore={<DashboardIcon />}
              iconAfter={<ChevronRightBigIcon />}
              href="/"
            >
              Dashboard
            </MenuLink>
            <MenuLink
              iconBefore={<TrendingUpIcon />}
              iconAfter={<ChevronRightBigIcon />}
              href="/"
            >
              Activity Feed
            </MenuLink>
            <MenuLink
              iconBefore={<StoreIcon />}
              iconAfter={<ChevronRightBigIcon />}
              href="/"
            >
              Shop Settings
            </MenuLink>
          </MenuSection>
          <MenuSection title="PORTFOLIO">
            <MenuLink iconAfter={<ChevronRightBigIcon />} href="/">Upload a new work</MenuLink>
            <MenuLink iconAfter={<ChevronRightBigIcon />} href="/">Collections</MenuLink>
          </MenuSection>
        </Menu>
      </Box>
    </Card>
  </Box>
</Box>
  `,
};

const activeExample = {
  name: 'Active items',
  description: 'Items can be set to active. This is useful when using the `Menu` as navigation.',
  render: () => {
    const items = {
      dashboard: 'Dashboard',
      feed: 'Activity Feed',
      profile: 'Profile',
    };

    return (
      <Box padding="m" flex="1">
        <State initialState={{ active: 'dashboard' }}>
          {
            ({ setState, state }) => (
              <Box display="flex">
                <Box flex="0.75" marginRight="xl">
                  <Menu>
                    <MenuSection title="ARTIST TOOLS" divided>
                      {
                        Object.entries(items).map(([slug, label]) => (
                          <MenuItem
                            key={slug}
                            active={state.active === slug}
                            onClick={() => setState({ active: slug })}
                          >
                            { label }
                          </MenuItem>
                        ))
                      }
                    </MenuSection>
                  </Menu>
                </Box>
                <Box flex="2">
                  <Text type="display3" display="block">{ items[state.active] }</Text>
                  <Text display="block">Content for the { items[state.active] } page</Text>
                </Box>
              </Box>
            )
          }
        </State>
      </Box>
    );
  },
  code: `
<Box padding="m" flex="1">
  <State initialState={{ active: 'dashboard' }}>
    {
      ({ setState, state }) => (
        <Box display="flex">
          <Box flex="0.75" marginRight={2}>
            <Menu>
              <MenuSection title="ARTIST TOOLS" divided>
                {
                  Object.entries(items).map(([slug, label]) => (
                    <MenuItem
                      key={slug}
                      active={state.active === slug}
                      onClick={() => setState({ active: slug })}
                    >
                      { label }
                    </MenuItem>
                  ))
                }
              </MenuSection>
            </Menu>
          </Box>
          <Box flex="2">
            <Text type="display3" display="block">{ items[state.active] }</Text>
            <Text display="block">Content for the { items[state.active] } page</Text>
          </Box>
        </Box>
      )
    }
  </State>
</Box>
  `,
};

const Demo = {
  title: Menu.displayName,
  docGen: docs.find(({ displayName }) => displayName === 'Menu'),
  slug: 'Menu',
  description: '',
  exports: Object.keys(MenuExports),
  examples: [
    basicExample,
    iconExample,
    activeExample,
  ],
};

export default Demo;
