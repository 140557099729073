import React from 'react';
import styles from '../styles.scss';

const SVG = ({
  fillColor,
  wordMarkColor = '#333',
  brandMarkColor = '#e41321',
}) => (
  <svg
    role="img"
    data-testid="ds-2019-stacked-logo"
    viewBox="0 0 73.25 64"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.svg}
  >
    <title>Redbubble logo</title>
    <path
      data-testid="ds-wordmark"
      fill={fillColor || wordMarkColor}
      d="M7.12 63.73s0-.74-.06-1.64l-.1-3c0-1.29-.3-2.25-1.59-2.6v-.06a3.33 3.33 0 001.84-3.28c0-2.58-1.22-3.76-3.59-3.76H0v14.34h2.32v-5.89h1.12c.92 0 1.24.55 1.26 1.51 0 0 .07 2.28.08 3s0 1.43 0 1.43zm-2.29-9.41c0 .84-.31 1.72-1.41 1.72h-1.1v-4.55h1.12a1.49 1.49 0 011.41 1.7zm68.42 9.41V61.6h-3.72v-4.25h3v-2.13h-3v-3.68h3.72v-2.13h-6v14.32zm-7.39 0V61.6h-3.73V49.41h-2.32v14.32zm-10.62 0c1.69 0 3.16-1.23 3.16-4.07a3.2 3.2 0 00-2-3.23v-.09a3.3 3.3 0 001.87-3.25c0-2.66-1.3-3.68-3.27-3.68h-3.89v14.32zm.65-9.75a1.36 1.36 0 01-1.3 1.49h-1.18v-4h1.14c1 0 1.38.47 1.36 1.62zm.11 6c0 1.14-.46 1.74-1.48 1.74h-1.11v-4.3h1.06A1.49 1.49 0 0156 58.98zm-9.48 3.8c1.69 0 3.16-1.23 3.16-4.07a3.2 3.2 0 00-2-3.23v-.09a3.3 3.3 0 001.87-3.25c0-2.66-1.3-3.68-3.27-3.68H42.4v14.27zm.66-9.8a1.36 1.36 0 01-1.3 1.49H44.7v-4h1.14c1 0 1.38.47 1.36 1.62zm.12 6c0 1.14-.46 1.74-1.48 1.74H44.7v-4.3h1.06a1.49 1.49 0 011.54 1.56zm-10.07 4c2.46 0 3.52-1.62 3.52-3.85V49.41h-2.2v10.68c0 .9-.22 1.8-1.26 1.8s-1.3-1-1.3-1.76V49.41h-2.33v10.9c0 2.48 1.34 3.67 3.57 3.67m-8-.27c1.69 0 3.15-1.23 3.15-4.07a3.2 3.2 0 00-1.95-3.23v-.09a3.3 3.3 0 001.87-3.25c0-2.66-1.3-3.68-3.28-3.68h-3.91v14.34zm.67-9.73a1.36 1.36 0 01-1.31 1.49h-1.18v-4h1.14c1 0 1.39.47 1.37 1.62zm.1 6c0 1.14-.47 1.74-1.49 1.74h-1.1v-4.3h1.06A1.5 1.5 0 0130 58.98zm-9.89 3.8c2.11 0 3.29-1.1 3.29-3.44v-7.58c0-2.27-1.2-3.35-3.36-3.35h-3.8v14.32zm.95-3.54c0 .9-.28 1.43-1.42 1.43h-1.06V51.51h1.1a1.26 1.26 0 011.42 1.47zM8.72 63.73V49.41h6v2.13h-3.67v3.68h3v2.13h-3v4.25h3.72v2.13z"
    />
    <path
      data-testid="ds-brandmark"
      fill={fillColor || brandMarkColor}
      d="M50 23.45c0 2.73-2.05 4.36-5.47 4.36h-5.46a.55.55 0 01-.55-.55V13.58a.55.55 0 01.55-.55h5c3.79 0 4.59 2.24 4.59 4.11a3.7 3.7 0 01-.86 2.57 3.68 3.68 0 012.2 3.74m-12.85 3.81a.55.55 0 01-.54.55H25.29a.55.55 0 01-.54-.55V13.58a.55.55 0 01.54-.55h5.29c3.3 0 5.27 1.78 5.27 4.76a4.27 4.27 0 01-2.6 4.19L37 26.9a.51.51 0 01.14.36m19.92-6.82a20.44 20.44 0 10-20.43 20.44 20.44 20.44 0 0020.44-20.44"
    />
  </svg>
);

export default SVG;
