import React from 'react';
import useResponsive from '../../../hooks/useResponsive';
import PageIntro from '../../../components/PageIntro';
import PageTitle from '../../../components/PageTitle';
import PageDescription from '../../../components/PageDescription';
import Markdown from '../../../components/Markdown';
import DownloadIcon from '../../../../react/Icons/Download';
import MediaCard from '../../../../react/MediaCard';
import Image, { generatePlaceholderSrc } from '../../../../react/Image';
import Text from '../../../../react/Text';
import Box from '../../../../react/Box';
import Grid from '../../../../react/Grid';
import ComponentUsage from '../../../components/ComponentUsage';
import { PanelFooter } from '../../../components/Panel';
import styles from './Logo.css';

import logoAssets from './media/logo-assets-artwork.jpg';
import bukoAvatar from './media/bukoavatar.jpg';
import logoAssetsZip from './media/rb_logo_assets_fyt.zip';
import clearspaceMin from './media/clearspace-min.svg';
import clearspaceIdeal from './media/clearspace-ideal.svg';
import minsizeDefault from './media/minsize-default.svg';
import minsizeBrandmark from './media/minsize-brandmark.svg';
import logored from './media/RB_MonoRed_RGB.svg';
import logowhite from './media/RB_MonoWhite_RGB.svg';
import logogrey from './media/RB_MonoGrey_RGB.svg';
import logodefault from './media/RB_Default_RGB.svg';
import logobrandmark from './media/RB_Red_RGB.svg';

const artists = [
  {
    name: 'buko',
    avatar: bukoAvatar,
    shopUrl: 'https://www.redbubble.com/people/buko/shop',
  },
];

const relatedContent = [
  {
    name: 'Logo',
    link: '/components/Logo/examples',
  },
];

const LogoGuidelines = () => {
  const { mobile } = useResponsive();

  return (
    <>
      <Box className={styles.content}>
        <PageIntro>
          {/* eslint-disable max-len */}
          <PageTitle>Logo</PageTitle>
          <PageDescription>
            <Text display="block" type="display4">
              Our logo distinguishes us as fun, modern, thoughtful, and creative. It communicates
              who we are and what we stand for. It’s the Redbubble seal of awesomeness.
            </Text>
          </PageDescription>
          {/* eslint-enable max-len */}
        </PageIntro>
        <Box paddingTop="xl" marginTop="m" paddingBottom="m">
          <Markdown
            source={`

          ## Overview
          We’re a creative marketplace, so the right color, lockup, clearspace and size matter.

          ### Color
          Always use the logo as provided in the logo pack. Use the full colored version wherever possible. When the full colored logo doesn't work, use pure red, pure white, or dark grey.
        `}
          />
        </Box>
        <Box marginBottom="xl">
          <Grid columns={mobile ? 1 : 1}>
            <ComponentUsage
              type="do"
              background="none"
              description="Use the default full colored logo whenever possible."
            >
              <Box display="flex" justifyContent="center" className={styles.logo}>
                <Image src={logodefault} height="64" />
              </Box>
            </ComponentUsage>
          </Grid>
        </Box>
        <Grid columns={mobile ? 1 : 3}>
          <ComponentUsage
            type="do"
            background="none"
            description="Pure red"
          >
            <Box display="flex" justifyContent="center" className={styles.logo}>
              <Image src={logored} height="32" />
            </Box>
          </ComponentUsage>
          <ComponentUsage
            background="dark"
            type="do"
            description="Pure white"
          >
            <Box display="flex" justifyContent="center" className={styles.logo}>
              <Image src={logowhite} height="32" />
            </Box>
          </ComponentUsage>
          <ComponentUsage
            background="neutral"
            type="do"
            description="Dark grey"
          >
            <Box display="flex" justifyContent="center" className={styles.logo}>
              <Image src={logogrey} height="32" />
            </Box>
          </ComponentUsage>
        </Grid>
        <Box paddingTop="xl" marginTop="m" paddingBottom="m">
          <Markdown
            source={`
  ### Lockup

  We either show the **logo** as is or just the **brandmark**. And they should be used on all standalone branded materials.
  `}
          />
        </Box>
        <Grid columns={mobile ? 1 : 2}>
          <ComponentUsage
            type="do"
            background="none"
            description="Use the default logo whenever possible."
          >
            <Box display="flex" justifyContent="center" className={styles.logo}>
              <Image src={logodefault} height="48" />
            </Box>
          </ComponentUsage>
          <ComponentUsage
            type="caution"
            background="none"
            description="The brandmark alone should only be used if the Full logo has already been introduced."
          >
            <Box display="flex" justifyContent="center" className={styles.logo}>
              <Image src={logobrandmark} height="48" />
            </Box>
          </ComponentUsage>
        </Grid>
        <Box paddingTop="xl" marginTop="m" paddingBottom="m">
          <Markdown
            source={`
### Clearspace

Our logo needs a little breathing room. Using a third of the height is a good rule of thumb.
`}
          />
        </Box>
        <Grid columns={mobile ? 1 : 2}>
          <ComponentUsage
            type="do"
            background="none"
            description="Whenever possible, use the height of the logo."
          >
            <Box display="flex" justifyContent="center">
              <Box className={styles.clearspaceLogo}>
                <Image src={clearspaceIdeal} fluid />
              </Box>
            </Box>
          </ComponentUsage>
          <ComponentUsage
            type="do"
            background="none"
            description="The minumum should be a third of the height."
          >
            <Box display="flex" justifyContent="center">
              <Box className={styles.clearspaceLogo}>
                <Image src={clearspaceMin} fluid />
              </Box>
            </Box>
          </ComponentUsage>
        </Grid>
        <Box paddingTop="xl" marginTop="m" paddingBottom="m">
          <Markdown
            source={`
### Size

Check out all of these tiny logos. Pretty cute, right? This is the absolute smallest sizes that we should ever make our logo. We’re proud of our logo, so we need to make sure not to make it too small.

`}
          />
        </Box>
        <Grid columns={mobile ? 1 : 2}>
          <ComponentUsage type="do" background="none">
            <Box display="flex" justifyContent="center">
              <Image src={minsizeDefault} />
            </Box>
          </ComponentUsage>
          <ComponentUsage type="do" background="none">
            <Box display="flex" justifyContent="center">
              <Box>
                <Image src={minsizeBrandmark} />
              </Box>
            </Box>
          </ComponentUsage>
        </Grid>

        <Markdown
          source={`


  ### Download

  This package includes **vector** and **bitmap** versions of our available logos.

  `}
        />

        <Grid columns={mobile ? 1 : 2}>
          <Box className={styles.imageHoverContainer}>
            <MediaCard
              href={logoAssetsZip}
              imageRender={() => (
                <Box className={styles.imageContainer}>
                  <Image
                    fluid
                    src={logoAssets}
                    loadOnVisible={{
                      placeholder: generatePlaceholderSrc({ width: 300, height: 100 }),
                    }}
                  />
                </Box>
              )}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                paddingLeft="xs"
              >
                <Text display="block" type="display5">
                  Logo pack
                </Text>
                <Box paddingLeft="m" paddingRight="xs" display="flex" alignItems="center">
                  <DownloadIcon />
                </Box>
              </Box>
            </MediaCard>
          </Box>
        </Grid>
      </Box>
      <PanelFooter artists={artists} relatedContent={relatedContent} />
    </>
  );
};

export default LogoGuidelines;
