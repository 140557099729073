import React from 'react';
import PropTypes from 'prop-types';
import _styles from './styles.scss';

const ModalCard = ({ children, styles }) => (
  <div className={styles.card}>
    { children }
  </div>
);

ModalCard.propTypes = {
  /**
    * The content of the Modal Card, typically one or more ModalBody
    */
  children: PropTypes.node,
  /**
    * The CSS classNames for this component. NEVER manually specify this prop
    */
  styles: PropTypes.shape({}),
};

ModalCard.defaultProps = {
  children: null,
  styles: _styles,
};

ModalCard.displayName = 'ModalCard';

export default ModalCard;
