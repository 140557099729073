import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Redirect, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import Menu, { MenuLink, MenuSection } from '../../../react/Menu';
import Text from '../../../react/Text';
import Select from '../../../react/Form/Select';
import PageBanner from '../../components/Banner';
import ComponentDemo from '../../components/ComponentDemo';
import componentDemos from '../../lib/componentDemos';
import artwork from './assets/artwork.jpg';

const routes = componentDemos.map(item => ({
  label: item.demo.title,
  path: `/${item.demo.slug}/examples`,
  beta: item.demo.beta,
  section: item.section,
}))
// eslint-disable-next-line
.sort((a, b) => (a.label < b.label)  ? -1 : (a.label > b.label) ? 1 : 0);

// Get sections from component demos
const sectionLabels = componentDemos.reduce((unique, demo) => {
  return unique.includes(demo.section) ? unique : [...unique, demo.section];
}, []);

// Put components in matching section
const sections = sectionLabels.map(section => routes.filter(demo => demo.section === section));

const Components = ({ title }) => {
  const { path } = useRouteMatch();
  return (
    <>
      <Route exact path={path}>
        <Redirect to={`${path}${sections[0][0].path.toLowerCase()}`} />
      </Route>
      {
        componentDemos.map(item => (
          <Route
            key={item.demo.slug}
            path={`${path}/${item.demo.slug}`}
            render={({ match }) => (
              <>
                <Helmet>
                  <title>{title} - {item.demo.title}</title>
                  <meta property="og:title" content={item.demo.title} />
                </Helmet>
                <ComponentDemo
                  {...item.demo}
                  slug={`${path}/${item.demo.slug}`}
                  match={match}
                />
              </>
            )}
          />
        ))
      }
    </>
  );
};

const NavMenuItem = (props) => {
  // const { pathname } = useLocation();
  const history = useHistory();
  const match = useRouteMatch(props.href.replace('examples', ':tab'));

  function handleClick(e) {
    e.preventDefault();
    history.push(props.href);
  }

  return (
    <MenuLink
      {...props}
      onClick={handleClick}
      active={!!match}
    />
  );
};

const Sidebar = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Text type="display3" display="block" marginBottom={2}>Components</Text>
      <Menu>
        {
          sections.map(section => (
            <MenuSection title={section[0].section}>
              {
                section.map(item => (
                  <NavMenuItem
                    href={path + item.path}
                    key={item.label}
                  >
                    {item.label}
                  </NavMenuItem>
                ))
              }
            </MenuSection>
          ))
        }
      </Menu>
    </>
  );
};

const Banner = ({ mobile }) => {
  return (
    <PageBanner
      artwork={artwork}
      shopLink="https://www.redbubble.com/people/AmyHamilton/shop"
      artworkLink="https://www.redbubble.com/shop/ap/22238873"
      avatar="https://ih1.redbubble.net/avatar.790482.140x140.jpg"
      title="Moose"
      artist="Amy Hamilton"
      mobile={mobile}
    />
  );
};


const Nav = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { pathname } = useLocation();

  return (
    <>
      { /* eslint-disable-next-line */ }
      <label>
        <Text type="display5" display="block" marginBottom="s">Components</Text>
        <Select
          label="Components"
          type="select"
          name="components"
          placeholder="Components"
          value={pathname}
          fluid
          onChange={values => history.push(values)}
          options={
            routes.map((item) => {
              return (
                { value: path + item.path.toLowerCase(), label: item.label }
              );
            })
          }
        />
      </label>
    </>
  );
};

export {
  Nav,
  Sidebar,
  Banner,
  routes,
};

export default Components;
