import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import Box from '../Box';
import * as constants from '../constants';

import * as SkeletonExports from '.';

// eslint-disable-next-line
import SkeletonSrc from '!raw-loader!.';

const { default: Skeleton } = SkeletonExports;
const docs = generateDocs(SkeletonSrc, resolver.findAllExportedComponentDefinitions);

const basicExample = {
  name: 'Simple Example',
  description: `
Loading skeletons default to 100% width. You can optionally specify a custom width and height.
You can set them up to be a circular shape, in which case the aspect-ratio is set to be 1:1.
You can also lock the aspect ratio of a skeleton, in which case the height is determined for you.
  `,
  overflowVisual: false,
  render: () => (
    <Box display="flex">
      <Box marginRight="m" style={{ width: 150 }}>
        <Skeleton ratio={constants.RATIO_SQUARE} />
      </Box>

      <Box marginRight="m" style={{ width: 150 }}>
        <Skeleton ratio={constants.RATIO_PORTRAIT} />
      </Box>

      <Box marginRight="m" style={{ width: 150 }}>
        <Skeleton ratio={constants.RATIO_LANDSCAPE} />
      </Box>

      <Box marginRight="m" style={{ width: 150 }}>
        <Skeleton circular />
      </Box>

      <Box marginRight="m" style={{ width: 200, height: 100 }}>
        <Skeleton width="100%" height="100%" />
      </Box>

      <Box marginRight="m">
        <Skeleton width="100px" ratio={constants.RATIO_PORTRAIT} />
      </Box>
    </Box>
  ),
  code: `
<Box display="flex">
  <Box marginRight="m" style={{ width: 150 }}>
    <Skeleton ratio={constants.RATIO_SQUARE} />
  </Box>

  <Box marginRight="m" style={{ width: 150 }}>
    <Skeleton ratio={constants.RATIO_PORTRAIT} />
  </Box>

  <Box marginRight="m" style={{ width: 150 }}>
    <Skeleton ratio={constants.RATIO_LANDSCAPE} />
  </Box>

  <Box marginRight="m" style={{ width: 150 }}>
   <Skeleton circular />
  </Box>

  <Box marginRight="m" style={{ width: 200, height: 100 }}>
    <Skeleton width="100%" height="100%" />
  </Box>

  <Box marginRight="m">
   <Skeleton width="100px" ratio={constants.RATIO_PORTRAIT} />
  </Box>
</Box>
  `,
};

const animatedExample = {
  name: 'Animated Example',
  description: `
Loading skeletons can have a shimmer animation applied to them
  `,
  overflowVisual: false,
  render: () => (
    <Box>
      <Box display="flex" marginBottom="m">
        <Box marginRight="m" style={{ width: 150 }}>
          <Skeleton ratio={constants.RATIO_SQUARE} animated />
        </Box>

        <Box marginRight="m" style={{ width: 150 }}>
          <Skeleton ratio={constants.RATIO_PORTRAIT} animated />
        </Box>

        <Box marginRight="m" style={{ width: 150 }}>
          <Skeleton ratio={constants.RATIO_LANDSCAPE} animated />
        </Box>
      </Box>
      <Box>
        <Skeleton width={300} height={32} animated />
      </Box>
    </Box>
  ),
  code: `
  <Box>
    <Box display="flex" margin="m">
      <Box style={{ width: 150 }}>
        <Skeleton ratio={constants.RATIO_SQUARE} animated />
      </Box>

      <Box style={{ width: 150 }}>
        <Skeleton ratio={constants.RATIO_PORTRAIT} animated />
      </Box>

      <Box style={{ width: 150 }}>
        <Skeleton ratio={constants.RATIO_LANDSCAPE} animated />
      </Box>
    </Box>
    <Box>
      <Skeleton width={300} height={32} animated />
    </Box>
  </Box>
  `,
};

const Demo = {
  title: Skeleton.displayName,
  description: 'Skeletons for building loading states and wireframes.',
  docGen: docs,
  slug: 'skeleton',
  exports: Object.keys(SkeletonExports),
  examples: [
    basicExample,
    animatedExample,
  ],
  notes: `
  `,
};

export default Demo;
